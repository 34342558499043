import { Path, Remote } from './types';

const allDeferrals = (path: Path, remote: Remote) => () => remote.get(path('/'));

const approveDeferralsRequest = (path: Path, remote: Remote) => (requestId: any, comment: any) =>
  remote.post(path(`/${requestId}/approve`), { comment });

const rejectDeferralsRequest = (path: Path, remote: Remote) => (requestId: any, comment: any) =>
  remote.post(path(`/${requestId}/reject`), { comment });

const deleteDeferralRequest = (path: Path, remote: Remote) => (requestId: any) => remote.del(path(`/${requestId}`));

const resubmitDeferralRequest = (path: Path, remote: Remote) => (request: any, updateValues: any) =>
  remote.put(path(`/${request.id}`), { ...updateValues });

export default (path: Path, remote: Remote) => ({
  getAllDeferrals: allDeferrals(path, remote),
  approveDeferralsRequest: approveDeferralsRequest(path, remote),
  rejectDeferralsRequest: rejectDeferralsRequest(path, remote),
  deleteDeferralRequest: deleteDeferralRequest(path, remote),
  resubmitDeferralRequest: resubmitDeferralRequest(path, remote),
});
