import { TableContainer, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import ActionButton from '../../../../../components/ActionButton';
import { ImpactProbabilityAssessmentDetail } from '../../../../../types/risk-management';

interface Props {
  setEditRow: (value: number) => void;
  impactAssessments: ImpactProbabilityAssessmentDetail[];
  isReadOnly: boolean;
}

const ONE_MILLION = 1000000;

const CAD_DOLLAR = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});

const calculateSafetyImpact = (impactAssessment: ImpactProbabilityAssessmentDetail) =>
  impactAssessment.environmentalSafetyCost +
  impactAssessment.industrialSafetyCost +
  impactAssessment.radiologicalSafetyCost +
  impactAssessment.reactorSafetyCost +
  impactAssessment.reputationalImpactCost +
  impactAssessment.regulatoryComplianceCost;

const calculateCommercial = (impactAssessment: ImpactProbabilityAssessmentDetail) =>
  (impactAssessment.daysForcedOutageForProductionImpact *
    impactAssessment.forcedOutageRate *
    impactAssessment.unitsForProductionImpact +
    impactAssessment.commercialImpactForAllAffectedUnits) *
  ONE_MILLION;

const calculateNetImpact = (impactAssessment: ImpactProbabilityAssessmentDetail) =>
  (calculateSafetyImpact(impactAssessment) + calculateCommercial(impactAssessment)) *
  parseFloat(impactAssessment.probabilityRate);

const ImportProbabilityAssessmentTable = ({ setEditRow, impactAssessments: impactAssessments, isReadOnly }: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>I & P ID</TableCell>
          <TableCell>Year</TableCell>
          <TableCell align="center">Probability (%)</TableCell>
          <TableCell align="center">Total Safety Impact ($M)</TableCell>
          <TableCell align="center">Total Commercial ($M)</TableCell>
          <TableCell align="center">Net Impact ($M)</TableCell>
          <TableCell align="center">Worst Case Commercial Impact ($M)</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      {impactAssessments.map(impactAssessment => (
        <TableRow key={impactAssessment.id}>
          <TableCell>IP-{impactAssessment.id}</TableCell>
          <TableCell>{impactAssessment.year}</TableCell>
          <TableCell align="center">{(parseFloat(impactAssessment.probabilityRate) * 100).toFixed(2)}%</TableCell>
          <TableCell align="center">
            {CAD_DOLLAR.format(calculateSafetyImpact(impactAssessment) / ONE_MILLION)}M
          </TableCell>
          <TableCell align="center">
            {CAD_DOLLAR.format(calculateCommercial(impactAssessment) / ONE_MILLION)}M
          </TableCell>
          <TableCell align="center">{CAD_DOLLAR.format(calculateNetImpact(impactAssessment) / ONE_MILLION)}M</TableCell>
          <TableCell align="center">{CAD_DOLLAR.format(impactAssessment.worstCaseTotalCommercialImpact)}M</TableCell>
          <TableCell>
            <ActionButton
              variant="text"
              onClick={() => {
                setEditRow(impactAssessment.id);
              }}
            >
              {isReadOnly ? 'View' : 'Edit'}
            </ActionButton>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </TableContainer>
);

export default ImportProbabilityAssessmentTable;
