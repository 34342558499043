import React, { useContext } from 'react';
import * as R from 'ramda';
import useUiState from './ui-state';
import useExecutiveSummary from './executive-summary';
import useComments from './comments';

const ExecutiveSummaryUiContext = React.createContext();

const useExecutiveSummaryUi = () => {
  const context = useContext(ExecutiveSummaryUiContext);
  if (R.isNil(context)) {
    throw new Error('useCommentStuff must be used within a ExecutiveSummaryUiProvider');
  }

  return context;
};

export const ExecutiveSummaryUiProvider = ({ report, ...props }) => {
  const { uiState, subjectCommentId, isEmptyExecutiveSummary } = useUiState(report.executiveSummary);

  const { onEditExecutiveSummary, onSaveExecutiveSummary, onFinalizeExecutiveSummary } = useExecutiveSummary(report.id);

  const {
    comments,
    onDeleteComment,
    deletingCommentIndex,
    deletingComment,
    onConfirmDeleteComment,
    onCancelDeleteComment,
    onReorderComment,
    onAddComment,
    editingCommentIndex,
    editingComment,
    onEditComment,
    onSaveComment,
    onFinalizeComment,
  } = useComments(report.id);

  const value = {
    report,

    comments,

    uiState,
    subjectCommentId,

    isEmptyExecutiveSummary,

    onEditExecutiveSummary,
    onSaveExecutiveSummary,
    onFinalizeExecutiveSummary,

    onDeleteComment,
    deletingCommentIndex,
    deletingComment,
    onConfirmDeleteComment,
    onCancelDeleteComment,

    onReorderComment,

    onAddComment,
    editingCommentIndex,
    editingComment,
    onEditComment,
    onSaveComment,
    onFinalizeComment,
  };

  return (
    <ExecutiveSummaryUiContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useExecutiveSummaryUi;
