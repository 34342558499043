import React from 'react';

import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import * as R from 'ramda';

const ColourBox = styled(props => (
  <Box
    border={1}
    borderColor="grey.500"
    {...R.omit(['colour'], props)}
  />
))`
  background-color: ${({ theme, colour }) => theme.colours.scorecard[colour].background};
  color: ${({ theme, colour }) => theme.colours.scorecard[colour].text};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export default ColourBox;
