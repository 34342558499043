import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import UnstyledAlert from '@material-ui/lab/Alert';
import UnstyledPage from '../../../components/Page';
import UnstyledReportGenStepper from '../components/ReportGenStepper';
import elementTypePrefix from '../../../utils/element-type-prefix';
import { Report } from '../../../hooks/report';

const ReportGenStepper = styled(UnstyledReportGenStepper)``;

const Page = styled(UnstyledPage)`
  ${ReportGenStepper} {
    margin-bottom: 12px;
  }
`;

const Alert = styled(UnstyledAlert)`
  margin-bottom: 1%;
`;

interface Props {
  children: any;
  report: Report;
  comment?: any;
  pageTitle: string;
  currentStep: number;
  pages: any;
  onJumpTo(id: string): void;
}

const reportName = (report: Report) =>
  report ? `[${elementTypePrefix(report.elementType)}] ${report.elementName} (${report.facilityName})` : '';

const ReportGenContainer = ({ children, report, pageTitle, currentStep, pages, onJumpTo }: Props) => (
  <Page title={`${reportName(report)} - ${pageTitle}`}>
    <ReportGenStepper
      currentStep={currentStep}
      pages={pages}
      onJumpTo={onJumpTo}
    />
    <Paper square>
      {report && report.state === 'changes-requested' && <Alert severity="error">Changes Requested</Alert>}
      {report && report.state === 'unsubmitted' && <Alert severity="warning">Report Not Submitted</Alert>}
      {report && report.state === 'pending-review' && <Alert severity="success">Report Submitted</Alert>}
      {report && <>{children}</>}
    </Paper>
  </Page>
);

export default ReportGenContainer;
