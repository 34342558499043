import type { Path, RegisterOptions } from 'react-hook-form';
import { BlankCoding, Coding, CodingValue, WorkRequestCodingFormInputs } from '../../types';
import {
  validateBtuValue,
  validateCaaNumberValue,
  validateCrewValue,
  validateOutageCodeValue,
  validatePlanningCentreValue,
  validatePlanningGroupValue,
  validatePriorityValue,
  validateProjectNumberValue,
  validateScheduleBacklogValue,
  validateSpmValue,
  validateUcrValue,
  validateWoAcknowledgeValue,
  validateWorkCategoryValue,
  validateWorkTypeValue,
} from './validateCategoryValue';

export const handleValidateCategoryValue = <T extends (Coding | BlankCoding)['category']>(
  category: T,
  value: CodingValue<Coding['category']>,
  formValues: WorkRequestCodingFormInputs,
  crews: string[]
) => {
  switch (category) {
    case 'btu':
      return validateBtuValue(value);
    case 'caaNumber':
      return validateCaaNumberValue(value);
    case 'crew':
      return validateCrewValue(value, crews);
    case 'outageCode':
      return validateOutageCodeValue(value);
    case 'planningCentre':
      return validatePlanningCentreValue(value);
    case 'planningGroup':
      return validatePlanningGroupValue(value, formValues);
    case 'priority':
      return validatePriorityValue(value);
    case 'projectNumber':
      return validateProjectNumberValue(value);
    case 'scheduleBacklog':
      return validateScheduleBacklogValue(value);
    case 'spm':
      return validateSpmValue(value);
    case 'ucr':
      return validateUcrValue(value);
    case 'woAcknowledge':
      return validateWoAcknowledgeValue(value);
    case 'workCategory':
      return validateWorkCategoryValue(value);
    case 'workType':
      return validateWorkTypeValue(value);
    default:
      return false;
  }
};

/**
 * Generates the deps array for a given CodingCategory input
 *
 * @param index index of category input (i.e. `codings.${index}.category`)
 * @param codingCount number of codings
 * @returns {RegisterOptions['deps']}
 */
export const buildCategoryValidationDeps = (
  index: number,
  codingCount: number
): RegisterOptions<WorkRequestCodingFormInputs>['deps'] => {
  if (index < 0 || codingCount <= 0 || index >= codingCount) {
    // Invalid input
    return undefined;
  }

  const categoryInputs = Array.from({ length: codingCount }).map(
    (_, i) => `codings.${i}.category` as Path<WorkRequestCodingFormInputs>
  );
  // Remove the current index, so the input doesn't depend on itself
  categoryInputs.splice(index, 1);
  return [`codings.${index}.value`, ...categoryInputs];
};
