import React from 'react';
import styled from 'styled-components';
import { Link as UnstyledLink } from 'react-router-dom';

const StyledLink = styled(UnstyledLink)`
  text-transform: uppercase;
  font: ${({ theme }) => theme.fonts.bold11};
  color: ${({ theme }) => theme.colours.greys.darkGrey4};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Link = ({ to, label }) => <StyledLink to={to}>{label}</StyledLink>;

export default Link;
