import { useState, useEffect } from 'react';
import api from '../api';

const useRiskOwnersGroup = (elementId: number) => {
  const [riskOwnersGroup, setRiskOwnersGroup] = useState([]);

  useEffect(() => {
    void loadRiskOwnersGroup();
  }, [elementId]);

  const loadRiskOwnersGroup = async () => {
    const response = await api.getRiskOwnersGroup(elementId);
    setRiskOwnersGroup(response);
  };

  const setRiskOwner = async (userId: string) => {
    await api.setRiskOwner(elementId, userId);
    loadRiskOwnersGroup();
  };

  const clearRiskOwner = async (userId: string) => {
    await api.clearRiskOwner(elementId, userId);
    loadRiskOwnersGroup();
  };

  return {
    riskOwnersGroup,
    clearRiskOwner,
    setRiskOwner,
  };
};

export default useRiskOwnersGroup;
