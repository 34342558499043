import React from 'react';
import { useRiskApproverForm } from '../hooks/useRiskApproverForm';
import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlTextField } from '../../../../form/ControlTextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { UppercaseTitle } from './UppercaseTitle';
import ActionButton from '../../../../components/ActionButton';
import { ControlAutocomplete } from '../../../../form/ControlAutocomplete';
import useUsers from '../../../../hooks/users';
import { User } from '../../../../types/user';

type Props = {
  riskManagementId: number;
};

export const RiskApproverForm = ({ riskManagementId }: Props) => {
  const {
    loading,
    control,
    handleSubmit,
    errors,
    decisionOptions,
    yesOrNoOptions,
    watchDecisionType,
    watchFlagToCno,
    watchEscalateIssue,
    isReadOnly,
  } = useRiskApproverForm(riskManagementId);
  const { users }: { users: User[] } = useUsers();

  return (
    <Box margin="40px">
      <Box paddingBottom="20px">
        <UppercaseTitle>General Information</UppercaseTitle>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <ControlRadio
              control={control}
              error={!!errors.escalateIssue}
              name="escalateIssue"
              label="Want to escalate issue?"
              required={true}
              options={yesOrNoOptions}
              disabled={isReadOnly}
              row
            />
          </Grid>
          {watchEscalateIssue === 'yes' && (
            <Grid
              item
              xs={12}
            >
              <ControlAutocomplete
                control={control}
                name="escalateUsers"
                options={users.map((user: User) => ({ label: user.name, value: user.id }))}
                error={!!errors.escalateUsers}
                label="Who do you want to escalate to?"
                required={watchEscalateIssue === 'yes'}
                disabled={isReadOnly}
                multiple={true}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <ControlRadio
              control={control}
              required={true}
              label="Decision Type"
              name="decisionType"
              options={decisionOptions}
              error={!!errors.decisionType}
              disabled={isReadOnly}
              row
            />
          </Grid>
          {(watchDecisionType === 'rejectAndClose' ||
            watchDecisionType === 'rejectAndResubmit' ||
            watchDecisionType === 'acceptAndClose') && (
            <Grid
              item
              xs={12}
            >
              <ControlTextField
                control={control}
                required={
                  watchDecisionType === 'rejectAndClose' ||
                  watchDecisionType === 'rejectAndResubmit' ||
                  watchDecisionType === 'acceptAndClose'
                }
                name="decisionTypeDescription"
                error={!!errors.decisionTypeDescription}
                label="Decision Type Description"
                disabled={isReadOnly}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <ControlRadio
              control={control}
              required={false}
              label="Flag to CNO"
              name="flagToCno"
              options={yesOrNoOptions}
              error={!!errors.flagToCno}
              disabled={isReadOnly}
              row
            />
          </Grid>
          {watchFlagToCno === 'yes' && (
            <>
              <Grid
                item
                xs={12}
              >
                <ControlTextField
                  control={control}
                  required={true}
                  name="cnoDescription"
                  error={!!errors.cnoDescription}
                  maxLength={3000}
                  multiline={true}
                  minRows={6}
                  label="CNO Short Risk Description"
                  disabled={isReadOnly}
                  placeholder="Required (Character Limitation: 3000 characters)"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <ControlTextField
                  control={control}
                  required={true}
                  name="cnoBridgingStrategy"
                  error={!!errors.cnoBridgingStrategy}
                  maxLength={3000}
                  multiline={true}
                  minRows={6}
                  label="CNO Short Risk Bridging Strategy"
                  disabled={isReadOnly}
                  placeholder="Required (Character Limitation: 3000 characters)"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <ControlTextField
                  control={control}
                  required={true}
                  name="cnoStatusUpdate"
                  error={!!errors.cnoStatusUpdate}
                  maxLength={3000}
                  multiline={true}
                  minRows={6}
                  label="CNO Short Risk Status Update"
                  disabled={isReadOnly}
                  placeholder="Required (Character Limitation: 3000 characters)"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <ControlTextField
                  control={control}
                  required={true}
                  name="riskResponseSummary"
                  error={!!errors.riskResponseSummary}
                  maxLength={3000}
                  multiline={true}
                  minRows={6}
                  label="CNO Risk Response Summary"
                  disabled={isReadOnly}
                  placeholder="Required (Character Limitation: 3000 characters)"
                />
              </Grid>
            </>
          )}
          <Box marginTop="20px">
            <ActionButton
              color="primary"
              type="submit"
              aria-label="submit"
              disabled={isReadOnly || loading}
            >
              Save
            </ActionButton>
          </Box>
        </Grid>
      </form>
    </Box>
  );
};
