import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import * as uiActions from '../../../state/ui/actions';
import { ExtendedWorkRequest } from '../../../types/cwt/workrequest';
import { downloadBlob } from '../utils';
import { TabState } from '../wr/types';
import { useUsers } from './useUsers';

interface UseWorkRequestsValue {
  state: TabState | undefined;
  setState: React.Dispatch<React.SetStateAction<TabState | undefined>>;
  workRequests: ExtendedWorkRequest[];
  loading: boolean;
  exportCSV: () => Promise<void>;
  selectedForExport: string[];
  handleSelectForExport: (workRequestNumber: string, selected: boolean) => void;
  handleSelectAll: (selected: boolean) => void;
}

const WorkRequestsContext = createContext<UseWorkRequestsValue | undefined>(undefined);

interface WorkRequestsProviderProps extends React.PropsWithChildren {
  initialState?: TabState;
}

const useWorkRequests = () => {
  const context = useContext(WorkRequestsContext);
  if (context === undefined) {
    throw new Error('useWorkRequests must be used within a WorkRequestsProvider');
  }
  return context;
};

export const WorkRequestsProvider = ({ initialState, children }: WorkRequestsProviderProps) => {
  const [state, setState] = useState<TabState | undefined>(initialState);
  const [loading, setLoading] = useState(false);
  const [workRequests, setWorkRequests] = useState<ExtendedWorkRequest[]>([]);
  const [selectedForExport, setSelectedForExport] = useState<string[]>([]);
  const { userSite } = useUsers();
  const dispatch = useDispatch();

  // Refetch if user changes tab or site selection
  useEffect(() => {
    const fetchWorkRequests = async () => {
      setLoading(true);

      const response = await api.cwt.getAllWorkRequests(state);
      setWorkRequests(response);

      setLoading(false);
    };
    if (state) {
      void fetchWorkRequests();
    }
  }, [state, userSite]);

  const handleSelectForExport = (workRequestNumber: string, selected: boolean) => {
    if (selected) {
      setSelectedForExport(value => [...value, workRequestNumber]);
    } else {
      setSelectedForExport(value => value.filter(item => item !== workRequestNumber));
    }
  };

  const handleSelectAll = (selected: boolean) => {
    const workRequestNumbers = workRequests.map(workRequest => workRequest.workRequestNumber);
    if (selected) {
      // Keep the previous selection and add all work requests in the current view
      setSelectedForExport(value => {
        const missingElements = workRequestNumbers.filter(
          workRequestNumber => !selectedForExport.includes(workRequestNumber)
        );
        return [...value, ...missingElements];
      });
    } else {
      // Deselect only the work requests in the current view
      setSelectedForExport(value => value.filter(item => !workRequestNumbers.includes(item)));
    }
  };

  // CSV Export
  const exportCSV = async () => {
    try {
      if (selectedForExport.length) {
        const response = await api.cwt.exportWorkRequestCodings(selectedForExport);
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        downloadBlob(blob, `work_request_codings-${new Date().toISOString()}`);
      } else {
        dispatch(uiActions.error('', 'Please select one or more rows to export.'));
      }
    } catch (error) {
      console.error(error);
      dispatch(uiActions.error('', 'Export failed. Please try again.'));
    }
  };

  const value = useMemo(
    () => ({
      state,
      setState,
      workRequests,
      loading,
      exportCSV,
      selectedForExport,
      handleSelectForExport,
      handleSelectAll,
    }),
    [workRequests, loading, exportCSV, selectedForExport, handleSelectForExport, handleSelectAll]
  );

  return <WorkRequestsContext.Provider value={value}>{children}</WorkRequestsContext.Provider>;
};

export default useWorkRequests;
