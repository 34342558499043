import React, { useEffect, useState } from 'react';

import tableColumns from './table-columns';
import ReorderableActionTable from './ReorderableActionTable';
import StaticActionTable from './StaticActionTable';
import Api from '../../api';
interface MinActionTableProps {
  reorderable?: any;
  state?: string;
  onChangeStatus?: any;
  onEdit?: any;
  onDelete?: any;
  element?: any;
  completionNotes?: boolean;
}

type ActionTableProps = {
  completionNotes?: boolean;
  actions: any[];
  reorderable?: boolean;
  reorderRows?: any;
  className?: string;
} & MinActionTableProps;

const ActionTable = ({
  className,
  completionNotes = true,
  element,
  onChangeStatus,
  onDelete,
  onEdit,
  reorderable,
  reorderRows,
  state = '',
  ...props
}: ActionTableProps) => {
  const columnOptions = {
    showSHFR: element?.elementType !== 'program',
    state,
  };

  const [risks, setRisks] = useState<string[]>([]);

  useEffect(() => {
    const fetchRisks = async () => {
      const res = await Api.getAllRisks(element?.elementId);
      setRisks(res.risks.map(({ riskManagementId }: any) => riskManagementId.toString()));
    };
    if (element?.elementId) {
      fetchRisks();
    }
  }, [element?.elementId]);

  const allColumns = tableColumns(state, onEdit, onChangeStatus, onDelete, risks, columnOptions);

  const columns = completionNotes ? allColumns : allColumns.filter(column => column.label !== 'Completion Notes');

  return reorderable ? (
    <ReorderableActionTable
      columns={columns}
      reorderRows={reorderRows}
      className={className}
      {...props}
    />
  ) : (
    <StaticActionTable
      columns={columns}
      className={className}
      {...props}
    />
  );
};

export default ActionTable;
