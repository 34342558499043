import { Coding, CodingValue, WorkRequestCodingFormInputs } from '../../types';
import { PLANNING_CENTRES, SCHEDULE_BACKLOG_VALUES, UCR_VALUES, WORK_CATEGORIES, WORK_TYPES } from '../constants';
import { getPlanningGroupValue } from '..';

export const validateBtuValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && ['Y', 'N'].includes(value);
};

export const validateCaaNumberValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && value === '00000';
};

export const validateCrewValue = (value: CodingValue<Coding['category']>, crews: string[]) => {
  return typeof value === 'string' && crews.includes(value);
};

export const validateOutageCodeValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && /^[AB]\d{4}$/.test(value);
};

export const validatePlanningCentreValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && PLANNING_CENTRES.includes(value as (typeof PLANNING_CENTRES)[number]);
};

export const validatePlanningGroupValue = (
  value: CodingValue<Coding['category']>,
  formValues: WorkRequestCodingFormInputs
) => {
  const { codings } = formValues;
  const crew = codings.find((coding): coding is Coding<'crew'> => coding.category === 'crew')?.value ?? '';
  const planningCentre =
    codings.find((coding): coding is Coding<'planningCentre'> => coding.category === 'planningCentre')?.value ?? '';
  const expectedPlanningGroup = getPlanningGroupValue(crew, planningCentre);
  // If we can derive a planning group from crew & planning centre, check that given value matches.
  // Otherwise, any string is valid.
  return expectedPlanningGroup ? expectedPlanningGroup === value : typeof value === 'string';
};

export const validatePriorityValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'number' && value >= 1 && value <= 4;
};

export const validateProjectNumberValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && /^\d{5}$/i.test(value);
};

export const validateScheduleBacklogValue = (value: CodingValue<Coding['category']>) => {
  return (
    typeof value === 'string' && SCHEDULE_BACKLOG_VALUES.includes(value as (typeof SCHEDULE_BACKLOG_VALUES)[number])
  );
};

export const validateSpmValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'number' && value >= 5 && value <= 100;
};

export const validateUcrValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && UCR_VALUES.includes(value as (typeof UCR_VALUES)[number]);
};

export const validateWoAcknowledgeValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'number' && value >= 0 && value <= 4;
};

export const validateWorkCategoryValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && WORK_CATEGORIES.includes(value as (typeof WORK_CATEGORIES)[number]);
};

export const validateWorkTypeValue = (value: CodingValue<Coding['category']>) => {
  return typeof value === 'string' && WORK_TYPES.includes(value as (typeof WORK_TYPES)[number]);
};
