import styled from 'styled-components';
import MuiFormLabel from '@material-ui/core/FormLabel';

const CustomFormLabel = styled(MuiFormLabel).attrs(props => ({
  component: props.component || 'legend',
}))`
  padding: 0px;
  color: ${({ theme }) => theme.colours.greys.darkGrey3};
  font: ${({ theme }) => theme.fonts.medium16};
`;

export default CustomFormLabel;
