import { getPlanningGroupValue } from '.';
import { CATEGORY_LABELS } from '../constants';
import { Coding, CodingOfSameType, CodingValue } from '../types';
import { OVERLAPPING_WORK_CATEGORY_TYPE_VALUES } from './constants';
import {
  validateCaaNumberValue,
  validateCrewValue,
  validateOutageCodeValue,
  validatePlanningCentreValue,
  validatePriorityValue,
  validateProjectNumberValue,
  validateScheduleBacklogValue,
  validateSpmValue,
  validateUcrValue,
  validateWoAcknowledgeValue,
  validateWorkCategoryValue,
  validateWorkTypeValue,
} from './validate/validateCategoryValue';

export const parseInputValues = (input: string) => {
  return (
    input
      // Input coding values are separated by line breaks
      .split('\n')
      // For good measure, also remove leading & trailing whitespace characters
      .map(value => value.trim())
      // and remove any empty entries
      .filter(value => !!value)
  );
};

export const handleNumericValue = (numericValue: number, existingCodings: Coding[]): Coding | null => {
  const isValidWoAcknowledgeValue = validateWoAcknowledgeValue(numericValue);
  const isValidPriorityValue = validatePriorityValue(numericValue);
  if (isValidWoAcknowledgeValue && isValidPriorityValue) {
    // This could be either WO Acknowledge or Priority
    return handleOverlappingValue('woAcknowledge', 'priority', numericValue, existingCodings);
  } else if (isValidWoAcknowledgeValue) {
    return { category: 'woAcknowledge', value: numericValue };
  } else if (isValidPriorityValue) {
    return { category: 'priority', value: numericValue };
  } else if (validateSpmValue(numericValue)) {
    return { category: 'spm', value: numericValue };
  }
  return null;
};

export const handleOverlappingValue = <P extends Coding['category'], S extends CodingOfSameType<P>>(
  primaryCategory: P,
  secondaryCategory: S,
  value: CodingValue<P> | CodingValue<S>,
  existingCodings: Coding[]
): Coding<P> | Coding<S> => {
  // Assume that a value for primaryCategory is specified first.
  // If no existing value for primaryCategory, then treat this value as primaryCategory; otherwise, a primaryCategory
  // value was already specified, so treat this value as secondaryCategory.
  if (!existingCodings.find(element => element.category === primaryCategory)) {
    return { category: primaryCategory, value } as Coding<P>;
  }
  return { category: secondaryCategory, value } as Coding<S>;
};

export const buildCodingFromValue = (rawValue: string, existingCodings: Coding[], crews: string[]): Coding | null => {
  // All codings are case-insensitive
  const value = rawValue.toUpperCase();

  if (validateUcrValue(value)) {
    return { category: 'ucr', value };
  } else if (validateOutageCodeValue(value)) {
    return { category: 'outageCode', value };
  } else if (validateCaaNumberValue(value)) {
    // These values should remain strings to preserve any leading 0s
    return { category: 'caaNumber', value };
  } else if (validateProjectNumberValue(value)) {
    // These values should remain strings to preserve any leading 0s
    return { category: 'projectNumber', value };
  } else if (/^\d+$/i.test(value)) {
    // Note: this will match any value with leading 0s (e.g. '0004' -> 4)
    const numericValue = Number(value);
    return handleNumericValue(numericValue, existingCodings);
  } else if (value === 'BTU') {
    // Note: the parsing logic for BTU is different for auto-generating codings than the form validation logic.
    return { category: 'btu', value: 'Y' };
  } else if (validateCrewValue(value, crews)) {
    return { category: 'crew', value };
  } else if (validateScheduleBacklogValue(value)) {
    return { category: 'scheduleBacklog', value };
  } else if (validatePlanningCentreValue(value)) {
    return { category: 'planningCentre', value };
  } else if (
    OVERLAPPING_WORK_CATEGORY_TYPE_VALUES.includes(value as (typeof OVERLAPPING_WORK_CATEGORY_TYPE_VALUES)[number])
  ) {
    // This could be either Work Type or Work Category. These overlapping values must be checked first.
    return handleOverlappingValue('workType', 'workCategory', value, existingCodings);
  } else if (validateWorkTypeValue(value)) {
    return { category: 'workType', value };
  } else if (validateWorkCategoryValue(value)) {
    return { category: 'workCategory', value };
  }
  return null;
};

export const generateCodings = (input: string, crews: string[]): Coding[] => {
  const codings: Coding[] = [];

  // Step 1: separate input string into distinct input values
  const inputValues = parseInputValues(input);

  // Step 2: build all of the codings which can be derived from user input directly
  inputValues.forEach(value => {
    const coding = buildCodingFromValue(value, codings, crews);
    if (coding) {
      if (codings.find(element => element.category === coding.category)) {
        throw new Error(`Duplicate value identified for category ${CATEGORY_LABELS[coding.category]}`);
      }
      codings.push(coding);
    } else {
      console.warn(`Ignoring invalid input value ${value}`);
    }
  });

  // Step 3: apply additional business logic

  // Set Planning Group based on Crew and Planning Centre values
  const crew = codings.find((element): element is Coding<'crew'> => element.category === 'crew')?.value ?? '';
  const planningCentre =
    codings.find((element): element is Coding<'planningCentre'> => element.category === 'planningCentre')?.value ?? '';
  const planningGroup = getPlanningGroupValue(crew, planningCentre);
  if (planningGroup) {
    codings.push({ category: 'planningGroup', value: planningGroup });
  } else if (crew && planningCentre) {
    console.warn(`No Planning Group found for Crew ${crew} and Planning Centre ${planningCentre}`);
  }

  // If BTU is not included, then add it with value of "N"
  if (!codings.find((element): element is Coding<'btu'> => element.category === 'btu')) {
    codings.push({ category: 'btu', value: 'N' });
  }

  // Mark all generated codings as unlocked by default
  return codings.map(coding => ({ ...coding, locked: false }));
};
