import { AutocompleteOption } from '../../components/AutocompleteInput';
import { ActionType, ReferenceType } from '../../types/outages';

export const ACTION_TYPE_OPTIONS: AutocompleteOption<ActionType>[] = [
  { label: 'Mitigation', value: 'mitigation' },
  { label: 'Elimination', value: 'elimination' },
  { label: 'Cause Investigation', value: 'causeInvestigation' },
];

export const REFERENCE_TYPE_OPTIONS: AutocompleteOption<ReferenceType>[] = [
  { label: 'AMOT', value: 'amot' },
  { label: 'DCR', value: 'dcr' },
  { label: 'Engineering Change', value: 'engineeringChange' },
  { label: 'JPCR', value: 'jpcr' },
  { label: 'Legacy', value: 'legacy' },
  { label: 'MCR', value: 'mcr' },
  { label: 'PM', value: 'pm' },
  { label: 'PMCR', value: 'pmcr' },
  { label: 'Project', value: 'project' },
  { label: 'Racer', value: 'racer' },
  { label: 'SCR Activity', value: 'scrActivity' },
  { label: 'Work Order', value: 'workOrder' },
  { label: 'Work Request', value: 'workRequest' },
];

export type TabState = 'overview' | 'details' | 'management';
