/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UnstyledCheckIcon from '@material-ui/icons/Check';
import UnstyledClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Link from '../Link';
import useIndicatorRecordId from '../../hooks/indicator-record-id';
import IndicatorRecordTableWithExtraColumns from './IndicatorRecordTableWithExtraColumns';
import CustomTooltip from '../Tooltip';
import { Box, Grid, Modal } from '@material-ui/core';
import DetailModal from './DetailModal';

const CheckIcon = styled(UnstyledCheckIcon)`
  color: ${({ theme }) => theme.colours.greens.green2};
`;

const ClearIcon = styled(UnstyledClearIcon)`
  color: ${({ theme }) => theme.colours.reds.red3};
`;

const ActionGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const FF_ED_WHITELIST = ['comp-functional-failures', 'comp-equipment-defects'];

/* eslint-disable react/destructuring-assignment */
const evaluatedExtraColumns = (activeIndicator: string, indicatorRecordId: (record: any) => string) => [
  {
    id: 'update',
    width: 142,
    render: (record: any) => (
      <Link
        to={`/app/record-details?elementId=${
          record.elementId
        }&indicatorId=${activeIndicator}&recordId=${indicatorRecordId(record)}`}
        label="Update"
      />
    ),
  },
];

const unevaluatedExtraColumns = (
  indicatorShortName: string,
  indicatorRecordId: (record: any) => string,
  onApply: (recordId: string, recordType?: string) => void,
  onDismiss: (recordId: string) => void,
  setOpen: any,
  setSelectedRecord: any
): Array<any> => [
  {
    id: 'apply',
    label: 'Apply',
    width: 87,
    align: 'center',
    render: (record: any) => (
      <ActionGroup>
        {FF_ED_WHITELIST.includes(indicatorShortName) ? (
          <>
            {onApply && (
              <CustomTooltip title="FF">
                <IconButton
                  size="small"
                  onClick={() => {
                    onApply(indicatorRecordId(record), 'comp-functional-failures');
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </CustomTooltip>
            )}
            {onApply && (
              <CustomTooltip title="ED">
                <IconButton
                  size="small"
                  onClick={() => {
                    onApply(indicatorRecordId(record), 'comp-equipment-defects');
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </CustomTooltip>
            )}
            {onDismiss && (
              <CustomTooltip title="None">
                <IconButton
                  size="small"
                  onClick={() => {
                    onDismiss(indicatorRecordId(record));
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </CustomTooltip>
            )}
          </>
        ) : (
          <>
            {onApply && (
              <IconButton
                size="small"
                onClick={() => {
                  onApply(indicatorRecordId(record));
                }}
              >
                <CheckIcon />
              </IconButton>
            )}
            {onDismiss && (
              <IconButton
                size="small"
                onClick={() => {
                  onDismiss(indicatorRecordId(record));
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </>
        )}
      </ActionGroup>
    ),
  },
  {
    id: 'details',
    width: 55,
    render: (record: any) => (
      <IconButton
        onClick={() => {
          setSelectedRecord(record);
          setOpen(true);
        }}
      >
        <body
          style={{
            fontSize: 10,
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          Details
        </body>
      </IconButton>
    ),
  },
];

interface Props {
  indicatorId: string;
  indicatorShortName: string;
  items: any[];
  readonly?: boolean;
  evaluated?: boolean;
  onApply?: any;
  onDismiss?: any;
  noPromptApply?: any;
  onApplicable?: any;
}

const IndicatorRecordTable = ({
  indicatorId,
  indicatorShortName,
  items,
  readonly = false,
  evaluated = false,
  onApply = () => null,
  onDismiss = () => null,
  onApplicable = () => null,
  noPromptApply = () => null,
}: Props) => {
  const indicatorRecordId = useIndicatorRecordId(indicatorShortName);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();

  // eslint-disable-next-line no-nested-ternary
  const extraColumns = readonly
    ? []
    : evaluated
    ? evaluatedExtraColumns(indicatorId, indicatorRecordId)
    : unevaluatedExtraColumns(indicatorShortName, indicatorRecordId, onApply, onDismiss, setOpen, setSelectedRecord);

  return (
    <div>
      <IndicatorRecordTableWithExtraColumns
        indicatorShortName={indicatorShortName}
        items={items}
        extraColumns={extraColumns}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {open && (
            <Grid
              item
              style={{ marginTop: '14%' }}
            >
              <DetailModal
                data={selectedRecord}
                indicatorId={indicatorId}
                indicatorShortName={indicatorShortName}
                noPromptApply={noPromptApply}
                onDismiss={onDismiss}
                onApplicable={onApplicable}
                setOpen={setOpen}
              />
            </Grid>
          )}
        </>
      </Modal>
    </div>
  );
};

export default IndicatorRecordTable;
