import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as actions from '../state/indicator-records/actions';
import * as selectors from '../state/indicator-records/selectors';

const canLoad = (elementId, indicatorId) =>
  !R.isNil(elementId) && !R.isEmpty(elementId) && !R.isNil(indicatorId) && !R.isEmpty(indicatorId);

const useIndicatorRecords = (elementId, indicatorId) => {
  const dispatch = useDispatch();

  const loadIndicatorRecords = useCallback(() => {
    if (canLoad(elementId, indicatorId)) {
      dispatch(actions.loadRequest(elementId, indicatorId));
    }
  }, [dispatch, elementId, indicatorId]);

  useEffect(() => {
    loadIndicatorRecords();
  }, [loadIndicatorRecords]);

  const records = useSelector(state => selectors.getRecords(state, elementId, indicatorId));
  const isLoading = useSelector(state => selectors.isLoading(state, elementId, indicatorId));

  return {
    records,
    isLoading,
  };
};

export default useIndicatorRecords;
