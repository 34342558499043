import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as totalScoreColourActions from './actions';

function* loadAllTotalScoreColours() {
  try {
    const totalScoreColours = yield call(Api.getTotalScoreColours);
    yield put(totalScoreColourActions.loadAllSuccess(totalScoreColours));
  } catch (e) {
    yield put(totalScoreColourActions.loadAllFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.TOTAL_SCORE_COLOURS_LOAD_ALL_REQUEST, loadAllTotalScoreColours);
}

export default root;
