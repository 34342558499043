import { DecisionTypeRiskApprover, RiskApproverFormInputs, USER_ROLES } from '../../../../types/risk-management';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import api from '../../../../api';
import { useLocation, useHistory } from 'react-router-dom';
import * as uiActions from '../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { ACCEPT_AND_KEEP_ACTIVE, ACCEPT_AND_CLOSE, REJECT_AND_RESUBMIT, REJECT_AND_CLOSE } from '../../constants';
import { useRiskManagementUsers } from '../../../../context/business-equipment-risk-admin';

type DecisionOptions = { value: DecisionTypeRiskApprover; label: string }[];

export const getDecisionOption = (option: DecisionTypeRiskApprover) => {
  switch (option) {
    case 'acceptAndMonitor':
      return ACCEPT_AND_KEEP_ACTIVE;
    case 'acceptAndClose':
      return ACCEPT_AND_CLOSE;
    case 'rejectAndResubmit':
      return REJECT_AND_RESUBMIT;
    case 'rejectAndClose':
      return REJECT_AND_CLOSE;
    default:
      return 'N/A';
  }
};

export type RiskApproverForm = {
  loading: boolean;
  control: Control<RiskApproverFormInputs>;
  handleSubmit: () => Promise<void>;
  errors: FieldErrors<RiskApproverFormInputs>;
  decisionOptions: DecisionOptions;
  yesOrNoOptions: { value: string; label: string }[];
  watchEscalateIssue: string;
  watchDecisionType: string | null;
  watchFlagToCno: string | null;
  isReadOnly: boolean;
};

const INITIAL_VALUES: RiskApproverFormInputs = {
  escalateIssue: 'no',
  escalateUsers: [],
  decisionType: null,
  decisionTypeDescription: '',
  flagToCno: null,
  cnoDescription: '',
  cnoBridgingStrategy: '',
  cnoStatusUpdate: '',
  riskResponseSummary: '',
};

export const useRiskApproverForm = (riskManagementId: number): RiskApproverForm => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const currentUserRoles = useRiskManagementUsers();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<RiskApproverFormInputs>({
    defaultValues: INITIAL_VALUES,
  });

  const onSubmit: SubmitHandler<RiskApproverFormInputs> = async ({ escalateUsers, ...data }) => {
    setLoading(true);
    try {
      await api.submitRiskApproverForm({ ...data, escalateUsers }, riskManagementId);
      switch (data.decisionType) {
        case 'acceptAndMonitor':
        case 'acceptAndClose':
          dispatch(uiActions.genericMessage('Risk Approved...'));
          break;
        case 'rejectAndResubmit':
        case 'rejectAndClose':
        default:
          dispatch(uiActions.genericMessage('Risk Rejected...'));
      }
      push('/app/business-equipment-risks/risk-review');
    } catch {
      dispatch(uiActions.error('', 'Error: unable to submit decision'));
    }
    setLoading(false);
  };
  const getRiskApproverForm = useCallback(async () => {
    if (!riskManagementId) return null;
    const risk: RiskApproverFormInputs = await api.getRiskApproverForm(riskManagementId);
    Object.keys(risk).forEach((key: string) => {
      setValue(key as keyof RiskApproverFormInputs, risk[key as keyof RiskApproverFormInputs]);
    });
    return;
  }, [riskManagementId]);

  useEffect(() => {
    if (pathname.includes('/archive')) {
      void getRiskApproverForm();
    } else {
      reset(INITIAL_VALUES);
    }
  }, [pathname, riskManagementId]);

  const decisionOptions: DecisionOptions = useMemo(() => {
    return [
      { value: 'acceptAndMonitor', label: ACCEPT_AND_KEEP_ACTIVE },
      { value: 'acceptAndClose', label: ACCEPT_AND_CLOSE },
      { value: 'rejectAndClose', label: REJECT_AND_CLOSE },
      { value: 'rejectAndResubmit', label: REJECT_AND_RESUBMIT },
    ];
  }, []);

  const yesOrNoOptions = useMemo(() => {
    return [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ];
  }, []);

  return {
    loading,
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    decisionOptions,
    yesOrNoOptions,
    watchEscalateIssue: watch('escalateIssue'),
    watchDecisionType: watch('decisionType'),
    watchFlagToCno: watch('flagToCno'),
    isReadOnly: pathname.includes('/archive') || !currentUserRoles.includes(USER_ROLES.APPROVER),
  };
};
