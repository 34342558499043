import React from 'react';
import { useParams } from 'react-router-dom';
import useReport from '../../../hooks/report';
import Page from '../../../components/Page';
import ReportReview from '../../../components/ReportReview';
import { Downloader } from '../../../hooks/downloader';

const ReportReviewPage = () => {
  const { reportId } = useParams();
  const { report } = useReport(reportId);

  return <Page title="Report Review">{report && <ReportReview report={report} />}</Page>;
};

const RRPage = () => (
  <Downloader>
    <ReportReviewPage />
  </Downloader>
);

export default RRPage;
