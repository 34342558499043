import * as actionTypes from '../action-types';

const initialState = {
  dashboards: [],
  myDashboards: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_DASHBOARDS_LOAD_SUCCESS: {
      const { dashboards } = action.payload;
      return {
        ...state,
        myDashboards: dashboards,
      };
    }
    case actionTypes.MY_DASHBOARDS_ADD_FAVOURITE_SUCCESS: {
      const { dashboardId } = action.payload;
      return {
        ...state,
        myDashboards: state.myDashboards.map(dashboard =>
          dashboard.id === dashboardId ? { ...dashboard, favourite: true } : dashboard
        ),
      };
    }
    case actionTypes.MY_DASHBOARDS_REMOVE_FAVOURITE_SUCCESS: {
      const { dashboardId } = action.payload;
      return {
        ...state,
        myDashboards: state.myDashboards.map(dashboard =>
          dashboard.id === dashboardId ? { ...dashboard, favourite: false } : dashboard
        ),
      };
    }
    case actionTypes.DASHBOARDS_LOAD_SUCCESS: {
      const { dashboards } = action.payload;
      return {
        ...state,
        dashboards,
      };
    }
    case actionTypes.DASHBOARDS_CREATE_SUCCESS: {
      const { dashboard } = action.payload;
      return {
        ...state,
        dashboards: [...state.dashboards, dashboard],
      };
    }
    case actionTypes.DASHBOARDS_DELETE_SUCCESS: {
      const { dashboardId } = action.payload;
      return {
        ...state,
        dashboards: state.dashboards.filter(dashboard => dashboard.id !== dashboardId),
      };
    }
    case actionTypes.DASHBOARDS_UPDATE_SUCCESS: {
      const { dashboard } = action.payload;
      return {
        ...state,
        dashboards: state.dashboards.map(item => (item.id === dashboard.id ? dashboard : item)),
      };
    }
    default:
      return {
        ...state,
      };
  }
};
