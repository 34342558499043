import React from 'react';
import styled from 'styled-components';
import UnstyledFormControlLabel from '@material-ui/core/FormControlLabel';
import UnstyledCheckbox from '@material-ui/core/Checkbox';

const FormControlLabel = styled(UnstyledFormControlLabel)``;

const Checkbox = styled(UnstyledCheckbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colours.teals.teal1};
  }
  &.Mui-checked:hover {
    background-color: ${({ theme }) => theme.colours.teals.teal4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colours.teals.teal5};
  }
`;

interface Props {
  className?: string;
  id?: string;
  label?: string;
  checked?: boolean;
  onChange?(): void;
}

const CheckboxField = ({ className, id, label, checked, onChange }: Props) => (
  <FormControlLabel
    className={className}
    control={
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
      />
    }
    label={label}
  />
);

export default CheckboxField;
