import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Report } from '../../../../../hooks/report';
import useReports from '../../../../../hooks/reports';
import useComments from './comments';
import * as reportsActions from '../../../../../state/reports/actions';

const stateList = ['unsubmitted', 'pending-review', 'changes-requested', 'approved'];

interface PreviousReportData {
  previousReport?: any;
  previousComments?: any;
  applyPreviousComments(): void;
}

const usePreviousReport = (report: Report): PreviousReportData => {
  const { reports } = useReports(stateList, true);
  const [previousReport, setPreviousReport]: [any, any] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const prev: any = reports.find((r: any) => r.elementId === report.elementId && r.id !== report.id);
    setPreviousReport(prev);
  }, [report.elementId, report.id, reports]);

  // getting and applying comments from the previous report
  const { comments } = useComments(previousReport?.id || report.id);

  // Delete all existing comments and add the old ones from the previous report
  const applyPreviousComments = () => {
    if (previousReport) {
      dispatch(reportsActions.loadPreviousReportCommentsRequest(report.id, previousReport.id));
      dispatch(reportsActions.loadReportCommentsRequest(report.id));
    }
  };

  return {
    previousReport,
    previousComments: comments,
    applyPreviousComments,
  };
};

export default usePreviousReport;
