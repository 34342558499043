import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const Container = styled(Grid)`
  .MuiGrid-item {
    padding-left: 8px;
    padding-right: 8px;
    border-right: 1px solid ${({ theme }) => theme.colours.greys.darkGrey5};
    font: ${({ theme }) => theme.fonts.medium14};
    color: ${({ theme }) => theme.colours.greys.darkGrey5};
  }

  .MuiGrid-item:first-child {
    padding-left: 0px;
  }

  .MuiGrid-item:last-child {
    border-right: none;
  }
`;

export const HighLevelDetail = styled(Grid).attrs({
  item: true,
})`
  display: inline-block;
`;

interface Props {
  className?: string;
  children: any;
}

const HighLevelDetails = ({ className, children }: Props) => (
  <Container
    container
    className={className}
  >
    {children}
  </Container>
);

export default HighLevelDetails;
