import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useEngineering } from '../../../../hooks/useEngineering';
import { format } from 'date-fns';
import { RenderMaximoTicketLink } from '../../utils';
import CWTToolTip from '../../../CWTToolTip';
import { useSupplyChain } from '../../../../hooks/useSupplyChain';
const gridStyle = { minWidth: '100%' };

const SupplyChainEngineeringHoldsTable = ({ style }: any) => {
  const { holds: engineeringHolds, loading: isEngineeringLoading } = useEngineering();
  const { holds: supplyChainHolds, loading: isSupplyChainLoading } = useSupplyChain();

  const columns = [
    {
      name: 'ticketid',
      header: 'Ticket ID',
      defaultWidth: 100,
      render: RenderMaximoTicketLink,
    },
    {
      name: 'group',
      header: 'Group',
      defaultWidth: 100,
    },

    {
      name: 'description',
      header: 'Description',
      defaultWidth: 150,
      defaultFlex: 1,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
    {
      name: 'status',
      header: 'Status',
      defaultWidth: 150,
    },
    {
      name: 'statusdate',
      header: 'Status Date',
      defaultWidth: 150,
      render: ({ value }: any) => (value ? format(new Date(value), 'ddMMMyyyy HH:mm').toUpperCase() : undefined),
    },
    {
      name: 'targetfinish',
      header: 'Target Finish',
      defaultWidth: 150,
      render: ({ value }: any) => (value ? format(new Date(value), 'ddMMMyyyy HHmm').toUpperCase() : undefined),
    },
  ];

  return (
    <ReactDataGrid
      idProperty="id"
      style={{ ...gridStyle, ...style }}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={[
        ...engineeringHolds.map(hold => ({ ...hold, group: 'ENG' })),
        ...supplyChainHolds.map(hold => ({ ...hold, group: 'SC' })),
      ]}
      rowHeight={null}
      loading={isSupplyChainLoading || isEngineeringLoading}
    />
  );
};

export default SupplyChainEngineeringHoldsTable;
