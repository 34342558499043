import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as R from 'ramda';
import Api from '../api';

const AllReferenceTypesContext = React.createContext();

const useAllReferenceTypes = () => {
  const context = useContext(AllReferenceTypesContext);
  if (R.isNil(context)) {
    throw new Error('useAllReferenceTypes must be used inside an AllReferenceTypesProvider');
  }
  return context;
};

export const AllReferenceTypesProvider = props => {
  const [referenceTypes, setReferenceTypes] = useState([]);

  useEffect(() => {
    Api.getReferenceTypes().then(setReferenceTypes);
  }, [setReferenceTypes]);

  const referenceTypesById = useMemo(
    () =>
      R.reduce(
        (acc, referenceType) => ({
          ...acc,
          [referenceType.id]: referenceType,
        }),
        {},
        referenceTypes
      ),
    [referenceTypes]
  );

  const value = {
    referenceTypes,
    referenceTypesById,
  };

  return (
    <AllReferenceTypesContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useAllReferenceTypes;
