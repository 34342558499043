import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

declare global {
  interface Window {
    echoConfig: {
      tenantId: string;
      clientId: string;
      redirectUri: string;
      scopes: string[];
      features: string[];
      instrumentationKey: string;
      forcedOutagesPowerBiReportId: string;
      forcedOutagesOverviewPageName: string;
      forcedOutagesDetailsPageName: string;
      forcedOutagesManagementPageName: string;
      peraPowerBiReportId: string;
      supplyChainPowerBiReportId: string;
      supplyChainPowerBiPageName: string;
      erPowerBiReportId: string;
      erPowerBiPageName: string;
      cwtWrDetailsReportId: string;
      cwtWrDetailsCompCatPageName: string;
      cwtWrDetailsWoDataPageName: string;
      cwtWrDetailsJpDataPageName: string;
    };
  }
}

const msalInstance = new PublicClientApplication({
  auth: {
    authority: `https://login.microsoftonline.com/${window.echoConfig.tenantId}`,
    clientId: window.echoConfig.clientId,
    redirectUri: window.echoConfig.redirectUri,

    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    loggerOptions: {
      loggerCallback: (_, message) => {
        console.log('[MSAL]', message);
      },
      logLevel: LogLevel.Warning,
      piiLoggingEnabled: false,
    },
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
});

export default msalInstance;
