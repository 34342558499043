import * as R from 'ramda';
import moment from 'moment';

import { Button, FormControl, Grid, IconButton, TextField } from '@material-ui/core';
import UnstyledInfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';

import DateInput from '../../../../../components/ActionDialog/DateInput';
import CustomTextField from '../../../../../components/CustomTextField';
import UserSelectListForm from '../../../../../components/UserSelectListForm';
import useForm from '../../../../../hooks/form';
import CustomTooltip from '../../../../../components/Tooltip';
import { DeferralRequest } from '../../../../../hooks/deferral-requests';
import elementTypePrefix from '../../../../../utils/element-type-prefix';
import useMyRecentAssignments from '../../../../../hooks/my-recent-assignments';
import useSelectedItem from '../../../../../hooks/selected-item';
import CustomFormLabel from '../../../../../components/CustomFormLabel';
import FilterSelectorMultiple from '../../../../../components/FilterSelectorDeferrals';

const InfoIcon = styled(UnstyledInfoIcon)`
  color: ${({ theme }) => theme.colours.blues.blue2};
`;

const formConfig = {
  targetDate: {
    mapValueFn: R.identity,
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Set a target date',
      },
      datetime: {
        message: 'Must be a valid date',
      },
    },
  },
  comment: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Reason for Request and Justification',
      },
    },
  },
  approver: {
    mapValueFn: R.identity,
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Choose an approver',
      },
    },
  },
  deferralLength: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Choose a deferral length',
      },
    },
  },
};

const initialValues = (deferral?: DeferralRequest) => ({
  comment: deferral?.comment || '',
  targetDate: deferral?.targetDate
    ? moment(new Date(deferral?.targetDate as any))
        .utc()
        .add(1, 'day')
        .toDate()
    : new Date(),
  approver: '',
  deferralLength: 'custom',
});

interface Props {
  elementName?: string;
  deferral?: DeferralRequest;
  onSave(values: any): void;
  onSelectElement(values: any): any;
}

const RequestDeferralForm = ({ elementName, onSelectElement, deferral, onSave }: Props) => {
  const { myRecentAssignments } = useMyRecentAssignments();

  const elementOptions = myRecentAssignments.map((element: any) => ({
    name: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
    value: element.elementId,
  }));

  const { selectItem: selectElement } = useSelectedItem(myRecentAssignments, 'elementId');

  const handleSave = (values: any) => {
    onSave({
      ...values,
      targetDate: moment(values.targetDate).format('YYYY-MM-DD'),
    });
  };

  const { propsForField, submit } = useForm(formConfig, initialValues(deferral), handleSave);

  const elementStyle = {
    marginTop: '0.5em',
    marginBottom: '1em',
  };

  return (
    <Grid container>
      <Grid
        container
        spacing={2}
        item
        xs={4}
        direction="column"
      >
        <Grid
          item
          container
          alignItems="center"
        >
          {!elementName && (
            <Grid
              item
              xs={10}
            >
              <CustomFormLabel>Select Element</CustomFormLabel>
              <FormControl
                fullWidth={true}
                style={elementStyle}
              >
                <FilterSelectorMultiple
                  disableEmpty={true}
                  multipleSelections={true}
                  onChange={(selectedElements: any) => {
                    onSelectElement(selectedElements);
                    selectElement(selectedElements);
                  }}
                  elementOptions={elementOptions}
                />
              </FormControl>
            </Grid>
          )}
          {elementName && (
            <Grid
              item
              xs={10}
            >
              <FormControl
                fullWidth={true}
                style={elementStyle}
              >
                <TextField
                  disabled
                  variant="outlined"
                  value={elementName}
                />
              </FormControl>
            </Grid>
          )}
          <Grid xs={10}>
            <UserSelectListForm
              fullWidth
              label="Select Approver"
              userId={'1'}
              {...propsForField('approver')}
            />
          </Grid>
          <Grid xs={1}>
            <CustomTooltip title="If request is for one quarter, then select your Section Manager. If it is for more than one quarter, select your Department Manager">
              <IconButton
                size="small"
                onClick={() => null}
              >
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ paddingRight: '18%' }}
        >
          <DateInput
            label="Target Date"
            headerText="Target Completion Date"
            disablePast
            {...propsForField('targetDate')}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={8}
      >
        <CustomTextField
          fullWidth
          multiline
          rows={20}
          label="Reason for Request and Justification"
          {...propsForField('comment')}
        />
      </Grid>
      <Button onClick={submit}>Submit</Button>
    </Grid>
  );
};

export default RequestDeferralForm;
