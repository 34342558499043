import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import * as R from 'ramda';
import CircularProgress from '@material-ui/core/CircularProgress';

import DownloadButton from '../../../../../components/DownloadButton';
import UnstyledTotalScoreBox from '../../../../../components/Scorecard/TotalScoreBox';
import useScorecard from '../../../../../hooks/scorecard';
import useReport from '../../../../../hooks/report';
import { ScorecardErrorText as ErrorMessage } from '../../../../../components/ErrorText';

interface Props {
  theReport: any;
}

const LoadingOverlay = styled(CircularProgress)``;
const BackgroundLoadingOverlay = styled.div``;

const TotalScoreBox = styled(UnstyledTotalScoreBox)`
  height: 33px;
  width: 107px;
  margin-left: 22px;
`;

const section = {
  height: '100%',
  paddingTop: 45,
};

const PastScore = ({ theReport }: Props) => {
  const { generateAndDownloadDraftReport } = useReport(theReport.id);
  const {
    scorecardDisplayable,
    scorecard: scorecardActual,
    scorecardErrorMessage,
    staleData: scordcardStaleDate,
    isLoading,
    isBackgroundLoading,
    BackgroundLoadingMessage,
  } = useScorecard({
    elementId: theReport.elementId,
    elementName: theReport.elementName,
    year: theReport.year,
    quarter: theReport.quarter,
  });
  const scorecard = scorecardDisplayable;

  const totalScore: any = R.path(['totals', 'score'], scorecard);
  const scoreColour: any = R.path(['totals', 'colour'], scorecard);

  return (
    <Grid
      xs={8}
      spacing={2}
      item
      container
      direction="row"
      alignItems="center"
      style={{
        justifyContent: 'flex-end',
      }}
    >
      {isBackgroundLoading && (
        <BackgroundLoadingOverlay>
          <BackgroundLoadingMessage />
        </BackgroundLoadingOverlay>
      )}
      {!isLoading ? (
        <Grid
          item
          style={section}
        >
          <ErrorMessage
            scorecardDisplayable={scorecard}
            scorecard={scorecardActual}
            staleData={scordcardStaleDate}
            scorecardErrorMessage={scorecardErrorMessage}
            noDataMessage="Please try to re-select the element or 'reload' the page from the browser."
          />
        </Grid>
      ) : null}
      {isLoading && <LoadingOverlay size={20} />}
      {totalScore && scoreColour && (
        <>
          <Grid item>Most Recent Report: {`Q${theReport.quarter} ${theReport.year}`}</Grid>
          <TotalScoreBox
            overridden={false}
            totalScore={totalScore}
            scoreColour={scoreColour}
          />
          <Grid item>
            <DownloadButton onClick={generateAndDownloadDraftReport} />
          </Grid>
        </>
      )}
      {isLoading && (
        <Grid
          item
          style={section}
        >
          {' '}
          Loading Previous Report
        </Grid>
      )}
    </Grid>
  );
};

export default PastScore;
