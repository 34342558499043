import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import Api from '../api';

const ManualIndicator = React.createContext();

const useManualIndicators = () => {
  const context = useContext(ManualIndicator);
  if (R.isNil(context)) {
    throw new Error('useManualIndicators must be used inside an ManualIndicatorProvider');
  }
  return context;
};

export const ManualIndicatorProvider = ({ ...props }) => {
  const [indicator, setIndicator] = useState([]);

  useEffect(() => {
    Api.getManualIndicators().then(setIndicator);
  }, [setIndicator]);

  return (
    <ManualIndicator.Provider
      value={indicator}
      {...props}
    />
  );
};

export default useManualIndicators;
