import React, { useMemo } from 'react';
import styled from 'styled-components';
import UnstyledTableContainer from '@material-ui/core/TableContainer';
import UnstyledTable from '@material-ui/core/Table';
import UnstyledTableHead from '@material-ui/core/TableHead';
import UnstyledTableRow from '@material-ui/core/TableRow';
import HeaderCell from './HeaderCell';
import { uniqueId } from 'lodash';

const TableContainer = styled(UnstyledTableContainer)``;

const Table = styled(UnstyledTable).attrs({
  padding: 'normal',
  stickyHeader: true,
})`
  table-layout: fixed;
  width: 100%;
`;

const TableRow = styled(UnstyledTableRow)``;

const TableHead = styled(UnstyledTableHead)`
  background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  opacity: 1;

  ${TableRow} {
    height: 51px;
  }
`;

const ActionTableRoot = ({ className, children, columns }) => {
  const columnHeaders = useMemo(
    () =>
      columns.map(column => (
        <HeaderCell
          key={className + column.id + column.label + 'header-cell' + uniqueId()}
          align={column.align}
          style={{ width: column.width, maxWidth: column.width }}
        >
          {column.label}
        </HeaderCell>
      )),
    [columns]
  );

  return (
    <TableContainer className={className}>
      <Table aria-label="stick table">
        <TableHead>
          <TableRow>{columnHeaders}</TableRow>
        </TableHead>
        {children}
      </Table>
    </TableContainer>
  );
};

export default ActionTableRoot;
