import React from 'react';
import * as R from 'ramda';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DetailHeader from './DetailHeader';
import HighLevelDetails, { HighLevelDetail } from './HighLevelDetails';
import AssignmentTable from './AssignmentTable';

import { dateToString } from '../../../../../../utils/date-format';

const crUrl = (number: any) =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=plusca&uniqueid=${number}`;

interface Props {
  conditionReport: any;
  dense?: boolean;
}

const ConditionReportDetails = ({ conditionReport, dense }: Props) => {
  const { crNumber, ticketuid, description, status, dueDate: dueDateString, assignments } = conditionReport;

  const dueDate = new Date(dueDateString);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item>
        <Box p={5}>
          <Grid
            container
            direction="column"
            spacing={1}
          >
            <Grid item>
              <DetailHeader>
                {R.isNil(ticketuid) ? (
                  `CR #${crNumber}`
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={crUrl(ticketuid)}
                  >{`CR #${crNumber}`}</a>
                )}
              </DetailHeader>
            </Grid>
            <Grid item>
              <HighLevelDetails>
                <HighLevelDetail>Subject: {description}</HighLevelDetail>
                <HighLevelDetail>Status: {status}</HighLevelDetail>
                <HighLevelDetail>Due Date: {dateToString(dueDate)}</HighLevelDetail>
              </HighLevelDetails>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {!dense && (
        <Grid item>
          <AssignmentTable assignments={assignments} />
        </Grid>
      )}
    </Grid>
  );
};

export default ConditionReportDetails;
