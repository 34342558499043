import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledSectionNavigator from './SectionNavigator';
import UnstyledReportPreview from './ReportPreview';
import ExecutiveSummaryContainer from './ExecutiveSummaryContainer';
import ScorecardContainer from './ScorecardContainer';
import ShipContainer from './ShipContainer';
import SupportingDataContainer from './SupportingDataContainer';

import useSectionNavigator from '../../hooks/section-navigator';
import { ElementIndicatorsProvider } from '../../hooks/element-indicators';
import UnstyledNavPaper from './NavPaper';
import elementTypePrefix from '../../utils/element-type-prefix';
import AnalysisDataContainer from './AnalysisDataContainer';
import ForecastContainer from './ForecastContainer';
import AttachmentsContainer from './AttachmentsContainer';
import { Report } from '../../hooks/report';
import ScorecardPdfViewer from './ScorecardPdfViewer';

const SectionNavigator = styled(UnstyledSectionNavigator)``;

const ReportPreview = styled(UnstyledReportPreview)``;

const Body = styled.div`
  display: flex;
  overflow: scroll;

  ${SectionNavigator} {
    margin-right: 12px;
    flex-basis: 250px;
    flex-shrink: 0;
  }

  ${ReportPreview} {
    flex-grow: 1;
  }
`;

const NavPaper = styled(UnstyledNavPaper)``;

const Container = styled.div``;

const reportType: any = {
  program: 'PHIP',
  component: 'CHIP',
  system: 'SHIP',
};

const BASE_SECTIONS = (elementType: string, state: string) => {
  const sections = [
    {
      id: 1,
      title: 'Executive Summary',
      Component: ExecutiveSummaryContainer,
    },
    {
      id: 2,
      title: 'Score Card',
      Component: state === 'approved' && elementType !== 'program' ? ScorecardPdfViewer : ScorecardContainer,
    },
    {
      id: 3,
      title: reportType[elementType],
      Component: ShipContainer,
    },
    {
      id: 4,
      title: 'Analysis Data',
      Component: AnalysisDataContainer,
    },
    {
      id: 5,
      title: 'Supporting Data',
      Component: SupportingDataContainer,
    },
    {
      id: 6,
      title: 'Attachments',
      Component: AttachmentsContainer,
    },
  ];

  if (elementType !== 'program') {
    sections.push({
      id: 0,
      title: 'Forecast',
      Component: ForecastContainer,
    });
  }

  return sections;
};

interface Props {
  report: Report;
  onPrimary?(): void;
  primaryText?: string;
  onSecondary?(): void;
  secondaryText?: string;
  className?: string;
}

const ReportReview = ({
  report,
  onPrimary = undefined,
  primaryText = undefined,
  onSecondary = undefined,
  secondaryText = undefined,
  className,
}: Props) => {
  const ELEMENT_SECTIONS = BASE_SECTIONS(report.elementType, report.state);
  const sections =
    report.elementType === 'program'
      ? R.remove(R.findIndex(R.propEq('title', 'Analysis Data'))(ELEMENT_SECTIONS), 1, ELEMENT_SECTIONS)
      : ELEMENT_SECTIONS;

  const { activeSection, gotoSection, gotoNextPage, gotoPreviousPage } = useSectionNavigator(sections);

  const ActiveSectionComponent = activeSection.Component;

  return (
    <Container className={className}>
      {!(R.isNil(onPrimary) && R.isNil(onSecondary)) && (
        <NavPaper
          primaryText={primaryText}
          onPrimary={onPrimary}
          secondaryText={secondaryText}
          onSecondary={onSecondary}
        />
      )}
      <Body>
        <SectionNavigator
          sections={sections}
          onSectionItemClick={gotoSection}
          currentSectionId={activeSection.id}
        />

        <ReportPreview
          onNext={gotoNextPage}
          onPrev={gotoPreviousPage}
          reportTitle={`[${elementTypePrefix(report.elementType)}] ${report.elementName} (${report.facilityName}) - ${
            report.year
          }-Q${report.quarter}`}
        >
          <ElementIndicatorsProvider elementId={report.elementId}>
            <ActiveSectionComponent
              reportId={report.id}
              report={report}
            />
          </ElementIndicatorsProvider>
        </ReportPreview>
      </Body>
    </Container>
  );
};

export default ReportReview;
