import React from 'react';
import styled from 'styled-components';
import UnstyledNavButton from './NavButton';

const NavButton = styled(UnstyledNavButton)``;

const Container = styled.div`
  ${NavButton}.active {
    background-color: ${({ theme }) => theme.colours.purples.purple2};
  }
`;

const SubNavItem = ({ className, to, iconComponent, label }) => (
  <Container className={className}>
    <NavButton
      className={className}
      to={to}
      iconComponent={iconComponent}
      label={label}
      subMenu
    />
  </Container>
);

export default SubNavItem;
