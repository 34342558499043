import * as R from 'ramda';

export const getReports = state => R.pathOr([], ['all', 'reports'], state.reports);
export const areReportsLoading = state => R.pathOr(false, ['all', 'isLoading'], state.reports);
export const getReportsError = state => R.path(['all', 'error'], state.reports);

export const getReport = (state, reportId) => R.path(['byId', reportId, 'report'], state.reports);
export const isReportLoading = (state, reportId) => R.pathOr(false, ['byId', reportId, 'isLoading'], state.reports);
export const isExecutiveSummarySaving = (state, reportId) =>
  R.pathOr(false, ['byId', reportId, 'isSavingExecutiveSummary'], state.reports);
export const getReportError = (state, reportId) => R.path(['byId', reportId, 'error'], state.reports);

export const getComments = (state, reportId) =>
  R.map(R.prop('comment'), R.pathOr([], ['comments', reportId, 'comments'], state.reports));
export const isCommentSaving = (state, reportId, index) =>
  R.compose(R.propOr(false, 'isSaving'), R.nth(index), R.pathOr([], ['comments', reportId, 'comments']))(state.reports);
export const getSavingCommentIndex = (state, reportId) =>
  R.findIndex(R.propEq('isSaving', true), R.pathOr([], ['comments', reportId, 'comments'], state.reports));
export const getCommentError = (state, reportId, index) =>
  R.compose(R.prop('error'), R.nth(index), R.pathOr([], ['comments', reportId, 'comments']))(state.reports);
export const areCommentsLoading = (state, reportId) =>
  R.pathOr(false, ['comments', reportId, 'isLoading'], state.reports);
export const getCommentsError = (state, reportId) => R.path(['comments', reportId, 'error'], state.reports);
