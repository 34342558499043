import * as R from 'ramda';
import isFeatureEnabled from '../../utils/feature-flags';
import * as actionTypes from '../action-types';

const initialState = {
  messages: [],
};

const addMessage = (state: any, message: string) => ({
  ...state,
  messages: R.append({ message }, state.messages),
});

const addErrorMessage = (state: any, errorMessage: string) => ({
  ...state,
  messages: R.append({ errorMessage }, state.messages),
});

const messageActionTypes = [
  actionTypes.DEFERRAL_REQUEST_SUCCESS,
  actionTypes.DEFERRAL_REQUEST_APPROVE_SUCCESS,
  actionTypes.DEFERRAL_REQUEST_REJECT_SUCCESS,
  actionTypes.DEFERRAL_REQUEST_DELETE_SUCCESS,
  actionTypes.DEFERRAL_REQUEST_RESUBMIT_SUCCESS,
  actionTypes.PROGRAM_INDICATOR_UPDATE_SUCCESS,
  actionTypes.INDICATOR_ADD_SUCCESS,
  actionTypes.INDICATOR_REMOVE_SUCCESS,
  actionTypes.INDICATOR_UPDATE_SUCCESS,
  actionTypes.INDICATOR_SUM_SUCCESS,
  actionTypes.ELEMENTS_ADD_ELEMENT_MAPPINGS_SUCCESS,
  actionTypes.ELEMENTS_REMOVE_ELEMENT_MAPPINGS_SUCCESS,
  actionTypes.REPORTS_SUBMIT_SUCCESS,
  actionTypes.REPORTS_APPROVE_SUCCESS,
  actionTypes.REPORTS_REVERSE_APPROVE_SUCCESS,
  actionTypes.REPORTS_REJECT_SUCCESS,
  actionTypes.DASHBOARD_INDICATOR_RECORD_APPLIED,
  actionTypes.DASHBOARD_INDICATOR_RECORD_REJECTED,
  actionTypes.RECORD_DETAILS_UPDATE_INDICATOR_RECORD_SUCCESS,
  actionTypes.ACTIONS_ACTION_CLOSED,
  actionTypes.ACTIONS_ACTION_REOPENED,
  actionTypes.ACTIONS_ACTION_DELETED,
  actionTypes.ACTIONS_UPDATE_SUCCESS,
  actionTypes.REPORTS_CLOSE_SUCCESS,
  actionTypes.UI_NOTES,
  actionTypes.UI_GENERIC_MESSAGE,
];

const errorMessageActionTypes = [
  actionTypes.ERROR,
  actionTypes.INDICATOR_ADD_FAILURE,
  actionTypes.INDICATOR_REMOVE_FAILURE,
  actionTypes.INDICATOR_UPDATE_FAILURE,
  actionTypes.INDICATOR_SUM_FAILURE,
  actionTypes.PROGRAM_INDICATOR_UPDATE_FAILURE,
  actionTypes.ELEMENTS_ADD_ELEMENT_MAPPINGS_FAILURE,
  actionTypes.ELEMENTS_REMOVE_ELEMENT_MAPPINGS_FAILURE,
  actionTypes.REPORTS_SUBMIT_FAILURE,
  actionTypes.REPORTS_REVERSE_APPROVE_FAILURE,
  actionTypes.REPORTS_APPROVE_FAILURE,
  actionTypes.REPORTS_REJECT_FAILURE,
  actionTypes.REPORTS_CLOSE_FAILURE,
  actionTypes.HEALTH_REPORT_START_REPORT_FAILURE,
  actionTypes.DEFERRAL_REQUEST_FAILURE,
  actionTypes.DEFERRAL_REQUEST_APPROVE_FAILURE,
  actionTypes.DEFERRAL_REQUEST_REJECT_FAILURE,
  actionTypes.DEFERRAL_REQUEST_DELETE_FAILURE,
  actionTypes.DEFERRAL_REQUEST_RESUBMIT_FAILURE,
];
const errorMessageActionTypesWithScorecard = [...errorMessageActionTypes, actionTypes.SCORECARD_PUBLISH_FAILURE];

const checkIfErrorActionType = (action: any) => {
  if (isFeatureEnabled('scorecardOptimizations')) {
    return R.includes(action.type, errorMessageActionTypesWithScorecard);
  }
  return R.includes(action.type, errorMessageActionTypes);
};
export default (state = initialState, action: any) => {
  if (R.includes(action.type, messageActionTypes)) {
    return addMessage(state, action.payload.message);
  }

  if (checkIfErrorActionType(action)) {
    return addErrorMessage(state, action.payload.errorMessage);
  }

  switch (action.type) {
    case actionTypes.UI_POP_MESSAGE: {
      return {
        ...state,
        messages: R.tail(state.messages),
      };
    }
    case actionTypes.UI_LOADING_SPINNER_TRUE: {
      return {
        ...state,
        loadingSpinner: true,
      };
    }
    case actionTypes.UI_LOADING_SPINNER_FALSE: {
      return {
        ...state,
        loadingSpinner: false,
      };
    }
    default:
      return state;
  }
};
