import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import UnstyledListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UnstyledElementTypeIcon from './ElementTypeIcon';
import { ElementId } from '../../../../types/app';

const ElementTypeIcon = styled(UnstyledElementTypeIcon)``;

const ListItemIcon = styled(UnstyledListItemIcon)`
  ${ElementTypeIcon} {
    width: 32px
    height: 32px;
  }
`;

interface ElementListItemProps {
  element: any;
  selectedElementId: ElementId;
  onSelectElementId(x: any): void;
}

const ElementListItem = ({ element, selectedElementId, onSelectElementId }: ElementListItemProps) => (
  <ListItem
    button
    selected={selectedElementId === element.id}
    onClick={onSelectElementId}
  >
    <ListItemIcon>
      <ElementTypeIcon type={element.type} />
    </ListItemIcon>
    <ListItemText
      primary={element.name}
      secondary={element.facilityName}
    />
  </ListItem>
);

interface ElementListProps {
  className?: string;
  elements: any[];
  selectedElementId: any;
  onSelectElementId(id: number): void;
}

const ElementList = ({ className, elements, selectedElementId, onSelectElementId }: ElementListProps) => {
  const items = elements.map(element => (
    <ElementListItem
      key={element.id}
      element={element}
      selectedElementId={selectedElementId}
      onSelectElementId={R.thunkify(onSelectElementId)(element.id)}
    />
  ));

  return <List className={className}>{items}</List>;
};

export default ElementList;
