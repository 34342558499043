import React, { useMemo } from 'react';
import styled from 'styled-components';
import UnstyledTableBody from '@material-ui/core/TableBody';
import ActionTableRoot from './ActionTableRoot';
import UnstyledDraggableRow from './DraggableRow';
import DroppableComponent from './DroppableComponent';

const DraggableRow = styled(UnstyledDraggableRow)``;

const TableBody = styled(UnstyledTableBody)`
  ${DraggableRow}:nth-child(2n) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

const ReorderableActionTable = ({ unstyled = false, className, actions, columns, reorderRows }) => {
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.source.index !== result.destination.index) {
      reorderRows(result.source.index, result.destination.index);
    }
  };

  const rows = useMemo(
    () =>
      actions.map((action, i) => (
        <DraggableRow
          key={action.id + i}
          action={action}
          rowNumber={i}
          columns={columns}
        />
      )),
    [actions.length, columns]
  );

  return (
    <ActionTableRoot
      className={className}
      columns={columns}
    >
      {unstyled ? (
        <UnstyledTableBody component={DroppableComponent(onDragEnd)}>{rows}</UnstyledTableBody>
      ) : (
        <TableBody component={DroppableComponent(onDragEnd)}>{rows}</TableBody>
      )}
    </ActionTableRoot>
  );
};

export default ReorderableActionTable;
