import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useReadOnly from '../hooks/read-only';

interface Props {
  className?: string;
  label: string;
  options: Array<{ id: number; value: string | number; label: string | number }>;
  defaultValue?: string;
  onChange(x: string): void;
  variant?: 'standard' | 'filled' | 'outlined';
  labelPosition?: 'top' | 'bottom' | 'none';
  style?: React.CSSProperties;
  value?: any;
  error?: boolean;
}

const Dropdown = ({
  className,
  label,
  options,
  defaultValue = '',
  onChange,
  variant = 'standard',
  labelPosition = 'bottom',
  style,
  value,
  error,
}: Props) => {
  const [type, setType] = useState(defaultValue || options[0]?.value || '');
  const handleChange = (event: any) => {
    setType(event.target.value);
    onChange(event.target.value);
  };
  const readOnly = useReadOnly();
  return (
    <FormControl>
      {labelPosition === 'top' && <FormHelperText>{label}</FormHelperText>}
      <Select
        className={className}
        style={style}
        disabled={readOnly}
        displayEmpty
        labelId="type"
        value={value || type}
        variant={variant || 'standard'}
        onChange={handleChange}
        label={label}
        error={error}
      >
        {options.map((item: { id: number; value: string | number; label: string | number }) => (
          <MenuItem
            key={item.id}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {labelPosition === 'bottom' && <FormHelperText>{label}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;
