import React from 'react';
import styled from 'styled-components';

import FilterButton from '../../../components/FilterButton';
import DashboardSearchField from './DashboardSearchField';
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 50px;
  padding-bottom: 10px;
  border-bottom: ${({ theme }) => theme.borders.medium.solid2};

  ${FilterButton} {
    margin-right: 33px;
  }
`;

const FilterButtons = ({ className, showFavourites, onChange, searchInput, onSearchChange }) => {
  const handleClick = shouldShowFavourites => () => {
    onChange(shouldShowFavourites);
  };

  const handleSearchChange = value => {
    onSearchChange(value);
  };

  return (
    <Container className={className}>
      <FilterButton
        isActive={!showFavourites}
        onClick={handleClick(false)}
      >
        All
      </FilterButton>
      <FilterButton
        isActive={showFavourites}
        onClick={handleClick(true)}
      >
        Favourites
      </FilterButton>
      <DashboardSearchField
        value={searchInput}
        onChange={handleSearchChange}
      />
    </Container>
  );
};

export default FilterButtons;
