import * as R from 'ramda';
import { ChangeLog } from '../../../types/cwt/changelog';
import { AutocompleteOption } from '../../../components/AutocompleteInput';

export const optionsBuilder = <LabelKey extends keyof ChangeLog, ValueKey extends keyof ChangeLog>(
  logs: ChangeLog[],
  labelProp: LabelKey,
  valueProp: ValueKey,
  labelFormatter: (v: ChangeLog[LabelKey]) => string = v => v?.toString() || '',
  valueFormatter: (v: ChangeLog[ValueKey]) => string = v => v?.toString() || ''
): AutocompleteOption[] => {
  return R.pipe(
    R.map((item: ChangeLog) => ({
      label: labelFormatter(item[labelProp]),
      value: valueFormatter(item[valueProp]),
    })),
    R.uniqBy(R.prop('value'))
  )(logs);
};
