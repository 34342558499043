import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { USER_ROLES, UserWithRoles } from '../types/risk-management';
import api from '../api';
import * as R from 'ramda';
import isFeatureEnabled from '../utils/feature-flags';

interface BusinessEquipmentRiskData {
  nonApprovalUsers: { label: string; value: string }[];
  nonEnvGroupUsers: { label: string; value: string }[];
  mcrContactUsers: UserWithRoles[];
  approvalUsers: UserWithRoles[];
  envGroupUsers: UserWithRoles[];
  envGroupManager: UserWithRoles | null;
  globalContactUsers: UserWithRoles[];
  allUsers: UserWithRoles[];
  submitUserRole: (userIds: string[], role: USER_ROLES) => Promise<void>;
  removeUserRole: (userId: string, role: USER_ROLES) => Promise<void>;
}

export const BusinessEquipmentRiskAdminContext = createContext<BusinessEquipmentRiskData>({
  nonApprovalUsers: [],
  nonEnvGroupUsers: [],
  approvalUsers: [],
  envGroupUsers: [],
  envGroupManager: null,
  mcrContactUsers: [],
  globalContactUsers: [],
  allUsers: [],
  submitUserRole: () => Promise.resolve(),
  removeUserRole: () => Promise.resolve(),
});

// TODO-RS: Extract to separate file in /hooks directory
export const useRiskManagementUsers = () => {
  const [currentUserRoles, setCurrentUserRoles] = useState<USER_ROLES[]>([]);
  const isEnabled = isFeatureEnabled('riskManagement');
  const getMyRiskManagementUsers = async () => {
    const roles = await api.getMyRiskManagementUserRoles();
    setCurrentUserRoles(roles);
  };

  useEffect(() => {
    isEnabled && void getMyRiskManagementUsers();
  }, []);

  return currentUserRoles;
};

export const useBusinessEquipmentRisk = () => {
  const context = useContext(BusinessEquipmentRiskAdminContext);
  if (R.isNil(context)) {
    throw new Error('useBusinessEquipmentRisk must be used inside an BusinessEquipmentRiskProvider');
  }
  return context;
};

const BusinessEquipmentRiskAdminProvider = (props: React.PropsWithChildren) => {
  const [allUsers, setAllUsers] = useState<UserWithRoles[]>([]);
  const [approvalUsers, setApprovalUsers] = useState<UserWithRoles[]>([]);
  const [envGroupUsers, setEnvGroupUsers] = useState<UserWithRoles[]>([]);
  const [nonApprovalUsers, setNonApprovalUsers] = useState<{ label: string; value: string }[]>([]);
  const [nonEnvGroupUsers, setNonEnvGroupUsers] = useState<{ label: string; value: string }[]>([]);
  const [envGroupManager, setEnvManagerUser] = useState<UserWithRoles | null>(null);
  const [mcrContactUsers, setMcrContactUsers] = useState<UserWithRoles[]>([]);
  const [globalContactUsers, setGlobalContactUsers] = useState<UserWithRoles[]>([]);

  const getUsers = async () => {
    const usersWithRoles: UserWithRoles[] = await api.getRiskManagementUsers();
    setAllUsers(usersWithRoles);
    setApprovalUsers(usersWithRoles.filter(user => user.roles.includes(USER_ROLES.APPROVER)));
    setEnvGroupUsers(usersWithRoles.filter(user => user.roles.includes(USER_ROLES.ENVIRONMENT_GROUP)));
    setNonApprovalUsers(
      usersWithRoles
        .filter(user => !user.roles.includes(USER_ROLES.APPROVER))
        .map(user => ({ label: user.name, value: user.id }))
    );
    setNonEnvGroupUsers(
      usersWithRoles
        .filter(user => !user.roles.includes(USER_ROLES.ENVIRONMENT_GROUP))
        .map(user => ({ label: user.name, value: user.id }))
    );
    setMcrContactUsers(usersWithRoles.filter(user => user.roles.includes(USER_ROLES.MCR_CONTACT)));
    setGlobalContactUsers(usersWithRoles.filter(user => user.roles.includes(USER_ROLES.GLOBAL_CONTACT)));

    const envManager = usersWithRoles.find(u => u.roles.includes(USER_ROLES.ENV_GROUP_MANAGER));
    setEnvManagerUser(envManager || null);
  };

  useEffect(() => {
    void getUsers();
  }, []);

  const submitUserRole = useCallback(async (userIds: string[], role: USER_ROLES) => {
    await api.addRiskManagementUsers(userIds, role);
    await getUsers();
  }, []);

  const removeUserRole = useCallback(async (userId: string, role: USER_ROLES) => {
    await api.deleteRoleForUser(userId, role);
    await getUsers();
  }, []);

  return (
    <BusinessEquipmentRiskAdminContext.Provider
      value={{
        nonApprovalUsers,
        nonEnvGroupUsers,
        submitUserRole,
        removeUserRole,
        approvalUsers,
        envGroupUsers,
        envGroupManager,
        mcrContactUsers,
        globalContactUsers,
        allUsers,
      }}
    >
      {props.children}
    </BusinessEquipmentRiskAdminContext.Provider>
  );
};

export default BusinessEquipmentRiskAdminProvider;
