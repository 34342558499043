import React from 'react';
import * as R from 'ramda';
import TableBody from '@material-ui/core/TableBody';

const ExpandableTableBody = ({ className, children }) => {
  const [expandedItem, setExpandedItem] = React.useState(null);

  const rows = React.Children.map(children, (child, i) =>
    React.cloneElement(child, {
      ...child.props,
      key: `row ${i}`,
      onToggleOpen: i === expandedItem ? R.thunkify(setExpandedItem)(null) : R.thunkify(setExpandedItem)(i),
      isOpen: i === expandedItem,
    })
  );

  return <TableBody className={className}>{rows}</TableBody>;
};

export default ExpandableTableBody;
