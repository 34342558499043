import { Grid } from '@material-ui/core';
import Panel from '../../../../../components/Panel';
import RecentReportDetails from './RecentReportDetails';
import { Downloader } from '../../../../../hooks/downloader';

interface Props {
  selectedElement: any;
}

const TopPanel = ({ selectedElement }: Props) => {
  const section = {
    height: '100%',
    paddingTop: 48,
  };

  return (
    <Panel>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
      >
        {selectedElement && (
          <Downloader>
            <RecentReportDetails selectedElement={selectedElement} />
          </Downloader>
        )}
        {!selectedElement && <Grid style={section}></Grid>}
      </Grid>
    </Panel>
  );
};

export default TopPanel;
