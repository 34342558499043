import React, { useState } from 'react';
import * as R from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import MuiTextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import useConditionalConfirm from '../../../../hooks/conditional-confirm';
import useManualIndicators from '../../../../hooks/manual-indicators-component';
import useElementIndicators from '../../../../hooks/element-indicators';

interface Props {
  className?: string;
  title: string;
  onSubmit: any;
  onCancel: any;
}

const IndicatorForm = ({ className, title, onSubmit, onCancel }: Props) => {
  const [value, setValue] = useState(null) as any;
  const filter = createFilterOptions();

  const manualComponentsIndicators = useManualIndicators();
  const { elementIndicators } = useElementIndicators();
  const filteredIndicators = R.differenceWith(
    (x: any, y: any) => x.id === y.indicatorId,
    manualComponentsIndicators,
    elementIndicators
  );
  const handleSave = () => {
    onSubmit(value);
  };

  const { confirming, confirm, onConfirm, onCancel: onCancelConfirm } = useConditionalConfirm(onCancel);

  return (
    <Grid
      container
      component="fieldset"
      spacing={2}
      className={className}
    >
      <legend>{title}</legend>
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          freeSolo
          value={value}
          onChange={(_, newValue: any) => {
            if (typeof newValue === 'string') {
              setValue({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setValue({
                name: newValue.inputValue,
              });
            } else {
              // TODO: Talk to Jamie about adding object to form.
              // NEW object has no id
              setValue(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                description: params.inputValue,
                element_type: 'component',
                id: null,
                name: params.inputValue,
                short_name: null,
                type_id: 'manual',
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          id="indicators-standard"
          handleHomeEndKeys
          options={filteredIndicators}
          getOptionLabel={(option: any) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderInput={params => (
            <MuiTextField
              {...params}
              label="Name"
              fullWidth
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <IconButton onClick={handleSave}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={confirm}>
          <CancelIcon />
        </IconButton>
      </Grid>
      {confirming && (
        <OkayCancelDialog
          onOkay={onConfirm}
          onCancel={onCancelConfirm}
        >
          Are you sure you want to cancel? All changes will be lost.
        </OkayCancelDialog>
      )}
    </Grid>
  );
};

export default IndicatorForm;
