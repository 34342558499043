import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const WorkGridContainerDetail = styled(Grid).attrs({
  container: true,
})`
  .MuiGrid-item {
    border-right: 0;
    font: ${({ theme }) => theme.fonts.medium14};
    color: ${({ theme }) => theme.colours.greys.darkGrey5};
  }
`;

export const WorkGridItemDetail = styled(Grid).attrs({
  item: true,
})`
  display: inline-block;
  width: 33%;
`;
