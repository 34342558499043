import { FieldValues } from 'react-hook-form';
import { ControlRadio, ControlRadioProps } from './ControlRadio';

interface ControlBooleanProps<T extends FieldValues> extends Omit<ControlRadioProps<T>, 'options'> {
  yesLabel?: string;
  noLabel?: string;
}

export const ControlBoolean = <T extends FieldValues>({ yesLabel, noLabel, ...props }: ControlBooleanProps<T>) => {
  return (
    <ControlRadio
      {...props}
      options={[
        { label: yesLabel || 'Yes', value: 'true' },
        { label: noLabel || 'No', value: 'false' },
      ]}
      toFormValue={(value: boolean) => value.toString()}
      fromFormValue={value => value === 'true'}
    />
  );
};
