import React from 'react';
import styled from 'styled-components';
import MUITypography from '@material-ui/core/Typography';
import Paragraphs from '../Paragraphs';

const Typography = styled(MUITypography)`
  padding-bottom: 8px;
`;

const SectionHeader = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
`;

const Container = styled.div`
  margin: 36px;
`;

const TextSections = ({ sections }) => {
  const sectionItems = sections.map((section, index) => (
    <div key={section.id || `index-${index}`}>
      <SectionHeader>{section.title}</SectionHeader>
      <Paragraphs s={section.body} />
    </div>
  ));
  return <Container>{sectionItems}</Container>;
};

export default TextSections;
