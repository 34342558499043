import React from 'react';
import styled from 'styled-components';
import EditComment from './EditComment';
import StaticComment from './StaticComment';

const Container = styled.div``;

const Comment = ({ className, comment, editing, saving, canEdit, onEdit, onDelete, onUp, onDown }: any) => (
  <Container className={className}>
    {editing ? (
      <EditComment
        comment={comment}
        saving={saving}
      />
    ) : (
      <StaticComment
        comment={comment}
        saving={saving}
        canEdit={canEdit}
        onEdit={onEdit}
        onDelete={onDelete}
        onUp={onUp}
        onDown={onDown}
      />
    )}
  </Container>
);

export default Comment;
