import { Grid } from '@material-ui/core';
import { ControlTextField } from '../../../../form/ControlTextField';
import { ControlAutocomplete } from '../../../../form/ControlAutocomplete';
import { ControlDate } from '../../../../form/ControlDate';
import { useCrews } from '../../hooks/useCrews';
import { useTurnover } from '../../hooks/useTurnover';
const AddEditTurnoverForm = () => {
  const { crews } = useCrews();
  const { control, errors } = useTurnover();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <ControlTextField
          control={control}
          name="turnoverNotes"
          multiline
          minRows={4}
          minLength={1}
          maxLength={1000}
          required
          error={!!errors.turnoverNotes}
          placeholder="Required (Character Limitation: 1000 Characters)"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlDate
          control={control}
          name="turnoverDate"
          label="Date"
          required
          error={!!errors.turnoverDate}
          dateFormat="ddMMMyyyy"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlAutocomplete
          control={control}
          name="crewId"
          label="Crew"
          options={crews.map(crew => ({
            label: crew.id,
            value: crew.id,
          }))}
          required
          error={!!errors.crewId}
        />
      </Grid>
    </Grid>
  );
};

export default AddEditTurnoverForm;
