import React from 'react';
import * as R from 'ramda';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import useUiMessages from '../../hooks/ui-messages';

const Alert = props => (
  <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />
);

const Messages = () => {
  const { activeMessage, handleClose } = useUiMessages();

  return (
    <Snackbar
      key={R.isNil(activeMessage) ? undefined : activeMessage.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={!R.isNil(activeMessage)}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      {activeMessage && (
        <Alert
          onClose={handleClose}
          severity={activeMessage.message ? 'success' : 'error'}
        >
          {R.propOr(activeMessage.errorMessage, 'message', activeMessage)}
        </Alert>
      )}
    </Snackbar>
  );
};

export default Messages;
