import * as actionTypes from '../action-types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_SCORE_COLOURS_LOAD_ALL_SUCCESS: {
      const { totalScoreColours } = action.payload;
      return totalScoreColours;
    }
    default:
      return state;
  }
};
