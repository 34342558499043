import { useCallback, useEffect, useState } from 'react';
import Api from '../api';

export const useHealthTrendsChart = (elementId: string, year: number) => {
  const [indicatorScores, setIndicatorScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCategories = useCallback(
    async (elementId: string, year: number) => {
      setIsLoading(true);
      const result = await Api.getElementIndicatorScoresByYear(elementId, year);
      if (!result) {
        setIsLoading(false);
        return;
      }
      setIndicatorScores(result);
      setIsLoading(false);
    },
    [Api, elementId, year]
  );

  useEffect(() => {
    void getCategories(elementId, year);
  }, [getCategories, elementId, year]);

  return {
    indicatorScores,
    isLoading,
  };
};
