import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import UnstyledCard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import * as R from 'ramda';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ProgramCategory } from '../../../../../hooks/program-element';
import SmallButton from '../../../../../components/SmallButton';
import ActionButton from '../../../../../components/ActionButton';
import OkayCancelDialog from '../../../../../components/OkayCancelDialog';
import useConfirm from '../../../../../hooks/confirm';
import Indicator from './Indicator';
import useProgramCategoryIndicator from '../../../../../hooks/program-category-indicator';

const Card = styled(UnstyledCard)`
  width: 100%;
  margin-bottom: 2%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow2};
`;

const ClickSection = styled(Grid)`
  padding: 21px 24px;
`;

const Section = styled(Grid)`
  margin-bottom: 2%;
`;

interface Props {
  elementId: number;
  category?: ProgramCategory;
  idx: number;
  isExpanded?: boolean;
  isCreate?: boolean;
  autoCompleteCategories?: string[];
  onRemove?(): void;
  onSave?(category: ProgramCategory): void;
}

const filter = createFilterOptions<any>();

// eslint-disable-next-line import/prefer-default-export
export const Category = ({
  elementId,
  category = {
    id: '',
    displayOrder: null,
    name: '',
  },
  idx = 0,
  isCreate = false,
  isExpanded = false,
  autoCompleteCategories,
  onRemove = () => null,
  onSave = () => null,
}: Props) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onRemove);
  const [newIndicator, setNewIndicator] = useState(false);
  const { indicators, addIndicator, removeIndicator, updateIndicator, reorderIndicator, autoCompleteIndicators } =
    useProgramCategoryIndicator(elementId, parseInt(category.id, 10));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOnRemove = () => {
    if (!isCreate) {
      confirm();
    } else {
      onRemove();
    }
  };

  const handleOnSave = () => {
    onSave(selectedCategory);
    setExpanded(!expanded);
  };

  const handleOnDragIndicatorEnd = ({ destination, source }: DropResult) => {
    if (!destination || destination?.index === source.index) return null;
    const indicatorDragged = indicators[source.index];
    reorderIndicator(indicatorDragged, destination?.index);
    return null;
  };

  const num: number = R.sum(indicators.map(({ weight }: any) => weight));
  const indicatorWeightSum: number = Math.round(num * 100) / 100;

  return (
    <Draggable
      draggableId={`draggable-id-${selectedCategory.id || 0}`}
      index={idx}
    >
      {(provided: any) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              style={{ paddingLeft: '4%' }}
              xs={1}
              {...provided.dragHandleProps}
            >
              <DragHandleIcon />
            </Grid>
            <Grid
              item
              xs={11}
            >
              <CardActionArea onClick={handleExpandClick}>
                <ClickSection
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="button">{selectedCategory.name}</Typography>
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ClickSection>
              </CardActionArea>
            </Grid>
          </Grid>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <ClickSection
              container
              item
              xs={12}
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                {autoCompleteCategories && (
                  <Grid
                    item
                    xs={9}
                    style={{ marginBottom: '15px' }}
                  >
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={autoCompleteCategories?.sort().map(x => ({ title: x }))}
                      renderOption={option => option.title}
                      getOptionLabel={(option: any) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      value={selectedCategory.name}
                      disabled={Boolean(!isCreate)}
                      onChange={(_, newValue: any) => {
                        if (typeof newValue === 'string') {
                          setSelectedCategory({
                            ...selectedCategory,
                            name: newValue,
                          });
                        } else if (newValue) {
                          setSelectedCategory({
                            ...selectedCategory,
                            name: newValue.inputValue || newValue.title,
                          });
                        } else {
                          setSelectedCategory({
                            ...selectedCategory,
                            name: '',
                          });
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Add Category Name"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              {isCreate ? null : (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Section
                    item
                    xs={3}
                  >
                    <Typography>Indicators</Typography>
                  </Section>
                  <Section
                    item
                    xs={12}
                  >
                    <Typography variant={'subtitle2'}>Weight Total:</Typography>
                    <Typography
                      variant={'subtitle2'}
                      color={indicatorWeightSum !== 100 ? 'secondary' : 'textPrimary'}
                    >
                      {indicatorWeightSum}
                    </Typography>
                  </Section>
                  <Section
                    item
                    xs={12}
                  >
                    {!newIndicator && <SmallButton onClick={() => setNewIndicator(true)}>+ Add Indicator</SmallButton>}
                    {newIndicator && (
                      <Indicator
                        idx={0}
                        isCreate
                        isExpanded
                        autoCompleteIndicators={R.differenceWith(
                          (x: any, y: any) => x === y.name,
                          autoCompleteIndicators,
                          indicators
                        )}
                        onRemove={() => setNewIndicator(false)}
                        onSave={(indicator: any) => {
                          addIndicator(indicator);
                          setNewIndicator(false);
                        }}
                      />
                    )}
                  </Section>
                  <Section
                    item
                    xs={12}
                  >
                    <DragDropContext onDragEnd={handleOnDragIndicatorEnd}>
                      <Droppable
                        droppableId={`indicator-${category.id}`}
                        type={category.name}
                      >
                        {(providedIndicator: any) => (
                          <div>
                            <div
                              ref={providedIndicator.innerRef}
                              // {...providedIndicator.droppableProps}
                            >
                              {indicators.map((indicator: any, innerIdx: number) => (
                                <Indicator
                                  idx={innerIdx}
                                  key={indicator.id}
                                  indicator={indicator}
                                  onRemove={() => removeIndicator(indicator.id)}
                                  onSave={(updatedIndicator: any) => updateIndicator(updatedIndicator)}
                                />
                              ))}
                            </div>
                            {providedIndicator.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Section>
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                <SmallButton onClick={handleOnRemove}>{isCreate ? 'Cancel' : 'REMOVE'}</SmallButton>
                {isCreate && (
                  <ActionButton
                    color="primary"
                    onClick={handleOnSave}
                  >
                    {isCreate ? 'Add' : 'Save'}
                  </ActionButton>
                )}
              </Grid>
            </ClickSection>
          </Collapse>
          {confirming && (
            <OkayCancelDialog
              title="Remove?"
              okayLabel="Remove"
              onOkay={onConfirm}
              onCancel={onCancel}
            >
              Are you sure you want to remove this category?
            </OkayCancelDialog>
          )}
        </Card>
      )}
    </Draggable>
  );
};
