import React, { useState } from 'react';
import styled from 'styled-components';

import { Grid, Input, CardActionArea, Typography, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import UnstyledCard from '@material-ui/core/Card';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { Draggable } from 'react-beautiful-dnd';
import ActionButton from '../../../../../components/ActionButton';
import OkayCancelDialog from '../../../../../components/OkayCancelDialog';
import SmallButton from '../../../../../components/SmallButton';
import useConfirm from '../../../../../hooks/confirm';
import ColourThreshold from './ColourThreshold';
import { ProgramCategoryIndicator } from '../../../../../hooks/program-category-indicator';
import CustomTextField from '../../../../../components/CustomTextField';

const Card = styled(UnstyledCard)`
  width: 100%;
  margin-bottom: 2%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow2};
`;

const ClickSection = styled(Grid)`
  padding: 21px 24px;
`;

const TextField = styled(Input)``;

const filter = createFilterOptions<any>();

interface Props {
  isExpanded?: boolean;
  indicator?: ProgramCategoryIndicator;
  isCreate?: boolean;
  idx: number;
  autoCompleteIndicators?: Array<string>;
  onRemove(): any;
  onSave(x: any): any;
}

const DEFAULT_INDICATOR = {
  id: 0,
  name: '',
  weight: '',
  displayOrder: 0,
  colourThresholds: {
    green: { value: '', description: '' },
    red: { value: '', description: '' },
    white: { value: '', description: '' },
    yellow: { value: '', description: '' },
  },
  description: '',
};

// TODO: Refactor Indicator and Category
const Indicator = ({
  isExpanded,
  indicator = DEFAULT_INDICATOR,
  isCreate,
  idx,
  autoCompleteIndicators,
  onRemove,
  onSave,
}: Props) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const [selectedIndicator, setSelectedIndicator] = useState(indicator);
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onRemove);

  const sortedIndicatorsTitles = autoCompleteIndicators
    ? [...autoCompleteIndicators].sort((a, b) => a.localeCompare(b)).map(x => ({ title: x }))
    : [];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOnRemove = () => {
    if (!isCreate) {
      confirm();
    } else {
      onRemove();
    }
  };

  const handleOnSave = () => {
    onSave(selectedIndicator);
  };

  const handleColourThresholdChange =
    (colour: 'green' | 'red' | 'white' | 'yellow') => (type: string, value: string) => {
      setSelectedIndicator({
        ...selectedIndicator,
        colourThresholds: {
          ...selectedIndicator.colourThresholds,
          [colour]: {
            ...selectedIndicator.colourThresholds[colour],
            [type]: value,
          },
        },
      });
    };

  return (
    <Draggable
      key={`draggable-id--indicator-${indicator.id || 0}`}
      draggableId={`draggable-id--indicator-${indicator.id || 0}`}
      index={idx}
    >
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                style={{ paddingLeft: '4%' }}
                xs={1}
                {...provided.dragHandleProps}
              >
                <DragHandleIcon />
              </Grid>
              <Grid
                item
                xs={11}
              >
                <CardActionArea onClick={handleExpandClick}>
                  <ClickSection
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="button">{selectedIndicator.name}</Typography>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ClickSection>
                </CardActionArea>
              </Grid>
            </Grid>
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
            >
              <ClickSection
                container
                item
                xs={12}
                spacing={2}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography paragraph>Indicator</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    style={{ marginBottom: '15px' }}
                  >
                    {autoCompleteIndicators ? (
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={sortedIndicatorsTitles}
                        renderOption={option => option.title}
                        getOptionLabel={(option: any) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        value={selectedIndicator.name}
                        disabled={Boolean(!isCreate)}
                        onChange={(_, newValue: any) => {
                          if (typeof newValue === 'string') {
                            setSelectedIndicator({
                              ...selectedIndicator,
                              name: newValue,
                            });
                          } else if (newValue) {
                            setSelectedIndicator({
                              ...selectedIndicator,
                              name: newValue.inputValue || newValue.title,
                            });
                          } else {
                            setSelectedIndicator({
                              ...selectedIndicator,
                              name: '',
                            });
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              title: `Add "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        renderInput={params => (
                          <MuiTextField
                            // NOTE: Changing the Name of the indicator requires making a brand new Indicator
                            {...params}
                            placeholder="Add Indicator Name"
                            fullWidth
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        type="text"
                        placeholder="Add Indicator Name"
                        disabled
                        fullWidth
                        value={selectedIndicator.name}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography paragraph>Weight</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    alignItems="center"
                    style={{ marginBottom: '15px' }}
                  >
                    <TextField
                      type="number"
                      placeholder="Add Weight"
                      fullWidth
                      value={selectedIndicator.weight}
                      onChange={e => setSelectedIndicator({ ...selectedIndicator, weight: e.target.value })}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography paragraph>Colour thresholds</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{ marginBottom: '15px' }}
                  >
                    <ColourThreshold
                      colour="Green"
                      value={indicator.colourThresholds.green}
                      onChange={handleColourThresholdChange('green')}
                    />
                    <ColourThreshold
                      colour="White"
                      value={indicator.colourThresholds.white}
                      onChange={handleColourThresholdChange('white')}
                    />
                    <ColourThreshold
                      colour="Yellow"
                      value={indicator.colourThresholds.yellow}
                      onChange={handleColourThresholdChange('yellow')}
                    />
                    <ColourThreshold
                      colour="Red"
                      value={indicator.colourThresholds.red}
                      onChange={handleColourThresholdChange('red')}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  alignItems="center"
                  style={{ marginBottom: '5px' }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    alignItems="center"
                    style={{ marginBottom: '40px' }}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography paragraph>Indicator Description</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={10}
                    alignItems="center"
                  >
                    <CustomTextField
                      multiline
                      minRows={5}
                      fullWidth
                      value={selectedIndicator.description}
                      onChange={e => {
                        setSelectedIndicator({ ...selectedIndicator, description: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SmallButton onClick={handleOnRemove}>{isCreate ? 'Cancel' : 'REMOVE'}</SmallButton>
                  <ActionButton
                    color="primary"
                    onClick={handleOnSave}
                  >
                    {isCreate ? 'Add' : 'Save'}
                  </ActionButton>
                </Grid>
              </ClickSection>
            </Collapse>
            {confirming && (
              <OkayCancelDialog
                title="Remove?"
                okayLabel="Remove"
                onOkay={onConfirm}
                onCancel={onCancel}
              >
                Are you sure you want to remove this indicator?
              </OkayCancelDialog>
            )}
          </Card>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

export default Indicator;
