import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  Colors,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useHealthTrendsChart } from '../../../../hooks/health-trends-chart';
import { useMemo } from 'react';
import { LinearProgress } from '@material-ui/core';

ChartJS.register(Colors, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  selectedAssignment: any;
  selectedYear: number;
}

interface LineData {
  name: string;
  data: number[];
}

const options: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    colors: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
    },
  },
};

const LABELS = ['Q1', 'Q2', 'Q3', 'Q4'];

const buildData = (data: LineData[]): ChartData<'line'> => ({
  labels: LABELS,
  datasets: data.map(data => ({
    label: data.name,
    data: data.data,
    fill: false,
  })),
});

export const ElementIndicatorsHealthTrendChart = ({ selectedAssignment, selectedYear }: Props) => {
  const { indicatorScores, isLoading } = useHealthTrendsChart(selectedAssignment.elementId, selectedYear);
  const data = useMemo(() => buildData(indicatorScores), [indicatorScores]);

  return selectedAssignment && isLoading ? (
    <LinearProgress />
  ) : (
    <Line
      options={options}
      data={data}
    />
  );
};
