import styled from 'styled-components';
import {
  renderWODescription,
  renderMaximoLink,
  onRenderRow,
  getRowContextMenuFunction,
} from '../../wo/components/utils';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useAssessing } from '../../hooks/useAssessing';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import { renderAssessingActions } from './utils';
import { TypeRowProps } from '@inovua/reactdatagrid-community/types';

const AsessGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__row-cell-wrap:hover {
    background-color: transparent !important;
    border: unset !important;
    border: 2px solid gray !important;
  }

  .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid transparent !important;
  }
  .InovuaReactDataGrid__row-cell-wrap:active {
    transform: scale(0.997);
    cursor: pointer;
  }

  .InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid rgb(38, 36, 82) !important;
  }
  .InovuaReactDataGrid__row.InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
  }
`;

const gridStyle = { minHeight: 600, minWidth: '100%' };

const WoTable = () => {
  const { filteredWorkorders, woLoading, setGridRef, setSelectedWonum, setTaskLoading } = useAssessing();

  const { selectedWorkorderId, handleHeaderTableSelection, setSelectedWorkorderId } = useWorkOrders();

  const { handleOpen } = useWODetailsModal();

  const onRowClick = (rowProps: TypeRowProps) => {
    setSelectedWonum(rowProps.data.wonum);
  };

  const onRowDoubleClick = () => {
    setTaskLoading(true);
    handleOpen('assessing');
  };

  const wo_columns = [
    {
      name: 'actions',
      header: 'Actions',
      defaultWidth: 80,
      sortable: false,
      showColumnMenuTool: false,
      render: renderAssessingActions,
    },
    {
      name: 'wonum',
      header: 'WO',
      defaultWidth: 80,
      render: ({ data }: any) => renderMaximoLink(data.id, data.wonum),
    },
    {
      name: 'plusplanner',
      header: 'Assessor',
    },
    {
      name: 'wopriority',
      header: 'Prior',
      defaultWidth: 65,
      enableColumnFilterContextMenu: false,
      headerEllipsis: false,
    },
    {
      name: 'worktype',
      header: 'Type',
      defaultWidth: 65,
      headerEllipsis: false,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'status',
      header: 'Status',
      defaultWidth: 90,
      enableColumnFilterContextMenu: false,
    },
    { name: 'location', header: 'Location', defaultWidth: 100 },
    {
      name: 'description',
      header: 'Description',
      minWidth: 140,
      defaultFlex: 1,
      render: renderWODescription,
    },
    { name: 'crewworkgroup', header: 'Crew', defaultWidth: 80 },
    {
      name: 'bpspm',
      header: 'SPM',
      headerEllipsis: false,
      defaultWidth: 60,
    },
    {
      name: 'totallife',
      header: 'Life',
      headerEllipsis: false,
      defaultWidth: 65,
    },
  ];

  return (
    <AsessGrid
      style={gridStyle}
      idProperty="id"
      enableSelection
      selected={selectedWorkorderId}
      onSelectionChange={handleHeaderTableSelection}
      onRowClick={onRowClick}
      onRowDoubleClick={onRowDoubleClick}
      renderRowContextMenu={getRowContextMenuFunction(handleOpen, setSelectedWorkorderId)}
      rowStyle={onRenderRow}
      pagination
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={wo_columns}
      dataSource={filteredWorkorders}
      showCellBorders={false}
      loading={woLoading}
      onReady={setGridRef}
    />
  );
};

export default WoTable;
