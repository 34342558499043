import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LandingPage from './pages/landing-page/LandingPage';
import ReportGenRouter from './pages/ReportGenRouter';
import ReportReviewPage from './pages/ReportReviewPage';

const HealthReportsPage = ({ match }) => (
  <Switch>
    <Route
      exact
      path={match.path}
      component={LandingPage}
    />
    <Route
      path={`${match.path}/review/:reportId`}
      component={ReportReviewPage}
    />
    <Route
      path={`${match.path}/:reportId`}
      component={ReportGenRouter}
    />
  </Switch>
);

export default HealthReportsPage;
