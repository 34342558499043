import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ColourCurve } from 'echo-scorecard';
import * as R from 'ramda';
import styled from 'styled-components';
import Named from './Named';

const ColourBox = styled(props => (
  <Box
    border={1}
    borderColor="grey.500"
    {...R.omit(['colour'], props)}
  />
))`
  background-color: ${({ theme, colour }) => theme.colours.scorecard[colour].background};
  color: ${({ theme, colour }) => theme.colours.scorecard[colour].text};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const ColourLegend = ({ className, colourCurve }: { className?: string; colourCurve: ColourCurve }) => (
  <Named
    className={className}
    title="Colour Thresholds"
  >
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
    >
      {colourCurve.map(({ low, high, colour }) => (
        <Grid
          key={colour}
          item
          xs={2}
        >
          <ColourBox colour={colour}>
            {low} - {high || '∞'}
          </ColourBox>
        </Grid>
      ))}
    </Grid>
  </Named>
);

export default ColourLegend;
