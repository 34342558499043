import styled from 'styled-components';
import * as R from 'ramda';

import MuiTable from '@material-ui/core/Table';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTypography from '@material-ui/core/Typography';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiIconButton from '@material-ui/core/IconButton';

export const TableContainer = styled(MuiTableContainer)`
  padding-top: 4px;
`;

export const Tooltip = styled(MuiTooltip)`
  color: 'black';
  background-color: 'black';
`;
export const TableCell = styled(MuiTableCell)``;

export const BorderlessCell = styled(TableCell)`
  padding-right: 0px;
  padding-left: 0px;
`;

export const IconButton = styled(MuiIconButton)`
  padding: 0px;
  padding-right: 10px;
  padding-left: 10px;

  :hover {
    background: none;
  }
`;

export const UnitScoreCell = styled(props => <TableCell {...R.omit(['readonly'], props)} />)`
  ${({ readonly }) =>
    readonly
      ? ''
      : `
    &:hover {
      filter: brightness(125%); /* TODO */
      cursor: pointer;
    }
  `}
`;

export const ColourBox = styled(props => <div {...R.omit(['colour', 'overridden'], props)} />)`
  background-color: ${({ theme, colour }) => theme.colours.scorecard[colour].background};
  color: ${({ theme, colour }) => theme.colours.scorecard[colour].text};
  display: flex;
  flex-direction: column;
  border: ${({ theme, overridden }) => (overridden ? `6px solid ${theme.colours.blues.blue3}` : 'none')};
  padding-top: ${({ overridden }) => (overridden ? '4px' : '15px')};
  padding-bottom: ${({ overridden }) => (overridden ? '4px' : '15px')};
  min-width: 80px;
`;

export const IndicatorCell = styled(BorderlessCell)``;

export const HeaderCell = styled(TableCell)``;

export const TableRow = styled(props => <MuiTableRow {...Object.assign({}, R.omit(['numUnits'], props))} />)`
  ${TableCell} {
    width: 60px;
  }

  ${TableCell}:first-child {
    padding-left: 10px;
    width: ${({ numUnits }) => (15 - numUnits) * 70}px;
    background-color: ${({ theme }) => theme.colours.white};
  }
`;

export const TableBody = styled(MuiTableBody)``;

export const TableHead = styled(MuiTableHead)``;

export const Table = styled(MuiTable)`
  table-layout: auto;
`;

export const Typography = styled(MuiTypography)`
  font: ${({ theme }) => theme.fonts.normal14};
  text-align: center;
`;

export const TypographyBorder = styled(props => <MuiTypography {...Object.assign({}, R.omit(['underline'], props))} />)`
  font: ${({ theme }) => theme.fonts.normal14};
`;

export const TableTitle = styled(Typography)`
  font: ${({ theme }) => theme.fonts.size4};
  text-transform: uppercase;
`;

export const Header = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
  text-align: center;
  color: ${({ theme }) => theme.colours.purples.purple1};
`;

export const CategoryText = styled(Header)`
  text-align: justify;
`;

export const IndicatorName = styled(TypographyBorder)``;
