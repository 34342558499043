import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const StartIconButton = styled(({ startIcon = <AddIcon />, ...props }) => (
  <Button
    startIcon={startIcon}
    {...props}
  />
))`
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.teals.teal1};
  font: ${({ theme }) => theme.fonts.medium14};
  letter-spacing: inherit;
`;

export default StartIconButton;
