import * as R from 'ramda';

import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useUsers from '../hooks/users';

interface Props {
  label?: string;
  userId: string;
  onUserSelect(userId: string): void;
}

const UserSelectList = ({ label, userId, onUserSelect }: Props) => {
  const { users } = useUsers();

  const options = R.sortBy<any>(
    R.prop('name'),
    R.map(user => ({ value: user.id, label: user.name }), users)
  );

  return (
    <Grid
      item
      xs={12}
    >
      <Autocomplete
        id={userId}
        renderInput={params => (
          <TextField
            {...params}
            label={label || 'Select User'}
            variant="outlined"
          />
        )}
        options={options}
        getOptionLabel={(option: any) => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(_, value) => {
          onUserSelect(value);
        }}
      />
    </Grid>
  );
};

export default UserSelectList;
