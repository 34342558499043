import * as R from 'ramda';

const NOT_EVALUATED = 'Not Evaluated';
const APPLIED = 'Applied';
const NOT_APPLIED = 'Not Applied';

const recordApplicability = applicable => {
  if (R.isNil(applicable)) {
    return 'Not Evaluated';
  }
  return applicable ? 'Applied' : 'Not Applied';
};

export const RECORD_APPLICABILITY = {
  NOT_EVALUATED,
  APPLIED,
  NOT_APPLIED,
};

export default recordApplicability;
