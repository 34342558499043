import React from 'react';
import styled from 'styled-components';
import UnstyledNavButton from './NavButton';

const NavButton = styled(UnstyledNavButton)`
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.colours.purples.purple2 : 'transparent')};
`;

const Container = styled.div`
  ${NavButton}.active {
    background-color: ${({ theme }) => theme.colours.purples.purple2};
  }
`;

const NavItem = ({ className, to, iconComponent, label, isSelected }) => (
  <Container className={className}>
    <NavButton
      className={className}
      to={to}
      iconComponent={iconComponent}
      label={label}
      isSelected={isSelected}
    />
  </Container>
);

export default NavItem;
