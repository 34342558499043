import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadAllRequest = states => ({
  type: actionTypes.REPORTS_LOAD_ALL_REQUEST,
  payload: {
    states,
  },
});

export const loadAllSuccess = reports => ({
  type: actionTypes.REPORTS_LOAD_ALL_SUCCESS,
  payload: {
    reports,
  },
});

export const loadAllFailure = errorAction(actionTypes.REPORTS_LOAD_ALL_FAILURE);

export const loadRequest = reportId => ({
  type: actionTypes.REPORTS_LOAD_REQUEST,
  payload: {
    reportId,
  },
});

export const loadSuccess = report => ({
  type: actionTypes.REPORTS_LOAD_SUCCESS,
  payload: {
    report,
  },
});

export const loadFailure = errorAction(actionTypes.REPORTS_LOAD_FAILURE);

export const loadReportCommentsRequest = reportId => ({
  type: actionTypes.REPORTS_LOAD_COMMENTS_REQUEST,
  payload: {
    reportId,
  },
});

export const loadPreviousReportCommentsRequest = (reportId, previousId) => ({
  type: actionTypes.REPORTS_LOAD_PREVIOUS_COMMENTS_REQUEST,
  payload: {
    reportId,
    previousId,
  },
});

export const loadReportCommentsSuccess = (reportId, comments) => ({
  type: actionTypes.REPORTS_LOAD_COMMENTS_SUCCESS,
  payload: {
    reportId,
    comments,
  },
});

export const loadReportCommentsFailure = errorAction(actionTypes.REPORTS_LOAD_COMMENTS_FAILURE);

export const addComment = (reportId, title, body) => ({
  type: actionTypes.REPORTS_ADD_COMMENT,
  payload: {
    reportId,
    title,
    body,
  },
});

export const saveCommentRequest = (reportId, commentId, commentsUpdatedAt, sequence, title, body) => ({
  type: actionTypes.REPORTS_SAVE_COMMENT_REQUEST,
  payload: {
    reportId,
    commentId,
    commentsUpdatedAt,
    sequence,
    title,
    body,
  },
});

export const finalizeCommentRequest = (reportId, commentId, commentsUpdatedAt, sequence, title, body) => ({
  type: actionTypes.REPORTS_FINALIZE_COMMENT_REQUEST,
  payload: {
    reportId,
    commentId,
    commentsUpdatedAt,
    sequence,
    title,
    body,
  },
});

export const createCommentSuccess = (reportId, commentId, sequence, title, body) => ({
  type: actionTypes.REPORTS_CREATE_COMMENT_SUCCESS,
  payload: {
    reportId,
    commentId,
    sequence,
    title,
    body,
  },
});

export const updateCommentSuccess = (reportId, commentId, commentsUpdatedAt, sequence, title, body) => ({
  type: actionTypes.REPORTS_UPDATE_COMMENT_SUCCESS,
  payload: {
    reportId,
    commentId,
    commentsUpdatedAt,
    sequence,
    title,
    body,
  },
});

export const saveCommentFailure = errorAction(actionTypes.REPORTS_SAVE_COMMENT_FAILURE);

export const updateExecutiveSummaryRequest = (reportId, executiveSummary, executiveSummaryUpdatedAt) => ({
  type: actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_REQUEST,
  payload: {
    reportId,
    executiveSummary,
    executiveSummaryUpdatedAt,
  },
});

export const finalizeExecutiveSummaryRequest = (reportId, executiveSummary, executiveSummaryUpdatedAt) => ({
  type: actionTypes.REPORTS_FINALIZE_EXECUTIVE_SUMMARY_REQUEST,
  payload: {
    reportId,
    executiveSummary,
    executiveSummaryUpdatedAt,
  },
});

export const updateExecutiveSummarySuccess = (reportId, executiveSummary, executiveSummaryUpdatedAt) => ({
  type: actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_SUCCESS,
  payload: {
    reportId,
    executiveSummary,
    executiveSummaryUpdatedAt,
  },
});

export const updateExecutiveSummaryFailure = errorAction(actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_FAILURE);

export const updateCommentSequenceRequest = (reportId, commentId, sequence) => ({
  type: actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_REQUEST,
  payload: {
    reportId,
    commentId,
    sequence,
  },
});

export const updateCommentSequenceSuccess = (reportId, commentId, sequence) => ({
  type: actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_SUCCESS,
  payload: {
    reportId,
    commentId,
    sequence,
  },
});

export const updateCommentSequenceFailure = errorAction(actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_FAILURE);

export const deleteCommentRequest = (reportId, commentId) => ({
  type: actionTypes.REPORTS_DELETE_COMMENT_REQUEST,
  payload: {
    reportId,
    commentId,
  },
});

export const deleteCommentSuccess = (reportId, commentId) => ({
  type: actionTypes.REPORTS_DELETE_COMMENT_SUCCESS,
  payload: {
    reportId,
    commentId,
  },
});

export const deleteCommentFailure = errorAction(actionTypes.REPORTS_DELETE_COMMENT_FAILURE);

export const submitRequest = (report, users) => ({
  type: actionTypes.REPORTS_SUBMIT_REQUEST,
  payload: {
    report,
    users,
  },
});

export const submitSuccess = report => ({
  type: actionTypes.REPORTS_SUBMIT_SUCCESS,
  payload: {
    report,
    message: 'Report submitted',
  },
});

export const submitFailure = error => ({
  type: actionTypes.REPORTS_SUBMIT_FAILURE,
  payload: {
    error,
    errorMessage: 'Error submitting report',
  },
});

export const approveRequest = (report, comment) => ({
  type: actionTypes.REPORTS_APPROVE_REQUEST,
  payload: {
    report,
    comment,
  },
});

export const reverseApproveRequest = (reportId, states) => ({
  type: actionTypes.REPORTS_REVERSE_APPROVE_REQUEST,
  payload: {
    reportId,
    states,
  },
});

export const reverseApproveFailure = error => ({
  type: actionTypes.REPORTS_REVERSE_APPROVE_FAILURE,
  payload: {
    error,
    errorMessage: 'Error reversing approval',
  },
});

export const reverseApproveSuccess = reports => ({
  type: actionTypes.REPORTS_REVERSE_APPROVE_SUCCESS,
  payload: {
    reports,
    message: 'Approval reversed',
  },
});

export const approveSuccess = report => ({
  type: actionTypes.REPORTS_APPROVE_SUCCESS,
  payload: {
    report,
    message: 'Report approved',
  },
});

export const approveFailure = error => ({
  type: actionTypes.REPORTS_APPROVE_FAILURE,
  payload: {
    error,
    errorMessage: 'Error approving report',
  },
});

export const rejectRequest = (report, comment) => ({
  type: actionTypes.REPORTS_REJECT_REQUEST,
  payload: {
    report,
    comment,
  },
});

export const rejectSuccess = report => ({
  type: actionTypes.REPORTS_REJECT_SUCCESS,
  payload: {
    report,
    message: 'Changes requested',
  },
});

export const rejectFailure = error => ({
  type: actionTypes.REPORTS_REJECT_FAILURE,
  payload: {
    error,
    errorMessage: 'Error rejecting report',
  },
});

export const closeRequest = (report, comment) => ({
  type: actionTypes.REPORTS_CLOSE_REQUEST,
  payload: {
    report,
    comment,
  },
});

export const closeSuccess = report => ({
  type: actionTypes.REPORTS_CLOSE_SUCCESS,
  payload: {
    report,
    message: 'Report closed',
  },
});

export const closeFailure = error => ({
  type: actionTypes.REPORTS_CLOSE_FAILURE,
  payload: {
    error,
    errorMessage: 'Error closing report',
  },
});
