import React, { useState } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Walkdown } from './types/types';
import useWalkdowns from '../../hooks/walkdowns';
import ActionButton from '../../components/ActionButton';
import Table from '../../components/Table';
import elementTypePrefix from '../../utils/element-type-prefix';
import NoRecords from '../../components/NoRecords';
import OkayCancelDialog from '../../components/OkayCancelDialog';

const InprogressPage = () => {
  const { inprogressWalkdowns, del } = useWalkdowns();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [removingWalkdown, setRemovingWalkdown] = useState<Walkdown | null>(null);

  return (
    <>
      {removingWalkdown && (
        <OkayCancelDialog
          title={`Are you sure you want to remove walkdown ${removingWalkdown.title}`}
          onOkay={() => {
            del(removingWalkdown.id);
            setRemovingWalkdown(null);
          }}
          onCancel={() => setRemovingWalkdown(null)}
          okayLabel="Delete"
        />
      )}
      <Grid
        container
        style={{ paddingTop: '1em', paddingBottom: '1em' }}
      >
        <ActionButton
          color="primary"
          onClick={() => {
            history.push(`${path}/start-walkdown`);
          }}
        >
          Start New Walkdown
        </ActionButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {inprogressWalkdowns ? (
              inprogressWalkdowns.map((wd: Walkdown) => {
                const { id, title, quarter, createdDate, state, facilityName, elementName, elementType, unit } = wd;

                return (
                  <TableRow key={id}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <b>
                        <>
                          [{elementTypePrefix(elementType)}] {elementName} ({facilityName})
                        </>
                      </b>
                    </TableCell>
                    <TableCell align="right">
                      <b>{title}</b>
                    </TableCell>
                    <TableCell align="right">{unit && <b>{unit}</b>}</TableCell>
                    <TableCell align="right">{quarter && <i>Q{quarter}</i>}</TableCell>
                    <TableCell align="right">
                      <i>Started On {moment(createdDate).format('LL')}</i>
                    </TableCell>
                    <TableCell align="right">{state}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        onClick={() => setRemovingWalkdown(wd)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        color="primary"
                        onClick={() => history.push(`${path}/in-progress/view/${id}`)}
                      >
                        Continue
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecords />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InprogressPage;
