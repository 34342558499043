import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as reportAttachmentActions from '../state/element-attachments/actions';
import * as reportAttachmentSelectors from '../state/element-attachments/selectors';

const useReportAttachments = (elementId, load = true) => {
  const dispatch = useDispatch();

  const loadAttachments = useCallback(() => {
    dispatch(reportAttachmentActions.loadRequest(elementId));
  }, [dispatch, elementId]);

  useEffect(() => {
    if (!R.isEmpty(elementId) && load) {
      loadAttachments();
    }
  }, [elementId, load, loadAttachments]);

  const attachments = useSelector(state => reportAttachmentSelectors.getAttachments(state, elementId));
  const isLoading = useSelector(state => reportAttachmentSelectors.isLoading(state, elementId));
  const error = useSelector(state => reportAttachmentSelectors.getError(state, elementId));

  return {
    attachments,
    isLoading,
    error,
    loadAttachments,
  };
};

export default useReportAttachments;
