import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';

interface Props<T extends FieldValues> extends Pick<CheckboxProps, 'checkedIcon' | 'icon'> {
  className?: string;
  control: Control<T>;
  name: Path<T>;
  label: string;
  disabled?: boolean;
}

export const ControlCheckbox = <T extends FieldValues>({
  control,
  name,
  label,
  disabled = false,
  checkedIcon,
  icon,
  className,
}: Props<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => {
      return (
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              {...field}
              checked={Boolean(field.value)}
              disabled={disabled}
              checkedIcon={checkedIcon}
              icon={icon}
            />
          }
          label={label}
          style={{ padding: 0, fontSize: '0.5rem' }}
        />
      );
    }}
  />
);
