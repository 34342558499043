import { ButtonGroup, Button } from '@material-ui/core';
import { useUsers } from '../../pages/cwt/hooks/useUsers';
import api from '../../api';

const SiteButtonGroup = () => {
  const { userSite, setUserSite } = useUsers();
  return (
    <ButtonGroup>
      <Button
        color="primary"
        variant={userSite === 'ba' ? 'contained' : 'outlined'}
        onClick={async () => {
          await api.cwt.updateFacility('ba');
          setUserSite('ba');
        }}
      >
        BA
      </Button>
      <Button
        color="primary"
        variant={userSite === null ? 'contained' : 'outlined'}
        onClick={async () => {
          await api.cwt.updateFacility(null);
          setUserSite(null);
        }}
      >
        BOTH
      </Button>
      <Button
        color="primary"
        variant={userSite === 'bb' ? 'contained' : 'outlined'}
        onClick={async () => {
          await api.cwt.updateFacility('bb');
          setUserSite('bb');
        }}
      >
        BB
      </Button>
    </ButtonGroup>
  );
};

export default SiteButtonGroup;
