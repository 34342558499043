import TaskTable from './TaskTable';

const AssessingTab = () => {
  return (
    <>
      <TaskTable />
    </>
  );
};

export default AssessingTab;
