import React from 'react';
import useComments from '../../hooks/report-comments';
import TextSections from './TextSections';

const SupportingDataContainer = ({ reportId }) => {
  const { comments } = useComments(reportId);

  return <TextSections sections={comments} />;
};

export default SupportingDataContainer;
