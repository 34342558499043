import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as dashboardActions from '../../../state/dashboards/actions';

const useDeleteDashboard = dashboards => {
  const dispatch = useDispatch();
  const [deletingDashboard, setDeletingDashboard] = useState(null);

  const deleteDashboardId = useCallback(
    dashboardId => {
      const dashboard = R.find(R.propEq('id', dashboardId), dashboards);
      setDeletingDashboard(dashboard);
    },
    [dashboards, setDeletingDashboard]
  );

  const cancelDeleteDashboard = useCallback(() => {
    setDeletingDashboard(null);
  }, [setDeletingDashboard]);

  const deleteDashboard = useCallback(() => {
    dispatch(dashboardActions.deleteDashboardRequest(deletingDashboard.id));
    setDeletingDashboard(null);
  }, [dispatch, deletingDashboard, setDeletingDashboard]);

  return {
    deletingDashboard,
    deleteDashboardId,
    cancelDeleteDashboard,
    deleteDashboard,
  };
};

export default useDeleteDashboard;
