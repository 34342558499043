import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import * as R from 'ramda';
import DropdownAutocomplete from '../../../../components/DropdownAutocomplete';
import useElements from '../../../../hooks/elements';

interface Props {
  yearOptions: Array<{ label: number | string; value: number | string }>;
  onChangeFilter(x: {
    elementName?: string;
    elementType?: string;
    facility?: string;
    quarter?: string;
    year?: string;
  }): void;
}

const Root = styled(Grid)`
  padding: 5px 59px;

  .MuiFormControl-root {
    margin-right: 20px;
  }
`;

const FilterOptionsPanel = ({ yearOptions, onChangeFilter }: Props) => {
  const { elements } = useElements();

  const elementNames = R.uniq(elements.map(element => element.name));

  const elementOptions = () => {
    const newArray = [{ value: 'none', label: 'None' }];
    elementNames.map(elementName => {
      return newArray.push({ value: elementName, label: elementName });
    });
    return newArray;
  };

  return (
    <Root
      container
      spacing={4}
    >
      <Grid
        item
        lg={2}
        md={4}
        sm={6}
        xs={12}
      >
        <DropdownAutocomplete
          onChange={(val: string) => {
            onChangeFilter({ elementName: val });
          }}
          label="Element Name"
          options={elementOptions()}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={4}
        sm={6}
        xs={12}
      >
        <DropdownAutocomplete
          onChange={(val: string) => onChangeFilter({ elementType: val })}
          label="Element Type"
          options={[
            { value: 'none', label: 'None' },
            { value: 'component', label: 'Component' },
            { value: 'system', label: 'System' },
            { value: 'program', label: 'Program' },
          ]}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={4}
        sm={6}
        xs={12}
      >
        <DropdownAutocomplete
          onChange={(val: string) => onChangeFilter({ facility: val })}
          label="Facility"
          options={[
            { value: 'none', label: 'None' },
            { value: 'ba', label: 'Bruce A' },
            { value: 'bb', label: 'Bruce B' },
          ]}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={4}
        sm={6}
        xs={12}
      >
        <DropdownAutocomplete
          onChange={(val: string) => onChangeFilter({ year: val })}
          label="Year"
          options={[{ value: 'none', label: 'None' }, ...yearOptions]}
        />
      </Grid>
      <Grid
        item
        lg={2}
        md={4}
        sm={6}
        xs={12}
      >
        <DropdownAutocomplete
          onChange={(val: string) => onChangeFilter({ quarter: val })}
          label="Quarter"
          options={[
            { value: 'none', label: 'None' },
            { value: '1', label: 'Q1' },
            { value: '2', label: 'Q2' },
            { value: '3', label: 'Q3' },
            { value: '4', label: 'Q4' },
          ]}
        />
      </Grid>
    </Root>
  );
};
export default FilterOptionsPanel;
