import React, { useMemo } from 'react';
import * as R from 'ramda';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ExpectedOutcomeForm from '../ExpectedOutcomeForm';
import ExpectedOutcomeSummary from '../ExpectedOutcomeSummary';
import OkayCancelDialog from '../OkayCancelDialog';
import useConfirm from '../../hooks/confirm';
import useEditListItem from '../../hooks/edit-list-item';

const ExpectedOutcomeList = ({ className, expectedOutcomes, onUpdateItem, onRemoveItem }) => {
  const { editingIndex, edit, onUpdate, onCancelUpdate } = useEditListItem(onUpdateItem);
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onRemoveItem);

  const sortedItems = useMemo(() => R.sortBy(R.prop('elementUnit'), expectedOutcomes), [expectedOutcomes]);

  const items = sortedItems.map((expectedOutcome, i) =>
    editingIndex === i ? (
      <ListItem key={expectedOutcome.id || `unsaved_${i}`}>
        <ExpectedOutcomeForm
          expectedOutcome={expectedOutcome}
          onSubmit={onUpdate}
          onCancel={onCancelUpdate}
        />
      </ListItem>
    ) : (
      <ListItem
        key={expectedOutcome.id || `unsaved_${i}`}
        button
        onClick={R.thunkify(edit)(i)}
      >
        <ListItemText primary={<ExpectedOutcomeSummary expectedOutcome={expectedOutcome} />} />
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            onClick={R.thunkify(confirm)(i)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  );

  return (
    <>
      <List className={className}>{items}</List>
      {confirming && (
        <OkayCancelDialog
          title="Delete?"
          okayLabel="Delete"
          onOkay={onConfirm}
          onCancel={onCancel}
        >
          Are you sure you want to delete this outcome?
        </OkayCancelDialog>
      )}
    </>
  );
};

export default ExpectedOutcomeList;
