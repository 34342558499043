import { Coding, CodingOfType } from '../types';

const NUMERIC_CATEGORIES_MAP: { [category in CodingOfType<number>]: category } = {
  priority: 'priority',
  spm: 'spm',
  woAcknowledge: 'woAcknowledge',
};

export const isNumericCategory = (category: Coding['category']): category is CodingOfType<number> => {
  return Object.values(NUMERIC_CATEGORIES_MAP).includes(category as CodingOfType<number>);
};

export const getPlanningGroupValue = (crew: string, planningCentre: string): string | null => {
  if (['MFBC', 'MFBM', 'MFBK'].includes(crew)) {
    return 'TP-FIN';
  } else if (crew === 'MCBC') {
    return 'TP_EWT_CNTRL';
  } else if (crew === 'MMBC') {
    return 'TP_EWT_MECH';
  } else if (['MCBS', 'MCBM', 'MCBQ', 'MCB4', 'MCB7', 'MCNC', 'MCBH', 'MCBO', 'MCNR', 'MCBV', 'MCBT'].includes(crew)) {
    if (planningCentre === 'OPB') {
      return 'TP-ONL-CNTRL';
    } else {
      return 'TP-OUT-CNTRL';
    }
  } else if (['MMBS', 'MMBV', 'MMBH', 'MMBT', 'MMNC', 'MMNB', 'MMHX'].includes(crew)) {
    if (planningCentre === 'OPB') {
      return 'TP-ONL-MECH';
    } else {
      return 'TP-OUT-MECH';
    }
  } else if (crew === 'MSBK') {
    if (planningCentre === 'OPB') {
      return 'TP-ONL-CIVIL';
    } else {
      return 'TP-OUT-CIVIL';
    }
  } else if (['MMBW', 'MMNW'].includes(crew)) {
    return 'TP-WELD';
  } else if (crew === 'OPBS') {
    if (planningCentre === 'OPB') {
      return 'TP-ONL-OPS';
    } else {
      return 'TP-OUT-OPS';
    }
  } else if (crew === 'ISNP') {
    return 'TP-INSP';
  } else if (crew.startsWith('BT')) {
    return 'TP-BTU';
  } else if (crew === 'EMNB') {
    return 'TP-VEND';
  }
  return null;
};
