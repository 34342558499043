import React, { useState } from 'react';
import { CardActionArea, Collapse, Grid, Typography } from '@material-ui/core';
import UnstyledCard from '@material-ui/core/Card';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import WalkdownTaskList from './WalkdownTaskList';
import { WalkdownCategory } from '../../../types/walkdown-types';

const Card = styled(UnstyledCard)`
  width: 100%;
  margin-bottom: 2%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow2};
  margin-top: 10px;
`;

const ClickSection = styled(Grid)`
  padding: 21px 24px;
`;

const Content = styled.div`
  padding: 2%;
`;

interface Props {
  category: WalkdownCategory;
  walkdownId: number | string;
}

const WalkdownCategoryCard = ({ category, walkdownId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
        >
          <CardActionArea onClick={() => setIsOpen(!isOpen)}>
            <ClickSection
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="button">{category.name}</Typography>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ClickSection>
          </CardActionArea>
        </Grid>
      </Grid>
      {category && (
        <Collapse in={isOpen}>
          <Content>
            <WalkdownTaskList
              walkdownId={walkdownId}
              tasks={category.tasks}
              categoryId={category.id}
            />
          </Content>
        </Collapse>
      )}
    </Card>
  );
};

export default WalkdownCategoryCard;
