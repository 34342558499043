import { useEffect } from 'react';
import * as R from 'ramda';

const useDeselector = (records, selectedRecord, indicatorRecordId, onSelectRecordId) => {
  useEffect(() => {
    if (selectedRecord) {
      if (R.isNil(R.find(record => indicatorRecordId(record) === indicatorRecordId(selectedRecord), records))) {
        onSelectRecordId(null);
      }
    }
  }, [records, selectedRecord, indicatorRecordId, onSelectRecordId]);
};

export default useDeselector;
