import { ElementId } from '../../types/app';
import { CreateElementParams } from '../../types/create-element-params';
import { PatchElementsUpdates } from '../../types/patch-elements-updates';
import { Path, Remote } from '../types';

const getElement = (path: Path, remote: Remote) => (elementId: number) => remote.get(path(`/${elementId}`));

const setOverrideReportDueDate = (path: Path, remote: Remote) => (elementId: number, overrideDueDate: string) =>
  remote.patch(path(`/${elementId}/override-report-due-date`), { overrideDueDate });

const updateMcrExemption =
  (path: Path, remote: Remote) =>
  (elementId: number, isMcrExempt: boolean, mcrExemptionStartDate: string | null, mcrExemptionEndDate: string | null) =>
    remote.patch(path(`/${elementId}/update-mcr-exemptions`), {
      isMcrExempt,
      mcrExemptionStartDate,
      mcrExemptionEndDate,
    });

const getAllElements = (path: Path, remote: Remote) => () => remote.get(path('/'));

const getElementUnits = (path: Path, remote: Remote) => (elementId: number) =>
  remote.get(path(`/${elementId}/element-units`));

const getReportAttachmentsByQuarter = (path: Path, remote: Remote) => (elementId: number, year: any, quarter: any) =>
  remote.get(path(`/${elementId}/periods/${year}/${quarter}/attachments`));

const getReportAttachments = (path: Path, remote: Remote) => (elementId: number) =>
  remote.get(path(`/${elementId}/attachments`));

const uploadReportAttachment =
  (path: Path, remote: Remote) =>
  (elementId: number, year: any, quarter: any, fileType: string | Blob, file: string | Blob) => {
    const formData = new FormData();
    formData.append('fileType', fileType);
    formData.append('file', file);
    return remote.post(path(`/${elementId}/periods/${year}/${quarter}/attachments`), formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  };

const deleteReportAttachment =
  (path: Path, remote: Remote) => (elementId: number, year: any, quarter: any, attachmentId: any) =>
    remote.del(path(`/${elementId}/periods/${year}/${quarter}/attachments/${attachmentId}`));

const downloadReportAttachment =
  (path: Path, remote: Remote) => (elementId: number, year: any, quarter: any, attachmentId: any) =>
    remote.download(path(`/${elementId}/periods/${year}/${quarter}/attachments/${attachmentId}/download`));

const getScorecard =
  (path: Path, remote: Remote) =>
  (elementId: number, year: any, quarter: any, bypassCache = false) =>
    remote.get(path(`/${elementId}/periods/${year}/${quarter}/scorecard`), {
      params: { bypassCache },
    });
const getScorecardOverride =
  (path: Path, remote: Remote) => (elementId: number, year: any, quarter: any, indicatorId: any, unitNumber: any) =>
    remote.get(path(`/${elementId}/periods/${year}/${quarter}/scorecard/-/overrides/${indicatorId}/${unitNumber}`));

const overrideScorecardScore =
  (path: Path, remote: Remote) =>
  (elementId: number, year: any, quarter: any, indicatorId: any, unitNumber: any, score: any, comment: any) =>
    remote.put(path(`/${elementId}/periods/${year}/${quarter}/scorecard/-/overrides/${indicatorId}/${unitNumber}`), {
      score,
      comment,
    });

const resetOverrideScore =
  (path: Path, remote: Remote) => (elementId: number, year: any, quarter: any, indicatorId: any, unitNumber: any) =>
    remote.del(path(`/${elementId}/periods/${year}/${quarter}/scorecard/-/overrides/${indicatorId}/${unitNumber}`));

const overrideUnitTotalValue =
  (path: Path, remote: Remote) =>
  (
    elementId: string | number,
    year: string | number,
    quarter: string | number,
    unit: string,
    score: number,
    colour?: string,
    comment?: string
  ) =>
    remote.put(path(`/${elementId}/periods/${year}/${quarter}/scorecard/-/unit-total-override`), {
      unit,
      score,
      colour,
      comment,
    });

const clearOverrideUnitTotalValue =
  (path: Path, remote: Remote) =>
  (elementId: ElementId, year: string | number, quarter: string | number, unit: string) =>
    remote.del(path(`/${elementId}/periods/${year}/${quarter}/scorecard/-/unit-total-override/${unit}`));

const getScorecardSections = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/scorecard-sections`));

const getCustomIndicators = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/custom-indicators`));

const getElementCategories = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/categories`));

const getAutoCompleteCategories = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/categories/autocomplete`));

const addProgramCategory = (path: Path, remote: Remote) => (elementId: ElementId, category: any) =>
  remote.post(path(`/${elementId}/categories`), { ...category });

const removeProgramCategory = (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number) =>
  remote.del(path(`/${elementId}/categories/${categoryId}`));

const updateProgramCategory = (path: Path, remote: Remote) => (elementId: ElementId, category: any) =>
  remote.put(path(`/${elementId}/categories`), { category });

const reorderProgramCategory = (path: Path, remote: Remote) => (elementId: ElementId, category: any) =>
  remote.put(path(`/${elementId}/categories/reorder`), { category });

const getProgramCategoryIndicators = (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number) =>
  remote.get(path(`/${elementId}/categories/${categoryId}/indicators`));

const getAutoCompleteIndicators = (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number) =>
  remote.get(path(`/${elementId}/categories/${categoryId}/indicators/autocomplete`));

const addProgramCategoryIndicator =
  (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number, indicator: any) =>
    remote.post(path(`/${elementId}/categories/${categoryId}/indicators`), { ...indicator });

const removeProgramCategoryIndicator =
  (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number, indicatorId: number) =>
    remote.del(path(`/${elementId}/categories/${categoryId}/indicators/${indicatorId}`));

const updateProgramCategoryIndicator =
  (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number, indicator: any) =>
    remote.put(path(`/${elementId}/categories/${categoryId}/indicators`), { indicator });

const reorderProgramCategoryIndicator =
  (path: Path, remote: Remote) => (elementId: ElementId, categoryId: number, indicator: any) =>
    remote.put(path(`/${elementId}/categories/${categoryId}/indicators/reorder`), { indicator });

const addElementIndicator = (path: Path, remote: Remote) => (elementId: ElementId, indicatorId: any, weight: any) =>
  remote.post(path(`/${elementId}/indicators`), { elementIndicator: { indicatorId, weight } });

const removeElementIndicator = (path: Path, remote: Remote) => (elementId: ElementId, indicatorId: any) =>
  remote.del(path(`/${elementId}/indicators/${indicatorId}`));

const updateElementIndicator =
  (path: Path, remote: Remote) => (elementId: ElementId, indicatorId: number, weight: string) =>
    remote.patch(path(`/${elementId}/indicators/${indicatorId}`), { weight });

const updateCustomIndicator =
  (path: Path, remote: Remote) => (elementId: ElementId, customIndicatorId: any, name: any, description: any) =>
    remote.patch(path(`/${elementId}/custom-indicators/${customIndicatorId}`), { name, description });

const deleteCustomIndicator = (path: Path, remote: Remote) => (elementId: ElementId, customIndicatorId: any) =>
  remote.del(path(`/${elementId}/custom-indicators/${customIndicatorId}`));

const getCustomIndicatorValues = (path: Path, remote: Remote) => (elementId: ElementId, year: any, quarter: any) =>
  remote.get(path(`/${elementId}/periods/${year}/${quarter}/custom-indicator-values`));

const setCustomIndicatorValue =
  (path: Path, remote: Remote) =>
  (
    elementId: number,
    year: any,
    quarter: any,
    indicatorId: any,
    unitNumber: any,
    value: any,
    colour: any,
    comment: any
  ) =>
    remote.put(
      path(`/${elementId}/periods/${year}/${quarter}/scorecard/-/manual-values/${indicatorId}/${unitNumber}`),
      { value, colour, comment }
    );

const getBackupRses = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/backup-rses`));

const getElementMappings = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/mappings`));

const postElementMappings = (path: Path, remote: Remote) => (elementId: ElementId, mapping: any) =>
  remote.post(path(`/${elementId}/mappings`), { mapping });

const deleteElementMapping =
  (path: Path, remote: Remote) =>
  (elementId: number, { facilityId, unitNumber, usi, location }: any) =>
    remote.del(
      location === null
        ? path(`/${elementId}/mappings/-/by-unit/${facilityId}/${unitNumber}/${usi}`)
        : path(`/${elementId}/mappings/-/by-location/${facilityId}/${location}/${usi}`)
    );

const setPrimaryRse = (path: Path, remote: Remote) => (elementId: ElementId, userId: any) =>
  remote.patch(path(`/${elementId}`), { primaryRse: userId });

const setBackupRse = (path: Path, remote: Remote) => (elementId: ElementId, userId: any) =>
  remote.put(path(`/${elementId}/backup-rses`), { userId });

const clearBackupRse = (path: Path, remote: Remote) => (elementId: ElementId, userId: any) =>
  remote.del(path(`/${elementId}/backup-rses/${userId}`));

const expandApplicable = (applicable: null | undefined) => {
  if (applicable === undefined) {
    return {};
  }
  if (applicable === null) {
    return { applicable: null };
  }
  return { applicable: applicable ? true : false }; // eslint-disable-line no-unneeded-ternary
};

const getIndicatorRecordsByQuarter =
  (path: Path, remote: Remote) => (elementId: ElementId, year: any, quarter: any, indicatorId: any, applicable: any) =>
    remote.get(path(`/${elementId}/periods/${year}/${quarter}/indicator-records/${indicatorId}`), {
      params: { ...expandApplicable(applicable) },
    });

const assignIndicatorRecord =
  (path: Path, remote: Remote) =>
  (indicatorId: number, elementId: ElementId, elementUnit: string, indicatorShortName: string, record: any) =>
    remote.post(path(`/${elementId}/record-map/${elementUnit}/${indicatorId}/${indicatorShortName}`), { record });

const removeAssociatedIndicatorRecord =
  (path: Path, remote: Remote) =>
  (
    indicatorId: number,
    elementId: ElementId,
    elementUnit: string,
    crNumber: number,
    pmNumber: number,
    workOrderNumber: number,
    workRequestNumber: number
  ) =>
    remote.del(
      path(
        `/${elementId}/record-map/${elementUnit}/${indicatorId}/${crNumber}/${pmNumber}/${workOrderNumber}/${workRequestNumber}`
      )
    );

const getAssignedIndicatorRecords =
  (path: Path, remote: Remote) => (indicatorId: number, elementId: ElementId, elementUnit: string) =>
    remote.get(path(`/${elementId}/record-map/${elementUnit}/${indicatorId}`));

const getIndicatorScore =
  (path: Path, remote: Remote) => (indicatorId: any, elementUnit: any, elementId: ElementId, year: any, quarter: any) =>
    remote.get(path(`/${elementId}/indicators/${indicatorId}/score/${elementUnit}/${year}/${quarter}`));

const getIndicatorRecordsByElement = (path: Path, remote: Remote) => (elementId: ElementId, indicatorId: any) =>
  remote.get(path(`/${elementId}/indicator-records/${indicatorId}`));

const getActions = (path: Path, remote: Remote) => (elementId: ElementId, state: any) =>
  remote.get(path(`/${elementId}/actions`), { params: { state } });

const getSpecificAction =
  (path: Path, remote: Remote) => (elementId: ElementId, referenceNumber: number, typeId: number | string) =>
    remote.get(path(`/${elementId}/actions/reference`), { params: { typeId, referenceNumber } });

const getUnitWeights = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/unit-weights`));

const getElementIndicators = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/indicators`));

const getElementActuals = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/actuals`));

const patchElement =
  (path: Path, remote: Remote) =>
  (
    elementId: ElementId,
    {
      reportingFrequency,
      category,
      state,
      referenceReportingYear,
      referenceReportingQuarter,
      pmpRequired,
      tier,
      sis,
      safetyRelatedSystemClassification,
      pmpDate,
    }: PatchElementsUpdates
  ) =>
    remote.patch(path(`/${elementId}`), {
      reportingFrequency,
      category,
      state,
      referenceReportingYear,
      referenceReportingQuarter,
      pmpRequired,
      sis,
      tier,
      safetyRelatedSystemClassification,
      pmpDate,
    });

const createElement = (path: Path, remote: Remote) => (details: CreateElementParams) => remote.post(path('/'), details);

const addDeferralsRequest = (path: Path, remote: any) => (elementId: ElementId, request: any) =>
  remote.post(path(`/${elementId}/deferrals`), { request });

const getElementIndicatorScoresByYear = (path: Path, remote: Remote) => (elementId: ElementId, year: number) =>
  remote.get(path(`/${elementId}/indicator-scores/${year}`));

const getExecutiveSummary = (path: Path, remote: Remote) => (elementId: ElementId) =>
  remote.get(path(`/${elementId}/executive-summary`));

const updateExecutiveSummary = (path: Path, remote: Remote) => (elementId: ElementId, text: string) =>
  remote.put(path(`/${elementId}/executive-summary`), { text });

export default (path: any, remote: any) => ({
  addElementIndicator: addElementIndicator(path, remote),
  addProgramCategory: addProgramCategory(path, remote),
  addProgramCategoryIndicator: addProgramCategoryIndicator(path, remote),
  addDeferralsRequest: addDeferralsRequest(path, remote),
  assignIndicatorRecord: assignIndicatorRecord(path, remote),
  removeAssociatedIndicatorRecord: removeAssociatedIndicatorRecord(path, remote),
  getAssignedIndicatorRecords: getAssignedIndicatorRecords(path, remote),
  clearBackupRse: clearBackupRse(path, remote),
  clearOverrideUnitTotalValue: clearOverrideUnitTotalValue(path, remote),
  createElement: createElement(path, remote),
  deleteCustomIndicator: deleteCustomIndicator(path, remote),
  deleteElementMapping: deleteElementMapping(path, remote),
  deleteReportAttachment: deleteReportAttachment(path, remote),
  downloadReportAttachment: downloadReportAttachment(path, remote),
  getActions: getActions(path, remote),
  getSpecificAction: getSpecificAction(path, remote),
  getAllElements: getAllElements(path, remote),
  getAutoCompleteCategories: getAutoCompleteCategories(path, remote),
  getAutoCompleteIndicators: getAutoCompleteIndicators(path, remote),
  getBackupRses: getBackupRses(path, remote),
  getCustomIndicators: getCustomIndicators(path, remote),
  getCustomIndicatorValues: getCustomIndicatorValues(path, remote),
  getElement: getElement(path, remote),
  getElementActuals: getElementActuals(path, remote),
  getElementCategories: getElementCategories(path, remote),
  getElementIndicators: getElementIndicators(path, remote),
  getElementMappings: getElementMappings(path, remote),
  getElementUnits: getElementUnits(path, remote),
  getIndicatorRecordsByElement: getIndicatorRecordsByElement(path, remote),
  getIndicatorRecordsByQuarter: getIndicatorRecordsByQuarter(path, remote),
  getProgramCategoryIndicators: getProgramCategoryIndicators(path, remote),
  getReportAttachments: getReportAttachments(path, remote),
  getReportAttachmentsByQuarter: getReportAttachmentsByQuarter(path, remote),
  getScorecard: getScorecard(path, remote),
  getScorecardOverride: getScorecardOverride(path, remote),
  getScorecardSections: getScorecardSections(path, remote),
  getUnitWeights: getUnitWeights(path, remote),
  overrideScorecardScore: overrideScorecardScore(path, remote),
  overrideUnitTotalValue: overrideUnitTotalValue(path, remote),
  patchElement: patchElement(path, remote),
  postElementMappings: postElementMappings(path, remote),
  removeElementIndicator: removeElementIndicator(path, remote),
  removeProgramCategory: removeProgramCategory(path, remote),
  removeProgramCategoryIndicator: removeProgramCategoryIndicator(path, remote),
  reorderProgramCategory: reorderProgramCategory(path, remote),
  reorderProgramCategoryIndicator: reorderProgramCategoryIndicator(path, remote),
  resetOverrideScore: resetOverrideScore(path, remote),
  setBackupRse: setBackupRse(path, remote),
  setCustomIndicatorValue: setCustomIndicatorValue(path, remote),
  setOverrideReportDueDate: setOverrideReportDueDate(path, remote),
  setPrimaryRse: setPrimaryRse(path, remote),
  updateCustomIndicator: updateCustomIndicator(path, remote),
  updateElementIndicator: updateElementIndicator(path, remote),
  updateProgramCategory: updateProgramCategory(path, remote),
  updateProgramCategoryIndicator: updateProgramCategoryIndicator(path, remote),
  uploadReportAttachment: uploadReportAttachment(path, remote),
  getIndicatorScore: getIndicatorScore(path, remote),
  updateMcrExemption: updateMcrExemption(path, remote),
  getElementIndicatorScoresByYear: getElementIndicatorScoresByYear(path, remote),
  getExecutiveSummary: getExecutiveSummary(path, remote),
  updateExecutiveSummary: updateExecutiveSummary(path, remote),
});
