import { Tooltip } from '@material-ui/core';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { generateMaximoLink } from '../../utils';
const MaximoLink = styled.a`
  color: blue;
`;
export const getHandleTabChangeFunction = (handleChange: (tab: string) => void) => {
  return (_: ChangeEvent<object>, value: string) => {
    handleChange(value);
  };
};

export const RenderDate = ({ value }: any) => {
  const date = new Date(value).toISOString().split('T')[0];
  return <span>{date}</span>;
};

export const RenderFlags = ({ data }: any) => {
  if (data.flags.length === 0) {
    return <span />;
  }
  const labels = data.flags.map((obj: any) => obj.label);
  return <span>{labels.join(', ')}</span>;
};

export const RenderOwner = ({ data }: any) => {
  if (!data.owner) {
    return <span />;
  }
  return <span>{data.owner.name}</span>;
};

export const RenderWorkorder = ({ data }: any) => {
  if (!data.workorder) {
    return <span />;
  }
  return <span>{data.workorder.wonum}</span>;
};

export const RenderMaximoTicketLink = ({ data }: any) => {
  return (
    <Tooltip title="Open in Maximo">
      <MaximoLink
        href={generateMaximoLink(data.ticketuid, 'plusca')}
        target="_blank"
      >
        {data.ticketid}
      </MaximoLink>
    </Tooltip>
  );
};
