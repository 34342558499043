import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../../../../api';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  originalTitle: string;
};

const EditBusinessObjectiveDashboard = ({ opened, setOpened, originalTitle }: Props) => {
  const [title, setTitle] = useState(originalTitle);
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle(originalTitle);
  }, [originalTitle]);

  const handleEdit = useCallback(async () => {
    if (title && status) {
      await api.editBusinessObjectives(title, status, originalTitle);
      dispatch(uiActions.genericMessage('Updated Succesfully'));
      setOpened(false);
    } else {
      dispatch(uiActions.error('', 'Please enter a valid input'));
    }
  }, [title, status]);

  return (
    <Dialog
      open={opened}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Edit Business Objective</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <CarlaText>Edit title and set status</CarlaText>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <CustomTextField
              label="Title"
              inputProps={{ maxLength: 256 }}
              onChange={e => setTitle(e.target.value)}
              defaultValue={originalTitle}
            ></CustomTextField>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Select
              fullWidth
              label="Status"
              options={[
                { label: 'Active', value: 'Active' },
                { label: 'In-Active', value: 'In-Active' },
              ]}
              onChange={e => setStatus(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={() => setOpened(false)}>Cancel</DialogButton>
        <DialogButton
          color="primary"
          onClick={handleEdit}
        >
          Update
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditBusinessObjectiveDashboard;
