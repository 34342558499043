import { useEffect, useState } from 'react';
import * as R from 'ramda';
import Api from '../api';

const useReportActions = (reportId: string, elementId: string, actionState: any) => {
  const [actions, setActions] = useState([]);
  useEffect(() => {
    if (!R.isNil(reportId) && !R.isNil(elementId)) {
      Api.getReportActions(reportId, elementId, actionState).then(setActions);
    } else {
      setActions([] as any);
    }
  }, [reportId, elementId, actionState, setActions]);

  return {
    actions,
  };
};

export default useReportActions;
