import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

const Container = styled(props => <div {...R.omit(['scoreColour'], props)} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, scoreColour }) =>
    R.pathOr(theme.colours.white, ['colours', 'scorecard', scoreColour, 'background'], theme)};
  border: ${({ theme, scoreColour = 'white', overridden }) => {
    if (overridden) {
      return `6px solid ${theme.colours.blues.blue3}`;
    }
    return scoreColour === 'white' ? `1px solid ${theme.colours.greys.lightGrey2}` : 'none';
  }};
`;

interface Props {
  children: any;
  className?: string;
  scoreColour: string;
  overridden?: boolean;
}

const ScoreColouredBox = ({ children, scoreColour = 'white', ...props }: Props) => (
  <Container
    scoreColour={scoreColour}
    {...props}
  >
    {children}
  </Container>
);

export default ScoreColouredBox;
