import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormWatch,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../../../state/ui/actions';
import { useCrews } from '../../hooks/useCrews';
import { generateCodings } from '../utils/generateCodings';
import { Coding, CodingValue, ScreeningStatus, WorkRequestCodingFormInputs, WorkRequestCodingValues } from '../types';
import { handleValidateCategoryValue } from '../utils/validate';
import { useMemo } from 'react';
import Api from '../../../../api';
import { RadioOption } from '../../../../components/CustomRadioGroup';

export interface WorkRequestCodingForm {
  control: Control<WorkRequestCodingFormInputs>;
  handleSubmit: () => Promise<void>;
  errors: FieldErrors<WorkRequestCodingFormInputs>;
  codings: FieldArrayWithId<WorkRequestCodingFormInputs, 'codings', 'id'>[];
  handleGenerateCodings: () => void;
  handleAddRow: () => void;
  handleBlurRow: React.FocusEventHandler<HTMLInputElement>;
  validateCategoryValue: <T extends Coding['category']>(
    category: T,
    value: CodingValue<Coding['category']>,
    formValues: WorkRequestCodingFormInputs
  ) => boolean;
  fieldsDisabled: boolean;
  screeningStatusOptions: RadioOption[];
  watch: UseFormWatch<WorkRequestCodingFormInputs>;
}

interface Props {
  workRequestNumber: string;
  initialValues?: Partial<WorkRequestCodingFormInputs>;
  refetchCoding: () => void;
}

const useWorkRequestCodingForm = ({
  initialValues: inputInitialValues,
  workRequestNumber,
  refetchCoding,
}: Props): WorkRequestCodingForm => {
  const initialValues: WorkRequestCodingFormInputs = {
    proposedCoding: inputInitialValues?.proposedCoding ?? null,
    codingNotes: inputInitialValues?.codingNotes ?? null,
    screeningStatus: inputInitialValues?.screeningStatus ?? null,
    codings: inputInitialValues?.codings ?? [],
  };

  const { crews } = useCrews();
  const crewIds = useMemo(() => crews.map(crew => crew.id), [crews]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm<WorkRequestCodingFormInputs>({
    defaultValues: initialValues,
  });

  const {
    fields: codings,
    append,
    remove,
    replace,
  } = useFieldArray({
    control,
    name: 'codings',
  });

  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<WorkRequestCodingFormInputs> = async data => {
    try {
      if (!data.screeningStatus || data.codings.some(coding => coding.category === null || coding.value === null)) {
        // This will be handled by form validation, so we should never hit this case.
        return;
      }

      const codingValues = data.codings.reduce<WorkRequestCodingValues>(
        (acc, coding) => ({ ...acc, [coding.category as Coding['category']]: coding.value }),
        {}
      );

      await Api.cwt.saveWorkRequestCoding(workRequestNumber, {
        proposedCoding: data.proposedCoding ?? undefined,
        screeningStatus: data.screeningStatus,
        notes: data.codingNotes ?? undefined,
        btu: codingValues.btu,
        caaNumber: codingValues.caaNumber,
        crew: codingValues.crew,
        outageCode: codingValues.outageCode,
        planningCentre: codingValues.planningCentre,
        planningGroup: codingValues.planningGroup,
        priority: codingValues.priority,
        projectNumber: codingValues.projectNumber,
        scheduleBacklog: codingValues.scheduleBacklog,
        spm: codingValues.spm,
        ucr: codingValues.ucr,
        woAcknowledge: codingValues.woAcknowledge,
        workCategory: codingValues.workCategory,
        workType: codingValues.workType,
      });
      dispatch(uiActions.genericMessage('Coding saved'));
      refetchCoding();
    } catch (error: any) {
      console.error(error);
      dispatch(uiActions.error('', 'Error saving coding'));
    }
  };

  const handleGenerateCodings = () => {
    const proposedCoding = getValues('proposedCoding');
    try {
      const lockedCodings = getValues('codings').filter(coding => coding.locked);

      const generatedCodings = generateCodings(proposedCoding ?? '', crewIds);
      const newCodings = generatedCodings.filter(
        coding =>
          !lockedCodings.find(
            lockedCoding => lockedCoding.category === coding.category && lockedCoding.value === coding.value
          )
      );

      // First, remove all entries to clear validation errors
      remove();

      // Second, replace codings completely, with existing locked codings at the top of the table
      replace([...lockedCodings, ...newCodings]);
    } catch (error: any) {
      dispatch(uiActions.error('', error?.message || 'Could not generate codings'));
    }
  };

  const handleAddRow = () => {
    // Add blank row at the bottom of the coding table
    append({ category: null, value: null, locked: true });
  };

  const handleBlurRow: React.FocusEventHandler<HTMLInputElement> = event => {
    event.preventDefault();

    const target = event.target;
    const relatedTarget = event.relatedTarget;

    // Name is of the form "codings.{number}.{field}"
    const codingsInputRegex = new RegExp(/^codings\.(\d+)\./);
    const targetCodingIndex = parseInt(codingsInputRegex.exec(target.name)?.[1] ?? '', 10);
    const relatedTargetCodingIndex = parseInt(
      codingsInputRegex.exec(relatedTarget?.getAttribute('name') ?? '')?.[1] ?? '',
      10
    );

    // Only clear the row if the user clicked out of the row (not between inputs within the row)
    if (!isNaN(targetCodingIndex) && relatedTargetCodingIndex !== targetCodingIndex) {
      // Check if this row is empty
      const rowValues = getValues(`codings.${targetCodingIndex}`);
      if (!rowValues.category && !rowValues.value) {
        remove(targetCodingIndex);
      }
    }
  };

  const validateCategoryValue = <T extends Coding['category']>(
    category: T,
    value: CodingValue<Coding['category']>,
    formValues: WorkRequestCodingFormInputs
  ) => handleValidateCategoryValue(category, value, formValues, crewIds);

  const fieldsDisabled = !watch('screeningStatus') || watch('screeningStatus') === <ScreeningStatus>'noActionRequired';

  const screeningStatusOptions = [
    { value: 'actionRequired', label: 'Action Required', disabled: initialValues.screeningStatus === 'coded' },
    { value: 'noActionRequired', label: 'No Action Required', disabled: initialValues.screeningStatus === 'coded' },
    { value: 'coded', label: 'Coded', disabled: watch('codings').length === 0 },
  ];

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    handleGenerateCodings,
    codings,
    handleAddRow,
    handleBlurRow,
    validateCategoryValue,
    fieldsDisabled,
    screeningStatusOptions,
    watch,
  };
};

export default useWorkRequestCodingForm;
