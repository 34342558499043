import { Route, Switch, useRouteMatch } from 'react-router-dom';
import WorkRequestPage from './pages';
import WorkRequestScreening from './pages/WorkRequestScreening';
import { WorkRequestsProvider } from '../hooks/useWorkRequests';

const WorkRequestSwitch = () => {
  const { path } = useRouteMatch();
  return (
    <WorkRequestsProvider>
      <Switch>
        <Route
          path={path}
          exact
          component={WorkRequestPage}
        />
        <Route
          path={`${path}/:workRequestNumber/screening`}
          component={WorkRequestScreening}
        />
      </Switch>
    </WorkRequestsProvider>
  );
};

export default WorkRequestSwitch;
