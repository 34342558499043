import styled from 'styled-components';
import rocket from '../assets/rocket.svg';

const Rocket = styled.img.attrs({
  src: rocket,
})`
  display: block;
  transform: ${({ open }: any) => (open ? 'rotate(90deg)' : 'none')};
  transition: transform 0.3s;
  cursor: pointer;
`;

export default Rocket;
