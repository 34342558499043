import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import TccDetails from './TccDetails';

const Container = styled.div``;

interface Props {
  className?: string;
  recordDetails: any;
}

const TmodsBeyond6MonthsDetails = ({ className, recordDetails }: Props) => (
  <Container className={className}>
    {recordDetails.tccRecord && (
      <Box p={5}>
        <TccDetails tccRecord={recordDetails.tccRecord} />
      </Box>
    )}
  </Container>
);

export default TmodsBeyond6MonthsDetails;
