import { useState, useCallback } from 'react';

const useSectionNavigator = sections => {
  const [activeSectionNumber, setActiveSectionNumber] = useState(0);

  const gotoSection = useCallback(
    sectionNumber => {
      if (sectionNumber >= 0 && sectionNumber < sections.length) {
        setActiveSectionNumber(sectionNumber);
      }
    },
    [setActiveSectionNumber, sections.length]
  );

  const gotoNextPage = useCallback(() => {
    setActiveSectionNumber(Math.min(sections.length - 1, activeSectionNumber + 1));
  }, [sections.length, activeSectionNumber]);

  const gotoPreviousPage = useCallback(() => {
    setActiveSectionNumber(Math.max(0, activeSectionNumber - 1));
  }, [activeSectionNumber]);

  return {
    activeSection: sections.length > 0 ? sections[activeSectionNumber] : undefined,
    gotoSection,
    gotoNextPage,
    gotoPreviousPage,
  };
};

export default useSectionNavigator;
