import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import styled from 'styled-components';
import { onRenderRow } from '../../../wo/components/utils';
import { WorkOrder } from '../../../../../types/cwt/workorder';
import { WOColumns } from '../../../wo/components/WOTableColumns';

const gridStyle = { minHeight: 600, minWidth: '100%' };

const HeaderGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__row-cell-wrap:hover {
    background-color: transparent !important;
    border: unset !important;
    border: 2px solid gray !important;
  }

  .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid transparent !important;
  }
  .InovuaReactDataGrid__row-cell-wrap:active {
    transform: scale(0.997);
    cursor: pointer;
  }

  .InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid rgb(38, 36, 82) !important;
  }
  .InovuaReactDataGrid__row.InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
  }
`;

interface WOTableProps {
  workOrders: WorkOrder[];
  loading: boolean;
  setSelectedWorkOrderId: (workorderId: string) => void;
}

const WOTable = ({ workOrders, loading, setSelectedWorkOrderId }: WOTableProps) => {
  const columns = [
    ...WOColumns,
    {
      name: 'updated_at',
      header: 'Date Added',
      type: 'number',
      headerEllipsis: false,
      defaultWidth: 90,
    },
  ];
  return (
    <HeaderGrid
      style={gridStyle}
      idProperty="id"
      enableSelection
      rowStyle={onRenderRow}
      pagination
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={workOrders}
      showCellBorders={false}
      loading={loading}
      onSelectionChange={e => {
        e.selected && setSelectedWorkOrderId(e.selected?.toString());
      }}
    />
  );
};

export default WOTable;
