import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import useElementIndicators from '../../hooks/element-indicators';
import UnstyledSummaryRecordTables from './SummaryRecordTables';
import UnstyledSummaryScorecard from './SummaryScorecard';
import SummaryActionTable from './SummaryActionTable';
import { Report } from '../../hooks/report';

const SummaryRecordTables = styled(UnstyledSummaryRecordTables)``;

const SummaryScorecard = styled(UnstyledSummaryScorecard)``;

const Container = styled.div`
  padding: 20px;

  ${SummaryRecordTables} {
    margin-bottom: 40px;
  }

  ${SummaryScorecard} {
    margin-bottom: 40px;
  }
`;

const SectionTitle = styled(Typography).attrs({
  variant: 'h3',
})`
  font: ${({ theme }) => theme.fonts.normal22};
`;

const titleMap: any = {
  program: 'PHIP Actions',
  system: 'SHIP Actions',
  component: 'CHIP Actions',
};

interface Props {
  className?: string;
  report: Report;
}

const ReportSummary = ({ className, report }: Props) => {
  const { elementIndicators } = useElementIndicators();
  const calculatedElementIndicators = R.filter(R.propEq('indicatorType', 'calculated'), elementIndicators);
  return (
    <Container className={className}>
      {report.elementType !== 'program' && (
        <>
          <SectionTitle>Indicator Records</SectionTitle>
          <SummaryRecordTables
            report={report}
            elementIndicators={calculatedElementIndicators}
          />
        </>
      )}
      <SectionTitle>Scorecard</SectionTitle>
      <SummaryScorecard report={report} />
      <SectionTitle>{titleMap[report.elementType]}</SectionTitle>
      <SummaryActionTable report={report} />
    </Container>
  );
};

export default ReportSummary;
