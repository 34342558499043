import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Input } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Field from './Field';
import { EchoElement } from '../../../../hooks/elements';
import PencilSelect from '../../../../components/PencilSelect';
import useElementData from '../../hooks/element-data';
import useElementOverrideReportDueDate from '../../hooks/element-override-report-date';
import CheckboxField from '../../../../components/CheckboxField';

const ButtonContainer = styled.span`
  position: absolute;
  top: -10px;
  right: 10px;
`;

const Wrapper = styled.div`
  position: relative;
  height: 30px;
  .MuiSelect-root {
    margin: 0;
  }
`;

interface Props {
  element: EchoElement;
}

const REPORT_QUARTERS = [
  { value: '1', label: 'Q1' },
  { value: '2', label: 'Q2' },
  { value: '3', label: 'Q3' },
  { value: '4', label: 'Q4' },
];

const REPORT_YEARS = [
  { value: 'even', label: 'Even' },
  { value: 'odd', label: 'Odd' },
];

const TIER_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];

const SIS_OPTIONS = [
  { value: null, label: '-' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const ElementHeader = ({ element }: Props) => {
  const { frequencyOptions, categoryOptions, updateElementData } = useElementData(element.id);

  const [canOverrideDueDate, setCanOverrideDueDate] = useState(false);
  const [overrideDueDate, setOverrideDueDate] = useState('');

  const [canOverridePmpDate, setCanOverridePmpDate] = useState(false);
  const [overridePmpDate, setOverridePmpDate] = useState('');

  const { setOverrideReportDueDate } = useElementOverrideReportDueDate(element.id);

  const nextDueDate = moment(new Date(element.nextReportDueDate)).utc().format('DD-MMM-YYYY');
  const pmpDate = moment(new Date(element.pmpDate)).utc().format('DD-MMM-YYYY');
  const pmpLabel = element.type === 'component' ? 'CPMP' : 'SPMP';

  return (
    <>
      <Field
        title="State"
        alignItems="center"
      >
        <CheckboxField
          label="Is Active"
          checked={element.state === 'ACTIVE'}
          onChange={() => updateElementData({ state: element.state === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
        />
      </Field>
      {element.type !== 'program' && (
        <>
          <Field
            title={`${pmpLabel} Required`}
            alignItems="center"
          >
            <CheckboxField
              checked={element.pmpRequired}
              onChange={() => updateElementData({ pmpRequired: !element.pmpRequired })}
            />
          </Field>

          {element.pmpRequired && (
            <Field
              title={`${pmpLabel} Review Date`}
              alignItems="center"
            >
              {canOverridePmpDate ? (
                <Wrapper>
                  <Input
                    type="date"
                    defaultValue={moment(new Date(element.pmpDate)).utc().format('YYYY-MM-DD')}
                    inputProps={{ min: moment().format('YYYY-MM-DD') }}
                    onChange={evt => {
                      setOverridePmpDate(evt.target.value);
                    }}
                  />
                  <ButtonContainer>
                    <IconButton
                      onClick={() => {
                        updateElementData({ pmpDate: overridePmpDate });
                        setCanOverridePmpDate(false);
                      }}
                    >
                      <DoneIcon />
                    </IconButton>
                  </ButtonContainer>
                </Wrapper>
              ) : (
                <Wrapper>
                  <Typography>{pmpDate}</Typography>
                  <ButtonContainer>
                    <IconButton onClick={() => setCanOverridePmpDate(true)}>
                      <EditIcon />
                    </IconButton>
                  </ButtonContainer>
                </Wrapper>
              )}
            </Field>
          )}
        </>
      )}

      <Field
        title="Health Report Due Date"
        alignItems="center"
      >
        {canOverrideDueDate ? (
          <Wrapper>
            <Input
              type="date"
              defaultValue={moment(new Date(element.nextReportDueDate)).utc().format('YYYY-MM-DD')}
              inputProps={{ min: moment().format('YYYY-MM-DD') }}
              onChange={evt => {
                setOverrideDueDate(evt.target.value);
              }}
            />
            <ButtonContainer>
              <IconButton
                onClick={() => {
                  setOverrideReportDueDate(overrideDueDate);
                  setCanOverrideDueDate(false);
                }}
              >
                <DoneIcon />
              </IconButton>
            </ButtonContainer>
          </Wrapper>
        ) : (
          <Wrapper>
            <Typography>{nextDueDate}</Typography>
            <ButtonContainer>
              <IconButton onClick={() => setCanOverrideDueDate(true)}>
                <EditIcon />
              </IconButton>
            </ButtonContainer>
          </Wrapper>
        )}
      </Field>

      {element.type !== 'component' && (
        <Field
          title="Tier"
          alignItems="center"
        >
          <PencilSelect
            value={element.tier}
            onSave={(val: any) => {
              updateElementData({ tier: val });
            }}
            options={TIER_OPTIONS}
            trigger={element.id}
          />
        </Field>
      )}
      <Field
        title="Safety Related System Classification"
        alignItems="center"
      >
        <CheckboxField
          label="A"
          checked={element.safetyRelatedSystemClassification.a === true}
          onChange={() => {
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                a: !element.safetyRelatedSystemClassification.a,
              },
            });
          }}
        />
        <CheckboxField
          label="B"
          checked={element.safetyRelatedSystemClassification.b === true}
          onChange={() =>
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                b: !element.safetyRelatedSystemClassification.b,
              },
            })
          }
        />
        <CheckboxField
          label="C"
          checked={element.safetyRelatedSystemClassification.c === true}
          onChange={() =>
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                c: !element.safetyRelatedSystemClassification.c,
              },
            })
          }
        />
        <CheckboxField
          label="D"
          checked={element.safetyRelatedSystemClassification.d === true}
          onChange={() =>
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                d: !element.safetyRelatedSystemClassification.d,
              },
            })
          }
        />
        <CheckboxField
          label="E"
          checked={element.safetyRelatedSystemClassification.e === true}
          onChange={() =>
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                e: !element.safetyRelatedSystemClassification.e,
              },
            })
          }
        />
        <CheckboxField
          label="F"
          checked={element.safetyRelatedSystemClassification.f === true}
          onChange={() =>
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                f: !element.safetyRelatedSystemClassification.f,
              },
            })
          }
        />
        <CheckboxField
          label="G"
          checked={element.safetyRelatedSystemClassification.g === true}
          onChange={() =>
            updateElementData({
              safetyRelatedSystemClassification: {
                ...element.safetyRelatedSystemClassification,
                g: !element.safetyRelatedSystemClassification.g,
              },
            })
          }
        />
      </Field>

      <Field
        title={'SIS'}
        alignItems="center"
      >
        <PencilSelect
          value={`${element.sis}`}
          onSave={(val: any) => {
            updateElementData({ sis: val === 'null' ? null : val });
          }}
          options={SIS_OPTIONS}
          trigger={element.id}
        />
      </Field>

      <Field
        title="Reporting Frequency"
        alignItems="center"
      >
        <PencilSelect
          value={element.reportingFrequency}
          onSave={(val: any) => {
            updateElementData({ reportingFrequency: val });
          }}
          options={frequencyOptions}
          trigger={element.id}
        />
      </Field>

      {element.reportingFrequency === 'biannually' && (
        <Field
          title="Reference Year"
          alignItems="center"
        >
          <PencilSelect
            value={element.referenceReportingYear}
            onSave={(val: any) => {
              updateElementData({ referenceReportingYear: val });
            }}
            options={REPORT_YEARS}
            trigger={element.id}
          />
        </Field>
      )}

      <Field
        title="Reference Quarter"
        alignItems="center"
      >
        <PencilSelect
          value={element.referenceReportingQuarter?.toString(10)}
          onSave={(val: any) => {
            updateElementData({ referenceReportingQuarter: val });
          }}
          options={REPORT_QUARTERS}
          trigger={element.id}
        />
      </Field>

      <Field
        title="Category"
        alignItems="center"
      >
        <PencilSelect
          value={element.category}
          onSave={(val: any) => {
            updateElementData({ category: val });
          }}
          options={categoryOptions}
          trigger={element.id}
        />
      </Field>
    </>
  );
};

export default ElementHeader;
