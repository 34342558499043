import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, TextField } from '@material-ui/core';
import ActionButton from '../../../../components/ActionButton';
import Select from '../../../../components/Select';
import useElementData, { ElementDataProvider } from '../../hooks/element-data';
import useElementCreator from '../../hooks/element-creator';
import useForm from '../../../../hooks/form';
import createElementFormConfig, { createElementDefaultValues } from './create-element-form-config';

const TYPE_OPTIONS = [
  { value: 'program', label: 'Program' },
  { value: 'component', label: 'Component' },
  { value: 'system', label: 'System' },
];

const CreateButton = styled(ActionButton)`
  border: none;
  padding-left: 0;
`;

const Story = styled.div`
  padding: 10px;

  .fieldName {
    padding-top: 10px;
  }

  .MuiSelect-root {
    min-width: 300px;
    margin-top: 0;
  }
`;

const Controls = styled.div`
  text-align: center;

  button {
    margin-right: 20px;
  }
`;

const ErrorDiv = styled.div`
  color: red;
  padding: 10px;
  text-align: center;

  :empty {
    display: none;
  }
`;

const CreateElementForm = () => {
  const [open, setOpen] = useState(false);

  const { frequencyOptions, categoryOptions, schemas, facilities } = useElementData();
  const { createElement, lastCreatedId, error } = useElementCreator();

  const { propsForField, submit, clear } = useForm(createElementFormConfig, createElementDefaultValues, createElement);

  // Close the popup when an element is successfully created
  useEffect(() => {
    if (lastCreatedId) {
      setOpen(false);
      clear();
    }
  }, [lastCreatedId]);

  return (
    <ElementDataProvider>
      <Dialog open={open}>
        <DialogTitle>Create Element</DialogTitle>
        <DialogContent style={{ minWidth: 'min(100vw, 500px)' }}>
          <ErrorDiv>{error}</ErrorDiv>

          <Story>
            <div className="fieldName">Type</div>
            <Select
              {...propsForField('type')}
              options={TYPE_OPTIONS}
            />
          </Story>

          <Story>
            <div className="fieldName">Facility</div>
            <Select
              {...propsForField('facilityId')}
              options={facilities.filter((r: any) => r.label !== 'Unknown')}
            />
          </Story>

          <Story>
            <div className="Unit Schema">Unit Schema</div>
            <Select
              options={schemas}
              {...propsForField('schemaName')}
            />
          </Story>

          <Story>
            <div className="fieldName">Name</div>
            <TextField {...propsForField('name')} />
          </Story>

          <Story>
            <div className="fieldName">Reporting Frequency</div>
            <Select
              options={frequencyOptions}
              {...propsForField('reportingFrequency')}
            />
          </Story>

          <Story>
            <div className="fieldName">Category</div>
            <Select
              options={categoryOptions}
              {...propsForField('category')}
            />
          </Story>

          <Controls>
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
            >
              Save
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Controls>
        </DialogContent>
      </Dialog>

      <CreateButton onClick={() => setOpen(true)}>+ Create Element</CreateButton>
    </ElementDataProvider>
  );
};

export default CreateElementForm;
