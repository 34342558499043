import { UnitScoreCell, ColourBox } from './StyledComponents';

export const InteractiveCell = ({
  children,
  colour,
  overridden = false,
  onClick,
}: {
  children: React.ReactNode;
  colour: string;
  overridden?: boolean;
  onClick: () => void;
}) => (
  <UnitScoreCell
    onClick={onClick}
    padding="none"
    align="center"
  >
    <ColourBox
      colour={colour}
      overridden={overridden}
    >
      {children}
    </ColourBox>
  </UnitScoreCell>
);
