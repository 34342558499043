import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actionActions from '../state/actions/actions';
import { MyElement } from '../types/my';

const useNewAction = (element: MyElement | null) => {
  const dispatch = useDispatch();
  const [newAction, setNewAction] = useState(null as any);

  const { elementId, elementType } = element || {};

  const showNewActionDialog = useCallback(() => {
    setNewAction({ elementId, elementType });
  }, [setNewAction, elementId]);

  const clearNewAction = useCallback(() => {
    setNewAction(null);
  }, [setNewAction]);

  const saveNewAction = useCallback(
    (action: any) => {
      dispatch(actionActions.createActionRequest({ ...action, elementId }));
      clearNewAction();
    },
    [dispatch, clearNewAction, elementId]
  );

  return {
    newAction,
    showNewActionDialog,
    clearNewAction,
    saveNewAction,
  };
};

export default useNewAction;
