import { Grid } from '@material-ui/core';
import { useState } from 'react';
import ActionButton from '../../../components/ActionButton';
import FormDialog from '../../../components/FormDialog';
import ReferenceForm from '../forms/ReferenceForm';
import ForcedOutageReport from './ForcedOutageReport';

const DetailsTab: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const openDialog = (edit = false) => {
    setShowDialog(true);
    setIsEdit(edit);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && (
        <FormDialog
          title={`${isEdit ? 'Edit' : 'Add'} reference`}
          onCancel={() => setShowDialog(false)}
          hideSave={true}
        >
          <ReferenceForm
            handleClose={closeDialog}
            edit={isEdit}
          />
        </FormDialog>
      )}
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          container
          xs={12}
          spacing={1}
        >
          <Grid item>
            <ActionButton
              color="primary"
              onClick={() => openDialog(false)}
            >
              Add Reference
            </ActionButton>
          </Grid>
          <Grid item>
            <ActionButton onClick={() => openDialog(true)}>Edit Reference</ActionButton>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ForcedOutageReport
            tab="details"
            width="1620px"
            height="800px"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsTab;
