import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import globalTheme from '../../../../theme';

const colourPath = s => ['colours', ...R.split('.', s)];

const ColourBox = styled(props => <Box {...R.omit(['path', 'border'], props)} />)`
  background-color: ${({ path, theme }) => R.path(colourPath(path), theme)};
  border: ${({ border = 'none' }) => border};
  width: 128px;
  height: 40px;
`;

const ColourChipContainer = styled(Grid).attrs({
  item: true,
})`
  display: flex;
  flex-direction: column;
`;

const Section = ({ children, name }) => (
  <>
    <Typography variant="h4">{name}</Typography>
    <Grid container>{children}</Grid>
  </>
);

const ColourChip = ({ name, path, border }) => (
  <ColourChipContainer>
    <ColourBox
      path={path}
      border={border}
    />
    <Typography>{name}</Typography>
  </ColourChipContainer>
);

const section = (name, path) => ({
  name,
  path,
});

const sections = [
  section('Greys', 'greys'),
  section('Teals', 'teals'),
  section('Blues', 'blues'),
  section('Greens', 'greens'),
  section('Yellows', 'yellows'),
  section('Reds', 'reds'),
  section('Pinks', 'pinks'),
];

const Colours = () => {
  const chipsFromPath = path =>
    R.keys(R.path(colourPath(path), globalTheme)).map(name => (
      <ColourChip
        key={name}
        name={name}
        path={`${path}.${name}`}
      />
    ));

  const sectionItems = sections.map(({ name, path }) => (
    <Grid
      key={name}
      item
      xs={12}
    >
      <Section name={name}>{chipsFromPath(path)}</Section>
    </Grid>
  ));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Section name="Basics">
          <ColourChip
            name="Black"
            path="black"
          />
          <ColourChip
            name="White"
            path="white"
            border="1px solid black"
          />
        </Section>
      </Grid>
      {sectionItems}
    </Grid>
  );
};

export default Colours;
