import { FormControl } from '@material-ui/core';
import CustomFormLabel from './CustomFormLabel';
import UserSelectList from './UserSelectList';

interface Props {
  label?: string;
  userId: string;
  onChange(x: any): void;
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

const UserSelectListForm = ({ label, userId, onChange, error, disabled, fullWidth }: Props) => (
  <FormControl
    error={error}
    disabled={disabled}
    fullWidth={fullWidth}
  >
    {label && <CustomFormLabel style={{ marginBottom: '3%' }}>{label}</CustomFormLabel>}
    <UserSelectList
      label={label}
      onUserSelect={onChange}
      userId={userId}
    />
  </FormControl>
);

export default UserSelectListForm;
