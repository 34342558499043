import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadRequest = (elementId, indicatorId) => ({
  type: actionTypes.INDICATOR_RECORDS_LOAD_REQUEST,
  payload: {
    elementId,
    indicatorId,
  },
});

export const loadSuccess = (elementId, indicatorId, records) => ({
  type: actionTypes.INDICATOR_RECORDS_LOAD_SUCCESS,
  payload: {
    elementId,
    indicatorId,
    records,
  },
});

export const loadFailure = errorAction(actionTypes.INDICATOR_RECORDS_LOAD_FAILURE);

export const loadByQuarterRequest = (elementId, year, quarter, indicatorId, applicable) => ({
  type: actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    applicable,
  },
});

export const loadByQuarterSuccess = (elementId, year, quarter, indicatorId, records) => ({
  type: actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    records,
  },
});

export const loadByQuarterFailure = errorAction(actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_FAILURE);

export const updateApplicabilityRequest = (
  elementId,
  year,
  quarter,
  indicatorId,
  indicatorShortName,
  recordId,
  applicable,
  options
) => ({
  type: actionTypes.INDICATOR_RECORDS_UPDATE_APPLICABILITY_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    indicatorShortName,
    recordId,
    applicable,
    options,
  },
});

export const updateApplicabilitySuccess = (
  elementId,
  year,
  quarter,
  indicatorId,
  indicatorShortName,
  recordId,
  applicable,
  options
) => ({
  type: actionTypes.INDICATOR_RECORDS_UPDATE_APPLICABILITY_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    indicatorShortName,
    recordId,
    applicable,
    options,
  },
});

export const updateApplicabilityFailure = errorAction(actionTypes.INDICATOR_RECORDS_UPDATE_APPLICABILITY_FAILURE);
