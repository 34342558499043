import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadAllRequest = () => ({
  type: actionTypes.USERS_LOAD_ALL_REQUEST,
});

export const loadAllSuccess = users => ({
  type: actionTypes.USERS_LOAD_ALL_SUCCESS,
  payload: {
    users,
  },
});

export const loadFailure = errorAction(actionTypes.USERS_LOAD_ALL_FAILURE);
