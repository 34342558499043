import Scorecard from '../../../../components/Scorecard/Scorecard';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import useQuarters from '../../../../hooks/quarters';
import { TabProps } from '../../types';

const ScorecardTab: React.FC<TabProps> = ({ element }) => {
  const { quarters } = useQuarters({ n: 1 });
  return (
    <ElementIndicatorsProvider elementId={element.elementId}>
      <Scorecard
        facilityId={element.facilityId}
        elementId={Number(element.elementId)}
        elementName={element.elementName}
        elementType={element.elementType}
        year={quarters[0].year}
        quarter={quarters[0].quarter}
        isLive
      />
    </ElementIndicatorsProvider>
  );
};

export default ScorecardTab;
