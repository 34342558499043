import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledActionButton from '../../../../../../../components/ActionButton';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMemo } from 'react';

const ActionButton = styled(UnstyledActionButton)``;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colours.greys.lightGrey9};
  border: ${({ theme }) => theme.borders.thin.solid4};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow1};
  padding: 16px 20px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${ActionButton} {
    margin-left: 20px;
  }
`;

interface Props {
  className?: string;
  record: any;
  onApply(x: any): void;
  onDismiss(triggerCommentBox?: boolean): void;
  setOpen(x: boolean): void;
}

const isCHR = (record: any) => {
  if (
    record.indicatorShortName === 'comp-functional-failures' ||
    record.indicatorShortName === 'comp-equipment-defects'
  ) {
    return true;
  }
  return false;
};

const canDismiss = (record: any) => R.isNil(record.applicable) || record.applicable;

const PopupInputPanel = ({ className, record, onApply, onDismiss, setOpen }: Props) => {
  const isRecordEvaluated = useMemo(() => !R.isNil(record.applicable), [record.applicable]);
  const isRecordApplicable = useMemo(() => Boolean(record.applicable), [record.applicable]);
  return (
    <div>
      {isCHR(record) ? (
        <Container className={className}>
          <IconButton
            style={{ marginRight: '50%' }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
          <ActionButton onClick={() => onApply({ onlyComments: true })}>Neither (WITH COMMENTS)</ActionButton>
          <ActionButton onClick={() => onDismiss()}>Neither</ActionButton>
          <div>
            <ActionButton
              color="primary"
              onClick={() => {
                onApply({ recordType: 'comp-functional-failures' });
              }}
            >
              {record.applicable ? 'Update as FF' : 'Apply FF'}
            </ActionButton>
            <ActionButton
              color="primary"
              onClick={() => {
                onApply({ recordType: 'comp-equipment-defects' });
              }}
            >
              {record.applicable ? 'Update as ED' : 'Apply ED'}
            </ActionButton>
          </div>
        </Container>
      ) : (
        <Container className={className}>
          <IconButton
            style={{ marginRight: '15%' }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
          {(!isRecordEvaluated || isRecordApplicable) && (
            <>
              <ActionButton
                style={{ whiteSpace: 'nowrap', width: '100%' }}
                onClick={() => {
                  onApply({ onlyComments: true, isActivated: false });
                }}
                disabled={!canDismiss(record)}
              >
                Do Not Apply (WITH COMMENTS)
              </ActionButton>
              <ActionButton
                style={{ whiteSpace: 'nowrap', width: '50%' }}
                onClick={() => onDismiss(false)}
                disabled={!canDismiss(record)}
              >
                Do Not Apply
              </ActionButton>
            </>
          )}
          <ActionButton
            style={{ whiteSpace: 'nowrap', width: '50%' }}
            color="primary"
            onClick={() =>
              isRecordEvaluated && isRecordApplicable
                ? onApply({ onlyComments: true, isActivated: true, comment: record.applicabilityDescription })
                : onApply({
                    onlyComments: isRecordApplicable,
                    recordType: record.indicatorShortName,
                    isActivated: true,
                    comment: '',
                  })
            }
          >
            {isRecordEvaluated && isRecordApplicable ? 'Update Comments' : 'Apply'}
          </ActionButton>
          {!isRecordApplicable && isRecordEvaluated && (
            <ActionButton
              style={{ whiteSpace: 'nowrap', width: '100%' }}
              color="primary"
              onClick={() => {
                onApply({ onlyComments: true });
              }}
            >
              Update Comments
            </ActionButton>
          )}
        </Container>
      )}
    </div>
  );
};
export default PopupInputPanel;
