import { Paper } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page';
import { WalkdownsProvider } from '../../hooks/walkdowns';
import InprogressPage from './InprogressPage';
import ArchivesPage from './ArchivesPage';
import FilterButtons from './components/FilterButtons';
import { WalkdownTemplatesListProvider } from './hooks/useWalkdownTemplatesList';
import Templates from './templates';
import SingleTemplate from './templates/SingleTemplate';
import { WalkdownTemplateProvider } from './hooks/useWalkdownTemplate';
import { Downloader } from '../../hooks/downloader';
import { MyRecentAssignmentsProvider } from '../../hooks/my-recent-assignments';
import StartWalkdown from './StartWalkdown';
import SingleWalkdown from './SingleWalkdown';
import LegacyRedirect from './components/LegacyRedirect';

const WrappedSingleTemplate = ({ match }: any) => (
  <WalkdownTemplateProvider templateId={match.params.id}>
    <SingleTemplate />
  </WalkdownTemplateProvider>
);

const WalkdownPage = () => {
  const match = useRouteMatch();

  return (
    <Page
      title="Walkdown"
      bodyComponent={<Paper square />}
    >
      <MyRecentAssignmentsProvider>
        <FilterButtons href={match.path} />
      </MyRecentAssignmentsProvider>
      <Switch>
        <Route
          exact
          path={match.path}
          component={InprogressPage}
        />
        <Route
          exact
          path={`${match.path}/archives`}
          component={ArchivesPage}
        />

        <Route
          path={`${match.path}/templates/view/:id`}
          component={WrappedSingleTemplate}
        />
        <Route
          exact
          path={`${match.path}/start-walkdown`}
          component={StartWalkdown}
        />
        <Route
          exact
          path={[`${match.path}/archives/view/:walkdownId`, `${match.path}/in-progress/view/:walkdownId`]}
          component={SingleWalkdown}
        />

        <Route
          exact
          path={`${match.path}/view/:walkdownId`}
          render={() => <LegacyRedirect oldPath={match.path} />}
        />
        <Route path={`${match.path}/templates`}>
          <WalkdownTemplatesListProvider>
            <Templates />
          </WalkdownTemplatesListProvider>
        </Route>
      </Switch>
    </Page>
  );
};

export default () => (
  <Downloader>
    <WalkdownsProvider>
      <WalkdownPage />
    </WalkdownsProvider>
  </Downloader>
);
