import * as actionTypes from '../action-types';

const states = {
  IDLE: 'idle',
  EDITING_EXECUTIVE_SUMMARY: 'editingExecutiveSummary',
  SAVING_EXECUTIVE_SUMMARY: 'savingExecutiveSummary',
  FINALIZING_EXECUTIVE_SUMMARY: 'finalizingExecutiveSummary',
  DELETING_COMMENT: 'deletingComment',
  UPDATING_COMMENT_SEQUENCE: 'updatingCommentSequence',
  EDITING_COMMENT: 'editingComment',
  SAVING_COMMENT: 'savingComment',
  FINALIZING_COMMENT: 'finalizingComment',
};

const newState = (stateName, commentId) => ({
  stateName,
  commentId,
});

const initialState = newState(states.IDLE);

const reducer = (state = initialState, action) => {
  if (action.type === actionTypes.EXECUTIVE_SUMMARY_UI_RESET) {
    return newState(states.IDLE);
  }

  switch (state.stateName) {
    case states.IDLE: {
      switch (action.type) {
        case actionTypes.EXECUTIVE_SUMMARY_UI_EDIT_EXECUTIVE_SUMMARY:
          return newState(states.EDITING_EXECUTIVE_SUMMARY);
        case actionTypes.REPORTS_DELETE_COMMENT_REQUEST:
          return newState(states.DELETING_COMMENT, action.payload.commentId);
        case actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_REQUEST:
          return newState(states.UPDATING_COMMENT_SEQUENCE, action.payload.commentId);
        case actionTypes.EXECUTIVE_SUMMARY_UI_EDIT_COMMENT:
          return newState(states.EDITING_COMMENT);
        default:
          return state;
      }
    }
    case states.EDITING_EXECUTIVE_SUMMARY: {
      switch (action.type) {
        case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_REQUEST:
          return newState(states.SAVING_EXECUTIVE_SUMMARY);
        case actionTypes.REPORTS_FINALIZE_EXECUTIVE_SUMMARY_REQUEST:
          return newState(states.FINALIZING_EXECUTIVE_SUMMARY);
        default:
          return state;
      }
    }
    case states.SAVING_EXECUTIVE_SUMMARY: {
      switch (action.type) {
        case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_SUCCESS:
        case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_FAILURE:
          return newState(states.EDITING_EXECUTIVE_SUMMARY);
        default:
          return state;
      }
    }
    case states.FINALIZING_EXECUTIVE_SUMMARY: {
      switch (action.type) {
        case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_SUCCESS:
          return newState(states.IDLE);
        case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_FAILURE:
          return newState(states.EDITING_EXECUTIVE_SUMMARY);
        default:
          return state;
      }
    }
    case states.DELETING_COMMENT: {
      switch (action.type) {
        case actionTypes.REPORTS_DELETE_COMMENT_SUCCESS:
        case actionTypes.REPORTS_DELETE_COMMENT_FAILURE:
          return newState(states.IDLE);
        default:
          return state;
      }
    }
    case states.UPDATING_COMMENT_SEQUENCE: {
      switch (action.type) {
        case actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_SUCCESS:
        case actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_FAILURE:
          return newState(states.IDLE);
        default:
          return state;
      }
    }
    case states.EDITING_COMMENT: {
      switch (action.type) {
        case actionTypes.REPORTS_SAVE_COMMENT_REQUEST:
          return newState(states.SAVING_COMMENT);
        case actionTypes.REPORTS_FINALIZE_COMMENT_REQUEST:
          return newState(states.FINALIZING_COMMENT, action.payload.commentId);
        default:
          return state;
      }
    }
    case states.SAVING_COMMENT: {
      switch (action.type) {
        case actionTypes.REPORTS_CREATE_COMMENT_SUCCESS:
        case actionTypes.REPORTS_UPDATE_COMMENT_SUCCESS:
        case actionTypes.REPORTS_SAVE_COMMENT_FAILURE:
          return newState(states.EDITING_COMMENT);
        default:
          return state;
      }
    }
    case states.FINALIZING_COMMENT: {
      switch (action.type) {
        case actionTypes.REPORTS_CREATE_COMMENT_SUCCESS:
        case actionTypes.REPORTS_UPDATE_COMMENT_SUCCESS:
          return newState(states.IDLE);
        case actionTypes.REPORTS_SAVE_COMMENT_FAILURE:
          return newState(states.EDITING_COMMENT);
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default reducer;
