import React from 'react';
import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import ReportReview from '../../../../components/ReportReview';
import useSubmitReport from '../../../../hooks/submit-report';
import useCallWithRedirect from '../../../../hooks/call-with-redirect';
import useCanSubmit from '../../hooks/can-submit';
import useConfirm from '../../../../hooks/confirm';
import ReportSubmitDialog from '../../../../components/ReportSubmitDialog';
import useUsers from '../../../../hooks/users';
import Conditional from '../../../../components/Conditional';
import { Report } from '../../../../hooks/report';
import { CircularProgress } from '@material-ui/core';

const recordSummary = (ffCount: number, ocaCount: number) =>
  R.join(', ', [
    ...(ffCount > 0 ? [`${ffCount} functional failures`] : []),
    ...(ocaCount > 0 ? [`${ocaCount} outstanding functional failure corrective actions`] : []),
  ]);

const ReviewAndSubmitPage = ({ report }: { report: Report }) => {
  const { users } = useUsers();
  const { submitReport } = useSubmitReport(report);
  const submitAndRedirect = useCallWithRedirect('/app/health-reports', submitReport);
  const { canSubmit, ffCount, ocaCount } = useCanSubmit(report);
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(submitAndRedirect);

  return (
    <>
      {report.state !== 'pending-review' && (
        <Conditional
          condition={!canSubmit && ffCount && ocaCount && Boolean(ffCount > 0 || ocaCount > 0)}
          contents={
            <Alert severity="error">
              You have unreviewed records ({recordSummary(ffCount || 0, ocaCount || 0)}). You will be able to submit
              when they have been reviewed.
            </Alert>
          }
          fallback={
            <>
              {!canSubmit && (
                <Alert severity="error">
                  You have unreviewed records. You will be able to submit when they have been reviewed.
                </Alert>
              )}
              {canSubmit === 'Not Checked' && (
                <Alert severity="info">
                  Checking if your report is ready for submission
                  <CircularProgress style={{ height: '1.5vh', width: '1.5vh', marginLeft: '0.5vw' }} />
                </Alert>
              )}
            </>
          }
        />
      )}
      <ReportReview
        className=""
        report={report}
        onPrimary={canSubmit === true ? confirm : undefined}
        primaryText={report.state === 'unsubmitted' ? 'Submit Report' : 'Resubmit Report'}
      />
      {confirming === true && (
        <ReportSubmitDialog
          title="Confirm Submit"
          options={users.map(({ name, id }: any) => ({ label: name, value: id }))}
          onSave={onConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default ReviewAndSubmitPage;
