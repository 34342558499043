import { useState, useCallback } from 'react';

const useToggle = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const onToggle = useCallback(() => {
    setValue(!value);
  }, [value, setValue]);

  return {
    value,
    onToggle,
  };
};

export default useToggle;
