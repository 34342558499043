import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledTypography from '@material-ui/core/Typography';
import UnstyledColouredBox from './ScoreColouredBox';

const ScoreColouredBox = styled(UnstyledColouredBox)``;

export const ScoreColouredText = styled(props => (
  <UnstyledTypography {...Object.assign({}, R.omit(['scoreColour'], props))} />
))`
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme, scoreColour = 'white' }) =>
    R.pathOr(theme.colours.black, ['colours', 'scorecard', scoreColour, 'text'], theme)};
`;

interface Props {
  className?: string;
  totalScore: number | null;
  scoreColour: string;
  overridden?: boolean;
}

const TotalScoreBox = ({ className, totalScore, scoreColour, overridden }: Props) => (
  <ScoreColouredBox
    overridden={overridden}
    className={className}
    scoreColour={scoreColour}
  >
    <ScoreColouredText scoreColour={scoreColour}>
      {typeof totalScore === 'number' ? totalScore.toFixed(2) : ''}
    </ScoreColouredText>
  </ScoreColouredBox>
);

export default TotalScoreBox;
