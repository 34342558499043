import { useCallback, useEffect, useState } from 'react';
import Api from '../api';

const useAdminCategories = () => {
  const [categories, setCategories] = useState([]);

  const getCategories = useCallback(async () => {
    const result = await Api.getAllCategories();
    setCategories(result);
  }, [Api]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const deleteCategory = async (categoryId: number) => {
    await Api.deleteCategory(categoryId);
    getCategories();
  };
  const addCategory = async (categoryName: string) => {
    await Api.addCategory(categoryName);
    getCategories();
  };
  const updateCategory = async (categoryId: number, categoryName: string) => {
    await Api.updateCategory(categoryId, categoryName);
    getCategories();
  };

  return {
    categories,
    deleteCategory,
    addCategory,
    updateCategory,
  };
};
export default useAdminCategories;
