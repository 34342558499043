import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SmallIconButton = styled(IconButton).attrs({
  size: 'small',
})`
  color: ${({ theme }) => theme.colours.white};
`;

interface Props {
  className?: string;
  onClick?(): void;
}

const CloseButton = ({ className, onClick }: Props) => (
  <SmallIconButton
    className={className}
    onClick={onClick}
  >
    <CloseIcon data-testid="close-icon" />
  </SmallIconButton>
);

export default CloseButton;
