/* eslint-disable @typescript-eslint/no-explicit-any */
import { Remote, Path } from '../types';

const getReports = (path: Path, remote: Remote) => (states: Array<string>) =>
  remote.get(path('/'), { params: { states: states.join(',') } });

const getReport = (path: Path, remote: Remote) => (reportId: string) => remote.get(path(`/${reportId}`));

const getReportActions = (path: Path, remote: Remote) => (reportId: string, elementId: string, state: any) =>
  remote.get(path(`/${reportId}/actions/${elementId}`), { params: { state } });

const createReport = (path: Path, remote: Remote) => (elementId: string, year: string, quarter: any) =>
  remote.post(path('/'), { report: { elementId, year, quarter } });

const getReportComments = (path: Path, remote: Remote) => (reportId: string) =>
  remote.get(path(`/${reportId}/comments`));

const createReportComment = (path: Path, remote: Remote) => (reportId: string, comment: string) =>
  remote.post(path(`/${reportId}/comments`), { comment });

const updateReportComment =
  (path: Path, remote: Remote) => (reportId: string, commentId: string, comment: string, commentsUpdatedAt: Date) =>
    remote.patch(path(`/${reportId}/comments/${commentId}`), { comment, commentsUpdatedAt });

const deleteReportComment = (path: Path, remote: Remote) => (reportId: string, commentId: string) =>
  remote.del(path(`/${reportId}/comments/${commentId}`));

const updateReportExecutiveSummary =
  (path: Path, remote: Remote) => (reportId: string, executiveSummary: string, executiveSummaryUpdatedAt: Date) =>
    remote.patch(path(`/${reportId}`), { executiveSummary, executiveSummaryUpdatedAt });

const submitReport = (path: Path, remote: Remote) => (reportId: string, users: string[]) =>
  remote.post(path(`/${reportId}/submissions`), { users });

const approveReport = (path: Path, remote: Remote) => (reportId: string, comment: string) =>
  remote.post(path(`/${reportId}/approvals`), { comment });

const reverseApproveReport = (path: Path, remote: Remote) => (reportId: string) =>
  remote.del(path(`/${reportId}/approvals`));

const generateDraftReport = (path: Path, remote: Remote) => (reportId: string) =>
  remote.post(path(`/${reportId}/draft`));

const rejectReport = (path: Path, remote: Remote) => (reportId: string, comment: string) =>
  remote.post(path(`/${reportId}/rejections`), { comment });

const closeReport = (path: Path, remote: Remote) => (reportId: string, comment: string) =>
  remote.post(path(`/${reportId}/closures`), { comment });

const downloadReport = (path: Path, remote: Remote) => (reportId: string) => remote.download(path(`/${reportId}/pdf`));

const overrideReportTotal =
  (path: Path, remote: Remote) => (reportId: number, score: number, colour?: string, comment?: string) =>
    remote.put(path(`/${reportId}/total-score-override`), { score, colour, comment });

const clearOverrideReportTotal = (path: Path, remote: Remote) => (reportId: number) =>
  remote.del(path(`/${reportId}/total-score-override`));

const copyReportComments = (path: Path, remote: Remote) => (reportId: number, previousId: number) =>
  remote.patch(path(`/${reportId}/comments/copyFrom/${previousId}`));

export default (path: Path, remote: Remote) => ({
  getReports: getReports(path, remote),
  getReport: getReport(path, remote),
  createReport: createReport(path, remote),
  getReportComments: getReportComments(path, remote),
  getReportActions: getReportActions(path, remote),
  createReportComment: createReportComment(path, remote),
  updateReportComment: updateReportComment(path, remote),
  deleteReportComment: deleteReportComment(path, remote),
  updateReportExecutiveSummary: updateReportExecutiveSummary(path, remote),
  generateDraftReport: generateDraftReport(path, remote),
  submitReport: submitReport(path, remote),
  approveReport: approveReport(path, remote),
  reverseApproveReport: reverseApproveReport(path, remote),
  rejectReport: rejectReport(path, remote),
  closeReport: closeReport(path, remote),
  downloadReport: downloadReport(path, remote),
  overrideReportTotal: overrideReportTotal(path, remote),
  clearOverrideReportTotal: clearOverrideReportTotal(path, remote),
  copyReportComments: copyReportComments(path, remote),
});
