import { useState } from 'react';
import { TabProps } from '../../types';
import Layout from './Layout';

const ReviewRecordsTab: React.FC<TabProps> = ({ element }) => {
  const [indicatorId, setIndicatorId] = useState<string | null>('');
  const [recordId, setRecordId] = useState('');
  return (
    <Layout
      elementId={element.elementId}
      indicatorId={indicatorId ? `${indicatorId}` : ''}
      recordId={recordId}
      onChangeIndicatorId={setIndicatorId}
      onChangeRecordId={setRecordId}
    />
  );
};

export default ReviewRecordsTab;
