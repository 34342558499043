import useConditionalConfirm from '../../../../hooks/conditional-confirm';
import { List, ListItem, ListItemText } from '@material-ui/core';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import { useEffect, useState } from 'react';

export const RecentReportsList = ({ reports, onDelete }: any) => {
  const [recentReports, setRecentReports] = useState(reports);
  const { confirming, confirm, onConfirm, onCancel } = useConditionalConfirm(onDelete, true);

  useEffect(() => {
    setRecentReports(reports);
  }, [reports, onConfirm]);

  const items = recentReports.map((report: any) => (
    <ListItem
      button
      key={report.id}
      onClick={() => confirm(report.id)}
    >
      <ListItemText
        primary={`${report.elementName} ${report.facilityName}`}
        secondary={`${report.year} Q${report.quarter}`}
      />
    </ListItem>
  ));

  return (
    <>
      <List>{items}</List>
      {confirming && (
        <OkayCancelDialog
          title="Reverse Approve?"
          okayLabel="Yes"
          cancelLabel="No"
          onOkay={onConfirm}
          onCancel={onCancel}
        >
          Are you sure you wish to Reverse Approve this report?
        </OkayCancelDialog>
      )}
    </>
  );
};
