import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import Api from '../api';

const AllAttachmentTypesContext = React.createContext();

const useAllAttachmentTypes = () => {
  const context = useContext(AllAttachmentTypesContext);
  if (R.isNil(context)) {
    throw new Error('useAllAttachmentTypes must be used inside an AllAttachmentTypesProvider');
  }
  return context;
};

export const AllAttachmentTypesProvider = props => {
  const [attachmentTypes, setAttachmentTypes] = useState([]);

  useEffect(() => {
    Api.getElementAttachmentTypes().then(setAttachmentTypes);
  }, [setAttachmentTypes]);

  const value = {
    attachmentTypes,
  };

  return (
    <AllAttachmentTypesContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useAllAttachmentTypes;
