import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import Select from './Select';
import capitalize from '../utils/capitalize';

interface Option {
  value: any;
  label: string;
}

interface Props {
  value: string;
  onSave(val: any): void;
  options: Option[];
  trigger?: any;
}

const Wrapper = styled.div`
  position: relative;
  height: 30px;
  .MuiSelect-root {
    margin: 0;
  }
`;

const ButtonContainer = styled.span`
  position: absolute;
  top: -10px;
  right: 10px;
`;

const PencilSelect = ({ value, onSave, options, trigger }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [staticValue, setStaticValue] = useState(capitalize(value));
  const [selectedValue, setSelectedValue] = useState(value);

  const refreshStaticValue = (newValue = value) => {
    const newLabel = options.find(r => r.value === newValue);
    setStaticValue(newLabel?.label || '');
    setSelectedValue(value);
  };

  useEffect(refreshStaticValue, [isEditing, value]);

  // Change back to static mode when the trigger chanegs value
  useEffect(() => {
    setIsEditing(false);
    setSelectedValue(value);
  }, [trigger]);

  // save
  const saveHandler = () => {
    onSave(selectedValue);
    setIsEditing(!isEditing);
  };

  // Cancel
  const cancelHandler = () => {
    setIsEditing(!isEditing);
    setSelectedValue(value);
  };

  const changeHandler = (e: any) => {
    setSelectedValue(e.target.value);
  };

  if (isEditing) {
    return (
      <Wrapper>
        <Select
          allowEmpty={false}
          options={options}
          onChange={changeHandler}
          value={selectedValue}
        />

        <ButtonContainer>
          <IconButton onClick={saveHandler}>
            <CheckIcon />
          </IconButton>

          <IconButton onClick={cancelHandler}>
            <CancelIcon />
          </IconButton>
        </ButtonContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Typography>{staticValue}</Typography>
      <ButtonContainer>
        <IconButton onClick={() => setIsEditing(!isEditing)}>
          <EditIcon />
        </IconButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default PencilSelect;
