import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Attachment from './Attachment';

const ListItem = styled.li`
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => theme.borders.thin.solid4};
`;

const List = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;

  ${ListItem} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

interface AttachmentState {
  id: string;
  name: string;
  state: string;
  uploadId: string;
  walkdownId: number;
}

interface Props {
  className?: string;
  attachments: Array<any>;
  onDelete(id: string): void;
  onDownload(id: string): void;
}

const AttachmentList = ({ className, attachments, onDelete, onDownload }: Props) => {
  const items =
    attachments.length > 0 &&
    attachments.map((attachment: AttachmentState) => (
      <ListItem key={attachment.id}>
        <Attachment
          attachment={attachment}
          onDelete={attachment.id ? R.thunkify(onDelete)(attachment.id) : undefined}
          onDownload={attachment.id ? R.thunkify(onDownload)(attachment.id) : undefined}
        />
      </ListItem>
    ));

  return <List className={className}>{items}</List>;
};

export default AttachmentList;
