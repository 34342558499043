export type Facility = 'BA' | 'BB' | 'CS' | 'BA, BB' | 'All' | 'None';
export const FACILITIES = ['BA', 'BB', 'CS', 'BA, BB', 'All', 'None'];

export type Unit = '0A' | '0B' | 'U1' | 'U2' | 'U3' | 'U4' | 'U5' | 'U6' | 'U7' | 'U8' | 'None';

export const BA_UNITS = ['0A', 'U1', 'U2', 'U3', 'U4'] as const;
export const BB_UNITS = ['0B', 'U5', 'U6', 'U7', 'U8'] as const;
export const OTHER_UNITS = ['None'] as const;

export const getUnitsForFacility = (facility: Facility): readonly Unit[] => {
  switch (facility) {
    case 'BA':
      return BA_UNITS;
    case 'BB':
      return BB_UNITS;
    case 'BA, BB':
    case 'All':
      return [...BA_UNITS, ...BB_UNITS];
    case 'CS':
    case 'None':
    default:
      return [...OTHER_UNITS];
  }
};
