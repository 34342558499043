/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import MUITypography from '@material-ui/core/Typography';

const InputContainer = styled.div`
  font: ${({ theme }) => theme.fonts.bold12};
  color: ${({ theme }) => theme.colours.teals.teal1};
  text-decoration: underline;

  cursor: pointer;
  input {
    display: none;
  }
`;

const ChooseFile = React.forwardRef(
  (
    {
      className,
      onChange,
      children,
    }: { className?: string; onChange?(event: React.ChangeEvent<HTMLInputElement>): void; children: React.ReactNode },
    ref
  ) => (
    <InputContainer className={className}>
      <input
        type="file"
        ref={ref as any}
        onChange={onChange}
      />
      {children}
    </InputContainer>
  )
);

const Typography = styled(MUITypography)`
  font: ${({ theme }) => theme.fonts.normal12};
  color: ${({ theme }) => theme.colours.teals.teal1};
  padding-right: 4px;
`;

const Container = styled.div`
  border: ${({ theme }) => theme.borders.teal.dashed1};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
`;

const FileDropZone = ({ className, onFileDrop }: { className?: string; onFileDrop(files: Array<any>): void }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFileDrop });

  return (
    <Container
      {...getRootProps({
        className,
      })}
    >
      <Typography>Drop files in here to upload or</Typography>
      <ChooseFile {...getInputProps()}>choose file</ChooseFile>
    </Container>
  );
};

export default FileDropZone;
