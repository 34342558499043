import { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import useReportComments from '../../../../../hooks/report-comments';
import * as executiveSummaryUiActions from '../../../../../state/executive-summary-ui/actions';
import * as reportsActions from '../../../../../state/reports/actions';

const useComments = reportId => {
  const dispatch = useDispatch();

  const { comments } = useReportComments(reportId);

  const [deletingCommentIndex, setDeletingCommentIndex] = useState(null);
  const deletingComment = useMemo(() => R.nth(deletingCommentIndex, comments), [deletingCommentIndex, comments]);

  const onDeleteComment = useCallback(
    commentIndex => {
      setDeletingCommentIndex(commentIndex);
    },
    [setDeletingCommentIndex]
  );

  const onConfirmDeleteComment = useCallback(() => {
    dispatch(reportsActions.deleteCommentRequest(reportId, deletingComment.id));
    setDeletingCommentIndex(null);
  }, [dispatch, reportId, deletingComment, setDeletingCommentIndex]);

  const onCancelDeleteComment = useCallback(() => {
    setDeletingCommentIndex(null);
  }, [setDeletingCommentIndex]);

  const onReorderComment = useCallback(
    (commentId, newSequence) => {
      dispatch(reportsActions.updateCommentSequenceRequest(reportId, commentId, newSequence));
    },
    [dispatch, reportId]
  );

  const [editingCommentIndex, setEditingCommentIndex] = useState(null);
  const editingComment = useMemo(() => R.nth(editingCommentIndex, comments), [editingCommentIndex, comments]);

  const onEditComment = useCallback(
    commentIndex => {
      dispatch(executiveSummaryUiActions.editComment());
      setEditingCommentIndex(commentIndex);
    },
    [dispatch, setEditingCommentIndex]
  );

  const onAddComment = useCallback(() => {
    dispatch(reportsActions.addComment(reportId, `Section ${comments.length + 1}`, ''));
    onEditComment(comments.length);
  }, [dispatch, comments, reportId, onEditComment]);

  const onSaveComment = useCallback(
    (title, body) => {
      const commentsUpdatedAt = new Date().toLocaleString();
      dispatch(
        reportsActions.saveCommentRequest(
          reportId,
          editingComment.id,
          commentsUpdatedAt,
          editingComment.sequence,
          title,
          body
        )
      );
    },
    [dispatch, reportId, editingComment]
  );

  const onFinalizeComment = useCallback(
    (title, body) => {
      const commentsUpdatedAt = new Date().toLocaleString();
      dispatch(
        reportsActions.finalizeCommentRequest(
          reportId,
          editingComment.id,
          commentsUpdatedAt,
          editingComment.sequence,
          title,
          body
        )
      );
    },
    [dispatch, reportId, editingComment]
  );

  return {
    comments,
    deletingCommentIndex,
    deletingComment,
    onDeleteComment,
    onConfirmDeleteComment,
    onCancelDeleteComment,
    onReorderComment,
    editingCommentIndex,
    editingComment,
    onEditComment,
    onAddComment,
    onSaveComment,
    onFinalizeComment,
  };
};

export default useComments;
