import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadMyDashboardsSuccess = dashboards => ({
  type: actionTypes.MY_DASHBOARDS_LOAD_SUCCESS,
  payload: {
    dashboards,
  },
});

export const loadMyDashboardsRequest = () => ({
  type: actionTypes.MY_DASHBOARDS_LOAD_REQUEST,
});

export const loadMyDashboardsFailure = errorAction(actionTypes.MY_DASHBOARDS_LOAD_FAILURE);

export const addMyDashboardFavouriteRequest = dashboardId => ({
  type: actionTypes.MY_DASHBOARDS_ADD_FAVOURITE_REQUEST,
  payload: {
    dashboardId,
  },
});

export const addMyDashboardFavouriteSuccess = dashboardId => ({
  type: actionTypes.MY_DASHBOARDS_ADD_FAVOURITE_SUCCESS,
  payload: {
    dashboardId,
  },
});

export const addMyDashboardFavouriteFailure = errorAction(actionTypes.MY_DASHBOARDS_ADD_FAVOURITE_FAILURE);

export const removeMyDashboardFavouriteRequest = dashboardId => ({
  type: actionTypes.MY_DASHBOARDS_REMOVE_FAVOURITE_REQUEST,
  payload: {
    dashboardId,
  },
});

export const removeMyDashboardFavouriteSuccess = dashboardId => ({
  type: actionTypes.MY_DASHBOARDS_REMOVE_FAVOURITE_SUCCESS,
  payload: {
    dashboardId,
  },
});

export const removeMyDashboardFavouriteFailure = errorAction(actionTypes.MY_DASHBOARDS_REMOVE_FAVOURITE_FAILURE);

export const loadDashboardsRequest = () => ({ type: actionTypes.DASHBOARDS_LOAD_REQUEST });

export const loadDashboardsSuccess = dashboards => ({
  type: actionTypes.DASHBOARDS_LOAD_SUCCESS,
  payload: {
    dashboards,
  },
});

export const loadDashboardsFailure = errorAction(actionTypes.DASHBOARDS_LOAD_FAILURE);

export const createDashboardRequest = dashboard => ({
  type: actionTypes.DASHBOARDS_CREATE_REQUEST,
  payload: {
    dashboard,
  },
});

export const createDashboardSuccess = dashboard => ({
  type: actionTypes.DASHBOARDS_CREATE_SUCCESS,
  payload: {
    dashboard,
  },
});

export const createDashboardFailure = errorAction(actionTypes.DASHBOARDS_CREATE_FAILURE);

export const deleteDashboardRequest = dashboardId => ({
  type: actionTypes.DASHBOARDS_DELETE_REQUEST,
  payload: {
    dashboardId,
  },
});

export const deleteDashboardSuccess = dashboardId => ({
  type: actionTypes.DASHBOARDS_DELETE_SUCCESS,
  payload: {
    dashboardId,
  },
});

export const deleteDashboardFailure = errorAction(actionTypes.DASHBOARDS_DELETE_FAILURE);

export const updateDashboardRequest = dashboard => ({
  type: actionTypes.DASHBOARDS_UPDATE_REQUEST,
  payload: {
    dashboard,
  },
});

export const updateDashboardSuccess = dashboard => ({
  type: actionTypes.DASHBOARDS_UPDATE_SUCCESS,
  payload: {
    dashboard,
  },
});

export const updateDashboardFailure = errorAction(actionTypes.DASHBOARDS_UPDATE_FAILURE);
