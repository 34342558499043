import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import {
  FormControl as UnstyledFormControl,
  InputLabel as UnstyledInputLabel,
  Select as UnstyledSelect,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  Theme,
  useTheme,
} from '@material-ui/core';

const InputLabel = styled(UnstyledInputLabel)`
  text-transform: uppercase;
  top: calc(-50% + 1px);
  left: 16px;
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme }) => theme.colours.greys.darkGrey3};

  &.Mui-focused {
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
  }
`;

const FormControl = styled(UnstyledFormControl)`
  min-width: 120px;
  background-color: ${({ theme }) => theme.colours.greys.lightGrey4};
  border: ${({ theme }) => theme.borders.thin.solid1};
  border-radius: 2px;
`;

const Select = styled(UnstyledSelect)``;

interface Props {
  className?: string;
  name?: string;
  elementOptions: { value: string; name: string }[];
  emptyLabel?: string;
  disableEmpty?: boolean;
  onChange(value: any): void;
  multipleSelections?: boolean;
}

const styleGet = (item: any, itemNames: readonly string[], theme: Theme) => {
  return {
    fontWeight: itemNames.indexOf(item) !== -1 ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
};

const chipStyle = {
  color: 'black',
  backgroundColor: 'light-grey',
  marginTop: 3,
  marginBottom: 3,
  maxWidth: '100%',
};

//eslint-disable-next-line @typescript-eslint/comma-dangle
const FilterSelectorDeferrals = ({
  className,
  name,
  elementOptions,
  emptyLabel = 'None',
  disableEmpty = false,
  onChange,
  multipleSelections = false,
}: Props) => {
  const theme = useTheme();
  const [itemName, setItemName] = React.useState<any[]>([]);
  const items = elementOptions.map(option => ({ name: option.name, key: option.value }));

  const maptoKeys = (names: string[]) => {
    const keys = names.map((singleName: string) => {
      const correctItem = items.find((filterName: any) => {
        return filterName.name == singleName;
      });
      if (correctItem) {
        return correctItem.key;
      } else {
        return -1;
      }
    });
    return keys;
  };

  const deleteFunction = (selectedElement: string) => {
    const newItem = R.without([selectedElement], itemName);
    setItemName(newItem);
    onChange(maptoKeys(newItem));
  };

  const handleChange = (e: any) => {
    setItemName(e.target.value);
    onChange(maptoKeys(e.target.value));
  };

  return (
    <FormControl
      className={className}
      style={{ border: '0' }}
    >
      <InputLabel>{name}</InputLabel>
      <Select
        MenuProps={{ variant: 'menu' }}
        style={{ backgroundColor: 'white' }}
        multiple={multipleSelections}
        value={R.isEmpty(elementOptions) ? [] : itemName}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', borderColor: 'white' }}>
            {selected.map((selectedElement: string) => (
              <Chip
                key={selectedElement}
                label={selectedElement}
                style={chipStyle}
                onDelete={() => deleteFunction(selectedElement)}
                onMouseDown={(event: any) => {
                  event.stopPropagation();
                }}
              />
            ))}
          </Box>
        )}
      >
        {!disableEmpty && (
          <MenuItem value="">
            <em>{emptyLabel}</em>
          </MenuItem>
        )}
        {items.map((item: any) => (
          <MenuItem
            key={item.key}
            value={item.name}
            style={styleGet(item.name, itemName, theme)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectorDeferrals;
