import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import FilterButton from '../../../../components/FilterButton';
import ActionButton from '../../../../components/ActionButton';

const LeftGroup = styled(Grid)`
  .MuiFormControl-root {
    margin-right: 20px;
  }

  ${FilterButton} {
    margin-right: 16px;
  }
`;

const Root = styled(Grid)`
  padding: 16px 59px;
`;

interface Props {
  onChangeTab(x: string): void;
  onStartNew(): void;
  currentTab: string;
}

const TAB_STATES = ['in-progress', 'archived', 'historical'];

const TITLES: Record<string, string> = {
  'in-progress': 'IN-PROGRESS REPORTS',
  archived: 'ARCHIVED REPORTS',
  historical: 'LEGACY REPORTS',
};

const FilterPanel = ({ onChangeTab, onStartNew, currentTab }: Props) => (
  <Root
    container
    justifyContent="space-between"
  >
    <LeftGroup item>
      {TAB_STATES.map(tabState => (
        <FilterButton
          isActive={currentTab === tabState}
          onClick={() => onChangeTab(tabState)}
        >
          {TITLES[tabState]}
        </FilterButton>
      ))}
    </LeftGroup>
    <Grid item>
      <ActionButton
        color="primary"
        onClick={onStartNew}
      >
        Start New Report
      </ActionButton>
    </Grid>
  </Root>
);

export default FilterPanel;
