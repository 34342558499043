import { Box, Grid, Tooltip, makeStyles } from '@material-ui/core';
import ActionsTable from './ActionsTable';
import { Action } from '../../../../../types/cwt/actions';
import theme from '../../../../../theme';

interface Props {
  actionCountByStatusId: { [key: string]: number };
  actions: Action[];
}

const ActionsCell = ({ actionCountByStatusId, actions }: Props) => {
  const useStyles = makeStyles(() => ({
    tooltip: {
      minWidth: '1000px !important',
    },
  }));

  const getCountByStatus = (statusId: number) => {
    return actionCountByStatusId[statusId] ?? 0;
  };

  const classes = useStyles();
  return (
    <Tooltip
      title={ActionsTable({ actions })}
      placement="right-start"
      arrow
      disableFocusListener
      interactive
      classes={{ tooltip: classes.tooltip }}
      data-testid="actions-tooltip"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          xs={4}
        >
          <Box
            color="black"
            bgcolor={theme.colours.yellows.yellow1}
            textAlign={'center'}
          >
            {getCountByStatus(1) + getCountByStatus(4)}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Box
            color="white"
            bgcolor={theme.colours.blues.blue1}
            textAlign={'center'}
          >
            {getCountByStatus(2)}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Box
            color="white"
            bgcolor={theme.colours.greens.green1}
            textAlign={'center'}
          >
            {getCountByStatus(3)}
          </Box>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

export default ActionsCell;
