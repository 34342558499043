import styled from 'styled-components';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import CWTToolTip from '../../components/CWTToolTip';
import { Coding } from '../types';
import { isNumericCategory } from '../utils';
import UnstyledCheckIcon from '@material-ui/icons/Check';
import UnstyledClearIcon from '@material-ui/icons/Close';

type ColumnWithRender = TypeColumn & Required<Pick<TypeColumn, 'render'>>;

const ActionRequiredIcon = styled(UnstyledCheckIcon)`
  color: ${({ theme }) => theme.colours.greens.green2};
  height: 25px;
  width: 25px;
`;

const NoActionRequiredIcon = styled(UnstyledClearIcon)`
  height: 25px;
  width: 25px;
`;

/**
 * A helper function to attach an informative tooltip to the provided column
 *
 * If a `render` function has been defined for a given column, its return value is wrapped in the tooltip.
 * Otherwise, the column value is displayed.
 *
 * @param column base column definition
 */
export const buildWorkRequestColumn = ({
  render: baseRender = ({ value }) => value,
  ...columnInfo
}: TypeColumn): ColumnWithRender => {
  return {
    ...columnInfo,
    render: args => renderValueWithTooltip(baseRender(args), 'Double click to open record'),
  };
};

export const renderValueWithTooltip = (value: React.ReactNode, label: string) => {
  // MUI Tooltip requires a nonempty child element, so render a &nbsp character if value is empty or undefined.
  const tooltipValue = value || '\u00A0';
  return (
    <CWTToolTip
      label={label}
      value={tooltipValue}
      size="small"
    />
  );
};

export const renderRelatedWorkOrders = ({ value }: { value: string[] }) => value.join(', ');

export const getInputTypeForCategory = (category: Coding['category'] | null) => {
  return category !== null && isNumericCategory(category) ? 'number' : undefined;
};

export const renderFlipIcon = ({ value }: { value: string }) => {
  if (value === 'actionRequired') {
    return renderValueWithTooltip(<ActionRequiredIcon aria-label="Action Required Indicator" />, 'Action Required');
  } else if (value === 'noActionRequired') {
    return renderValueWithTooltip(
      <NoActionRequiredIcon
        aria-label="No Action Required Indicator"
        color="error"
      />,
      'No Action Required'
    );
  } else {
    return null;
  }
};
