const parseLongDescription = (text: string) =>
  text
    .replace(/<\/div>/g, '\n')
    .replace(/<div>/g, '\n')
    .replace(/<br>/g, '\n')
    .replace(/<\/p>/g, '\n')
    .replace(/<p>/g, '\n')
    .replace(/(<([^>]+)>)/gi, '');

export default parseLongDescription;
