import { useRef } from 'react';
import Page from '../../../../components/Page';
import ScheduleCalendar from './ScheduleCalendar';
import WODetailsModal from '../../components/WODetailsModal';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import { useSchedule } from '../../hooks/useSchedule';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import AutocompleteInput from '../../../../components/AutocompleteInput';
import { Grid } from '@material-ui/core';
import {
  getPriorityOptions,
  getUnitOptions,
  getWorkGroupOptions,
  getWorkTypeOptions,
  getWorkorderOptions,
  getLocationOptions,
} from '../../wo/components/utils';
import { generateMaximoLink } from '../../utils';
interface Props {
  displayCalendar: boolean;
  setDisplayCalendar: (displayCalendar: boolean) => void;
}
const Schedule = ({ displayCalendar, setDisplayCalendar }: Props) => {
  const { handleOpen } = useWODetailsModal();
  const { filters, setFilters } = useSchedule();
  const { workorders, setSelectedWorkorderId } = useWorkOrders();

  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleEventClick = (info: any) => {
    if (clickTimeout.current !== null) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      // Open a new tab and navigate to a new link
      window.open(generateMaximoLink(info.event.extendedProps.workorderId, 'pluswotr'), '_blank');
    } else {
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null;
        setDisplayCalendar(false);
        setSelectedWorkorderId(info.event.extendedProps.workorderId);
        handleOpen('schedule');
      }, 250); // wait for 250ms before treating as a single click
    }
  };
  return (
    <Page title="Schedule">
      <Grid
        container
        spacing={2}
        style={{ marginLeft: '10px', marginBottom: '10px' }}
      >
        <Grid
          item
          xs={3}
        >
          <AutocompleteInput
            label="Work Order"
            options={getWorkorderOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, wonum: value });
            }}
            value={filters.wonum}
            multiple
            selectAll
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteInput
            label="Unit"
            options={getUnitOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, unit: value });
            }}
            value={filters.unit}
            multiple
            selectAll
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteInput
            label="Priority"
            options={getPriorityOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, wopriority: value });
            }}
            value={filters.wopriority}
            multiple
            selectAll
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteInput
            label="Work Type"
            options={getWorkTypeOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, worktype: value });
            }}
            value={filters.worktype}
            multiple
            selectAll
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteInput
            label="Crew"
            options={getWorkGroupOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, crewworkgroup: value });
            }}
            value={filters.crewworkgroup}
            multiple
            selectAll
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteInput
            label="Location"
            options={getLocationOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, location: value });
            }}
            value={filters.location}
            multiple
            selectAll
          />
        </Grid>
      </Grid>
      <WODetailsModal />
      {displayCalendar && <ScheduleCalendar handleClick={handleEventClick} />}
    </Page>
  );
};
export default Schedule;
