import { useState, useCallback } from 'react';

const useConfirm = onConfirmFn => {
  const [confirming, setConfirming] = useState(false);
  const [confirmedArgs, setConfirmedArgs] = useState([]);

  const confirm = useCallback(
    (...args) => {
      setConfirming(true);
      setConfirmedArgs(args);
    },
    [setConfirming, setConfirmedArgs]
  );

  const onConfirm = useCallback(
    (...args) => {
      onConfirmFn(...args, ...confirmedArgs);
      setConfirming(false);
      setConfirmedArgs([]);
    },
    [onConfirmFn, setConfirming, confirmedArgs, setConfirmedArgs]
  );

  const onCancel = useCallback(() => {
    setConfirming(false);
    setConfirmedArgs([]);
  }, [setConfirming, setConfirmedArgs]);

  return {
    confirming,
    confirm,
    onConfirm,
    onCancel,
  };
};

export default useConfirm;
