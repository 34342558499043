import { Container, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import useIndicator from '../../hooks/indicator';
import useIndicatorRecordId from '../../hooks/indicator-record-id';
import useIndicatorRecords from '../../hooks/indicator-records';
import useReviewIndicatorRecordApplicability from '../../hooks/review-indicator-record-applicability';
import PopUpRecordDetailsPanel from '../../pages/live-reports/tabs/review-records/components/RecordDetailsPanel/PopupIndex';
import ApplyDialog from '../ApplyDialog';
import { useDetailModal } from './hooks/detail-modal';

interface Props {
  data: any;
  indicatorId: string;
  indicatorShortName: string;
  noPromptApply: any;
  onDismiss: any;
  setOpen: any;
  onApplicable: any;
}

// TODO: This entire component is a mess. It needs to be refactored.

const DetailModal = ({
  data,
  indicatorId,
  indicatorShortName,
  noPromptApply,
  onDismiss,
  onApplicable,
  setOpen,
}: Props) => {
  const indicatorRecordId = useIndicatorRecordId(indicatorShortName);
  const { shortName } = useIndicator();
  const { records } = useIndicatorRecords(data.elementId, indicatorId);

  const { selectedRecord, setSelectedRecord, setSelectedRecordApplicable, setSelectedRecordInapplicable } =
    useReviewIndicatorRecordApplicability(
      data.elementId,
      data.reportedYear,
      data.reportedQuarter,
      indicatorId,
      shortName,
      records
    );

  useEffect(() => {
    setSelectedRecord(indicatorRecordId(data), shortName);
  }, [data, shortName]);

  const { totalState, handleApply, handleSave, handleCancel } = useDetailModal({
    onApplicable,
    onDismiss,
    setOpen,
    noPromptApply,
    data,
    indicatorRecordId,
    setSelectedRecordInapplicable,
    setSelectedRecordApplicable,
  });

  return (
    <Container>
      <Grid
        container
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid
          item
          xs
        >
          {selectedRecord && (
            <PopUpRecordDetailsPanel
              record={selectedRecord}
              onApply={handleApply}
              onDismiss={(setOpenValue = false) => {
                onDismiss(indicatorRecordId(data));
                setOpen(setOpenValue);
              }}
              setOpen={setOpen}
              shortName={(totalState.applyDialogOpen && totalState.auxRecordType) || shortName}
            />
          )}
        </Grid>
        {totalState.applyDialogOpen && (
          <ApplyDialog
            indicatorShortName={totalState.auxRecordType || shortName}
            onlyComment={totalState.hasComment}
            record={selectedRecord}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}
      </Grid>
    </Container>
  );
};

export default DetailModal;
