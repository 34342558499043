import { getStore } from '../state';
import { IndexedDbHandlerReturn, StoreListenerParams, type ApiRequestRow, type HttpEventDetail } from './types';
import clientDb from './instance';
import useIndexedDbCache from './cache-hook';

export type IndexedDbHandlerParams = {
  getCache: ReturnType<typeof useIndexedDbCache>['getCache'];
  updateCache: ReturnType<typeof useIndexedDbCache>['updateCache'];
  uriPath: string;
  storeListener: (payload: StoreListenerParams) => IndexedDbHandlerReturn | undefined;
};

const indexedDbHandler = ({ getCache, updateCache, uriPath, storeListener }: IndexedDbHandlerParams) => {
  const hydrate = () =>
    getCache().then((dbRow: ApiRequestRow) => {
      if (!dbRow) {
        return null;
      }

      const { response } = dbRow;
      const lastUpdated = dbRow.lastUpdated instanceof Date ? dbRow.lastUpdated : new Date(dbRow.lastUpdated);

      return { response, lastUpdated };
    });
  const store = getStore();
  const unlisten = store.subscribe(async () => {
    let cachedValue = null;
    try {
      cachedValue = await getCache();
    } catch (error) {
      console.error('Indexed-DB Handler Error:', error);
      return;
    }
    const cachePayload = storeListener({ storeState: store.getState(), cachedValue });
    if (!cachePayload?.update) {
      return;
    }
    const { update: response } = cachePayload;

    try {
      await updateCache({ response });
    } catch (error) {
      console.error('Indexed-DB Handler Error:', error);
    }
  });
  const httpRegister = (detail: HttpEventDetail) => uriPath === detail.relativeUrl;

  clientDb.registerHttp(httpRegister);

  return {
    hydrate,
    unlisten: () => {
      clientDb.unregisterHttp(httpRegister);
      unlisten();
    },
  };
};
export default indexedDbHandler;
