import UnstyledPaper from '@material-ui/core/Paper';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Page from '../../../components/Page';
import Tab from '../../../components/Tabs/Tab';
import Tabs from '../../../components/Tabs/Tabs';
import useMyAssignments from '../../../hooks/my-assignments';
import useSelectedItem from '../../../hooks/selected-item';
import ElementFilter from '../components/ElementFilter';
import LiveReportsTab from '../components/LiveReportsTab';
import ExecutiveSummaryTab from '../tabs/executive-summary/ExecutiveSummaryTab';
import ReviewRecordsTab from '../tabs/review-records/ReviewRecordsTab';
import ScorecardTab from '../tabs/scorecard/ScorecardTab';
import ShipChipTab from '../tabs/ship-chip/ShipChipTab';
import UploadAttachmentsTab from '../tabs/upload-attachments/UploadAttachmentsTab';
import { TabState } from '../types';

const Paper = styled(UnstyledPaper)`
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;

const TabPanel = styled(LiveReportsTab)``;
const TabContainer = styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${TabPanel}:not([hidden]) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const TABS: Array<{ value: TabState; label: string }> = [
  {
    value: 'review-records',
    label: 'Review Records',
  },
  {
    value: 'scorecard',
    label: 'Scorecard',
  },
  {
    value: 'ship-chip',
    label: 'Review SHIP/CHIP/PHIP',
  },
  {
    value: 'executive-summary',
    label: 'Executive Summary',
  },
  {
    value: 'attachments',
    label: 'Upload Attachments',
  },
];

const LandingPage = () => {
  const { state } = useLocation<{ initialTab?: TabState }>();

  const [currentTab, setCurrentTab] = useState<TabState>(state?.initialTab || TABS[0].value);
  const { myAssignments } = useMyAssignments();
  const { selectedItem: selectedAssignment, selectItem: selectAssignment } = useSelectedItem(
    myAssignments,
    'elementId'
  );

  return (
    <Page
      title="Live Reports"
      bodyComponent={
        <Paper
          square
          elevation={0}
        />
      }
    >
      <ElementFilter
        elements={myAssignments}
        selectedElement={selectedAssignment}
        onChangeElement={selectAssignment}
        showDisclaimer={!!selectedAssignment}
      />
      <TabContainer>
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
        >
          {TABS.map(({ value, label }) => (
            <Tab
              key={value}
              value={value}
              label={label}
            />
          ))}
        </Tabs>
        <TabPanel
          value="review-records"
          currentTab={currentTab}
          element={selectedAssignment}
          component={ReviewRecordsTab}
        />
        <TabPanel
          value="scorecard"
          currentTab={currentTab}
          element={selectedAssignment}
          component={ScorecardTab}
        />
        <TabPanel
          value="ship-chip"
          currentTab={currentTab}
          element={selectedAssignment}
          component={ShipChipTab}
        />
        <TabPanel
          value="executive-summary"
          currentTab={currentTab}
          element={selectedAssignment}
          component={ExecutiveSummaryTab}
          lazyLoad
        />
        <TabPanel
          value="attachments"
          currentTab={currentTab}
          element={selectedAssignment}
          component={UploadAttachmentsTab}
        />
      </TabContainer>
    </Page>
  );
};

export default LandingPage;
