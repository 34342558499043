import React, { useState } from 'react';
import styled from 'styled-components';
import FileUploadForm from './FileUploadForm';
import StartIconButton from '../../../../components/StartIconButton';

const Container = styled.div``;

interface Props {
  walkdownId: any;
  onAddAttachment(walkdownId: any, file: any): void;
}

const AddAttachment = ({ walkdownId, onAddAttachment }: Props) => {
  const [showFileUploadForm, setShowFileUploadForm] = useState(false);

  const handleUpload = (file: string) => {
    setShowFileUploadForm(false);
    onAddAttachment(walkdownId, file);
  };

  return (
    <Container>
      {showFileUploadForm ? (
        <FileUploadForm onUpload={handleUpload} />
      ) : (
        <StartIconButton onClick={() => setShowFileUploadForm(true)}>Add Attachment</StartIconButton>
      )}
    </Container>
  );
};

export default AddAttachment;
