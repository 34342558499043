import React from 'react';
import * as R from 'ramda';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import useConfirm from '../../../../hooks/confirm';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import UserLink from './UserLink';

interface Props {
  className?: string;
  users?: any[];
  onDelete: any;
}

const UserLinkList = ({ className, users, onDelete }: Props) => {
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onDelete);

  const items = users?.map(assignment => (
    <ListItem key={assignment.userId}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <UserLink
        userId={assignment.userId}
        name={assignment.userName}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={R.thunkify(confirm)(assignment.userId)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));

  return (
    <>
      {items && <List className={className}>{items}</List>}
      {confirming && (
        <OkayCancelDialog
          onOkay={onConfirm}
          onCancel={onCancel}
        >
          Are you sure you want to remove this user?
        </OkayCancelDialog>
      )}
    </>
  );
};

export default UserLinkList;
