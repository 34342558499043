import * as R from 'ramda';
import { getActiveAccount } from './utils';

const getCurrentRoles = () => R.pathOr<string[]>([], ['idTokenClaims', 'roles'], getActiveAccount());

const isAllowed = R.curry((roles, role) => R.includes(role, roles));

const forRole = <T>(allowedRole: string, item: T) => (isAllowed(getCurrentRoles(), allowedRole) ? [item] : []);

const forRoles = <T>(allowedRoles: string[], item: T) =>
  R.any(isAllowed(allowedRoles), getCurrentRoles()) ? [item] : [];

const isAllowedForRoles = (allowedRoles: string[]) => R.any(isAllowed(allowedRoles), getCurrentRoles());

export { forRole, forRoles, isAllowedForRoles };
