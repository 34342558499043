import { v4 as uuidv4 } from 'uuid';
import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadRequest = elementId => ({
  type: actionTypes.REPORT_ATTACHMENTS_LOAD_REQUEST,
  payload: {
    elementId,
  },
});

export const loadSuccess = (elementId, attachments) => ({
  type: actionTypes.REPORT_ATTACHMENTS_LOAD_SUCCESS,
  payload: {
    elementId,
    attachments,
  },
});

export const loadFailure = errorAction(actionTypes.REPORT_ATTACHMENTS_LOAD_FAILURE);

export const loadByQuarterRequest = (elementId, year, quarter) => ({
  type: actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
  },
});

export const loadByQuarterSuccess = (elementId, year, quarter, attachments) => ({
  type: actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    attachments,
  },
});

export const loadByQuarterFailure = errorAction(actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_FAILURE);

export const addRequest = (elementId, year, quarter, fileType, file) => ({
  type: actionTypes.REPORT_ATTACHMENT_ADD_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    temporaryId: uuidv4(),
    fileType,
    file,
  },
});

export const addSuccess = (attachment, temporaryId) => ({
  type: actionTypes.REPORT_ATTACHMENT_ADD_SUCCESS,
  payload: {
    attachment,
    temporaryId,
  },
});

export const addFailure = errorAction(actionTypes.REPORT_ATTACHMENT_ADD_FAILURE);

export const deleteRequest = (elementId, year, quarter, attachmentId) => ({
  type: actionTypes.REPORT_ATTACHMENT_DELETE_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    attachmentId,
  },
});

export const deleteSuccess = (elementId, year, quarter, attachmentId) => ({
  type: actionTypes.REPORT_ATTACHMENT_DELETE_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    attachmentId,
  },
});

export const deleteFailure = errorAction(actionTypes.REPORT_ATTACHMENT_DELETE_FAILURE);
