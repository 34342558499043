import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const DialogButton = styled(Button)`
  font: ${({ theme }) => theme.fonts.bold14};

  &.MuiButton-text {
    color: ${({ theme }) => theme.colours.greys.darkGrey5};
  }

  &.MuiButton-textPrimary {
    color: ${({ theme }) => theme.colours.blues.blue3};
  }

  &.MuiButton-textPrimary.Mui-disabled {
    color: ${({ theme }) => theme.colours.blues.blue4};
  }
`;

export default DialogButton;
