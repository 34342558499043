import * as actionTypes from '../action-types';
import groupKey from './group-key';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCORECARD_COLOURS_LOAD_REQUEST: {
      const { type, category } = action.payload;
      const key = groupKey(type, category);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
        },
      };
    }
    case actionTypes.SCORECARD_COLOURS_LOAD_SUCCESS: {
      const { type, category, scorecardColours } = action.payload;
      const key = groupKey(type, category);
      return {
        ...state,
        [key]: {
          records: scorecardColours,
          isLoading: false,
        },
      };
    }
    case actionTypes.SCORECARD_COLOURS_LOAD_FAILURE: {
      const { error, type, category } = action.payload;
      const key = groupKey(type, category);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: false,
          error,
        },
      };
    }
    default:
      return state;
  }
};
