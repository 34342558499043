import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MUITypography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import useIndicatorRecordsByQuarter from '../../hooks/indicator-records-by-quarter';
import IndicatorRecordTable from '../IndicatorRecordTable';
import useFilteredList from '../../hooks/filtered-list';

const Typography = styled(MUITypography)`
  font: ${({ theme }) => theme.fonts.bold14};
`;

const TableContainer = styled.div`
  padding-bottom: 20px;
  ${Typography} {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 24px;
  }
`;

const ListItem = styled.li`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey1};
  }
  padding: 16px;
`;

const IndicatorName = styled(MUITypography).attrs({
  variant: 'h4',
})`
  font: ${({ theme }) => theme.fonts.medium16};
`;

const AnalysisDataTable = ({ className, report, elementIndicator }) => {
  const { records: allRecords, isLoading } = useIndicatorRecordsByQuarter(
    report.elementId,
    report.year,
    report.quarter,
    elementIndicator.indicatorId
  );
  const records = useFilteredList(allRecords, R.propEq('applicable', true));
  return (
    <ListItem className={className}>
      <IndicatorName>{elementIndicator.indicatorName}</IndicatorName>
      <TableContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography>{`Applied Records (${records.length})`}</Typography>
            {records.length > 0 && (
              <IndicatorRecordTable
                indicatorId={elementIndicator.indicatorId}
                indicatorShortName={elementIndicator.indicatorShortName}
                items={records}
                evaluated={true}
                readonly
              />
            )}
          </>
        )}
      </TableContainer>
    </ListItem>
  );
};

export default AnalysisDataTable;
