import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadElementsRequest = () => ({
  type: actionTypes.MY_LOAD_ELEMENTS_REQUEST,
});

export const loadElementsSuccess = elements => ({
  type: actionTypes.MY_LOAD_ELEMENTS_SUCCESS,
  payload: {
    elements,
  },
});

export const loadElementsFailure = errorAction(actionTypes.MY_LOAD_ELEMENTS_FAILURE);

export const loadAssignmentsRequest = () => ({
  type: actionTypes.MY_LOAD_ASSIGNMENTS_REQUEST,
});

export const loadAssignmentsSuccess = assignments => ({
  type: actionTypes.MY_LOAD_ASSIGNMENTS_SUCCESS,
  payload: {
    assignments,
  },
});

export const loadAssignmentsFailure = errorAction(actionTypes.MY_LOAD_ASSIGNMENTS_FAILURE);

export const loadUnitsRequest = () => ({
  type: actionTypes.MY_LOAD_UNITS_REQUEST,
});

export const loadUnitsSuccess = units => ({
  type: actionTypes.MY_LOAD_UNITS_SUCCESS,
  payload: {
    units,
  },
});

export const loadUnitsFailure = errorAction(actionTypes.MY_LOAD_UNITS_FAILURE);
