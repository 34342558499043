import styled from 'styled-components';
import Tab from '../../../../../components/Tabs/Tab';

export const OutagePlansTab = styled(Tab).attrs({
  label: '10 Years Outage Plans',
})<{ currentTab: number }>`
  min-width: 109px;
`;

export const BusinessObjectivesTab = styled(Tab).attrs({
  label: 'Business Objectives',
})<{ currentTab: number }>`
  min-width: 128px;
`;

export const MSMProgramTab = styled(Tab).attrs({
  label: 'MSM Program',
})<{ currentTab: number }>`
  min-width: 175px;
`;
