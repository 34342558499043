import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MuiTableCell from '@material-ui/core/TableCell';

const GreyCell = styled(MuiTableCell)`
  background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
`;

export const ColourCategoryCell = styled(props => <MuiTableCell {...R.omit(['colour'], props)} />)`
  border: ${({ theme, overridden }) => (overridden ? `6px solid ${theme.colours.blues.blue3}` : 'none')};
  background-color: ${({ theme, colour }) => theme.colours.scorecard[colour].background};
`;

export const CategoryCell = styled(GreyCell)`
  width: 100%;
  padding-left: 60px;
`;
