import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as elementActions from '../state/elements/actions';

const usePrimaryRse = (elementId, allUsers) => {
  const dispatch = useDispatch();

  const setPrimaryRse = useCallback(
    newUserId => {
      const user = R.find(R.propEq('id', newUserId), allUsers);
      dispatch(elementActions.setPrimaryRseRequest(elementId, newUserId, user.name));
    },
    [dispatch, elementId, allUsers]
  );

  const clearPrimaryRse = useCallback(
    oldUserId => {
      dispatch(elementActions.clearPrimaryRseRequest(elementId, oldUserId));
    },
    [dispatch, elementId]
  );

  return {
    setPrimaryRse,
    clearPrimaryRse,
  };
};

export default usePrimaryRse;
