import { Switch, Route, Redirect } from 'react-router-dom';

import DeferralsHistoryPage from './pages/history-page/DeferralsHistoryPage';
import DeferralsRequestPage from './pages/request-page/DeferralsRequestPage';

const DeferralsPage = ({ match }: any) => (
  <Switch>
    <Route
      path={`${match.path}/request`}
      component={DeferralsRequestPage}
    />
    <Route
      path={`${match.path}/history`}
      component={DeferralsHistoryPage}
    />
    <Route
      exact
      path={match.path}
    >
      <Redirect to={`${match.path}/request`} />
    </Route>
  </Switch>
);

export default DeferralsPage;
