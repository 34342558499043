import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Control, FieldErrors } from 'react-hook-form';
import { ControlSelect } from '../../../../form/ControlSelect';
import { ControlTextField } from '../../../../form/ControlTextField';
import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlCheckboxes } from '../../../../form/ControlCheckboxes';
import { UppercaseTitle } from './UppercaseTitle';
import { WrappedInput } from './WrappedInput';
import { InterestedParties, RiskCategories, RiskOwnerFormInputs } from '../../../../types/risk-management';
import { OutagePlansContext } from '../../../../context/outage-plans';
import { OutagePlan } from '../../../../types/outage-plans';
import { BusinessObjectivesContext } from '../../../../context/business-objective';
import { BusinessObjective } from '../../../../types/business-objectives';
import { getBusinessObjectivesOptions, getMSMPrograms, getOutagePlansOptions } from '../hooks/useRiskOwnerForm';
import { MSMProgramsContext } from '../../../../context/msm-programs';
import { MSMProgram } from '../../../../types/msm-programs';
import { FACILITIES } from '../../../../utils/units';

export interface CurrentStatusInfoProps {
  control: Control<RiskOwnerFormInputs>;
  errors: FieldErrors<RiskOwnerFormInputs>;
  watchContingencyPlanningStatus: RiskOwnerFormInputs['contingencyPlanningStatus'];
  watchClimateChange: RiskOwnerFormInputs['climateChange'];
  unitsOptions: { label: string; value: string }[];
  watchRiskCategory: RiskCategories;
  riskCategories: Record<keyof RiskCategories, string>;
  interestedParties: Record<keyof InterestedParties, string>;
  isReadOnly: boolean;
}

const CurrentStatusInfo: React.FC<CurrentStatusInfoProps> = ({
  control,
  errors,
  watchContingencyPlanningStatus,
  watchClimateChange,
  unitsOptions,
  riskCategories,
  interestedParties,
  watchRiskCategory,
  isReadOnly,
}) => {
  const outagePlans: OutagePlan[] = useContext(OutagePlansContext);
  const businessObjectives: BusinessObjective[] = useContext(BusinessObjectivesContext);
  const msmPrograms: MSMProgram[] = useContext(MSMProgramsContext);
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <UppercaseTitle>Current Status Information</UppercaseTitle>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            label="Changes Since Last Quarter"
            options={[
              { label: 'Improved', value: 'Improved' },
              { label: 'Worsened', value: 'Worsened' },
              { label: 'No Significant Change', value: 'No Significant Change' },
              { label: 'New Risk', value: 'New Risk' },
            ]}
            name="changesSinceLastQ"
            required={true}
            error={!!errors.changesSinceLastQ}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="currentStatusRating"
            required={true}
            label="Current Risk Status Rating"
            options={[
              { label: 'Red', value: 'Red' },
              { label: 'Yellow', value: 'Yellow' },
              { label: 'White', value: 'White' },
              { label: 'Green', value: 'Green' },
            ]}
            error={!!errors.currentStatusRating}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlTextField
            control={control}
            name="currentStatusComment"
            label="Current Status Overall Comment"
            minLength={1}
            maxLength={1000}
            placeholder="Required (Character Limitation: 1000 characters)"
            multiline={true}
            minRows={6}
            required={true}
            error={!!errors.currentStatusComment}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="contingencyPlanningStatus"
            label="Contingency Planning Started"
            options={[
              { label: 'Not Started', value: 'Not Started' },
              { label: 'In Development', value: 'In Development' },
              { label: 'Ready', value: 'Ready' },
              { label: 'Not Applicable - do NOT intend to have Contingency Plan', value: 'Not Applicable' },
            ]}
            required={true}
            error={!!errors.contingencyPlanningStatus}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      {watchContingencyPlanningStatus && watchContingencyPlanningStatus !== 'Not Applicable' && (
        <>
          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlTextField
                control={control}
                name="contingencyPlanningDate"
                required={false}
                variant="filled"
                size="small"
                type="date"
                helperText="Due Date for Contingency Plan Readiness"
                error={!!errors.contingencyPlanningDate}
                readOnly={isReadOnly}
              />
            </WrappedInput>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlTextField
                control={control}
                name="contingencyPlanDescription"
                label="Contingency Plan Description"
                minLength={1}
                maxLength={1000}
                placeholder="Required (Character Limitation: 1000 characters)"
                multiline={true}
                minRows={6}
                required={true}
                error={!!errors.contingencyPlanDescription}
                readOnly={isReadOnly}
              />
            </WrappedInput>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="businessObjectives"
            label="Business Objectives"
            options={getBusinessObjectivesOptions(businessObjectives)}
            required={false}
            error={!!errors.businessObjectives}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="msmProgram"
            label="MSM Program"
            options={getMSMPrograms(msmPrograms)} // TODO: Populate with real values
            required={true}
            error={!!errors.msmProgram}
            readOnly={isReadOnly}
            multiple={true}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="plannedOutageReference"
            required={true}
            label="Planned Outage Reference"
            options={getOutagePlansOptions(outagePlans)}
            error={!!errors.plannedOutageReference}
            readOnly={isReadOnly}
            multiple={true}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          control={control}
          name="climateChange"
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ]}
          row={true}
          error={!!errors.climateChange}
          required={true}
          label="Is Climate Change/Environment the cause or driver of this risk?"
          readOnly={isReadOnly}
        />
      </Grid>
      {watchClimateChange === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              control={control}
              name="climateDescription"
              label="Describe how Climate or Environment causes this risk:"
              minLength={1}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000)"
              multiline={true}
              fullWidth={true}
              minRows={6}
              required={true}
              error={!!errors.climateDescription}
              readOnly={isReadOnly}
            />
          </WrappedInput>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            name="facility"
            required={true}
            control={control}
            fullWidth={true}
            label="Facility"
            options={FACILITIES.map(facility => ({ value: facility, label: facility }))}
            error={!!errors.facility}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="units"
            required={true}
            fullWidth={true}
            label="Units"
            options={unitsOptions}
            error={!!errors.units}
            readOnly={isReadOnly}
            multiple={true}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography style={{ fontWeight: 500 }}>Risk Categories:</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlCheckboxes
          control={control}
          name="riskCategories"
          checkboxOptions={riskCategories}
          disabled={isReadOnly}
        />
      </Grid>
      {Boolean(watchRiskCategory.mcrRelatedRisk) && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="riskImpactMcr"
              control={control}
              label="Describe how the risk impacts MCR?"
              minLength={1}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000 characters)"
              multiline={true}
              fullWidth={true}
              minRows={6}
              required={true}
              error={!!errors.riskImpactMcr}
              readOnly={isReadOnly}
            />
          </WrappedInput>
        </Grid>
      )}
      {Boolean(watchRiskCategory.reputationRisk) && (
        <>
          <Grid
            item
            xs={12}
          >
            <WrappedInput>
              <ControlTextField
                name="reputationDescription"
                control={control}
                label="Describe how the risk impacts reputation"
                minLength={1}
                maxLength={1000}
                placeholder="Required (Character Limitation: 1000 characters)"
                multiline={true}
                fullWidth={true}
                minRows={6}
                required={true}
                error={!!errors.reputationDescription}
                readOnly={isReadOnly}
              />
            </WrappedInput>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography style={{ fontWeight: 500 }}>Interested Parties:</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
          >
            <ControlCheckboxes
              control={control}
              name="interestedParties"
              checkboxOptions={interestedParties}
              disabled={isReadOnly}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            name="responseType"
            fullWidth={true}
            label="Response Type"
            options={[
              { label: 'Eliminate', value: 'Eliminate' },
              { label: 'Mitigate', value: 'Mitigate' },
              { label: 'Accept', value: 'Accept' },
              { label: 'Monitor', value: 'Monitor' },
              { label: 'Transfer', value: 'Transfer' },
            ]}
            required={true}
            error={!!errors.responseType}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlTextField
            name="businessPlanDescription"
            control={control}
            label="Impact to the business plan"
            minLength={1}
            maxLength={1000}
            placeholder="Required (Character Limitation: 1000 characters)"
            multiline={true}
            fullWidth={true}
            minRows={6}
            required={true}
            error={!!errors.businessPlanDescription}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlTextField
            name="estimationDescription"
            control={control}
            label="Estimation of impact and probability"
            minLength={1}
            maxLength={1000}
            placeholder="If a risk cannot be eliminated, provide a short explanation of why (Character Limitation: 1000)"
            multiline={true}
            fullWidth={true}
            minRows={6}
            required={true}
            error={!!errors.estimationDescription}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlTextField
            name="otherInfoDescription"
            control={control}
            label="Other relevant information"
            minLength={1}
            maxLength={3000}
            multiline={true}
            fullWidth={true}
            minRows={6}
            required={false}
            placeholder="(Character Limitation: 3000 characters)"
            error={!!errors.otherInfoDescription}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
    </Grid>
  );
};

export default CurrentStatusInfo;
