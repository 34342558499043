/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Named from './Named';

const Value = styled(Typography)`
  font: ${({ theme }) => theme.fonts.medium14};
`;

const NamedValue = ({ className, title, value }: { className?: string; title: string; value: any }) => (
  <Named
    className={className}
    title={title}
  >
    <Value>{value}</Value>
  </Named>
);

export default NamedValue;
