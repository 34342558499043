import { useContext, createContext, useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import moment from 'moment';
import Api from '../api';
import * as actions from '../state/elements/actions';
import getUniqueListBy from '../utils/unique-array';

const deferralLengthMap: any = {
  '1Q': 1,
  '2Q': 2,
  '3Q': 3,
  '4Q': 4,
};

export interface DeferralRequest {
  id: number;
  elementId: number;
  elementName: string;
  facilityName: string;
  requesterId: string;
  requesterFirstName: string;
  requesterLastName: string;
  requesterName: string;
  requesterEmail: string;
  approverId: string;
  approverFirstName: string;
  approverLastName: string;
  approverName: string;
  approverEmail: string;
  approvedState: string | null;
  dateStateChanged: Date | null;
  comment: string;
  quarter: number | null;
  year: number | null;
  targetDate: string;
  deferralLength: string;
  totalScore: number;
  totalColour: string;
  reportId: number;
  uploadId: string | null;
  originalNextReportDueDate?: Date;
  lastApprovedReportDate?: Date;
}

interface DeferralFilters {
  state: string;
  approver: string;
  element: number | 'none';
  facility: string;
  from: Date;
  to: Date;
}

const isPendingDeferral = R.propEq('approvedState', 'pending');

const DeferralsRequestsContext = createContext(null as any);

const deferralDate = (date: Date, deferralLength: string) => moment(date).add(deferralLengthMap[deferralLength], 'Q');

const useDeferralsRequests = () => {
  const context = useContext(DeferralsRequestsContext);
  if (R.isNil(context)) {
    throw new Error('useDeferralsRequests must be used inside an DeferralsRequestsProvider');
  }
  return context;
};

export const DeferralsRequestsProvider = ({ ...props }: any) => {
  const dispatch = useDispatch();
  const [selectedElement, setSelectedElement] = useState<any>([]);
  const [deferrals, setDeferrals] = useState<DeferralRequest[] | []>([]);
  const [allDeferrals, setAllDeferrals] = useState<DeferralRequest[] | []>([]);
  const [requestedDeferrals, setRequestedDeferrals] = useState<DeferralRequest[] | []>([]);

  useEffect(() => {
    Api.getMyAssignedDeferrals().then(R.filter(isPendingDeferral)).then(setDeferrals);
    Api.getMyRequestedDeferrals().then(setRequestedDeferrals);
    Api.getAllDeferrals().then(setAllDeferrals);
  }, []);

  const getFilteredDeferrals = useCallback(
    (filters: DeferralFilters) =>
      allDeferrals
        .filter(rd => (filters.element !== 'none' ? rd.elementId === filters.element : true))
        .filter(rd => (filters.approver !== 'none' ? rd.approverId === filters.approver : true))
        .filter(rd => (filters.state !== 'none' ? rd.approvedState === filters.state : true))
        .filter(rd => (filters.facility !== 'none' ? rd.facilityName === filters.facility : true))
        // eslint-disable-next-line no-nested-ternary
        .filter(rd =>
          !R.isNil(filters.from)
            ? rd.deferralLength === 'custom' || rd.dateStateChanged === null
              ? moment(rd.dateStateChanged).isSameOrAfter(moment(filters.from))
              : deferralDate(rd.dateStateChanged, rd.deferralLength).isSameOrAfter(moment(filters.from))
            : true
        )
        // eslint-disable-next-line no-nested-ternary
        .filter((rd: any) =>
          !R.isNil(filters.to)
            ? rd.deferralLength === 'custom' || rd.dateStateChanged === null
              ? moment(rd.dateStateChanged).isSameOrBefore(moment(filters.to))
              : deferralDate(rd.dateStateChanged, rd.deferralLength).isSameOrBefore(moment(filters.to))
            : true
        ),
    [allDeferrals]
  );

  const requestedDeferralsElements = useMemo(
    () =>
      getUniqueListBy(
        allDeferrals.map(({ elementId, elementName, facilityName }: DeferralRequest) => ({
          label: `${elementName} (${facilityName})`,
          value: elementId,
        })),
        'value'
      ),
    [allDeferrals]
  );

  const unsortedRequestedDeferralsApprovers = useMemo(
    () =>
      getUniqueListBy(
        allDeferrals.map(({ approverId, approverName, approverFirstName, approverLastName }: DeferralRequest) => ({
          firstName: approverFirstName,
          lastName: approverLastName,
          label: approverName,
          value: approverId,
        })),
        'value'
      ),
    [allDeferrals]
  );

  const requestedDeferralsApprovers = unsortedRequestedDeferralsApprovers.sort((a, b) => {
    const result = a.lastName.localeCompare(b.lastName);

    return result !== 0 ? result : a.firstName.localeCompare(b.firstName);
  });

  const requestedDeferralsFacilities = useMemo(
    () =>
      getUniqueListBy(
        allDeferrals.map(({ facilityName }: DeferralRequest) => ({
          label: facilityName,
          value: facilityName,
        })),
        'value'
      ),
    [allDeferrals]
  );

  const getDeferrals = useCallback(async () => {
    const returnedDeferrals: DeferralRequest[] = await Api.getMyAssignedDeferrals();
    const pendingDeferrals: DeferralRequest[] = R.filter(isPendingDeferral)(returnedDeferrals);
    setDeferrals(pendingDeferrals);
  }, []);

  const getMyRequestedDeferrals = useCallback(async () => {
    const myRequestedDeferrals: DeferralRequest[] = await Api.getMyRequestedDeferrals();
    setRequestedDeferrals(myRequestedDeferrals);
  }, []);

  const submitDeferralRequest = useCallback(
    async (request: { approver: { value: any } }) => {
      const sendElement = (e: any) => {
        const myPromise = new Promise<void>((myResolve, myReject) => {
          Api.addDeferralsRequest(e, {
            ...request,
            approverId: request.approver.value,
          })
            .then(() => myResolve())
            .catch((error: any) => myReject(error));
        });
        return myPromise;
      };

      const promises = selectedElement.map((element: any) => sendElement(element));

      Promise.all(promises)
        .then(() => dispatch(actions.addDeferralRequestSuccess()))
        .catch(error => dispatch(actions.addDeferralRequestFailure(error)));
    },
    [selectedElement]
  );

  const approveDeferralRequest = useCallback(
    async (requestId: string, comment: string) => {
      try {
        await Api.approveDeferralsRequest(requestId, comment);
        await getDeferrals();
        dispatch(actions.approveDeferralRequestSuccess());
      } catch (e) {
        dispatch(actions.approveDeferralRequestFailure(e));
      }
    },
    [dispatch, deferrals]
  );

  const rejectDeferralRequest = useCallback(
    async (requestId: string, comment: string) => {
      try {
        await Api.rejectDeferralsRequest(requestId, comment);
        await getDeferrals();
        dispatch(actions.rejectDeferralRequestSuccess());
      } catch (e) {
        dispatch(actions.rejectDeferralRequestFailure(e));
      }
    },
    [dispatch, deferrals]
  );

  const resubmitDeferralRequest = useCallback(
    async (request: DeferralRequest, updateValues: any) => {
      try {
        await Api.resubmitDeferralRequest(request, updateValues);
        await getMyRequestedDeferrals();
        dispatch(actions.resubmitDeferralRequestSuccess());
      } catch (e) {
        dispatch(actions.resubmitDeferralRequestFailure(e));
      }
    },
    [dispatch, deferrals]
  );

  const deleteDeferralRequest = useCallback(
    async (requestId: string) => {
      try {
        await Api.deleteDeferralRequest(requestId);
        await getMyRequestedDeferrals();
        dispatch(actions.deleteDeferralRequestSuccess());
      } catch (e) {
        dispatch(actions.deleteDeferralRequestFailure(e));
      }
    },
    [dispatch, deferrals]
  );

  const value = {
    deferrals,
    requestedDeferrals,
    requestedDeferralsElements,
    requestedDeferralsApprovers,
    requestedDeferralsFacilities,
    getFilteredDeferrals,
    submitDeferralRequest,
    approveDeferralRequest,
    rejectDeferralRequest,
    resubmitDeferralRequest,
    deleteDeferralRequest,
    setSelectedElement,
    selectedElement,
  };

  return (
    <DeferralsRequestsContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useDeferralsRequests;
