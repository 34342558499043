import React, { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { AddCircleOutline, Description } from '@material-ui/icons';
import { WalkdownTask } from '../../../types/walkdown-types';
import Conditional from '../../../components/Conditional';
import TaskCommentDialog from './TaskCommentDialog';
import useReadOnly from '../../../hooks/read-only';

interface Props {
  task: WalkdownTask;
  hasComment?: boolean;
  setTaskComment(categoryId: number, taskId: number, text: string): any;
}

const TaskCommentcell = ({ task, hasComment, setTaskComment }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const saveHandler = useCallback(
    async ({ comment }: any) => {
      await setTaskComment(task.categoryId, task.id, comment);
    },
    [task.id, task.categoryId]
  );

  const readOnly = useReadOnly();

  return (
    <>
      <TaskCommentDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSave={saveHandler}
        body={task.body}
      />

      <Conditional
        condition={task.body || hasComment}
        contents={
          <IconButton
            color="primary"
            onClick={() => setIsOpen(true)}
          >
            <Description />
          </IconButton>
        }
        fallback={
          readOnly ? (
            <></>
          ) : (
            <IconButton
              color="primary"
              onClick={() => setIsOpen(true)}
            >
              <AddCircleOutline />
            </IconButton>
          )
        }
      />
    </>
  );
};

export default TaskCommentcell;
