import * as R from 'ramda';
import { recordKey, quarterKey } from './keys';

export const getRecords = (state, elementId, indicatorId) =>
  R.pathOr([], [recordKey(elementId, indicatorId), 'records'], state.indicatorRecords);

export const isLoading = (state, elementId, indicatorId) =>
  R.pathOr(false, [recordKey(elementId, indicatorId), 'isLoading'], state.indicatorRecords);

export const getError = (state, elementId, indicatorId) =>
  R.path([recordKey(elementId, indicatorId), 'error'], state.indicatorRecords);

export const getRecordsByQuarter = (state, elementId, year, quarter, indicatorId) =>
  R.pathOr([], [quarterKey(elementId, year, quarter, indicatorId), 'records'], state.indicatorRecords);

export const isLoadingByQuarter = (state, elementId, year, quarter, indicatorId) =>
  R.pathOr(false, [quarterKey(elementId, year, quarter, indicatorId), 'isLoading'], state.indicatorRecords);

export const getErrorByQuarter = (state, elementId, year, quarter, indicatorId) =>
  R.path([quarterKey(elementId, year, quarter, indicatorId), 'error'], state.indicatorRecords);
