import { useCallback } from 'react';
import * as R from 'ramda';
import Api from '../api';
import useDownloader from './downloader';

const useDownloadReportAttachment = attachments => {
  const { onDownload } = useDownloader();

  const downloadAttachment = useCallback(
    attachmentId => {
      const attachment = R.find(R.propEq('id', attachmentId), attachments);
      Api.downloadReportAttachment(attachment.elementId, attachment.year, attachment.quarter, attachmentId).then(
        blob => {
          onDownload(blob, attachment.filename);
        }
      );
    },
    [attachments, onDownload]
  );

  return {
    downloadAttachment,
  };
};

export default useDownloadReportAttachment;
