import React, { useCallback, useContext, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button, Typography } from '@material-ui/core';
import { BusinessObjective } from '../../../../../../types/business-objectives';
import { BusinessObjectivesContext } from '../../../../../../context/business-objective';
import NewBusinessObjectiveDashboard from './NewBusinessObjectiveDashboard';
import EditBusinessObjectiveDashboard from './EditBusinessObjectiveDashboard';
import ActionButton from '../../../../../../components/ActionButton';
import api from '../../../../../../api';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';

interface RowProps {
  item: BusinessObjective;
  onEdit: (businessObjective: BusinessObjective) => void;
  onDelete: (businessObjective: BusinessObjective) => void;
}

const Row = ({ item, onEdit, onDelete }: RowProps) => (
  <TableRow>
    <TableCell>{item.title}</TableCell>
    <TableCell>{item.status}</TableCell>
    <TableCell>
      <Button onClick={() => onEdit(item)}>EDIT</Button>
    </TableCell>
    <TableCell>
      <Button onClick={() => onDelete(item)}>DELETE</Button>
    </TableCell>
  </TableRow>
);

const BusinessObjectivesTable = () => {
  const businessObjectives = useContext(BusinessObjectivesContext);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [originalTitle, setOriginalTitle] = useState('');
  const dispatch = useDispatch();

  const handleDelete = useCallback(async (item: BusinessObjective) => {
    try {
      await api.deleteBusinessObjective(item.title);
      dispatch(uiActions.genericMessage('deleted succesfully'));
    } catch (error) {
      dispatch(uiActions.error('', 'delete unsuccesful'));
    }
  }, []);

  return (
    <>
      <NewBusinessObjectiveDashboard
        opened={openAddDialog}
        setOpened={setOpenAddDialog}
      />
      <EditBusinessObjectiveDashboard
        opened={openEditDialog}
        setOpened={setOpenEditDialog}
        originalTitle={originalTitle}
      />
      <TableContainer>
        {businessObjectives.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
                <TableCell>
                  <ActionButton
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                  >
                    Add New Business Objective
                  </ActionButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessObjectives
                .filter(item => !item.is_deleted)
                .map((item: BusinessObjective) => (
                  <Row
                    item={item}
                    onEdit={() => {
                      setOpenEditDialog(true);
                      setOriginalTitle(item.title);
                    }}
                    onDelete={async item => await handleDelete(item)}
                  />
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Business Objectives Exist Yet</Typography>
        )}
      </TableContainer>
    </>
  );
};

export default BusinessObjectivesTable;
