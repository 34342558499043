import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as actions from './actions';
import * as uiActions from '../ui/actions';

function* loadAttachments(action) {
  const { elementId } = action.payload;
  try {
    const attachments = yield call(Api.getReportAttachments, elementId);
    yield put(actions.loadSuccess(elementId, attachments));
  } catch (e) {
    yield put(actions.loadFailure(e, { elementId }));
  }
}

function* loadAttachmentsByQuarter(action) {
  const { elementId, year, quarter } = action.payload;
  try {
    const attachments = yield call(Api.getReportAttachmentsByQuarter, elementId, year, quarter);
    yield put(actions.loadByQuarterSuccess(elementId, year, quarter, attachments));
  } catch (e) {
    yield put(actions.loadByQuarterFailure(e, { elementId, year, quarter }));
  }
}

function* uploadAttachment(action) {
  const { elementId, year, quarter, fileType, file, temporaryId } = action.payload;
  try {
    yield put(uiActions.setLoadingSpinnerTrue());
    yield put(uiActions.genericMessage('Uploading Attachment...'));
    const { attachment } = yield call(Api.uploadReportAttachment, elementId, year, quarter, fileType, file);
    yield put(actions.addSuccess(attachment, temporaryId));
    yield put(uiActions.genericMessage('Attachment Uploaded'));
    yield put(uiActions.setLoadingSpinnerFalse());
  } catch (e) {
    yield put(actions.addFailure(e, { elementId, year, quarter, temporaryId }));
    yield put(uiActions.error('', 'Attachment Failed'));
    yield put(uiActions.setLoadingSpinnerFalse());
  }
}

function* deleteAttachment(action) {
  const { elementId, year, quarter, attachmentId } = action.payload;
  try {
    yield call(Api.deleteReportAttachment, elementId, year, quarter, attachmentId);
    yield put(actions.deleteSuccess(elementId, year, quarter, attachmentId));
  } catch (e) {
    yield put(actions.deleteFailure(e, { elementId, year, quarter, attachmentId }));
  }
}

function* root() {
  yield takeEvery(actionTypes.REPORT_ATTACHMENTS_LOAD_REQUEST, loadAttachments);
  yield takeEvery(actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_REQUEST, loadAttachmentsByQuarter);
  yield takeEvery(actionTypes.REPORT_ATTACHMENT_ADD_REQUEST, uploadAttachment);
  yield takeEvery(actionTypes.REPORT_ATTACHMENT_DELETE_REQUEST, deleteAttachment);
}

export default root;
