import { Grid } from '@material-ui/core';
import React from 'react';
import ActionButton from '../../../../components/ActionButton';
import { useEditReferenceForm } from '../../hooks/useEditReferenceForm';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';
import ConfirmEditDialog from './components/ConfirmEditDialog';
import { Inputs } from './inputs';

interface ReferenceFormProps {
  handleClose: () => void;
}

const EditReferenceForm: React.FC<ReferenceFormProps> = ({ handleClose }) => {
  const {
    control,
    errors,
    handleSubmit,
    actionTypeOptions,
    outageCodeOptions,
    referenceTypeOptions,
    referenceNumberOptions,
    onChangeOutageCode,
    onChangeReferenceType,
    onChangeReferenceNumberOption,
    matchingReference,
    confirmingEdit,
    onConfirmEdit,
    onCancelEdit,
    canDelete,
    onClickDelete,
    confirmingDelete,
    onConfirmDelete,
    onCancelDelete,
  } = useEditReferenceForm(handleClose);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Inputs.OutageCode
            control={control}
            options={outageCodeOptions}
            error={!!errors.outageCode}
            onChangeCallback={onChangeOutageCode}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Inputs.ReferenceType
            control={control}
            options={referenceTypeOptions}
            error={!!errors.referenceType}
            onChangeCallback={onChangeReferenceType}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Inputs.ReferenceNumberOption
            control={control}
            options={referenceNumberOptions}
            error={!!errors.referenceNumberOption}
            onChangeCallback={onChangeReferenceNumberOption}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Inputs.ActionType
            control={control}
            options={actionTypeOptions}
            error={!!errors.actionType}
            placeholder="Please update"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={1}
        >
          <Grid item>
            <ActionButton
              color="primary"
              type="submit"
            >
              Save
            </ActionButton>
          </Grid>
          <Grid item>
            <ActionButton
              disabled={!canDelete}
              onClick={onClickDelete}
            >
              Delete
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
      {confirmingEdit && matchingReference && (
        <ConfirmEditDialog
          onOkay={onConfirmEdit}
          onCancel={onCancelEdit}
          existingReference={matchingReference}
        />
      )}
      {confirmingDelete && matchingReference && (
        <ConfirmDeleteDialog
          onOkay={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      )}
    </form>
  );
};

export default EditReferenceForm;
