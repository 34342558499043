import React from 'react';
import styled from 'styled-components';
import PersonIcon from '@material-ui/icons/Person';

const Container = styled.div`
  position: relative;
`;

const StyledPhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  opacity: 0;
  background-color: ${({ theme }) => theme.colours.white};
  z-index: 5;
  :hover {
    opacity: 0.4;
  }
`;

interface Props {
  className?: string;
  onClick?(): void;
}

const ProfileImage = ({ className, onClick }: Props) => (
  <Container className={className}>
    <PersonIcon />
    <StyledPhotoOverlay onClick={onClick} />
  </Container>
);

export default ProfileImage;
