import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import ShipChipTable from '../../../../../components/ShipChip/ShipChipTable';
import { useShipsChips } from '../../../../live-reports/tabs/ship-chip/hooks/useShipsChips';
import * as R from 'ramda';
import ActionButton from '../../../../../components/ActionButton';
import ActionFilterPanel from '../../../../../components/ShipChip/ActionFilterPanel';
import { useEffect, useState } from 'react';
import useRiskManagement from '../../../../../context/risk-management';
import CustomTooltip from '../../../../../components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { MyElement } from '../../../../../types/my';
import useAllElements from '../../../../../hooks/all-elements';
import { EchoElement } from '../../../../../hooks/elements';

interface Props {
  elementId: string | number;
  riskManagementId: number;
  isReadOnly: boolean;
}
const ShipChipSection = ({ elementId, riskManagementId, isReadOnly }: Props) => {
  const { myElements } = useRiskManagement();
  const { elements } = useAllElements();

  const [element, setElement] = useState<MyElement | null>(null);

  const {
    actions,
    clearDeleteAction,
    clearEditAction,
    clearNewAction,
    confirmActionStatusToggle,
    confirmDeleteActionToggle,
    deleteAction,
    deleteActionStatus,
    editAction,
    handleOnCancel,
    handleOnOkay,
    isLoading,
    newAction,
    reorderActions,
    saveNewAction,
    setComment,
    setState,
    source,
    setSource,
    showEditActionDialog,
    showNewActionDialog,
    state,
    statusAction,
    updateAction,
  } = useShipsChips(element);

  useEffect(() => {
    let element: MyElement | null = null;
    if (isReadOnly) {
      const echoElement = elements.find((e: EchoElement) => e.id === elementId);
      if (echoElement) {
        element = {
          elementId: echoElement.id,
          elementName: echoElement.name,
          elementType: echoElement.type,
          facilityId: echoElement.facilityId,
          facilityName: echoElement.facilityName,
        } as MyElement;
      }
    } else {
      element = myElements.find(element => element.elementId === elementId) || null;
    }
    setElement(element);
  }, [elementId]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Typography>Response/SHIP/CHIP/PHIP Actions</Typography>
      </Grid>
      <Grid
        container
        style={{
          marginTop: '20px',
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
        >
          <Grid
            item
            container
            xs={10}
          >
            <ActionFilterPanel
              state={state}
              source={source}
              onChangeStatus={setState}
              onChangeSource={setSource}
            />
          </Grid>
          <Grid
            item
            container
            xs={2}
          >
            <CustomTooltip title="This section allows you to add health improvement plan (SHIP/CHIP/PHIP) actions associated with this risk. It will take you to the Action Item Details page where the actions are added. Once you SAVE the Action Item Details, the newly added action will appear in the Response/SHIP/CHIP/PHIP section on this RISK OWNER page.">
              <IconButton
                size="small"
                onClick={() => null}
              >
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
            <ActionButton
              color="primary"
              onClick={showNewActionDialog}
              disabled={R.isEmpty(elementId) || isReadOnly}
            >
              Add New
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
      <Box style={{ marginTop: '20px' }}>
        <ShipChipTable
          actions={actions}
          clearDeleteAction={clearDeleteAction}
          clearEditAction={clearEditAction}
          clearNewAction={clearNewAction}
          confirmActionStatusToggle={confirmActionStatusToggle}
          confirmDeleteActionToggle={confirmDeleteActionToggle}
          deleteAction={deleteAction}
          deleteActionStatus={deleteActionStatus}
          editAction={editAction}
          element={element}
          handleOnCancel={handleOnCancel}
          handleOnOkay={handleOnOkay}
          isLoading={isLoading}
          newAction={newAction}
          relatedRisk={riskManagementId}
          reorderActions={reorderActions}
          saveNewAction={saveNewAction}
          setComment={setComment}
          showEditActionDialog={showEditActionDialog}
          state={state}
          statusAction={statusAction}
          updateAction={updateAction}
        />
      </Box>
    </>
  );
};

export default ShipChipSection;
