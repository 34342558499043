import React, { useMemo, useState } from 'react';
import ReportStateChangeCommentDialog from '../../../components/ReportStateChangeCommentDialog';
import useMyElements from '../../../hooks/my-elements';
import { WalkdownsProvider } from '../../../hooks/walkdowns';
import { Walkdown } from '../../../types/walkdown-types';
import elementTypePrefix from '../../../utils/element-type-prefix';
import useWalkdownReview from './hooks/useWalkdownReview';
import ReviewTable from './ReviewTable';
import DropdownAutocomplete from '../../../components/DropdownAutocomplete';
import { Grid } from '@material-ui/core';
import { MyElement } from '../../../types/my';

const WalkdownReviewPage = () => {
  const { walkdowns, approve, reject, refresh } = useWalkdownReview();
  const [elementId, setElementId] = useState<string | null>('');

  const { myElements } = useMyElements();

  const elementOptions = useMemo(
    () =>
      myElements
        .filter((element: MyElement) => element.elementType !== 'program')
        .map((element: MyElement) => ({
          label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
          value: element.elementId,
        })),
    [myElements]
  );

  const [approving, setApproving] = useState<Walkdown | null>(null);
  const [rejecting, setRejecting] = useState<Walkdown | null>(null);

  const filteredWalkdowns = useMemo(() => {
    if (elementId) {
      return walkdowns.filter(w => w.elementId.toString() === elementId.toString());
    }
    return walkdowns;
  }, [elementId, walkdowns]);

  return (
    <div>
      <div style={{ padding: '20px 0' }}>
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Element"
            value={elementId && `${elementId}`}
            onChange={el => setElementId(el)}
            options={elementOptions}
          />
        </Grid>
      </div>
      <WalkdownsProvider>
        <ReviewTable
          onAccept={setApproving}
          onReject={setRejecting}
          walkdowns={filteredWalkdowns}
        />
      </WalkdownsProvider>

      {approving && (
        <ReportStateChangeCommentDialog
          title={`Do you approve: ${approving?.elementName || ''} - ${approving?.title} for ${approving?.year || ''}-Q${
            approving?.quarter || ''
          }`}
          commentRequired={false}
          message={false}
          onSave={(comment: string) => {
            approve(approving?.id, comment);
            setApproving(null);
            refresh();
          }}
          onCancel={() => setApproving(null)}
        />
      )}

      {rejecting && (
        <ReportStateChangeCommentDialog
          title={`Do you Reject: ${rejecting?.elementName || ''} - ${rejecting?.title} for ${rejecting?.year || ''}-Q${
            rejecting?.quarter || ''
          }`}
          commentRequired={true}
          message={false}
          onSave={(comment: string) => {
            reject(rejecting?.id, comment);
            setRejecting(null);
            refresh();
          }}
          onCancel={() => setRejecting(null)}
        />
      )}
    </div>
  );
};

export default WalkdownReviewPage;
