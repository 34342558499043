import Tabs from '../../../components/Tabs/Tabs';
import { RiskOwnerForm } from './form/RiskOwnerForm';
import { RiskApproverForm } from './form/RiskApproverForm';
import useRiskManagement from '../../../context/risk-management';
import RiskEnvironmentGroupForm from './form/RiskEnvironmentGroupForm';
import UnstyledTabPanel from '../../../components/Tabs/TabPanel';
import styled from 'styled-components';
import { EnvironmentalGroupTab, RiskApproverTab, RiskOwnerTab } from './RiskFormTabs';

export type RiskFormTab = 'owner' | 'approver' | 'environmentalGroup';

const TabPanel = styled(UnstyledTabPanel)`
  padding: 0;
  border-radius: 2px;
`;

interface Props {
  value: RiskFormTab;
  onChange: (_: any, newValue: RiskFormTab) => void;
  tabs: RiskFormTab[];
}

const RiskFormTabGroup = ({ value, onChange, tabs }: Props) => {
  const { data } = useRiskManagement();

  return (
    <>
      <Tabs
        value={value}
        onChange={onChange}
      >
        {tabs.map(tab => {
          switch (tab) {
            case 'owner':
              return (
                <RiskOwnerTab
                  key="risk-owner-tab"
                  value={tab}
                />
              );
            case 'approver':
              return (
                <RiskApproverTab
                  key="risk-approver-tab"
                  value={tab}
                />
              );
            default:
              return (
                <EnvironmentalGroupTab
                  key="environmental-group-tab"
                  value={tab}
                />
              );
          }
        })}
      </Tabs>
      <TabPanel
        value="owner"
        currentTab={value}
      >
        <RiskOwnerForm />
      </TabPanel>
      {tabs.includes('approver') && (
        <TabPanel
          value="approver"
          currentTab={value}
        >
          <RiskApproverForm riskManagementId={data.riskId} />
        </TabPanel>
      )}
      {tabs.includes('environmentalGroup') && (
        <TabPanel
          value="environmentalGroup"
          currentTab={value}
        >
          <RiskEnvironmentGroupForm
            status={data.status}
            riskManagementId={data.riskId}
          />
        </TabPanel>
      )}
    </>
  );
};

export default RiskFormTabGroup;
