import { useMemo } from 'react';
import OkayCancelDialog from '../OkayCancelDialog';
import useConfirm from '../../hooks/confirm';
import ReorderableActionTable from '../ActionTable/ReorderableActionTable';
import useReferenceList from './hooks/reference-list';
import { MaximoReference } from '../../types/actions';

const ReferenceList = ({ references, onRemoveItem }: any) => {
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onRemoveItem);
  const { workOrderColumns, workReportColumns, otherReferencesColumn } = useReferenceList(confirm);

  const woActionTableActions = useMemo(
    () =>
      references &&
      references.filter(
        (actionItem: MaximoReference) =>
          actionItem &&
          (actionItem.typeName === 'WO' || actionItem.typeName === 'EC' || actionItem.typeName === 'Activity')
      ),
    [references]
  );

  const wrActionTableActions = useMemo(
    () =>
      references &&
      references.filter(
        (actionItem: MaximoReference) =>
          actionItem && (actionItem.typeName === 'WR' || actionItem.typeName === 'CR' || actionItem.typeName === 'AR')
      ),
    [references]
  );

  const otherActionTableActions = useMemo(
    () =>
      references &&
      references.filter(
        (actionItem: MaximoReference) =>
          actionItem &&
          actionItem.typeName !== 'WO' &&
          actionItem.typeName !== 'WR' &&
          actionItem.typeName !== 'CR' &&
          actionItem.typeName !== 'AR' &&
          actionItem.typeName !== 'EC' &&
          actionItem.typeName !== 'Activity'
      ),
    [references]
  );

  return (
    <>
      <ReorderableActionTable
        key={'WOActionTable'}
        className={'WOActionTable'}
        actions={woActionTableActions}
        columns={workOrderColumns}
        reorderRows={true}
        unstyled={true}
      />
      <ReorderableActionTable
        key={'WRActionTable'}
        className={'WRActionTable'}
        actions={wrActionTableActions}
        columns={workReportColumns}
        reorderRows={true}
        unstyled={true}
      />
      <ReorderableActionTable
        key={'OtherActionTable'}
        className={'OtherActionTable'}
        actions={otherActionTableActions}
        columns={otherReferencesColumn}
        reorderRows={true}
        unstyled={true}
      />
      {confirming && (
        <OkayCancelDialog
          title="Delete?"
          okayLabel="Delete"
          onOkay={onConfirm}
          onCancel={onCancel}
        >
          Are you sure you want to delete this reference?
        </OkayCancelDialog>
      )}
    </>
  );
};

export default ReferenceList;
