import React from 'react';
import { Grid } from '@material-ui/core';
import AttachmentList from './AttachmentList';
import AddAttachment from './AddAttachment';
import useAddAttachment from '../hooks/useAddAttachment';
import useAttachmentDownload from '../hooks/useAttachmentDownloader';
import useReadOnly from '../../../../hooks/read-only';

const AttachmentsBox = ({ walkdownId }: any) => {
  const { addAttachment, attachments, deleteAttachment } = useAddAttachment(walkdownId);

  const { downloadAttachment } = useAttachmentDownload(walkdownId, attachments);

  const onDelete = (attachmentId: any) => {
    deleteAttachment(walkdownId, attachmentId);
  };

  const onDownload = (attachmentId: any) => {
    downloadAttachment(attachmentId);
  };

  const readOnly = useReadOnly();

  return (
    <Grid container>
      <Grid item>
        <AttachmentList
          attachments={attachments}
          onDownload={onDownload}
          onDelete={onDelete}
        />
      </Grid>
      <Grid container>
        {!readOnly && (
          <AddAttachment
            onAddAttachment={addAttachment}
            walkdownId={walkdownId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AttachmentsBox;
