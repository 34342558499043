import styled from 'styled-components';

const TabPanelContainer = styled.div`
  border: 1px solid #dad8d8;
  padding: 10px;
`;

export interface TabPanelProps<T> extends React.PropsWithChildren {
  value: T;
  currentTab: T;
  className?: string;
}

const TabPanel = <T,>({ children, value, currentTab, className }: TabPanelProps<T>) => {
  return (
    <TabPanelContainer
      className={className}
      role="tabpanel"
      hidden={value !== currentTab}
    >
      {children}
    </TabPanelContainer>
  );
};

export default TabPanel;
