import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import DateInput from '../components/DateInput';

interface ControlDateProps<T extends FieldValues, P extends Path<T>>
  extends Pick<RegisterOptions<T, P>, 'deps' | 'required' | 'validate'> {
  control: Control<T>;
  label?: string;
  name: P;
  error: boolean;
  disabled?: boolean;
  disableTyping?: boolean;
  dateFormat?: string;
  clearable?: boolean;
}

export const ControlDate = <T extends FieldValues, P extends Path<T>>({
  control,
  required,
  label,
  name,
  error,
  disabled = false,
  disableTyping = false,
  dateFormat = 'yyyy-MM-dd',
  clearable = false,
  validate,
  deps,
}: ControlDateProps<T, P>) => (
  <Controller
    name={name}
    control={control}
    disabled={disabled}
    rules={{ required, validate, deps }}
    render={({ field }) => (
      <DateInput
        onChange={field.onChange}
        value={field.value}
        error={error}
        label={label}
        disableTyping={disableTyping}
        dateFormat={dateFormat}
        clearable={clearable}
      />
    )}
  />
);
