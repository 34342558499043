import React from 'react';
import styled from 'styled-components';
import UnstyledList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UnstyledNavItem from '../default/NavItem';
import UnstyledSubMenuItem from '../default/SubMenuItem';
import ActionItem from '../default/ActionItem';

const NavItem = styled(UnstyledNavItem)``;
const SubMenuItem = styled(UnstyledSubMenuItem)``;

const List = styled(UnstyledList)`
  color: ${({ theme }) => theme.colours.menu.text};
  padding-top: 0px;
`;

const entryToItem = (entry, i) => {
  switch (entry.type) {
    case 'subMenu': {
      return (
        <SubMenuItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
          entries={entry.children}
          isSelected={location.pathname.includes(entry.to)}
        />
      );
    }
    case 'nav': {
      return (
        <NavItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
          isSelected={location.pathname.includes(entry.to)}
        />
      );
    }
    case 'action': {
      return (
        <ActionItem
          key={`${i}:${entry.label}`}
          onClick={entry.action}
          iconComponent={entry.icon}
          label={entry.label}
        />
      );
    }
    default: {
      return (
        <ListItem key={`${i}:${entry.type}`}>
          <ListItemText>Type ({entry.type}) Unknown</ListItemText>
        </ListItem>
      );
    }
  }
};

const MenuList = ({ entries }) => {
  const items = entries.map(entryToItem);

  return <List component="nav">{items}</List>;
};

export default MenuList;
