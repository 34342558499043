import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import UnstyledIconButton from '@material-ui/core/IconButton';
import UnstyledCheckIcon from '@material-ui/icons/Check';
import UnstyledClearIcon from '@material-ui/icons/Clear';
import Table from '../../../../components/Table';

import { dateToString } from '../../../../utils/date-format';
import { ScoreColouredText } from '../../../../components/Scorecard/TotalScoreBox';
import DownloadButton from '../../../../components/DownloadButton';
import useDownloadReport from '../../../../hooks/download-report';
import { DeferralRequest } from '../../../../hooks/deferral-requests';

const IconButton = styled(UnstyledIconButton)``;

const CheckIcon = styled(UnstyledCheckIcon)`
  color: ${({ theme }) => theme.colours.greens.green2};
`;

const ClearIcon = styled(UnstyledClearIcon)`
  color: ${({ theme }) => theme.colours.reds.red3};
`;

const TableColourCell = styled<any>(TableCell)`
  background-color: ${({ theme, scoreColour }: any) =>
    R.pathOr(theme.colours.white, ['colours', 'scorecard', scoreColour, 'background'], theme)};
  border: ${({ theme, scoreColour = 'white', overridden }: any) => {
    if (overridden) {
      return `6px solid ${theme.colours.blues.blue3}`;
    }
    return scoreColour === 'white' ? `1px solid ${theme.colours.greys.lightGrey2}` : 'none';
  }};
`;

const Approval = ({ onCheck, onClear }: any) => (
  <Grid
    container
    justifyContent="center"
    spacing={1}
  >
    <Grid item>
      <IconButton
        size="small"
        onClick={onCheck}
      >
        <CheckIcon />
      </IconButton>
    </Grid>
    <Grid item>
      <IconButton
        size="small"
        onClick={onClear}
      >
        <ClearIcon />
      </IconButton>
    </Grid>
  </Grid>
);

const deferralLengthString = (setDate: string) => {
  const date = moment(setDate);
  return `Q${date.quarter()} ${date.year()}`;
};

interface Props {
  deferrals: DeferralRequest[];
  onAccept(id: number): void;
  onReject(id: number, comment: string): void;
}

const DeferralsReviewTable = ({ deferrals, onAccept, onReject }: Props) => {
  const { downloadReport } = useDownloadReport();

  const rows = deferrals.map(deferral => (
    <TableRow key={deferral.id}>
      <TableCell>{`${deferral.elementName} (${deferral.facilityName})`}</TableCell>
      <TableCell align="center">{deferral.requesterName}</TableCell>
      <TableCell align="center">
        {deferral.deferralLength === 'custom'
          ? dateToString(moment(deferral.targetDate).utc())
          : deferralLengthString(deferral.targetDate)}
      </TableCell>
      <TableCell style={{ whiteSpace: 'pre-line' }}>{deferral.comment}</TableCell>
      {deferral.reportId ? (
        <TableColourCell
          align="center"
          scoreColour={deferral.totalColour}
        >
          <ScoreColouredText scoreColour={deferral.totalColour}>{deferral.totalScore.toFixed(2)}</ScoreColouredText>
        </TableColourCell>
      ) : (
        <TableCell align="center">N/A</TableCell>
      )}
      {deferral.quarter ? (
        <TableCell align="center">
          Q{deferral.quarter} {deferral.year}
        </TableCell>
      ) : (
        <TableCell align="center">N/A</TableCell>
      )}
      <TableCell align="center">
        {deferral.uploadId && <DownloadButton onClick={R.thunkify(downloadReport)(deferral)} />}
      </TableCell>
      <TableCell
        align="center"
        padding="none"
      >
        <Approval
          onCheck={R.thunkify(onAccept)(deferral.id)}
          onClear={R.thunkify(onReject)(deferral.id)}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>System Name</TableCell>
            <TableCell align="center">Submitted By</TableCell>
            <TableCell align="center">Deferral To</TableCell>
            <TableCell align="center">Reason</TableCell>
            <TableCell align="center">Last Report Score</TableCell>
            <TableCell align="center">Last Report Date</TableCell>
            <TableCell align="center">PDF</TableCell>
            <TableCell align="center">Approval</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeferralsReviewTable;
