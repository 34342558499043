import React from 'react';

import { Grid, Paper } from '@material-ui/core';
import Rocket from './Rocket';

const Content = ({ title = 'No new records', subtitle = 'Looks like no records have landed yet!' }) => (
  <Paper elevation={0}>
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{ justifyContent: 'center' }}
    >
      <Grid
        item
        xs={1}
      >
        <Rocket />
      </Grid>
      <Grid
        item
        xs={5}
        container
      >
        <Grid
          item
          xs={12}
        >
          {title}
        </Grid>
        <Grid
          item
          xs={12}
        >
          {subtitle}
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

interface NoRecordsProps {
  title?: string;
  subtitle?: string;
}

const NoRecords = ({ title, subtitle }: NoRecordsProps) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    style={{ justifyContent: 'center', marginTop: '5%' }}
  >
    <Grid
      item
      xs={12}
    >
      <Content
        title={title}
        subtitle={subtitle}
      />
    </Grid>
  </Grid>
);
export default NoRecords;
