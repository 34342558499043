import { useCallback } from 'react';
import useDownloader from '../../../../../hooks/downloader';
import api from '../../../../../api';

const useAttachmentDownload = (walkdownId: number, attachment: any, taskId: number) => {
  const { onDownload } = useDownloader();

  const downloadAttachment = useCallback(async () => {
    const blob = await api.downloadTaskAttachment(walkdownId, taskId);
    onDownload(blob, attachment.name);
  }, [attachment, onDownload]);

  return {
    downloadAttachment,
  };
};

export default useAttachmentDownload;
