import React, { useContext, useState } from 'react';
import * as R from 'ramda';

interface AttachmentTypeContextValue {
  attachmentType: string;
  onChangeAttachmentType: React.Dispatch<React.SetStateAction<string>>;
}

const AttachmentTypeContext = React.createContext<AttachmentTypeContextValue | null>(null);

const useAttachmentType = () => {
  const context = useContext(AttachmentTypeContext);
  if (R.isNil(context)) {
    throw new Error('useAttachmentType must be used inside a AttachmentTypeProvider');
  }
  return context;
};

export const AttachmentTypeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [attachmentTypeValue, setAttachmentTypeValue] = useState('');

  const value = {
    attachmentType: attachmentTypeValue,
    onChangeAttachmentType: setAttachmentTypeValue,
  };

  return <AttachmentTypeContext.Provider value={value}>{children}</AttachmentTypeContext.Provider>;
};
export default useAttachmentType;
