import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpectedOutcomeForm from '../ExpectedOutcomeForm';
import StartIconButton from '../StartIconButton';
import FormLabel from '../CustomFormLabel';
import FormTitle from './FormTitle';
import ExpectedOutcomeList from './ExpectedOutcomeList';
import useAddListItem from '../../hooks/add-list-item';

interface Props {
  className?: string;
  values: string[];
  error?: boolean;
  helperText?: string;
  onAddItem: (value: string) => void;
  onUpdateItem: (index: number, value: string) => void;
  onRemoveItem: (index: number) => void;
}

const ExpectedOutcomes = ({ className, values, error, helperText, onAddItem, onUpdateItem, onRemoveItem }: Props) => {
  const { adding, add, onAdd, onCancelAdd } = useAddListItem(onAddItem, false);

  return (
    <Grid
      container
      className={className}
    >
      <Grid
        item
        lg={12}
        md={10}
        xs={8}
      >
        <FormLabel error={error}>Expected Outcomes (Optional)</FormLabel>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Grid>
      <Grid
        item
        lg={12}
        md={10}
        xs={8}
      >
        <ExpectedOutcomeList
          className={''}
          expectedOutcomes={values}
          onUpdateItem={onUpdateItem}
          onRemoveItem={onRemoveItem}
        />
      </Grid>
      {!adding && (
        <Grid
          item
          lg={12}
          md={10}
          xs={8}
        >
          <StartIconButton onClick={add}>Add</StartIconButton>
        </Grid>
      )}
      {adding && (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={12}
            md={10}
            xs={8}
          >
            <FormTitle>New Expected Outcome</FormTitle>
          </Grid>
          <Grid
            item
            lg={12}
            md={10}
            xs={8}
          >
            <ExpectedOutcomeForm
              expectedOutcome={[]}
              onSubmit={onAdd}
              onCancel={onCancelAdd}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ExpectedOutcomes;
