import * as R from 'ramda';

const toFieldFilter =
  ([field, value]) =>
  datum =>
    datum[field] === value;
const filterDatum = (filters, datum) => filters.reduce((acc, filter) => acc && filter(datum), true);

const useDataFilters = (data, filters) => {
  const selectedFilters = R.pipe(
    R.toPairs,
    R.filter(([, value]) => value !== ''),
    R.map(toFieldFilter)
  )(filters);

  const filteredData = R.filter(datum => filterDatum(selectedFilters, datum), data);

  return filteredData;
};

export default useDataFilters;
