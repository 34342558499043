import {
  Checkbox,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import ActionButton from '../../../components/ActionButton';
import CustomTextField from '../../../components/CustomTextField';
import useForm from '../../../hooks/form';
import { WalkdownTask } from '../../../types/walkdown-types';
import useSingleWalkdown from '../hooks/useSingleWalkdown';
import TaskCommentcell from './TaskCommentcell';
import UploadAttachmentsPage from './upload-attachments/UploadAttachmentsPage';
import useReadOnly from '../../../hooks/read-only';
import { WalkdownInputTypesProvider } from '../hooks/useWalkdownInputTypes';
import CreateTask from './CreateTask';

interface Props {
  tasks: WalkdownTask[];
  categoryId: number;
  walkdownId: number | string;
}

const formConfig = {
  tasks: {},
};

const validationProps = (r: WalkdownTask) => {
  try {
    const v = parseFloat(r.value as string);
    if ((!R.isNil(r.absoluteMax) && v > r.absoluteMax) || (!R.isNil(r.absoluteMin) && v < r.absoluteMin)) {
      return {
        error: true,
        helperText: 'Value out of acceptable range!',
      };
    }

    return { error: false };
  } catch {
    return { error: false };
  }
};

const WalkdownTaskList = ({ tasks, categoryId, walkdownId }: Props) => {
  const { updateTask, addTask, setTaskComment, addTaskAttachment } = useSingleWalkdown();

  const saveHandler = useCallback(
    (v: any) => {
      Promise.all(
        v.tasks.map((t: any) =>
          updateTask(categoryId, t.id, {
            isDone: t.isDone,
            value: t.value,
          })
        )
      );
    },
    [categoryId]
  );

  const { values, submit, setField } = useForm(formConfig, { tasks }, saveHandler);
  const [creating, setCreating] = useState(false);

  const createTaskHandler = useCallback(
    async (t: any) => {
      const newTask = await addTask(categoryId, t);
      if (newTask) {
        await setTaskComment(categoryId, newTask.id, t.comment);
        if (t.file) {
          await addTaskAttachment(newTask.id, t.file);
        }

        newTask.body = t.comment;
      }

      setCreating(false);
      setField('tasks', [...values.tasks, newTask]);
    },
    [categoryId]
  );

  const changeHandler = (index: number, field: string, value: any) => {
    const newTasks = [...values.tasks];
    newTasks[index] = {
      ...newTasks[index],
      [field]: value,
    };
    setField('tasks', newTasks);
  };

  const readOnly = useReadOnly();

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Done</TableCell>
                <TableCell>Task Title</TableCell>
                <TableCell>Task Description</TableCell>
                <TableCell align="center">Acceptable Range</TableCell>
                <TableCell align="center">Measurement/Observation</TableCell>
                <TableCell>Attachment</TableCell>
                <TableCell align="center">Comments</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {values.tasks.map((r: WalkdownTask, i: number) => (
                <TableRow key={r.id}>
                  <TableCell>
                    <Checkbox
                      checked={r.isDone}
                      onChange={(_e, v) => changeHandler(i, 'isDone', v)}
                      disabled={readOnly}
                    />
                  </TableCell>
                  <TableCell>{r.title}</TableCell>
                  <TableCell>{r.description}</TableCell>
                  <TableCell align="center">
                    {r.isMeasurement
                      ? `${R.isNil(r.min) ? '' : r.min} to ${R.isNil(r.max) ? '' : r.max} ${r.inputType}`
                      : ''}
                  </TableCell>
                  <TableCell align="center">
                    <CustomTextField
                      value={r.value || ''}
                      variant="outlined"
                      {...validationProps(r)}
                      onChange={e => changeHandler(i, 'value', e.target.value)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <UploadAttachmentsPage
                      walkdownId={walkdownId as any}
                      taskId={r.id}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TaskCommentcell
                      task={r}
                      hasComment={!!tasks[i].body}
                      setTaskComment={setTaskComment}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {creating && (
                <WalkdownInputTypesProvider>
                  <CreateTask onCreate={createTaskHandler} />
                </WalkdownInputTypesProvider>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {!readOnly && (
        <Grid
          container
          item
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <ActionButton
              onClick={submit}
              color="primary"
            >
              Save
            </ActionButton>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={() => setCreating(true)}
            >
              + Add Task
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WalkdownTaskList;
