import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as indicatorRecordActions from '../state/indicator-records/actions';
import useIndicatorApplicabilityHandlers from './indicator-applicability-handlers';
import useReviewRecordFinder from './review-record-finder';
import { propFn } from '../utils/indicator-specifics';

const useReviewIndicatorRecordApplicability = (
  elementId: string | number,
  year: string | number,
  quarter: string | number,
  indicatorId: string | number,
  indicatorShortName: string,
  records: any[]
) => {
  const dispatch = useDispatch();

  const indicatorRecordId = propFn(indicatorShortName);

  const findRecord = useReviewRecordFinder(records, indicatorShortName);

  const setInapplicable = useCallback(
    (record: any, options: any) => {
      dispatch(
        indicatorRecordActions.updateApplicabilityRequest(
          elementId,
          year,
          quarter,
          indicatorId,
          indicatorShortName,
          indicatorRecordId(record),
          false,
          options
        )
      );
    },
    [dispatch, elementId, year, quarter, indicatorId, indicatorShortName, indicatorRecordId]
  );

  const setApplicable = useCallback(
    (record: any, options: any) => {
      dispatch(
        indicatorRecordActions.updateApplicabilityRequest(
          elementId,
          year,
          quarter,
          indicatorId,
          indicatorShortName,
          indicatorRecordId(record),
          true,
          options
        )
      );
    },
    [dispatch, elementId, year, quarter, indicatorId, indicatorShortName, indicatorRecordId]
  );

  return useIndicatorApplicabilityHandlers(findRecord, setApplicable, setInapplicable);
};

export default useReviewIndicatorRecordApplicability;
