import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as elementsActions from '../state/elements/actions';
import * as elementsSelectors from '../state/elements/selectors';

const useElementUnits = (elementId, load = true) => {
  const dispatch = useDispatch();

  const loadElementUnits = useCallback(() => {
    if (!R.isNil(elementId)) {
      dispatch(elementsActions.loadElementUnitsRequest(elementId));
    }
  }, [dispatch, elementId]);

  useEffect(() => {
    if (load) {
      loadElementUnits();
    }
  }, [load, loadElementUnits]);

  const elementUnits = useSelector(state => elementsSelectors.getElementUnits(state, elementId));

  return {
    elementUnits,
    loadElementUnits,
  };
};

export default useElementUnits;
