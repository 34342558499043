import { TurnoverItemInput } from '../../../types/cwt/turnover';
import { Path, Remote } from '../../types';

const getTurnoverLogs = (path: Path, remote: Remote) => (workorderId?: number) =>
  remote.get(path(`/`), { params: { workorderId } });

const createTurnoverLog = (path: Path, remote: Remote) => (turnover: TurnoverItemInput) =>
  remote.post(path(`/`), { turnover });

const updateTurnoverLog = (path: Path, remote: Remote) => (turnover: TurnoverItemInput) =>
  remote.put(path(`/${turnover.id}`), { turnover });

const turnover = (path: Path, remote: Remote) => ({
  getTurnoverLogs: getTurnoverLogs(path, remote),
  createTurnoverLog: createTurnoverLog(path, remote),
  updateTurnoverLog: updateTurnoverLog(path, remote),
});

export default turnover;
