import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import CustomFormLabel from '../../../../components/CustomFormLabel';
import { WorkRequest } from '../../../../types/cwt/workrequest';

const NarrowParagraph = styled.p`
  margin-block: 0.25em;
`;

interface WorkRequestDetailsProps {
  workRequest: WorkRequest;
}

export const WorkRequestDetails: React.FC<WorkRequestDetailsProps> = ({ workRequest }) => {
  return (
    <Grid
      container
      item
      spacing={1}
      wrap="nowrap"
    >
      <Grid item>
        <CustomFormLabel
          component={NarrowParagraph}
          id="wr-details-number"
        >
          Work Request Number:
        </CustomFormLabel>
        <CustomFormLabel
          component={NarrowParagraph}
          id="wr-details-location"
        >
          Location:
        </CustomFormLabel>
        <CustomFormLabel
          component={NarrowParagraph}
          id="wr-details-title"
        >
          Title:
        </CustomFormLabel>
      </Grid>
      <Grid item>
        <NarrowParagraph aria-labelledby="wr-details-number">{workRequest.workRequestNumber}</NarrowParagraph>
        <NarrowParagraph aria-labelledby="wr-details-location">{workRequest.location ?? 'N/A'}</NarrowParagraph>
        <NarrowParagraph aria-labelledby="wr-details-title">{workRequest.title ?? 'N/A'}</NarrowParagraph>
      </Grid>
    </Grid>
  );
};
