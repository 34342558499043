import { Path, Remote } from '../types';

const getIndicatorRecords =
  (path: Path, remote: Remote) => (indicatorId: string, elementId: string | null, unit: string) =>
    remote.get(path(`/${indicatorId}`), { params: { elementId, unit } });
const getReportIndicatorRecords =
  (path: Path, remote: Remote) => (indicatorId: string, elementId: string, facilityId: string) =>
    remote.get(path(`/${indicatorId}`), { params: { elementId, facilityId } });
const updateIndicatorRecord =
  (path: Path, remote: Remote) => (indicatorId: string, recordId: string, applicable: string, options: any) =>
    remote.patch(path(`/${indicatorId}/${recordId}`), { applicable, options });

export default (path: Path, remote: Remote) => ({
  getIndicatorRecords: getIndicatorRecords(path, remote),
  getReportIndicatorRecords: getReportIndicatorRecords(path, remote),
  updateIndicatorRecord: updateIndicatorRecord(path, remote),
});
