import { useMemo } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import buildForecast from 'echo-forecast';

const useForecast = (actions, scorecard) => {
  const filteredActions = R.filter(
    R.propSatisfies(d => moment(d).isAfter(), 'targetDate'),
    actions
  );

  return useMemo(() => buildForecast(scorecard, filteredActions), [scorecard, filteredActions]);
};

export default useForecast;
