import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import StartIconButton from '../../../../components/StartIconButton';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import Comment from './Comment';
import useExecutiveSummaryUi from './hooks/executive-summary-ui';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListItem = styled.li``;

const List = styled.ul`
  list-style: none;
  padding-left: 0px;

  ${ListItem} {
    margin-bottom: 20px;
  }
`;

const CommentList = ({ className }: { className?: string }) => {
  const {
    isEmptyExecutiveSummary,
    comments,
    uiState,
    editingCommentIndex,
    deletingComment,
    onConfirmDeleteComment,
    onCancelDeleteComment,
    onReorderComment,
    onAddComment,
    onEditComment,
    onDeleteComment,
    subjectCommentId,
  } = useExecutiveSummaryUi();

  const handleUp = (commentId: string, index: number) => () => {
    onReorderComment(commentId, Math.max(0, index - 1));
  };

  const handleDown = (commentId: string, index: number) => () => {
    onReorderComment(commentId, Math.min(comments.length - 1, index + 1));
  };

  const commentFields = comments.map((comment: any, i: number) => (
    <ListItem key={`comment_${i}`}>
      <Comment
        comment={comment}
        editing={
          (uiState === 'editingComment' && i === editingCommentIndex) ||
          (uiState === 'savingComment' && i === editingCommentIndex)
        }
        saving={
          (uiState === 'savingComment' && i === editingCommentIndex) ||
          (uiState === 'finalizingComment' && comment.id === subjectCommentId) ||
          (uiState === 'updatingCommentSequence' && comment.id === subjectCommentId) ||
          (uiState === 'deletingComment' && comment.id === subjectCommentId)
        }
        canEdit={uiState === 'idle'}
        onEdit={R.thunkify(onEditComment)(i)}
        onDelete={R.thunkify(onDeleteComment)(i)}
        onUp={i !== 0 ? handleUp(comment.id, i) : undefined}
        onDown={i !== comments.length - 1 ? handleDown(comment.id, i) : undefined}
      />
    </ListItem>
  ));

  return (
    <>
      <List className={className}>{commentFields}</List>
      <ButtonWrapper>
        <StartIconButton
          disabled={isEmptyExecutiveSummary}
          onClick={onAddComment}
        >
          Add New Section
        </StartIconButton>
      </ButtonWrapper>
      {deletingComment && (
        <OkayCancelDialog
          title="Delete?"
          okayLabel="Delete"
          onOkay={onConfirmDeleteComment}
          onCancel={onCancelDeleteComment}
        >
          Are you sure you want to delete this section with title "{deletingComment.title}"?
        </OkayCancelDialog>
      )}
    </>
  );
};

export default CommentList;
