import { call, put, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as actions from './actions';

function* loadReports(action) {
  try {
    const { states } = action.payload;
    const reports = yield call(Api.getReports, states);
    yield put(actions.loadAllSuccess(reports));
  } catch (e) {
    yield put(actions.loadAllFailure(e));
  }
}

function* loadReport(action) {
  try {
    const { reportId } = action.payload;
    const report = yield call(Api.getReport, reportId);
    yield put(actions.loadSuccess(report));
  } catch (e) {
    yield put(actions.loadFailure(e));
  }
}

function* loadComments(action) {
  try {
    const { reportId } = action.payload;
    const comments = yield call(Api.getReportComments, reportId);
    yield put(actions.loadReportCommentsSuccess(reportId, comments));
  } catch (err) {
    yield put(actions.loadReportCommentsFailure(err));
  }
}

// Replaces a report's comments with another
function* loadPreviousReportComments(action) {
  try {
    const { reportId, previousId } = action.payload;
    const previousComments = yield call(Api.copyReportComments, reportId, previousId);
    yield put(actions.loadReportCommentsSuccess(reportId, previousComments));
  } catch (err) {
    yield put(actions.loadReportCommentsFailure(err));
  }
}

function* updateExecutiveSummary(action) {
  try {
    const { reportId, executiveSummary, executiveSummaryUpdatedAt } = action.payload;
    yield call(Api.updateReportExecutiveSummary, reportId, executiveSummary, executiveSummaryUpdatedAt);
    yield put(actions.updateExecutiveSummarySuccess(reportId, executiveSummary, executiveSummaryUpdatedAt));
  } catch (e) {
    yield put(actions.updateExecutiveSummaryFailure(e));
  }
}

function* submitReport(action) {
  try {
    const { report, users } = action.payload;
    yield call(Api.submitReport, report.id, users);
    yield put(actions.submitSuccess(report));
  } catch (e) {
    yield put(actions.submitFailure(e));
  }
}

function* saveComment(action) {
  try {
    const { reportId, commentId, commentsUpdatedAt, sequence, title, body } = action.payload;
    if (R.isNil(commentId)) {
      const { id, sequence: newSequence } = yield call(Api.createReportComment, reportId, { title, body });
      yield put(actions.createCommentSuccess(reportId, id, newSequence, title, body));
    } else {
      yield call(Api.updateReportComment, reportId, commentId, { title, body }, commentsUpdatedAt);
      yield put(actions.updateCommentSuccess(reportId, commentId, commentsUpdatedAt, sequence, title, body));
    }
  } catch (e) {
    yield put(actions.saveCommentFailure(e));
  }
}

function* updateCommentSequence(action) {
  try {
    const { reportId, commentId, sequence } = action.payload;
    yield call(Api.updateReportComment, reportId, commentId, { sequence });
    yield put(actions.updateCommentSequenceSuccess(reportId, commentId, sequence));
  } catch (e) {
    yield put(actions.updateCommentSequenceFailure(e));
  }
}

function* deleteComment(action) {
  try {
    const { reportId, commentId } = action.payload;
    yield call(Api.deleteReportComment, reportId, commentId);
    yield put(actions.deleteCommentSuccess(reportId, commentId));
  } catch (e) {
    yield put(actions.deleteCommentFailure(e));
  }
}

function* approveReport(action) {
  try {
    const { report, comment } = action.payload;
    yield call(Api.approveReport, report.id, comment);
    yield put(actions.approveSuccess(report));
  } catch (e) {
    yield put(actions.approveFailure(e));
  }
}

function* reverseApproveRequest(action) {
  try {
    const { reportId, states } = action.payload;
    yield call(Api.reverseApproveReport, reportId);
    const reports = yield call(Api.getReports, states);
    yield put(actions.reverseApproveSuccess(reports));
  } catch (e) {
    yield put(actions.reverseApproveFailure(e));
  }
}

function* rejectReport(action) {
  try {
    const { report, comment } = action.payload;
    yield call(Api.rejectReport, report.id, comment);
    yield put(actions.rejectSuccess(report));
  } catch (e) {
    yield put(actions.rejectFailure(e));
  }
}

function* closeReport(action) {
  try {
    const { report, comment } = action.payload;
    yield call(Api.closeReport, report.id, comment);
    yield put(actions.closeSuccess(report));
  } catch (e) {
    yield put(actions.closeFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.REPORTS_LOAD_ALL_REQUEST, loadReports);
  yield takeEvery(actionTypes.REPORTS_LOAD_COMMENTS_REQUEST, loadComments);
  yield takeEvery(actionTypes.REPORTS_LOAD_PREVIOUS_COMMENTS_REQUEST, loadPreviousReportComments);
  yield takeEvery(actionTypes.REPORTS_LOAD_REQUEST, loadReport);
  yield takeEvery(actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_REQUEST, updateExecutiveSummary);
  yield takeEvery(actionTypes.REPORTS_FINALIZE_EXECUTIVE_SUMMARY_REQUEST, updateExecutiveSummary); // same mechanism, different trigger
  yield takeEvery(actionTypes.REPORTS_SAVE_COMMENT_REQUEST, saveComment);
  yield takeEvery(actionTypes.REPORTS_FINALIZE_COMMENT_REQUEST, saveComment);
  yield takeEvery(actionTypes.REPORTS_DELETE_COMMENT_REQUEST, deleteComment);
  yield takeEvery(actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_REQUEST, updateCommentSequence);
  yield takeEvery(actionTypes.REPORTS_SUBMIT_REQUEST, submitReport);
  yield takeEvery(actionTypes.REPORTS_APPROVE_REQUEST, approveReport);
  yield takeEvery(actionTypes.REPORTS_REVERSE_APPROVE_REQUEST, reverseApproveRequest);
  yield takeEvery(actionTypes.REPORTS_REJECT_REQUEST, rejectReport);
  yield takeEvery(actionTypes.REPORTS_CLOSE_REQUEST, closeReport);
}

export default root;
