import React from 'react';
import { AllAttachmentTypesProvider } from '../../../hooks/all-attachment-types';
import { Downloader } from '../../../hooks/downloader';
import AttachmentsBox from './components/AttachmentsBox';

interface Props {
  walkdownId: number;
}

const UploadAttachmentsPage = ({ walkdownId }: Props) => (
  <AllAttachmentTypesProvider>
    <Downloader>
      <AttachmentsBox walkdownId={walkdownId} />
    </Downloader>
  </AllAttachmentTypesProvider>
);

export default UploadAttachmentsPage;
