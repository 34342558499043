import React from 'react';
import * as R from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useForm from '../../../../hooks/form';

const formConfig = (allowEmpty?: boolean) => ({
  userId: {
    validate: {
      presence: {
        allowEmpty,
        message: 'Select a user',
      },
    },
  },
});
const initialValues = (userId: string) => ({
  userId: userId || '',
});

interface Props {
  className?: string;
  title?: string;
  allowEmpty?: boolean;
  userId?: string;
  users: any[];
  onSubmit: any;
  onCancel: any;
}

const UserSelectForm = ({ className, title, allowEmpty = false, userId = '', users, onSubmit, onCancel }: Props) => {
  const handleSubmit = (values: any) => {
    onSubmit(values.userId);
  };

  const { values, submit, propsForField, setField } = useForm(
    formConfig(allowEmpty),
    initialValues(userId),
    handleSubmit
  );

  const options = R.sortBy<any>(
    R.prop('name'),
    R.map(user => ({ value: user.id, label: user.name }), users)
  );

  const canSubmit = allowEmpty || !R.isEmpty(values.userId);

  return (
    <Grid
      container
      component="fieldset"
      spacing={2}
      className={className}
    >
      <legend>{title}</legend>
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          id={userId}
          renderInput={params => (
            <TextField
              {...params}
              label="User"
              variant="outlined"
              {...propsForField('userId')}
            />
          )}
          options={options}
          getOptionLabel={(option: any) => option.label}
          onChange={(_, value) => {
            setField('userId', value.value);
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <IconButton
          onClick={submit}
          disabled={!canSubmit}
        >
          <SaveIcon />
        </IconButton>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default UserSelectForm;
