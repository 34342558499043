import * as R from 'ramda';

import { Indicator, Scorecard, Section as ScorecardSection } from 'echo-scorecard';
import CarlaText from '../CarlaText';
import { CategoryCell, ColourCategoryCell } from '../CategoryCell';
import { EditableIndicatorRow } from './IndicatorRow/EditableIndicatorRow';
import {
  CategoryText,
  Header,
  HeaderCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableTitle,
} from './StyledComponents';
import { InteractiveCell } from './InteractiveCell';
import { Props } from './types';
import { ReadOnlyIndicatorRow } from './IndicatorRow/ReadOnlyIndicatorRow';

const IndicatorRow = ({
  editable,
  onCalculatedScoreClicked,
  onManualScoreClicked,
  ...props
}: Omit<Props, 'className' | 'onUnitTotalScoreClicked'> & { indicator: Indicator }) =>
  editable ? (
    <EditableIndicatorRow
      onCalculatedScoreClicked={onCalculatedScoreClicked}
      onManualScoreClicked={onManualScoreClicked}
      {...props}
    />
  ) : (
    <ReadOnlyIndicatorRow {...props} />
  );

const TotalsRow = ({
  scorecard,
  onUnitTotalScoreClicked,
}: {
  scorecard: Scorecard;
  onUnitTotalScoreClicked?: ((unit: string) => void) | null;
}) => {
  const totalItems = scorecard.units.map((unit: string) =>
    !R.isNil(onUnitTotalScoreClicked) ? (
      <InteractiveCell
        key={unit}
        colour={scorecard.unitMap[unit]?.mcr ? 'grey' : scorecard.totals.byUnit[unit]?.colour}
        overridden={R.pathOr(false, ['totals', 'byUnit', unit, 'override'], scorecard)}
        onClick={() => onUnitTotalScoreClicked(unit)}
      >
        <CarlaText>{R.pathOr(0, ['totals', 'byUnit', unit, 'score'], scorecard).toFixed(2)}</CarlaText>
      </InteractiveCell>
    ) : (
      <ColourCategoryCell
        key={unit}
        colour={scorecard.unitMap[unit]?.mcr ? 'grey' : scorecard.totals.byUnit[unit]?.colour}
        overridden={R.pathOr(false, ['totals', 'byUnit', unit, 'override'], scorecard)}
      >
        <CarlaText font="bold14">{R.pathOr(0, ['totals', 'byUnit', unit, 'score'], scorecard).toFixed(2)}</CarlaText>
      </ColourCategoryCell>
    )
  );

  return (
    <TableRow>
      <CategoryCell align="right">
        <CarlaText font="bold14">UNIT SCORE</CarlaText>
      </CategoryCell>
      {totalItems}
    </TableRow>
  );
};

const Section = ({
  section,
  scorecard,
  editable,
  onCalculatedScoreClicked,
  onManualScoreClicked,
}: { section: ScorecardSection } & Omit<Props, 'className' | 'onUnitTotalScoreClicked'>) => {
  const indicatorRows = section.indicators.map((indicator: any) =>
    scorecard.indicatorMap[indicator] ? (
      <IndicatorRow
        key={indicator}
        indicator={scorecard.indicatorMap[indicator] as Indicator}
        scorecard={scorecard}
        editable={editable}
        onCalculatedScoreClicked={onCalculatedScoreClicked}
        onManualScoreClicked={onManualScoreClicked}
      />
    ) : null
  );

  return (
    <>
      <TableRow>
        <CategoryCell colSpan={scorecard.units.length + 2}>
          <CategoryText>{section.name}</CategoryText>
        </CategoryCell>
      </TableRow>
      {indicatorRows}
    </>
  );
};

const ScorecardTable = ({
  className,
  scorecard,
  editable,
  onCalculatedScoreClicked,
  onManualScoreClicked,
  onUnitTotalScoreClicked,
}: Props) => {
  const unitHeaderCells = scorecard.units.map((unit: string) => (
    <HeaderCell key={unit}>
      <Header>{unit}</Header>
    </HeaderCell>
  ));

  const sectionItems = scorecard.sections.map((section: ScorecardSection) => (
    <Section
      key={section.name}
      section={section}
      scorecard={scorecard}
      editable={editable}
      onCalculatedScoreClicked={onCalculatedScoreClicked}
      onManualScoreClicked={onManualScoreClicked}
    />
  ));

  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow numUnits={R.isNil(scorecard.units) ? 0 : scorecard.units.length}>
            <HeaderCell>
              <TableTitle>Scorecard</TableTitle>
            </HeaderCell>
            {unitHeaderCells}
          </TableRow>
        </TableHead>
        <TableBody>
          {sectionItems}
          <TotalsRow
            scorecard={scorecard}
            onUnitTotalScoreClicked={onUnitTotalScoreClicked}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScorecardTable;
