import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledSelect from '../Select';

const EchoSelect = styled(UnstyledSelect)`
  width: 600px;
`;

const ExpectedOutcomeUnits = ({ unitOptions, CustomFormLabel, setField, value }: any) => {
  if (!unitOptions.length) {
    return null;
  }

  const changeHandler = (e: any) => {
    setField('elementUnit', e.target.value);
  };

  const sortedUnitOptions = R.sortBy<any>(R.prop('label'), unitOptions);

  return (
    <EchoSelect
      multiple
      label="Unit"
      fullWidth
      options={sortedUnitOptions}
      LabelComponent={CustomFormLabel}
      onChange={changeHandler}
      value={value}
    />
  );
};

export default ExpectedOutcomeUnits;
