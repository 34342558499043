import ReactDataGrid from '@inovua/reactdatagrid-community';
import { renderMaximoLink } from '../../wo/components/utils';
import { format } from 'date-fns';
import { useChangeTrackingValue } from '../../hooks/useChangeTracking';

const gridStyle = { minHeight: 600, minWidth: '100%' };

type ChangeTrackingTableProps = Pick<useChangeTrackingValue, 'filteredLogs' | 'setGridRef' | 'loading'>;

const ChangeTrackingTable = ({ filteredLogs, loading, setGridRef }: ChangeTrackingTableProps) => {
  const columns = [
    {
      name: 'wonum',
      header: 'WO #',
      render: ({ data }: any) => renderMaximoLink(data.workorderId, data.wonum),
    },
    {
      name: 'recordType',
      header: 'Changed Type',
    },
    {
      name: 'field',
      header: 'Changed Value',
    },
    {
      name: 'oldValue',
      header: 'Old Value',
      flex: 1,
    },
    {
      name: 'newValue',
      header: 'New Value',
      flex: 1,
    },
    {
      name: 'changedDate',
      header: 'Changed Date',
      render: (value: any) => {
        const date = new Date(value.data.changedDate);
        return value.data.changedDate ? format(date, 'ddMMMyyyy').toUpperCase().toString() : '';
      },
    },
    {
      name: 'changedByName',
      header: 'Changed By',
    },
  ];

  return (
    <ReactDataGrid
      style={gridStyle}
      dataSource={filteredLogs}
      columns={columns}
      loading={loading}
      pagination
      onReady={setGridRef}
    />
  );
};
export default ChangeTrackingTable;
