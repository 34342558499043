/**
 * Prefixes text with either of the articles "a" or "an", depending on whether it starts with a vowel
 *
 * Warning: This logic is overly simplistic and will miss some cases where a word should be prefixed with "an",
 * e.g. "honour".
 *
 * @param text
 * @returns text prefixed with "an" if it starts with a vowel and prefixed with "a" otherwise
 */
export const prefixWithAOrAn = (text: string) => `${text.match(/^[aeiou]/i) ? 'an' : 'a'} ${text}`;
