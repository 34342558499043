import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ActionDialog from '../../../../components/ActionDialog';
import useActions from '../../../../hooks/actions';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import useReorderActions from '../../../../hooks/reorder-actions';
import useEditAction from '../../../../hooks/edit-action';
import useNewAction from '../../../../hooks/new-action';
import useActionState from '../../../../hooks/action-state';
import UnstyledActionTable from '../../../../components/ActionTable';
import NavPaper from '../../components/NavPaper';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import ActionFilterPanel from '../../../../components/ShipChip/ActionFilterPanel';
import { MyElement } from '../../../../types/my';
import type { ActionSource, ActionState } from '../../../../types/actions';
import LoadingContainer from '../../../../components/LoadingContainer';

const ActionTable = styled(UnstyledActionTable)`
  max-height: calc(100vh - 270px);
`;

interface Props {
  report: any;
  onNext(): void;
  onBack(): void;
}

const ReviewShipPage = ({ report: { elementId, elementType }, onNext, onBack }: Props) => {
  const [state, setState] = useState<ActionState>('ongoing');
  const [source, setSource] = useState<ActionSource>('All');
  const { actions, isLoading } = useActions(elementId, state, source);

  const [comment, setComment] = useState('');

  const reorderActions = useReorderActions(actions, state);
  const { editAction, showEditActionDialog, clearEditAction, updateAction } = useEditAction(actions);
  const { newAction, showNewActionDialog, clearNewAction, saveNewAction } = useNewAction({
    elementId,
    elementType,
  } as MyElement);
  const {
    statusAction,
    confirmActionStatusToggle,
    clearStatusAction,
    toggleActionStatus,
    deleteActionStatus,
    confirmDeleteActionToggle,
    deleteAction,
    clearDeleteAction,
  } = useActionState(actions);

  const handleNext = () => {
    // save some stuff
    onNext();
  };

  const handleBack = () => {
    // save some stuff
    onBack();
  };

  const handleOnOkay = () => {
    toggleActionStatus(comment);
    setComment('');
  };

  const handleOnCancel = () => {
    clearStatusAction();
    setComment('');
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <NavPaper
          onNext={handleNext}
          onBack={handleBack}
        >
          <ActionFilterPanel
            state={state}
            source={source}
            onChangeStatus={setState}
            onChangeSource={setSource}
            onAddNew={showNewActionDialog}
          />
        </NavPaper>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <LoadingContainer loading={isLoading}>
          {actions?.length > 0 ? (
            <ActionTable
              actions={actions}
              state={state}
              reorderable={state === 'ongoing'}
              onChangeStatus={confirmActionStatusToggle}
              onEdit={showEditActionDialog}
              onDelete={confirmDeleteActionToggle}
              reorderRows={reorderActions}
              element={{ elementId, elementType }}
            />
          ) : (
            <>No actions found</>
          )}
        </LoadingContainer>
        {editAction && (
          <ElementIndicatorsProvider elementId={elementId}>
            <ActionDialog
              action={editAction}
              onSave={updateAction}
              onCancel={clearEditAction}
            />
          </ElementIndicatorsProvider>
        )}
        {newAction && (
          <ElementIndicatorsProvider elementId={elementId}>
            <ActionDialog
              action={newAction}
              onSave={saveNewAction}
              onCancel={clearNewAction}
            />
          </ElementIndicatorsProvider>
        )}
        {statusAction && (
          <OkayCancelDialog
            title={statusAction.state === 'complete' ? 'Re-open?' : 'Complete?'}
            okayLabel="Yes"
            onOkay={handleOnOkay}
            onCancel={handleOnCancel}
          >
            <Grid
              container
              item
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                {statusAction.state === 'complete'
                  ? 'Are you sure you want to re-open this action?'
                  : 'Are you sure you want to mark this action complete?'}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  label="Submission Comment"
                  fullWidth
                  onChange={e => setComment(e.target.value)}
                />
              </Grid>
            </Grid>
          </OkayCancelDialog>
        )}
        {deleteAction && (
          <OkayCancelDialog
            title={'Delete Warning'}
            okayLabel="Yes"
            onOkay={deleteActionStatus}
            onCancel={clearDeleteAction}
          >
            <Grid
              container
              item
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                Are you sure you wish to delete this action?
              </Grid>
              <Grid
                item
                xs={12}
              >
                <b>It will be permanently removed</b>
              </Grid>
            </Grid>
          </OkayCancelDialog>
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewShipPage;
