import HistoricalReportFilter from '../types/historical-report-filter';
import createQueryParam from '../utils/url-query';

const getHistoricalReport = (path: any, remote: any) => (id: number) => remote.get(path(`pdfs/${id}`));

const listHistoricalReports = (path: any, remote: any) => (filters: HistoricalReportFilter) => {
  const { elementName, facilityId, elementType, year, quarter } = filters;

  const nameQuery = createQueryParam('elementName', elementName);
  const typeQuery = createQueryParam('elementType', elementType);
  const yearQuery = createQueryParam('year', year);
  const quarterQuery = createQueryParam('quarter', quarter);
  const facilityQuery = createQueryParam('facilityId', facilityId);

  const queryString = `${nameQuery}${facilityQuery}${typeQuery}${yearQuery}${quarterQuery}`;

  return remote.get(path(`/pdfs?${queryString}`));
};

const listHistoricalElements = (path: any, remote: any) => (elementType?: string) => {
  const queryString = createQueryParam('elementType', elementType);
  return remote.get(path(`/elements?${queryString}`));
};

const downloadHistoricalReport = (path: any, remote: any) => (id: number) => remote.download(path(`/pdfs/${id}`));

export default (path: any, remote: any) => ({
  getHistoricalReport: getHistoricalReport(path, remote),
  listHistoricalReports: listHistoricalReports(path, remote),
  listHistoricalElements: listHistoricalElements(path, remote),
  downloadHistoricalReport: downloadHistoricalReport(path, remote),
});
