import { useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { ReferenceFormInputs } from '../../../types/outages';

export const useFormProps = (edit = false): UseFormReturn<ReferenceFormInputs> => {
  const initialValues = useMemo<ReferenceFormInputs>(() => {
    return edit
      ? {
          outageCode: null,
          actionType: null,
          referenceType: null,
          referenceNumberOption: null,
        }
      : {
          outageCode: null,
          actionType: null,
          referenceType: null,
          referenceNumberText: null,
        };
  }, [edit]);

  const formProps = useForm<ReferenceFormInputs>({
    defaultValues: initialValues,
  });

  return formProps;
};
