import * as actionTypes from '../../../../../state/action-types';
import errorAction from '../../../../../state/utils/error-action';

export const loadRecordsRequest = (indicatorId, element, unit) => ({
  type: actionTypes.RECORD_DETAILS_LOAD_RECORDS_REQUEST,
  payload: {
    indicatorId,
    element,
    unit,
  },
});

export const loadRecordsSuccess = (indicatorId, records) => ({
  type: actionTypes.RECORD_DETAILS_LOAD_RECORDS_SUCCESS,
  payload: {
    indicatorId,
    records,
  },
});

export const loadRecordsFailure = errorAction(actionTypes.RECORD_DETAILS_LOAD_RECORDS_FAILURE);

export const loadRecordDetailsRequest = (indicatorShortName, record) => ({
  type: actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST,
  payload: {
    indicatorShortName,
    record,
  },
});

export const loadRecordDetailsSuccess = recordDetails => ({
  type: actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_SUCCESS,
  payload: {
    recordDetails,
  },
});

export const loadRecordDetailsFailure = errorAction(actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_FAILURE);

export const setFilter = (name, value) => ({
  type: actionTypes.RECORD_DETAILS_SET_FILTER,
  payload: {
    name,
    value,
  },
});

export const updateIndicatorRecord = (indicatorId, element, unit, recordId, applicable, options) => ({
  type: actionTypes.RECORD_DETAILS_UPDATE_INDICATOR_RECORD_REQUEST,
  payload: {
    indicatorId,
    element,
    unit,
    recordId,
    applicable,
    options,
  },
});

export const updateIndicatorRecordSuccess = (indicatorId, element, unit, recordId, applicable, options) => ({
  type: actionTypes.RECORD_DETAILS_UPDATE_INDICATOR_RECORD_SUCCESS,
  payload: {
    indicatorId,
    element,
    unit,
    recordId,
    applicable,
    options,
    message: applicable ? 'Record applied' : 'Record marked inapplicable',
  },
});

export const updateIndicatorRecordFailure = errorAction(actionTypes.RECORD_DETAILS_UPDATE_INDICATOR_RECORD_FAILURE);
