import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { dateToString } from '../../../../utils/date-format';
import pluralize from '../../../../utils/pluralize';
import ExpectedOutcomeSummary from '../../../../components/ExpectedOutcomeSummary';
import { ActionProvider } from '../../../../hooks/action';

const ExpectedOutcome = ({ expectedOutcome }) => (
  <ListItem>
    <ListItemIcon>
      {expectedOutcome.changeType === 'increase' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
    </ListItemIcon>
    <ListItemText>
      <ExpectedOutcomeSummary expectedOutcome={expectedOutcome} />
    </ListItemText>
  </ListItem>
);

const ExpectedOutcomes = ({ expectedOutcomes }) => {
  const items = expectedOutcomes.map(expectedOutcome => (
    <ExpectedOutcome
      key={expectedOutcome.id}
      expectedOutcome={expectedOutcome}
    />
  ));

  return <List>{items}</List>;
};

const ForecastAction = ({ action }) => (
  <ActionProvider action={action}>
    <ListItem>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography>{action.description}</Typography>
        </Grid>
        <Grid item>
          <ExpectedOutcomes expectedOutcomes={action.expectedOutcomes} />
        </Grid>
      </Grid>
    </ListItem>
  </ActionProvider>
);

const ForecastActions = ({ actions }) => {
  const items = actions.map(action => (
    <ForecastAction
      key={action.id}
      action={action}
    />
  ));

  return <List>{items}</List>;
};

const ScorecardCell = styled(props => <TableCell {...R.omit(['colour'], props)} />)`
  background-color: ${({ colour, theme }) => theme.colours.scorecard[colour].background};
  color: ${({ colour, theme }) => theme.colours.scorecard[colour].text};
`;

const ForecastScorecard = ({ scorecard }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Indicator</TableCell>
          {scorecard.units.map(unitId => (
            <TableCell
              key={unitId}
              align="center"
            >
              {unitId}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {scorecard.indicators.map(indicatorId => (
          <TableRow key={indicatorId}>
            <TableCell>{scorecard.indicatorMap[indicatorId].name}</TableCell>
            {scorecard.units.map(unitId => (
              <ScorecardCell
                key={unitId}
                colour={scorecard.scores[indicatorId][unitId].colour}
                align="center"
              >
                {scorecard.scores[indicatorId][unitId].value}
              </ScorecardCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const ForecastEvent = ({ event, initial }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>
        {dateToString(event.date)} (
        {initial ? 'Initial' : `${event.actions.length} ${pluralize('action', event.actions.length)}`})
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        {!initial && (
          <>
            <Grid item>
              <Typography variant="subtitle1">Actions</Typography>
            </Grid>
            <Grid item>
              <ForecastActions actions={event.actions} />
            </Grid>
          </>
        )}
        <Grid item>
          <Typography variant="subtitle1">Scorecard</Typography>
        </Grid>
        <Grid item>
          <ForecastScorecard scorecard={event.scorecard} />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);

const ForecastDetails = ({ forecast }) => {
  const items = forecast.map((event, i) => (
    <ForecastEvent
      key={event.date}
      event={event}
      initial={i === 0}
    />
  ));

  return <div>{items}</div>;
};

export default ForecastDetails;
