import styled from 'styled-components';
import TableRow from '@material-ui/core/TableRow';

const BodyRow = styled(TableRow).attrs({
  tabIndex: -1,
})`
  border: none;
  vertical-align: top;

  .MuiTypography-body1:first-child {
    padding-top: 6px;
  }
`;

export default BodyRow;
