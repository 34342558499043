import { gt } from 'lodash';
import { Report } from '../../../../hooks/report';

export const getLastSavedTime = (report: Report | undefined, comment: { commentsUpdatedAt: string }[] | undefined) => {
  const clientTimestamp = report?.executiveSummaryUpdatedAt ? new Date(report?.executiveSummaryUpdatedAt) : undefined;
  const latestCommentTime = comment?.reduce<Date | undefined>((maxTime, currComment) => {
    const commentTime = new Date(currComment.commentsUpdatedAt);
    return !maxTime || commentTime > maxTime ? commentTime : maxTime;
  }, undefined);

  const latestTime = gt(latestCommentTime, clientTimestamp) ? latestCommentTime : clientTimestamp;
  return latestTime?.toLocaleString();
};
