import React, { useState } from 'react';
import { CardActionArea, Collapse, Grid, Typography } from '@material-ui/core';
import UnstyledCard from '@material-ui/core/Card';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { WalkdownTemplateCategory, WalkdownTemplateTask } from '../../../../types/walkdown-template';
import TasksList from './TasksList';

const Card = styled(UnstyledCard)`
  width: 100%;
  margin-bottom: 2%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow2};
  margin-top: 10px;
`;

const ClickSection = styled(Grid)`
  padding: 21px 24px;
`;

const Content = styled.div`
  padding: 2%;
`;

interface Props {
  category: WalkdownTemplateCategory;
  onRemoveCategory(id: any): any;
  onCreateTask(categoryId: any, task: WalkdownTemplateTask): any;
  onRemoveTask(categoryId: any, t: WalkdownTemplateTask): any;
  onUpdateTask(categoryId: any, task: WalkdownTemplateTask): any;
}

const TemplateCategory = ({ category, onCreateTask, onRemoveTask, onRemoveCategory, onUpdateTask }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
        >
          <CardActionArea onClick={() => setIsOpen(!isOpen)}>
            <ClickSection
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="button">{category.name}</Typography>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ClickSection>
          </CardActionArea>
        </Grid>
      </Grid>
      <Collapse in={isOpen}>
        <Content>
          {category && (
            <TasksList
              tasks={category.tasks}
              categoryId={category.id}
              categoryName={category.name}
              onCreateTask={onCreateTask}
              onUpdateTask={onUpdateTask}
              onRemoveCategory={onRemoveCategory}
              onRemoveTask={onRemoveTask}
            />
          )}
        </Content>
      </Collapse>
    </Card>
  );
};

export default TemplateCategory;
