import * as actionTypes from '../../../state/action-types';
import errorAction from '../../../state/utils/error-action';

export const loadUserAssignmentsRequest = userId => ({
  type: actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_REQUEST,
  payload: {
    userId,
  },
});

export const loadUserAssignmentsSuccess = (userId, userAssignments) => ({
  type: actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_SUCCESS,
  payload: {
    userId,
    userAssignments,
  },
});

export const loadUserAssignmentsFailure = errorAction(actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_FAILURE);

export const loadElementsRequest = () => ({
  type: actionTypes.ADMIN_LOAD_ELEMENTS_REQUEST,
});

export const loadElementsSuccess = elements => ({
  type: actionTypes.ADMIN_LOAD_ELEMENTS_SUCCESS,
  payload: {
    elements,
  },
});

export const loadElementsFailure = errorAction(actionTypes.ADMIN_LOAD_ELEMENTS_FAILURE);

export const addUserAssignmentRequest = (userId, elementId, assignmentType) => ({
  type: actionTypes.ADMIN_ADD_USER_ASSIGNMENT_REQUEST,
  payload: {
    userId,
    elementId,
    assignmentType,
  },
});

export const addUserAssignmentSuccess = (userId, elementId, assignmentType) => ({
  type: actionTypes.ADMIN_ADD_USER_ASSIGNMENT_SUCCESS,
  payload: {
    userId,
    elementId,
    assignmentType,
  },
});

export const addUserAssignmentFailure = errorAction(actionTypes.ADMIN_ADD_USER_ASSIGNMENT_FAILURE);

export const deleteUserAssignmentRequest = (userId, elementId, assignmentType) => ({
  type: actionTypes.ADMIN_DELETE_USER_ASSIGNMENT_REQUEST,
  payload: {
    userId,
    elementId,
    assignmentType,
  },
});

export const deleteUserAssignmentSuccess = (userId, elementId, assignmentType) => ({
  type: actionTypes.ADMIN_DELETE_USER_ASSIGNMENT_SUCCESS,
  payload: {
    userId,
    elementId,
    assignmentType,
  },
});

export const deleteUserAssignmentFailure = errorAction(actionTypes.ADMIN_DELETE_USER_ASSIGNMENT_FAILURE);
