import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import Table from '../../../../../../components/Table';
import ControlButton from '../../../../../../components/ControlButton';
import LongDescription from '../../../../../../components/LongDescription';
import CarlaText from '../../../../../../components/CarlaText';
import UnstyledExpandableTable from './ExpandableTable';
import ExpandableTableBody from './ExpandableTableBody';

const ExpandableTable = styled(UnstyledExpandableTable)`
  .MuiTableCell-body {
    color: ${({ theme }) => theme.colours.greys.darkGrey5};
    font: ${({ theme }) => theme.fonts.medium14};
  }

  .MuiTableCell-body.strong {
    color: ${({ theme }) => theme.colours.greys.darkGrey4};
  }
`;

const TaskRow = ({ task, isOpen, onToggleOpen }) => (
  <>
    <TableRow>
      <TableCell
        align="left"
        className="strong"
      >
        {task.taskNumber}
      </TableCell>
      <TableCell align="center">{task.status}</TableCell>
      <TableCell align="center">{task.priority}</TableCell>
      <TableCell>{task.title}</TableCell>
      <TableCell align="center">{task.asFoundConditionCode}</TableCell>
      <TableCell
        padding="none"
        align="right"
      >
        <ControlButton onClick={onToggleOpen}>{isOpen ? 'Close' : 'Open'}</ControlButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={6}
      >
        <Collapse
          in={isOpen}
          unmountOnExit
        >
          <Box py={2}>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <CarlaText
                  variant="h3"
                  colour="greys.darkGrey4"
                >
                  Task Instructions
                </CarlaText>
              </Grid>
              <Grid item>
                <LongDescription text={R.propOr('', 'instructions', task)} />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
);

const WorkOrderTaskTable = ({ tasks }) => {
  const rows = tasks.map(task => (
    <TaskRow
      key={task.taskNumber}
      task={task}
    />
  ));

  return (
    <Table
      padding={40}
      tableElement={ExpandableTable}
    >
      <TableHead>
        <TableRow>
          <TableCell align="left">Task</TableCell>
          <TableCell align="center">Status</TableCell>
          <TableCell align="center">Priority</TableCell>
          <TableCell>Title</TableCell>
          <TableCell align="center">As Found Condition Code</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <ExpandableTableBody>{rows}</ExpandableTableBody>
    </Table>
  );
};

export default WorkOrderTaskTable;
