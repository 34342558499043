import { rgba } from 'polished';
import theme from '../../../theme';

const getActionStatusColor = (statusId: number) => {
  switch (statusId) {
    case 1 /** "New" */:
    case 4 /** "Returned" */:
      return rgba(theme.colours.yellows.yellow1, 0.9);
    case 2 /** "In-Progress" */:
      return rgba(theme.colours.blues.blue1, 0.9);
    case 3 /** "Complete" */:
      return rgba(theme.colours.greens.green1, 0.9);
    default:
      return 'white';
  }
};

export { getActionStatusColor };
