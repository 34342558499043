import React from 'react';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import UnstyledButton from '@material-ui/core/Button';
import EventNoteIcon from '@material-ui/icons/EventNote';
import UnstyledProfileImage from './ProfileImage';
import { dateToString, YYYYMMDDHHmmss } from '../../utils/date-format';
import OutlineButton from '../../components/OutlineButton';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { getUserName } from '../../auth/utils';

const ProfileImage = styled(UnstyledProfileImage)``;
const Typography = styled(UnstyledTypography)``;
const Button = styled(UnstyledButton)``;
const Feedback = styled(OutlineButton)`
  margin-right: 3%;
`;

const Container = styled.div`
  padding-right: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${ProfileImage} {
    margin-right: 7px;
  }

  ${Typography} {
    font: ${({ theme }) => theme.fonts.medium12};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
    line-height: 20px;
    text-transform: uppercase;
  }

  .header-name {
    margin-right: 32px;
  }

  .header-date {
    margin-right: 50px;
  }
`;

interface Props {
  className?: string;
  hideIcon?: boolean;
  onIconClick(): void;
}

const Header = ({ className, hideIcon, onIconClick }: Props) => (
  <AuthenticatedTemplate>
    <Container className={className}>
      <Feedback
        variant="outlined"
        color="primary"
        href={`mailto:echo@npxinnovation.ca?subject=ECHO Feedback from Bruce Power User. Ticket: #${YYYYMMDDHHmmss(
          new Date()
        )}`}
      >
        Give Feedback
      </Feedback>
      <ProfileImage />
      <Typography className="header-name">{getUserName()}</Typography>
      <Typography className="header-date">{dateToString(new Date())}</Typography>
      {!hideIcon && (
        <Button onClick={onIconClick}>
          <EventNoteIcon />
        </Button>
      )}
    </Container>
  </AuthenticatedTemplate>
);

export default Header;
