import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Walkdown } from '../../../types/walkdown-types';
import ActionButton from '../../../components/ActionButton';
import useWalkdownReview from './hooks/useWalkdownReview';
import ReportStateChangeCommentDialog from '../../../components/ReportStateChangeCommentDialog';

const Container = styled.div`
  text-align: right;
  padding: 20px;
  border: 1px solid black;

  button {
    margin: 0 10px;
    border-radius: 0;
  }
`;

interface Props {
  walkdown: Walkdown;
}

const ApprovalHeader = ({ walkdown }: Props) => {
  const { approve, reject, refresh } = useWalkdownReview();
  const [approving, setApproving] = useState<Walkdown | null>(null);
  const [rejecting, setRejecting] = useState<Walkdown | null>(null);
  const { push } = useHistory();

  useEffect(() => {
    if (walkdown.state !== 'Submitted') {
      push('/app/approvals/walkdown-review');
    }
  }, [walkdown.state]);

  return (
    <Container>
      <ActionButton
        color="primary"
        onClick={() => setApproving(walkdown)}
      >
        Approve
      </ActionButton>
      <Button
        onClick={() => setRejecting(walkdown)}
        variant="outlined"
      >
        Reject
      </Button>

      {approving && (
        <ReportStateChangeCommentDialog
          title={`Do you approve: ${approving?.elementName || ''} - ${approving?.title} for ${approving?.year || ''}-Q${
            approving?.quarter || ''
          }`}
          commentRequired={false}
          message={false}
          onSave={(comment: string) => {
            approve(approving?.id, comment);
            setApproving(null);
            refresh();
            push('/app/approvals/walkdown-review');
          }}
          onCancel={() => setApproving(null)}
        />
      )}

      {rejecting && (
        <ReportStateChangeCommentDialog
          title={`Do you Reject: ${rejecting?.elementName || ''} - ${rejecting?.title} for ${rejecting?.year || ''}-Q${
            rejecting?.quarter || ''
          }`}
          commentRequired={true}
          message={false}
          onSave={(comment: string) => {
            reject(rejecting?.id, comment);
            setRejecting(null);
            refresh();
            push('/app/approvals/walkdown-review');
          }}
          onCancel={() => setRejecting(null)}
        />
      )}
    </Container>
  );
};

export default ApprovalHeader;
