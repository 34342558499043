import { createBrowserHistory, History } from 'history';
import * as R from 'ramda';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import createRootReducer from './reducer';
import rootSaga from './sagas';

// currently does nothing - left over from early session managment but will likely be requested as a feature
export { loadState, saveState } from './local-storage';

export const history: History = createBrowserHistory();

const logger = createLogger({
  collapsed: true,
});
let store = null as any;
export const getStore = () => store;
export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  store = createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(routerMiddleware(history), sagaMiddleware, logger))
  );
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: R.pathOr('', ['echoConfig', 'instrumentationKey'], window),
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });

  appInsights.loadAppInsights();
  sagaMiddleware.run(rootSaga);

  return {
    store,
    reactPlugin,
    appInsights,
  };
};
