import { Breadcrumbs, Button, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import UnstyledLoadingContainer from '../../../../components/LoadingContainer';
import NoRecords from '../../../../components/NoRecords';
import PowerBiReport from '../../../../components/PowerBiReport';
import AccordionSection from '../components/AccordionSection';
import { WorkRequestScreeningForm } from '../components/WorkRequestScreeningForm';
import { useWorkRequest } from '../hooks/useWorkRequest';
import { useWorkRequestCoding } from '../hooks/useWorkRequestCoding';
import PageTitle from '../../../../components/PageTitle';
import * as R from 'ramda';

const FullHeightPaper = styled(Paper)`
  height: calc(100vh - 100px);
  margin-left: 48px;
  margin-right: 48px;
`;

const LoadingContainer = styled(UnstyledLoadingContainer)`
  height: 100%;
  width: 100%;
  margin-top: 16px;
`;

const BackButton = styled(Button)`
  background-color: ${({ theme }) => theme.colours.purples.purple1};
  color: ${({ theme }) => theme.colours.white};

  margin-bottom: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colours.purples.purple1};
    opacity: 0.8;
  }
`;

const DarkBreadcrumbs = styled(Breadcrumbs)`
  color: inherit;
`;

type WorkRequestScreeningState = {
  workRequestNumber: string;
};

const WorkRequestScreening = () => {
  const { workRequestNumber } = useParams<WorkRequestScreeningState>();
  const { push } = useHistory();
  const { workRequest, loading: loadingWorkRequest } = useWorkRequest(workRequestNumber);
  const { coding, loading: loadingCoding, refetch } = useWorkRequestCoding(workRequestNumber);

  const wrDetailsReportId = R.pathOr('', ['echoConfig', 'cwtWrDetailsReportId'], window);
  const wrDetailsCompCatPageName = R.pathOr('', ['echoConfig', 'cwtWrDetailsCompCatPageName'], window);
  const wrDetailsWoDataPageName = R.pathOr('', ['echoConfig', 'cwtWrDetailsWoDataPageName'], window);
  const wrDetailsJpDataPageName = R.pathOr('', ['echoConfig', 'cwtWrDetailsJpDataPageName'], window);

  const isLoading = loadingWorkRequest || loadingCoding;

  return (
    <FullHeightPaper elevation={0}>
      <BackButton
        variant="contained"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => push('/cwt/wr')}
      >
        Back
      </BackButton>
      <DarkBreadcrumbs separator={<ArrowForwardIcon fontSize="inherit" />}>
        <PageTitle>Work Request Screening Form</PageTitle>
        {!isLoading && workRequest && (
          <PageTitle variantMapping={{ h1: 'h2' }}>
            {coding?.screeningStatus === 'coded' ? 'Coded WRs' : 'WRs Requiring Coding'}
          </PageTitle>
        )}
      </DarkBreadcrumbs>

      <LoadingContainer loading={isLoading}>
        {workRequest ? (
          <>
            <WorkRequestScreeningForm
              workRequest={workRequest}
              coding={coding}
              refetch={refetch}
            />
            <AccordionSection
              title="WR Data"
              content={
                <PowerBiReport
                  title="WR Data"
                  reportId={wrDetailsReportId}
                  pageName="ReportSection"
                  filter={`WR/WR_NUMBER eq '${workRequest.workRequestNumber}' and WR_ATTRIBUTE/TICKETID eq '${workRequest.workRequestNumber}'`}
                  height="50vh"
                />
              }
            />
            {!!workRequest.location && (
              <>
                <AccordionSection
                  title="Component Cat & PEG"
                  content={
                    <PowerBiReport
                      title="Component Category & PEG"
                      reportId={wrDetailsReportId}
                      pageName={wrDetailsCompCatPageName}
                      filter={`Locations/LOCATION eq '${workRequest.location}' and Locations/SITEID eq '${workRequest.facilityId}' and Equipment_Group/LOCATION eq '${workRequest.location}' and Equipment_Group/SITEID eq '${workRequest.facilityId}'`}
                      height="50vh"
                    />
                  }
                />
                <AccordionSection
                  title="Related WOs by Location"
                  content={
                    <PowerBiReport
                      title="Related WOs by Location"
                      reportId={wrDetailsReportId}
                      pageName={wrDetailsWoDataPageName}
                      filter={`Workorder/LOCATION eq '${workRequest.location}' and Workorder/SITEID eq '${workRequest.facilityId}'`}
                      height="50vh"
                    />
                  }
                />
                <AccordionSection
                  title="Related Job Plans by Location"
                  content={
                    <PowerBiReport
                      title="Related Job Plans by Location"
                      reportId={wrDetailsReportId}
                      pageName={wrDetailsJpDataPageName}
                      filter={`Job_x0020_Plans/LOCATION eq '${workRequest.location}' and Job_x0020_Plans/SITEID eq '${workRequest.facilityId}'`}
                      height="50vh"
                    />
                  }
                />
              </>
            )}
          </>
        ) : (
          <NoRecords
            title="Work Request could not be found."
            subtitle="Please go back and try again."
          />
        )}
      </LoadingContainer>
    </FullHeightPaper>
  );
};

export default WorkRequestScreening;
