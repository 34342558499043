import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import useElementIndicators from '../../../../hooks/element-indicators';
import ElementIndicatorList from './ElementIndicatorList';
import IndicatorForm from './ElementIndicatorForm';
import CarlaText from '../../../../components/CarlaText';
import { ManualIndicatorProvider } from '../../../../hooks/manual-indicators-component';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import useConfirm from '../../../../hooks/confirm';
import useElementIndicatorWeightsCheck from '../../hooks/element-indicator-weights-check';

const Container = styled.div``;
const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  className?: string;
}

const ElementIndicators = ({ className }: Props) => {
  const { elementIndicators, onAddIndicator, onUpdateIndicator, onRemoveIndicator } = useElementIndicators() as any;
  const [addingIndicator, setAddingIndicator] = useState(false);
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onRemoveIndicator);
  useElementIndicatorWeightsCheck(elementIndicators);

  const handleAdd = (indicator: any) => {
    onAddIndicator(indicator);
    setAddingIndicator(false);
  };

  const handleUpdate = (indicatorId: number, weight: string) => {
    onUpdateIndicator(indicatorId, weight);
  };

  return (
    <Container>
      <Container>
        {!addingIndicator && (
          <FlexCenter>
            <IconButton
              onClick={() => {
                setAddingIndicator(true);
              }}
            >
              <AddIcon />
            </IconButton>
            <CarlaText>Add Item</CarlaText>
          </FlexCenter>
        )}
        {addingIndicator && (
          <ManualIndicatorProvider>
            <IndicatorForm
              // state={'add'}
              title="Add Indicator"
              onSubmit={handleAdd}
              onCancel={() => {
                setAddingIndicator(false);
              }}
            />
          </ManualIndicatorProvider>
        )}
      </Container>
      <Container className={className}>
        <ElementIndicatorList
          elementIndicators={elementIndicators}
          onEdit={handleUpdate}
          onRemove={confirm}
        />
        {confirming && (
          <OkayCancelDialog
            title="Cancel?"
            okayLabel="Yes"
            cancelLabel="No"
            onOkay={onConfirm}
            onCancel={onCancel}
          >
            Are you sure you want to delete?
          </OkayCancelDialog>
        )}
      </Container>
    </Container>
  );
};

export default ElementIndicators;
