import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import DetailHeader from './DetailHeader';
import HighLevelDetails, { HighLevelDetail } from './HighLevelDetails';
import TaskTitle from './TaskTitle';
import { dateToString } from '../../../../../../utils/date-format';
import useToggle from '../../../../../../hooks/toggle';
import ControlButton from '../../../../../../components/ControlButton';
import CarlaText from '../../../../../../components/CarlaText';

const pmUrl = (number: number) =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=pluspm&uniqueid=${number}`;

interface Props {
  pm: any;
  dense?: boolean;
}

const PmDetails = ({ pm, dense }: Props) => {
  const {
    pmNumber,
    description,
    facilityName,
    unitNumber,
    usi,
    lastCompleteDate,
    nextDate,
    ucr,
    modelWorkOrderNumber,
    frequency,
    frequencyUnit,
  } = pm;
  const { value: showViewMore, onToggle: onToggleViewMore } = useToggle();

  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <Box p={5}>
          <Grid
            container
            direction="column"
            spacing={1}
          >
            <Grid item>
              <DetailHeader>
                <a href={pmUrl(pmNumber)}>{`PM #${pmNumber}`}</a>
              </DetailHeader>
            </Grid>
            <Grid item>
              <HighLevelDetails>
                <HighLevelDetail>{`Facility: ${facilityName}`}</HighLevelDetail>
                <HighLevelDetail>{`Unit: ${unitNumber}`}</HighLevelDetail>
                <HighLevelDetail>{`System (USI): ${usi}`}</HighLevelDetail>
                <HighLevelDetail>{`PM Last Completed Date: ${dateToString(lastCompleteDate)}`}</HighLevelDetail>
                <HighLevelDetail>{`PM Next Date: ${dateToString(nextDate)}`}</HighLevelDetail>
                <HighLevelDetail>{`UCR: ${ucr}`}</HighLevelDetail>
                <HighLevelDetail>{`Model WO Number: ${modelWorkOrderNumber}`}</HighLevelDetail>
                <HighLevelDetail>{`Frequency: ${frequency} ${frequencyUnit}`}</HighLevelDetail>
              </HighLevelDetails>
            </Grid>
            {!dense && (
              <>
                <Grid
                  item
                  container
                  justify="flex-end"
                >
                  <ControlButton onClick={onToggleViewMore}>View {showViewMore ? 'less' : 'more'}</ControlButton>
                </Grid>
                <Grid item>
                  <Collapse
                    in={showViewMore}
                    unmountOnExit
                  >
                    <Grid
                      container
                      direction="column"
                      spacing={1}
                    >
                      <Grid item>
                        <TaskTitle>PM Description</TaskTitle>
                      </Grid>
                      <Grid item>
                        <CarlaText>{description}</CarlaText>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PmDetails;
