const createElementFormConfig = {
  type: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Field cannot be empty!',
      },
    },
  },

  name: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Field cannot be empty!',
      },
    },
  },

  category: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Field cannot be empty!',
      },
    },
  },

  reportingFrequency: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Field cannot be empty!',
      },
    },
  },

  schemaName: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Field cannot be empty!',
      },
    },
  },

  facilityId: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Field cannot be empty!',
      },
    },
  },
};

export default createElementFormConfig;

export const createElementDefaultValues = {
  type: '',
  name: '',
  category: '',
  reportingFrequency: '',
  schemaName: '',
  facilityId: '',
};
