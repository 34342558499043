import React from 'react';
import styled from 'styled-components';
import UnstyledListItem from '@material-ui/core/ListItem';
import UnstyledListItemIcon from '@material-ui/core/ListItemIcon';
import UnstyledListItemText from '@material-ui/core/ListItemText';

const ListItemIcon = styled(UnstyledListItemIcon)``;
const ListItemText = styled(UnstyledListItemText)``;

const ListItem = styled(UnstyledListItem)`
  padding-left: 96px;

  ${ListItemIcon} {
    min-width: 0px;
    margin-right: 18px;
  }

  ${ListItemText} {
    span {
      font: ${({ theme }) => theme.fonts.medium14};
    }
  }
`;

const ActionItem = ({ className, onClick, iconComponent: IconComponent, label }) => (
  <ListItem
    className={className}
    button
    disableGutters
    onClick={onClick}
  >
    {IconComponent && (
      <ListItemIcon>
        <IconComponent />
      </ListItemIcon>
    )}
    <ListItemText>{label}</ListItemText>
  </ListItem>
);

export default ActionItem;
