import React from 'react';
import styled from 'styled-components';

import MuiTooltip from '@material-ui/core/Tooltip';

const Tooltip = styled(MuiTooltip)`
  color: 'black';
  background-color: 'black';
`;

const CustomTooltip = ({ children, ...props }: any) => <Tooltip {...props}>{children}</Tooltip>;

export default CustomTooltip;
