/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';

const CloseButton = (props: any) => (
  <Button {...props}>
    <CancelIcon />
  </Button>
);

export default CloseButton;
