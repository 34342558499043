import React, { useMemo } from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import useElementIndicators from '../../hooks/element-indicators';
import useForm from '../../hooks/form';
import useConditionalConfirm from '../../hooks/conditional-confirm';
import useAction from '../../hooks/action';
import useElementUnits from '../../hooks/element-units';
import DialogButton from '../DialogButton';
import OkayCancelDialog from '../OkayCancelDialog';
import CustomFormLabel from '../CustomFormLabel';
import CustomTextField from '../CustomTextField';
import EchoSelect from '../Select';
import ExpectedOutcomeUnits from './ExpectedOutcomeUnits';

const formConfig = {
  changeType: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select change type',
      },
    },
  },
  indicatorId: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select indicator',
      },
    },
  },
  elementUnit: {
    validate: {
      nonEmptyArray: {
        message: 'Add at least one unit',
      },
    },
  },
  amount: {
    list: {},
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter amount',
      },
    },
  },
};

const initialValues = (expectedOutcome: any) => ({
  changeType: R.propOr('', 'changeType', expectedOutcome),
  indicatorId: R.propOr('', 'indicatorId', expectedOutcome),
  elementUnit: [],
  amount: R.propOr('', 'amount', expectedOutcome),
});

const withProgFormConfig = (_formConfig: any, elementType: string): any => {
  if (elementType !== 'program') return _formConfig;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { amount, changeType, ...updatedFormConfig } = _formConfig;
  return {
    ...updatedFormConfig,
    colourStatus: {
      validate: {
        presence: {
          allowEmpty: false,
          message: 'Choose status',
        },
      },
    },
  };
};

const withProgInitValues = (initValues: any, expectedOutcome: any, elementType: string): any => {
  if (elementType !== 'program') return initValues;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { amount, changeType, ...progInitValues } = initValues;

  return {
    ...progInitValues,
    colourStatus: R.propOr('', 'colourStatus', expectedOutcome),
  };
};

interface Props {
  className?: string;
  expectedOutcome?: any;
  onSubmit(values: any): void;
  onCancel(): void;
}

const changeTypeOptions = [
  { value: 'increase', label: 'Increase' },
  { value: 'decrease', label: 'Decrease' },
];

const programUnitOptions = [
  { value: 'BA', label: 'BA' },
  { value: 'BB', label: 'BB' },
];

const colourOptions = [
  { value: 'green', label: 'Green' },
  { value: 'white', label: 'White' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'red', label: 'Red' },
];

const ExpectedOutcomeForm = ({ className, expectedOutcome, onSubmit, onCancel }: Props) => {
  const { elementIndicators } = useElementIndicators();
  const action = useAction();

  const progFormConfig = withProgFormConfig(formConfig, action.elementType);
  const progInitValues = withProgInitValues(initialValues(expectedOutcome), expectedOutcome, action.elementType);

  const { elementUnits } = useElementUnits(action.elementId);

  // If you have multiple units selected, create an expected outcome for each one
  const formSubmitHandler = (values: any) => {
    values.elementUnit.forEach((u: string) => {
      onSubmit({ ...values, elementUnit: u });
    });
  };

  const { submit, isDirty, propsForField, setField } = useForm(progFormConfig, progInitValues, formSubmitHandler);
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  const indicatorOptions = R.compose<any, any, any, any, any>(
    R.map((reportIndicator: any) => ({ value: reportIndicator.indicatorId, label: reportIndicator.indicatorName })),
    R.when(() => action.elementType === 'program', R.filter<any>(R.propEq('indicatorType', 'manual'))),
    R.when(() => action.elementType !== 'program', R.filter<any>(R.propEq('indicatorType', 'calculated'))),
    R.sortBy(R.prop('indicatorName'))
  )(elementIndicators as any);

  const systemAndComponentUnitOptions = useMemo(
    () =>
      elementUnits.map(elementUnit => ({
        value: elementUnit.elementUnit,
        label: elementUnit.elementUnit,
      })),
    [elementUnits]
  );

  return (
    <Grid
      container
      className={className}
      spacing={0}
    >
      {action.elementType !== 'program' && (
        <Grid
          item
          xs={12}
        >
          <EchoSelect
            label="Change Type"
            fullWidth
            options={changeTypeOptions}
            LabelComponent={CustomFormLabel}
            {...propsForField('changeType')}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <EchoSelect
          label="Indicator"
          fullWidth
          options={indicatorOptions}
          LabelComponent={CustomFormLabel}
          {...propsForField('indicatorId')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ExpectedOutcomeUnits
          CustomFormLabel={CustomFormLabel}
          unitOptions={action.elementType === 'program' ? programUnitOptions : systemAndComponentUnitOptions}
          setField={setField}
          value={propsForField('elementUnit').value}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        {action.elementType !== 'program' ? (
          <CustomTextField
            label="Amount"
            fullWidth
            type="number"
            inputProps={{ min: 1 }}
            {...propsForField('amount')}
          />
        ) : (
          <EchoSelect
            label="Select Colour Status"
            fullWidth
            options={colourOptions}
            LabelComponent={CustomFormLabel}
            {...propsForField('colourStatus')}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <DialogButton
              color="primary"
              onClick={submit}
            >
              Confirm
            </DialogButton>
          </Grid>
          <Grid item>
            <DialogButton onClick={confirmCancel}>Cancel</DialogButton>
          </Grid>
        </Grid>
      </Grid>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Yes"
          cancelLabel="No"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          You have unconfirmed changes. Are you sure you want to cancel?
        </OkayCancelDialog>
      )}
    </Grid>
  );
};

export default ExpectedOutcomeForm;
