export default {
  title: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  type: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },
};
