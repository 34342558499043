import { startCase } from 'lodash';
import { useFormContext } from 'react-hook-form';
import OkayCancelDialog from '../../../../../components/OkayCancelDialog';
import { OutageReference, ReferenceFormInputs } from '../../../../../types/outages';
import { prefixWithAOrAn } from '../../../utils/prefixWithAOrAn';
import { REFERENCE_TYPE_OPTIONS } from '../../../constants';

interface ConfirmEditDialogProps extends Pick<React.ComponentProps<typeof OkayCancelDialog>, 'onCancel' | 'onOkay'> {
  existingReference: OutageReference;
}

const ConfirmEditDialog: React.FC<ConfirmEditDialogProps> = ({ onCancel, onOkay, existingReference }) => {
  const { getValues } = useFormContext<ReferenceFormInputs>();

  const oldActionTypeLabel = startCase(existingReference?.action_type);
  const newActionTypeLabel = startCase(getValues().actionType || '');
  const referenceTypeDisplay = REFERENCE_TYPE_OPTIONS.find(
    option => option.value === existingReference?.reference_type
  )?.label;
  const warningText = `If you choose to continue, ${referenceTypeDisplay} ${
    existingReference?.reference_number
  } will be changed from ${prefixWithAOrAn(oldActionTypeLabel)} action to ${prefixWithAOrAn(
    newActionTypeLabel
  )} action for Outage ${existingReference?.outage_code}.`;
  return (
    <OkayCancelDialog
      title="Continue with saving?"
      okayLabel="Continue"
      cancelLabel="Cancel"
      onOkay={onOkay}
      onCancel={onCancel}
    >
      {warningText}
    </OkayCancelDialog>
  );
};

export default ConfirmEditDialog;
