import styled from 'styled-components';
import FilterButton from '../../../../components/FilterButton';
import { TabState } from '../types';

const TAB_TITLES: Record<TabState, string> = {
  requiringCoding: 'WRs Requiring Coding',
  coded: 'Coded WRs',
};

const FilterButtonContainer = styled.div`
  button + button {
    margin-left: 16px;
  }
`;

interface FilterButtonsProps {
  currentTab: TabState | undefined;
  onChangeTab: (tabState: TabState) => void;
}

const FilterButtons: React.FC<FilterButtonsProps> = ({ currentTab, onChangeTab }) => {
  return (
    <FilterButtonContainer role="tablist">
      {Object.entries(TAB_TITLES).map(([tabState, title]) => {
        const isSelected = currentTab === tabState;
        return (
          <FilterButton
            key={tabState}
            role="tab"
            aria-selected={isSelected}
            isActive={isSelected}
            onClick={() => onChangeTab(tabState as TabState)}
          >
            {title}
          </FilterButton>
        );
      })}
    </FilterButtonContainer>
  );
};

export default FilterButtons;
