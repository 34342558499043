import React from 'react';
import { Grid } from '@material-ui/core';
import AttachmentList from './Attachment';
import AddAttachment from './AddAttachment';
import useAddAttachment from '../hooks/useAddAttachment';
import useAttachmentDownload from '../hooks/useAttachmentDownloader';
import useReadOnly from '../../../../../hooks/read-only';

interface Props {
  walkdownId: number;
  taskId: number;
  showAttachment?: any;
  onAddAttachment?: any;
}

const TaskAttachmentsBox = ({ walkdownId, taskId, showAttachment, onAddAttachment }: Props) => {
  const { addAttachment, attachment, deleteAttachment } = useAddAttachment(walkdownId, taskId);

  const { downloadAttachment } = useAttachmentDownload(walkdownId, attachment, taskId);

  const onDelete = () => {
    deleteAttachment(taskId);
  };

  const onDownload = () => {
    downloadAttachment();
  };

  const readOnly = useReadOnly();

  return (
    <Grid container>
      <Grid item>
        <AttachmentList
          attachment={showAttachment || attachment}
          onDownload={onDownload}
          onDelete={onDelete}
        />
      </Grid>
      <Grid container>
        {!readOnly && (
          <AddAttachment
            attachment={showAttachment || attachment}
            onAddAttachment={onAddAttachment || addAttachment}
            taskId={taskId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TaskAttachmentsBox;
