import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as facilitiesActions from './actions';

function* loadFacilities() {
  try {
    const facilities = yield call(Api.getAllFacilities);
    yield put(facilitiesActions.loadFacilitiesSuccess(facilities));
  } catch (e) {
    yield put(facilitiesActions.loadFacilitiesFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.ADMIN_LOAD_FACILITIES_REQUEST, loadFacilities);
}

export default root;
