import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Select from '../../../../components/Select';
import UnstyledFormDialog from '../../../../components/FormDialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const CustomSelectField = styled(Select)``;

const FormDialog = styled(UnstyledFormDialog)`
  ${CustomSelectField} {
    width: 500px;
  }
`;

type Props = {
  users: { label: string; value: string }[];
  onSubmit: (userIds: string[]) => void;
  onClose: () => void;
  title: string;
  multiple: boolean;
  defaultValue?: { label: string; value: string } | null;
  loading?: boolean;
};

const UserRiskRolesDialog = ({ title, onSubmit, onClose, users, multiple, defaultValue, loading }: Props) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  return (
    <FormDialog
      title={title}
      onSave={() => onSubmit(selectedUserIds)}
      onCancel={onClose}
      disabled={loading}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          Choose users
        </Grid>
        <Grid
          item
          xs={12}
        >
          {multiple ? (
            <Autocomplete
              multiple={true}
              options={users}
              getOptionLabel={option => option.label}
              onChange={(_, selected) => {
                setSelectedUserIds(selected.map(s => s.value));
              }}
              renderInput={params => <TextField {...params} />}
            />
          ) : (
            <Autocomplete
              multiple={false}
              options={users}
              getOptionLabel={option => option.label}
              onChange={(_, selected) => {
                selected && setSelectedUserIds([selected.value]);
              }}
              renderInput={params => <TextField {...params} />}
              defaultValue={defaultValue}
            />
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default UserRiskRolesDialog;
