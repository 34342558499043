import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import CreateTemplate from './CreateTemplate';
import CloneTemplate from './clone';
import TemplatesList from './TemplatesList';

const Templates = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/clone-template`}
        component={CloneTemplate}
      />
      <Route
        path={`${path}/create`}
        component={CreateTemplate}
        exact
      />
      <Route
        path={`${path}`}
        component={TemplatesList}
      />
    </Switch>
  );
};

export default Templates;
