import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as dashboardActions from '../../../state/dashboards/actions';

const useEditDashboard = dashboards => {
  const dispatch = useDispatch();
  const [editingDashboard, setEditingDashboard] = useState(null);

  const editDashboardId = useCallback(
    dashboardId => {
      const dashboard = R.find(R.propEq('id', dashboardId), dashboards);
      setEditingDashboard(dashboard);
    },
    [setEditingDashboard, dashboards]
  );

  const cancelEditDashboard = useCallback(() => {
    setEditingDashboard(null);
  }, [setEditingDashboard]);

  const updateDashboard = useCallback(
    dashboard => {
      dispatch(dashboardActions.updateDashboardRequest(dashboard));
      setEditingDashboard(null);
    },
    [dispatch, setEditingDashboard]
  );

  return {
    editingDashboard,
    editDashboardId,
    cancelEditDashboard,
    updateDashboard,
  };
};

export default useEditDashboard;
