import DownloadButton from '../../../../components/DownloadButton';
import { Tooltip } from '@material-ui/core';
import { useChangeTrackingValue } from '../../hooks/useChangeTracking';

type ExportChangeTrackingButtonProps = Pick<useChangeTrackingValue, 'exportCSV'>;

const ExportChangeTrackingButton = ({ exportCSV }: ExportChangeTrackingButtonProps) => {
  return (
    <Tooltip
      title="Export CSV"
      placement="top"
    >
      <DownloadButton onClick={exportCSV}></DownloadButton>
    </Tooltip>
  );
};
export default ExportChangeTrackingButton;
