import { Path, Remote } from '../../types';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import { buildFilterObject } from '../../../utils/build-filter-object';

const getTasks = (path: Path, remote: Remote) => (page?: number, pageSize?: number, filterValue?: TypeFilterValue) => {
  const filtersObject = buildFilterObject(filterValue);
  return remote.get(path(`/`), { params: { ...filtersObject, page, pageSize } });
};

const getByWonumTasks = (path: Path, remote: Remote) => (wonum: string | number) => remote.get(path(`/${wonum}`));

const task = (path: Path, remote: Remote) => ({
  getTasks: getTasks(path, remote),
  getByWonumTasks: getByWonumTasks(path, remote),
});

export default task;
