import styled from 'styled-components';
import Table from '@material-ui/core/Table';

const ExpandableTable = styled(Table)`
  .MuiTableBody-root {
    .MuiTableRow-root:nth-child(odd) {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
    .MuiTableRow-root:nth-child(4n-3) {
      background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
    }

    .MuiTableRow-root:nth-child(4n-2) {
      background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
    }
  }
`;

export default ExpandableTable;
