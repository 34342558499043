import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as elementActions from './actions';

function* loadAllElements() {
  try {
    const elements = yield call(Api.getAllElements);
    yield put(elementActions.loadAllSuccess(elements));
  } catch (e) {
    yield put(elementActions.loadAllFailure(e));
  }
}

function* loadBackupRses(action) {
  const { elementId } = action.payload;
  try {
    const backupRses = yield call(Api.getBackupRses, elementId);
    yield put(elementActions.loadBackupRsesSuccess(elementId, backupRses));
  } catch (e) {
    yield put(elementActions.loadBackupRsesFailure(e, { elementId }));
  }
}

function* loadElementUnits(action) {
  try {
    const { elementId } = action.payload;
    const elementUnits = yield call(Api.getElementUnits, elementId);
    yield put(elementActions.loadElementUnitsSuccess(elementId, elementUnits));
  } catch (e) {
    yield put(elementActions.loadElementUnitsFailure(e));
  }
}

function* setPrimaryRse(action) {
  try {
    const { elementId, userId, name } = action.payload;
    yield call(Api.setPrimaryRse, elementId, userId);
    yield put(elementActions.setPrimaryRseSuccess(elementId, userId, name));
  } catch (e) {
    yield put(elementActions.setPrimaryRseFailure(e));
  }
}

function* clearPrimaryRse(action) {
  try {
    const { elementId, userId } = action.payload;
    yield call(Api.clearPrimaryRse, elementId, userId);
    yield put(elementActions.clearPrimaryRseSuccess(elementId));
  } catch (e) {
    yield put(elementActions.clearPrimaryRseFailure(e));
  }
}

function* setBackupRse(action) {
  try {
    const { elementId, userId, name } = action.payload;
    yield call(Api.setBackupRse, elementId, userId);
    yield put(elementActions.setBackupRseSuccess(elementId, userId, name));
  } catch (e) {
    yield put(elementActions.setBackupRseFailure(e));
  }
}

function* patchElement(action) {
  try {
    const {
      elementId,
      reportingFrequency,
      category,
      tier,
      state,
      referenceReportingYear,
      referenceReportingQuarter,
      pmpRequired,
      sis,
      safetyRelatedSystemClassification,
      pmpDate,
    } = action.payload;
    const elementUpdates = yield call(Api.patchElement, elementId, {
      reportingFrequency,
      category,
      state,
      tier,
      referenceReportingYear,
      referenceReportingQuarter,
      pmpRequired,
      sis,
      safetyRelatedSystemClassification,
      pmpDate,
    });

    yield put(
      elementActions.patchElementSuccess(elementId, {
        state: elementUpdates.state,
        tier: elementUpdates.tier,
        referenceReportingQuarter: elementUpdates.referenceReportingQuarter,
        referenceReportingYear: elementUpdates.referenceReportingYear,
        reportingFrequency: elementUpdates.reportingFrequency,
        category: elementUpdates.category,
        pmpRequired: elementUpdates.pmpRequired,
        sis: elementUpdates.sis,
        safetyRelatedSystemClassification: elementUpdates.safetyRelatedSystemClassification,
        pmpDate: elementUpdates.pmpDate,
      })
    );
  } catch (e) {
    yield put(elementActions.patchElementFailure(e));
  }
}

function* clearBackupRse(action) {
  try {
    const { elementId, userId } = action.payload;
    yield call(Api.clearBackupRse, elementId, userId);
    yield put(elementActions.clearBackupRseSuccess(elementId, userId));
  } catch (e) {
    yield put(elementActions.clearBackupRseFailure(e));
  }
}

function* loadUnitWeights(action) {
  const { elementId } = action.payload;
  try {
    const unitWeights = yield call(Api.getUnitWeights, elementId);
    yield put(elementActions.loadUnitWeightsSuccess(elementId, unitWeights));
  } catch (e) {
    yield put(elementActions.loadUnitWeightsFailure(e, { elementId }));
  }
}

function* loadElement(action) {
  const { elementId } = action.payload;
  try {
    const element = yield call(Api.getElement, elementId);
    yield put(elementActions.loadSuccess(elementId, element));
  } catch (e) {
    yield put(elementActions.loadFailure(e, { elementId }));
  }
}

function* setOverrideReportDueDate(action) {
  try {
    const { elementId, date } = action.payload;
    yield call(Api.setOverrideReportDueDate, elementId, date);
    yield put(elementActions.setOverrideReportDueDateSuccess(elementId, date));
  } catch (e) {
    yield put(elementActions.setOverrideReportDueDateFailure(e));
  }
}

function* loadCategories() {
  try {
    const categories = yield call(Api.getElementCategoryOptions);
    yield put(elementActions.loadCategoriesSuccess(categories));
  } catch (e) {
    yield put(elementActions.loadCategoriesFailure(e));
  }
}

function* loadFrequencies() {
  try {
    const freqs = yield call(Api.getReportingFrequencyOptions);
    yield put(elementActions.loadFrequenciesSuccess(freqs));
  } catch (e) {
    yield put(elementActions.loadFrequenciesFailure(e));
  }
}

function* loadFacilities() {
  try {
    const freqs = yield call(Api.getFacilityOptions);
    yield put(elementActions.loadFacilitiesSuccess(freqs));
  } catch (e) {
    yield put(elementActions.loadFacilitiesFailure(e));
  }
}

function* loadSchemas() {
  try {
    const freqs = yield call(Api.getElementSchemas);
    yield put(elementActions.loadSchemasSuccess(freqs));
  } catch (e) {
    yield put(elementActions.loadSchemasFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.ELEMENTS_LOAD_ALL_REQUEST, loadAllElements);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_BACKUP_RSES_REQUEST, loadBackupRses);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_ELEMENT_UNITS_REQUEST, loadElementUnits);
  yield takeEvery(actionTypes.SET_OVERRIDE_REPORT_DUE_DATE_REQUEST, setOverrideReportDueDate);
  yield takeEvery(actionTypes.ELEMENTS_SET_PRIMARY_RSE_REQUEST, setPrimaryRse);
  yield takeEvery(actionTypes.ELEMENTS_CLEAR_PRIMARY_RSE_REQUEST, clearPrimaryRse);
  yield takeEvery(actionTypes.ELEMENTS_SET_BACKUP_RSE_REQUEST, setBackupRse);
  yield takeEvery(actionTypes.ELEMENTS_CLEAR_BACKUP_RSE_REQUEST, clearBackupRse);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_REQUEST, loadUnitWeights);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_REQUEST, loadElement);
  yield takeEvery(actionTypes.ELEMENTS_PATCH_REQUEST, patchElement);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_CATEGORIES_REQUEST, loadCategories);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_FREQUENCIES_REQUEST, loadFrequencies);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_FACILITIES_REQUEST, loadFacilities);
  yield takeEvery(actionTypes.ELEMENTS_LOAD_SCHEMAS_REQUEST, loadSchemas);
}

export default root;
