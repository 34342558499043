import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as reportActions from '../state/reports/actions';

const useApproveReport = reports => {
  const dispatch = useDispatch();
  const [approvingReport, setApprovingReport] = useState(null);

  const confirmApproveReport = useCallback(
    reportId => {
      const report = R.find(R.propEq('id', reportId), reports);
      setApprovingReport(report);
    },
    [reports, setApprovingReport]
  );

  const cancelApproveReport = useCallback(() => {
    setApprovingReport(null);
  }, [setApprovingReport]);

  const approveReport = useCallback(
    comment => {
      dispatch(reportActions.approveRequest(approvingReport, comment));
      setApprovingReport(null);
    },
    [dispatch, approvingReport]
  );

  return {
    approvingReport,
    confirmApproveReport,
    cancelApproveReport,
    approveReport,
  };
};

export default useApproveReport;
