import React, { useState } from 'react';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import ChevronIcon from '../../components/ChevronIcon';
import UnstyledNavButton from './NavButton';
import SubMenu from './SubMenu';

const NavButton = styled(UnstyledNavButton)`
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.colours.purples.purple2 : 'transparent')};
`;

const Container = styled.div`
  position: relative;

  ${ChevronIcon} {
    position: absolute;
    top: 14px;
    left: 37px;
  }

  ${NavButton}.active {
    background-color: ${({ theme }) => theme.colours.purples.purple2};
  }
`;

const SubMenuItem = ({ className, to, iconComponent, label, entries, isSelected }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClickChevron = () => {
    setOpen(!isOpen);
  };

  const handleClickNavButton = () => {
    setOpen(true);
  };

  return (
    <Container className={className}>
      <NavButton
        to={to}
        onClick={handleClickNavButton}
        iconComponent={iconComponent}
        isSelected={isSelected}
        label={label}
      />
      <Collapse in={isOpen}>
        <SubMenu entries={entries} />
      </Collapse>
      <ChevronIcon
        open={isOpen}
        onClick={handleClickChevron}
      />
    </Container>
  );
};

export default SubMenuItem;
