import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Control, FieldErrors, UseFormSetValue, useForm } from 'react-hook-form';
import { NoteInput, Note } from '../../../types/cwt/workorder';
import { useWorkOrders } from './useWorkOrders';
interface useNotesValue {
  notes: Note[];
  loading: boolean;
  //notes form
  control: Control<NoteInput>;
  errors: FieldErrors<NoteInput>;
  reset: () => void;
  setValue: UseFormSetValue<NoteInput>;
  handleAddNotes: () => Promise<void>;
  openAddNotesDialog: boolean;
  handleOpenAddNotesDialog: () => void;
  handleCloseAddNotesDialog: () => void;
}

interface Props {
  children: ReactNode;
}
const NotesContext = createContext<useNotesValue | undefined>(undefined);

export const NotesContextProvider = ({ children }: Props) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openAddNotesDialog, setOpenAddNotesDialog] = useState<boolean>(false);
  const { selectedWorkorderId } = useWorkOrders();
  const {
    control,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<NoteInput>();
  const handleOpenAddNotesDialog = () => {
    setOpenAddNotesDialog(true);
  };
  const handleCloseAddNotesDialog = () => {
    setOpenAddNotesDialog(false);
    reset();
  };

  useEffect(() => {
    setLoading(true);
  }, [selectedWorkorderId]);

  useEffect(() => {
    const getNotes = async () => {
      if (!selectedWorkorderId) return;
      const res = await Api.cwt.getWorkorderNotes(selectedWorkorderId);
      setNotes(res);
      setLoading(false);
    };

    if (loading) {
      void getNotes();
    }
  }, [loading]);

  const onAddNotes = async (data: NoteInput) => {
    if (selectedWorkorderId) {
      try {
        await Api.cwt.createWorkorderNote({ ...data, workorderId: selectedWorkorderId });
        setLoading(true);
        handleCloseAddNotesDialog();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <NotesContext.Provider
      value={{
        notes,
        loading,
        control,
        errors,
        reset,
        setValue,
        openAddNotesDialog,
        handleOpenAddNotesDialog,
        handleCloseAddNotesDialog,
        handleAddNotes: handleSubmit(onAddNotes),
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export const useNotes = () => {
  const context = useContext(NotesContext);
  if (context === undefined) {
    throw new Error('useNotes must be used within a NotesContextProvider');
  }
  return context;
};
