import React, { useCallback } from 'react';
import styled from 'styled-components';
import UnstyledList from '@material-ui/core/List';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiTextField from '@material-ui/core/TextField';
import UnstyledReportItem from './ReportItem';
import useHistoricalReports from '../../hooks/historical-reports';
import HistoricalReportItem from './HistoricalReportItem';
import UnstyledSelect from '../../../../components/Select';
import useDownloader from '../../../../hooks/downloader';
import Conditional from '../../../../components/Conditional';
import { Grid } from '@material-ui/core';

const ReportItem = styled(UnstyledReportItem)``;

const List = styled(UnstyledList)`
  ${ReportItem}:nth-child(2n - 1) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

const FilterControls = styled.div`
  padding: 10px 30px;
  display: flex;
`;

const Select = styled(UnstyledSelect)`
  width: 200px;
`;

const FilterSection = styled.div`
  padding-right: 30px;
`;

interface Props {
  filters: any;
  setFilters: any;
}

const ELEMENT_TYPE_OPTIONS = [
  { value: 'system', label: 'System' },
  { value: 'component', label: 'Component' },
  { value: 'program', label: 'Program' },
  { value: '', label: 'All' },
];
const FACILITY_OPTIONS = [
  { value: 'ba', label: 'BA' },
  { value: 'bb', label: 'BB' },
  { value: '', label: 'All' },
];
const QUARTER_OPTIONS = [
  { label: '-', value: '' },
  { value: '1', label: 'Q1' },
  { value: '2', label: 'Q2' },
  { value: '3', label: 'Q3' },
  { value: '4', label: 'Q4' },
];
const YEAR_OPTIONS: any[] = [{ label: '-', value: '' }];

const START_YEAR = 2009;
const END_YEAR = 2021;

// generate options for each year between a start and end year
for (let i = START_YEAR; i <= END_YEAR; i += 1) {
  YEAR_OPTIONS.push({
    label: `${i}`,
    value: `${i}`,
  });
}

const HistoricalReportList = ({ filters, setFilters }: Props) => {
  const { reports, elementNames, download, isLoading } = useHistoricalReports();

  const changeHandler = (e: any) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const { onDownload } = useDownloader();

  const downloadHandler = useCallback(async (report: any) => {
    const blob = await download(report.id);
    onDownload(blob, report.pdfLocation);
  }, []);

  return (
    <div>
      <List>
        <FilterControls>
          <Grid
            container
            xs={12}
            spacing={1}
          >
            <FilterSection>
              <Grid
                item
                sm={6}
                xs={12}
              >
                <div>Element Type</div>
                <div>
                  <Select
                    options={ELEMENT_TYPE_OPTIONS}
                    value={filters.elementType}
                    onChange={changeHandler}
                    name="elementType"
                  />
                </div>
              </Grid>
            </FilterSection>

            <FilterSection>
              <Grid
                item
                xs={12}
              >
                <div>Element Name (Required)</div>
                <div>
                  <Autocomplete
                    options={elementNames}
                    getOptionLabel={(p: any) => `${p.label || ''}`}
                    onChange={(_, option: any) => {
                      changeHandler({ target: { value: option?.value || '', name: 'elementName' } });
                    }}
                    renderInput={params => (
                      <MuiTextField
                        name="elementName"
                        {...params}
                        style={{ marginTop: '16px' }}
                        fullWidth
                      />
                    )}
                  />
                </div>
              </Grid>
            </FilterSection>

            <FilterSection>
              <Grid
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
              >
                <div>Facility</div>
                <div>
                  <Select
                    options={FACILITY_OPTIONS}
                    value={filters.facilityId}
                    onChange={changeHandler}
                    name={'facilityId'}
                  />
                </div>
              </Grid>
            </FilterSection>

            <FilterSection>
              <Grid
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
              >
                <div>Year</div>
                <div>
                  <Select
                    options={YEAR_OPTIONS}
                    value={filters.year}
                    onChange={changeHandler}
                    name={'year'}
                  />
                </div>
              </Grid>
            </FilterSection>

            <FilterSection>
              <Grid
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
              >
                <div>Quarter</div>
                <div>
                  <Select
                    options={QUARTER_OPTIONS}
                    value={filters.quarter}
                    onChange={changeHandler}
                    name={'quarter'}
                  />
                </div>
              </Grid>
            </FilterSection>
          </Grid>
        </FilterControls>
        <Conditional
          condition={filters.elementName}
          fallback={
            <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>Please specify an element name!</div>
          }
        >
          <Conditional condition={!isLoading}>
            {reports.length === 0 && (
              <div style={{ textAlign: 'center', padding: '20px', color: 'grey' }}>No records found!</div>
            )}
            {reports.map((report: any) => (
              <HistoricalReportItem
                key={report.id}
                report={report}
                onDownload={downloadHandler}
              />
            ))}
          </Conditional>
        </Conditional>
      </List>
    </div>
  );
};

export default HistoricalReportList;
