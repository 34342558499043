import OkayCancelDialog from '../../../../../components/OkayCancelDialog';

const ConfirmDeleteDialog: React.FC<Pick<React.ComponentProps<typeof OkayCancelDialog>, 'onCancel' | 'onOkay'>> = ({
  onCancel,
  onOkay,
}) => {
  return (
    <OkayCancelDialog
      title="Continue with deleting?"
      okayLabel="Delete"
      cancelLabel="Cancel"
      onOkay={onOkay}
      onCancel={onCancel}
    >
      Are you sure you wish to delete this reference? It will be permanently removed.
    </OkayCancelDialog>
  );
};

export default ConfirmDeleteDialog;
