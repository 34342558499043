import * as R from 'ramda';

export const getUserAssignments = (state: any, userId: any) =>
  R.pathOr([], ['userAssignments', userId, 'records'], state.pages.admin);
export const getElements = (state: any) => state.pages.admin.elements;

export const getFrequencyOptions = (s: any) => s.elements.reportingFrequencies;
export const getCategoryOptions = (s: any) => s.elements.categories;
export const getSchemaOptions = (s: any) => s.elements.schemas;
export const getFacilityOptions = (s: any) => s.elements.facilities;
