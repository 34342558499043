import React from 'react';
import styled from 'styled-components';
import UnstyledGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UnstyledPage from '../../../../components/Page';
import Colours from './Colours';
import Shadows from './Shadows';
import Borders from './Borders';
import Buttons from './Buttons';
import Fonts from './Fonts';

const Grid = styled(UnstyledGrid)``;

const Page = styled(UnstyledPage)`
  ${Grid} {
    > .MuiGrid-item {
      margin-bottom: 30px;
    }
  }
`;

const DesignSystemPage = () => (
  <Page title="Design System">
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Typography variant="h3">Colours</Typography>
        <Colours />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="h3">Shadows</Typography>
        <Shadows />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="h3">Borders</Typography>
        <Borders />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="h3">Buttons</Typography>
        <Buttons />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="h3">Fonts</Typography>
        <Fonts />
      </Grid>
    </Grid>
  </Page>
);

export default DesignSystemPage;
