import { Box } from '@material-ui/core';
import ActionButton from '../../../../../components/ActionButton';
import NotesTable from './components/NotesTable';
import AddWorkorderNote from '../../forms/AddWorkorderNote';
import FormDialog from '../../../../../components/FormDialog';
import { useNotes } from '../../../hooks/useNotes';
import styled from 'styled-components';
const NotesTab = () => {
  const { openAddNotesDialog, handleOpenAddNotesDialog, handleCloseAddNotesDialog, handleAddNotes } = useNotes();
  const AddNoteButton = styled(ActionButton)`
    margin-bottom: 10px;
  `;
  return (
    <Box>
      <AddNoteButton
        color="primary"
        onClick={handleOpenAddNotesDialog}
      >
        Add Note
      </AddNoteButton>
      <NotesTable />
      {openAddNotesDialog && (
        <FormDialog
          title={'Add Note'}
          onCancel={handleCloseAddNotesDialog}
          onSave={handleAddNotes}
        >
          <AddWorkorderNote />
        </FormDialog>
      )}
    </Box>
  );
};

export default NotesTab;
