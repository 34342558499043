import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'redux';
import * as myActions from '../state/my/actions';
import * as mySelectors from '../state/my/selectors';
import { MyElement } from '../types/my';

const useMyAssignments = (load = true) => {
  const dispatch = useDispatch();

  const loadMyAssignments = useCallback(() => {
    dispatch(myActions.loadAssignmentsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (load) {
      loadMyAssignments();
    }
  }, [load, loadMyAssignments]);

  const myAssignments = useSelector<Store, MyElement[]>(mySelectors.getAssignments);

  return {
    myAssignments,
    loadMyAssignments,
  };
};

export default useMyAssignments;
