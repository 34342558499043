import { useState } from 'react';

interface Props {
  onApplicable: (id: string, options: any) => void;
  onDismiss?: ((id: string, options: any) => void) | null | undefined;
  setOpen: (open: boolean) => void;
  noPromptApply: (id: string) => void;
  data: any;
  indicatorRecordId: (data: any) => string;
  setSelectedRecordInapplicable: (options: any) => void;
  setSelectedRecordApplicable: (options: any) => void;
}

export const useDetailModal = ({
  onApplicable,
  onDismiss,
  setOpen,
  noPromptApply,
  data,
  indicatorRecordId,
  setSelectedRecordInapplicable,
  setSelectedRecordApplicable,
}: Props) => {
  const [totalState, setTotalState] = useState({
    applyDialogOpen: false,
    hasComment: false,
    auxRecordType: null,
    activated: false,
  });

  const handleApply = ({ onlyComments, recordType, isActivated, comment }: any) => {
    // updating comments when in active | moving to applied section with comments
    if (isActivated && onlyComments) {
      setTotalState(previousState => {
        return { ...previousState, applyDialogOpen: true, activated: true };
      });
      onApplicable(indicatorRecordId(data), { description: comment });
    } else {
      setTotalState(previousState => {
        return { ...previousState, activated: isActivated };
      });
      if (!recordType) {
        // updating comment when not active
        if (onlyComments) {
          setTotalState(previousState => {
            return { ...previousState, applyDialogOpen: true };
          });
          // moving to applied section*
        } else {
          noPromptApply(indicatorRecordId(data));
          setOpen(false);
        }
        // updating comments when not active | moving to not applied section with comments
      } else {
        if (onDismiss) {
          onDismiss(indicatorRecordId(data), { description: comment });
        }
        setTotalState(previousState => {
          return { ...previousState, applyDialogOpen: true };
        });
      }
    }
    setTotalState(previousState => {
      return { ...previousState, auxRecordType: recordType, hasComment: onlyComments };
    });
  };

  const handleSave = (options: any) => {
    // setSelectedRecord
    if (totalState.hasComment) {
      // updating comment when active
      if (totalState.activated) {
        onApplicable(indicatorRecordId(data), options);
        // updating comment when not active
      } else {
        setSelectedRecordInapplicable(options);
      }
      // moving to applied section*
    } else {
      setSelectedRecordApplicable({ ...options, recordType: totalState.auxRecordType });
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setTotalState(previousState => {
      return { ...previousState, applyDialogOpen: false };
    });
  };

  return {
    totalState,
    handleApply,
    handleSave,
    handleCancel,
  };
};
