import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as reportAttachmentActions from '../state/element-attachments/actions';

const useAddReportAttachment = (elementId, year, quarter) => {
  const dispatch = useDispatch();

  const addAttachment = useCallback(
    (fileType, file) => {
      dispatch(reportAttachmentActions.addRequest(elementId, year, quarter, fileType, file));
    },
    [dispatch, elementId, year, quarter]
  );

  return {
    addAttachment,
  };
};

export default useAddReportAttachment;
