export const defaultValues = {
  title: '',
  description: '',
  inputType: '',
  isMeasurement: false,
  max: '',
  min: '',
};

export const formConfig = {
  title: {
    validate: {
      length: {
        maximum: 200,
        wrongLength: 'Length between 5 and 200 char',
      },
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  description: {
    validate: {
      length: {
        maximum: 200,
        wrongLength: 'Length between 5 and 200 char',
      },
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  inputType: {},

  isMeasurement: {},

  max: {},

  min: {},
};
