import React from 'react';
import styled from 'styled-components';
import useUpdateMCRExemption from './hooks/useUpdateMCRExemption';
import AddExemptElements from './AddExemptElements';
import ExemptElement from './ExemptElement';

const ListItem = styled.li`
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => theme.borders.thin.solid4};
`;

const List = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;

  ${ListItem} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const ExemptElementsList = () => {
  const { elements } = useUpdateMCRExemption();
  const exemptElements = elements.filter((element: any) => element.isMcrExempt === true);
  const items =
    exemptElements.length > 0
      ? exemptElements.map((element: any) => (
          <ListItem key={element.id}>
            <ExemptElement element={element} />
          </ListItem>
        ))
      : [];

  return (
    <div>
      <List>{items}</List>
      <AddExemptElements />
    </div>
  );
};

export default ExemptElementsList;
