import useAdminCategories from '../../../hooks/admin_categories';
import useAction from '../../../hooks/action';
import { useEffect, useState } from 'react';
import { MaximoReference } from '../../../types/actions';

const useActionForm = (values: any) => {
  const [maxDate, setMaxDate] = useState('');
  const action = useAction();
  const { categories } = useAdminCategories();

  const getLatestDate = (dates: any) => {
    const dateObjects = dates.map((date: any) => new Date(date));
    const latestDate = new Date(Math.max.apply(null, dateObjects));
    return latestDate.toDateString();
  };
  useEffect(() => {
    const scheduledCompletionDates =
      values &&
      values.references
        .map((ref: MaximoReference) => (ref.maximoTARGCOMPDATE ? ref.maximoTARGCOMPDATE : ref.maximoTARGETFINISH))
        .filter((date: any) => date !== undefined);
    if (scheduledCompletionDates && scheduledCompletionDates.length > 0) {
      setMaxDate(getLatestDate(scheduledCompletionDates));
      return;
    }
    setMaxDate('');
  }, [values]);

  const getOptionCategories = () => {
    const categoryArray = [{ id: 0, value: null, label: 'None' }];
    categories.forEach((category: any) => {
      categoryArray.push({ id: category.categoryId, value: category.categoryName, label: category.categoryName });
    });
    return categoryArray;
  };

  const referencesActions = action.references;
  const optionCategories = getOptionCategories();

  return {
    referencesActions,
    optionCategories,
    maxDate,
  };
};

export default useActionForm;
