import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import WorkRequestDetails from './WorkRequestDetails';
import WorkOrderDetails from './WorkOrderDetails';

const DividedGrid = styled(Grid)`
  > .MuiGrid-item {
    border-bottom: ${({ theme }) => theme.borders.medium.solid2};
  }

  > .MuiGrid-item:last-child {
    border-bottom: none;
  }
`;

interface Props {
  className?: string;
  recordDetails: any;
  dense?: boolean;
}

const EquipmentDefectDetails = ({ className, recordDetails, dense = false }: Props) => (
  <DividedGrid
    container
    direction="column"
    className={className}
  >
    {recordDetails.workRequest && (
      <Grid item>
        <Box p={5}>
          <WorkRequestDetails
            workRequest={recordDetails.workRequest}
            dense={dense}
          />
        </Box>
      </Grid>
    )}
    {recordDetails.workOrder && (
      <Grid item>
        <WorkOrderDetails
          workOrder={recordDetails.workOrder}
          dense={dense}
        />
      </Grid>
    )}
  </DividedGrid>
);

export default EquipmentDefectDetails;
