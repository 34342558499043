import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledFormControl from '@material-ui/core/FormControl';
import UnstyledInputLabel from '@material-ui/core/InputLabel';
import UnstyledSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledInput = styled(InputBase)`
  .MuiInputBase-input {
    position: relative;
    padding-left: 16px;
    padding-right: 32px;
    font-family: Roboto;
    &:focus: {
      border: none;
    }
  }
`;

const InputLabel = styled(UnstyledInputLabel)`
  text-transform: uppercase;
  top: calc(-50% + 1px);
  left: 16px;
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme }) => theme.colours.greys.darkGrey3};

  &.Mui-focused {
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
  }
`;

const FormControl = styled(UnstyledFormControl)`
  min-width: 120px;
  background-color: ${({ theme }) => theme.colours.greys.lightGrey4};
  border: ${({ theme }) => theme.borders.thin.solid1};
  border-radius: 2px;
`;

const Select = styled(UnstyledSelect)``;

interface Props {
  className?: string;
  name?: string;
  options: { value: string; name: string }[];
  value?: string;
  emptyLabel?: string;
  disableEmpty?: boolean;
  onChange(value: string): void;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FilterSelector = ({
  className,
  name,
  options,
  value,
  emptyLabel = 'None',
  disableEmpty = false,
  onChange,
}: Props) => {
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  const items = options.map(option => (
    <MenuItem
      key={option.value}
      value={option.value}
    >
      {option.name}
    </MenuItem>
  ));

  return (
    <FormControl className={className}>
      <InputLabel
        id="demo-customized-select-label"
        shrink={value !== ''}
      >
        {name}
      </InputLabel>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={R.isNil(value) || R.isEmpty(options) ? '' : value}
        onChange={handleChange}
        input={<StyledInput />}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          transformOrigin: {
            vertical: 'center',
            horizontal: 'left',
          },
        }}
      >
        {!disableEmpty && (
          <MenuItem value="">
            <em>{emptyLabel}</em>
          </MenuItem>
        )}
        {items}
      </Select>
    </FormControl>
  );
};

export default FilterSelector;
