export const defaultValues = {
  title: '',
  description: '',
  value: '',
  comment: '',
  isMeasurement: false,
  file: null,
};

export const formConfig = {
  title: {
    validate: {
      length: {
        maximum: 200,
        wrongLength: 'Length between 5 and 200 char',
      },
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  description: {
    validate: {
      length: {
        maximum: 200,
        wrongLength: 'Length between 5 and 200 char',
      },
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },
  value: {},

  comment: {},

  file: {},
};
