import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as adminActions from '../state/actions';
import * as adminSelectors from '../state/selectors';

const useAllElements = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.loadElementsRequest());
  }, [dispatch]);

  const elements = useSelector(adminSelectors.getElements);
  const elementsById = R.reduce(
    (map, element) => ({
      ...map,
      [element.id]: element,
    }),
    {},
    elements
  );

  return {
    elements,
    elementsById,
  };
};

export default useAllElements;
