/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import UnstyledAttachmentName from './AttachmentName';

const AttachmentName = styled(UnstyledAttachmentName)``;

const AttachmentType = styled(UnstyledTypography)`
  font: ${({ theme }) => theme.fonts.normal14};
`;

const Actions = styled.div`
  display: flex;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;

  ${AttachmentName} {
    width: 385px;
    margin-right: 20px;
  }
`;

interface Props {
  className?: string;
  attachment: any;
  isLoading: boolean;
  onDelete?: any;
  onDownload?: any;
}

const Attachment = ({ className, attachment, isLoading, onDelete, onDownload }: Props) => (
  <Container className={className}>
    <AttachmentName>{attachment.filename}</AttachmentName>
    <Details>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <AttachmentType>File Type: {attachment.typeName}</AttachmentType>
          <Actions>
            <IconButton
              size="small"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={onDownload}
            >
              <GetAppIcon />
            </IconButton>
          </Actions>
        </>
      )}
    </Details>
  </Container>
);

export default Attachment;
