// useProgramElement
import * as R from 'ramda';
import { useState, useEffect, useCallback, createContext, useContext } from 'react';
import Api from '../api';

export interface ProgramCategory {
  id: string;
  name: string;
  displayOrder: number | null;
}
const ProgramElementContext = createContext(null as any);

const useProgramElementProvider = () => {
  const reportIndicators = useContext(ProgramElementContext);

  if (R.isNil(reportIndicators)) {
    throw new Error('useProgramElementProvider must be used inside ProgramElementProvider');
  }

  return reportIndicators;
};

export const ProgramElementProvider = ({ elementId, ...props }: any) => {
  const [categories, setCategories] = useState([] as Array<ProgramCategory>);
  const [autoCompleteCategories, setAutoCompleteCategories] = useState([] as Array<string>);
  const [indicators, setIndicators] = useState([] as any);

  useEffect(() => {
    Api.getElementCategories(elementId).then(setCategories);
  }, [elementId, setCategories]);

  useEffect(() => {
    Api.getAutoCompleteCategories(elementId).then(setAutoCompleteCategories);
  }, [elementId, setAutoCompleteCategories]);

  useEffect(() => {
    Api.getElementIndicators(elementId).then(setIndicators);
  }, [elementId, setIndicators, categories]);

  const getElementIndicators = useCallback(async () => {
    const res = await Api.getElementIndicators(elementId);
    setIndicators(res);
  }, [elementId, setIndicators]);

  const addCategory = useCallback(
    async (categoryName: any) => {
      const maxValueOfY =
        categories.length === 0 ? 0 : Math.max(...categories.map(o => (!R.isNil(o.displayOrder) ? o.displayOrder : 0)));
      await Api.addProgramCategory(elementId, { name: categoryName, displayOrder: maxValueOfY + 1 });
      const updatedCateogries = await Api.getElementCategories(elementId);
      await setCategories(updatedCateogries);
    },
    [elementId, categories]
  );

  const removeCategory = useCallback(
    async (categoryId: any) => {
      await Api.removeProgramCategory(elementId, categoryId);
      setCategories(categories.filter(({ id }) => categoryId !== id));
    },
    [elementId, categories]
  );

  const updateCategory = useCallback(
    async (category: ProgramCategory) => {
      await Api.updateProgramCategory(elementId, category);
      const updatedCateogries = await Api.getElementCategories(elementId);
      await setCategories(updatedCateogries);
    },
    [elementId]
  );

  const reorderCategory = useCallback(
    async (category: ProgramCategory, destinationIndex: number) => {
      const indexOfOldCategory = categories.findIndex((c: ProgramCategory) => c.id === category.id);
      const updatedCategories: any = R.insert(
        destinationIndex,
        R.nth(indexOfOldCategory, categories),
        R.remove(indexOfOldCategory, 1, categories)
      );

      const updatedCategoriesWithDisplayOrder = updatedCategories.map((i: ProgramCategory, idx: number) => ({
        ...i,
        displayOrder: idx + 1,
      }));

      setCategories(updatedCategoriesWithDisplayOrder);

      updatedCategoriesWithDisplayOrder.forEach((i: ProgramCategory) => {
        Api.reorderProgramCategory(elementId, i);
      });
    },
    [categories, setCategories]
  );

  const value = {
    categories,
    indicators,
    getElementIndicators,
    autoCompleteCategories,
    addCategory,
    removeCategory,
    updateCategory,
    reorderCategory,
  };

  return (
    <ProgramElementContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useProgramElementProvider;
