import { Remote, Path } from './types';

const getAllUsers = (path: Path, remote: Remote) => () => remote.get(path('/'));
const getAllUserElements = (path: Path, remote: Remote) => (userId: string) =>
  remote.get(path(`/${userId}/assignments`));
const addUserAssignment = (path: Path, remote: Remote) => (userId: string, elementId: string, assignmentType: string) =>
  remote.put(path(`/${userId}/assignments`), { elementId, assignmentType });
const deleteUserAssignment =
  (path: Path, remote: Remote) => (userId: string, elementId: string, assignmentType: string) =>
    remote.del(path(`/${userId}/assignments/${elementId}`), { data: { assignmentType } });

const clearPrimaryRse = (path: Path, remote: Remote) => (elementId: string, userId: string) =>
  deleteUserAssignment(path, remote)(userId, elementId, 'primary');

const getUserNotes = (path: Path, remote: Remote) => () => remote.get(path('/notes/all'));
const addUserNotes = (path: Path, remote: Remote) => (note: string) => remote.post(path('/notes'), { note });
const updateUserNote = (path: Path, remote: Remote) => (id: number, note: string) =>
  remote.put(path(`/notes/${id}`), { note });
const setUserNoteDone = (path: Path, remote: Remote) => (id: number) => remote.patch(path(`/notes/${id}/done`));

export default (path: Path, remote: Remote) => ({
  getAllUsers: getAllUsers(path, remote),
  getAllUserAssignments: getAllUserElements(path, remote),
  addUserAssignment: addUserAssignment(path, remote),
  deleteUserAssignment: deleteUserAssignment(path, remote),
  clearPrimaryRse: clearPrimaryRse(path, remote),
  getUserNotes: getUserNotes(path, remote),
  addUserNotes: addUserNotes(path, remote),
  setUserNoteDone: setUserNoteDone(path, remote),
  updateUserNote: updateUserNote(path, remote),
});
