import React, { useState, useCallback, useMemo } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import useWalkdownTemplatesList from '../../hooks/useWalkdownTemplatesList';
import useAllElements, { AllElementsProvider } from '../../../../hooks/all-elements';
import Page from '../../../../components/Page';
import Table from '../../../../components/Table';
import UnstyledFilterSelector from '../../../../components/FilterSelector';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import { Template } from '../../types/types';

const FilterSelector = styled(UnstyledFilterSelector)`
  min-width: 300px;
`;

const CloneTemplatesPage = () => {
  const { totalList } = useWalkdownTemplatesList();
  const { goBack, push } = useHistory();
  const { elements } = useAllElements();
  const [elementId, setElementId] = useState<any>(null);

  const filteredList = useMemo(() => {
    if (elementId) {
      return totalList.filter(template => template.elementId === elementId);
    }
    return totalList;
  }, [elementId, totalList]);

  const elementOptions = elements.map((element: any) => ({
    name: `[${elementTypePrefix(element.type)}] ${element.name} (${element.facilityName})`,
    value: element.id,
  }));

  const cloneHandler = useCallback(
    async (id: number) => {
      push(`/app/walkdown/templates/create?cloneFrom=${id}`);
    },
    [elementId]
  );

  return (
    <Page>
      <Button
        color="primary"
        onClick={goBack}
      >
        <ArrowBackIosIcon color="primary" />
        Back
      </Button>
      <Grid
        container
        style={{ paddingTop: '1em', paddingBottom: '1em', width: '10em' }}
      >
        <FormControl fullWidth>
          <FilterSelector
            name="Element"
            value={elementId || ''}
            onChange={setElementId}
            options={elementOptions}
          />
        </FormControl>
      </Grid>
      <Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {filteredList.map(({ id, title, elementName, facilityName }: Template) => (
                <TableRow key={id}>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <b>
                      {elementName} {facilityName.toUpperCase()}
                    </b>
                  </TableCell>
                  <TableCell align="left">{title}</TableCell>
                  <TableCell align="left">
                    <Button onClick={() => cloneHandler(id)}>Clone</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Page>
  );
};

export default () => (
  <AllElementsProvider>
    <CloneTemplatesPage />
  </AllElementsProvider>
);
