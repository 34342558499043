import React from 'react';
import styled from 'styled-components';

import MUIListItem from '@material-ui/core/ListItem';
import MUIGrid from '@material-ui/core/Grid';

import ControlButton from '../ControlButton';

const GridItem = styled(MUIGrid).attrs({
  item: true,
})`
  &:first-child {
    font-weight: 700;
  }
`;

const GridContainer = styled(MUIGrid).attrs({
  container: true,
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  margin-left: 28px;
  margin-right: 28px;
  ${GridItem}:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const ListItem = styled(MUIListItem)`
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

const AttachmentListItem = ({ attachment, onDownload }) => (
  <ListItem>
    <GridContainer>
      <GridItem xs={8}>{attachment.filename}</GridItem>
      <GridItem xs={2}>{attachment.typeName}</GridItem>
      <GridItem xs={2}>
        <ControlButton onClick={onDownload}>View</ControlButton>
      </GridItem>
    </GridContainer>
  </ListItem>
);

export default AttachmentListItem;
