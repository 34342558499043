/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UnstyledFileUploadField from './FileUploadField';

const FileUploadField = styled(UnstyledFileUploadField)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  ${FileUploadField} {
    max-width: 240px;
    max-height: 50px;
  }
`;

interface Props {
  className?: string;
  onUpload(file: any): void;
}

const FileUploadForm = ({ className, onUpload }: Props) => {
  const [file, setFile] = useState(null);

  const handleUploadFile = () => {
    if (file) {
      onUpload(file);
    }
  };

  useEffect(() => {
    handleUploadFile();
  }, [file]);

  return (
    <Container className={className}>
      <FileUploadField onChange={setFile} />
    </Container>
  );
};

export default FileUploadForm;
