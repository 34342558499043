import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as elementActions from '../state/elements/actions';
import * as elementSelectors from '../state/elements/selectors';
import Api from '../api';
import { EchoElement } from './elements';

const ElementMappingContext = React.createContext(null as any);

const useElementMapping = () => {
  const context = useContext(ElementMappingContext);
  if (R.isNil(context)) {
    throw new Error('useElementMapping must be used inside an ElementMappingProvider');
  }
  return context;
};

export const ElementMappingProvider = ({ elementId, ...props }: any) => {
  const dispatch = useDispatch();
  const [elementMappings, setElementMappings] = useState([]);
  const element: EchoElement = useSelector<any, any>(state => elementSelectors.getElement(state, elementId));

  useEffect(() => {
    Api.getElementMappings(elementId).then(setElementMappings);
  }, [elementId, setElementMappings]);

  const onAddMapping = useCallback(
    async (mapping: any) => {
      const { facilityId } = element;
      const rMapping = { facilityId, ...mapping };
      if (rMapping.mappingType === 'location') {
        rMapping.unitNumber = null;
      } else {
        rMapping.location = null;
      }
      delete rMapping.mappingType;
      try {
        await Api.postElementMappings(elementId, rMapping);
        dispatch(elementActions.addElementMappingSuccess());
        Api.getElementMappings(elementId).then(setElementMappings);
      } catch (e: any) {
        const conflictMessage = e?.response?.data?.message || 'Error Adding Element Mappin';
        dispatch(elementActions.addElementMappingFailure(e, { errorMessage: conflictMessage }));
      }
    },
    [dispatch, element, elementId, setElementMappings]
  );

  const onRemoveMapping = useCallback(
    async ({ facilityId, location, unitNumber, usi }: any): Promise<void> => {
      try {
        await Api.deleteElementMapping(elementId, { facilityId, location, unitNumber, usi });
        dispatch(elementActions.removeElementMappingSuccess());
        Api.getElementMappings(elementId).then(setElementMappings);
      } catch (e) {
        dispatch(elementActions.removeElementMappingFailure(e, { errorMessage: 'Error Removing Element Mapping' }));
      }
    },
    [dispatch, elementId]
  );

  const value = {
    onAddMapping,
    onRemoveMapping,
    elementMappings,
  };

  return (
    <ElementMappingContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useElementMapping;
