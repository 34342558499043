// import * as R from 'ramda';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import { useState } from 'react';
import Panel from '../../../../../components/Panel';
import DropdownAutocomplete from '../../../../../components/DropdownAutocomplete';
import useDeferralsRequests from '../../../../../hooks/deferral-requests';
import DateSelect from '../../../../../components/DateSelect';

const NONE = { value: 'none', label: 'None' };

const FILTER_STATES = [
  NONE,
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];

const Root = styled(Grid)`
  padding: 5px 59px 5px 0px;

  .MuiFormControl-root {
    margin-right: 20px;
  }
`;

// get approvers from requestedDeferrals
// get facilities from list
// get health report names from list
const TopPanel = ({ onChangeFilter }: any) => {
  const { requestedDeferralsElements, requestedDeferralsApprovers, requestedDeferralsFacilities } =
    useDeferralsRequests();
  const [maxDate, setMaxDate] = useState<Date>();
  const [minDate, setMinDate] = useState(new Date());
  return (
    <Panel>
      <Root
        container
        spacing={3}
      >
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Status"
            onChange={(state: string) => {
              onChangeFilter({ state });
            }}
            options={FILTER_STATES}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Element"
            onChange={(element: string) => {
              onChangeFilter({ element });
            }}
            options={[NONE, ...requestedDeferralsElements]}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Approvers"
            onChange={approver => {
              onChangeFilter({ approver });
            }}
            options={[NONE, ...requestedDeferralsApprovers]}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Facilities"
            onChange={facility => {
              onChangeFilter({ facility });
            }}
            options={[NONE, ...requestedDeferralsFacilities]}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <DateSelect
            label="Deferral From Date"
            defaultValue={''}
            minDate={new Date()}
            maxDate={maxDate}
            onChange={from => {
              onChangeFilter({ from });
              setMinDate(from);
            }}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <DateSelect
            label="Deferral To Date"
            minDate={minDate}
            onChange={to => {
              onChangeFilter({ to });
              setMaxDate(to);
            }}
          />
        </Grid>
      </Root>
    </Panel>
  );
};

export default TopPanel;
