import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import * as R from 'ramda';
import { getAccessToken } from '../auth/getAccessToken';
import msalInstance from '../auth/msalInstance';

const configWithAuthorization = (config, accessToken) => ({
  ...config,
  maxRedirects: 0,
  headers: {
    ...config.headers,
    authorization: `Bearer ${accessToken}`,
  },
});

const get = (url, config = {}) =>
  getAccessToken()
    .then(token => axios.get(url, configWithAuthorization(config, token)))
    .then(R.prop('data'));

const post = (url, data = {}, config = {}) =>
  getAccessToken()
    .then(token => axios.post(url, data, configWithAuthorization(config, token)))
    .then(R.prop('data'));

const del = (url, config = {}) =>
  getAccessToken()
    .then(token => axios.delete(url, configWithAuthorization(config, token)))
    .then(R.prop('data'));

const patch = (url, data = {}, config = {}) =>
  getAccessToken()
    .then(token => axios.patch(url, data, configWithAuthorization(config, token)))
    .then(R.prop('data'));

const put = (url, data = {}, config = {}) =>
  getAccessToken()
    .then(token => axios.put(url, data, configWithAuthorization(config, token)))
    .then(R.prop('data'));

const download = (url, config = {}) =>
  getAccessToken()
    .then(token =>
      axios.get(
        url,
        configWithAuthorization(
          {
            ...config,
            responseType: 'blob',
            timeout: 30000,
          },
          token
        )
      )
    )
    .then(R.prop('data'));

const handleApiError =
  fn =>
  async (...args) =>
    fn(...args)
      .then(response => response)
      .catch(async error => {
        if (error instanceof InteractionRequiredAuthError) {
          // Do not fallback to interaction when running outside the context of MsalProvider.
          // Interaction should always be done inside context. Instead, clear cache and refresh as a workaround.
          await msalInstance.clearCache();
          window.location.reload();
        } else {
          // If this isn't a InteractionRequiredAuthError, propagate to caller.
          throw error;
        }
      });

export default () => ({
  get: handleApiError(get),
  post: handleApiError(post),
  del: handleApiError(del),
  patch: handleApiError(patch),
  put: handleApiError(put),
  download: handleApiError(download),
});
