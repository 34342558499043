/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import UnstyledTypography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import UnstyledFileDropZone from './FileDropZone';

const FileDropZone = styled(UnstyledFileDropZone)``;

const Container = styled.div`
  width: 385px;
`;

const Typography = styled(UnstyledTypography)`
  font: ${({ theme }) => theme.fonts.medium16};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AttachmentNameContainer = styled.div`
  padding: 12px 14px;
  border: ${({ theme }) => theme.borders.thin.solid6};
`;

const AttachmentName = ({ className, children }: { className?: string; children: React.ReactNode }) => (
  <AttachmentNameContainer className={className}>
    <Typography>{children}</Typography>
  </AttachmentNameContainer>
);

const AttachmentPreview = styled.div`
  display: flex;
  justify-content: space-between;

  ${AttachmentNameContainer} {
    flex-grow: 1;
    max-width: calc(385px - 48px);
  }
`;

interface Props {
  className?: string;
  value?: any;
  error?: boolean;
  helperText?: string;
  onChange(file: any): void;
}

const FileUploadField = ({ className, value, error, helperText, onChange }: Props) => {
  const handleFileDrop = (files: Array<any>) => {
    if (files.length === 1) {
      onChange(files[0]);
    }
  };

  return (
    <Container className={className}>
      {value ? (
        <AttachmentPreview>
          <AttachmentName>{value.name}</AttachmentName>
          <IconButton
            onClick={() => {
              onChange(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        </AttachmentPreview>
      ) : (
        <>
          <FileDropZone onFileDrop={handleFileDrop} />
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </>
      )}
    </Container>
  );
};

export default FileUploadField;
