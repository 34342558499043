import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import UnstyledPaper from '@material-ui/core/Paper';
import globalTheme from '../../../../theme';

const shadowPath = s => ['shadows', ...R.split('.', s)];

const ShadowBox = styled(props => <Box {...R.omit(['path', 'border'], props)} />)`
  box-shadow: ${({ path, theme }) => R.path(shadowPath(path), theme)};
  border: ${({ border = 'none' }) => border};
  width: 200px;
  height: 128px;
`;

const Paper = styled(UnstyledPaper)``;

const ShadowChipContainer = styled(Grid).attrs({
  item: true,
})`
  display: flex;
  flex-direction: column;

  ${Paper} {
    width: 200px;
    height: 128px;
  }
`;

const Section = ({ children, name }) => (
  <>
    <Typography variant="h4">{name}</Typography>
    <Grid
      container
      spacing={3}
    >
      {children}
    </Grid>
  </>
);

const ShadowChip = ({ name, path, border }) => (
  <ShadowChipContainer>
    <ShadowBox
      path={path}
      border={border}
    />
    <Typography>{name}</Typography>
  </ShadowChipContainer>
);

const PaperChip = ({ elevation }) => (
  <ShadowChipContainer>
    <Paper
      square
      elevation={elevation}
    />
    <Typography>elevation={elevation}</Typography>
  </ShadowChipContainer>
);

const Shadows = () => {
  const shadowChips = R.keys(globalTheme.shadows).map(name => (
    <ShadowChip
      key={name}
      name={name}
      path={name}
    />
  ));

  const pageChips = R.range(0, 6).map(elevation => (
    <PaperChip
      key={elevation}
      elevation={elevation}
    />
  ));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Section name="Custom">{shadowChips}</Section>
        <Section name="MaterialUI Paper">{pageChips}</Section>
      </Grid>
    </Grid>
  );
};

export default Shadows;
