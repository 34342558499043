import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadAllRequest = () => ({
  type: actionTypes.ELEMENTS_LOAD_ALL_REQUEST,
});

export const loadAllSuccess = elements => ({
  type: actionTypes.ELEMENTS_LOAD_ALL_SUCCESS,
  payload: {
    elements,
  },
});

export const loadAllFailure = errorAction(actionTypes.ELEMENTS_LOAD_ALL_FAILURE);

export const loadBackupRsesRequest = elementId => ({
  type: actionTypes.ELEMENTS_LOAD_BACKUP_RSES_REQUEST,
  payload: {
    elementId,
  },
});

export const loadBackupRsesSuccess = (elementId, backupRses) => ({
  type: actionTypes.ELEMENTS_LOAD_BACKUP_RSES_SUCCESS,
  payload: {
    elementId,
    backupRses,
  },
});

export const loadBackupRsesFailure = errorAction(actionTypes.ELEMENTS_LOAD_BACKUP_RSES_FAILURE);

// Patch element
export const patchElementRequest = (
  elementId,
  {
    reportingFrequency,
    state,
    tier,
    category,
    referenceReportingYear,
    referenceReportingQuarter,
    pmpRequired,
    sis,
    safetyRelatedSystemClassification,
    pmpDate,
  }
) => ({
  type: actionTypes.ELEMENTS_PATCH_REQUEST,
  payload: {
    elementId,
    state,
    tier,
    referenceReportingYear,
    referenceReportingQuarter,
    reportingFrequency,
    category,
    pmpRequired,
    sis,
    safetyRelatedSystemClassification,
    pmpDate,
  },
});

export const patchElementSuccess = (
  elementId,
  {
    reportingFrequency,
    state,
    tier,
    category,
    referenceReportingYear,
    referenceReportingQuarter,
    pmpRequired,
    sis,
    safetyRelatedSystemClassification,
    pmpDate,
  }
) => ({
  type: actionTypes.ELEMENTS_PATCH_SUCCESS,
  payload: {
    elementId,
    state,
    tier,
    referenceReportingYear,
    referenceReportingQuarter,
    reportingFrequency,
    category,
    pmpRequired,
    sis,
    safetyRelatedSystemClassification,
    pmpDate,
  },
});

export const patchElementFailure = errorAction(actionTypes.ELEMENTS_PATCH_FAILURE);

export const addElementMappingSuccess = () => ({
  type: actionTypes.ELEMENTS_ADD_ELEMENT_MAPPINGS_SUCCESS,
  payload: {
    message: 'Add Element Mapping Success',
  },
});

export const addElementMappingFailure = errorAction(actionTypes.ELEMENTS_ADD_ELEMENT_MAPPINGS_FAILURE);

export const removeElementMappingSuccess = () => ({
  type: actionTypes.ELEMENTS_REMOVE_ELEMENT_MAPPINGS_SUCCESS,
  payload: {
    message: 'Remove Element Mapping Success',
  },
});

export const removeElementMappingFailure = errorAction(actionTypes.ELEMENTS_REMOVE_ELEMENT_MAPPINGS_FAILURE);

export const loadElementUnitsRequest = elementId => ({
  type: actionTypes.ELEMENTS_LOAD_ELEMENT_UNITS_REQUEST,
  payload: {
    elementId,
  },
});

export const loadElementUnitsSuccess = (elementId, elementUnits) => ({
  type: actionTypes.ELEMENTS_LOAD_ELEMENT_UNITS_SUCCESS,
  payload: {
    elementId,
    elementUnits,
  },
});

export const loadElementUnitsFailure = errorAction(actionTypes.ELEMENTS_LOAD_ELEMENT_UNITS_FAILURE);

export const setPrimaryRseRequest = (elementId, userId, name) => ({
  type: actionTypes.ELEMENTS_SET_PRIMARY_RSE_REQUEST,
  payload: {
    elementId,
    userId,
    name,
  },
});

export const setPrimaryRseSuccess = (elementId, userId, name) => ({
  type: actionTypes.ELEMENTS_SET_PRIMARY_RSE_SUCCESS,
  payload: {
    elementId,
    userId,
    name,
  },
});

export const setPrimaryRseFailure = errorAction(actionTypes.ELEMENTS_SET_PRIMARY_RSE_FAILURE);

export const clearPrimaryRseRequest = (elementId, userId) => ({
  type: actionTypes.ELEMENTS_CLEAR_PRIMARY_RSE_REQUEST,
  payload: {
    elementId,
    userId,
  },
});

export const clearPrimaryRseSuccess = elementId => ({
  type: actionTypes.ELEMENTS_CLEAR_PRIMARY_RSE_SUCCESS,
  payload: {
    elementId,
  },
});

export const clearPrimaryRseFailure = errorAction(actionTypes.ELEMENTS_CLEAR_PRIMARY_RSE_FAILURE);

export const setBackupRseRequest = (elementId, userId, name) => ({
  type: actionTypes.ELEMENTS_SET_BACKUP_RSE_REQUEST,
  payload: {
    elementId,
    userId,
    name,
  },
});

export const setBackupRseSuccess = (elementId, userId, name) => ({
  type: actionTypes.ELEMENTS_SET_BACKUP_RSE_SUCCESS,
  payload: {
    elementId,
    userId,
    name,
  },
});

export const setBackupRseFailure = errorAction(actionTypes.ELEMENTS_SET_BACKUP_RSE_FAILURE);

export const clearBackupRseRequest = (elementId, userId) => ({
  type: actionTypes.ELEMENTS_CLEAR_BACKUP_RSE_REQUEST,
  payload: {
    elementId,
    userId,
  },
});

export const clearBackupRseSuccess = (elementId, userId) => ({
  type: actionTypes.ELEMENTS_CLEAR_BACKUP_RSE_SUCCESS,
  payload: {
    elementId,
    userId,
  },
});

export const clearBackupRseFailure = errorAction(actionTypes.ELEMENTS_CLEAR_BACKUP_RSE_FAILURE);

export const loadUnitWeightsRequest = elementId => ({
  type: actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_REQUEST,
  payload: {
    elementId,
  },
});

export const loadUnitWeightsSuccess = (elementId, unitWeights) => ({
  type: actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_SUCCESS,
  payload: {
    elementId,
    unitWeights,
  },
});

export const loadUnitWeightsFailure = errorAction(actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_FAILURE);

export const loadRequest = elementId => ({
  type: actionTypes.ELEMENTS_LOAD_REQUEST,
  payload: {
    elementId,
  },
});

export const loadSuccess = (elementId, element) => ({
  type: actionTypes.ELEMENTS_LOAD_SUCCESS,
  payload: {
    elementId,
    element,
  },
});

export const setOverrideReportDueDateRequest = (elementId, date) => ({
  type: actionTypes.SET_OVERRIDE_REPORT_DUE_DATE_REQUEST,
  payload: {
    elementId,
    date,
  },
});

export const setOverrideReportDueDateSuccess = (elementId, date) => ({
  type: actionTypes.SET_OVERRIDE_REPORT_DUE_DATE_SUCCESS,
  payload: {
    elementId,
    date,
  },
});

export const setOverrideReportDueDateFailure = errorAction(actionTypes.SET_OVERRIDE_REPORT_DUE_DATE_REQUEST);

export const setOverridePmpDateRequest = (elementId, date) => ({
  type: actionTypes.SET_OVERRIDE_PMP_DATE_REQUEST,
  payload: {
    elementId,
    date,
  },
});

export const setOverridePmpDateSuccess = (elementId, date) => ({
  type: actionTypes.SET_OVERRIDE_PMP_DATE_SUCCESS,
  payload: {
    elementId,
    date,
  },
});

export const setOverridePmpDateFailure = errorAction(actionTypes.SET_OVERRIDE_PMP_DATE_FAILURE);

export const loadFailure = errorAction(actionTypes.ELEMENTS_LOAD_FAILURE);

// Load Categories
export const loadCategoriesRequest = () => ({
  type: actionTypes.ELEMENTS_LOAD_CATEGORIES_REQUEST,
});

export const loadCategoriesSuccess = categories => ({
  type: actionTypes.ELEMENTS_LOAD_CATEGORIES_SUCCESS,
  payload: categories,
});

export const loadCategoriesFailure = errorAction(actionTypes.ELEMENTS_LOAD_CATEGORIES_FAILURE);

// Load Frequencies
export const loadFrequenciesRequest = () => ({
  type: actionTypes.ELEMENTS_LOAD_FREQUENCIES_REQUEST,
});

export const loadFrequenciesSuccess = data => ({
  type: actionTypes.ELEMENTS_LOAD_FREQUENCIES_SUCCESS,
  payload: data,
});

export const loadFrequenciesFailure = errorAction(actionTypes.ELEMENTS_LOAD_FREQUENCIES_FAILURE);

// Load Facilities
export const loadFacilitiesRequest = () => ({
  type: actionTypes.ELEMENTS_LOAD_FACILITIES_REQUEST,
});

export const loadFacilitiesSuccess = data => ({
  type: actionTypes.ELEMENTS_LOAD_FACILITIES_SUCCESS,
  payload: data,
});

export const loadFacilitiesFailure = errorAction(actionTypes.ELEMENTS_LOAD_FACILITIES_FAILURE);

// Load Schemas
export const loadSchemasRequest = () => ({
  type: actionTypes.ELEMENTS_LOAD_SCHEMAS_REQUEST,
});

export const loadSchemasSuccess = data => ({
  type: actionTypes.ELEMENTS_LOAD_SCHEMAS_SUCCESS,
  payload: data,
});

export const loadSchemasFailure = errorAction(actionTypes.ELEMENTS_LOAD_SCHEMAS_FAILURE);

export const addDeferralRequestSuccess = () => ({
  type: actionTypes.DEFERRAL_REQUEST_SUCCESS,
  payload: {
    message: 'Deferral Request Success',
  },
});

export const addDeferralRequestFailure = errorAction(actionTypes.DEFERRAL_REQUEST_FAILURE);

export const approveDeferralRequestSuccess = () => ({
  type: actionTypes.DEFERRAL_REQUEST_APPROVE_SUCCESS,
  payload: {
    message: 'Deferral Request Approved',
  },
});

export const approveDeferralRequestFailure = errorAction(actionTypes.DEFERRAL_REQUEST_APPROVE_FAILURE);

export const rejectDeferralRequestSuccess = () => ({
  type: actionTypes.DEFERRAL_REQUEST_REJECT_SUCCESS,
  payload: {
    message: 'Deferral Request Rejected',
  },
});

export const rejectDeferralRequestFailure = errorAction(actionTypes.DEFERRAL_REQUEST_REJECT_FAILURE);

export const deleteDeferralRequestSuccess = () => ({
  type: actionTypes.DEFERRAL_REQUEST_DELETE_SUCCESS,
  payload: {
    message: 'Deferral Request Deleted',
  },
});

export const deleteDeferralRequestFailure = errorAction(actionTypes.DEFERRAL_REQUEST_DELETE_FAILURE);

export const resubmitDeferralRequestSuccess = () => ({
  type: actionTypes.DEFERRAL_REQUEST_RESUBMIT_SUCCESS,
  payload: {
    message: 'Deferral Request Resubmitted',
  },
});

export const resubmitDeferralRequestFailure = errorAction(actionTypes.DEFERRAL_REQUEST_RESUBMIT_FAILURE);
