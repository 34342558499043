import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';

const DownloadButton = (props, ref) => (
  <IconButton
    {...props}
    ref={ref}
  >
    <DownloadIcon />
  </IconButton>
);

export default React.forwardRef(DownloadButton);
