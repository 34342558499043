import { FormActionInputs, InlineUpdateActionInputs } from '../../../types/cwt/actions';
import { Path, Remote } from '../../types';

const getAllActions = (path: Path, remote: Remote) => (workorderId?: number) =>
  remote.get(path(`/`), { params: { workorderId } });
const createAction = (path: Path, remote: Remote) => (action: Omit<FormActionInputs, 'id'>) =>
  remote.post(path('/'), { action });
const updateAction = (path: Path, remote: Remote) => (action: Omit<FormActionInputs, 'workorderId'>) =>
  remote.patch(path(`/${action.id}`), { action });
const updateActionInline = (path: Path, remote: Remote) => (action: InlineUpdateActionInputs) => {
  return remote.patch(path(`/${action.id}`), { action });
};

const getAllActionGroups = (path: Path, remote: Remote) => () => remote.get(path(`/groups`));

const getAllActionStatuses = (path: Path, remote: Remote) => () => remote.get(path(`/statuses`));

const getAllActionFlags = (path: Path, remote: Remote) => () => remote.get(path(`/flags`));

const removeActionFlag = (path: Path, remote: Remote) => (id: string | number) =>
  remote.patch(path(`/flags/${id}/disable`));

const addActionFlag = (path: Path, remote: Remote) => (label: string | number) =>
  remote.post(path(`/flags`), { label });

const removeActionGroup = (path: Path, remote: Remote) => (id: string | number) =>
  remote.patch(path(`/groups/${id}/disable`));

const addActionGroup = (path: Path, remote: Remote) => (label: string | number) =>
  remote.post(path(`/groups`), { label });

const removeActionStatus = (path: Path, remote: Remote) => (id: string | number) =>
  remote.patch(path(`/statuses/${id}/disable`));

const addActionStatus = (path: Path, remote: Remote) => (label: string | number) =>
  remote.post(path(`/statuses`), { label });

const action = (path: Path, remote: Remote) => ({
  getAllActions: getAllActions(path, remote),
  createAction: createAction(path, remote),
  updateAction: updateAction(path, remote),
  updateActionInline: updateActionInline(path, remote),
  getAllActionGroups: getAllActionGroups(path, remote),
  getAllActionStatuses: getAllActionStatuses(path, remote),
  getAllActionFlags: getAllActionFlags(path, remote),
  removeActionFlag: removeActionFlag(path, remote),
  addActionFlag: addActionFlag(path, remote),
  removeActionGroup: removeActionGroup(path, remote),
  addActionGroup: addActionGroup(path, remote),
  removeActionStatus: removeActionStatus(path, remote),
  addActionStatus: addActionStatus(path, remote),
});

export default action;
