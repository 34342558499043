import ReportSummary from '../../../../components/ReportSummary';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import { Report } from '../../../../hooks/report';

interface SummaryPanelProps {
  className?: string;
  report: Report;
}

const SummaryPanel: React.FC<SummaryPanelProps> = ({ className, report }) => (
  <ElementIndicatorsProvider elementId={report.elementId}>
    <ReportSummary
      className={className}
      report={report}
    />
  </ElementIndicatorsProvider>
);

export default SummaryPanel;
