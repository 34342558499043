import React from 'react';
import styled from 'styled-components';

const Details = styled.div``;

const Container = styled.div`
  ${Details} {
    font: ${({ theme }) => theme.fonts.normal13};
  }
`;

const DashboardDetails = ({ className, details }) => (
  <Container className={className}>
    <Details>{details}</Details>
  </Container>
);

export default DashboardDetails;
