import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import TccDetails from './TccDetails';

const Container = styled.div``;

const TmodsBeyond90DaysDetails = ({ className, recordDetails }) => (
  <Container className={className}>
    {recordDetails.tccRecord && (
      <Box p={5}>
        <TccDetails tccRecord={recordDetails.tccRecord} />
      </Box>
    )}
  </Container>
);

export default TmodsBeyond90DaysDetails;
