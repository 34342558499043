import { Path, Remote } from '../types';

const getAllCategories = (path: Path, remote: Remote) => () => remote.get(path(`/`));

const deleteCategory = (path: Path, remote: Remote) => (categoryId: number) => remote.del(path(`/${categoryId}`));

const addCategory = (path: Path, remote: Remote) => (categoryName: string) => remote.post(path(`/${categoryName}`));

const updateCategory = (path: Path, remote: Remote) => (categoryId: number, categoryName: string) =>
  remote.patch(path(`/${categoryId}`), { categoryName });

export default (path: Path, remote: Remote) => ({
  getAllCategories: getAllCategories(path, remote),
  deleteCategory: deleteCategory(path, remote),
  addCategory: addCategory(path, remote),
  updateCategory: updateCategory(path, remote),
});
