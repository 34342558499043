import React from 'react';
import styled from 'styled-components';
import useElementIndicators from '../../hooks/element-indicators';
import AnalysisDataTable from './AnalysisDataTable';

const List = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-top: 0px;
  border: ${({ theme }) => theme.borders.thin.solid1};
`;

const AnalysisDataContainer = ({ className, report }) => {
  const { elementIndicators } = useElementIndicators();

  const tables = elementIndicators
    .filter(({ indicatorType }) => indicatorType !== 'manual')
    .map(elementIndicator => (
      <AnalysisDataTable
        key={elementIndicator.indicatorId}
        report={report}
        elementIndicator={elementIndicator}
      />
    ));

  return <List className={className}>{tables}</List>;
};

export default AnalysisDataContainer;
