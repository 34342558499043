import React from 'react';
import styled from 'styled-components';

interface PanelProps {
  className?: string;
  component: JSX.Element;
}

const Panel: React.FC<PanelProps> = ({ className, component }) => {
  return React.cloneElement(component, { className });
};

const LeftPanel = styled(Panel)``;
const RightPanel = styled(Panel)``;
const Container = styled.div`
  display: flex;

  ${LeftPanel} {
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    flex-basis: 60%;
    border: ${({ theme }) => theme.borders.thin.solid5};
  }
  ${RightPanel} {
    flex-basis: 40%;
    max-height: calc(100vh - 220px);
    box-shadow: none;
  }
`;

interface Props {
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
}

const ExecutiveSummaryLayout: React.FC<Props> = ({ leftPanel, rightPanel }) => {
  return (
    <Container>
      <LeftPanel component={leftPanel} />
      <RightPanel component={rightPanel} />
    </Container>
  );
};

export default ExecutiveSummaryLayout;
