import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MUITextField from '@material-ui/core/TextField';
import UnstyledFormDialog from '../../../../components/FormDialog';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import useForm from '../../../../hooks/form';
import useConditionalConfirm from '../../../../hooks/conditional-confirm';

const TextField = styled(MUITextField).attrs({
  fullWidth: true,
})``;

const FormDialog = styled(UnstyledFormDialog)`
  display: flex;
  flex-direction: column;
  width: 40vw;
  min-width: 400px;

  ${TextField} {
    margin-bottom: 20px;
  }
`;

const formConfig = {
  title: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Set a title',
      },
    },
  },
  description: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Set a description',
      },
    },
  },
  dashboardUrl: {
    validate: {
      url: {
        allowLocal: process.env.NODE_ENV === 'development',
        message: 'Set a dashboard URL',
      },
    },
  },
  previewImgUrl: {
    validate: {
      url: {
        allowLocal: process.env.NODE_ENV === 'development',
        message: 'Set a preview image URL',
      },
    },
  },
};

const initialValues = R.pick(['title', 'description', 'dashboardUrl', 'previewImgUrl']);

const DashboardDialog = ({ className, title, dashboard, onSave, onClose }) => {
  const handleSave = values => {
    onSave({
      ...values,
      id: dashboard.id,
    });
  };

  const { submit, isDirty, propsForField } = useForm(formConfig, initialValues(dashboard), handleSave);
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onClose, isDirty);

  return (
    <FormDialog
      className={className}
      title={title}
      onCancel={confirmCancel}
      onSave={submit}
    >
      <TextField
        label="Title"
        {...propsForField('title')}
      />
      <TextField
        label="Description"
        {...propsForField('description')}
      />
      <TextField
        label="Dashboard Url"
        {...propsForField('dashboardUrl')}
      />
      <TextField
        label="Preview Image Url"
        {...propsForField('previewImgUrl')}
      />
      {confirming && (
        <OkayCancelDialog
          title="Lose changes?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          You have unsaved changes. If you continue, you will lose them.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default DashboardDialog;
