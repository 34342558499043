import { TextField } from '@material-ui/core';
import { Autocomplete, Value } from '@material-ui/lab';
import { useMemo } from 'react';

export interface AutocompleteOption<OptionValue extends string = string> {
  label: string;
  value: OptionValue;
}

export interface AutocompleteInputProps<OptionValue extends string, Multiple extends boolean> {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  label: string;
  multiple?: Multiple;
  onChange: (newValue: Value<OptionValue, Multiple, false, false>) => void;
  options: AutocompleteOption<OptionValue>[];
  placeholder?: string;
  value: Value<OptionValue, Multiple, false, false>;
  selectAll?: boolean;
  helperText?: string;
  name?: string;
  onInputBlur?: React.FocusEventHandler<HTMLInputElement>;
}

/**
 * A wrapper around MaterialUI's Autocomplete component
 *
 * @remarks
 *
 * Per MaterialUI requirements, the input's value needs to be the same type as its options. In our case, this is the
 * {@link AutocompleteOption} type (an object with `label` and `value` properties).
 *
 * However, this value type is inconsistent with other input types (including the almost identical Select). What's
 * more, the `label` property is only used for display purposes and shouldn't be saved in the form outputs.
 *
 * Therefore, this wrapper handles transformation from the external value we want to use, {@link OptionValue}, to the
 * internal data type that MaterialUI requires, {@link AutocompleteOption}.
 */
const AutocompleteInput = <OptionValue extends string, Multiple extends boolean = false>({
  className,
  disabled = false,
  error,
  label,
  multiple,
  onChange,
  options,
  placeholder,
  value,
  selectAll = false,
  helperText,
  name,
  onInputBlur,
}: AutocompleteInputProps<OptionValue, Multiple>) => {
  const selectAllOption = { label: 'Select All', value: 'selectAll' } as AutocompleteOption<OptionValue>;
  const inputValue = useMemo(() => {
    if (Array.isArray(value)) {
      // Convert OptionValue[] to AutocompleteOption<OptionValue>[]
      return options.filter(option => value?.includes(option.value));
    }
    // Convert OptionValue | null to AutocompleteOption<OptionValue> | null
    return options.find(option => option.value === value) || null;
  }, [options, value]);
  return (
    <Autocomplete<AutocompleteOption<OptionValue>, Multiple, false, false>
      className={className}
      multiple={multiple}
      freeSolo={false}
      options={selectAll ? [selectAllOption, ...options] : options}
      disableCloseOnSelect={!!multiple}
      getOptionLabel={option => option.label}
      onChange={(_e, newValue) => {
        // Save value only, not entire AutocompleteOption object

        if (selectAll && multiple && (newValue as AutocompleteOption<OptionValue>[])?.includes(selectAllOption)) {
          const value = options.map(option => option.value) as Value<OptionValue, Multiple, false, false>;
          onChange(value);
        } else {
          const value = (
            Array.isArray(newValue) ? newValue.map(option => option.value) : newValue?.value || null
          ) as Value<OptionValue, Multiple, false, false>;
          onChange(value);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          name={name}
          onBlur={onInputBlur}
        />
      )}
      value={inputValue as Value<AutocompleteOption<OptionValue>, Multiple, false, false>}
      getOptionSelected={(option, value) => option.value === value.value}
      disabled={disabled}
      limitTags={selectAll ? 3 : -1}
    />
  );
};

export default AutocompleteInput;
