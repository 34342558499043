import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../../api';
import { Walkdown } from '../../../../types/walkdown-types';

const WalkdownReviewContext = createContext<{
  walkdowns: Walkdown[];
  isLoading: boolean;
  approve(walkdownId: number, comment: string): Promise<void>;
  reject(walkdownId: number, comment: string): Promise<void>;
  refresh(): Promise<void>;
}>(null as any);

interface Props {
  children: any;
}

export const WalkdownReviewProvider = ({ children }: Props) => {
  const [walkdowns, setWalkdowns] = useState<Walkdown[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Api.getSubmittedWalkdowns().then((w: any) => {
      setWalkdowns(w);
      setIsLoading(false);
    });
  }, []);

  const approve = useCallback(async (id: number, comment: string) => {
    await Api.approveWalkdown(id, comment);
    const wd = await Api.getSubmittedWalkdowns();
    setWalkdowns(wd);
  }, []);

  const reject = useCallback(async (id: number, comment: string) => {
    await Api.rejectWalkdown(id, comment);
    const wd = await Api.getSubmittedWalkdowns();
    setWalkdowns(wd);
  }, []);

  const refresh = useCallback(async () => {
    const wd = await Api.getSubmittedWalkdowns();
    setWalkdowns(wd);
  }, []);

  const val = {
    walkdowns,
    isLoading,
    approve,
    reject,
    refresh,
  };
  return <WalkdownReviewContext.Provider value={val}>{children}</WalkdownReviewContext.Provider>;
};

const useWalkdownReview = () => {
  const v = useContext(WalkdownReviewContext);

  if (!v) {
    throw new Error('useWalkdownReview must be used inside its provider!');
  }

  return v;
};

export default useWalkdownReview;
