import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const NameText = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
  padding-left: 45px;
  padding-right: 24px;
`;

export default NameText;
