import * as R from 'ramda';

const recordSpecifics = (fieldName: string) => ({
  propFn: R.prop(fieldName),
  recordEqualsFn: R.propEq(fieldName),
});

const indicatorSpecifics = {
  'functional-failures': recordSpecifics('workRequestNumber'),
  'outstanding-corrective-actions': recordSpecifics('crNumber'),
  'online-deficient-maintenance-backlog': recordSpecifics('workOrderNumber'),
  'shutdown-deficient-maintenance-backlog': recordSpecifics('workOrderNumber'),
  'online-corrective-maintenance-backlog': recordSpecifics('workOrderNumber'),
  'shutdown-corrective-maintenance-backlog': recordSpecifics('workOrderNumber'),
  predefines: recordSpecifics('pmNumber'),
  'regdoc-reportable-events': recordSpecifics('crNumber'),
  'operator-workarounds': recordSpecifics('workOrderNumber'),
  'operator-burdens': recordSpecifics('workOrderNumber'),
  'tmods-beyond-6-months': recordSpecifics('tccId'),
  'tmods-beyond-90-days': recordSpecifics('tccId'),
  'modification-backlog': recordSpecifics('workOrderNumber'),
  'toe-corrective-actions': recordSpecifics('crNumber'),
  'comp-er-clock-resets': recordSpecifics('crNumber'),
  'comp-equipment-defects': recordSpecifics('workRequestNumber'),
  'comp-maintenance-feedback-backlog': recordSpecifics('workOrderNumber'),
  'comp-functional-failures': recordSpecifics('workRequestNumber'),
  'comp-outstanding-corrective-actions': recordSpecifics('crNumber'),
  'comp-predefines': recordSpecifics('pmNumber'),
  'comp-online-deficient-maintenance-backlog': recordSpecifics('workOrderNumber'),
  'comp-online-corrective-maintenance-backlog': recordSpecifics('workOrderNumber'),
  'comp-shutdown-corrective-maintenance-backlog': recordSpecifics('workOrderNumber'),
  'comp-shutdown-deficient-maintenance-backlog': recordSpecifics('workOrderNumber'),
};

export const propFn = (indicatorId: string) =>
  R.pathOr(R.always('Invalid indicator'), [indicatorId, 'propFn'], indicatorSpecifics);

export const recordEqualsFn = (indicatorId: string) =>
  R.pathOr(R.always('Invalid indicator'), [indicatorId, 'recordEqualsFn'], indicatorSpecifics);

export default indicatorSpecifics;
