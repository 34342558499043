import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import packageInfo from '../../../package.json';
import LoginLayout from '../../layouts/LoginLayout';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 64px;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 32px;
  padding-right: 32px;
  border-right: ${({ theme }) => theme.borders.thin.solid7};
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoginPage = () => {
  const versionString = `Version ${packageInfo.version}`;

  return (
    <LoginLayout>
      <Container>
        <LeftPanel>
          <p>{versionString}</p>
        </LeftPanel>
        <RightPanel>
          <Link
            component={Button}
            to="/app/dashboard"
          >
            Login With Microsoft
          </Link>
        </RightPanel>
      </Container>
    </LoginLayout>
  );
};

export default LoginPage;
