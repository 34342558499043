import { useState, useCallback } from 'react';

const useIndicatorApplicabilityHandlers = (
  findRecord: any,
  setApplicable: any,
  setInapplicable: any,
  preSelectedRecordId: string | number | null = null
) => {
  const [selectedRecordId, setSelectedRecordId] = useState(preSelectedRecordId);
  const [recordType, setRecordType] = useState<string | undefined>(undefined);

  const selectedRecord = findRecord(selectedRecordId);

  const setSelectedRecord = (id: any, type: string) => {
    setRecordType(type);
    setSelectedRecordId(id);
  };

  const clearSelectedRecord = () => {
    setRecordType(undefined);
    setSelectedRecordId(null);
  };

  const setSelectedRecordApplicable = useCallback(
    (options: any) => {
      // NOTE: This is a hack to get around the fact that the recordType is not being set correctly when on an ff component ed is applied
      setApplicable(selectedRecord, {
        ...options,
        recordType: options.recordType ? options.recordType : recordType,
      });
      clearSelectedRecord();
    },
    [selectedRecord, recordType]
  );

  const setSelectedRecordInapplicable = (options: any) => {
    setInapplicable(selectedRecord, options);
  };

  const setRecordApplicable = (recordId: string, options: any) => {
    setApplicable(findRecord(recordId), options);
  };

  const setRecordInapplicable = (recordId: string, options: any) => {
    setInapplicable(findRecord(recordId), options);
  };

  return {
    selectedRecord,
    recordType,
    setSelectedRecord,
    clearSelectedRecord,
    setSelectedRecordApplicable,
    setSelectedRecordInapplicable,
    setRecordApplicable,
    setRecordInapplicable,
  };
};

export default useIndicatorApplicabilityHandlers;
