import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as userActions from '../state/users/actions';
import * as userSelectors from '../state/users/selectors';

const useUsers = (load = true) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (load) {
      dispatch(userActions.loadAllRequest());
    }
  }, [dispatch, load]);

  const users = useSelector(userSelectors.getAllUsers);

  const usersById = useMemo(
    () =>
      R.reduce(
        (acc, user) => ({
          ...acc,
          [user.id]: user,
        }),
        {},
        users
      ),
    [users]
  );

  return {
    users,
    usersById,
  };
};

export default useUsers;
