import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

interface ConditionalProps {
  condition: any;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
  contents?: string | number | React.ReactNode;
}

const LoadingContainer = styled.div`
  padding: 30px;
  text-align: center;
`;

const Conditional = ({ condition, children, fallback, contents }: ConditionalProps) => {
  const defaultFallback = (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );

  if (!condition) {
    return <>{fallback || defaultFallback}</>;
  }

  return (
    <>
      {children}
      {contents}
    </>
  );
};

export default Conditional;
