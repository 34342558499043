import { useEffect, useState } from 'react';
import { Control, FieldErrors, SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Api from '../../../api';
import { AutocompleteOption } from '../../../components/AutocompleteInput';
import * as uiActions from '../../../state/ui/actions';
import { ActionType, ReferenceFormInputs, ReferenceType } from '../../../types/outages';
import { ACTION_TYPE_OPTIONS, REFERENCE_TYPE_OPTIONS } from '../constants';
import { toOutageCodeOptions } from '../utils/toOptions';

interface ReferenceForm {
  control: Control<ReferenceFormInputs>;
  handleSubmit: () => Promise<void>;
  errors: FieldErrors<ReferenceFormInputs>;
  actionTypeOptions: AutocompleteOption<ActionType>[];
  outageCodeOptions: AutocompleteOption[];
  referenceTypeOptions: AutocompleteOption<ReferenceType>[];
}

// "Legacy" exists only for backwards compatibility and should not be allowed for new references
const ADD_REFERENCE_TYPE_OPTIONS = REFERENCE_TYPE_OPTIONS.filter(option => option.value !== 'legacy');

export const useAddReferenceForm = (handleClose: () => void): ReferenceForm => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ReferenceFormInputs>();

  const dispatch = useDispatch();

  const [outageCodeOptions, setOutageCodeOptions] = useState<AutocompleteOption[]>([]);

  const fetchOutageCodeOptions = async () => {
    const response = await Api.getOutages();
    setOutageCodeOptions(toOutageCodeOptions(response.filter(outage => !!outage.outage_code)));
  };

  useEffect(() => {
    void fetchOutageCodeOptions();
  }, []);

  const onSubmit: SubmitHandler<ReferenceFormInputs> = async data => {
    try {
      const { outageCode, actionType, referenceType, referenceNumberText } = data;
      if (!outageCode || !actionType || !referenceType || !referenceNumberText) {
        return;
      }
      await Api.addOutageReference(outageCode, {
        action_type: actionType,
        reference_type: referenceType,
        reference_number: referenceNumberText,
      });
      dispatch(uiActions.genericMessage('Reference added!'));
      handleClose();
    } catch (error: any) {
      console.error(error);
      dispatch(
        uiActions.error(
          '',
          (error?.response?.status === 400 && error?.response?.data?.message) || `Error adding reference`
        )
      );
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    actionTypeOptions: ACTION_TYPE_OPTIONS,
    outageCodeOptions,
    referenceTypeOptions: ADD_REFERENCE_TYPE_OPTIONS,
  };
};
