import * as R from 'ramda';

const elementTypePrefixes = {
  system: 'SYS',
  component: 'COMP',
  program: 'PROG',
};

const elementTypePrefix = type => R.propOr(type, type, elementTypePrefixes);

export default elementTypePrefix;
