import React from 'react';
import styled from 'styled-components';
import { menuItems } from './menu-items';
import MenuList from './MenuList';
import clientDb from '../../client-db';

import { handleLogout } from '../../auth/utils';

const Container = styled.div``;

const Menu = (props: any) => {
  const entries = menuItems(() => {
    clientDb.truncate();
    handleLogout();
  });

  return (
    <Container
      {...props}
      style={{ overflowX: 'hidden' }}
    >
      <MenuList entries={entries} />
    </Container>
  );
};

export default Menu;
