import * as R from 'ramda';

export const getRecords = (state, indicatorId) =>
  R.pathOr([], ['pages', 'recordDetails', 'records', indicatorId, 'records'], state);
export const isLoading = (state, indicatorId) =>
  R.pathOr(false, ['pages', 'recordDetails', 'records', indicatorId, 'isLoading'], state);

export const getRecordDetails = state => state.pages.recordDetails.recordDetails;
export const getFilter = (state, name) => state.pages.recordDetails.filters[name];
export const getSelectedRecord = state => state.pages.recordDetails.selectedRecord;
