import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import globalTheme from '../../../../theme';

const fontPath = s => ['fonts', ...R.split('.', s)];

const FontBox = styled(props => <p {...R.omit(['path'], props)} />)`
  font: ${({ path, theme }) => R.path(fontPath(path), theme)};
  width: 200px;
`;

const FontChipContainer = styled(Grid).attrs({
  item: true,
})`
  display: flex;
  flex-direction: column;
`;

const Section = ({ children, name }) => (
  <>
    <Typography variant="h4">{name}</Typography>
    <Grid
      container
      spacing={3}
    >
      {children}
    </Grid>
  </>
);

const FontChip = ({ name, path }) => (
  <FontChipContainer>
    <FontBox path={path}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </FontBox>
    <Typography>{name}</Typography>
  </FontChipContainer>
);

const section = (name, path) => ({
  name,
  path,
});

const sections = [section('Normal', 'normal'), section('Medium', 'medium'), section('Bold', 'bold')];

const filteredKeys = path => R.filter(key => key.startsWith(path), R.keys(globalTheme.fonts));

const Fonts = () => {
  const chipsFromPath = path =>
    filteredKeys(path).map(name => (
      <FontChip
        key={name}
        name={name}
        path={name}
      />
    ));

  const sectionItems = sections.map(({ name, path }) => (
    <Grid
      key={name}
      item
      xs={12}
    >
      <Section name={name}>{chipsFromPath(path)}</Section>
    </Grid>
  ));

  return <Grid container>{sectionItems}</Grid>;
};

export default Fonts;
