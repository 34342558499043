import React from 'react';
import * as R from 'ramda';
import CommentOverlay from './CommentOverlay';
import { Report } from '../../../../hooks/report';
import ExecutiveSummaryLayout from './Layout';
import SummaryPanel from './SummaryPanel';

interface Props {
  report: Report;
  comment?: any;
  onNext(): void;
}

const ExecutiveSummaryPage = ({ report, comment, onNext }: Props) =>
  !R.isEmpty(report) ? (
    <ExecutiveSummaryLayout
      leftPanel={<SummaryPanel report={report} />}
      rightPanel={
        <CommentOverlay
          report={report}
          comment={comment}
          onNext={onNext}
        />
      }
    />
  ) : null;

export default ExecutiveSummaryPage;
