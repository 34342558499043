/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';
import useForm from '../../hooks/form';
import useConditionalConfirm from '../../hooks/conditional-confirm';
import FormDialog from '../FormDialog';
import OkayCancelDialog from '../OkayCancelDialog';
import ColourSelect from '../ColourSelect';
import CustomTextField from '../CustomTextField';
import ActionButton from '../ActionButton';
import useProgramIndicator from '../../hooks/program-indicator';
import ColourBox from '../ColourBox';

const Typography = styled(MUITypography)``;

const Title = styled(Typography)`
  font: ${({ theme }) => theme.fonts.medium16};
  color: ${({ theme }) => theme.colours.greys.darkGrey3};
`;

const Item = ({ title, children }: { title: string; children: any }) => (
  <Grid
    container
    item
  >
    <Grid
      item
      xs={4}
    >
      <Title>{title}</Title>
    </Grid>
    <Grid
      item
      xs={8}
    >
      {children}
    </Grid>
  </Grid>
);

interface ManualValue {
  id: number;
  value: number;
  colour: string;
  comment: string;
}

interface Props {
  title?: string;
  validation?: any;
  manualValue: ManualValue;
  readonly?: boolean;
  onSave: (value: number, colour: string, comment: string) => void;
  onCancel: () => void;
  onClear?: () => void;
  elementId: any;
}

const formConfig = (validation: any) => ({
  value: {
    validate: {
      presence: {
        allowEmpty: true,
        message: 'Enter a value',
      },
    },
  },
  colour: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a colour',
      },
    },
  },
  comment: {},
  ...validation,
});

const initialValues = (manualValue: ManualValue) => ({
  value: manualValue.value,
  colour: manualValue.colour,
  comment: manualValue.comment,
});

const ManualProgramIndicatorValueDialog = ({
  validation = {},
  manualValue,
  readonly,
  onSave,
  onCancel,
  onClear,
  elementId,
}: Props) => {
  const handleSave = (values: ManualValue) => {
    onSave(values.value, values.colour, values.comment);
  };

  const { indicator } = useProgramIndicator(manualValue.id, elementId);

  const { values, submit, isDirty, propsForField } = useForm(
    formConfig(validation),
    initialValues(manualValue),
    handleSave
  );
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  const canSave = !R.isNil(values.colour) || !readonly;

  const titleStyle = {
    marginTop: '1em',
  };

  const THRESHOLD_COLOR_TYPES = ['green', 'white', 'yellow', 'red'];

  return (
    <FormDialog
      title={indicator.name}
      hideSave={!canSave || readonly}
      onSave={submit}
      onCancel={confirmCancel}
    >
      <Grid
        container
        spacing={4}
        style={{ padding: '2%' }}
      >
        <Grid
          container
          item
          xs={5}
          spacing={2}
        >
          <Item title="Indicator">
            <Typography>{indicator.name}</Typography>
          </Item>
          <Item title="Value">
            <CustomTextField
              placeholder="Enter Value"
              fullWidth
              {...propsForField('value')}
              disabled={readonly}
            />
          </Item>
          <Item title="Colour">
            <ColourSelect
              fullWidth
              {...propsForField('colour')}
              disabled={readonly}
            />
          </Item>
          <Item title="Comment">
            <CustomTextField
              fullWidth
              inputProps={{ maxLength: 5000 }}
              rows={20}
              multiline
              placeholder="Add Comment"
              {...propsForField('comment')}
              disabled={readonly}
            />
          </Item>
        </Grid>
        {(indicator.colourThresholds || indicator.description) && (
          <Grid
            container
            item
            xs={7}
            direction="column"
            style={{ paddingLeft: '5%' }}
            spacing={3}
          >
            {indicator.colourThresholds && (
              <Grid container>
                <Grid item>
                  <Title>Colour Thresholds</Title>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  direction="column"
                  spacing={1}
                >
                  {THRESHOLD_COLOR_TYPES.map((key: string) => (
                    <Grid
                      container
                      alignItems="center"
                      item
                    >
                      <Grid
                        key={key}
                        item
                        xs={2}
                      >
                        <ColourBox colour={key}>
                          {indicator.colourThresholds[key].value ? indicator.colourThresholds[key].value : 'N/A'}
                        </ColourBox>
                      </Grid>
                      <Grid
                        item
                        style={{ padding: '2%' }}
                        xs={8}
                      >
                        {indicator.colourThresholds[key].description
                          ? indicator.colourThresholds[key].description
                          : 'No Description'}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {indicator.description && (
              <Grid
                container
                xs={6}
              >
                <Grid item>
                  <Title style={titleStyle}>Indicator Description</Title>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: '-2.2em' }}
                >
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    inputProps={{ maxLength: 5000 }}
                    rows={10}
                    multiline
                    value={indicator.description}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {onClear && (
          <Grid
            item
            xs={12}
          >
            <ActionButton
              onClick={onClear}
              color="secondary"
            >
              Clear Override
            </ActionButton>
          </Grid>
        )}
      </Grid>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          Are you sure? If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default ManualProgramIndicatorValueDialog;
