import React from 'react';
import styled from 'styled-components';
import UnstyledListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import NameText from './NameText';
import StatusText from './StatusText';
import DownloadButton from '../../../../components/DownloadButton';

const ListItem = styled(UnstyledListItem)``;

interface Props {
  className?: string;
  report?: any;
  onDownload?: any;
}

const HistoricalReportItem = ({ className, report, onDownload }: Props) => (
  <ListItem
    className={className}
    button={true}
  >
    <Grid
      container
      justifyContent="space-between"
    >
      <Grid
        item
        xs={10}
        container
        spacing={2}
      >
        <Grid
          item
          xs={4}
        >
          <NameText>{`${report.facilityId.toUpperCase()} - ${report.elementName} (${report.elementType})`}</NameText>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <StatusText>{report.reportingPeriod}</StatusText>
        </Grid>
      </Grid>

      <Grid item>
        <DownloadButton
          size="small"
          onClick={() => onDownload(report)}
        />
      </Grid>
    </Grid>
  </ListItem>
);

export default HistoricalReportItem;
