import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { useSchedule } from '../../../../hooks/useSchedule';
import { useEffect } from 'react';
interface Props {
  handleClick?: (info: any) => void;
}
const ScheduleCalendar = ({ handleClick }: Props) => {
  const { events, onDragDrop, fetchSchedule } = useSchedule();

  useEffect(() => {
    fetchSchedule();
  }, []);

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
      initialView="dayGridMonth"
      height="auto"
      events={events}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
      }}
      editable={true}
      eventDrop={onDragDrop}
      eventResize={onDragDrop}
      eventClick={handleClick}
    />
  );
};

export default ScheduleCalendar;
