import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CloneTemplatesList from './CloneTemplatesList';

const CloneTemplate = ({ match }: any) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/`}
        component={CloneTemplatesList}
      />
      <Route path={`${match.path}/:id`} />
    </Switch>
  );
};

export default CloneTemplate;
