import * as actionTypes from '../action-types';

// eslint-disable-next-line import/prefer-default-export
export const popMessage = () => ({
  type: actionTypes.UI_POP_MESSAGE,
});

export const setLoadingSpinnerTrue = () => ({
  type: actionTypes.UI_LOADING_SPINNER_TRUE,
});

export const setLoadingSpinnerFalse = () => ({
  type: actionTypes.UI_LOADING_SPINNER_FALSE,
});

export const error = (e?: string, errorMessage?: string) => ({
  type: actionTypes.ERROR,
  payload: {
    error: e,
    errorMessage,
  },
});

export const notesMessage = (message: string) => ({
  type: actionTypes.UI_NOTES,
  payload: { message },
});

// eslint-disable-next-line import/prefer-default-export
export const genericMessage = (message: string) => ({
  type: actionTypes.UI_GENERIC_MESSAGE,
  payload: { message },
});
