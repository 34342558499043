import React from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker as UnstyledDatePicker } from '@material-ui/pickers';
import FormLabel from './CustomFormLabel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatePicker = styled(UnstyledDatePicker)`
  margin-top: 4px;
  padding-top: 0;
  &.MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.colours.teals.teal1};
  }
`;

const DatePickerHeaderText = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colours.teals.teal1};
  color: ${({ theme }) => theme.colours.white};
  font: ${({ theme }) => theme.fonts.medium16};

  display: flex;
  justify-content: center;
  align-items: center;
`;
interface Props {
  className?: string;
  label?: string;
  id?: string;
  value: any;
  error?: any;
  helperText?: string;
  clearable?: boolean;
  headerText?: string;
  disablePast?: boolean;
  onChange(x: any): void;
  InputProps?: any;
  dateFormat?: string;
  disableTyping?: boolean;
  disabled?: boolean;
}

const DateInput = ({
  className,
  label,
  id,
  value,
  error,
  helperText,
  clearable,
  headerText,
  disablePast,
  onChange,
  InputProps,
  dateFormat = 'yyyy-MM-dd',
  disableTyping,
  disabled = false,
}: Props) => (
  <Container className={className}>
    <FormLabel error={error}>{label}</FormLabel>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disabled={disabled}
        format={dateFormat}
        margin="normal"
        id={`date-picker-inline-${id}`}
        value={value ?? null}
        error={error}
        helperText={helperText}
        clearable={clearable}
        disablePast={disablePast}
        onChange={onChange}
        InputProps={InputProps}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onKeyDown={e => {
          disableTyping && e.preventDefault();
        }}
        ToolbarComponent={() => <DatePickerHeaderText>{headerText}</DatePickerHeaderText>}
      />
    </MuiPickersUtilsProvider>
  </Container>
);

export default DateInput;
