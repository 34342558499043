import { useReducer, useCallback } from 'react';
import * as R from 'ramda';

const reducer = (state, action) => ({
  ...state,
  [action.name]: action.checked,
});

const initialState = R.compose(
  R.fromPairs,
  R.map(([name, descriptor]) => [name, R.propOr(false, 'initialState', descriptor)]),
  R.toPairs
);

const useGroupFilters = (config, onSave) => {
  const [state, dispatch] = useReducer(reducer, initialState(config));

  const propsForGroup = useCallback(
    name => {
      const onChange = e => {
        dispatch({ name, checked: e.target.checked });
      };

      return {
        checked: state[name],
        onChange,
      };
    },
    [state, dispatch]
  );

  const filteredSave = useCallback(
    values => {
      onSave(
        R.omit(
          R.reduce(
            (acc, [name, descriptor]) => [...acc, ...(state[name] ? [] : R.propOr([], 'fields', descriptor))],
            [],
            R.toPairs(config)
          ),
          values
        )
      );
    },
    [config, onSave, state]
  );

  return {
    propsForGroup,
    filteredSave,
  };
};

export default useGroupFilters;
