import { useMemo } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledActionButton from '../../../../../../../components/ActionButton';
import Grid from '@material-ui/core/Grid';

const ActionButton = styled(UnstyledActionButton)``;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colours.greys.lightGrey9};
  border: ${({ theme }) => theme.borders.thin.solid4};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow1};
  padding: 16px 20px 16px 20px;

  ${ActionButton} {
    margin-left: 20px;
  }
`;

interface Props {
  className?: string;
  record: any;
  onApply(x: any): void;
  onDismiss(): void;
}

const isCHR = (record: any) =>
  record.indicatorShortName === 'comp-functional-failures' || record.indicatorShortName === 'comp-equipment-defects';

const canDismiss = (record: any) => R.isNil(record.applicable) || record.applicable;

const InputPanel = ({ className, record, onApply, onDismiss }: Props) => {
  const isRecordEvaluated = useMemo(() => !R.isNil(record.applicable), [record.applicable]);
  const isRecordApplicable = useMemo(() => Boolean(record.applicable), [record.applicable]);

  return (
    <div>
      {isCHR(record) ? (
        <div>
          <Container className={className}>
            <Grid
              container
              justifyContent="flex-end"
              alignContent="center"
            >
              <Grid item>
                <ActionButton onClick={() => onApply({ onlyComments: true })}>Neither (WITH COMMENTS)</ActionButton>
              </Grid>
              <Grid item>
                <ActionButton onClick={onDismiss}>Neither</ActionButton>
              </Grid>
              <Grid item>
                <ActionButton
                  color="primary"
                  onClick={() => {
                    onApply({ recordType: 'comp-functional-failures' });
                  }}
                >
                  {record.applicable ? 'Update as FF' : 'Apply FF'}
                </ActionButton>
                <ActionButton
                  color="primary"
                  onClick={() => {
                    onApply({ recordType: 'comp-equipment-defects' });
                  }}
                >
                  {record.applicable ? 'Update as ED' : 'Apply ED'}
                </ActionButton>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <Container className={className}>
          {(!isRecordEvaluated || isRecordApplicable) && (
            <>
              <ActionButton
                style={{ whiteSpace: 'nowrap', width: '100%' }}
                onClick={() => {
                  onApply({ onlyComments: true, isActivated: false });
                }}
                disabled={!canDismiss(record)}
              >
                Do Not Apply (WITH COMMENTS)
              </ActionButton>
              <ActionButton
                style={{ whiteSpace: 'nowrap', width: '50%' }}
                onClick={onDismiss}
                disabled={!canDismiss(record)}
              >
                Do Not Apply
              </ActionButton>
            </>
          )}
          <ActionButton
            style={{ whiteSpace: 'nowrap', width: '50%' }}
            color="primary"
            onClick={() =>
              isRecordEvaluated && isRecordApplicable
                ? onApply({ onlyComments: true, isActivated: true, comment: record.applicabilityDescription })
                : onApply({
                    onlyComments: isRecordApplicable,
                    recordType: record.indicatorShortName,
                    isActivated: true,
                    comment: '',
                  })
            }
          >
            {isRecordEvaluated && isRecordApplicable ? 'Update Comments' : 'Apply'}
          </ActionButton>
          {!isRecordApplicable && isRecordEvaluated && (
            <ActionButton
              style={{ whiteSpace: 'nowrap', width: '100%' }}
              color="primary"
              onClick={() => {
                onApply({ onlyComments: true });
              }}
            >
              Update Comments
            </ActionButton>
          )}
        </Container>
      )}
    </div>
  );
};
export default InputPanel;
