import React from 'react';
import styled from 'styled-components';
import SearchField from './SearchField';
import StatusFilter from './StatusFilter';

const Container = styled.div`
  border-bottom: ${({ theme }) => theme.borders.thin.solid2};
  padding: 15px;
  width: 100%;
`;

const SearchFilter = ({
  query,
  onChangeQuery,
  applicableStates,
  onChangeApplicableState,
  records,
  filteredRecords,
}) => (
  <Container>
    <SearchField
      value={query}
      onChange={onChangeQuery}
    />
    <StatusFilter
      records={records}
      filteredRecords={filteredRecords}
      applicableStates={applicableStates}
      onChange={onChangeApplicableState}
    />
  </Container>
);

export default SearchFilter;
