import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';

export const buildFilterObject = (array?: TypeFilterValue): { [key: string]: any } => {
  return (
    array?.reduce((result, item) => {
      if (item.value !== '') {
        result[item.name] = item.value;
      }
      return result;
    }, {} as { [key: string]: any }) ?? {}
  );
};
