import { useDispatch } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import * as R from 'ramda';

import useProgramElement from './program-element';
import Api from '../api';
import * as programActions from '../state/program/actions';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type ProgramCategoryIndicatorColourThresholds = {
  green: ProgramCategoryIndicatorColourThreshold;
  red: ProgramCategoryIndicatorColourThreshold;
  white: ProgramCategoryIndicatorColourThreshold;
  yellow: ProgramCategoryIndicatorColourThreshold;
};
export interface ProgramCategoryIndicator {
  id: number;
  name: string;
  weight: string;
  displayOrder: number;
  colourThresholds: ProgramCategoryIndicatorColourThresholds;
  description: any;
}

export interface ProgramCategoryIndicatorColourThreshold {
  value: any;
  description: any;
}

type AddIndicatorProps = {
  name: string;
  weight: number;
  colourThresholds: ProgramCategoryIndicatorColourThresholds;
  description: string;
};

// NOTE: This gets multiple Program Indicators
const useProgramCategoryIndicator = (elementId: number, categoryId: number) => {
  const { getElementIndicators } = useProgramElement();
  const [indicators, setIndicators] = useState([] as any);
  const [autoCompleteIndicators, setAutoCompleteIndicators] = useState([] as Array<string>);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categoryId) {
      setIndicators([]);
    } else {
      Api.getProgramCategoryIndicators(elementId, categoryId).then(setIndicators);
    }
  }, []);

  useEffect(() => {
    if (!categoryId) {
      setAutoCompleteIndicators([]);
    } else {
      Api.getAutoCompleteIndicators(elementId, categoryId).then(setAutoCompleteIndicators);
    }
  }, [categoryId, setAutoCompleteIndicators]);

  const addIndicator = useCallback(
    async ({ name, weight, colourThresholds, description }: AddIndicatorProps) => {
      const maxValueOfY =
        indicators.length === 0
          ? 0
          : Math.max(...indicators.map((o: any) => (!R.isNil(o.displayOrder) ? o.displayOrder : 0)));
      const res = await Api.addProgramCategoryIndicator(elementId, categoryId, {
        name,
        weight,
        displayOrder: maxValueOfY + 1,
        colourThresholds,
        description,
      });
      setIndicators(res);
      // dispatch
    },
    [indicators]
  );

  const updateIndicator = useCallback(async (indicator: ProgramCategoryIndicator) => {
    try {
      const res = await Api.updateProgramCategoryIndicator(elementId, categoryId, indicator);
      setIndicators(res);
      dispatch(programActions.updateIndicatorSuccess());
    } catch (e) {
      dispatch(programActions.updateIndicatorFailure(e));
    }
    // dispatch
  }, []);

  const removeIndicator = useCallback(async (indicatorId: number) => {
    await Api.removeProgramCategoryIndicator(elementId, categoryId, indicatorId);
    const res = await Api.getProgramCategoryIndicators(elementId, categoryId);
    setIndicators(res);
    getElementIndicators();
  }, []);

  const reorderIndicator = useCallback(
    async (indicator: ProgramCategoryIndicator, destinationIndex: number) => {
      const indexOfOldIndicator = indicators.findIndex((i: ProgramCategoryIndicator) => i.id === indicator.id);
      const updatedIndicators: any = R.insert(
        destinationIndex,
        R.nth(indexOfOldIndicator, indicators),
        R.remove(indexOfOldIndicator, 1, indicators)
      );

      const updatedIndicatorsWithDisplayOrder = updatedIndicators.map((i: ProgramCategoryIndicator, idx: number) => ({
        ...i,
        displayOrder: idx + 1,
      }));

      setIndicators(updatedIndicatorsWithDisplayOrder);

      updatedIndicatorsWithDisplayOrder.forEach((i: ProgramCategoryIndicator) => {
        Api.reorderProgramCategoryIndicator(elementId, categoryId, i);
      });
    },
    [indicators, setIndicators]
  );

  return {
    indicators,
    removeIndicator,
    addIndicator,
    updateIndicator,
    reorderIndicator,
    autoCompleteIndicators,
  };
};

export default useProgramCategoryIndicator;
