import * as R from 'ramda';
import * as actionTypes from '../action-types';

const initialState = {
  all: [],
  byType: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_REQUEST: {
      const { type } = action.payload;
      return {
        ...state,
        byType: {
          ...state.byType,
          [type]: {
            ...R.propOr({}, type, state.byType),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_SUCCESS: {
      const { type, indicators } = action.payload;
      return {
        ...state,
        byType: {
          ...state.byType,
          [type]: {
            records: indicators,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_FAILURE: {
      const { error, type } = action.payload;
      return {
        ...state,
        byType: {
          ...state.byType,
          [type]: {
            ...R.propOr({}, type, state.byType),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.INDICATORS_LOAD_ALL_REQUEST: {
      return {
        ...state,
        all: {
          ...state.all,
          isLoading: true,
        },
      };
    }
    case actionTypes.INDICATORS_LOAD_ALL_SUCCESS: {
      const { indicators } = action.payload;
      return {
        ...state,
        all: {
          records: indicators,
          isLoading: false,
        },
      };
    }
    case actionTypes.INDICATORS_LOAD_ALL_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        all: {
          ...state.all,
          isLoading: false,
          error,
        },
      };
    }
    default:
      return state;
  }
};
