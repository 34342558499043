import qs from 'qs';
import { CSSProperties } from 'react';

interface PowerBiReportProps {
  reportId: string;
  title: string;
  pageName?: string;
  filter?: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  showActionBar?: boolean;
}

const PowerBiReport: React.FC<PowerBiReportProps> = ({
  pageName,
  reportId,
  title,
  width,
  height,
  filter,
  showActionBar = false,
}) => {
  const defaultWidth = '100%';
  const defaultHeight = 'max(calc(100vh - 200px), 80vh)';

  const reportParams = qs.stringify({
    reportId,
    pageName,
    filter,
    autoAuth: true,
    actionBarEnabled: showActionBar,
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
  });

  return (
    <div
      style={{
        width: width || defaultWidth,
        height: height || defaultHeight,
        maxWidth: defaultWidth,
        maxHeight: defaultHeight,
      }}
    >
      <iframe
        title={title}
        src={`https://app.powerbi.com/reportEmbed?${reportParams}`}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

export default PowerBiReport;
