import React, { useState, useEffect, useContext, useMemo, createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import Api from '../api';
import * as actions from '../state/indicators/actions';
import { Indicator, ElementIndicator } from '../types/element-indicators';

export type ElmentIndicatorsContext = {
  elementIndicators: ElementIndicator[];
  elementIndicatorsById: Record<number, ElementIndicator>;
  onAddIndicator: (indicator: Indicator) => Promise<void>;
  onRemoveIndicator: (indicatorId: string | number) => Promise<void>;
  onUpdateIndicator: (indicatorId: string | number, weight: number) => Promise<void>;
};
const ElementIndicatorsContext = createContext(null);

const useElementIndicators = (): ElmentIndicatorsContext => {
  const context = useContext(ElementIndicatorsContext);
  if (R.isNil(context)) {
    throw new Error('useElementIndicators must be used inside an ElementIndicatorsProvider');
  }
  return context;
};

export const ElementIndicatorsProvider = ({ elementId, ...props }: any) => {
  const [elementIndicators, setElementIndicators] = useState<Array<any>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.getElementIndicators(elementId).then(setElementIndicators);
  }, [elementId, setElementIndicators]);

  const elementIndicatorsById = useMemo(
    () =>
      R.reduce(
        (acc, elementIndicator) => ({
          ...acc,
          [elementIndicator.indicatorId]: elementIndicator,
        }),
        {},
        elementIndicators
      ),
    [elementIndicators]
  );

  const onAddIndicator = useCallback(
    async (indicator: Indicator) => {
      try {
        // if indicator has no id/ add and then associate
        // if indicator has id. assocaite
        if (!R.isNil(indicator.id)) {
          await Api.addElementIndicator(elementId, indicator.id, 0);
          const res = await Api.getElementIndicators(elementId);
          setElementIndicators(res);
        } else {
          const { id } = await Api.createIndicator('component', indicator.name);
          await Api.addElementIndicator(elementId, id, 0);
          const res = await Api.getElementIndicators(elementId);
          setElementIndicators(res);
        }
        dispatch(actions.addIndicatorSuccess());
      } catch (e) {
        dispatch(actions.addIndicatorFailure(e));
      }
    },
    [dispatch, elementId]
  );

  const onRemoveIndicator = useCallback(
    async (indicatorId: any) => {
      try {
        await Api.removeElementIndicator(elementId, indicatorId);
        const res = await Api.getElementIndicators(elementId);
        setElementIndicators(res);
        dispatch(actions.removeIndicatorSuccess());
      } catch (e) {
        dispatch(actions.removeIndicatorFailure(e));
      }
    },
    [dispatch, elementId]
  );

  const onUpdateIndicator = useCallback(
    async (indicatorId: number, weight: string) => {
      try {
        await Api.updateElementIndicator(elementId, indicatorId, weight);
        const res = await Api.getElementIndicators(elementId);
        setElementIndicators(res);
        dispatch(actions.updateIndicatorSuccess());
      } catch (e) {
        dispatch(actions.updateIndicatorFailure(e));
      }
    },
    [dispatch, elementId]
  );

  const value = {
    elementIndicators,
    elementIndicatorsById,
    onAddIndicator,
    onUpdateIndicator,
    onRemoveIndicator,
  };

  return (
    <ElementIndicatorsContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useElementIndicators;
