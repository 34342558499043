import * as actionTypes from '../../../state/action-types';

const initialState = {
  currentPage: '',
  activeStep: 0,
  recordsSummary: {},
  attachments: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.HEALTH_REPORT_SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;
      return {
        ...state,
        currentPage,
      };
    }
    case actionTypes.HEALTH_REPORT_SET_ACTIVE_STEP: {
      const { activeStep } = action.payload;
      return {
        ...state,
        activeStep,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
