import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as uiActions from '../state/ui/actions';
import * as uiSelectors from '../state/ui/selectors';

interface ActiveMessage {
  message?: string;
  errorMessage?: string;
  key: number;
}

const useUiMessages = () => {
  const dispatch = useDispatch();
  const messages = useSelector(uiSelectors.getMessages);
  const isLoading = useSelector(uiSelectors.getLoadingStatus);
  const [activeMessage, setActiveMessage] = useState<ActiveMessage | null>(null);

  useEffect(() => {
    if (!R.isEmpty(messages)) {
      if (R.isNil(activeMessage)) {
        setActiveMessage({
          ...(R.head(messages) as any),
          key: new Date().getTime(),
        });
        dispatch(uiActions.popMessage());
      } else {
        setActiveMessage(null);
      }
    }
  }, [messages, activeMessage, setActiveMessage, dispatch]);

  const handleClose = useCallback(() => {
    setActiveMessage(null);
  }, [setActiveMessage]);

  return {
    isLoading,
    activeMessage,
    handleClose,
  };
};

export default useUiMessages;
