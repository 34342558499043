import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Hold } from '../../../types/cwt/supplychain';
import { useWorkOrders } from './useWorkOrders';
interface useSupplyChainValue {
  holds: Hold[];
  loading: boolean;
}

interface Props {
  children: ReactNode;
}
const SupplyChainContext = createContext<useSupplyChainValue | undefined>(undefined);

export const SupplyChainContextProvider = ({ children }: Props) => {
  const [holds, setHolds] = useState<Hold[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedWorkorderId } = useWorkOrders();
  useEffect(() => {
    const getSCHolds = async () => {
      if (!selectedWorkorderId) return;
      const res = await Api.cwt.getWorkorderSupplyChainHolds(selectedWorkorderId);
      setHolds(res);
      setLoading(false);
    };

    if (loading) {
      void getSCHolds();
    }
  }, [loading]);
  return (
    <SupplyChainContext.Provider
      value={{
        holds,
        loading,
      }}
    >
      {children}
    </SupplyChainContext.Provider>
  );
};

export const useSupplyChain = () => {
  const context = useContext(SupplyChainContext);
  if (context === undefined) {
    throw new Error('useSupplyChain must be used within a SupplyChainContextProvider');
  }
  return context;
};
