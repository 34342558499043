import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import Api from '../../api';
import { Report } from '../../hooks/report';
import UnstyledLoadableContainer from '../LoadingContainer';

const ErrorText = styled(Typography)`
  margin-top: 50px;
`;

const PdfViewer = styled.iframe`
  height: 100%;
  width: 100%;
`;

const LoadingContainer = styled(UnstyledLoadableContainer)`
  display: flex;
  height: calc(100vh - 200px);
  overflow-y: auto;
`;

const ScorecardPdfViewer = ({ report }: { report: Report }) => {
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const downloadReport = async () => {
      try {
        const reportBlob = await Api.downloadReport(`${report.id}`);
        const url = window.URL.createObjectURL(reportBlob);
        setUrl(url);
      } catch (e) {
        console.error('Error downloading report', e);
      }
      setLoading(false);
    };

    void downloadReport();
  }, [report.id]);

  return (
    <LoadingContainer loading={loading}>
      {url ? (
        <PdfViewer
          src={url}
          title="scorecardPdf"
        />
      ) : (
        <ErrorText>{'Error loading pdf. Please try again later.'}</ErrorText>
      )}
    </LoadingContainer>
  );
};

export default ScorecardPdfViewer;
