import { Tab as UnstyledTab } from '@material-ui/core';
import styled from 'styled-components';

const Tab = styled(UnstyledTab)`
  border: 1px solid #dad8d8;
  border-radius: 2px;
  margin-right: 3px;
  color: #4a4a4a;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  min-height: 29px;

  &.Mui-selected {
    background-color: #e1e0e0;
  }
`;

export default Tab;
