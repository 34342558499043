import React from 'react';
import styled from 'styled-components';
import CloseButton from './CloseButton';
import SaveButton from './SaveButton';

const TitleText = styled.div`
  font: ${({ theme }) => theme.fonts.bold16};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  ${TitleText} {
    margin-left: 26px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;

  background-color: ${({ theme }) => theme.colours.teals.teal1};
  color: ${({ theme }) => theme.colours.white};
`;

interface Props {
  hideSave?: boolean;
  disabled?: boolean;
  onSave?(): void;
  onCancel?(): void;
  children: any;
}

const DialogTitle = ({ hideSave, disabled, onSave, onCancel, children }: Props) => (
  <Container>
    <LeftContainer>
      <CloseButton onClick={onCancel} />
      <TitleText>{children}</TitleText>
    </LeftContainer>
    {!hideSave && (
      <SaveButton
        disabled={disabled}
        onClick={onSave}
      />
    )}
  </Container>
);

export default DialogTitle;
