import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import LandingPage from './pages/landing-page/LandingPage';
import ReportPreviewPage from './pages/report-preview/ReportPreviewPage';
import Page from '../../../components/Page';

const ReportReviewPage = ({ match }: any) => (
  <Page
    title="Report Review"
    bodyComponent={<Paper square />}
  >
    <Switch>
      <Route
        path={`${match.path}/`}
        exact
      >
        <LandingPage />
      </Route>
      <Route
        path={`${match.path}/:reportId`}
        exact
      >
        <ReportPreviewPage />
      </Route>
    </Switch>
  </Page>
);

export default ReportReviewPage;
