import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';

const CarlaText = styled(props => <Typography {...R.omit(['font', 'colourPath', 'italic', 'upperCase'], props)} />)`
  font: ${({ theme, font }) => (R.isNil(font) ? 'inherit' : R.pathOr(theme.fonts.normal14, ['fonts', font], theme))};
  color: ${({ theme, colourPath }) =>
    R.isNil(colourPath) ? 'inherit' : R.pathOr('black', ['colours', ...R.split('.', colourPath)], theme)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-transform: ${({ upperCase = false }) => (upperCase ? 'uppercase' : 'inherit')};
`;

export default CarlaText;
