import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as executiveSummaryUiActions from '../../../../../state/executive-summary-ui/actions';
import * as reportsActions from '../../../../../state/reports/actions';

const useExecutiveSummary = reportId => {
  const dispatch = useDispatch();

  const onEditExecutiveSummary = useCallback(() => {
    dispatch(executiveSummaryUiActions.editExecutiveSummary());
  }, [dispatch]);

  const onSaveExecutiveSummary = useCallback(
    executiveSummary => {
      const executiveSummaryUpdatedAt = new Date().toLocaleString();
      dispatch(reportsActions.updateExecutiveSummaryRequest(reportId, executiveSummary, executiveSummaryUpdatedAt));
    },
    [dispatch, reportId]
  );

  const onFinalizeExecutiveSummary = useCallback(
    executiveSummary => {
      const executiveSummaryUpdatedAt = new Date().toLocaleString();
      dispatch(reportsActions.finalizeExecutiveSummaryRequest(reportId, executiveSummary, executiveSummaryUpdatedAt));
    },
    [dispatch, reportId]
  );

  return {
    onEditExecutiveSummary,
    onSaveExecutiveSummary,
    onFinalizeExecutiveSummary,
  };
};

export default useExecutiveSummary;
