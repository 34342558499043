import React from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import useConditionalConfirm from '../../../../hooks/conditional-confirm';
import CustomRadioGroup from '../../../../components/CustomRadioGroup';
import Select from '../../../../components/Select';
import useForm from '../../../../hooks/form';
import useUnits from '../../../../hooks/units';

const formConfig = {
  mappingType: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a Type',
      },
    },
  },
  elementUnit: {},
  location: {},
  unitNumber: {},
  usi: {},
};

const initialValues = elementUnits => ({
  mappingType: 'location',
  elementUnit: R.head(elementUnits).value,
  location: null,
  unitNumber: null,
  usi: null,
});

const MappingForm = ({ elementUnits, onSave, onCancel }) => {
  const { values, submit, isDirty, propsForField } = useForm(formConfig, initialValues(elementUnits), onSave);
  const { confirming, confirm, onConfirm, onCancel: onCancelConfirm } = useConditionalConfirm(onCancel, isDirty);
  const { units } = useUnits();

  return (
    <Grid
      container
      component="fieldset"
      spacing={2}
    >
      <legend>Add Mapping</legend>
      <Grid
        item
        xs={12}
      >
        <CustomRadioGroup
          options={[
            { value: 'location', label: 'Location' },
            { value: 'unit', label: 'Unit/USI' },
          ]}
          {...propsForField('mappingType')}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
      >
        <Grid
          item
          xs={4}
        >
          <Select
            label="Element Unit"
            fullWidth
            options={elementUnits.map(elUnits => ({
              value: elUnits.elementUnit,
              label: elUnits.elementUnit,
            }))}
            {...propsForField('elementUnit')}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={8}
        >
          {values.mappingType === 'location' && (
            <Grid
              item
              xs={6}
              style={{ paddingTop: '4%' }}
            >
              <FormControl
                component="fieldset"
                fullWidth
              >
                <TextField
                  label="Location"
                  fullWidth
                  type="string"
                  {...propsForField('location')}
                />
              </FormControl>
            </Grid>
          )}
          {values.mappingType === 'unit' && (
            <Grid
              item
              xs={6}
            >
              <Select
                label="Unit Number"
                fullWidth
                options={units.map(elUnits => ({
                  value: elUnits.id,
                  label: elUnits.name,
                }))}
                {...propsForField('unitNumber')}
              />
            </Grid>
          )}
          <Grid
            item
            xs={6}
            style={{ paddingTop: '4%' }}
          >
            <FormControl component="fieldset">
              <TextField
                label="USI"
                fullWidth
                type="string"
                {...propsForField('usi')}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <IconButton onClick={submit}>
          <SaveIcon />
        </IconButton>
        <IconButton onClick={confirm}>
          <CancelIcon />
        </IconButton>
      </Grid>
      {confirming && (
        <OkayCancelDialog
          onOkay={onConfirm}
          onCancel={onCancelConfirm}
        >
          Are you sure you want to cancel? All changes will be lost.
        </OkayCancelDialog>
      )}
    </Grid>
  );
};

export default MappingForm;
