/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import UnstyledAttachmentName from './AttachmentName';
import useReadOnly from '../../../../hooks/read-only';

const AttachmentName = styled(UnstyledAttachmentName)``;

const Actions = styled.div`
  display: flex;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;

  ${AttachmentName} {
    width: 385px;
    margin-right: 20px;
  }
`;

interface Props {
  className?: string;
  attachment: any;
  onDelete?: any;
  onDownload?: any;
}

const Attachment = ({ className, attachment, onDelete, onDownload }: Props) => {
  const readOnly = useReadOnly();
  return (
    <Container className={className}>
      <AttachmentName>{attachment.name}</AttachmentName>
      <Details>
        <>
          <Actions>
            {!readOnly && (
              <IconButton
                size="small"
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={onDownload}
            >
              <GetAppIcon />
            </IconButton>
          </Actions>
        </>
      </Details>
    </Container>
  );
};

export default Attachment;
