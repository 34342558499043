import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import EcDetails from './EcDetails';

const Container = styled.div``;

const ModificationBacklogDetails = ({ className, record }) => (
  <Container className={className}>
    <Box p={5}>
      <EcDetails record={record} />
    </Box>
  </Container>
);

export default ModificationBacklogDetails;
