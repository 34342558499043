import { call } from 'redux-saga/effects';
import Api from '../../../../../api';

function* loadWorkRequest(workRequestNumber) {
  if (workRequestNumber) {
    return yield call(Api.getWorkRequest, workRequestNumber);
  }
  return null;
}

function* loadWorkOrderAndTasks(workOrderNumber) {
  if (workOrderNumber) {
    const workOrder = yield call(Api.getWorkOrder, workOrderNumber);
    const tasks = yield call(Api.getWorkOrderTasks, workOrderNumber);
    return {
      ...workOrder,
      tasks,
    };
  }
  return null;
}

function* loadPm(pmNumber) {
  if (pmNumber) {
    return yield call(Api.getPm, pmNumber);
  }
  return null;
}

function* loadTccRecord(tccId) {
  if (tccId) {
    return yield call(Api.getTccRecord, tccId);
  }
  return null;
}

function* loadConditionReport(record) {
  const { crNumber } = record;
  if (crNumber) {
    const conditionReport = yield call(Api.getConditionReport, crNumber);
    const conditionReportAssignments = yield call(Api.getConditionReportAssignments, crNumber);
    return {
      conditionReport: {
        ...conditionReport,
        assignments: conditionReportAssignments,
      },
    };
  }
  return null;
}

function* loadJustWorkOrderAndTasks(record) {
  const { workOrderNumber } = record;
  const workOrder = yield loadWorkOrderAndTasks(workOrderNumber);
  return {
    workOrder,
  };
}

function* loadJustTccRecord(record) {
  const { tccId } = record;
  const tccRecord = yield loadTccRecord(tccId);
  return {
    tccRecord,
  };
}

function* loadFunctionalFailureDetails(record) {
  const { workRequestNumber, workOrderNumber } = record;
  const workRequest = yield loadWorkRequest(workRequestNumber);
  const workOrder = yield loadWorkOrderAndTasks(workOrderNumber);

  return {
    workRequest,
    workOrder,
  };
}

const loadOutstandingCorrectiveActionDetails = loadConditionReport;

const loadOnlineDeficientMaintenanceBacklogDetails = loadJustWorkOrderAndTasks;

const loadOnlineCorrectiveMaintenanceBacklogDetails = loadJustWorkOrderAndTasks;

const loadShutdownDeficientMaintenanceBacklogDetails = loadJustWorkOrderAndTasks;

const loadShutdownCorrectiveMaintenanceBacklogDetails = loadJustWorkOrderAndTasks;

function* loadPredefineDetails(record) {
  const { pmNumber, workOrderNumber } = record;
  const pm = yield loadPm(pmNumber);
  const workOrder = yield loadWorkOrderAndTasks(workOrderNumber);
  return {
    pm,
    workOrder,
  };
}

const loadRegdocReportableEventDetails = loadConditionReport;

const loadOperatorWorkaroundDetails = loadJustWorkOrderAndTasks;

const loadOperatorBurdenDetails = loadJustWorkOrderAndTasks;

const loadTmodsBeyond6MonthsDetails = loadJustTccRecord;

const loadTmodsBeyond90DaysDetails = loadJustTccRecord;

const loadModificationBacklogDetails = loadJustWorkOrderAndTasks;

const loadToeCorrectiveActionDetails = loadConditionReport;

// Loading the newly added indicator details

const loadErClockResetDetails = loadConditionReport;

const loadEquipmentDefectsDetails = loadFunctionalFailureDetails;

const loadMaintenanceFeedackBacklogDetails = loadJustWorkOrderAndTasks;

const indicatorDetailsLoaders = {
  'functional-failures': loadFunctionalFailureDetails,
  'outstanding-corrective-actions': loadOutstandingCorrectiveActionDetails,
  'online-deficient-maintenance-backlog': loadOnlineDeficientMaintenanceBacklogDetails,
  'online-corrective-maintenance-backlog': loadOnlineCorrectiveMaintenanceBacklogDetails,
  'shutdown-deficient-maintenance-backlog': loadShutdownDeficientMaintenanceBacklogDetails,
  'shutdown-corrective-maintenance-backlog': loadShutdownCorrectiveMaintenanceBacklogDetails,
  predefines: loadPredefineDetails,
  'regdoc-reportable-events': loadRegdocReportableEventDetails,
  'operator-workarounds': loadOperatorWorkaroundDetails,
  'operator-burdens': loadOperatorBurdenDetails,
  'tmods-beyond-6-months': loadTmodsBeyond6MonthsDetails,
  'tmods-beyond-90-days': loadTmodsBeyond90DaysDetails,
  'modification-backlog': loadModificationBacklogDetails,
  'toe-corrective-actions': loadToeCorrectiveActionDetails,
  'comp-er-clock-resets': loadErClockResetDetails,
  'comp-equipment-defects': loadEquipmentDefectsDetails,
  'comp-maintenance-feedback-backlog': loadMaintenanceFeedackBacklogDetails,
  'comp-functional-failures': loadFunctionalFailureDetails,
  'comp-outstanding-corrective-actions': loadOutstandingCorrectiveActionDetails,
  'comp-predefines': loadPredefineDetails,
  'comp-online-deficient-maintenance-backlog': loadOnlineDeficientMaintenanceBacklogDetails,
  'comp-online-corrective-maintenance-backlog': loadOnlineCorrectiveMaintenanceBacklogDetails,
  'comp-shutdown-corrective-maintenance-backlog': loadShutdownCorrectiveMaintenanceBacklogDetails,
  'comp-shutdown-deficient-maintenance-backlog': loadShutdownDeficientMaintenanceBacklogDetails,
};

const indicatorSpecificLoad = (indicatorShortName, record) => indicatorDetailsLoaders[indicatorShortName](record);

export default indicatorSpecificLoad;
