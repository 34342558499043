import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { User } from '../../../types/cwt/user';
import { getUserId } from '../../../auth/utils';
interface useUsersValue {
  users: User[];
  loadingUsers: boolean;
  getUser: (id: string) => User | undefined;
  userSite: string | null | undefined;
  setUserSite: (site: string | null) => void;
}

interface Props {
  children: ReactNode;
}
const UsersContext = createContext<useUsersValue | undefined>(undefined);

export const UsersContextProvider = ({ children }: Props) => {
  const [userMap, setUserMap] = useState<Map<string, User>>(new Map());
  const [userSite, setUserSite] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

  const userId = getUserId();

  const handleSetUsers = (users: User[]) => {
    const userMap = new Map<string, User>();
    users.forEach(user => {
      userMap.set(user.id, user);
    });
    setUserMap(userMap);
    setUsers(users);
    setUserSite((userId && userMap.get(userId)?.facilityId) || null);
  };

  useEffect(() => {
    const getAllUsers = async () => {
      const res = await Api.cwt.getAllUsers();
      handleSetUsers(res);
      setLoadingUsers(false);
    };

    if (loadingUsers) {
      getAllUsers();
    }
  }, [loadingUsers]);

  const getUser = (id: string) => {
    return userMap.get(id);
  };

  return (
    <UsersContext.Provider value={{ users, loadingUsers, getUser, userSite, setUserSite }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within a UsersContextProvider');
  }
  return context;
};
