import { useState, useCallback } from 'react';
import * as R from 'ramda';
import useIndicatorQueryFields from './indicator-query-fields';

const stateMatches = R.curry((applicableStates, record) => {
  if (R.isNil(record.applicable) && R.includes('not-evaluated', applicableStates)) {
    return true;
  }
  if (record.applicable && R.includes('applicable', applicableStates)) {
    return true;
  }
  if (!R.isNil(record.applicable) && !record.applicable && R.includes('inapplicable', applicableStates)) {
    return true;
  }
  return false;
});

const textMatches = (indicatorQueryFields, query) => record =>
  R.reduce(
    (acc, fieldName) => acc || R.includes(query.toLowerCase(), R.propOr('', fieldName, record).toLowerCase()),
    false,
    indicatorQueryFields
  );

const useApplicableStates = records => {
  const [applicableStates, setApplicableStates] = useState([]);

  const setApplicableState = useCallback(
    (state, value) => {
      if (value) {
        setApplicableStates(R.append(state, applicableStates));
      } else {
        setApplicableStates(R.without([state], applicableStates));
      }
    },
    [applicableStates, setApplicableStates]
  );

  const filteredRecords = R.filter(
    R.isEmpty(applicableStates) ? R.always(true) : stateMatches(applicableStates),
    records
  );

  return {
    filteredRecords,
    applicableStates,
    setApplicableState,
  };
};

const useTextFilter = (indicatorId, records) => {
  const [query, setQuery] = useState('');

  const indicatorQueryFields = useIndicatorQueryFields(indicatorId);

  const filteredRecords = R.filter(textMatches(indicatorQueryFields, query), records);

  return {
    filteredRecords,
    query,
    setQuery,
  };
};

const useFilteredRecords = (indicatorId, records) => {
  const { filteredRecords: applicableRecords, applicableStates, setApplicableState } = useApplicableStates(records);
  const { filteredRecords, query, setQuery } = useTextFilter(indicatorId, applicableRecords);

  return {
    filteredRecords,
    query,
    applicableStates,
    setQuery,
    setApplicableState,
  };
};

export default useFilteredRecords;
