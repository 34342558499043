import React, { createContext, useContext } from 'react';
import * as R from 'ramda';

const ActionContext = createContext();

const useAction = () => {
  const action = useContext(ActionContext);

  if (R.isNil(action)) {
    throw new Error('useAction must be used inside ActionProvider');
  }

  return action;
};

export const ActionProvider = ({ action, ...props }) => (
  <ActionContext.Provider
    value={action}
    {...props}
  />
);

export default useAction;
