import React from 'react';
import styled from 'styled-components';
import ActionButton from '../../../components/ActionButton';

const Container = styled.div``;

const OpenDashboard = ({ className, dashboard }) => (
  <Container className={className}>
    <ActionButton
      component="a"
      href={dashboard.dashboardUrl}
      target="_blank"
    >
      Open
    </ActionButton>
  </Container>
);

export default OpenDashboard;
