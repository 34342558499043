import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styled from 'styled-components';
import ActionButton from '../../../../components/ActionButton';
import CustomFormLabel from '../../../../components/CustomFormLabel';
import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlTextField } from '../../../../form/ControlTextField';
import useWorkRequestCodingForm from '../hooks/useWorkRequestCodingForm';
import { WorkRequestCoding } from '../types';
import CodingTable from './CodingTable';
import { buildInitialValues } from '../utils/buildInitialValues';
import { WorkRequest } from '../../../../types/cwt/workrequest';
import CustomIconButton from './CustomIconButton';
import { WorkRequestDetails } from './WorkRequestDetails';
import { useWorkRequestNavigation } from '../hooks/useWorkRequestNavigation';
import { useHistory } from 'react-router-dom';

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

// styled-components doesn't preserve generic props, so explicitly cast with generic type to resolve errors
// Source: https://github.com/styled-components/styled-components/issues/1803#issuecomment-550895376
const FullHeightTextField = styled(ControlTextField)`
  height: 100%;

  .MuiFormControl-root,
  .MuiInputBase-root {
    height: 100%;
  }

  .MuiInputBase-input {
    align-self: flex-start;
    overflow-y: scroll !important;
    height: 100% !important;
  }
` as typeof ControlTextField;

interface WorkRequestScreeningFormProps {
  workRequest: WorkRequest;
  coding: WorkRequestCoding | undefined;
  refetch: () => void;
}

export const WorkRequestScreeningForm: React.FC<WorkRequestScreeningFormProps> = ({ coding, workRequest, refetch }) => {
  const {
    control,
    handleGenerateCodings,
    codings,
    errors,
    handleAddRow,
    handleBlurRow,
    validateCategoryValue,
    handleSubmit,
    fieldsDisabled,
    screeningStatusOptions,
    watch,
  } = useWorkRequestCodingForm({
    workRequestNumber: workRequest.workRequestNumber,
    initialValues: buildInitialValues(coding),
    refetchCoding: refetch,
  });

  const { push } = useHistory();

  const { previousWorkRequestNumber, nextWorkRequestNumber } = useWorkRequestNavigation({
    screeningStatus: coding?.screeningStatus,
    workRequestNumber: workRequest.workRequestNumber,
  });

  const navigateTo = (workRequestNumber: string | undefined) => {
    if (workRequestNumber) {
      push(`/cwt/wr/${workRequestNumber}/screening`);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          xs={11}
          justifyContent="flex-start"
        >
          <WorkRequestDetails workRequest={workRequest} />
          <Grid
            item
            container
            alignItems="center"
            spacing={6}
            wrap="nowrap"
          >
            <Grid item>
              <CustomFormLabel error={!!errors?.screeningStatus}>Screening Status:</CustomFormLabel>
            </Grid>
            <Grid item>
              <ControlRadio
                control={control}
                label=""
                name="screeningStatus"
                options={screeningStatusOptions}
                error={!!errors?.screeningStatus}
                required
                row
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={1}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <ActionButton
            color="primary"
            variant="text"
            style={{ height: '3em' }}
            onClick={handleSubmit}
          >
            Save
          </ActionButton>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ marginTop: '4px', height: '38.5%', alignItems: 'center', position: 'relative' }}
        wrap="nowrap"
      >
        <CustomIconButton
          aria-label="Go to previous work request"
          style={{ position: 'absolute', left: '-42px' }}
          disabled={!previousWorkRequestNumber}
          onClick={() => navigateTo(previousWorkRequestNumber)}
        >
          <ArrowBackIcon />
        </CustomIconButton>
        <FullHeightGrid
          item
          xs={4}
        >
          <FullHeightTextField
            control={control}
            name="proposedCoding"
            label="Proposed Coding"
            required={false}
            error={!!errors?.proposedCoding}
            minLength={1}
            maxLength={1000}
            multiline
            minRows={4}
            disabled={fieldsDisabled}
          />
        </FullHeightGrid>
        <FullHeightGrid
          item
          xs={5}
        >
          <CodingTable
            codings={codings}
            control={control}
            errors={errors}
            handleBlurRow={handleBlurRow}
            validateCategoryValue={validateCategoryValue}
            disabled={fieldsDisabled}
            watch={watch}
          />
        </FullHeightGrid>
        <FullHeightGrid
          item
          xs={3}
        >
          <FullHeightTextField
            control={control}
            name="codingNotes"
            label="Coding Notes"
            required={false}
            error={!!errors?.codingNotes}
            minLength={1}
            maxLength={1000}
            multiline
            minRows={4}
            disabled={fieldsDisabled}
          />
        </FullHeightGrid>
        <CustomIconButton
          aria-label="Go to next work request"
          style={{ position: 'absolute', right: '-42px' }}
          disabled={!nextWorkRequestNumber}
          onClick={() => navigateTo(nextWorkRequestNumber)}
        >
          <ArrowForwardIcon />
        </CustomIconButton>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ marginTop: '8px', marginBottom: '16px' }}
      >
        <Grid
          container
          item
          xs={4}
          justifyContent="flex-end"
        >
          <ActionButton
            color="primary"
            variant="text"
            onClick={handleGenerateCodings}
            disabled={fieldsDisabled}
          >
            Generate Coding
          </ActionButton>
        </Grid>
        <Grid
          container
          item
          xs={5}
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomIconButton
            onClick={handleAddRow}
            aria-label="Add new coding"
            disabled={fieldsDisabled}
          >
            <AddIcon />
          </CustomIconButton>
        </Grid>
      </Grid>
    </>
  );
};
