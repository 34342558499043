import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as actionActions from '../state/actions/actions';

const useActionStatus = (actions: any) => {
  const dispatch = useDispatch();
  const [statusAction, setStatusAction] = useState<any>(null);
  const [deleteAction, setDeleteAction] = useState<any>(null);

  const confirmActionStatusToggle = useCallback(
    (actionId: any) => {
      setStatusAction(R.find((action: any) => action.id === actionId, actions));
    },
    [actions, setStatusAction]
  );

  const confirmDeleteActionToggle = useCallback(
    (actionId: any) => {
      setDeleteAction(R.find((_action: any) => _action.id === actionId, actions));
    },
    [actions]
  );

  const clearStatusAction = useCallback(() => {
    setStatusAction(null);
  }, [setStatusAction]);

  const clearDeleteAction = useCallback(() => {
    setDeleteAction(null);
  }, []);

  const toggleActionStatus = useCallback(
    (comment: string) => {
      dispatch(
        actionActions.updateActionStatusRequest(
          statusAction.id,
          comment,
          statusAction.state === 'complete' ? 'ongoing' : 'complete',
          statusAction.elementId
        )
      );
      clearStatusAction();
    },
    [dispatch, statusAction, clearStatusAction]
  );

  const deleteActionStatus = useCallback(() => {
    dispatch(actionActions.updateActionStatusRequest(deleteAction.id, '', 'delete', deleteAction.elementId));
    setDeleteAction(null);
  }, [dispatch, deleteAction]);

  return {
    deleteAction,
    confirmDeleteActionToggle,
    deleteActionStatus,
    setDeleteAction,
    clearDeleteAction,
    statusAction,
    confirmActionStatusToggle,
    clearStatusAction,
    toggleActionStatus,
  };
};

export default useActionStatus;
