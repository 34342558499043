import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import UnstyledCard from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import UnstyledPage from '../../../../components/Page';
import useUsers from '../../../../hooks/users';
import useQuery from '../../../../hooks/query';
import UserList from './UserList';
import User from './User';

const Card = styled(UnstyledCard)``;

const Page = styled(UnstyledPage)`
  ${Card} {
    margin: 0;
    background-color: ${({ theme }) => theme.colours.white};
    box-shadow: ${({ theme }) => theme.shadows.boxShadow3};
  }
`;

const UsersPage = () => {
  const history = useHistory();
  const { userId } = useQuery();
  const { users } = useUsers();
  const selectedUser = useMemo(() => {
    if (R.isEmpty(users) || R.isNil(userId)) {
      return undefined;
    }

    return R.find(R.propEq('id', userId), users);
  }, [users, userId]);

  const handleSelectedUser = useCallback(
    newUserId => {
      history.replace(`/app/admin/users?userId=${newUserId}`);
    },
    [history]
  );

  return (
    <Page title="Admin - Users">
      <Card>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <UserList
              users={users}
              selectedUserId={userId}
              onSelectUserId={handleSelectedUser}
            />
          </Grid>
          <Grid
            item
            xs={9}
          >
            {selectedUser && <User user={selectedUser} />}
          </Grid>
        </Grid>
      </Card>
    </Page>
  );
};

export default UsersPage;
