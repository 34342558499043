import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useReadOnly from '../../../hooks/read-only';

interface Props {
  label: string;
  options: Array<{ value: string | number; label: string | number }>;
  defaultValue?: string;
  onChange(x: string | null): void;
  variant?: 'standard' | 'filled' | 'outlined';
  style?: React.CSSProperties;
  value?: any;
  error?: boolean;
}

const Dropdown = ({
  label,
  options,
  defaultValue = '',
  onChange,
  variant = 'standard',
  style,
  value,
  error,
}: Props) => {
  const [type, setType] = useState<string | number | null>(defaultValue || options[0]?.value || '');
  const handleChange = (_event: any, value: any) => {
    if (!value) {
      setType(null);
      onChange(null);
      return;
    }
    setType(value?.value);
    onChange(value?.value);
  };
  const readOnly = useReadOnly();

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        options={options}
        disabled={readOnly}
        getOptionLabel={option => option.label.toString()}
        getOptionSelected={(option, value) => option?.value === value?.value}
        value={options.find(option => option?.value === value || option?.value === type)}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant={variant || 'standard'}
            style={style}
            error={error}
          />
        )}
      />
    </FormControl>
  );
};

export default Dropdown;
