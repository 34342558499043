import * as R from 'ramda';

const indicatorSpecifics = {
  'functional-failures': record => [
    `WO ${R.prop('workOrderNumber', record)}`,
    `WR ${R.prop('workRequestNumber', record)}`,
  ],
  'outstanding-corrective-actions': record => [`CR ${R.prop('crNumber', record)}`],
  'online-deficient-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'shutdown-deficient-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'online-corrective-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'shutdown-corrective-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  predefines: record => [`PM ${R.prop('pmNumber', record)}`],
  'regdoc-reportable-events': record => [`CR ${R.prop('crNumber', record)}`],
  'operator-workarounds': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'operator-burdens': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'tmods-beyond-6-months': record => [`TCC ${R.prop('serialNumber', record)}`],
  'tmods-beyond-90-days': record => [`TCC ${R.prop('serialNumber', record)}`],
  'modification-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'toe-corrective-actions': record => [`CR ${R.prop('crNumber', record)}`],
  'comp-er-clock-resets': record => [`CR ${R.prop('crNumber', record)}`],
  'comp-equipment-defects': record => [
    `WO ${R.prop('workOrderNumber', record)}`,
    `WR ${R.prop('workRequestNumber', record)}`,
  ],
  'comp-maintenance-feedback-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'comp-functional-failures': record => [
    `WO ${R.prop('workOrderNumber', record)}`,
    `WR ${R.prop('workRequestNumber', record)}`,
  ],
  'comp-outstanding-corrective-actions': record => [`CR ${R.prop('crNumber', record)}`],
  'comp-predefines': record => [`PM ${R.prop('pmNumber', record)}`],
  'comp-online-deficient-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'comp-online-corrective-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'comp-shutdown-corrective-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
  'comp-shutdown-deficient-maintenance-backlog': record => [`WO ${R.prop('workOrderNumber', record)}`],
};

const useIndicatorRecordLabels = indicatorId =>
  R.propOr(R.always(['Invalid indicator']), indicatorId, indicatorSpecifics);

export default useIndicatorRecordLabels;
