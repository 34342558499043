import React, { useState, useEffect, createContext, useCallback } from 'react';
import api from '../api';
import { MSMProgram } from '../types/msm-programs';

export const MSMProgramsContext = createContext<MSMProgram[]>([]);

const MSMProgramsProvider = (props: React.PropsWithChildren) => {
  const [data, setData] = useState<MSMProgram[]>([{ msm_program: 'default', status: 'Active' }]);

  const getMSMPrograms = useCallback(async () => {
    try {
      const newPrograms = await api.getMSMPrograms();
      if (newPrograms.length > 0) {
        setData(newPrograms);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    void getMSMPrograms();
  }, []);

  return <MSMProgramsContext.Provider value={data}>{props.children}</MSMProgramsContext.Provider>;
};

export default MSMProgramsProvider;
