import React from 'react';
import styled from 'styled-components';
import parseLongDescription from '../utils/parse-long-description';
import Paragraphs from './Paragraphs';

const Container = styled.div`
  width: 100%;
`;

interface Props {
  className?: string;
  text: string;
}

const LongDescription = ({ className, text }: Props) => {
  const s = parseLongDescription(text);

  return (
    <Paragraphs
      className={className}
      s={s}
      font="monospace"
      containerElement={Container}
    />
  );
};

export default LongDescription;
