import { useState, useCallback } from 'react';

const useEditListItem = onUpdateItem => {
  const [editingIndex, setEditingIndex] = useState(null);

  const edit = useCallback(
    index => {
      setEditingIndex(index);
    },
    [setEditingIndex]
  );

  const onUpdate = useCallback(
    (...params) => {
      onUpdateItem(editingIndex, ...params);
      setEditingIndex(null);
    },
    [onUpdateItem, editingIndex, setEditingIndex]
  );

  const onCancelUpdate = useCallback(() => {
    setEditingIndex(null);
  }, [setEditingIndex]);

  return {
    editingIndex,
    edit,
    onUpdate,
    onCancelUpdate,
  };
};

export default useEditListItem;
