/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { Scorecard, CalculatedValue, ColourCurve, Override } from 'echo-scorecard';
import * as scorecardActions from '../state/scorecard/actions';
import { ElementId } from '../types/app';

export type OverrideValue = {
  indicatorId: number;
  indicatorName: string;
  elementUnit: string;
  elementType: string;
  colourCurve: ColourCurve;
  score: number;
  override?: Override | undefined;
  indicatorWeight?: number;
  unitWeight?: number;
};

const useOverrideValue = (elementId: ElementId, year: number, quarter: number, scorecard: Scorecard) => {
  const dispatch = useDispatch();
  const [selectedIndicatorId, setSelectedIndicatorId] = useState<number>(0);
  const [selectedElementUnit, setSelectedElementUnit] = useState<string>('');

  const overrideValue = useMemo(() => {
    if (R.not(selectedIndicatorId) || R.isEmpty(selectedElementUnit)) {
      return null;
    }

    // TODO: Fix typing here in scorecard library
    const indicator: any = scorecard.indicatorMap[selectedIndicatorId];
    const { colourCurve } = indicator;
    return {
      ...R.path<CalculatedValue>(['values', selectedIndicatorId, selectedElementUnit, 'calculated'], scorecard),
      indicatorId: selectedIndicatorId,
      indicatorName: scorecard.indicatorMap[selectedIndicatorId].name,
      elementUnit: selectedElementUnit,
      colourCurve,
    } as OverrideValue;
  }, [selectedIndicatorId, selectedElementUnit, scorecard]);

  const editOverrideValue = useCallback(
    (indicatorId: number, elementUnit: string) => {
      setSelectedIndicatorId(indicatorId);
      setSelectedElementUnit(elementUnit);
    },
    [setSelectedIndicatorId, setSelectedElementUnit]
  );

  const cancelEditOverrideValue = useCallback(() => {
    setSelectedIndicatorId(0);
    setSelectedElementUnit('');
  }, [setSelectedIndicatorId, setSelectedElementUnit]);

  const clearOverrideValue = useCallback(() => {
    dispatch(
      scorecardActions.resetOverrideScoreRequest(elementId, year, quarter, selectedIndicatorId, selectedElementUnit)
    );
    setSelectedIndicatorId(0);
    setSelectedElementUnit('');
  }, [dispatch, selectedIndicatorId, selectedElementUnit, setSelectedIndicatorId, setSelectedElementUnit]);

  const saveOverrideValue = useCallback(
    (score: string, comment: string) => {
      dispatch(
        scorecardActions.setOverrideValueRequest(
          elementId,
          year,
          quarter,
          selectedIndicatorId,
          selectedElementUnit,
          score,
          comment
        )
      );
      setSelectedIndicatorId(0);
      setSelectedElementUnit('');
    },
    [dispatch, elementId, year, quarter, scorecard, selectedIndicatorId, selectedElementUnit]
  );

  return {
    overrideValue,
    editOverrideValue,
    cancelEditOverrideValue,
    clearOverrideValue,
    saveOverrideValue,
  };
};

export default useOverrideValue;
