import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import MultiLineText from './MultiLineText';

const Paragraph = styled(Typography)``;

const Container = styled(({ element, ...props }) => React.createElement(element, R.omit(['font'], props)))`
  margin-top: 0px;
  margin-bottom: 0px;

  ${Paragraph} {
    margin-bottom: 4px;
    font: ${({ theme, font }) => R.propOr(theme?.fonts?.normal14, font, theme?.fonts)};
  }

  ${Paragraph}:last-child {
    margin-bottom: 0px;
  }
`;

interface Props {
  className?: string;
  s?: string;
  font?: any;
  containerElement?: string;
}

const Paragraphs = ({ className, s, font, containerElement = 'blockquote' }: Props) => (
  <Container
    className={className}
    font={font}
    element={containerElement}
  >
    <MultiLineText description={s} />
  </Container>
);

export default Paragraphs;
