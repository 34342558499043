import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledList from '@material-ui/core/List';
import UnstyledListItem from '@material-ui/core/ListItem';
import UnstyledTypography from '@material-ui/core/Typography';
import UnstyledChip from '@material-ui/core/Chip';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';

const CircularProgress = styled(UnstyledCircularProgress)``;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${CircularProgress} {
    margin-top: 20px;
    align-self: center;
  }
`;

const Typography = styled(UnstyledTypography)``;
const Chip = styled(UnstyledChip)``;

const ListItem = styled(props => <UnstyledListItem {...R.omit(['selected'], props)} />)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 0px;
  border-bottom: ${({ theme }) => theme.borders.thin.solid2};

  background-color: ${({ theme, selected }) => (selected ? theme.colours.greys.lightGrey3 : theme.colours.white)};

  &:hover {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey3};
  }

  .MuiChip-outlinedPrimary {
    color: ${({ theme }) => theme.colours.black};
    border-color: ${({ theme }) => theme.colours.pinks.pink1};
  }

  .MuiChip-outlinedSecondary {
    color: ${({ theme }) => theme.colours.black};
    border-color: ${({ theme }) => theme.colours.greys.mediumGrey1};
  }

  ${Chip} {
    margin-top: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: fit-content;
  }
`;

const List = styled(UnstyledList)`
  padding-left: 0px;
  padding-right: 0px;
  margin: 0;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 455px);
`;

const Header = styled.div`
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  width: 100%;
  border-bottom: ${({ theme }) => theme.borders.thin.solid2};
  font: ${({ theme }) => theme.fonts.bold14};
  align-self: flex-start;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  width: 100%;
`;

const recordApplicability = applicable => {
  if (R.isNil(applicable)) {
    return 'Not Evaluated';
  }
  return applicable ? 'Applied' : 'Not Applied';
};

const RecordList = ({ records, selectedRecordId, isLoading, onSelectRecordId }) => {
  const handleSelectRecord = recordId => () => {
    onSelectRecordId(recordId);
  };

  const listItems = records.map((record, i) => {
    const labels = record.labels.map((label, j) => <Typography key={`label-${j}`}>{label}</Typography>);

    return (
      <ListItem
        key={`${i}|${record.recordId}`}
        button
        selected={record.recordId === selectedRecordId}
        onClick={handleSelectRecord(record.recordId)}
      >
        <LabelContainer columns={labels.length}>{labels}</LabelContainer>
        <Chip
          label={recordApplicability(record.applicable)}
          variant="outlined"
          color={R.isNil(record.applicable) ? 'primary' : 'secondary'}
        />
      </ListItem>
    );
  });

  return (
    <Container>
      <Header>Search Results</Header>
      {isLoading ? <CircularProgress /> : listItems.length > 0 && <List>{listItems}</List>}
    </Container>
  );
};

export default RecordList;
