import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as actions from './actions';

function* loadScorecardColours(action) {
  const { type, category } = action.payload;
  try {
    const scorecardColours = yield call(Api.getScorecardColours, type, category);
    yield put(actions.loadSuccess(type, category, scorecardColours));
  } catch (e) {
    yield put(actions.loadFailure(e, { type, category }));
  }
}

function* root() {
  yield takeEvery(actionTypes.SCORECARD_COLOURS_LOAD_REQUEST, loadScorecardColours);
}

export default root;
