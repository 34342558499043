import { useState } from 'react';
import { ScheduleContextProvider } from '../hooks/useSchedule';
import { WODetailsModalContextProvider } from '../hooks/useWODetailsModal';
import Schedule from './components/Schedule';

const SchedulePage = () => {
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(true);
  return (
    <ScheduleContextProvider allSchedules={true}>
      <WODetailsModalContextProvider
        onClose={() => {
          setDisplayCalendar(true);
        }}
      >
        <Schedule
          displayCalendar={displayCalendar}
          setDisplayCalendar={setDisplayCalendar}
        />
      </WODetailsModalContextProvider>
    </ScheduleContextProvider>
  );
};
export default SchedulePage;
