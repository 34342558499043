import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actionActions from '../state/actions/actions';

const useReorderActions = (actions, state) => {
  const dispatch = useDispatch();

  const reorderActions = useCallback(
    (fromIndex, toIndex) => {
      const action = actions[fromIndex];
      dispatch(actionActions.reorderActionsRequest(action.id, fromIndex, toIndex, state, action.elementId));
    },
    [dispatch, actions, state]
  );

  return reorderActions;
};

export default useReorderActions;
