import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../../../../api';
import * as actionTypes from '../../../../../state/action-types';
import * as pageActions from './actions';
import indicatorSpecificLoad from './indicator-detail-loaders';

function* loadRecords(action) {
  try {
    const { indicatorId, element, unit } = action.payload;
    const records = yield call(Api.getIndicatorRecords, indicatorId, element, unit);
    yield put(pageActions.loadRecordsSuccess(indicatorId, records));
  } catch (e) {
    yield put(pageActions.loadRecordsFailure(e));
  }
}

function* loadRecordDetails(action) {
  try {
    const { indicatorShortName, record } = action.payload;
    const details = yield indicatorSpecificLoad(indicatorShortName, record);
    yield put(pageActions.loadRecordDetailsSuccess(details));
  } catch (e) {
    yield put(pageActions.loadRecordDetailsFailure(e));
  }
}

function* updateIndicatorRecord(action) {
  try {
    const { indicatorId, element, unit, recordId, applicable, options } = action.payload;
    yield call(Api.updateIndicatorRecord, indicatorId, recordId, applicable, options);
    yield put(pageActions.updateIndicatorRecordSuccess(indicatorId, element, unit, recordId, applicable, options));
  } catch (e) {
    yield put(pageActions.updateIndicatorRecordFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.RECORD_DETAILS_LOAD_RECORDS_REQUEST, loadRecords);
  yield takeEvery(actionTypes.RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST, loadRecordDetails);
  yield takeEvery(actionTypes.RECORD_DETAILS_UPDATE_INDICATOR_RECORD_REQUEST, updateIndicatorRecord);
  yield takeEvery(actionTypes.RECORD_DETAILS_UPDATE_INDICATOR_RECORD_SUCCESS, loadRecords);
}

export default root;
