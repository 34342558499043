import { ColourBox, UnitScoreCell } from './StyledComponents';

export const StaticCell = ({
  children,
  colour,
  overridden = false,
}: {
  children: React.ReactNode;
  colour: string;
  overridden?: boolean;
}) => (
  <UnitScoreCell
    padding="none"
    align="center"
    readonly
  >
    <ColourBox
      colour={colour}
      overridden={overridden}
    >
      {children}
    </ColourBox>
  </UnitScoreCell>
);
