import { Grid } from '@material-ui/core';
import Page from '../../../../components/Page';
import { useActions } from '../../hooks/useActions';
import DropdownList from './components/DropdownList';

const DropdownMaintenancePage = () => {
  const {
    flags,
    removeActionFlag,
    addActionFlag,
    newActionFlag,
    setNewActionFlag,
    removeActionGroup,
    newActionGroup,
    setNewActionGroup,
    addActionGroup,
    groups,
    statuses,
    removeActionStatus,
    newActionStatus,
    setNewActionStatus,
    addActionStatus,
  } = useActions();
  return (
    <Page title="Modify Dropdowns">
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={2}
        >
          <DropdownList
            title="Action:Group"
            onDelete={removeActionGroup}
            entries={groups}
            onAdd={addActionGroup}
            newEntry={newActionGroup}
            setNewEntry={setNewActionGroup}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <DropdownList
            title="Action:Flags"
            onDelete={removeActionFlag}
            entries={flags}
            onAdd={addActionFlag}
            newEntry={newActionFlag}
            setNewEntry={setNewActionFlag}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <DropdownList
            title="Action:Status"
            onDelete={removeActionStatus}
            entries={statuses}
            onAdd={addActionStatus}
            newEntry={newActionStatus}
            setNewEntry={setNewActionStatus}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DropdownMaintenancePage;
