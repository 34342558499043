import { useState, useEffect, useRef, useCallback } from 'react';

const useAutosave = (value, save, delay = 2000) => {
  const [lastValue, setLastValue] = useState(value);
  const timerRef = useRef(null);

  const clearAutosaveTimeout = useCallback(() => {
    clearTimeout(timerRef.current);
  }, [timerRef]);

  const forceSave = useCallback(() => {
    clearAutosaveTimeout();
    save();
  }, [clearAutosaveTimeout, save]);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (value !== lastValue) {
        save();
        setLastValue(value);
      }
    }, delay);
    return clearAutosaveTimeout;
  }, [timerRef, value, lastValue, save, delay, clearAutosaveTimeout]);

  return {
    forceSave,
  };
};

export default useAutosave;
