import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UnstyledCard from '@material-ui/core/Card';

import useBackupRses from '../../../../hooks/backup-rses';
import useElementUnits from '../../../../hooks/element-units';
import { ElementMappingProvider } from '../../../../hooks/element-mappings';
import useUsers from '../../../../hooks/users';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import usePrimaryRse from '../../../../hooks/primary-rse';
import ElementTypeIcon from './ElementTypeIcon';
import Field from './Field';
import UnstyledMappingsTable from './MappingsTable';
import PrimaryRse from './PrimaryRse';
import ElementIndicators from './ElementIndicators';
import Program from './Program';
import { EchoElement } from '../../../../hooks/elements';
import { ProgramElementProvider } from '../../../../hooks/program-element';
import ElementHeader from './ElementHeader';
import useRiskOwnersGroup from '../../../../hooks/risk-owners-group';
import StakeholderGroup from './StakeholderGroup';
import isFeatureEnabled from '../../../../utils/feature-flags';

const MappingsTable = styled(UnstyledMappingsTable)`
  max-height: 30vh;
  overflow-y: scroll;
`;

const Card = styled(UnstyledCard)`
  padding: 3%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow3};
`;

interface Props {
  element: EchoElement;
}

const Element = ({ element }: Props) => {
  const { users } = useUsers();
  const { backupRses, setBackupRse, clearBackupRse } = useBackupRses(element.id, users);
  const { setRiskOwner, clearRiskOwner, riskOwnersGroup } = useRiskOwnersGroup(element.id);
  const { elementUnits } = useElementUnits(element.id);
  const { setPrimaryRse, clearPrimaryRse } = usePrimaryRse(element.id, users);

  return (
    <Card>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          alignItems="center"
        >
          <Grid
            item
            container
            xs={3}
            alignItems="center"
          >
            <Grid item>
              <ElementTypeIcon type={element.type} />
            </Grid>
            <Grid
              item
              style={{ marginLeft: '5px' }}
            >
              <Typography variant="caption">{element.facilityName}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={9}
          >
            <Typography variant="h6">{element.name}</Typography>
          </Grid>
        </Grid>

        <ElementHeader element={element} />
        <Field
          title="Unit Schema"
          alignItems="center"
        >
          <Typography>
            {element.elementSchemaName} ({elementUnits.map(R.prop('elementUnit')).join(', ')})
          </Typography>
        </Field>
        <Field
          title={element.type === 'program' ? 'Primary RPE' : 'Primary RSE'}
          alignItems="center"
        >
          <PrimaryRse
            userId={element.primaryRse}
            name={element.primaryRseName}
            allUsers={users}
            onSet={setPrimaryRse}
            onClear={clearPrimaryRse}
          />
        </Field>
        <Field
          title={element.type === 'program' ? 'Backup RPEs' : 'Backup RSEs'}
          alignItems={backupRses.length > 0 ? 'flex-start' : 'center'}
        >
          <StakeholderGroup
            className="Add Backup RSE"
            allUsers={users}
            stakeholderGroup={backupRses}
            onSetStakeholderGroup={setBackupRse}
            onClearStakeholderGroup={clearBackupRse}
          />
        </Field>
        {isFeatureEnabled('riskOwnersGroup') && (
          <Field
            title="Risk Owners"
            alignItems={riskOwnersGroup.length > 0 ? 'flex-start' : 'center'}
          >
            <StakeholderGroup
              className="Add Risk Owner"
              allUsers={users}
              stakeholderGroup={riskOwnersGroup}
              onSetStakeholderGroup={setRiskOwner}
              onClearStakeholderGroup={clearRiskOwner}
            />
          </Field>
        )}
        {element.type === 'program' ? (
          <ProgramElementProvider elementId={element.id}>
            <Program elementId={element.id} />
          </ProgramElementProvider>
        ) : (
          <>
            <Field title="Mappings">
              <ElementMappingProvider elementId={element.id}>
                <MappingsTable elementUnits={elementUnits} />
              </ElementMappingProvider>
            </Field>
            <Field title="Indicators">
              <ElementIndicatorsProvider elementId={element.id}>
                <ElementIndicators />
              </ElementIndicatorsProvider>
            </Field>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default Element;
