import { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as elementActions from '../../../state/elements/actions';
import { getCategoryOptions, getFacilityOptions, getFrequencyOptions, getSchemaOptions } from '../state/selectors';

const elementDataContext = createContext<any>(null);

// Provider
export const ElementDataProvider = ({ children }: any) => {
  const frequencyOptions = useSelector(getFrequencyOptions);
  const categoryOptions = useSelector(getCategoryOptions);
  const schemas = useSelector(getSchemaOptions);
  const facilities = useSelector(getFacilityOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(elementActions.loadFrequenciesRequest());
    dispatch(elementActions.loadCategoriesRequest());
    dispatch(elementActions.loadFacilitiesRequest());
    dispatch(elementActions.loadSchemasRequest());
  }, []);

  return (
    <elementDataContext.Provider value={{ frequencyOptions, categoryOptions, schemas, facilities }}>
      {children}
    </elementDataContext.Provider>
  );
};

// Hook
const useElementData = (elementId?: number) => {
  const { frequencyOptions, categoryOptions, schemas, facilities } = useContext(elementDataContext);
  const dispatch = useDispatch();

  if (!frequencyOptions) {
    throw new Error('useElementData must be used inside a FrequencyOptionsProvider');
  }

  const updateElementData = ({
    reportingFrequency,
    state,
    category,
    tier,
    referenceReportingYear,
    referenceReportingQuarter,
    pmpRequired,
    sis,
    safetyRelatedSystemClassification,
    pmpDate,
  }: any) => {
    dispatch(
      elementActions.patchElementRequest(elementId, {
        state,
        tier,
        referenceReportingQuarter,
        referenceReportingYear,
        reportingFrequency,
        category,
        pmpRequired,
        sis,
        safetyRelatedSystemClassification,
        pmpDate,
      })
    );
  };

  return { frequencyOptions, categoryOptions, updateElementData, schemas, facilities };
};

export default useElementData;
