// Github Link to Code Based on: https://github.com/hmarggraff/react-material-ui-table-row-drag-and-drop/blob/master/src/index.js
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TableBody from '@material-ui/core/TableBody';

const DroppableComponent = onDragEnd => props =>
  (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={'1'}
        direction="vertical"
      >
        {provided => (
          <TableBody
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...props}
          >
            {props.children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );

export default DroppableComponent;
