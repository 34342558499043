import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const SmallButton = styled(Button).attrs({
  size: 'small',
})`
  color: ${({ theme }) => theme.colours.white};
  font: ${({ theme }) => theme.fonts.size4};
`;

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?(): void;
}

const SaveButton = ({ className, disabled, onClick }: Props) => (
  <SmallButton
    className={className}
    disabled={disabled}
    onClick={onClick}
  >
    Save
  </SmallButton>
);

export default SaveButton;
