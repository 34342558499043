import { useEffect, useState } from 'react';
import api from '../../../api';
import { useUsers } from './useUsers';
import { WorkOrder } from '../../../types/cwt/workorder';

const useAddNewWorkorder = () => {
  const [workOrderNumber, setWorkOrderNumber] = useState('');
  const [workOrder, setWorkOrder] = useState<{ id: number; title: string } | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [manualWorkOrders, setManualWorkOrders] = useState<WorkOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [isBacklog, setIsBacklog] = useState(false);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<string>('');
  const { userSite } = useUsers();

  const fetchWorkOrder = async () => {
    const response = await api.cwt.getNewWorkorder(workOrderNumber);
    if (response?.message) {
      setErrorMessage(response.message);
      setWorkOrder(null);
    } else {
      setWorkOrder(response);
      setErrorMessage('');
    }
  };

  const removeManualWorkorder = async () => {
    await api.cwt.removeManuallyAddedWorkorder(selectedWorkOrderId);
    setSelectedWorkOrderId('');
    setLoading(true);
  };

  const addNewWorkOrder = async () => {
    await api.cwt.insertNewWorkorder(workOrderNumber);
    setWorkOrder(null);
    setWorkOrderNumber('');
    setLoading(true);
  };

  const fetchManualWorkOrders = async () => {
    setLoading(true);
  };

  useEffect(() => {
    const getManualWorkOrders = async () => {
      const res = await api.cwt.getAllWorkorders({ manuallyAdded: true, active: isBacklog });
      setManualWorkOrders(res);
      setLoading(false);
    };
    {
      if (loading) void getManualWorkOrders();
    }
  }, [loading]);

  useEffect(() => {
    fetchManualWorkOrders();
  }, [userSite, isBacklog]);

  return {
    fetchWorkOrder,
    workOrder,
    setWorkOrderNumber,
    workOrderNumber,
    addNewWorkOrder,
    errorMessage,
    setWorkOrder,
    loading,
    setIsBacklog,
    isBacklog,
    removeManualWorkorder,
    setSelectedWorkOrderId,
    selectedWorkOrderId,
    fetchManualWorkOrders,
    manualWorkOrders,
    setManualWorkOrders,
  };
};

export default useAddNewWorkorder;
