import { MaximoOutage, Outage, OutageReference, Units } from '../types/outages';
import { Path, Remote } from './types';

interface AddOutagePayload
  extends Omit<Outage, 'outage_type' | 'created_at' | 'created_by' | 'modified_at' | 'modified_by'> {
  applicable_units: Units[];
}

type UpdateOutagePayload = Omit<AddOutagePayload, 'outage_code'>;

const getOutages = (path: Path, remote: Remote) => (): Promise<Outage[]> => remote.get(path('/'));
const getMaximoOutages = (path: Path, remote: Remote) => (): Promise<MaximoOutage[]> => remote.get(path('/maximo'));
const getOutageReferences = (path: Path, remote: Remote) => (): Promise<OutageReference[]> =>
  remote.get(path('/references'));
const addOutage =
  (path: Path, remote: Remote) =>
  (data: AddOutagePayload): Promise<Outage> =>
    remote.post(path(`/`), data);
const updateOutage =
  (path: Path, remote: Remote) =>
  (outage_code: string, data: UpdateOutagePayload): Promise<Outage> =>
    remote.put(path(`/${outage_code}`), data);
const addOutageReference =
  (path: Path, remote: Remote) =>
  (
    outage_code: OutageReference['outage_code'],
    referenceData: Omit<OutageReference, 'id' | 'outage_code'>
  ): Promise<Outage[]> =>
    remote.post(path(`/${outage_code}/references`), referenceData);
const deleteOutageReference =
  (path: Path, remote: Remote) => (outage_code: OutageReference['outage_code'], reference_id: OutageReference['id']) =>
    remote.del(path(`/${outage_code}/references/${reference_id}`));
const updateOutageReferenceActionType =
  (path: Path, remote: Remote) =>
  (
    outage_code: OutageReference['outage_code'],
    reference_id: OutageReference['id'],
    newActionType: OutageReference['action_type']
  ): Promise<Outage[]> =>
    remote.patch(path(`/${outage_code}/references/${reference_id}/update-action-type`), { action_type: newActionType });

export default (path: Path, remote: Remote) => ({
  getOutages: getOutages(path, remote),
  getMaximoOutages: getMaximoOutages(path, remote),
  getOutageReferences: getOutageReferences(path, remote),
  addOutage: addOutage(path, remote),
  updateOutage: updateOutage(path, remote),
  addOutageReference: addOutageReference(path, remote),
  deleteOutageReference: deleteOutageReference(path, remote),
  updateOutageReferenceActionType: updateOutageReferenceActionType(path, remote),
});
