import * as R from 'ramda';
import useDownloadReportAttachment from '../../../../../../hooks/download-report-attachment';
import useAttachmentType from '../../hooks/attachment-type';
import useAttachments from '../../hooks/attachments';
import useQuarter from '../../hooks/quarter';

export const useAttachmentList = () => {
  const { attachmentType } = useAttachmentType();
  const { year, quarter } = useQuarter();

  const { attachments: allAttachments } = useAttachments();

  const { downloadAttachment } = useDownloadReportAttachment(allAttachments);

  const attachments = allAttachments.filter((attachment: any) => {
    const filterByAttachementType = !R.isEmpty(attachmentType);
    const filterByQuarter = !isNaN(Number(year)) && !R.isNil(quarter);

    if (filterByAttachementType && filterByQuarter) {
      return (
        parseInt(attachment.year, 10) === parseInt(year, 10) &&
        parseInt(attachment.quarter, 10) === parseInt(quarter, 10) &&
        attachment.type === attachmentType
      );
    }
    if (filterByAttachementType) {
      return attachment.type === attachmentType;
    }
    if (filterByQuarter) {
      return (
        parseInt(attachment.year, 10) === parseInt(year, 10) &&
        parseInt(attachment.quarter, 10) === parseInt(quarter, 10)
      );
    }
    return true;
  });

  return {
    attachments,
    downloadAttachment,
  };
};
