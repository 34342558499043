export const defaultValues = {
  title: '',
  approver: '',
  year: null,
  quarter: '',
  startDate: '',
  completedDate: '',
  person: '',
  unit: '',
  templateId: null,
  elementId: null,
};

export const formConfig = {
  title: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  approver: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  year: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  quarter: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  startDate: {},

  completedDate: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  person: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  unit: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  templateId: {},

  elementId: {},
};
