import { useParams, Redirect } from 'react-router-dom';

interface WalkdownParameters {
  walkdownId: string;
}

const LegacyRedirect = ({ oldPath }: { oldPath: string }) => {
  const { walkdownId } = useParams<WalkdownParameters>(); // Extract the id from the URL

  //TODO: The walkdown could be archived but the url will always contain in-progress
  //in-progress and archives will render the same component but currently the url will not match in this case
  return <Redirect to={`${oldPath}/in-progress/view/${walkdownId}`} />;
};
export default LegacyRedirect;
