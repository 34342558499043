import { Grid } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import FilterButton from '../../../components/FilterButton';

const FilterButtons = ({ href }: any) => {
  const inprogressMatch = useRouteMatch({
    path: href,
    strict: true,
    sensitive: true,
  });

  const archivesMatch = useRouteMatch({
    path: `${href}/archives`,
    strict: true,
    sensitive: true,
  });

  const templatesMatch = useRouteMatch({
    path: `${href}/templates`,
    strict: true,
    sensitive: true,
  });
  return (
    <Grid container>
      <Grid
        item
        style={{ paddingRight: '1em' }}
      >
        <Link
          to={href}
          style={{ textDecoration: 'none' }}
        >
          <FilterButton isActive={inprogressMatch && !archivesMatch && !templatesMatch}>In-progress</FilterButton>
        </Link>
      </Grid>
      <Grid
        item
        style={{ paddingRight: '1em' }}
      >
        <Link
          to={`${href}/templates`}
          style={{ textDecoration: 'none' }}
        >
          <FilterButton isActive={templatesMatch}>Templates</FilterButton>
        </Link>
      </Grid>
      <Grid item>
        <Link
          to={`${href}/archives`}
          style={{ textDecoration: 'none' }}
        >
          <FilterButton isActive={archivesMatch}>Archives</FilterButton>
        </Link>
      </Grid>
    </Grid>
  );
};
export default FilterButtons;
