import * as R from 'ramda';
import * as actionTypes from '../../../state/action-types';

const initialState = {
  records: {},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_LOAD_RECORDS_REQUEST: {
      const { indicatorId } = action.payload;
      return {
        ...state,
        records: {
          ...state.records,
          [indicatorId]: {
            records: R.pathOr([], ['records', indicatorId, 'records'], state),
            isLoading: true,
            error: undefined,
          },
        },
      };
    }
    case actionTypes.DASHBOARD_LOAD_RECORDS_SUCCESS: {
      const { indicatorId, records } = action.payload;
      return {
        ...state,
        records: {
          ...state.records,
          [indicatorId]: {
            records,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.DASHBOARD_LOAD_RECORDS_FAILURE: {
      const { error, extra: indicatorId } = action.payload;
      return {
        ...state,
        records: {
          ...state.records,
          [indicatorId]: {
            records: [],
            isLoading: false,
            error,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
