import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as myActions from './actions';

function* loadElements() {
  try {
    const elements = yield call(Api.getMyElements);
    yield put(myActions.loadElementsSuccess(elements));
  } catch (e) {
    yield put(myActions.loadElementsFailure(e));
  }
}

function* loadAssignments() {
  try {
    const assignments = yield call(Api.getMyAssignments);
    yield put(myActions.loadAssignmentsSuccess(assignments));
  } catch (e) {
    yield put(myActions.loadAssignmentsFailure(e));
  }
}

function* loadUnits() {
  try {
    const units = yield call(Api.getMyUnits);
    yield put(myActions.loadUnitsSuccess(units));
  } catch (e) {
    yield put(myActions.loadUnitsFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.MY_LOAD_ELEMENTS_REQUEST, loadElements);
  yield takeEvery(actionTypes.MY_LOAD_ASSIGNMENTS_REQUEST, loadAssignments);
  yield takeEvery(actionTypes.MY_LOAD_UNITS_REQUEST, loadUnits);
}

export default root;
