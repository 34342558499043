import isFeatureEnabled from '../../utils/feature-flags';
import * as actionTypes from '../action-types';
import scorecardKey from './keys';

const initialState = {};
const mapErrorMessage = err => {
  const error = `${typeof err === 'object' ? err.toString() : err}`;
  if (error.includes('Request failed with status code 500') || error.includes('Request failed with status code 503')) {
    return 'Unable to load Scorecard Data';
  }
  if (error.includes('Request failed with status code 400')) {
    return 'Scorecard related data was not found';
  }
  if (error.includes('Request failed with status code 4')) {
    return 'There was a permission or authentication issue';
  }
  if (!error || typeof error !== 'string') {
    return 'API Server is unreachable';
  }
  return error;
};
const clearKeyOfErrorData = cacheState => {
  if (cacheState) {
    delete cacheState.error;
    delete cacheState.errorMessage;
    delete cacheState.staleData;
  }
};

const scorecardReducerWithCache = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCORECARD_LOAD_REQUEST: {
      const { elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      const { staleData } = state[key] || {};
      clearKeyOfErrorData(state[key]);

      return {
        ...state,
        currentElementId: elementId,
        [key]: {
          ...state[key],
          staleData,
          elementId,
          isLoading: true,
        },
      };
    }
    case actionTypes.SCORECARD_LOAD_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      clearKeyOfErrorData(state[key]);

      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          staleData: scorecard,
          isLoading: false,
          lastUpdatedAt: new Date(),
        },
      };
    }
    case actionTypes.SCORECARD_LOAD_FAILURE: {
      const { error, elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      const isOldDataValid = state[key].scorecard && Object.keys(state[key].scorecard).length > 0;
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard: undefined,
          staleData: isOldDataValid ? state[key].scorecard : state[key].staleData,
          isLoading: false,
          error,
          errorMessage: mapErrorMessage(error),
        },
      };
    }
    case actionTypes.SCORECARD_ERROR_CLEAR: {
      const { elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);

      const { staleData } = state[key] || {};
      clearKeyOfErrorData(state[key]);

      return {
        ...state,
        [key]: {
          ...state[key],
          staleData,
        },
      };
    }
    case actionTypes.SCORECARD_HYDRATE_FROM_CACHE: {
      const { elementId, year, quarter, staleData, cacheLastModified } = action.payload;
      const key = scorecardKey(elementId, year, quarter);

      return {
        ...state,
        [key]: {
          ...state[key],
          staleData,
          lastUpdatedAt: state[key] && !state[key].isLoading ? state[key].lastUpdatedAt : cacheLastModified,
        },
      };
    }
    case actionTypes.OVERRIDE_VALUE_SET_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);

      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          staleData: scorecard,
          lastUpdatedAt: new Date(),
        },
      };
    }
    case actionTypes.MANUAL_INDICATOR_VALUES_SET_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      clearKeyOfErrorData(state[key]);

      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          staleData: scorecard,
          lastUpdatedAt: new Date(),
        },
      };
    }
    default:
      return state;
  }
};
const scorecardReducerWithoutCache = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCORECARD_LOAD_REQUEST: {
      const { elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
          error: undefined,
        },
      };
    }
    case actionTypes.SCORECARD_LOAD_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          isLoading: false,
          lastUpdatedAt: new Date(),
        },
      };
    }
    case actionTypes.SCORECARD_LOAD_FAILURE: {
      const { error, elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard: undefined,
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.OVERRIDE_VALUE_SET_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          lastUpdatedAt: new Date(),
        },
      };
    }
    case actionTypes.MANUAL_INDICATOR_VALUES_SET_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          lastUpdatedAt: new Date(),
        },
      };
    }
    default:
      return state;
  }
};

const scorecardReducer = (state = initialState, action) =>
  isFeatureEnabled('scorecardOptimizations')
    ? scorecardReducerWithCache(state, action)
    : scorecardReducerWithoutCache(state, action);
export default scorecardReducer;
