import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadReportIndicatorsRequest = type => ({
  type: actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_REQUEST,
  payload: {
    type,
  },
});

export const loadReportIndicatorsSuccess = (type, indicators) => ({
  type: actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_SUCCESS,
  payload: {
    type,
    indicators,
  },
});

export const loadReportIndicatorsFailure = errorAction(actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_FAILURE);

export const loadAllRequest = () => ({
  type: actionTypes.INDICATORS_LOAD_ALL_REQUEST,
});

export const loadAllSuccess = indicators => ({
  type: actionTypes.INDICATORS_LOAD_ALL_SUCCESS,
  payload: {
    indicators,
  },
});

export const loadAllFailure = errorAction(actionTypes.INDICATORS_LOAD_ALL_FAILURE);

export const addIndicatorSuccess = () => ({
  type: actionTypes.INDICATOR_ADD_SUCCESS,
  payload: {
    message: 'Indicator Added',
  },
});

export const addIndicatorFailure = e => ({
  type: actionTypes.INDICATOR_ADD_FAILURE,
  payload: {
    error: e,
    errorMessage: 'Error adding indicator',
  },
});

export const removeIndicatorSuccess = () => ({
  type: actionTypes.INDICATOR_REMOVE_SUCCESS,
  payload: {
    message: 'Indicator Removed',
  },
});

export const removeIndicatorFailure = e => ({
  type: actionTypes.INDICATOR_REMOVE_FAILURE,
  payload: {
    error: e,
    errorMessage: 'Error removing indicator',
  },
});

export const updateIndicatorSuccess = () => ({
  type: actionTypes.INDICATOR_UPDATE_SUCCESS,
  payload: {
    message: 'Indicator Updated',
  },
});

export const updateIndicatorFailure = e => ({
  type: actionTypes.INDICATOR_UPDATE_FAILURE,
  payload: {
    error: e,
    errorMessage: 'Error updating indicator',
  },
});

export const indicatorSumFailure = weight => ({
  type: actionTypes.INDICATOR_SUM_FAILURE,
  payload: {
    error: null,
    errorMessage: `Sum is ${weight}%. Please adjust to 100%.`,
  },
});

export const indicatorSumSuccess = () => ({
  type: actionTypes.INDICATOR_SUM_SUCCESS,
  payload: {
    message: 'Sum is 100%',
  },
});
