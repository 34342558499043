import { useEffect } from 'react';
import useWorkRequests from '../../hooks/useWorkRequests';
import { ScreeningStatus } from '../types';

const getWorkRequestState = (screeningStatus: ScreeningStatus | undefined) => {
  switch (screeningStatus) {
    case 'coded':
      return 'coded';
    case 'actionRequired':
    case 'noActionRequired':
      return 'requiringCoding';
    default:
      return undefined;
  }
};

export interface UseWorkRequestNavigationProps {
  screeningStatus: ScreeningStatus | undefined;
  workRequestNumber: string;
}

export interface UseWorkRequestNavigationReturn {
  previousWorkRequestNumber: string | undefined;
  nextWorkRequestNumber: string | undefined;
}

export const useWorkRequestNavigation = ({
  screeningStatus,
  workRequestNumber,
}: UseWorkRequestNavigationProps): UseWorkRequestNavigationReturn => {
  const { state, setState, loading, workRequests } = useWorkRequests();

  const workRequestState = getWorkRequestState(screeningStatus);

  // If the user navigates directly to this page (or refreshes), we need to update the tab state accordingly.
  useEffect(() => {
    if (workRequestState && state !== workRequestState) {
      setState(workRequestState);
    }
  }, [state, workRequestState]);

  const currentIndex = !loading
    ? workRequests.findIndex(workRequest => workRequest.workRequestNumber === workRequestNumber)
    : -1;
  const previousWorkRequestNumber = currentIndex > 0 ? workRequests[currentIndex - 1].workRequestNumber : undefined;
  const nextWorkRequestNumber =
    currentIndex !== -1 && currentIndex < workRequests.length - 1
      ? workRequests[currentIndex + 1].workRequestNumber
      : undefined;

  return {
    previousWorkRequestNumber,
    nextWorkRequestNumber,
  };
};
