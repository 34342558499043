import React from 'react';

const formatMap: any = {
  sh1: 'SH1',
  sh2: 'SH2',
  sh3: 'SH3',
};

interface Props {
  systemHealthFocusRating: string;
}

const SHFRCell = ({ systemHealthFocusRating }: Props) => (
  <>{formatMap[systemHealthFocusRating] ? formatMap[systemHealthFocusRating] : systemHealthFocusRating}</>
);

export default SHFRCell;
