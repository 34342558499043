import React, { useCallback, useContext, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button, Typography } from '@material-ui/core';
import { MSMProgram } from '../../../../../../types/msm-programs';
import { MSMProgramsContext } from '../../../../../../context/msm-programs';
import NewMSMProgramDashboard from './NewMSMProgramsDashboard';
import EditMSMProgramDashboard from './EditMSMProgramsDashboard';
import ActionButton from '../../../../../../components/ActionButton';
import api from '../../../../../../api';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';

interface RowProps {
  item: MSMProgram;
  onEdit: (businessObjective: MSMProgram) => void;
  onDelete: (businessObjective: MSMProgram) => void;
}
const Row = ({ item, onEdit, onDelete }: RowProps) => (
  <TableRow>
    <TableCell>{item.msm_program}</TableCell>
    <TableCell>{item.status}</TableCell>
    <TableCell>
      <Button onClick={() => onEdit(item)}>EDIT</Button>
    </TableCell>
    <TableCell>
      <Button onClick={() => onDelete(item)}>DELETE</Button>
    </TableCell>
  </TableRow>
);

const MSMProgramsTable = () => {
  const msmPrograms = useContext(MSMProgramsContext);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [originalMSMProgram, setOriginalMSMProgram] = useState('');
  const dispatch = useDispatch();

  const handleDelete = useCallback(async (item: MSMProgram) => {
    try {
      await api.deleteMSMProgram(item.msm_program);
      dispatch(uiActions.genericMessage('deleted succesfully'));
    } catch (error) {
      dispatch(uiActions.error('', 'delete unsuccesful'));
    }
  }, []);

  return (
    <>
      <NewMSMProgramDashboard
        opened={openAddDialog}
        setOpened={setOpenAddDialog}
      />
      <EditMSMProgramDashboard
        opened={openEditDialog}
        setOpened={setOpenEditDialog}
        originalMSMProgram={originalMSMProgram}
      />

      <TableContainer>
        {msmPrograms.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>MSM Program</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
                <TableCell>
                  <ActionButton
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                  >
                    Add New MSM Program
                  </ActionButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {msmPrograms
                .filter(item => !item.is_deleted)
                .map((item: MSMProgram) => (
                  <Row
                    item={item}
                    onEdit={() => {
                      setOpenEditDialog(true);
                      setOriginalMSMProgram(item.msm_program);
                    }}
                    onDelete={async item => await handleDelete(item)}
                  />
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No MSM Programs Exist Yet</Typography>
        )}
      </TableContainer>
    </>
  );
};

export default MSMProgramsTable;
