import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadFacilitiesRequest = () => ({
  type: actionTypes.ADMIN_LOAD_FACILITIES_REQUEST,
});

export const loadFacilitiesSuccess = facilities => ({
  type: actionTypes.ADMIN_LOAD_FACILITIES_SUCCESS,
  payload: {
    facilities,
  },
});

export const loadFacilitiesFailure = errorAction(actionTypes.ADMIN_LOAD_FACILITIES_FAILURE);
