import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

interface Props {
  className?: string;
  disabled: boolean;
  canReorder: boolean;
  onEdit?(): void;
  onDelete?(): void;
  onUp?(): void;
  onDown?(): void;
}

const CommentActions = ({ className, disabled, canReorder, onEdit, onDelete, onUp, onDown }: Props) => (
  <Column className={className}>
    <Row>
      <IconButton
        disabled={disabled}
        onClick={onEdit}
        size="small"
      >
        <EditIcon />
      </IconButton>
      {onDelete && (
        <IconButton
          disabled={disabled}
          onClick={onDelete}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Row>
    {canReorder && (
      <Row>
        <IconButton
          disabled={disabled || R.isNil(onUp)}
          onClick={onUp}
          size="small"
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          disabled={disabled || R.isNil(onDown)}
          onClick={onDown}
          size="small"
        >
          <ArrowDownwardIcon />
        </IconButton>
      </Row>
    )}
  </Column>
);

export default CommentActions;
