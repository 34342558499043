import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledTableCell from '@material-ui/core/TableCell';
import SortableColumnHeader from './SortableColumnHeader';
import { SortableColumn } from './types';

const TableCell = styled(props => <UnstyledTableCell {...R.omit(['width'], props)} />)`
  padding: 0px;
  height: 38px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  font: ${({ theme }) => theme.fonts.bold14};
  text-transform: uppercase;
  border: none;
`;

interface Props {
  className?: string;
  column: SortableColumn;
  sortColumn: string;
  sortOrder: boolean;
  onSort(): void;
}

const HeaderCell = ({ className, column, sortColumn, sortOrder, onSort }: Props) => (
  <TableCell
    className={className}
    align={R.propOr('left', 'align', column)}
    width={column.width}
  >
    {column.sortable ? (
      <SortableColumnHeader
        label={column.label}
        columnName={column.id}
        sortColumn={sortColumn}
        sortOrder={sortOrder}
        onSort={onSort}
      />
    ) : (
      <>{column.label}</>
    )}
  </TableCell>
);

export default HeaderCell;
