import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';
import useIndicatorRecordsByQuarter from '../../hooks/indicator-records-by-quarter';
import { IndicatorProvider } from '../../hooks/indicator';
import { type ElementIndicator } from '../../types/element-indicators';
import { Report } from '../../hooks/report';
import RecordTables from '../IndicatorRecordTable/RecordTables';

const CircularProgress = styled(UnstyledCircularProgress)``;

const List = styled.ul`
  list-style: none;
  padding-left: 0px;
`;

const ListItem = styled.li`
  ${CircularProgress} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const IndicatorName = styled(Typography).attrs({
  variant: 'h4',
})`
  font: ${({ theme }) => theme.fonts.medium16};
`;

interface RecordTablesContainerProps {
  className?: string;
  report: Report;
  elementIndicator: ElementIndicator;
}

const RecordTablesContainer: React.FC<RecordTablesContainerProps> = ({ className, report, elementIndicator }) => {
  const { records, isLoading } = useIndicatorRecordsByQuarter(
    report.elementId,
    report.year,
    report.quarter,
    elementIndicator.indicatorId
  );
  return (
    <ListItem className={className}>
      <IndicatorName>{elementIndicator.indicatorName}</IndicatorName>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <IndicatorProvider indicatorId={elementIndicator.indicatorId}>
          <RecordTables
            records={records}
            isLoading={isLoading}
            readonly
          />
        </IndicatorProvider>
      )}
    </ListItem>
  );
};

interface SummaryRecordTablesProps {
  className?: string;
  report: Report;
  elementIndicators: ElementIndicator[];
}

const SummaryRecordTables: React.FC<SummaryRecordTablesProps> = ({ className, report, elementIndicators }) => {
  const tables = elementIndicators.map((elementIndicator: any) => (
    <RecordTablesContainer
      key={elementIndicator.indicatorId}
      report={report}
      elementIndicator={elementIndicator}
    />
  ));

  return <List className={className}>{tables}</List>;
};

export default SummaryRecordTables;
