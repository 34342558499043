import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Paragraphs from '../../../../components/Paragraphs';
import UnstyledCommentActions from './CommentActions';
import UnstyledBody from './Body';
import ProgressOverlay from './ProgressOverlay';

const CommentActions = styled(UnstyledCommentActions)``;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${CommentActions} {
    margin-left: 10px;
  }
`;

const CommentTitle = styled(Typography).attrs({
  variant: 'h3',
})`
  text-transform: uppercase;
  font: ${({ theme }) => theme.fonts.bold16};
  color: ${({ theme }) => theme.colours.greys.darkGrey4};
`;

const Body = styled(UnstyledBody)`
  ${CommentTitle} {
    margin-bottom: 10px;
  }
`;

interface Props {
  className?: string;
  comment: any;
  saving?: boolean;
  canEdit?: boolean;
  onEdit(): void;
  onDelete(): void;
  onUp(): void;
  onDown(): void;
}

const StaticComment = ({ className, comment, saving, canEdit, onEdit, onDelete, onUp, onDown }: Props) => (
  <Container className={className}>
    <Body>
      <CommentTitle>{comment.title}</CommentTitle>
      <Paragraphs s={comment.body} />
    </Body>
    <CommentActions
      disabled={!canEdit}
      onEdit={onEdit}
      onDelete={onDelete}
      onUp={onUp}
      onDown={onDown}
      canReorder={true}
    />
    {saving && <ProgressOverlay />}
  </Container>
);

export default StaticComment;
