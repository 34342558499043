/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Select from './Select';
import CustomFormLabel from './CustomFormLabel';

interface Props {
  fullWidth?: boolean;
  className?: string;
  label?: string;
  id?: string;
  value?: any;
  onChange: () => void;
  error?: any;
  helperText?: string;
  disabled?: boolean;
  renderItem?: any;
}

const ColourSelect = ({
  className,
  label,
  id,
  value,
  onChange,
  error,
  helperText,
  disabled = false,
  renderItem,
  ...props
}: Props) => {
  const options = [
    { value: 'green', label: 'Green' },
    { value: 'white', label: 'White' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'red', label: 'Red' },
  ];

  return (
    <Select
      className={className}
      label={label}
      LabelComponent={CustomFormLabel}
      options={options}
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={error}
      helperText={helperText}
      renderItem={renderItem}
      {...props}
    />
  );
};

export default ColourSelect;
