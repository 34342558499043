import { useState } from 'react';
import useActionState from '../../../../../hooks/action-state';
import useActions from '../../../../../hooks/actions';
import useEditAction from '../../../../../hooks/edit-action';
import useNewAction from '../../../../../hooks/new-action';
import useReorderActions from '../../../../../hooks/reorder-actions';
import { MyElement } from '../../../../../types/my';
import type { ActionSource, ActionState } from '../../../../../types/actions';

export interface ShipChipHook {
  actions: any[];
  clearDeleteAction: () => void;
  clearEditAction: () => void;
  clearNewAction: () => void;
  clearStatusAction: () => void;
  comment: string;
  confirmActionStatusToggle: (actionId: any) => void;
  confirmDeleteActionToggle: (actionId: any) => void;
  deleteAction: boolean;
  deleteActionStatus: any;
  editAction: any;
  handleOnCancel: () => void;
  handleOnOkay: () => void;
  isLoading: boolean;
  newAction: any;
  reorderActions: (fromIndex: any, toIndex: any) => void;
  saveNewAction: (action: any) => void;
  setComment: (comment: string) => void;
  setState: (state: ActionState) => void;
  source: ActionSource;
  setSource: (source: ActionSource) => void;
  showEditActionDialog: () => void;
  showNewActionDialog: () => void;
  state: ActionState;
  statusAction: any;
  updateAction: (action: any) => void;
}

export const useShipsChips = (element: MyElement | null): ShipChipHook => {
  const [state, setState] = useState<ActionState>('ongoing');
  const [source, setSource] = useState<ActionSource>('All');
  const [comment, setComment] = useState('');
  const { actions, isLoading } = useActions(element?.elementId ?? '', state, source);

  const reorderActions = useReorderActions(actions, state);
  const { editAction, showEditActionDialog, clearEditAction, updateAction } = useEditAction(actions);
  const { newAction, showNewActionDialog, clearNewAction, saveNewAction } = useNewAction(element);
  const {
    statusAction,
    confirmActionStatusToggle,
    clearStatusAction,
    toggleActionStatus,
    deleteActionStatus,
    confirmDeleteActionToggle,
    deleteAction,
    clearDeleteAction,
  } = useActionState(actions);

  const handleOnOkay = () => {
    toggleActionStatus(comment);
    setComment('');
  };

  const handleOnCancel = () => {
    clearStatusAction();
    setComment('');
  };

  return {
    actions,
    clearDeleteAction,
    clearEditAction,
    clearNewAction,
    clearStatusAction,
    comment,
    confirmActionStatusToggle,
    confirmDeleteActionToggle,
    deleteAction,
    deleteActionStatus,
    editAction,
    handleOnCancel,
    handleOnOkay,
    isLoading,
    newAction,
    reorderActions,
    saveNewAction,
    setComment,
    setState,
    source,
    setSource,
    showEditActionDialog,
    showNewActionDialog,
    state,
    statusAction,
    updateAction,
  };
};
