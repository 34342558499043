import { Path, Remote } from './types';

const getMyElements = (path: Path, remote: Remote) => () => remote.get(path('/elements'));
const getMyAssignedDeferrals = (path: Path, remote: Remote) => () => remote.get(path('/deferrals'));
const getMyRequestedDeferrals = (path: Path, remote: Remote) => () => remote.get(path('/deferrals/requested'));
const getMyAssignments = (path: Path, remote: Remote) => () =>
  remote.get(path('/assignments'), { params: { unique: true } });
const getMyRecentAssignments = (path: Path, remote: Remote) => () =>
  remote.get(path('/assignments'), { params: { unique: true, recent: true } });
const getMyUnits = (path: Path, remote: Remote) => () => remote.get(path('/units'));
const getMyDashboards = (path: Path, remote: Remote) => () => remote.get(path('/dashboards'));
const addMyDashboardFavourite = (path: Path, remote: Remote) => (dashboardId: string) =>
  remote.post(path('/dashboards/favourites'), { dashboardId });
const removeMyDashboardFavourite = (path: Path, remote: Remote) => (dashboardId: string) =>
  remote.del(path(`/dashboards/favourites/${dashboardId}`));

const testMaximo = (path: Path, remote: Remote) => () => remote.get(path('/test/maximo'));

const getMyRiskManagementUserRoles = (path: Path, remote: Remote) => () => remote.get(path('/riskManagement/users'));

const getMyRiskManagementRisks = (path: Path, remote: Remote) => (elementId: string, status: string[]) =>
  remote.get(path(`/riskManagement/risks?elementId=${elementId}&status=${status.join(',')}`));

export default (path: Path, remote: Remote) => ({
  getMyAssignedDeferrals: getMyAssignedDeferrals(path, remote),
  getMyRequestedDeferrals: getMyRequestedDeferrals(path, remote),
  getMyElements: getMyElements(path, remote),
  getMyAssignments: getMyAssignments(path, remote),
  getMyRecentAssignments: getMyRecentAssignments(path, remote),
  getMyUnits: getMyUnits(path, remote),
  getMyDashboards: getMyDashboards(path, remote),
  addMyDashboardFavourite: addMyDashboardFavourite(path, remote),
  removeMyDashboardFavourite: removeMyDashboardFavourite(path, remote),
  testMaximo: testMaximo(path, remote),
  getMyRiskManagementUserRoles: getMyRiskManagementUserRoles(path, remote),
  getMyRiskManagementRisks: getMyRiskManagementRisks(path, remote),
});
