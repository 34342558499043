import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import RestoreIcon from '@material-ui/icons/Restore';
import UnstyledEditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const EditIcon = styled(UnstyledEditIcon)`
  height: 21px;
  width: 21px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

interface Props {
  className?: string;
  state: string;
  action: any;
  onEdit?: any;
  onChangeStatus?: any;
  onDelete?: any;
}

const ActionsCell = ({ className, state, action, onEdit, onChangeStatus, onDelete }: Props) => {
  const handleEdit = () => {
    onEdit(action.id);
  };

  const handleChangeStatus = () => {
    onChangeStatus(action.id);
  };

  const handleDelete = () => {
    onDelete(action.id);
  };

  return (
    <Container className={className}>
      {onEdit && (
        <IconButton
          size="small"
          onClick={handleEdit}
        >
          <EditIcon color="primary" />
        </IconButton>
      )}
      {onChangeStatus && (
        <IconButton
          size="small"
          onClick={handleChangeStatus}
        >
          {action.state === 'ongoing' || action.actionState === 'ongoing' ? (
            <CheckIcon color="primary" />
          ) : (
            <RestoreIcon color="primary" />
          )}
        </IconButton>
      )}
      {state !== 'complete' && onDelete && (
        <IconButton
          size="small"
          onClick={handleDelete}
        >
          <DeleteIcon color="primary" />
        </IconButton>
      )}
    </Container>
  );
};

export default ActionsCell;
