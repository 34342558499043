import React, { useState } from 'react';

import UnstyledCard from '@material-ui/core/Card';
import MuiTextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import CarlaText from '../CarlaText';
import OutlineButton from '../OutlineButton';

const Card = styled(UnstyledCard)`
  width: 100%;
  padding: 15px;
`;

const TextField = styled(MuiTextField)`
  width: 100%;
`;

interface Props {
  id: number;
  note: string;
  isDone: boolean;
  createdOn: string;
  onDone(id: number): void;
  onUpdate(id: number, note: string): void;
}

const Note = ({ id, note, isDone, createdOn, onDone, onUpdate }: Props) => {
  const [updatedNote, setUpdatedNote] = useState(note);
  const [saveTime, setSaveTimeout] = useState<any>(null);

  const handleUpdate = (text: string) => {
    if (saveTime) {
      clearTimeout(saveTime);
    }
    const t = setTimeout(() => {
      onUpdate(id, text);
      setSaveTimeout(null);
    }, 1000);
    setUpdatedNote(text);
    setSaveTimeout(t);
  };

  return (
    <Grid
      item
      key={id}
    >
      <Card>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
        >
          <TextField
            disabled={isDone}
            inputProps={{ maxLength: 255 }}
            type="string"
            multiline
            value={updatedNote}
            onChange={e => handleUpdate(e.target.value)}
          />
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            style={{ marginTop: '10px' }}
          >
            <Grid
              item
              xs
            >
              <CarlaText
                font="medium14"
                colourPath="greys.darkGrey6"
              >
                {createdOn}
              </CarlaText>
            </Grid>
            {!isDone && (
              <Grid
                item
                xs
                container
                direction="row"
                alignItems="center"
              >
                <OutlineButton
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onDone(id)}
                >
                  Completed
                </OutlineButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
      {saveTime && <LinearProgress />}
    </Grid>
  );
};

export default Note;
