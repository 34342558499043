import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as reportActions from '../state/reports/actions';
import { Report } from './report';

const useSubmitReport = (report: Report) => {
  const dispatch = useDispatch();

  const submitReport = useCallback(
    (users: any) => {
      dispatch(reportActions.submitRequest(report, users));
    },
    [dispatch, report]
  );

  return {
    submitReport,
  };
};

export default useSubmitReport;
