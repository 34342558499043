import React, { useState } from 'react';
import * as R from 'ramda';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';

interface ItemProps {
  elementIndicator: any;
  idx: number;
  onEdit: any;
  onRemove: any;
}

const Item = ({ elementIndicator, idx, onEdit, onRemove }: ItemProps) => {
  const [isEdit, setEdit] = useState(false);
  const [weight, setWeight] = useState(0);
  return (
    <>
      <ListItem
        key={elementIndicator.indicatorId + idx}
        button
      >
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
        <ListItemText
          primary={elementIndicator.indicatorName}
          secondary={`Weight: ${elementIndicator.weight}%`}
        />
        {isEdit && (
          <TextField
            label="Weight"
            type="number"
            defaultValue={elementIndicator.weight}
            onChange={(evt: any) => setWeight(evt.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        )}
        {elementIndicator.indicatorType !== 'calculated' && isEdit === false && (
          <ListItemIcon onClick={R.thunkify(onRemove)(elementIndicator.indicatorId)}>
            <DeleteIcon />
          </ListItemIcon>
        )}
        {isEdit === false && (
          <ListItemIcon onClick={() => setEdit(true)}>
            <EditIcon />
          </ListItemIcon>
        )}
        {isEdit === true && (
          <ListItemIcon
            onClick={() => {
              onEdit(elementIndicator.indicatorId, weight);
              setEdit(false);
            }}
          >
            <CheckIcon />
          </ListItemIcon>
        )}
        {isEdit === true && (
          <ListItemIcon onClick={() => setEdit(false)}>
            <CancelIcon />
          </ListItemIcon>
        )}
      </ListItem>
    </>
  );
};

interface Props {
  className?: string;
  elementIndicators: any[];
  onEdit: any;
  onRemove: any;
}

const ElementIndicatorList = ({ className, elementIndicators, onEdit, onRemove }: Props) => {
  const items = elementIndicators.map((elementIndicator, idx) => (
    <Item
      elementIndicator={elementIndicator}
      idx={idx}
      key={idx + 1}
      onEdit={onEdit}
      onRemove={onRemove}
    />
  ));

  return <List className={className}>{items}</List>;
};

export default ElementIndicatorList;
