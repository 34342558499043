import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as elementActions from '../state/elements/actions';
import * as elementSelectors from '../state/elements/selectors';

const useBackupRses = (elementId, allUsers, load = true) => {
  const dispatch = useDispatch();

  const loadBackupRses = useCallback(() => {
    dispatch(elementActions.loadBackupRsesRequest(elementId));
  }, [dispatch, elementId]);

  const setBackupRse = useCallback(
    userId => {
      const user = R.find(R.propEq('id', userId), allUsers);
      dispatch(elementActions.setBackupRseRequest(elementId, userId, user.name));
    },
    [dispatch, elementId, allUsers]
  );

  const clearBackupRse = useCallback(
    userId => {
      dispatch(elementActions.clearBackupRseRequest(elementId, userId));
    },
    [dispatch, elementId]
  );

  useEffect(() => {
    if (load) {
      loadBackupRses();
    }
  }, [load, loadBackupRses]);

  const backupRses = useSelector(state => elementSelectors.getBackupRses(state, elementId));

  return {
    backupRses,
    loadBackupRses,
    setBackupRse,
    clearBackupRse,
  };
};

export default useBackupRses;
