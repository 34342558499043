export const ERROR = 'ERROR';

export const DASHBOARD_LOAD_RECORDS_REQUEST = 'DASHBOARD_LOAD_RECORDS_REQUEST';
export const DASHBOARD_LOAD_RECORDS_SUCCESS = 'DASHBOARD_LOAD_RECORDS_SUCCESS';
export const DASHBOARD_LOAD_RECORDS_FAILURE = 'DASHBOARD_LOAD_RECORDS_FAILURE';
export const DASHBOARD_UPDATE_INDICATOR_RECORD_REQUEST = 'DASHBOARD_UPDATE_INDICATOR_RECORD_REQUEST';
export const DASHBOARD_UPDATE_INDICATOR_RECORD_FAILURE = 'DASHBOARD_UPDATE_INDICATOR_RECORD_FAILURE';
export const DASHBOARD_INDICATOR_RECORD_APPLIED = 'DASHBOARD_INDICATOR_RECORD_APPLIED';
export const DASHBOARD_INDICATOR_RECORD_REJECTED = 'DASHBOARD_INDICATOR_RECORD_REJECTED';

export const MY_LOAD_ELEMENTS_REQUEST = 'MY_LOAD_ELEMENTS_REQUEST';
export const MY_LOAD_ELEMENTS_SUCCESS = 'MY_LOAD_ELEMENTS_SUCCESS';
export const MY_LOAD_ELEMENTS_FAILURE = 'MY_LOAD_ELEMENTS_FAILURE';
export const MY_LOAD_ASSIGNMENTS_REQUEST = 'MY_LOAD_ASSIGNMENTS_REQUEST';
export const MY_LOAD_ASSIGNMENTS_SUCCESS = 'MY_LOAD_ASSIGNMENTS_SUCCESS';
export const MY_LOAD_ASSIGNMENTS_FAILURE = 'MY_LOAD_ASSIGNMENTS_FAILURE';
export const MY_LOAD_UNITS_REQUEST = 'MY_LOAD_UNITS_REQUEST';
export const MY_LOAD_UNITS_SUCCESS = 'MY_LOAD_UNITS_SUCCESS';
export const MY_LOAD_UNITS_FAILURE = 'MY_LOAD_UNITS_FAILURE';

export const RECORD_DETAILS_LOAD_RECORDS_REQUEST = 'RECORD_DETAILS_LOAD_RECORDS_REQUEST';
export const RECORD_DETAILS_LOAD_RECORDS_SUCCESS = 'RECORD_DETAILS_LOAD_RECORDS_SUCCESS';
export const RECORD_DETAILS_LOAD_RECORDS_FAILURE = 'RECORD_DETAILS_LOAD_RECORDS_FAILURE';

export const RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST = 'RECORD_DETAILS_LOAD_RECORD_DETAILS_REQUEST';
export const RECORD_DETAILS_LOAD_RECORD_DETAILS_SUCCESS = 'RECORD_DETAILS_LOAD_RECORD_DETAILS_SUCCESS';
export const RECORD_DETAILS_LOAD_RECORD_DETAILS_FAILURE = 'RECORD_DETAILS_LOAD_RECORD_DETAILS_FAILURE';
export const RECORD_DETAILS_SET_FILTER = 'RECORD_DETAILS_SET_FILTER';
export const RECORD_DETAILS_SET_SELECTED_RECORD = 'RECORD_DETAILS_SET_SELECTED_RECORD';
export const RECORD_DETAILS_UPDATE_INDICATOR_RECORD_REQUEST = 'RECORD_DETAILS_UPDATE_INDICATOR_RECORD_REQUEST';
export const RECORD_DETAILS_UPDATE_INDICATOR_RECORD_SUCCESS = 'RECORD_DETAILS_UPDATE_INDICATOR_RECORD_SUCCESS';
export const RECORD_DETAILS_UPDATE_INDICATOR_RECORD_FAILURE = 'RECORD_DETAILS_UPDATE_INDICATOR_RECORD_FAILURE';

export const USERS_LOAD_ALL_REQUEST = 'USERS_LOAD_ALL_REQUEST';
export const USERS_LOAD_ALL_SUCCESS = 'USERS_LOAD_ALL_SUCCESS';
export const USERS_LOAD_ALL_FAILURE = 'USERS_LOAD_ALL_FAILURE';

export const ADMIN_LOAD_USER_ASSIGNMENTS_REQUEST = 'ADMIN_LOAD_USER_ASSIGNMENTS_REQUEST';
export const ADMIN_LOAD_USER_ASSIGNMENTS_SUCCESS = 'ADMIN_LOAD_USER_ASSIGNMENTS_SUCCESS';
export const ADMIN_LOAD_USER_ASSIGNMENTS_FAILURE = 'ADMIN_LOAD_USER_ASSIGNMENTS_FAILURE';
export const ADMIN_ADD_USER_ASSIGNMENT_REQUEST = 'ADMIN_ADD_USER_ASSIGNMENT_REQUEST';
export const ADMIN_ADD_USER_ASSIGNMENT_SUCCESS = 'ADMIN_ADD_USER_ASSIGNMENT_SUCCESS';
export const ADMIN_ADD_USER_ASSIGNMENT_FAILURE = 'ADMIN_ADD_USER_ASSIGNMENT_FAILURE';
export const ADMIN_DELETE_USER_ASSIGNMENT_REQUEST = 'ADMIN_DELETE_USER_ASSIGNMENT_REQUEST';
export const ADMIN_DELETE_USER_ASSIGNMENT_SUCCESS = 'ADMIN_DELETE_USER_ASSIGNMENT_SUCCESS';
export const ADMIN_DELETE_USER_ASSIGNMENT_FAILURE = 'ADMIN_DELETE_USER_ASSIGNMENT_FAILURE';
export const ADMIN_LOAD_ELEMENTS_REQUEST = 'ADMIN_LOAD_ELEMENTS_REQUEST';
export const ADMIN_LOAD_ELEMENTS_SUCCESS = 'ADMIN_LOAD_ELEMENTS_SUCCESS';
export const ADMIN_LOAD_ELEMENTS_FAILURE = 'ADMIN_LOAD_ELEMENTS_FAILURE';
export const ADMIN_LOAD_FACILITIES_REQUEST = 'ADMIN_LOAD_FACILITIES_REQUEST';
export const ADMIN_LOAD_FACILITIES_SUCCESS = 'ADMIN_LOAD_FACILITIES_SUCCESS';
export const ADMIN_LOAD_FACILITIES_FAILURE = 'ADMIN_LOAD_FACILITIES_FAILURE';

export const REPORT_ATTACHMENTS_LOAD_REQUEST = 'REPORT_ATTACHMENTS_LOAD_REQUEST';
export const REPORT_ATTACHMENTS_LOAD_SUCCESS = 'REPORT_ATTACHMENTS_LOAD_SUCCESS';
export const REPORT_ATTACHMENTS_LOAD_FAILURE = 'REPORT_ATTACHMENTS_LOAD_FAILURE';
export const REPORT_ATTACHMENTS_LOAD_BY_QUARTER_REQUEST = 'REPORT_ATTACHMENTS_LOAD_BY_QUARTER_REQUEST';
export const REPORT_ATTACHMENTS_LOAD_BY_QUARTER_SUCCESS = 'REPORT_ATTACHMENTS_LOAD_BY_QUARTER_SUCCESS';
export const REPORT_ATTACHMENTS_LOAD_BY_QUARTER_FAILURE = 'REPORT_ATTACHMENTS_LOAD_BY_QUARTER_FAILURE';
export const REPORT_ATTACHMENT_ADD_REQUEST = 'REPORT_ATTACHMENT_ADD_REQUEST';
export const REPORT_ATTACHMENT_ADD_SUCCESS = 'REPORT_ATTACHMENT_ADD_SUCCESS';
export const REPORT_ATTACHMENT_ADD_FAILURE = 'REPORT_ATTACHMENT_ADD_FAILURE';
export const REPORT_ATTACHMENT_DELETE_REQUEST = 'REPORT_ATTACHMENT_DELETE_REQUEST';
export const REPORT_ATTACHMENT_DELETE_SUCCESS = 'REPORT_ATTACHMENT_DELETE_SUCCESS';
export const REPORT_ATTACHMENT_DELETE_FAILURE = 'REPORT_ATTACHMENT_DELETE_FAILURE';

export const RECORDS_SUMMARY_LOAD_REQUEST = 'RECORDS_SUMMARY_LOAD_REQUEST';
export const RECORDS_SUMMARY_LOAD_SUCCESS = 'RECORDS_SUMMARY_LOAD_SUCCESS';
export const RECORDS_SUMMARY_LOAD_FAILURE = 'RECORDS_SUMMARY_LOAD_FAILURE';

export const REPORTS_LOAD_ALL_REQUEST = 'REPORTS_LOAD_ALL_REQUEST';
export const REPORTS_LOAD_ALL_SUCCESS = 'REPORTS_LOAD_ALL_SUCCESS';
export const REPORTS_LOAD_ALL_FAILURE = 'REPORTS_LOAD_ALL_FAILURE';
export const REPORTS_LOAD_REQUEST = 'REPORTS_LOAD_REQUEST';
export const REPORTS_LOAD_SUCCESS = 'REPORTS_LOAD_SUCCESS';
export const REPORTS_LOAD_FAILURE = 'REPORTS_LOAD_FAILURE';
export const REPORTS_LOAD_COMMENTS_REQUEST = 'REPORTS_LOAD_COMMENTS_REQUEST';
export const REPORTS_LOAD_COMMENTS_SUCCESS = 'REPORTS_LOAD_COMMENTS_SUCCESS';
export const REPORTS_LOAD_COMMENTS_FAILURE = 'REPORTS_LOAD_COMMENTS_FAILURE';
export const REPORTS_LOAD_PREVIOUS_COMMENTS_REQUEST = 'REPORTS_LOAD_PREVIOUS_COMMENTS_REQUEST';
export const REPORTS_ADD_COMMENT = 'REPORTS_ADD_COMMENT';
export const REPORTS_UPDATE_EXECUTIVE_SUMMARY_REQUEST = 'REPORTS_UPDATE_EXECUTIVE_SUMMARY_REQUEST';
export const REPORTS_FINALIZE_EXECUTIVE_SUMMARY_REQUEST = 'REPORTS_FINALIZE_EXECUTIVE_SUMMARY_REQUEST';
export const REPORTS_UPDATE_EXECUTIVE_SUMMARY_SUCCESS = 'REPORTS_UPDATE_EXECUTIVE_SUMMARY_SUCCESS';
export const REPORTS_UPDATE_EXECUTIVE_SUMMARY_FAILURE = 'REPORTS_UPDATE_EXECUTIVE_SUMMARY_FAILURE';
export const REPORTS_SAVE_COMMENT_REQUEST = 'REPORTS_SAVE_COMMENT_REQUEST';
export const REPORTS_FINALIZE_COMMENT_REQUEST = 'REPORTS_FINALIZE_COMMENT_REQUEST';
export const REPORTS_CREATE_COMMENT_SUCCESS = 'REPORTS_CREATE_COMMENT_SUCCESS';
export const REPORTS_UPDATE_COMMENT_SUCCESS = 'REPORTS_UPDATE_COMMENT_SUCCESS';
export const REPORTS_SAVE_COMMENT_FAILURE = 'REPORTS_SAVE_COMMENT_FAILURE';
export const REPORTS_DELETE_COMMENT_REQUEST = 'REPORTS_DELETE_COMMENT_REQUEST';
export const REPORTS_DELETE_COMMENT_SUCCESS = 'REPORTS_DELETE_COMMENT_SUCCESS';
export const REPORTS_DELETE_COMMENT_FAILURE = 'REPORTS_DELETE_COMMENT_FAILURE';
export const REPORTS_UPDATE_COMMENT_SEQUENCE_REQUEST = 'REPORTS_UPDATE_COMMENT_SEQUENCE_REQUEST';
export const REPORTS_UPDATE_COMMENT_SEQUENCE_SUCCESS = 'REPORTS_UPDATE_COMMENT_SEQUENCE_SUCCESS';
export const REPORTS_UPDATE_COMMENT_SEQUENCE_FAILURE = 'REPORTS_UPDATE_COMMENT_SEQUENCE_FAILURE';
export const REPORTS_SUBMIT_REQUEST = 'REPORTS_SUBMIT_REQUEST';
export const REPORTS_SUBMIT_SUCCESS = 'REPORTS_SUBMIT_SUCCESS';
export const REPORTS_SUBMIT_FAILURE = 'REPORTS_SUBMIT_FAILURE';
export const REPORTS_APPROVE_REQUEST = 'REPORTS_APPROVE_REQUEST';
export const REPORTS_APPROVE_SUCCESS = 'REPORTS_APPROVE_SUCCESS';
export const REPORTS_APPROVE_FAILURE = 'REPORTS_APPROVE_FAILURE';
export const REPORTS_REVERSE_APPROVE_REQUEST = 'REPORTS_REVERSE_APPROVE_REQUEST';
export const REPORTS_REVERSE_APPROVE_SUCCESS = 'REPORTS_REVERSE_APPROVE_SUCCESS';
export const REPORTS_REVERSE_APPROVE_FAILURE = 'REPORTS_REVERSE_APPROVE_FAILURE';
export const REPORTS_REJECT_REQUEST = 'REPORTS_REJECT_REQUEST';
export const REPORTS_REJECT_SUCCESS = 'REPORTS_REJECT_SUCCESS';
export const REPORTS_REJECT_FAILURE = 'REPORTS_REJECT_FAILURE';
export const REPORTS_CLOSE_REQUEST = 'REPORTS_CLOSE_REQUEST';
export const REPORTS_CLOSE_SUCCESS = 'REPORTS_CLOSE_SUCCESS';
export const REPORTS_CLOSE_FAILURE = 'REPORTS_CLOSE_FAILURE';

export const INDICATORS_LOAD_ALL_REQUEST = 'INDICATORS_LOAD_ALL_REQUEST';
export const INDICATORS_LOAD_ALL_SUCCESS = 'INDICATORS_LOAD_ALL_SUCCESS';
export const INDICATORS_LOAD_ALL_FAILURE = 'INDICATORS_LOAD_ALL_FAILURE';
export const INDICATORS_LOAD_REPORT_INDICATORS_REQUEST = 'INDICATORS_LOAD_REPORT_INDICATORS_REQUEST';
export const INDICATORS_LOAD_REPORT_INDICATORS_SUCCESS = 'INDICATORS_LOAD_REPORT_INDICATORS_SUCCESS';
export const INDICATORS_LOAD_REPORT_INDICATORS_FAILURE = 'INDICATORS_LOAD_REPORT_INDICATORS_FAILURE';

export const CUSTOM_INDICATORS_LOAD_REQUEST = 'CUSTOM_INDICATORS_LOAD_REQUEST';
export const CUSTOM_INDICATORS_LOAD_SUCCESS = 'CUSTOM_INDICATORS_LOAD_SUCCESS';
export const CUSTOM_INDICATORS_LOAD_FAILURE = 'CUSTOM_INDICATORS_LOAD_FAILURE';
export const CUSTOM_INDICATORS_CREATE_REQUEST = 'CUSTOM_INDICATORS_CREATE_REQUEST';
export const CUSTOM_INDICATORS_CREATE_SUCCESS = 'CUSTOM_INDICATORS_CREATE_SUCCESS';
export const CUSTOM_INDICATORS_CREATE_FAILURE = 'CUSTOM_INDICATORS_CREATE_FAILURE';
export const CUSTOM_INDICATORS_ADD_REQUEST = 'CUSTOM_INDICATORS_ADD_REQUEST';
export const CUSTOM_INDICATORS_ADD_SUCCESS = 'CUSTOM_INDICATORS_ADD_SUCCESS';
export const CUSTOM_INDICATORS_ADD_FAILURE = 'CUSTOM_INDICATORS_ADD_FAILURE';
export const CUSTOM_INDICATORS_UPDATE_REQUEST = 'CUSTOM_INDICATORS_UPDATE_REQUEST';
export const CUSTOM_INDICATORS_UPDATE_SUCCESS = 'CUSTOM_INDICATORS_UPDATE_SUCCESS';
export const CUSTOM_INDICATORS_UPDATE_FAILURE = 'CUSTOM_INDICATORS_UPDATE_FAILURE';
export const CUSTOM_INDICATORS_DELETE_REQUEST = 'CUSTOM_INDICATORS_DELETE_REQUEST';
export const CUSTOM_INDICATORS_DELETE_SUCCESS = 'CUSTOM_INDICATORS_DELETE_SUCCESS';
export const CUSTOM_INDICATORS_DELETE_FAILURE = 'CUSTOM_INDICATORS_DELETE_FAILURE';

export const MANUAL_INDICATOR_VALUES_LOAD_REQUEST = 'CUSTOM_INDICATOR_VALUES_LOAD_REQUEST';
export const MANUAL_INDICATOR_VALUES_LOAD_SUCCESS = 'CUSTOM_INDICATOR_VALUES_LOAD_SUCCESS';
export const MANUAL_INDICATOR_VALUES_LOAD_FAILURE = 'CUSTOM_INDICATOR_VALUES_LOAD_FAILURE';
export const MANUAL_INDICATOR_VALUES_SET_REQUEST = 'MANUAL_INDICATOR_VALUES_SET_REQUEST';
export const MANUAL_INDICATOR_VALUES_SET_SUCCESS = 'MANUAL_INDICATOR_VALUES_SET_SUCCESS';
export const MANUAL_INDICATOR_VALUES_SET_FAILURE = 'MANUAL_INDICATOR_VALUES_SET_FAILURE';

export const SCORECARD_LOAD_REQUEST = 'SCORECARD_LOAD_REQUEST';
export const SCORECARD_LOAD_SUCCESS = 'SCORECARD_LOAD_SUCCESS';
export const SCORECARD_LOAD_FAILURE = 'SCORECARD_LOAD_FAILURE';
export const SCORECARD_LOAD_OVERRIDE_REQUEST = 'SCORECARD_LOAD_OVERRIDE_REQUEST';
export const SCORECARD_LOAD_OVERRIDE_SUCCESS = 'SCORECARD_LOAD_OVERRIDE_SUCCESS';
export const SCORECARD_LOAD_OVERRIDE_FAILURE = 'SCORECARD_LOAD_OVERRIDE_FAILURE';
export const SCORECARD_CLEAR_OVERRIDE = 'SCORECARD_CLEAR_OVERRIDE';
export const SCORECARD_OVERRIDE_SCORE_REQUEST = 'SCORECARD_OVERRIDE_SCORE_REQUEST';
export const SCORECARD_OVERRIDE_SCORE_FAILURE = 'SCORECARD_OVERRIDE_SCORE_FAILURE';
export const SCORECARD_RESET_OVERRIDE_SCORE_REQUEST = 'SCORECARD_RESET_OVERRIDE_SCORE_REQUEST';
export const SCORECARD_RESET_OVERRIDE_SCORE_SUCCESS = 'SCORECARD_RESET_OVERRIDE_SCORE_SUCCESS';
export const SCORECARD_RESET_OVERRIDE_SCORE_FAILURE = 'SCORECARD_RESET_OVERRIDE_SCORE_FAILURE';
export const SCORECARD_ERROR_CLEAR = 'SCORECARD_ERROR_CLEAR';
export const SCORECARD_HYDRATE_FROM_CACHE = 'SCORECARD_HYDRATE_FROM_CACHE';
export const SCORECARD_PUBLISH_FAILURE = 'SCORECARD_PUBLISH_FAILURE';

export const REFERENCE_TYPES_LOAD_REQUEST = 'REFERENCE_TYPES_LOAD_REQUEST';
export const REFERENCE_TYPES_LOAD_SUCCESS = 'REFERENCE_TYPES_LOAD_SUCCESS';
export const REFERENCE_TYPES_LOAD_FAILURE = 'REFERENCE_TYPES_LOAD_FAILURE';

export const HEALTH_REPORT_SET_CURRENT_PAGE = 'HEALTH_REPORT_SET_CURRENT_PAGE';
export const HEALTH_REPORT_SET_ACTIVE_STEP = 'HEALTH_REPORT_SET_ACTIVE_STEP';
export const HEALTH_REPORT_START_REPORT_REQUEST = 'HEALTH_REPORT_START_REPORT_REQUEST';
export const HEALTH_REPORT_START_REPORT_FAILURE = 'HEALTH_REPORT_START_REPORT_FAILURE';
export const HEALTH_REPORT_UPDATE_INDICATOR_RECORD_REQUEST = 'HEALTH_REPORT_UPDATE_INDICATOR_RECORD_REQUEST';
export const HEALTH_REPORT_UPDATE_INDICATOR_RECORD_FAILURE = 'HEALTH_REPORT_UPDATE_INDICATOR_RECORD_FAILURE';

export const ACTIONS_LOAD_ALL_REQUEST = 'ACTIONS_LOAD_ALL_REQUEST';
export const ACTIONS_LOAD_ALL_SUCCESS = 'ACTIONS_LOAD_ALL_SUCCESS';
export const ACTIONS_LOAD_ALL_FAILURE = 'ACTIONS_LOAD_ALL_FAILURE';
export const ACTIONS_CREATE_REQUEST = 'ACTIONS_CREATE_REQUEST';
export const ACTIONS_CREATE_FAILURE = 'ACTIONS_CREATE_FAILURE';
export const ACTIONS_UPDATE_REQUEST = 'ACTIONS_UPDATE_REQUEST';
export const ACTIONS_UPDATE_SUCCESS = 'ACTIONS_UPDATE_SUCCESS';
export const ACTIONS_UPDATE_FAILURE = 'ACTIONS_UPDATE_FAILURE';
export const ACTIONS_REORDER_REQUEST = 'ACTIONS_REORDER_REQUEST';
export const ACTIONS_REORDER_SUCCESS = 'ACTIONS_REORDER_SUCCESS';
export const ACTIONS_REORDER_FAILURE = 'ACTIONS_REORDER_FAILURE';
export const ACTIONS_UPDATE_STATUS_REQUEST = 'ACTIONS_UPDATE_STATUS_REQUEST';
export const ACTIONS_UPDATE_STATUS_FAILURE = 'ACTIONS_UPDATE_STATUS_FAILURE';
export const ACTIONS_ACTION_CLOSED = 'ACTIONS_ACTION_CLOSED';
export const ACTIONS_ACTION_REOPENED = 'ACTIONS_ACTION_REOPENED';
export const ACTIONS_ACTION_DELETED = 'ACTIONS_ACTION_DELETED';

export const DASHBOARDS_LOAD_REQUEST = 'DASHBOARDS_LOAD_REQUEST';
export const DASHBOARDS_LOAD_SUCCESS = 'DASHBOARDS_LOAD_SUCCESS';
export const DASHBOARDS_LOAD_FAILURE = 'DASHBOARDS_LOAD_FAILURE';
export const DASHBOARDS_CREATE_REQUEST = 'DASHBOARDS_CREATE_REQUEST';
export const DASHBOARDS_CREATE_SUCCESS = 'DASHBOARDS_CREATE_SUCCESS';
export const DASHBOARDS_CREATE_FAILURE = 'DASHBOARDS_CREATE_FAILURE';
export const DASHBOARDS_DELETE_REQUEST = 'DASHBOARDS_DELETE_REQUEST';
export const DASHBOARDS_DELETE_SUCCESS = 'DASHBOARDS_DELETE_SUCCESS';
export const DASHBOARDS_DELETE_FAILURE = 'DASHBOARDS_DELETE_FAILURE';
export const DASHBOARDS_UPDATE_REQUEST = 'DASHBOARDS_UPDATE_REQUEST';
export const DASHBOARDS_UPDATE_SUCCESS = 'DASHBOARDS_UPDATE_SUCCESS';
export const DASHBOARDS_UPDATE_FAILURE = 'DASHBOARDS_UPDATE_FAILURE';
export const MY_DASHBOARDS_LOAD_REQUEST = 'MY_DASHBOARDS_LOAD_REQUEST';
export const MY_DASHBOARDS_LOAD_SUCCESS = 'MY_DASHBOARDS_LOAD_SUCCESS';
export const MY_DASHBOARDS_LOAD_FAILURE = 'MY_DASHBOARDS_LOAD_FAILURE';
export const MY_DASHBOARDS_ADD_FAVOURITE_REQUEST = 'MY_DASHBOARDS_ADD_FAVOURITE_REQUEST';
export const MY_DASHBOARDS_ADD_FAVOURITE_SUCCESS = 'MY_DASHBOARDS_ADD_FAVOURITE_SUCCESS';
export const MY_DASHBOARDS_ADD_FAVOURITE_FAILURE = 'MY_DASHBOARDS_ADD_FAVOURITE_FAILURE';
export const MY_DASHBOARDS_REMOVE_FAVOURITE_SUCCESS = 'MY_DASHBOARDS_REMOVE_FAVOURITE_SUCCESS';
export const MY_DASHBOARDS_REMOVE_FAVOURITE_REQUEST = 'MY_DASHBOARDS_REMOVE_FAVOURITE_REQUEST';
export const MY_DASHBOARDS_REMOVE_FAVOURITE_FAILURE = 'MY_DASHBOARDS_REMOVE_FAVOURITE_FAILURE';

export const ELEMENTS_LOAD_ALL_REQUEST = 'ELEMENTS_LOAD_ALL_REQUEST';
export const ELEMENTS_LOAD_ALL_SUCCESS = 'ELEMENTS_LOAD_ALL_SUCCESS';
export const ELEMENTS_LOAD_ALL_FAILURE = 'ELEMENTS_LOAD_ALL_FAILURE';
export const ELEMENTS_SET_ACTIVE_REQUEST = 'ELEMENTS_SET_ACTIVE_REQUEST';
export const ELEMENTS_SET_ACTIVE_SUCCESS = 'ELEMENTS_SET_ACTIVE_SUCCESS';
export const ELEMENTS_SET_ACTIVE_FAILURE = 'ELEMENTS_SET_ACTIVE_FAILURE';
export const ELEMENTS_LOAD_BACKUP_RSES_REQUEST = 'ELEMENTS_LOAD_BACKUP_RSES_REQUEST';
export const ELEMENTS_LOAD_BACKUP_RSES_SUCCESS = 'ELEMENTS_LOAD_BACKUP_RSES_SUCCESS';
export const ELEMENTS_LOAD_BACKUP_RSES_FAILURE = 'ELEMENTS_LOAD_BACKUP_RSES_FAILURE';
export const ELEMENTS_ADD_ELEMENT_MAPPINGS_SUCCESS = 'ELEMENTS_ADD_ELEMENT_MAPPINGS_SUCCESS';
export const ELEMENTS_ADD_ELEMENT_MAPPINGS_FAILURE = 'ELEMENTS_ADD_ELEMENT_MAPPINGS_FAILURE';
export const ELEMENTS_REMOVE_ELEMENT_MAPPINGS_SUCCESS = 'ELEMENTS_REMOVE_ELEMENT_MAPPINGS_SUCCESS';
export const ELEMENTS_REMOVE_ELEMENT_MAPPINGS_FAILURE = 'ELEMENTS_REMOVE_ELEMENT_MAPPINGS_FAILURE';
export const ELEMENTS_LOAD_ELEMENT_UNITS_REQUEST = 'ELEMENTS_LOAD_ELEMENT_UNITS_REQUEST';
export const ELEMENTS_LOAD_ELEMENT_UNITS_SUCCESS = 'ELEMENTS_LOAD_ELEMENT_UNITS_SUCCESS';
export const ELEMENTS_LOAD_ELEMENT_UNITS_FAILURE = 'ELEMENTS_LOAD_ELEMENT_UNITS_FAILURE';
export const ELEMENTS_SET_PRIMARY_RSE_REQUEST = 'ELEMENTS_SET_PRIMARY_RSE_REQUEST';
export const ELEMENTS_SET_PRIMARY_RSE_SUCCESS = 'ELEMENTS_SET_PRIMARY_RSE_SUCCESS';
export const ELEMENTS_SET_PRIMARY_RSE_FAILURE = 'ELEMENTS_SET_PRIMARY_RSE_FAILURE';
export const ELEMENTS_CLEAR_PRIMARY_RSE_REQUEST = 'ELEMENTS_CLEAR_PRIMARY_RSE_REQUEST';
export const ELEMENTS_CLEAR_PRIMARY_RSE_SUCCESS = 'ELEMENTS_CLEAR_PRIMARY_RSE_SUCCESS';
export const ELEMENTS_CLEAR_PRIMARY_RSE_FAILURE = 'ELEMENTS_CLEAR_PRIMARY_RSE_FAILURE';
export const ELEMENTS_SET_BACKUP_RSE_REQUEST = 'ELEMENTS_SET_BACKUP_RSE_REQUEST';
export const ELEMENTS_SET_BACKUP_RSE_SUCCESS = 'ELEMENTS_SET_BACKUP_RSE_SUCCESS';
export const ELEMENTS_SET_BACKUP_RSE_FAILURE = 'ELEMENTS_SET_BACKUP_RSE_FAILURE';
export const ELEMENTS_CLEAR_BACKUP_RSE_REQUEST = 'ELEMENTS_CLEAR_BACKUP_RSE_REQUEST';
export const ELEMENTS_CLEAR_BACKUP_RSE_SUCCESS = 'ELEMENTS_CLEAR_BACKUP_RSE_SUCCESS';
export const ELEMENTS_CLEAR_BACKUP_RSE_FAILURE = 'ELEMENTS_CLEAR_BACKUP_RSE_FAILURE';
export const ELEMENTS_LOAD_UNIT_WEIGHTS_REQUEST = 'ELEMENTS_LOAD_UNIT_WEIGHTS_REQUEST';
export const ELEMENTS_LOAD_UNIT_WEIGHTS_SUCCESS = 'ELEMENTS_LOAD_UNIT_WEIGHTS_SUCCESS';
export const ELEMENTS_LOAD_UNIT_WEIGHTS_FAILURE = 'ELEMENTS_LOAD_UNIT_WEIGHTS_FAILURE';
export const ELEMENTS_LOAD_REQUEST = 'ELEMENTS_LOAD_REQUEST';
export const ELEMENTS_LOAD_SUCCESS = 'ELEMENTS_LOAD_SUCCESS';
export const ELEMENTS_LOAD_FAILURE = 'ELEMENTS_LOAD_FAILURE';
export const ELEMENTS_PATCH_REQUEST = 'ELEMENTS_PATCH_REQUEST';
export const ELEMENTS_PATCH_SUCCESS = 'ELEMENTS_PATCH_SUCCESS';
export const ELEMENTS_PATCH_FAILURE = 'ELEMENTS_PATCH_FAILURE';
export const ELEMENTS_LOAD_CATEGORIES_REQUEST = 'ELEMENTS_LOAD_CATEGORIES_REQUEST';
export const ELEMENTS_LOAD_CATEGORIES_SUCCESS = 'ELEMENTS_LOAD_CATEGORIES_SUCCESS';
export const ELEMENTS_LOAD_CATEGORIES_FAILURE = 'ELEMENTS_LOAD_CATEGORIES_FAILURE';
export const ELEMENTS_LOAD_FREQUENCIES_REQUEST = 'ELEMENTS_LOAD_FREQUENCIES_REQUEST';
export const ELEMENTS_LOAD_FREQUENCIES_SUCCESS = 'ELEMENTS_LOAD_FREQUENCIES_SUCCESS';
export const ELEMENTS_LOAD_FREQUENCIES_FAILURE = 'ELEMENTS_LOAD_FREQUENCIES_FAILURE';
export const ELEMENTS_LOAD_SCHEMAS_REQUEST = 'ELEMENTS_LOAD_SCHEMAS_REQUEST';
export const ELEMENTS_LOAD_SCHEMAS_SUCCESS = 'ELEMENTS_LOAD_SCHEMAS_SUCCESS';
export const ELEMENTS_LOAD_SCHEMAS_FAILURE = 'ELEMENTS_LOAD_SCHEMAS_FAILURE';
export const ELEMENTS_LOAD_FACILITIES_REQUEST = 'ELEMENTS_LOAD_FACILITIES_REQUEST';
export const ELEMENTS_LOAD_FACILITIES_SUCCESS = 'ELEMENTS_LOAD_FACILITIES_SUCCESS';
export const ELEMENTS_LOAD_FACILITIES_FAILURE = 'ELEMENTS_LOAD_FACILITIES_FAILURE';

export const UI_POP_MESSAGE = 'UI_POP_MESSAGE';
export const UI_GENERIC_MESSAGE = 'UI_GENERIC_MESSAGE';
export const UI_LOADING_SPINNER_TRUE = 'UI_LOADING_SPINNER_TRUE';
export const UI_LOADING_SPINNER_FALSE = 'UI_LOADING_SPINNER_FALSE';

export const TOTAL_SCORE_COLOURS_LOAD_ALL_REQUEST = 'TOTAL_SCORE_COLOURS_LOAD_ALL_REQUEST';
export const TOTAL_SCORE_COLOURS_LOAD_ALL_SUCCESS = 'TOTAL_SCORE_COLOURS_LOAD_ALL_SUCCESS';
export const TOTAL_SCORE_COLOURS_LOAD_ALL_FAILURE = 'TOTAL_SCORE_COLOURS_LOAD_ALL_FAILURE';

export const INDICATOR_RECORDS_LOAD_REQUEST = 'INDICATOR_RECORDS_LOAD_REQUEST';
export const INDICATOR_RECORDS_LOAD_SUCCESS = 'INDICATOR_RECORDS_LOAD_SUCCESS';
export const INDICATOR_RECORDS_LOAD_FAILURE = 'INDICATOR_RECORDS_LOAD_FAILURE';
export const INDICATOR_RECORDS_LOAD_BY_QUARTER_REQUEST = 'INDICATOR_RECORDS_LOAD_BY_QUARTER_REQUEST';
export const INDICATOR_RECORDS_LOAD_BY_QUARTER_SUCCESS = 'INDICATOR_RECORDS_LOAD_BY_QUARTER_SUCCESS';
export const INDICATOR_RECORDS_LOAD_BY_QUARTER_FAILURE = 'INDICATOR_RECORDS_LOAD_BY_QUARTER_FAILURE';
export const INDICATOR_RECORDS_UPDATE_APPLICABILITY_REQUEST = 'INDICATOR_RECORDS_UPDATE_APPLICABILITY_REQUEST';
export const INDICATOR_RECORDS_UPDATE_APPLICABILITY_SUCCESS = 'INDICATOR_RECORDS_UPDATE_APPLICABILITY_SUCCESS';
export const INDICATOR_RECORDS_UPDATE_APPLICABILITY_FAILURE = 'INDICATOR_RECORDS_UPDATE_APPLICABILITY_FAILURE';

export const SCORECARD_COLOURS_LOAD_REQUEST = 'SCORECARD_COLOURS_LOAD_REQUEST';
export const SCORECARD_COLOURS_LOAD_SUCCESS = 'SCORECARD_COLOURS_LOAD_SUCCESS';
export const SCORECARD_COLOURS_LOAD_FAILURE = 'SCORECARD_COLOURS_LOAD_FAILURE';

export const COLOUR_VALUES_LOAD_REQUEST = 'COLOUR_VALUES_LOAD_REQUEST';
export const COLOUR_VALUES_LOAD_SUCCESS = 'COLOUR_VALUES_LOAD_SUCCESS';
export const COLOUR_VALUES_LOAD_FAILURE = 'COLOUR_VALUES_LOAD_FAILURE';

export const EXECUTIVE_SUMMARY_UI_RESET = 'EXECUTIVE_SUMMARY_UI_RESET';
export const EXECUTIVE_SUMMARY_UI_EDIT_COMMENT = 'EXECUTIVE_SUMMARY_UI_EDIT_COMMENT';
export const EXECUTIVE_SUMMARY_UI_EDIT_EXECUTIVE_SUMMARY = 'EXECUTIVE_SUMMARY_UI_EDIT_EXECUTIVE_SUMMARY';

export const OVERRIDE_VALUE_SET_REQUEST = 'OVERRIDE_VALUE_SET_REQUEST';
export const OVERRIDE_VALUE_SET_SUCCESS = 'OVERRIDE_VALUE_SET_SUCCESS';
export const OVERRIDE_VALUE_SET_FAILURE = 'OVERRIDE_VALUE_SET_FAILURE';

export const INDICATOR_ADD_SUCCESS = 'INDICATOR_ADD_SUCCESS';
export const INDICATOR_REMOVE_SUCCESS = 'INDICATOR_REMOVE_SUCCESS';
export const INDICATOR_UPDATE_SUCCESS = 'INDICATOR_UPDATE_SUCCESS';
export const INDICATOR_ADD_FAILURE = 'INDICATOR_ADD_FAILURE';
export const INDICATOR_REMOVE_FAILURE = 'INDICATOR_REMOVE_FAILURE';
export const INDICATOR_UPDATE_FAILURE = 'INDICATOR_UPDATE_FAILURE';
export const INDICATOR_SUM_FAILURE = 'INDICATOR_SUM_FAILURE';
export const INDICATOR_SUM_SUCCESS = 'INDICATOR_SUM_SUCCESS';

export const SET_REFERENCE_QUARTER_REQUEST = 'SET_REFERENCE_QUARTER_REQUEST';
export const SET_REFERENCE_QUARTER_SUCCESS = 'SET_REFERENCE_QUARTER_SUCCESS';
export const SET_REFERENCE_QUARTER_FAILURE = 'SET_REFERENCE_QUARTER_FAILURE';

export const SET_OVERRIDE_REPORT_DUE_DATE_REQUEST = 'SET_OVERRIDE_REPORT_DUE_DATE_REQUEST';
export const SET_OVERRIDE_REPORT_DUE_DATE_SUCCESS = 'SET_OVERRIDE_REPORT_DUE_DATE_SUCCESS';
export const SET_OVERRIDE_REPORT_DUE_DATE_FAILURE = 'SET_OVERRIDE_REPORT_DUE_DATE_FAILURE';

export const SET_OVERRIDE_PMP_DATE_REQUEST = 'SET_OVERRIDE_PMP_DATE_REQUEST';
export const SET_OVERRIDE_PMP_DATE_SUCCESS = 'SET_OVERRIDE_PMP_DATE_SUCCESS';
export const SET_OVERRIDE_PMP_DATE_FAILURE = 'SET_OVERRIDE_PMP_DATE_FAILURE';

export const DEFERRAL_REQUEST_SUCCESS = 'DEFERRAL_REQUEST_SUCCESS';
export const DEFERRAL_REQUEST_FAILURE = 'DEFERRAL_REQUEST_FAILURE';
export const DEFERRAL_REQUEST_APPROVE_SUCCESS = 'DEFERRAL_REQUEST_APPROVE_SUCCESS';
export const DEFERRAL_REQUEST_APPROVE_FAILURE = 'DEFERRAL_REQUEST_APPROVE_FAILURE';
export const DEFERRAL_REQUEST_REJECT_SUCCESS = 'DEFERRAL_REQUEST_REJECT_SUCCESS';
export const DEFERRAL_REQUEST_REJECT_FAILURE = 'DEFERRAL_REQUEST_REJECT_FAILURE';
export const DEFERRAL_REQUEST_DELETE_SUCCESS = 'DEFERRAL_REQUEST_DELETE_SUCCESS';
export const DEFERRAL_REQUEST_DELETE_FAILURE = 'DEFERRAL_REQUEST_DELETE_FAILURE';
export const DEFERRAL_REQUEST_RESUBMIT_SUCCESS = 'DEFERRAL_REQUEST_RESUBMIT_SUCCESS';
export const DEFERRAL_REQUEST_RESUBMIT_FAILURE = 'DEFERRAL_REQUEST_RESUBMIT_FAILURE';

export const PROGRAM_INDICATOR_UPDATE_SUCCESS = 'PROGRAM_INDICATOR_UPDATE_SUCCESS';
export const PROGRAM_INDICATOR_UPDATE_FAILURE = 'PROGRAM_INDICATOR_UPDATE_FAILURE';

export const UI_NOTES = 'UI_NOTES';
