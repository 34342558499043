import styled from 'styled-components';
import chevronIcon from '../assets/chevron-right.svg';

const ChevronIcon = styled.img.attrs({
  src: chevronIcon,
})`
  display: block;
  height: 12px;
  width: 8px;
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'none')};
  transition: transform 0.3s;
  cursor: pointer;
`;

export default ChevronIcon;
