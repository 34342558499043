import { Path, Remote } from '../types';

const updateActionPriority = (path: Path, remote: Remote) => (actionId: string, priority: any) =>
  remote.patch(path(`/${actionId}`), { priority });

const updateActionStatus = (path: Path, remote: Remote) => (actionId: string, state: string, comment: string) =>
  remote.patch(path(`/${actionId}`), { state, comment });

const createAction = (path: Path, remote: Remote) => (action: any) => remote.post(path('/'), { action });

const updateAction = (path: Path, remote: Remote) => (action: any) => remote.put(path(`/${action.id}`), { action });

export default (path: Path, remote: Remote) => ({
  updateActionPriority: updateActionPriority(path, remote),
  createAction: createAction(path, remote),
  updateAction: updateAction(path, remote),
  updateActionStatus: updateActionStatus(path, remote),
});
