import { useEffect, useState } from 'react';
import Api from '../api';

// NOTE: This gets a Program Indicator

const useProgramIndicator = (indicatorId: number, elementId: number) => {
  const [indicator, setIndicator] = useState([] as any);

  useEffect(() => {
    if (!indicatorId) {
      setIndicator({});
    } else {
      Api.getProgramElementIndicator(indicatorId, elementId).then(setIndicator);
    }
  }, [indicatorId]);

  return {
    indicator,
  };
};

export default useProgramIndicator;
