import React from 'react';
import { dateToString } from '../../utils/date-format';
import ActionsCell from './ActionsCell';
import CellText from './CellText';
import SHFRCell from './SHFRCell';
import MultiLineText from '../MultiLineText';
import isFeatureEnabled from '../../utils/feature-flags';

const getTextFromReference = (reference: any) => `${reference.typeName} ${reference.referenceNumber}`;

const capitalize = (value: any) => (value ? value.charAt(0).toUpperCase() + value.slice(1) : '');

const getTextFromExpectedOutcome = (eo: any) =>
  `${capitalize(eo.changeType)} ${eo.indicatorName} ${eo.colourStatus ? `to ${capitalize(eo.colourStatus)}` : ''} for ${
    eo.elementUnit
  } ${eo.amount ? `by ${eo.amount}` : ''}`;

//  Interfaces
interface TargetDateWrapper {
  targetDate: Date;
}

interface DateWorkFinishedWrapper {
  dateWorkFinished: Date;
  stateChangedAt: Date;
}

//  Columns
const tableColumns = (
  state: string,
  onEdit?: any,
  onChangeStatus?: any,
  onDelete?: any,
  risks?: string[],
  columnOptions?: any
) => {
  const colList = [
    {
      id: 'displayId',
      label: 'Item #',
      width: '80px',
      align: 'right',
    },
    {
      id: 'references',
      label: 'References',
      width: '150px',
      align: 'left',
      render: (action: any) =>
        action.references.map((reference: any) => (
          <CellText key={reference.id + action.id + 'cell-text'}>{getTextFromReference(reference)}</CellText>
        )),
    },
    {
      id: 'expectedOutcomes',
      label: 'Expected Outcome',
      width: '200px',
      align: 'left',
      render: (action: any) =>
        action.expectedOutcomes.map((eo: any) => (
          <React.Fragment key={eo.id + action.id + 'expectedOutcome'}>
            <CellText>{getTextFromExpectedOutcome(eo)}</CellText>
            <br />
          </React.Fragment>
        )),
    },
    {
      id: 'ownerName',
      label: 'Owner',
      width: '130px',
      align: 'left',
    },
    {
      id: 'tcd',
      label: 'TCD',
      width: '140px',
      align: 'left',
      render: ({ targetDate }: TargetDateWrapper) => (
        <CellText key={targetDate + 'tcd'}>{dateToString(targetDate)}</CellText>
      ),
    },
    {
      id: 'relatedRiskScore',
      label: 'Related Risk',
      width: '90px',
      align: 'left',
      render: ({ relatedRiskScore, relatedRiskLabel }: any) => {
        if (risks?.includes(relatedRiskScore)) {
          return (
            <a href={`/app/business-equipment-risks/${relatedRiskScore}/archive`}>
              {relatedRiskLabel ?? relatedRiskScore}
            </a>
          );
        } else {
          return relatedRiskScore;
        }
      },
    },
    ...(columnOptions?.state === 'ongoing'
      ? []
      : [
          {
            id: 'stateChangeComment',
            label: 'Completion Notes',
            width: '15em',
            align: 'left',
          },
        ]),
    {
      id: 'systemHealthFocusRating',
      label: 'SH Rating',
      width: '120px',
      align: 'left',
      render: SHFRCell,
    },
    ...(isFeatureEnabled('actionSource')
      ? [
          {
            id: 'source',
            label: 'System Source',
            align: 'left',
            width: '110px',
          },
        ]
      : []),
    {
      id: 'description',
      label: 'Comments',
      align: 'left',
      width: '210px',
      render: MultiLineText,
    },
  ];

  if (columnOptions?.state === 'complete') {
    colList.push({
      id: 'dateWorkFinished',
      label: 'Date Work Finished',
      width: '120px',
      align: 'left',
      render: ({ dateWorkFinished, stateChangedAt }: DateWorkFinishedWrapper) => (
        <CellText>{dateWorkFinished ? dateToString(dateWorkFinished) : dateToString(stateChangedAt)}</CellText>
      ),
    });
  }

  if (onEdit || onChangeStatus || onDelete) {
    colList.push({
      id: 'actions',
      label: '',
      width: '92px',
      align: 'center',
      render: (action: any) => (
        <ActionsCell
          className=""
          state={state}
          action={action}
          onEdit={onEdit}
          onChangeStatus={onChangeStatus}
          onDelete={onDelete}
        />
      ),
    });
  }

  return colList;
};

export default tableColumns;
