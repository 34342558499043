import React from 'react';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';

const Typography = styled(UnstyledTypography)`
  font: ${({ theme }) => theme.fonts.medium16};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  padding: 12px 14px;
  border: ${({ theme }) => theme.borders.thin.solid6};
`;

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AttachmentName = ({ children, className }: Props) => (
  <Container className={className}>
    <Typography>{children}</Typography>
  </Container>
);

export default AttachmentName;
