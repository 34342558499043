import React, { useContext, useState, useEffect } from 'react';

import * as R from 'ramda';
import Api from '../api';

const UnitsContext = React.createContext();

const useUnits = () => {
  const context = useContext(UnitsContext);
  if (R.isNil(context)) {
    throw new Error('useUnits must be used inside an UnitsProvider');
  }
  return context;
};

export const UnitsProvider = ({ ...props }) => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    Api.getUnits().then(setUnits);
  }, [setUnits]);

  const value = {
    units,
  };

  return (
    <UnitsContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useUnits;
