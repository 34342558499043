import { Button, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { WalkdownTemplateTask } from '../../../../types/walkdown-template';
import CreateTask from './CreateTask/CreateTask';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import TaskRow from './TaskRow';

interface Props {
  tasks: WalkdownTemplateTask[];
  categoryId: number;
  onRemoveCategory(id: any): any;
  onCreateTask(categoryId: any, task: WalkdownTemplateTask): any;
  onUpdateTask(categoryId: any, task: WalkdownTemplateTask): any;
  onRemoveTask(categoryId: any, t: WalkdownTemplateTask): any;
  categoryName: string;
}

const TasksList = ({
  tasks,
  categoryId,
  onRemoveCategory,
  onCreateTask,
  onRemoveTask,
  categoryName,
  onUpdateTask,
}: Props) => {
  const [creating, setCreating] = useState(false);

  const saveHandler = useCallback(
    async (task: WalkdownTemplateTask) => {
      await onCreateTask(categoryId, task);
      setCreating(false);
    },
    [categoryId, onCreateTask]
  );

  const [removing, setRemoving] = useState(false);

  const [removingTask, setRemovingTask] = useState<WalkdownTemplateTask | null>(null);

  return (
    <Grid
      container
      spacing={2}
    >
      {removing && (
        <OkayCancelDialog
          title={`Are you sure you want to remove category ${categoryName}`}
          onOkay={() => onRemoveCategory(categoryId)}
          onCancel={() => setRemoving(false)}
          okayLabel="Delete"
        />
      )}

      {removingTask && (
        <OkayCancelDialog
          title={`Are you sure you want to remove task ${removingTask.title}`}
          onOkay={() => {
            onRemoveTask(categoryId, removingTask);
            setRemovingTask(null);
          }}
          onCancel={() => setRemovingTask(null)}
          okayLabel="Delete"
        />
      )}
      <Grid
        item
        xs={12}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Task Title</TableCell>
                <TableCell>Task Description</TableCell>
                <TableCell align="center">Measurement</TableCell>
                <TableCell align="center">Range</TableCell>
                <TableCell align="center">UOM</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {[...tasks].map(r => (
                <TaskRow
                  task={r}
                  key={r.id}
                  setRemovingTask={setRemovingTask}
                  onUpdateTask={t => onUpdateTask(categoryId, t)}
                />
              ))}

              {creating && <CreateTask onCreate={saveHandler} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Button onClick={() => onRemoveCategory(setRemoving(true))}>Remove</Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={() => setCreating(true)}
          >
            + Add Task
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TasksList;
