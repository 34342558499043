import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as reportsActions from '../state/reports/actions';
import * as reportsSelectors from '../state/reports/selectors';

const useReportComments = (reportId, loadComments = true) => {
  const dispatch = useDispatch();
  const comments = useSelector(state => reportsSelectors.getComments(state, reportId));

  useEffect(() => {
    if (loadComments) {
      dispatch(reportsActions.loadReportCommentsRequest(reportId));
    }
  }, [dispatch, reportId, loadComments]);

  return {
    comments,
  };
};

export default useReportComments;
