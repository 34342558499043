import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { useSchedule } from '../../hooks/useSchedule';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import { ScheduleEvent } from '../../../../types/cwt/schedule';
import { useEffect, useMemo } from 'react';
interface Props {
  handleClick?: (info: any) => void;
}
const ScheduleCalendar = ({ handleClick }: Props) => {
  const { events, onDragDrop, filters, fetchSchedule } = useSchedule();
  const { workorders } = useWorkOrders();

  useEffect(() => {
    fetchSchedule();
  }, [workorders, filters]);
  const filteredEvents = useMemo(() => {
    const scheduleWithWorkorder = events.map((event: ScheduleEvent) => {
      const workorder = workorders.get(event.workorderId);
      return {
        ...event,
        wonum: workorder?.wonum,
        unit: workorder?.unit,
        wopriority: workorder?.wopriority,
        worktype: workorder?.worktype,
      };
    });

    const filteredSchedule = scheduleWithWorkorder.filter((event: any) => {
      return (
        (!filters.wonum.length || filters.wonum.includes(event.wonum)) &&
        (!filters.unit.length || filters.unit.includes(event.unit)) &&
        (!filters.wopriority.length || filters.wopriority.includes(event.wopriority?.toString())) &&
        (!filters.worktype.length || filters.worktype.includes(event.worktype)) &&
        (!filters.crewworkgroup.length || filters.crewworkgroup.includes(event.crewId)) &&
        (!filters.location.length || filters.location.includes(event.location))
      );
    });
    return events.filter(event => filteredSchedule.map(schedule => schedule.id).includes(event.id));
  }, [events, filters, workorders]);
  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
      initialView="dayGridMonth"
      height="auto"
      events={filteredEvents}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
      }}
      editable={true}
      eventDrop={onDragDrop}
      eventResize={onDragDrop}
      eventClick={handleClick}
    />
  );
};

export default ScheduleCalendar;
