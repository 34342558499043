import React, { useCallback } from 'react';

import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import useUserNotes from '../../hooks/user-notes';

import NewNote from './NewNote';
import Note from './Note';

const Notes = ({ isArchived }: { isArchived?: boolean }) => {
  const { addNote, userNotes, setDone, setUpdate } = useUserNotes();

  const handleOnDone = useCallback(
    (id: number) => {
      setDone(id);
    },
    [setDone]
  );

  const handleSetUpdate = useCallback(
    (id: number, note: string) => {
      setUpdate(id, note);
    },
    [setUpdate]
  );

  const handleOnCreateSubmit = useCallback(
    ({ note }: { note: string }) => {
      addNote(note);
    },
    [addNote]
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      style={{ marginBottom: '15%' }}
    >
      {!isArchived && (
        <Grid
          item
          container
          style={{ marginBottom: '8%' }}
        >
          <NewNote onSubmit={handleOnCreateSubmit} />
        </Grid>
      )}
      {!R.isEmpty(userNotes) && (
        <Grid
          container
          direction="column"
          item
          spacing={1}
        >
          {userNotes
            .filter(({ isDone }) => (isArchived ? isDone : !isDone))
            .sort((a, b) => new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime())
            .map(({ note, id, createdOn, isDone }) => (
              <Note
                key={id}
                id={id}
                note={note}
                isDone={isDone}
                createdOn={new Date(createdOn).toLocaleDateString('en-CA', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
                onDone={handleOnDone}
                onUpdate={handleSetUpdate}
              />
            ))}
        </Grid>
      )}
    </Grid>
  );
};

export default Notes;
