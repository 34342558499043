import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';

import * as actionTypes from '../action-types';
import * as dashboardsActions from './actions';

function* loadMyDashboards() {
  try {
    const dashboards = yield call(Api.getMyDashboards);
    yield put(dashboardsActions.loadMyDashboardsSuccess(dashboards));
  } catch (e) {
    yield put(dashboardsActions.loadMyDashboardsFailure(e));
  }
}

function* addDashboardFavourite(action) {
  try {
    const { dashboardId } = action.payload;
    const payload = yield call(Api.addMyDashboardFavourite, dashboardId);
    yield put(dashboardsActions.addMyDashboardFavouriteSuccess(payload.dashboardId));
  } catch (e) {
    yield put(dashboardsActions.addMyDashboardFavouriteFailure(e));
  }
}

function* removeDashboardFavourite(action) {
  try {
    const { dashboardId } = action.payload;
    const payload = yield call(Api.removeMyDashboardFavourite, dashboardId);
    yield put(dashboardsActions.removeMyDashboardFavouriteSuccess(payload.dashboardId));
  } catch (e) {
    yield put(dashboardsActions.removeMyDashboardFavouriteFailure(e));
  }
}

function* loadDashboards() {
  try {
    const dashboards = yield call(Api.getDashboards);
    yield put(dashboardsActions.loadDashboardsSuccess(dashboards));
  } catch (e) {
    yield put(dashboardsActions.loadDashboardsFailure(e));
  }
}

function* createDashboard(action) {
  try {
    const { dashboard } = action.payload;
    const dashboardId = yield call(Api.addDashboard, dashboard);
    yield put(dashboardsActions.createDashboardSuccess({ ...dashboard, id: dashboardId }));
  } catch (e) {
    yield put(dashboardsActions.createDashboardFailure(e));
  }
}

function* deleteDashboard(action) {
  try {
    const { dashboardId } = action.payload;
    yield call(Api.removeDashboard, dashboardId);
    yield put(dashboardsActions.deleteDashboardSuccess(dashboardId));
  } catch (e) {
    yield put(dashboardsActions.deleteDashboardFailure(e));
  }
}

function* updateDashboard(action) {
  try {
    const { dashboard } = action.payload;
    yield call(Api.updateDashboard, dashboard);
    yield put(dashboardsActions.updateDashboardSuccess(dashboard));
  } catch (e) {
    yield put(dashboardsActions.updateDashboardFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.MY_DASHBOARDS_LOAD_REQUEST, loadMyDashboards);
  yield takeEvery(actionTypes.MY_DASHBOARDS_ADD_FAVOURITE_REQUEST, addDashboardFavourite);
  yield takeEvery(actionTypes.MY_DASHBOARDS_REMOVE_FAVOURITE_REQUEST, removeDashboardFavourite);
  yield takeEvery(actionTypes.DASHBOARDS_LOAD_REQUEST, loadDashboards);
  yield takeEvery(actionTypes.DASHBOARDS_CREATE_REQUEST, createDashboard);
  yield takeEvery(actionTypes.DASHBOARDS_DELETE_REQUEST, deleteDashboard);
  yield takeEvery(actionTypes.DASHBOARDS_UPDATE_REQUEST, updateDashboard);
}

export default root;
