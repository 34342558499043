import React from 'react';
import styled from 'styled-components';
import ConditionReportDetails from './ConditionReportDetails';

const Container = styled.div``;

interface Props {
  className?: string;
  recordDetails: any;
  dense?: boolean;
}

const RegdocReportableEventDetails = ({ className, recordDetails, dense }: Props) => (
  <Container className={className}>
    {recordDetails.conditionReport && (
      <ConditionReportDetails
        conditionReport={recordDetails.conditionReport}
        dense={dense}
      />
    )}
  </Container>
);

export default RegdocReportableEventDetails;
