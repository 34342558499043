import * as R from 'ramda';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Table from '../../../../../../components/Table';
import DownloadButton from '../../../../../../components/DownloadButton';
import { dateToString } from '../../../../../../utils/date-format';
import { useAttachmentList } from './useAttachmentList';
import LoadingContainer from '../../../../../../components/LoadingContainer';
import useUiMessages from '../../../../../../hooks/ui-messages';

const AttachmentList = () => {
  const { attachments, downloadAttachment } = useAttachmentList();
  const { isLoading } = useUiMessages();

  const rows = attachments.map((attachment: any) => (
    <TableRow key={attachment.id}>
      <TableCell>{attachment.filename}</TableCell>
      <TableCell>{attachment.typeName}</TableCell>
      <TableCell>
        {attachment.year}-Q{attachment.quarter}
      </TableCell>
      <TableCell>{dateToString(attachment.uploadedAt)}</TableCell>
      <TableCell
        padding="none"
        align="right"
      >
        <DownloadButton onClick={R.thunkify(downloadAttachment)(attachment.id)} />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <LoadingContainer
        loading={isLoading}
        overlay={true}
        indicatorSize={150}
      />
      {R.isEmpty(attachments) ? (
        <Typography>No records</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Quarter</TableCell>
                <TableCell>Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AttachmentList;
