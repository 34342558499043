import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as R from 'ramda';
import Api from '../api';

const AllColourValuesContext = React.createContext();

const useAllColourValues = () => {
  const context = useContext(AllColourValuesContext);
  if (R.isNil(context)) {
    throw new Error('useAllColourValues must be used inside an AllColourValuesContext');
  }
  return context;
};

export const AllColourValuesProvider = props => {
  const [colourValues, setColourValues] = useState([]);

  useEffect(() => {
    Api.getColourValues().then(setColourValues);
  }, [setColourValues]);

  const colourValuesByColour = useMemo(
    () =>
      R.reduce(
        (acc, colourValue) => ({
          ...acc,
          [colourValue.colour]: colourValue.value,
        }),
        {},
        colourValues
      ),
    [colourValues]
  );

  const value = {
    colourValues,
    colourValuesByColour,
  };

  return (
    <AllColourValuesContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useAllColourValues;
