import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

interface Props {
  className?: string;
  userId: string;
  name: string;
}

const UserLink = ({ className, userId, name }: Props) =>
  userId ? (
    <Typography
      className={className}
      display="inline"
    >
      <Link to={`/app/admin/users?userId=${userId}`}>{name}</Link>
    </Typography>
  ) : (
    <Typography
      className={className}
      display="inline"
    >
      None
    </Typography>
  );

export default UserLink;
