import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { WorkOrder, WorkOrderFilters } from '../../../types/cwt/workorder';
import { checkWorkOrderFilters, downloadBlob } from '../utils';
import { useUsers } from './useUsers';
import { ActionFlag } from '../../../types/cwt/actions';

interface useWorkOrdersValue {
  workorders: Map<number, WorkOrder>;
  filteredWorkorders: WorkOrder[];
  selectedWorkorderId: number | undefined;
  filters: WorkOrderFilters;
  setFilters: (filters: WorkOrderFilters) => void;
  fetchWorkorders: () => void;
  handleHeaderTableSelection: (selected: TypeOnSelectionChangeArg) => void;
  setSelectedWorkorderId: (workorderId: number | undefined) => void;
  woloading: boolean;
  getWorkorderById: (id: number | undefined) => WorkOrder | undefined;
  getWorkorderByWonum: (wonum: string | undefined) => WorkOrder | undefined;
  fetchWorkorderById: (id: number | undefined) => Promise<WorkOrder | undefined>;
  gridRef: any;
  setGridRef: (gridRef: any) => void;
  exportCSV: () => void;
  flags: ActionFlag[];
}

interface Props {
  children: ReactNode;
}
const WorkOrdersContext = createContext<useWorkOrdersValue | undefined>(undefined);

export const WorkOrdersContextProvider = ({ children }: Props) => {
  const [workorders, setWorkorders] = useState<Map<number, WorkOrder>>(new Map());
  const [filteredWorkorders, setFilteredWorkorders] = useState<WorkOrder[]>([]);
  const [selectedWorkorderId, setSelectedWorkorderId] = useState<number | undefined>(undefined);

  const [woloading, setWoLoading] = useState<boolean>(true);
  const [flags, setFlags] = useState<ActionFlag[]>([]);

  const [filters, setFilters] = useState<WorkOrderFilters>({
    wonum: [],
    unit: [],
    wopriority: [],
    worktype: [],
    status: [],
    crewworkgroup: [],
    bpplanningctr: [],
    bpschedulebacklog: [],
    specifications: [],
    schedstart: [],
    location: [],
    readyOnly: false,
    waitingAssessing: false,
    waitingEng: false,
    waitingSc: false,
    waitingOps: false,
    waitingOther: false,
    highPriority: false,
  });

  const handleSetFilteredWorkorders = (workorders: Map<number, WorkOrder>) => {
    const result = Array.from(workorders.values()).filter(wo => checkWorkOrderFilters(wo, filters));
    setSelectedWorkorderId(result.length > 0 ? result[0].id : undefined);
    setFilteredWorkorders(result);
  };

  const handleSetWorkorders = (workorders: Map<number, WorkOrder>) => {
    setWorkorders(workorders);
    handleSetFilteredWorkorders(workorders);
  };

  const handleHeaderTableSelection = useCallback(
    (selected: TypeOnSelectionChangeArg) => {
      if (selected) {
        setSelectedWorkorderId(Number(selected?.selected?.toString()));
      } else {
        setSelectedWorkorderId(undefined);
      }
    },
    [setSelectedWorkorderId]
  );

  const fetchWorkorders = () => {
    setWoLoading(true);
  };

  const { userSite } = useUsers();
  useEffect(() => {
    // Queue a refetch if site selection is changed
    fetchWorkorders();
  }, [userSite]);

  const getWorkorderById = (id: number | undefined) => {
    if (!id || !workorders) return undefined;
    return workorders.get(id);
  };

  const getWorkorderByWonum = (wonum: string | undefined) => {
    if (!wonum || !workorders) return undefined;
    return Array.from(workorders.values()).find(wo => wo.wonum === wonum);
  };

  const getAllFlags = async () => {
    const res = await Api.cwt.getAllActionFlags();
    setFlags(res);
  };
  const fetchWorkorderById = async (id: number | undefined) => {
    if (!id) return undefined;
    try {
      const res = await Api.cwt.getWorkorder(id);
      return res;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };
  useEffect(() => {
    handleSetFilteredWorkorders(workorders);
  }, [filters, workorders]);

  useEffect(() => {
    const getAllWorkorders = async () => {
      const res = await Api.cwt.getAllWorkorders();
      handleSetWorkorders(new Map(res.map((wo: WorkOrder) => [wo.id, wo])));
      setWoLoading(false);
    };
    if (woloading) {
      void getAllWorkorders();
      void getAllFlags();
    }
  }, [woloading]);

  //CSV Export
  const [gridRef, setGridRef] = useState<any>(null);

  const exportCSV = () => {
    const header = gridRef.current.visibleColumns
      .filter((column: any) => column.header !== 'Actions' && column.header !== 'Notes')
      .map((column: any) => column.header)
      .join(',');

    const rows = filteredWorkorders.map((row: any) => {
      return gridRef.current.visibleColumns
        .filter((column: any) => column.header !== 'Actions' && column.header !== 'Notes')
        .map((column: any) => {
          const value = row[column.id];
          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',');
    });

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, `workorders-${new Date().toISOString()}`);
  };
  return (
    <WorkOrdersContext.Provider
      value={{
        workorders,
        filteredWorkorders,
        selectedWorkorderId,
        fetchWorkorders,
        filters,
        setFilters,
        handleHeaderTableSelection,
        setSelectedWorkorderId,
        woloading,
        getWorkorderById,
        getWorkorderByWonum,
        fetchWorkorderById,
        gridRef,
        setGridRef,
        exportCSV,
        flags,
      }}
    >
      {children}
    </WorkOrdersContext.Provider>
  );
};

export const useWorkOrders = () => {
  const context = useContext(WorkOrdersContext);
  if (context === undefined) {
    throw new Error('useWorkOrders must be used within a WorkOrdersContextProvider');
  }
  return context;
};
