import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadRecordsSummaryRequest = (indicatorId, elementId) => ({
  type: actionTypes.RECORDS_SUMMARY_LOAD_REQUEST,
  payload: {
    indicatorId,
    elementId,
  },
});

export const loadRecordsSummarySuccess = (indicatorId, recordsSummary) => ({
  type: actionTypes.RECORDS_SUMMARY_LOAD_SUCCESS,
  payload: {
    indicatorId,
    recordsSummary,
  },
});

export const loadRecordsSummaryFailure = errorAction(actionTypes.RECORDS_SUMMARY_LOAD_FAILURE);
