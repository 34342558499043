import React from 'react';
import styled from 'styled-components';
import MUIList from '@material-ui/core/List';
import MUITypography from '@material-ui/core/Typography';
import Attachment from './AttachmentListItem';
import useDownloadReportAttachment from '../../hooks/download-report-attachment';

const List = styled(MUIList)``;

const Typography = styled(MUITypography)`
  font: ${({ theme }) => theme.fonts.medium16};
  padding-top: 28px;
  padding-left: 20px;
  padding-bottom: 14px;
`;

const AttachmentList = ({ attachments }) => {
  const { downloadAttachment } = useDownloadReportAttachment(attachments);

  const handleDownload = attachmentId => () => {
    downloadAttachment(attachmentId);
  };

  const items = attachments.map(attachment => (
    <Attachment
      key={attachment.id}
      attachment={attachment}
      onDownload={handleDownload(attachment.id)}
    />
  ));

  return (
    <div>
      <Typography>Attachments</Typography>
      <List>{items}</List>
    </div>
  );
};

export default AttachmentList;
