import { WalkdownTemplate, WalkdownTemplateTypeString } from '../../../../types/walkdown-template';

const defaultType: WalkdownTemplateTypeString = 'innage';

export const defaultValues: Partial<WalkdownTemplate> = {
  title: '',
  type: defaultType,
  elementId: undefined,
};

export const formConfig = {
  title: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  type: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  elementId: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },
};
