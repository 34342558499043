import ReactDataGrid from '@inovua/reactdatagrid-community';
import CWTToolTip from '../../../CWTToolTip';
import { useOverview } from '../../../../hooks/useOverview';
import { renderMaximoPMLink } from '../../../../wo/components/utils';
import { PM } from '../../../../../../types/cwt/workorder';

const RelatedPMsTable = ({ style }: any) => {
  const { pms } = useOverview();

  const columns = [
    {
      name: 'pmnum',
      header: 'PM Number',
      defaultWidth: 120,
      render: ({ data }: { data: PM }) => renderMaximoPMLink(data.pmuid, data.pmnum),
    },
    {
      name: 'status',
      header: 'Status',
      defaultWidth: 100,
    },
    {
      name: 'pluspmtype',
      header: 'PM Type',
      defaultWidth: 120,
    },
    {
      name: 'location',
      header: 'Location',
      defaultWidth: 150,
    },
    {
      name: 'description',
      header: 'Description',
      defaultWidth: 452,
      defaultFlex: 1,
      render: ({ value }: { value: string }) => <CWTToolTip value={value}></CWTToolTip>,
    },
  ];

  return (
    <ReactDataGrid
      idProperty="id"
      style={style}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={pms}
      rowHeight={null}
    />
  );
};

export default RelatedPMsTable;
