import { Path, Remote } from './types';

const getReportingFrequencyOptions = (path: Path, remote: Remote) => () => remote.get(path('/reporting-frequency'));

const getElementCategoryOptions = (path: Path, remote: Remote) => () => remote.get(path('/element-category'));

const getFacilityOptions = (path: Path, remote: Remote) => () => remote.get(path('/facility'));

const getElementSchemas = (path: Path, remote: Remote) => () => remote.get(path('/element-schema'));

export default (path: Path, remote: Remote) => ({
  getReportingFrequencyOptions: getReportingFrequencyOptions(path, remote),
  getElementCategoryOptions: getElementCategoryOptions(path, remote),
  getFacilityOptions: getFacilityOptions(path, remote),
  getElementSchemas: getElementSchemas(path, remote),
});
