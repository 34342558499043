import React, { useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledTextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ActionButton from '../../../../components/ActionButton';
import useForm from '../../../../hooks/form';
import useAutosave from '../../../../hooks/autosave';
import UnstyledTextField from '@material-ui/core/TextField';

const TextareaAutosize: any = styled(UnstyledTextareaAutosize)`
  .MuiInputBase-root {
    font: ${({ theme }) => theme.fonts.normal14};
    border-radius: 0px;
  }
`;

const TextField = styled(UnstyledTextField)`
  .MuiInputBase-root {
    font: ${({ theme }) => theme.fonts.normal14};
    border-radius: 0px;
  }
`;

const TitleField = styled(TextField)`
  .MuiInputBase-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const Container = styled.div`
  ${TitleField} {
    width: 368px;
    margin-bottom: 10px;
  }

  ${TextareaAutosize} {
    margin-bottom: 10px;
  }
`;

const formConfig = {
  title: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a title',
      },
    },
  },
  body: {},
};

const initialValues = (comment: any) => ({
  title: comment.title,
  body: comment.body,
});

const autosaveValue = (values: any) => `${values.title}|${values.body}`;

interface Props {
  className?: string;
  comment: any;
  saving: boolean;
  onSave: any;
  onSubmit: any;
}

const CommentForm = ({ className, comment, saving, onSave, onSubmit }: Props) => {
  const titleRef: any = useRef();
  const bodyRef: any = useRef();
  const saveButtonRef = useRef(null);

  const { values, submit, propsForField } = useForm(formConfig, initialValues(comment), onSubmit);

  const handleSave = useCallback(() => {
    onSave(values);
  }, [onSave, values]);

  useAutosave(autosaveValue(values), handleSave);

  const handleBlur = useCallback(
    (e: { relatedTarget: undefined }) => {
      if (e.relatedTarget !== saveButtonRef.current) {
        handleSave();
      }
    },
    [saveButtonRef, handleSave]
  );

  useEffect(() => {
    if (R.isEmpty(comment.body)) {
      titleRef.current.focus();
      titleRef.current.select();
    } else {
      bodyRef.current.focus();
      bodyRef.current.setSelectionRange(comment.body.length, comment.body.length);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={className}>
      <TitleField
        inputRef={titleRef}
        variant="outlined"
        InputProps={{ notched: false }}
        onBlur={handleBlur as unknown as React.FocusEventHandler<HTMLTextAreaElement>}
        {...propsForField('title')}
      />
      <TextareaAutosize
        ref={bodyRef}
        multiline
        minRows={10}
        style={{ width: '100%' }}
        variant="outlined"
        onBlur={handleBlur}
        {...propsForField('body')}
      />
      <Grid
        container
        spacing={2}
      >
        <Grid item>
          <ActionButton
            ref={saveButtonRef}
            onClick={submit}
            disabled={saving}
          >
            Save
          </ActionButton>
        </Grid>
        {saving && (
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CommentForm;
