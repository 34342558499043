import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import UnstyledCard from '@material-ui/core/Card';
import { Grid, Button } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';

const Card = styled(UnstyledCard)`
  width: 100%;
  padding: 10px;
`;

const TextField = styled(MuiTextField)`
  width: 100%;
`;

const NewNote = ({ onSubmit }: { onSubmit(value: { note: string }): void }) => {
  const [note, setNote] = useState('');

  const handleSubmit = useCallback(() => {
    onSubmit({ note });
    setNote('');
  }, [note, setNote, onSubmit]);

  return (
    <Grid
      container
      item
      xs
    >
      <Card>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
        >
          <TextField
            inputProps={{ maxLength: 255 }}
            type="string"
            multiline
            value={note}
            placeholder="Add new note"
            onChange={e => {
              setNote(e.target.value);
            }}
          />
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            style={{ marginTop: '10px' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default NewNote;
