import * as R from 'ramda';
import * as actionTypes from '../action-types';
import actionKey from './key';

const initialState = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ACTIONS_LOAD_ALL_REQUEST: {
      const { elementId, state: actionState } = action.payload;
      const key = actionKey(elementId, actionState);
      const defaultValue = R.propOr<any, any, any>({}, key, state);
      return {
        ...state,
        [key]: {
          ...defaultValue,
          isLoading: true,
        },
      };
    }
    case actionTypes.ACTIONS_LOAD_ALL_SUCCESS: {
      const { elementId, actions, state: actionState } = action.payload;
      const key = actionKey(elementId, actionState);
      return {
        ...state,
        [key]: {
          actions,
          isLoading: false,
        },
      };
    }
    case actionTypes.ACTIONS_LOAD_ALL_FAILURE: {
      const { error, elementId, state: actionState } = action.payload;
      const key = actionKey(elementId, actionState);
      const defaultValue = R.propOr<any, any, any>({}, key, state);
      return {
        ...state,
        [key]: {
          ...defaultValue,
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.ACTIONS_REORDER_REQUEST: {
      const { elementId, state: actionState, fromIndex, toIndex } = action.payload;
      const key = actionKey(elementId, actionState);
      const actions = R.pathOr([], [key, 'actions'], state);
      const defaultValue = R.propOr<any, any, any>({}, key, state);
      return {
        ...state,
        [key]: {
          ...defaultValue,
          actions: R.insert(toIndex, R.nth(fromIndex, actions), R.remove(fromIndex, 1, actions)),
        },
      };
    }
    default:
      return state;
  }
};
