import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import FunctionalFailureDetails from './FunctionalFailureDetails';
import OutstandingCorrectiveActionDetails from './OutstandingCorrectiveActionDetails';
import OnlineDeficientMaintenanceBacklogDetails from './OnlineDeficientMaintenanceBacklogDetails';
import OnlineCorrectiveMaintenanceBacklogDetails from './OnlineCorrectiveMaintenanceBacklogDetails';
import ShutdownDeficientMaintenanceBacklogDetails from './ShutdownDeficientMaintenanceBacklogDetails';
import ShutdownCorrectiveMaintenanceBacklogDetails from './ShutdownCorrectiveMaintenanceBacklogDetails';
import PredefineDetails from './PredefineDetails';
import RegdocReportableEventDetails from './RegdocReportableEventDetails';
import OperatorWorkaroundDetails from './OperatorWorkaroundDetails';
import OperatorBurdenDetails from './OperatorBurdenDetails';
import TmodsBeyond6MonthsDetails from './TmodsBeyond6MonthsDetails';
import TmodsBeyond90DaysDetails from './TmodsBeyond90DaysDetails';
import InvalidIndicatorDetails from './InvalidIndicatorDetails';
import ModificationBacklogDetails from './ModificationBacklogDetails';
import ToeCorrectiveActionDetails from './ToeCorrectiveActionDetails';
import ERClockResetDetails from './ErClockResetDetails';
import EquipmentDefectDetails from './EquipmentDefectDetails';
import MaintenanceFeedbackBacklogDetails from './MaintenanceFeedbackBacklogDetails';

import RecordApplicabilityComments from './RecordApplicabilityComments';
import LoadingContainer from '../../../../../../components/LoadingContainer';
import { Box, Typography } from '@material-ui/core';

export const recordDetailsComponentMap = {
  'functional-failures': FunctionalFailureDetails,
  'outstanding-corrective-actions': OutstandingCorrectiveActionDetails,
  'online-deficient-maintenance-backlog': OnlineDeficientMaintenanceBacklogDetails,
  'shutdown-deficient-maintenance-backlog': ShutdownDeficientMaintenanceBacklogDetails,
  'online-corrective-maintenance-backlog': OnlineCorrectiveMaintenanceBacklogDetails,
  'shutdown-corrective-maintenance-backlog': ShutdownCorrectiveMaintenanceBacklogDetails,
  predefines: PredefineDetails,
  'regdoc-reportable-events': RegdocReportableEventDetails,
  'operator-workarounds': OperatorWorkaroundDetails,
  'operator-burdens': OperatorBurdenDetails,
  'tmods-beyond-6-months': TmodsBeyond6MonthsDetails,
  'tmods-beyond-90-days': TmodsBeyond90DaysDetails,
  'modification-backlog': ModificationBacklogDetails,
  'toe-corrective-actions': ToeCorrectiveActionDetails,
  'comp-er-clock-resets': ERClockResetDetails,
  'comp-equipment-defects': EquipmentDefectDetails,
  'comp-maintenance-feedback-backlog': MaintenanceFeedbackBacklogDetails,
  'comp-functional-failures': FunctionalFailureDetails,
  'comp-outstanding-corrective-actions': OutstandingCorrectiveActionDetails,
  'comp-predefines': PredefineDetails,
  'comp-online-deficient-maintenance-backlog': OnlineDeficientMaintenanceBacklogDetails,
  'comp-online-corrective-maintenance-backlog': OnlineCorrectiveMaintenanceBacklogDetails,
  'comp-shutdown-deficient-maintenance-backlog': ShutdownCorrectiveMaintenanceBacklogDetails,
  'comp-shutdown-corrective-maintenance-backlog': ShutdownCorrectiveMaintenanceBacklogDetails,
};

const Container = styled.div``;

interface Props {
  className?: string;
  record: any;
  shortName?: string;
  recordDetails?: any;
  dense?: boolean;
}

const RecordDetails = ({ className, dense, record, shortName, recordDetails }: Props) => {
  const RecordDetailsComponent: any = R.propOr(InvalidIndicatorDetails, shortName || '', recordDetailsComponentMap);

  return (
    <Container
      className={className}
      style={{ maxHeight: '25vw', overflowY: 'auto' }}
    >
      {!R.isNil(record.applicable) && <RecordApplicabilityComments record={record} />}

      <LoadingContainer loading={!!recordDetails?.isLoading}>
        {recordDetails?.error ? (
          <Box
            display="flex"
            alignItems="center"
            p={5}
          >
            <Typography variant="body2">Error fetching record details</Typography>
          </Box>
        ) : (
          <RecordDetailsComponent
            dense={dense}
            record={record}
            recordDetails={recordDetails}
          />
        )}
      </LoadingContainer>
    </Container>
  );
};

export default RecordDetails;
