import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import styled from 'styled-components';

// Error boundaries cannot be functional components

const StyledDiv = styled.div`
  color: rgb(2, 147, 195);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  font-family: sans-serif;
  width: 100%;
  flex-wrap: wrap;
`;

const Heading = styled.h1`
  width: 100%;
`;

const SubHeading = styled.h3`
  width: 100%;
  color: grey;
  font-weight: light;
`;

/* eslint-disable react/prefer-stateless-function */
export default class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      console.log({ error }); // eslint-disable-line no-console
      return (
        <StyledDiv>
          <div>
            <Heading>Something went wrong.</Heading>
            <SubHeading>Please refresh the page and try again</SubHeading>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location = '/';
              }}
            >
              Refresh
            </Button>
          </div>
        </StyledDiv>
      );
    }

    return children;
  }
}
/* eslint-enable react/prefer-stateless-function */
