import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import SmallButton from '../../../../../components/SmallButton';
import useProgramElement, { ProgramCategory } from '../../../../../hooks/program-element';
import { Category } from './Category';

const Section = styled(Grid)`
  margin-bottom: 2%;
`;

// TODO: Optimize the array and the performance issue on this page for Safari/Chrome?
const ProgramElement = ({ elementId }: { elementId: number }) => {
  const {
    categories,
    indicators,
    addCategory,
    removeCategory,
    updateCategory,
    reorderCategory,
    autoCompleteCategories,
  } = useProgramElement();
  const [newCategory, setNewCategory] = useState(false);

  const handleOnDragEnd = ({ destination, source }: DropResult) => {
    if (destination?.index === source.index) return null;
    const categoryDragged = categories[source.index];
    reorderCategory(categoryDragged, destination?.index);
    return null;
  };

  const num = R.sum(indicators.map(({ weight }: any) => weight));
  const indicatorWeightSum: number = Math.round(num * 100) / 100;

  return (
    <Grid
      container
      item
      xs={12}
    >
      <Section
        item
        xs={12}
      >
        <Typography variant="subtitle2">Category</Typography>
      </Section>
      <Section
        item
        xs={12}
      >
        <Typography variant={'subtitle2'}>Indicators Weight Total</Typography>
        <Typography
          variant={'subtitle2'}
          color={indicatorWeightSum !== 100 ? 'secondary' : 'textPrimary'}
        >
          {indicatorWeightSum} %
        </Typography>
      </Section>
      <Section
        item
        xs={12}
      >
        {!newCategory && <SmallButton onClick={() => setNewCategory(true)}>+ Add Category</SmallButton>}
        {newCategory && (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
              droppableId="category"
              type="categories"
            >
              {(provided: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Category
                    idx={0}
                    elementId={elementId}
                    isCreate
                    isExpanded
                    autoCompleteCategories={R.differenceWith(
                      (x: any, y: any) => x === y.name,
                      autoCompleteCategories,
                      categories
                    )}
                    onRemove={() => setNewCategory(false)}
                    onSave={({ name }) => {
                      addCategory(name);
                      setNewCategory(false);
                    }}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Section>
      <Section
        item
        xs={12}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable
            droppableId="category"
            type="categories"
          >
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {categories.map((category: ProgramCategory, idx: number) => (
                  <Category
                    idx={idx}
                    key={category.id}
                    elementId={elementId}
                    category={category}
                    onRemove={() => removeCategory(category.id)}
                    onSave={updatedCategory => updateCategory(updatedCategory)}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Section>
    </Grid>
  );
};

export default ProgramElement;
