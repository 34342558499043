import { useEffect, createContext, useContext, useState } from 'react';
import API from '../../../api';
import HistoricalReportFilter from '../../../types/historical-report-filter';

interface HistoricalReports {
  reports: any[];
  download(id: number): any;
  elementNames: string[];
  isLoading: boolean;
}

const HistoricalReportsContext = createContext<HistoricalReports | null>(null);

interface ProviderProps {
  children: any;
  filters: HistoricalReportFilter;
}

export const HistoricalReportsProvider = ({ children, filters }: ProviderProps) => {
  const [reports, setReports] = useState<any[]>([]);
  const [elementNames, setElementNames] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!filters.elementName) {
      setReports([]);
      setIsLoading(false);
    } else {
      API.listHistoricalReports(filters)
        .then((data: any[]) => {
          setReports(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [filters.elementName, filters.elementType, filters]);

  // get available elements by type
  useEffect(() => {
    API.listHistoricalElements(filters.elementType)
      .then((data: any) => {
        setElementNames(
          data.map((r: any) => ({
            label: r,
            value: r,
          }))
        );
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [filters.elementType]);

  const download = (id: number) => API.downloadHistoricalReport(id);

  return (
    <HistoricalReportsContext.Provider value={{ reports, download, elementNames, isLoading }}>
      {children}
    </HistoricalReportsContext.Provider>
  );
};

const useHistoricalReports = () => {
  const data = useContext(HistoricalReportsContext);

  if (!data) {
    throw new Error('useHistoricalReports must be used inside a HistoricalReportsProvider');
  }

  return data;
};

export default useHistoricalReports;
