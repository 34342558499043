import React from 'react';
import styled from 'styled-components';
import CustomTextField from '../../../../../components/CustomTextField';

interface Props {
  propsForField: any;
}

const TextBox = styled(CustomTextField)`
  width: 80px;
  margin: 0 10px;
`;

const RangeInputCell = ({ propsForField }: Props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <TextBox
        {...propsForField('min')}
        type={'number'}
      />
      to
      <TextBox
        {...propsForField('max')}
        type={'number'}
      />
    </div>
  );
};

export default RangeInputCell;
