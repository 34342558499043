import ActionButton from '../../../../components/ActionButton';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import { getOpenDetailsFunction } from './utils';
interface Props {
  text: string;
  openTab: string;
  disabled?: boolean;
}
const WOActionButton = ({ text, openTab, disabled }: Props) => {
  const { handleOpen } = useWODetailsModal();
  const onClickFunc = getOpenDetailsFunction(handleOpen, openTab);
  return (
    <ActionButton
      color="primary"
      variant="text"
      onClick={onClickFunc}
      disabled={disabled}
    >
      {text}
    </ActionButton>
  );
};

export default WOActionButton;
