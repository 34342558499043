import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../../../../api';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  originalMSMProgram: string;
};

const EditMSMProgramDashboard = ({ opened, setOpened, originalMSMProgram }: Props) => {
  const [msmProgram, setMSMProgram] = useState(originalMSMProgram);
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();

  const handleEdit = useCallback(async () => {
    if (msmProgram && status) {
      await api.editMSMProgram(msmProgram, status, originalMSMProgram);
      dispatch(uiActions.genericMessage('Updated Succesfully'));
      setOpened(false);
    } else {
      dispatch(uiActions.error('', 'Please enter valid input'));
    }
  }, [msmProgram, status]);

  useEffect(() => {
    setMSMProgram(originalMSMProgram);
  }, [originalMSMProgram]);

  return (
    <Dialog
      open={opened}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Edit MSM Program</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <CarlaText>Edit MSM Program and set status</CarlaText>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <CustomTextField
              label="Title"
              inputProps={{ maxLength: 256 }}
              onChange={e => setMSMProgram(e.target.value)}
              defaultValue={originalMSMProgram}
            ></CustomTextField>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Select
              fullWidth
              label="Status"
              options={[
                { label: 'Active', value: 'Active' },
                { label: 'Non-Active', value: 'Non-Active' },
              ]}
              onChange={e => setStatus(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={() => setOpened(false)}>Cancel</DialogButton>
        <DialogButton
          color="primary"
          onClick={handleEdit}
        >
          Update
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditMSMProgramDashboard;
