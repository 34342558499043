import { Tooltip } from '@material-ui/core';
import DownloadButton from '../../../../components/DownloadButton';
import useWorkRequests from '../../hooks/useWorkRequests';

export const ExportButton = () => {
  const { exportCSV, selectedForExport } = useWorkRequests();
  return (
    <Tooltip
      title="Export CSV"
      placement="top"
    >
      <span>
        <DownloadButton
          title="Export CSV"
          disabled={!selectedForExport.length}
          onClick={exportCSV}
        />
      </span>
    </Tooltip>
  );
};
