import React, { useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import UnstyledTextareaAutosize from '@material-ui/core/TextareaAutosize';
import CircularProgress from '@material-ui/core/CircularProgress';
import ActionButton from '../../../../components/ActionButton';
import useForm from '../../../../hooks/form';
import useAutosave from '../../../../hooks/autosave';

const TextareaAutosize: any = styled(UnstyledTextareaAutosize)`
  .MuiInputBase-root {
    margin-top: 25px;
    font: ${({ theme }) => theme.fonts.normal14};
    border: ${({ theme }) => theme.borders.thin.solid3};
    border-radius: 0px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    font: ${({ theme }) => theme.fonts.normal14};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
  }
`;

const Container = styled.div`
  ${TextareaAutosize} {
    margin-bottom: 10px;
  }
`;

const formConfig = {
  executiveSummary: {},
};

export interface ExecutiveSummaryFormValues {
  executiveSummary: string;
}

const initialValues = (initialValue: string): ExecutiveSummaryFormValues => ({
  executiveSummary: initialValue || '',
});

interface Props {
  className?: string;
  initialValue: string;
  saving: boolean;
  onSave: (value: string) => void;
  onSubmit: (values: ExecutiveSummaryFormValues) => void;
  copyPrevious?: (setValue: (previousValue: string | undefined) => void, submit: () => void) => void;
}

const ExecutiveSummaryForm = ({ className, initialValue, saving, onSave, onSubmit, copyPrevious }: Props) => {
  const bodyRef: any = useRef();
  const saveButtonRef = useRef(null);
  const { values, submit, propsForField, setField } = useForm(formConfig, initialValues(initialValue), onSubmit);

  const updateValue = (value: string | undefined) => setField('executiveSummary', value || '');

  const handleSave = useCallback(() => {
    onSave(values.executiveSummary);
  }, [onSave, values.executiveSummary]);

  useAutosave(values.executiveSummary, handleSave);

  const handleBlur = useCallback(
    (e: { relatedTarget: undefined }) => {
      if (e.relatedTarget !== saveButtonRef.current) {
        onSave(values.executiveSummary);
      }
    },
    [saveButtonRef, onSave, values.executiveSummary]
  );

  useEffect(() => {
    bodyRef.current.focus();
    bodyRef.current.setSelectionRange(initialValue.length, initialValue.length);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={className}>
      <TextareaAutosize
        ref={bodyRef}
        style={{ width: '100%' }}
        multiline
        minRows={10}
        variant="outlined"
        onBlur={handleBlur as unknown as React.FocusEventHandler<HTMLTextAreaElement>}
        {...propsForField('executiveSummary')}
      />

      <Grid
        container
        spacing={2}
      >
        <Grid item>
          <ActionButton
            ref={saveButtonRef}
            onClick={submit}
            disabled={saving}
          >
            Save
          </ActionButton>
        </Grid>
        {copyPrevious && (
          <Grid item>
            <Button
              onClick={() => copyPrevious(updateValue, submit)}
              color="primary"
            >
              Copy Previous
            </Button>
          </Grid>
        )}
        {saving && (
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ExecutiveSummaryForm;
