import styled from 'styled-components';
import Tab from '../../../components/Tabs/Tab';

export const RiskOwnerTab = styled(Tab).attrs({
  label: 'Risk Owner',
})`
  min-width: 109px;
`;

export const RiskApproverTab = styled(Tab).attrs({
  label: 'Risk Approver',
})`
  min-width: 128px;
`;

export const EnvironmentalGroupTab = styled(Tab).attrs({
  label: 'Environmental Group',
})`
  min-width: 175px;
`;
