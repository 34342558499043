import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useMemo } from 'react';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
};

export default useQuery;
