import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useQuarters from '../../../../hooks/quarters';
import useScorecard from '../../../../hooks/scorecard';
import { type ScorecardStaleData, type ScorecardErrorMessage } from '../../../../hooks/scorecard/types';
import useForecast from '../../../../hooks/forecast';
import ForecastChart from '../../../../components/ForecastChart';
import ForecastDetails from './ForecastDetails';
import useActions from '../../../../hooks/actions';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import useElementActuals, { ActualsProvider } from '../../../../hooks/useElementActuals';
import { Scorecard } from '../../../../types/scorecard';
import { MyElement } from '../../../../types/my';
import { ScorecardErrorText as ErrorMessage } from '../../../../components/ErrorText';

const WidthConstrainedGrid = styled(Grid)`
  max-width: 1200px;
`;
const BackgroundLoadingOverlay = styled.div``;

interface ContainerProps {
  scorecardErrorMessage?: ScorecardErrorMessage;
  staleData?: ScorecardStaleData;
  scorecardDisplayable: Scorecard | ScorecardStaleData | null;
  actions: any[];
  scorecard: Scorecard;
}

const ForecastContainer = ({
  actions,
  scorecardDisplayable,
  scorecard,
  scorecardErrorMessage,
  staleData,
}: ContainerProps) => {
  const historicData = useElementActuals();
  const forecastEvents = useForecast(actions, scorecardErrorMessage && staleData ? staleData : scorecard);
  const forecastData = R.map(
    ({ date, scorecard: forecastScorecard, actions: forecastActions }) => ({
      date,
      score: forecastScorecard.totals.score,
      actions: forecastActions,
    }),
    forecastEvents
  );

  return (
    <>
      {scorecardErrorMessage ? (
        <ErrorMessage
          scorecardDisplayable={scorecardDisplayable}
          scorecard={scorecard}
          staleData={staleData}
          scorecardErrorMessage={scorecardErrorMessage}
        />
      ) : null}
      <WidthConstrainedGrid
        container
        direction="column"
        spacing={2}
        style={{ maxWidth: 1200 }}
      >
        <Grid item>
          <ForecastChart
            historicData={historicData}
            forecastData={forecastData}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5">Forecast Details</Typography>
        </Grid>
        <Grid item>
          <ForecastDetails forecast={forecastEvents} />
        </Grid>
      </WidthConstrainedGrid>
    </>
  );
};

interface ChartProps {
  selectedAssignment: Pick<MyElement, 'elementId' | 'elementName'>;
}

const ChartContainer = ({ selectedAssignment }: ChartProps) => {
  const { elementId, elementName } = selectedAssignment;
  const { quarters } = useQuarters({ n: 1 });
  const {
    scorecardDisplayable,
    scorecard,
    scorecardErrorMessage,
    staleData,
    isBackgroundLoading,
    BackgroundLoadingMessage,
  } = useScorecard({
    elementId,
    elementName,
    year: quarters[0].year,
    quarter: quarters[0].quarter,
  });
  const { actions } = useActions(elementId, 'ongoing');
  const isNotDisplayable = !scorecardDisplayable || R.isNil(actions);

  if (!selectedAssignment) {
    return null;
  }

  if (isNotDisplayable && typeof scorecardErrorMessage === 'string') {
    return (
      <ErrorMessage
        scorecardDisplayable={scorecardDisplayable}
        scorecard={scorecard}
        staleData={staleData}
        scorecardErrorMessage={scorecardErrorMessage}
      />
    );
  }
  return isNotDisplayable ? (
    <Grid
      container
      justifyContent="center"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <ElementIndicatorsProvider elementId={elementId}>
      <ActualsProvider
        elementId={elementId}
        scorecard={scorecard}
      >
        {isBackgroundLoading && (
          <BackgroundLoadingOverlay>
            <BackgroundLoadingMessage />
          </BackgroundLoadingOverlay>
        )}
        <ForecastContainer
          scorecardDisplayable={scorecardDisplayable}
          staleData={staleData}
          scorecardErrorMessage={scorecardErrorMessage}
          actions={actions}
          scorecard={scorecard}
        />
      </ActualsProvider>
    </ElementIndicatorsProvider>
  );
};

export default ChartContainer;
