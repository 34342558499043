import Page from '../../../components/Page';
import { ChangeTrackingContextProvider } from '../hooks/useChangeTracking';
import ChangeTrackingPage from './components/ChangeTrackingPage';

const ChangeTracking = () => {
  return (
    <Page title="Change Tracking">
      <ChangeTrackingContextProvider>
        <ChangeTrackingPage />
      </ChangeTrackingContextProvider>
    </Page>
  );
};

export default ChangeTracking;
