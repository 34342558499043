import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePickerProps,
  MuiPickersUtilsProvider,
  DateTimePicker as UnstyledDateTimePicker,
} from '@material-ui/pickers';
import styled from 'styled-components';
import FormLabel from './CustomFormLabel';

const DateTimePicker = styled(UnstyledDateTimePicker)`
  margin-top: 4px;
  padding-top: 0;
  &.MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.colours.teals.teal1};
  }
`;

export interface DateTimeInputProps
  extends Pick<
    DateTimePickerProps,
    | 'clearable'
    | 'disabled'
    | 'disableFuture'
    | 'disablePast'
    | 'error'
    | 'fullWidth'
    | 'helperText'
    | 'inputVariant'
    | 'name'
    | 'onChange'
    | 'value'
  > {
  label?: string;
}

const DateTimeInput = ({
  clearable,
  disabled,
  disableFuture,
  disablePast,
  error,
  fullWidth,
  helperText,
  inputVariant,
  label,
  name,
  onChange,
  value,
}: DateTimeInputProps) => (
  <>
    <FormLabel error={error}>{label}</FormLabel>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        format="yyyy-MM-dd h:mm a"
        margin="normal"
        value={value}
        error={error}
        helperText={helperText}
        clearable={clearable}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        fullWidth={fullWidth}
        inputVariant={inputVariant}
        name={name}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  </>
);

export default DateTimeInput;
