import { Box, Tooltip } from '@material-ui/core';
import { useTurnover } from '../../../hooks/useTurnover';
import TurnoverTable from './TurnoverTable';
import DownloadButton from '../../../../../components/DownloadButton';
import ActionButton from '../../../../../components/ActionButton';
import AddEditTurnoverForm from '../../forms/AddEditTurnoverForm';
import FormDialog from '../../../../../components/FormDialog';

const TurnoverTab = () => {
  const {
    exportCSV,
    handleAddEditTurnover,
    handleOpenAddTurnoverDialog,
    handleCloseAddEditTurnoverDialog,
    openAddEditTurnoverDialog,
  } = useTurnover();
  return (
    <Box>
      <ActionButton
        color="primary"
        onClick={handleOpenAddTurnoverDialog}
      >
        Add Turnover Log
      </ActionButton>
      <Tooltip
        title="Export CSV"
        placement="top"
      >
        <DownloadButton onClick={exportCSV}></DownloadButton>
      </Tooltip>
      <TurnoverTable />
      {openAddEditTurnoverDialog && (
        <FormDialog
          title="Add Turnover Log"
          onSave={handleAddEditTurnover}
          onCancel={handleCloseAddEditTurnoverDialog}
        >
          <AddEditTurnoverForm />
        </FormDialog>
      )}
    </Box>
  );
};

export default TurnoverTab;
