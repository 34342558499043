import { useRef } from 'react';

// Creates a sequential id generator for creating locally-unique integer ids
// Useful when creating a set of records locally, and sending them to the server in batches to be presisted.
// We need the temporary local id to tel them apart until they are assigned a permanent database id
const useSequence = () => {
  const latest = useRef<number>(0);

  const generate = () => {
    latest.current += 1;
    return latest.current - 1;
  };

  return {
    generate,
    latest: latest.current,
  };
};

export default useSequence;
