import * as R from 'ramda';

const getRecentReports = (reports: any) => {
  const groupedById = R.groupBy(R.prop<any>('elementId'), reports);
  const latestReports = R.map(
    R.last,
    R.map(R.sortBy(R.prop('year')), R.map(R.sortBy(R.prop('quarter')), Object.values(groupedById)))
  );
  return latestReports;
};

export default getRecentReports;
