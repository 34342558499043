import { TableRow, Tooltip, IconButton } from '@material-ui/core';
import * as R from 'ramda';
import { IndicatorCell, IndicatorName } from '../StyledComponents';
import InfoIcon from '@material-ui/icons/Info';

export const IndicatorRowRoot = ({
  children,
  name,
  id,
  definition,
}: {
  children: React.ReactNode;
  name: string;
  id: number;
  definition?: string;
}) => (
  <TableRow>
    <IndicatorCell padding="none">
      <IndicatorName>
        <Tooltip
          placement="top"
          arrow
          title={R.isNil(definition) ? 'Manual Indicator' : definition}
        >
          <IconButton
            key={id}
            aria-label="delete"
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
        {name}
      </IndicatorName>
    </IndicatorCell>
    {children}
  </TableRow>
);
