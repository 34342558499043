// useOverrideDetails
import * as R from 'ramda';
import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as uiActions from '../state/ui/actions';
import Api from '../api';
import useIndicator, { IndicatorProvider } from './indicator';
import { ElementId } from '../types/app';

const OverrideDetailsContext = createContext(null as any);

const useOverrideDetails = () => {
  const reportIndicators = useContext(OverrideDetailsContext);

  if (R.isNil(reportIndicators)) {
    throw new Error('useOverrideDetails must be used inside OverrideDetailsProvider');
  }

  return reportIndicators;
};

interface ProviderProps {
  children: any;
  indicatorName: string;
  indicatorId: number;
  facilityId: string;
  elementUnit: string;
  elementType?: string;
  elementId: ElementId;
  year: number;
  quarter: number;
}

const OverrideDetails = ({ children, indicatorId, elementUnit, elementId, elementType, ...props }: ProviderProps) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState<any>([]);
  const [queryRecordId, setQueryRecordId] = useState('');
  const [associatedOverrideRecords, setAssociatedOverrideRecords] = useState<any>([]);

  const { shortName } = useIndicator();

  useEffect(() => {
    Api.getAssignedIndicatorRecords(indicatorId, elementId, elementUnit).then(setAssociatedOverrideRecords);
  }, [elementId, indicatorId, elementUnit]);

  const assign = useCallback(
    async (selectedRecord: any) => {
      await Api.assignIndicatorRecord(indicatorId, elementId, elementUnit, shortName, selectedRecord);
      setRecords([]);
    },
    [elementId, elementUnit, indicatorId, shortName, queryRecordId]
  );

  const searchRecords = useCallback(
    async (queryId: string) => {
      try {
        const res = await Api.getIndicatorRecords(elementId, indicatorId, queryId);
        dispatch(uiActions.genericMessage('Records Found.'));
        setRecords([R.head(res)]);
      } catch (e: any) {
        dispatch(uiActions.error('', e.response.data.message));
      }
      setQueryRecordId(queryId);
    },
    [indicatorId, elementType, elementId]
  );

  const deleteAssociatedRecord = useCallback(
    async (record: any) => {
      const { crNumber, pmNumber, workOrderNumber, workRequestNumber } = record;
      await Api.removeAssociatedIndicatorRecord(
        indicatorId,
        elementId,
        elementUnit,
        crNumber,
        pmNumber,
        workOrderNumber,
        workRequestNumber
      );
      const res = await Api.getAssignedIndicatorRecords(indicatorId, elementId, elementUnit);
      setAssociatedOverrideRecords(res);
    },
    [elementId, indicatorId, elementUnit]
  );

  const value = {
    associatedOverrideRecords,
    records,
    shortName,
    assign,
    searchRecords,
    deleteAssociatedRecord,
  };

  return (
    <OverrideDetailsContext.Provider
      value={value}
      {...props}
    >
      {children}
    </OverrideDetailsContext.Provider>
  );
};

export const OverrideDetailsProvider = ({ children, indicatorId, ...props }: ProviderProps) => (
  <IndicatorProvider indicatorId={indicatorId}>
    <OverrideDetails
      indicatorId={indicatorId}
      {...props}
    >
      {children}
    </OverrideDetails>
  </IndicatorProvider>
);

export default useOverrideDetails;
