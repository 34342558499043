import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import OutlineButton from '../../../../../components/OutlineButton';
import AttachmentList from './AttachmentList';
import useAddAttachment from '../hooks/add-attachment';
import AddAttachmentDialog from './AddAttachmentDialog';
import useAttachmentType from '../hooks/attachment-type';
import useQuarter from '../hooks/quarter';
import { Downloader } from '../../../../../hooks/downloader';
import { MyElement } from '../../../../../types/my';

const Documents: React.FC<{ element: MyElement }> = ({ element }) => {
  const { attachmentType } = useAttachmentType();
  const { quarterValue } = useQuarter();

  const { addingAttachment, onAddAttachment, onConfirmAddAttachment, onCancelAddAttachment } =
    useAddAttachment(element);

  return (
    <Box p={2}>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Downloader>
            <AttachmentList />
          </Downloader>
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="flex-end"
          >
            <Grid item>
              <OutlineButton onClick={onAddAttachment}>Add Attachment</OutlineButton>
            </Grid>
          </Grid>
        </Grid>
        {addingAttachment && (
          <AddAttachmentDialog
            element={element}
            initialQuarter={quarterValue}
            initialAttachmentType={attachmentType}
            onAdd={onConfirmAddAttachment}
            onCancel={onCancelAddAttachment}
          />
        )}
      </Grid>
    </Box>
  );
};

export default Documents;
