import { Grid } from '@material-ui/core';
import { ControlTextField } from '../../../../form/ControlTextField';
import { useNotes } from '../../hooks/useNotes';
const AddWorkorderNote = () => {
  const { control, errors } = useNotes();
  return (
    <Grid
      container
      style={{ minWidth: '400px' }}
    >
      <Grid
        item
        xs={12}
      >
        <ControlTextField
          control={control}
          name="note"
          label="Note"
          fullWidth
          multiline
          variant="outlined"
          placeholder="Character Limitation: 500 Characters"
          required={true}
          error={!!errors.note}
          maxLength={500}
          minLength={1}
          minRows={3}
        />
      </Grid>
    </Grid>
  );
};

export default AddWorkorderNote;
