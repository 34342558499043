import * as R from 'ramda';
import quarterKey from './quarter-key';

export const getAttachmentsWithStateByQuarter = (state, elementId, year, quarter) =>
  R.pathOr([], [quarterKey(elementId, year, quarter), 'attachments'], state.elementAttachments.byQuarter);

export const getAttachmentsByQuarter = (state, elementId, year, quarter) =>
  R.map(R.prop('attachment'), getAttachmentsWithStateByQuarter(state, elementId, year, quarter));

export const isLoadingByQuarter = (state, elementId, year, quarter) =>
  R.pathOr(false, [quarterKey(elementId, year, quarter), 'isLoading'], state.elementAttachments.byQuarter);

export const getErrorByQuarter = (state, elementId, year, quarter) =>
  R.path([quarterKey(elementId, year, quarter), 'error'], state.elementAttachments.byQuarter);

export const getAttachments = (state, elementId) =>
  R.pathOr([], [elementId, 'attachments'], state.elementAttachments.byElementId);

export const isLoading = (state, elementId) =>
  R.pathOr(false, [elementId, 'isLoading'], state.elementAttachments.byElementId);

export const getError = (state, elementId) => R.path([elementId, 'error'], state.elementAttachments.byElementId);
