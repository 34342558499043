import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../../api';
import * as actionTypes from '../../../state/action-types';
import * as pageActions from './actions';

function* loadRecords(action) {
  try {
    const { indicatorId } = action.payload;
    const records = yield call(Api.getSummary, indicatorId);
    yield put(pageActions.loadRecordsSuccess(indicatorId, records));
  } catch (e) {
    yield put(pageActions.loadRecordsFailure(e));
  }
}

function* updateIndicatorRecord(action) {
  const { indicatorId, recordId, applicable, options } = action.payload;
  try {
    yield call(Api.updateIndicatorRecord, indicatorId, recordId, applicable, options);
    yield put(applicable ? pageActions.indicatorRecordApplied() : pageActions.indicatorRecordRejected());
    yield put(pageActions.loadRecordsRequest(indicatorId));
  } catch (e) {
    yield put(pageActions.updateIndicatorRecordFailure(e, indicatorId));
  }
}

function* root() {
  yield takeEvery(actionTypes.DASHBOARD_LOAD_RECORDS_REQUEST, loadRecords);
  yield takeEvery(actionTypes.DASHBOARD_UPDATE_INDICATOR_RECORD_REQUEST, updateIndicatorRecord);
}

export default root;
