import React, { useState } from 'react';
import StartIconButton from '../../../../components/StartIconButton';
import useElements from '../../../../hooks/elements';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import FilterSelector from '../../../../components/FilterSelector';
import OutlineButton from '../../../../components/OutlineButton';
import useUpdateMCRExemption from './hooks/useUpdateMCRExemption';
import DateInput from '../../../../components/DateInput';

const AddExemptElements = () => {
  const { elements } = useElements();
  const [showExemptElementSelector, setShowExemptElementSelector] = useState(false);
  const [showDateSelector, setDateSelector] = useState(false);
  const [exemptElement, setExemptElement] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();

  const elementOptions = elements
    .filter((element: any) => element.elementType !== 'program')
    .map((element: any) => ({
      name: `[${elementTypePrefix(element.type)}] ${element.name} (${element.facilityName})`,
      value: element.id,
    }));

  const { setMCRExemption } = useUpdateMCRExemption();

  const filterSelectorHandler = (elementId: any) => {
    setExemptElement(elementId);
  };

  return (
    <div>
      {showExemptElementSelector ? (
        <div>
          <FilterSelector
            value={exemptElement}
            onChange={filterSelectorHandler}
            options={elementOptions}
          />
          {showDateSelector ? (
            <div style={{ paddingTop: '5%' }}>
              <div className="dateCell cell">
                <DateInput
                  value={startDate}
                  onChange={setStartDate}
                  label={'Start Date'}
                />
              </div>
              <div className="dateCell cell">
                <DateInput
                  value={endDate}
                  onChange={setEndDate}
                  label={'End Date'}
                />
              </div>
            </div>
          ) : (
            <div style={{ paddingTop: '5%' }}>
              <StartIconButton onClick={() => setDateSelector(true)}>Set Duration</StartIconButton>
            </div>
          )}
          <div style={{ paddingTop: '5%' }}>
            <OutlineButton
              onClick={() => {
                setMCRExemption(exemptElement, true, startDate, endDate);
                setDateSelector(false);
              }}
            >
              Add Element
            </OutlineButton>
          </div>
        </div>
      ) : (
        <StartIconButton onClick={() => setShowExemptElementSelector(true)}>Add</StartIconButton>
      )}
    </div>
  );
};

export default AddExemptElements;
