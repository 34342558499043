import React from 'react';
import * as R from 'ramda';
import {
  VictoryChart,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryLegend,
} from 'victory';
import { dateToString } from '../utils/date-format';

const LegendVisual = ({ x, y, style, datum }) => (
  <line
    x1={x - 10}
    y1={y}
    x2={x + 10}
    y2={y}
    style={{
      ...style,
      strokeDasharray: datum.dashed ? '3px' : '0px',
      stroke: datum.fill,
    }}
  />
);

const ChartLegend = ({ pastDataStroke, futureUpperBoundStroke, lineWidth, ...props }) => (
  <VictoryLegend
    {...props}
    x={500}
    y={0}
    orientation="horizontal"
    gutter={20}
    data={[
      { name: 'Actual', fill: pastDataStroke },
      { name: 'Forecast', fill: futureUpperBoundStroke, dashed: true },
    ]}
    dataComponent={<LegendVisual style={{ strokeWidth: lineWidth }} />}
    style={{ border: { background: 'transparent ' } }}
  />
);

const Tooltip = props => (
  <VictoryTooltip
    centerOffset={{
      x: ({ x, flyoutWidth }) => (x < 100 ? flyoutWidth + 30 : -30),
      y: -30,
    }}
    pointerWidth={0}
    pointerLength={5}
    cornerRadius={0}
    orientation="left"
    pointerOrientation={({ x }) => (x < 100 ? 'left' : 'right')}
    flyoutStyle={{
      fill: 'white',
      border: '#D8D8D8',
    }}
    style={{ border: '#D8D8D8' }}
    {...props}
  />
);

const actionLabel = datum =>
  !R.isEmpty(datum.actions) ? `Actions: ${R.join(', ', R.map(R.prop('id'), datum.actions))}\n` : '';

const datumLabel = datum =>
  `${dateToString(datum.date)}\n${actionLabel(datum)}Overall score: ${datum.score.toFixed(2)}`;

const ForecastChart = ({ historicData, forecastData }) => {
  const interpolation = 'cardinal';
  const pastDataStroke = '#0693C3';
  const futureUpperBoundStroke = '#41A700';
  const lineWidth = '3px';
  const xField = 'date';
  const yField = 'score';

  const clampedForecast = R.map(
    forecastEvent => ({
      ...forecastEvent,
      score: R.min(100, forecastEvent.score),
    }),
    forecastData
  );

  return (
    <VictoryChart
      scale={{ x: 'time', y: 'linear' }}
      width={1200}
      height={600}
      theme={VictoryTheme.material}
      containerComponent={
        <VictoryVoronoiContainer
          cursorDimension="x"
          responsive={true}
        />
      }
      padding={{ top: 50, left: 50, right: 50, bottom: 80 }}
    >
      <ChartLegend
        pastDataStroke={pastDataStroke}
        futureUpperBoundStroke={futureUpperBoundStroke}
        lineWidth={lineWidth}
      />
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 14,
            angle: -45,
            padding: 30,
          },
          ticks: {},
        }}
        tickCount={12}
        tickFormat={tick => dateToString(tick)}
      />
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 14,
          },
        }}
        dependentAxis
        domain={[0, 102]}
      />
      <VictoryLine
        data={historicData}
        x={xField}
        y={yField}
        interpolation={'linear'}
        style={{
          data: {
            stroke: pastDataStroke,
            strokeWidth: lineWidth,
          },
        }}
      />
      <VictoryLine
        labels={({ datum }) => datumLabel(datum)}
        labelComponent={<Tooltip />}
        data={clampedForecast}
        x={xField}
        y={yField}
        interpolation={interpolation}
        style={{
          data: {
            stroke: futureUpperBoundStroke,
            strokeWidth: lineWidth,
            strokeDasharray: '3px',
          },
          labels: {
            textAnchor: 'start',
          },
        }}
      />
      <VictoryScatter
        data={clampedForecast}
        x={xField}
        y={yField}
        size={2}
        style={{
          data: {
            fill: futureUpperBoundStroke,
            stroke: futureUpperBoundStroke,
            strokeWidth: lineWidth,
          },
        }}
      />
    </VictoryChart>
  );
};

export default ForecastChart;
