import UnstlyedHistoryIcon from '@material-ui/icons/History';
import styled from 'styled-components';
import { Tooltip, Box, Grid, makeStyles } from '@material-ui/core';
import { ChangeLog } from '../../../../types/cwt/changelog';
import format from 'date-fns/format';

const HistoryIcon = styled(UnstlyedHistoryIcon)`
  color: rgb(15, 154, 154);
  transition: transform 0.2s;
  &:active {
    transform: scale(0.9);
  }
`;
const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '500px',
    maxHeight: '300px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    overflowY: 'scroll',
    backgroundColor: 'rgb(38, 36, 82, 0.9)',
    fontSize: '16px',
    border: '3px solid black',
  },
}));

const History = ({ history }: { history: ChangeLog[] }) => {
  return (
    <Box>
      <Grid container>
        {history.map((log, index) => (
          <Box
            key={index}
            marginTop={'5px'}
            marginBottom={'10px'}
            bgcolor={'rgb(255,255,255, 1)'}
            borderRadius={'5px'}
            padding={'10px'}
            color={'black'}
            width={'100%'}
          >
            <Grid
              item
              xs={12}
            >
              <i>
                {format(new Date(log.changedDate), 'ddMMMyyyy HH:mm').toUpperCase()}
                <br />
                {log.changedByName}
              </i>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {`${log.field}: ${log.newValue}`}
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export const ActionHistory: React.FC<{ history: ChangeLog[] }> = ({ history }) => {
  const classes = useStyles();
  if (history.length === 0) return null;
  return (
    <Tooltip
      title={<History history={history} />}
      placement="left"
      classes={{ tooltip: classes.tooltip }}
      interactive
    >
      <Box style={{ marginTop: '10px' }}>
        <HistoryIcon />
      </Box>
    </Tooltip>
  );
};
