import React from 'react';
import styled from 'styled-components';

import MUIPaper from '@material-ui/core/Paper';

import UnstyledActionButton from '../../../components/ActionButton';

const ActionButton = styled(UnstyledActionButton)``;

const ActionContainer = styled.div`
  ${ActionButton} {
    margin-left: 16px;
  }
`;

const Paper = styled(MUIPaper).attrs({
  elevation: 0,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: ${({ theme }) => theme.borders.thin.solid3};
  padding: 20px 30px 20px 30px;
  border-radius: 0px;

  ${ActionContainer} {
    align-self: flex-start;
    flex-shrink: 0;
  }
`;

interface Props {
  className?: string;
  children?: React.ReactNode;
  nextLabel?: string;
  backLabel?: string;
  onNext?(): void;
  onBack?(): void;
}

const NavPaper = ({ className, children, nextLabel = 'Next', backLabel = 'Back', onNext, onBack }: Props) => (
  <Paper className={className}>
    {children}
    <ActionContainer>
      {onBack && (
        <ActionButton
          color="primary"
          onClick={onBack}
        >
          {backLabel}
        </ActionButton>
      )}
      {onNext && (
        <ActionButton
          color="primary"
          onClick={onNext}
        >
          {nextLabel}
        </ActionButton>
      )}
    </ActionContainer>
  </Paper>
);

export default NavPaper;
