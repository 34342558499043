import React from 'react';
import useActions from '../../hooks/actions';
import { Report } from '../../hooks/report';
import ActionTable from '../ActionTable';

interface Props {
  className?: string;
  report: Report;
}

const SummaryActionTable = ({ className, report }: Props) => {
  const { actions } = useActions(report.elementId, 'ongoing');

  return (
    <ActionTable
      className={className}
      actions={actions}
      reorderable={false}
    />
  );
};

export default SummaryActionTable;
