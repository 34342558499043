import React from 'react';
import Paper from '@material-ui/core/Paper';
import Page from '../../../../components/Page';
import AdminCategoriesTable from './AdminCategoriesTable';
import useAdminCategories from '../../../../hooks/admin_categories';

const AdminCategoriesPage = () => {
  const { categories, deleteCategory, addCategory, updateCategory } = useAdminCategories();
  return (
    <Page
      title="Admin - Admin Categories"
      bodyComponent={<Paper square />}
    >
      <AdminCategoriesTable
        categories={categories}
        onDelete={deleteCategory}
        onAddCategory={addCategory}
        onEdit={updateCategory}
      />
    </Page>
  );
};

export default AdminCategoriesPage;
