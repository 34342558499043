import React, { useCallback, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ControlTextField } from '../../../../form/ControlTextField';
import { ControlSelect } from '../../../../form/ControlSelect';
import { UppercaseTitle } from './UppercaseTitle';
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { StyledTypography } from './StyledTypography';
import { WrappedInput } from './WrappedInput';
import { RiskOwnerFormInputs } from '../../../../types/risk-management';
import useRiskManagement from '../../../../context/risk-management';
import { elementsOptions } from '../hooks/useRiskOwnerForm';
import TotalScoreBox from '../../../../components/Scorecard/TotalScoreBox';
import useQuarters from '../../../../hooks/quarters';
import api from '../../../../api';
import { Scorecard } from '../../../../types/scorecard';
import ActionButton from '../../../../components/ActionButton';
import useAllElements from '../../../../hooks/all-elements';
import { EchoElement } from '../../../../hooks/elements';

type Props = {
  riskStatus: string;
  control: Control<RiskOwnerFormInputs>;
  errors: FieldErrors<RiskOwnerFormInputs>;
  isReadOnly: boolean;
  rsaArcherRskRecordReference: string | null;
  watchElementId: number;
  watchHealthScore: number | undefined;
  watchHealthScoreColor: string | undefined;
  loadHealthScore: boolean;
  setValue: UseFormSetValue<RiskOwnerFormInputs>;
  onOpportunityOrThreatChange: () => void;
  riskOwner: string;
};

type HealthScoreProps = {
  isReadOnly: boolean;
  watchElementId: number;
  watchHealthScore: number | undefined;
  watchHealthScoreColor: string | undefined;
  setValue: UseFormSetValue<RiskOwnerFormInputs>;
};

const HealthScore = ({
  watchElementId,
  watchHealthScore,
  watchHealthScoreColor,
  isReadOnly,
  setValue,
}: HealthScoreProps) => {
  const { quarters } = useQuarters({ n: 1 });
  const [scorecard, setScoreCard] = useState<Scorecard | null>(null);
  const [loading, setLoading] = useState(false);

  const generateHealthScore = useCallback(() => {
    api.getScorecard(watchElementId, quarters[0].year, quarters[0].quarter).then((res: Scorecard) => {
      setScoreCard(res);
      setValue('healthScore', res?.totals?.score);
      setValue('healthScoreColor', res?.totals?.colour);
    });
    setLoading(true);
  }, [setValue, watchElementId, quarters]);

  return (
    <Grid
      item
      xs={2}
    >
      <Typography>Health Score:</Typography>
      {!watchHealthScore && !scorecard && watchElementId && !loading ? (
        <ActionButton
          onClick={generateHealthScore}
          color="primary"
        >
          Get Health Score
        </ActionButton>
      ) : watchHealthScore || scorecard ? (
        <TotalScoreBox
          totalScore={watchHealthScore || scorecard?.totals?.score || 0}
          overridden={false}
          scoreColour={watchHealthScoreColor || scorecard?.totals?.colour || 'red'}
        ></TotalScoreBox>
      ) : isReadOnly ? (
        <></>
      ) : (
        <CircularProgress size={20} />
      )}
    </Grid>
  );
};

const GeneralInfoForm: React.FC<Props> = ({
  riskStatus,
  control,
  errors,
  isReadOnly,
  rsaArcherRskRecordReference,
  riskOwner,
  watchElementId,
  watchHealthScore,
  loadHealthScore,
  watchHealthScoreColor,
  onOpportunityOrThreatChange,
  setValue,
}) => {
  const { data, myElements } = useRiskManagement();
  const { elements } = useAllElements();
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <UppercaseTitle>General information</UppercaseTitle>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlTextField
            control={control}
            name="riskTitle"
            label="Risk Title"
            minLength={1}
            maxLength={127}
            placeholder="Required"
            required={true}
            error={!!errors.riskTitle}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <StyledTypography>Risk Id</StyledTypography>
        <Typography style={{ fontWeight: 500 }}>{data.originalRiskId || data.riskId}</Typography>
      </Grid>
      {rsaArcherRskRecordReference && (
        <Grid
          item
          xs={12}
          md={4}
        >
          <StyledTypography>RSA Archer RSK Record Reference</StyledTypography>
          <Typography style={{ fontWeight: 500 }}>{rsaArcherRskRecordReference}</Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={4}
      >
        <StyledTypography>Risk Workflow Status: </StyledTypography>
        <Typography style={{ fontWeight: 500 }}>{riskStatus}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect<RiskOwnerFormInputs>
            control={control}
            name="elementId"
            required={true}
            label="Element"
            options={
              isReadOnly
                ? elementsOptions(
                    elements.map((e: EchoElement) => ({
                      elementName: e.name,
                      elementId: e.id,
                      elementType: e.type,
                      facilityId: e.facilityId,
                      facilityName: e.facilityName,
                    }))
                  )
                : elementsOptions(myElements)
            }
            error={!!errors.elementId}
            readOnly={isReadOnly}
            onChangeCallback={() => {
              setValue('healthScore', undefined);
              setValue('healthScoreColor', undefined);
            }}
          />
        </WrappedInput>
      </Grid>
      {((watchElementId !== 0 && loadHealthScore) || watchHealthScore) && (
        <HealthScore
          watchElementId={watchElementId}
          watchHealthScore={watchHealthScore}
          watchHealthScoreColor={watchHealthScoreColor}
          isReadOnly={isReadOnly}
          setValue={setValue}
        />
      )}
      {data.prevDecisionType === 'rejectAndResubmit' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              control={control}
              name="rejectionNotes"
              required={false}
              label="Rejection Notes"
              error={!!errors.rejectionNotes}
              readOnly={true}
            />
          </WrappedInput>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlTextField
            control={control}
            name="description"
            label="Risk Description"
            multiline={true}
            minRows={6}
            minLength={1}
            maxLength={3000}
            placeholder="Required (Character Limitation: 3000 characters)"
            error={!!errors.description}
            required={true}
            readOnly={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <StyledTypography>Risk Owner: </StyledTypography>
        <Typography style={{ fontWeight: 500 }}>{riskOwner}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            control={control}
            label="Opportunity or Threat"
            options={[
              { label: 'Opportunity', value: 'opportunity' },
              { label: 'Threat', value: 'threat' },
            ]}
            name="opportunityOrThreat"
            error={!!errors.opportunityOrThreat}
            required={true}
            readOnly={isReadOnly}
            onChangeCallback={onOpportunityOrThreatChange}
          />
        </WrappedInput>
      </Grid>
    </Grid>
  );
};

export default GeneralInfoForm;
