import React from 'react';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import CheckboxField from '../CheckboxField';

const Container = styled.div``;

const ChildWrapper = styled.div`
  padding-left: 32px;
`;

const ExpandingSection = ({ children, show }: { children?: React.ReactNode; show?: boolean }) => (
  <Collapse
    in={show}
    unmountOnExit
  >
    <ChildWrapper>{children}</ChildWrapper>
  </Collapse>
);

interface Props {
  className?: string;
  children?: React.ReactNode;
  id?: string;
  label?: string;
  checked?: boolean;
  onChange?(): void;
}

const CheckboxWithExpandingSection = ({ className, children, id, label, checked, onChange }: Props) => (
  <Container className={className}>
    <CheckboxField
      id={id}
      label={label}
      checked={checked}
      onChange={onChange}
    />
    <ExpandingSection show={checked}>{children}</ExpandingSection>
  </Container>
);

export default CheckboxWithExpandingSection;
