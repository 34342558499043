import msalInstance from './msalInstance';

export const getActiveAccount = () => {
  return msalInstance.getAllAccounts()[0] ?? null;
};

export const getUserName = () => {
  const account = getActiveAccount();
  return account?.name || 'Unknown User';
};

export const getUserId = () => {
  const account = getActiveAccount();
  return account?.localAccountId ?? null;
};

export const handleLogout = () => {
  // TODO: Navigate to login page
  return msalInstance.logoutRedirect();
};
