import React from 'react';
import { AllAttachmentTypesProvider } from '../../../../hooks/all-attachment-types';
import { Downloader } from '../../../../hooks/downloader';
import AttachmentsBox from './components/AttachmentsBox';

interface Props {
  walkdownId: number;
  taskId: number;
}

const UploadAttachmentsPage = ({ walkdownId, taskId }: Props) => (
  <AllAttachmentTypesProvider>
    <Downloader>
      <AttachmentsBox
        walkdownId={walkdownId}
        taskId={taskId}
      />
    </Downloader>
  </AllAttachmentTypesProvider>
);

export default UploadAttachmentsPage;
