import React from 'react';
import styled from 'styled-components';
import WorkOrderDetails from './WorkOrderDetails';

const Container = styled.div``;

interface Props {
  className?: string;
  recordDetails: any;
  dense?: boolean;
}

const OnlineDeficientMaintenanceBacklogDetails = ({ className, recordDetails, dense = false }: Props) => (
  <Container className={className}>
    {recordDetails.workOrder && (
      <WorkOrderDetails
        workOrder={recordDetails.workOrder}
        dense={dense}
      />
    )}
  </Container>
);

export default OnlineDeficientMaintenanceBacklogDetails;
