import * as R from 'ramda';
import styled from 'styled-components';
import { type Scorecard } from 'echo-scorecard';
import { type ScorecardStaleData, type ScorecardErrorMessage } from '../hooks/scorecard/types';

const ErrorText = styled.p`
  font-weight: 700;
  color: #ff0000;
  padding: 0px;
  margin: 0px;
`;

export type ErrorMessageProps = {
  scorecardDisplayable: Scorecard | ScorecardStaleData | null;
  scorecard: Scorecard;
  scorecardErrorMessage: ScorecardErrorMessage;
  staleData: ScorecardStaleData;
  noDataMessage?: string;
};
export const ScorecardErrorText = ({
  scorecardDisplayable,
  scorecard,
  scorecardErrorMessage,
  staleData,
  noDataMessage = '',
}: ErrorMessageProps) => {
  if (typeof scorecardErrorMessage !== 'string') {
    return null;
  }
  if (R.isEmpty(scorecard || {}) && !R.isEmpty(staleData || {})) {
    return (
      <ErrorText>Due to Error "{scorecardErrorMessage}" you are viewing data that was previously retrieved</ErrorText>
    );
  }
  if (!scorecardDisplayable) {
    return (
      <ErrorText>
        {scorecardErrorMessage}
        <br />
        {noDataMessage ? noDataMessage : `Please select a different element or hit 'refresh' in the browser.`}
      </ErrorText>
    );
  }

  return null;
};
export default ErrorText;
