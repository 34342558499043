import React from 'react';
import styled from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import UnstyledTotalScoreBox from './Scorecard/TotalScoreBox';
import usePreviousScore from '../hooks/previous-score';

const Typography = styled(MuiTypography)`
  font: ${({ theme }) => theme.fonts.normal14};
  align: center;
`;

const TotalScoreBox = styled(UnstyledTotalScoreBox)`
  height: 33px;
  width: 107px;
  margin-left: 22px;
`;

const PreviousStoryDialog = ({ indicatorName }: any) => {
  const { previousScoreData } = usePreviousScore();

  return (
    <>
      {previousScoreData ? (
        <Box
          m={4}
          p={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              Report {previousScoreData.year} - Q{previousScoreData.quarter}
            </Typography>

            <Typography>{indicatorName}</Typography>

            <TotalScoreBox
              totalScore={previousScoreData.score}
              scoreColour={previousScoreData.colour}
            />
          </Grid>
        </Box>
      ) : (
        <p>
          <em>No previous records exist</em>
        </p>
      )}
    </>
  );
};

export default PreviousStoryDialog;
