import React from 'react';
import styled from 'styled-components';
import DateInput from '../../../../components/DateInput';
import useMCRUnits from '../../../../hooks/mcr-units';
import MCRPatchParams from '../../../../types/mcr-patch-params';
import MCRUnit from '../../../../types/mcr-unit';

interface Props {
  unit: MCRUnit;
  handleSnackbar: () => void;
}

const UnitRowContainer = styled.div`
  display: flex;
  padding: 12px;
`;

const UnitRow = ({ unit, handleSnackbar }: Props) => {
  const { addUpdate, save } = useMCRUnits();

  const startDateInputHandler = (d: Date) => {
    addUpdate({ id: unit.id, startDate: d } as MCRPatchParams);
    save();
    handleSnackbar();
  };

  const endDateInputHandler = (d: Date) => {
    addUpdate({ id: unit.id, endDate: d } as MCRPatchParams);
    save();
    handleSnackbar();
  };

  return (
    <UnitRowContainer className="UnitRow">
      <div className="cell">{unit.id}</div>
      <div className="dateCell cell">
        <DateInput
          value={unit.startDate}
          onChange={startDateInputHandler}
          id={`mcr-unit-start-${unit.id}`}
        />
      </div>
      <div className="dateCell cell">
        <DateInput
          value={unit.endDate}
          onChange={endDateInputHandler}
          id={`mcr-unit-end-${unit.id}`}
        />
      </div>
    </UnitRowContainer>
  );
};

export default UnitRow;
