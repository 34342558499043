import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { WalkdownTemplateTask } from '../../../types/walkdown-template';
import { WalkdownTask, Walkdown } from '../../../types/walkdown-types';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../../state/ui/actions';
import useWalkdowns from '../../../hooks/walkdowns';

const SingleWalkdownContext = createContext<{
  walkdown?: Walkdown;
  isLoading: boolean;
  updateTask(categoryId: number, taskId: number, updates: Partial<WalkdownTask>): Promise<void>;
  update(v: Partial<Walkdown>): Promise<void>;
  setTaskComment(categoryId: number, taskId: number, text: string): Promise<void>;
  submitWalkdown(v: Partial<Walkdown>): Promise<void>;
  addTask(categoryId: number, t: WalkdownTemplateTask): Promise<WalkdownTask | null>;
  addTaskAttachment(taskId: number, file: any): Promise<any>;
}>(null as any);

interface ProviderProps {
  children: any;
  walkdownId?: number;
}

export const SingleWalkdownProvider = ({ children, walkdownId }: ProviderProps) => {
  const dispatch = useDispatch();
  const { refresh: refreshWalkdowns } = useWalkdowns();
  const [walkdown, setWalkdown] = useState<Walkdown>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchWalkdown = useCallback(async () => {
    if (walkdownId) {
      const data = await Api.getWalkdownById(walkdownId);
      setWalkdown(data);
      setIsLoading(false);
    }
  }, [walkdownId]);

  const update = useCallback(
    async (updates: Partial<Walkdown>) => {
      if (walkdown) {
        await Api.updateWalkdown(walkdown.id, updates);
        dispatch(uiActions.genericMessage('Walkdown Form Saved'));

        fetchWalkdown();
        refreshWalkdowns();
      }
    },
    [walkdown?.id]
  );

  const updateTask = useCallback(
    async (categoryId: number, taskId: number, updates: Partial<WalkdownTask>) => {
      if (walkdown) {
        await Api.updateWalkdownTask(walkdown.id, categoryId, taskId, updates);
        await fetchWalkdown();
      }
    },
    [walkdown]
  );

  const setTaskComment = useCallback(
    async (categoryId: number, taskId: number, text: string) => {
      if (walkdown?.id) {
        await Api.setWalkdownTaskComment(walkdown.id, categoryId, taskId, text);
        const data = await Api.getWalkdownById(walkdown.id);
        setWalkdown(data);
      }
    },
    [walkdown?.id, walkdownId]
  );

  const submitWalkdown = useCallback(
    async (updates: Partial<Walkdown>) => {
      if (walkdown?.id) {
        const message = walkdown.state === 'Pending' ? 'Walkdown Form Submitted' : 'Walkdown Form Saved';
        await Api.updateWalkdown(walkdown.id, updates);
        await Api.submitWalkdown(walkdown.id);
        dispatch(uiActions.genericMessage(message));

        fetchWalkdown();
        refreshWalkdowns();
      }
    },
    [walkdownId, walkdown?.id]
  );

  const addTask = useCallback(
    async (categoryId: number, task: WalkdownTemplateTask) => {
      if (walkdown?.id) {
        const data = await Api.addTaskToWalkdown(walkdown?.id, categoryId, task);
        await fetchWalkdown();
        return data;
      }
      return null;
    },
    [walkdown?.id]
  );

  const addTaskAttachment = useCallback(
    async (taskId: number, file: any) => {
      if (walkdown) {
        await Api.uploadTaskAttachment(walkdown.id, taskId, file);
        const attachment = await Api.getAttachmentByTaskId(walkdown.id, taskId);
        return attachment;
      }
      return null;
    },
    [walkdown?.id]
  );

  useEffect(() => {
    fetchWalkdown();
  }, [walkdownId]);

  const val = {
    walkdown,
    isLoading,
    updateTask,
    update,
    setTaskComment,
    submitWalkdown,
    addTask,
    addTaskAttachment,
  };

  return <SingleWalkdownContext.Provider value={val}>{children}</SingleWalkdownContext.Provider>;
};

const useSingleWalkdown = () => {
  const val = useContext(SingleWalkdownContext);
  if (!val) {
    throw new Error('useSingleWalkdown must be used inside its provider!');
  }
  return val;
};

export default useSingleWalkdown;
