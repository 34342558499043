import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import elementTypePrefix from '../../../utils/element-type-prefix';
import useMyAssignments from '../../../hooks/my-assignments';
import useForm from '../../../hooks/form';
import useQuarters, { toQuarterValue, fromQuarterValue } from '../../../hooks/quarters';
import Select from '../../../components/Select';
import CarlaText from '../../../components/CarlaText';
import DialogButton from '../../../components/DialogButton';

const formConfig = {
  elementId: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a system or component',
      },
    },
  },
  quarter: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a quarter',
      },
    },
  },
};

const initialValues = {
  elementId: '',
  quarter: '',
};

interface Props {
  open: boolean;
  onStartReport(elementId: string | number, year: string | number, quarter: string | number): void;
  onCancel(): void;
}

const StartReportDialog = ({ open, onStartReport, onCancel }: Props) => {
  const handleSubmit = (values: any) => {
    const [year, quarter] = fromQuarterValue(values.quarter);
    onStartReport(values.elementId, year, quarter);
  };

  const { submit, propsForField } = useForm(formConfig, initialValues, handleSubmit);
  const { myAssignments } = useMyAssignments();
  const { quarters } = useQuarters({ offset: -1 });

  const assignmentOptions = myAssignments.map((assignment: any) => ({
    value: assignment.elementId,
    label: `[${elementTypePrefix(assignment.elementType)}] ${assignment.elementName} (${assignment.facilityName})`,
  }));

  const quarterOptions = quarters.map(q => ({
    value: toQuarterValue(q.year, q.quarter),
    label: `Q${q.quarter} - ${q.year}`,
  }));

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Start a report</DialogTitle>
      <DialogContent>
        <CarlaText>Select a system and a quarter for your report</CarlaText>
        <Grid
          container
          direction="column"
          justifyContent="center"
        >
          <Select
            label="Element"
            options={assignmentOptions}
            {...propsForField('elementId')}
          />
          <Select
            label="Quarter"
            options={quarterOptions}
            {...propsForField('quarter')}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onCancel}>Cancel</DialogButton>
        <DialogButton
          color="primary"
          onClick={submit}
        >
          Continue
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default StartReportDialog;
