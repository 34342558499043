import React, { useContext, createContext } from 'react';

const Context = createContext<boolean | undefined>(false);

interface ProviderProps {
  children: any;
  value?: boolean;
}

export const ReadOnlyProvider = ({ children, value }: ProviderProps) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useReadOnly = () => {
  return useContext(Context);
};

export default useReadOnly;
