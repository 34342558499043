import * as R from 'ramda';
import * as actionTypes from '../action-types';

const initialState = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.CUSTOM_INDICATORS_LOAD_SUCCESS: {
      const { elementId, customIndicators } = action.payload;
      return {
        ...state,
        [elementId]: customIndicators,
      };
    }
    case actionTypes.CUSTOM_INDICATORS_ADD_SUCCESS: {
      const { customIndicatorId, elementId, name, description } = action.payload;
      return {
        ...state,
        [elementId]: R.append(
          { id: customIndicatorId, name, description, state: 'active' },
          R.propOr<any, any, any>([], elementId, state)
        ),
      };
    }
    case actionTypes.CUSTOM_INDICATORS_UPDATE_SUCCESS: {
      const { customIndicatorId, elementId, name, description } = action.payload;
      const result = R.map<any, any>(
        R.when<any, any>(R.propEq<any>('id', customIndicatorId), R.mergeRight<any, any>(R.__, { name, description })),
        R.propOr<any, any>([], elementId, state)
      );
      return {
        ...state,
        [elementId]: result,
      };
    }
    case actionTypes.CUSTOM_INDICATORS_DELETE_SUCCESS: {
      const { customIndicatorId, elementId } = action.payload;
      const result = R.map<any, any>(
        R.when<any, any>(R.propEq('id', customIndicatorId), R.assoc('state', 'inactive')),
        R.propOr<any, any>([], elementId, state)
      );
      return {
        ...state,
        [elementId]: result,
      };
    }
    default:
      return state;
  }
};
