import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { WorkOrder } from '../../../types/cwt/workorder';
import { useWorkOrders } from './useWorkOrders';

interface useWODetailsModalValue {
  open: boolean;
  handleOpen: (openTab: string) => void;
  handleClose: () => void;
  activeTab: string;
  handleSetActiveTab: (tab: string) => void;
  workorder: WorkOrder | undefined;
}

interface Props {
  children: ReactNode;
  onClose?: () => void;
}
const WODetailsModalContext = createContext<useWODetailsModalValue | undefined>(undefined);

export const WODetailsModalContextProvider = ({ children, onClose }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [workorder, setWorkorder] = useState<WorkOrder | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>('overview');
  const { getWorkorderById, selectedWorkorderId } = useWorkOrders();
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  const handleOpen = (openTab: string) => {
    setOpen(true);
    handleSetActiveTab(openTab);
  };

  const handleSetActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setWorkorder(getWorkorderById(selectedWorkorderId));
  }, [selectedWorkorderId]);

  return (
    <WODetailsModalContext.Provider value={{ open, handleOpen, handleClose, activeTab, handleSetActiveTab, workorder }}>
      {children}
    </WODetailsModalContext.Provider>
  );
};

export const useWODetailsModal = () => {
  const context = useContext(WODetailsModalContext);
  if (context === undefined) {
    throw new Error('useWODetailsModal must be used within a WODetailsModalContextProvider');
  }
  return context;
};
