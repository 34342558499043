import { useCallback, useEffect, useState } from 'react';
import api from '../../../../api';

const useAddAttachment = (walkdownId: any) => {
  const [attachments, setAttachments] = useState([]);

  const addAttachment = useCallback(async (id: number | string, file: any) => {
    await api.uploadWalkdownAttachment(id, file);
    api.getAttachmentsByWalkdownId(walkdownId).then((response: any) => {
      setAttachments(response);
    });
  }, []);

  const deleteAttachment = useCallback(async (id: number, attachmentId: number) => {
    await api.deleteWalkdownAttachment(id, attachmentId);
    api.getAttachmentsByWalkdownId(walkdownId).then((response: any) => {
      setAttachments(response);
    });
  }, []);

  useEffect(() => {
    api.getAttachmentsByWalkdownId(walkdownId).then((response: any) => {
      setAttachments(response);
    });
  }, [walkdownId]);

  return {
    addAttachment,
    attachments,
    deleteAttachment,
  };
};

export default useAddAttachment;
