import * as actionTypes from '../../../state/action-types';
import errorAction from '../../../state/utils/error-action';

export const setCurrentPage = pageName => ({
  type: actionTypes.HEALTH_REPORT_SET_CURRENT_PAGE,
  payload: {
    currentPage: pageName,
  },
});

export const setActiveStep = pageNumber => ({
  type: actionTypes.HEALTH_REPORT_SET_ACTIVE_STEP,
  payload: {
    activeStep: pageNumber,
  },
});

export const startReportRequest = (elementId, year, quarter) => ({
  type: actionTypes.HEALTH_REPORT_START_REPORT_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
  },
});

export const startReportFailure = (error, errorMessage) => ({
  type: actionTypes.HEALTH_REPORT_START_REPORT_FAILURE,
  payload: {
    error,
    errorMessage,
  },
});

export const updateIndicatorRecordRequest = (indicatorId, recordId, elementId, applicable, options) => ({
  type: actionTypes.HEALTH_REPORT_UPDATE_INDICATOR_RECORD_REQUEST,
  payload: {
    indicatorId,
    recordId,
    elementId,
    applicable,
    options,
  },
});

export const updateIndicatorRecordFailure = errorAction(actionTypes.HEALTH_REPORT_UPDATE_INDICATOR_RECORD_FAILURE);
