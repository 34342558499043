import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as reportActions from '../state/reports/actions';

const useRejectReport = reports => {
  const dispatch = useDispatch();
  const [rejectingReport, setRejectingReport] = useState(null);

  const confirmRejectReport = useCallback(
    reportId => {
      const report = R.find(R.propEq('id', reportId), reports);
      setRejectingReport(report);
    },
    [reports, setRejectingReport]
  );

  const cancelRejectReport = useCallback(() => {
    setRejectingReport(null);
  }, [setRejectingReport]);

  const rejectReport = useCallback(
    comment => {
      dispatch(reportActions.rejectRequest(rejectingReport, comment));
      setRejectingReport(null);
    },
    [dispatch, rejectingReport]
  );

  return {
    rejectingReport,
    confirmRejectReport,
    cancelRejectReport,
    rejectReport,
  };
};

export default useRejectReport;
