/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as reportAttachmentActions from '../state/element-attachments/actions';

const useDeleteReportAttachment = (elementId: number, year: string, quarter: string, attachments: any) => {
  const dispatch = useDispatch();
  const [attachmentToDelete, setAttachmentToDelete] = useState<any>(null);

  const confirmDeleteAttachment = useCallback(
    (attachmentId: string) => {
      setAttachmentToDelete(R.find(R.propEq('id', attachmentId), attachments));
    },
    [attachments, setAttachmentToDelete]
  );

  const cancelDeleteAttachment = useCallback(() => {
    setAttachmentToDelete(null);
  }, [setAttachmentToDelete]);

  const deleteAttachment = useCallback(() => {
    dispatch(reportAttachmentActions.deleteRequest(elementId, year, quarter, attachmentToDelete.id));
    setAttachmentToDelete(null);
  }, [dispatch, elementId, year, quarter, attachmentToDelete, setAttachmentToDelete]);

  return {
    attachmentToDelete,
    confirmDeleteAttachment,
    cancelDeleteAttachment,
    deleteAttachment,
  };
};

export default useDeleteReportAttachment;
