import styled from 'styled-components';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';

export type DraftSavingStatus = 'default' | 'saving' | 'saved' | 'error';

interface StatusButtonProps extends ButtonProps {
  status: DraftSavingStatus;
}

interface DraftButtonProps extends StatusButtonProps {
  onClick: () => void;
}

const StatusButton = styled(Button)<StatusButtonProps>`
  background-color: ${({ status }) => {
    switch (status) {
      case 'saving':
        return '#4050B5';
      case 'saved':
        return '#4CAF50';
      case 'error':
        return '#D3302F';
      default:
        return '#4050B5';
    }
  }};
  border: ${({ theme }) => theme.borders.thin.solid1};
  border-radius: 50px;
  font: ${({ theme }) => theme.fonts.medium14};
  padding: 8px 20px;
  transition-duration: 200ms;

  &.MuiButton-text {
    color: ${({ theme }) => theme.colours.white};
  }

  &.MuiButton-text:hover {
    background-color: ${({ status }) => {
      switch (status) {
        case 'saving':
          return '#4050B5';
        case 'saved':
          return '#4CAF50';
        case 'error':
          return '#D3302F';
        default:
          return '#2D387F';
      }
    }};
  }

  &.Mui-disabled {
    background-color: ${({ status, theme }) => (status === 'saved' ? '#4CAF50' : theme.colours.greys.lightGrey2)};
  }
`;

const DraftButton = (props: DraftButtonProps) => {
  const { status, onClick, disabled } = props;
  const buttonText = {
    default: 'Save Draft',
    saving: 'Saving...',
    saved: 'Draft Saved',
    error: 'Save Error',
  };

  const buttonIcon = {
    default: <SaveIcon />,
    saving: <SaveIcon />,
    saved: <CheckIcon />,
    error: <InfoIcon />,
  };

  return (
    <StatusButton
      onClick={onClick}
      status={status}
      disabled={disabled}
      startIcon={buttonIcon[status]}
    >
      {buttonText[status]}
    </StatusButton>
  );
};

export default DraftButton;
