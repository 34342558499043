import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateInput from './DateInput';
import ExpectedOutcomes from './ExpectedOutcomes';
import References from './References';
import CustomTextField from '../CustomTextField';
import CustomRadioGroup from '../CustomRadioGroup';
import CustomFormLabel from '../CustomFormLabel';
import Dropdown from '../Dropdown';
import useActionForm from './hooks/action-forms';
import useAction from '../../hooks/action';

interface Props {
  className?: string;
  propsForField: any;
  propsForListField: any;
  elementId: number | string;
  values: { references: any[] };
  disableRelatedRisk?: boolean;
}

const ActionForm = ({ className, propsForField, propsForListField, elementId, values, disableRelatedRisk }: Props) => {
  const { referencesActions, optionCategories, maxDate } = useActionForm(values);
  const action = useAction();

  return (
    <Grid
      container
      className={className}
    >
      <Grid
        item
        xs={12}
      >
        Action Item: {action.displayId}
      </Grid>
      <Grid
        item
        container
        xs={12}
      >
        <Grid container>
          <Grid
            item
            xs={8}
            md={11}
            lg={12}
          >
            <References
              elementId={elementId}
              referencesActions={referencesActions}
              {...propsForListField('references')}
            />
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={6}
            >
              <br />
              <ExpectedOutcomes {...propsForListField('expectedOutcomes')} />
            </Grid>
            <br />
            <Grid
              item
              lg={6}
            >
              <br />
              <DateInput
                label="Target Date"
                id="tcd"
                placeholder="Default to max Scheduled Date of any WO, or Override by user"
                headerText="Target Completion Date"
                disablePast
                defaultValue={maxDate || new Date()}
                {...propsForField('targetDate')}
              />
              <br />
              <CustomFormLabel>Category</CustomFormLabel>
              <Dropdown
                key={1}
                label="Category"
                options={optionCategories}
                style={{ width: '46vw' }}
                {...propsForField('category')}
              />
              <br />
              <br />
              <CustomTextField
                label="Owner (Optional)"
                placeholder="Add Owner"
                fullWidth
                {...propsForField('ownerName')}
              />
              <br />
              <br />
              <CustomTextField
                label="Related Risk Log Number (Optional)"
                fullWidth
                placeholder="Add Number"
                disabled={disableRelatedRisk}
                {...propsForField('relatedRiskScore')}
              />
              <br />
              <br />
              <CustomFormLabel>Health Focus Rating</CustomFormLabel>
              <CustomRadioGroup
                style={{ height: '45px' }}
                options={[
                  { value: 'sh1', label: 'SH1' },
                  { value: 'sh2', label: 'SH2' },
                  { value: 'sh3', label: 'SH3' },
                  { value: 'mgmt', label: 'MGMT' },
                ]}
                {...propsForField('systemHealthFocusRating')}
              />
              <CustomTextField
                label="Comment (Optional)"
                fullWidth
                multiline
                minRows={10}
                {...propsForField('description')}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ActionForm;
