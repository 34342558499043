import { onRenderRow, renderMaximoLink, renderMaximoTaskLink } from '../../../wo/components/utils';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useWorkorderTasks } from '../../../hooks/useWorkorderTasks';
import { useWorkOrders } from '../../../hooks/useWorkOrders';
import { renderIsASD } from '../../../assessing/components/utils';
import format from 'date-fns/format';
import { useState } from 'react';

const gridStyle = { minHeight: 600, minWidth: '100%' };

const TaskTable = () => {
  const { taskLoading, tasks } = useWorkorderTasks();
  const { getWorkorderByWonum } = useWorkOrders();

  const [sortDir, setSortDir] = useState(0);

  const task_columns = [
    {
      name: 'wonum',
      header: 'Task Number',
      defaultWidth: 80,
      render: ({ data }: any) => renderMaximoTaskLink(data.workorderid, data.wonum),
    },
    {
      name: 'parent',
      header: 'Parent',
      defaultWidth: 80,
      render: ({ data }: any) => renderMaximoLink(getWorkorderByWonum(data.parent)?.id, data.parent),
    },
    { name: 'taskid', header: 'Task Id', defaultWidth: 60 },
    { name: 'wopriority', header: 'Priority', defaultWidth: 60 },
    { name: 'worktype', header: 'WO Type', defaultWidth: 80 },
    { name: 'status', header: 'Status', defaultWidth: 80 },
    { name: 'siteid', header: 'Facility', defaultWidth: 100 },
    { name: 'description', minWidth: 140, defaultFlex: 1, header: 'WO Description' },
    { name: 'isasd', header: 'Assess.', defaultWidth: 60, textAlign: 'center' as const, render: renderIsASD },
    { name: 'crewworkgroup', header: 'Task Crew', defaultWidth: 80 },
    { name: 'ownergroup', header: 'Owner Group', defaultWidth: 80 },
    { name: 'plusplanner', header: 'Assessor', defaultWidth: 80 },
    { name: 'bpspm', header: 'SPM', defaultWidth: 80 },
    {
      name: 'schedstart',
      header: 'Scheduled Start',
      sort: (value1: any, value2: string | undefined) => {
        if (!value2) return -sortDir;
        if (!value1) return sortDir;
        return Date.parse(value1) - Date.parse(value2);
      },
      defaultWidth: 130,
      render: (value: any) => {
        const date = new Date(value.data.schedstart);
        return <span>{value.data.schedstart ? format(date, 'ddMMMyyyy').toUpperCase() : null}</span>;
      },
    },
    {
      name: 'schedfinish',
      header: 'Scheduled Finish',
      sort: (value1: any, value2: string | undefined) => {
        if (!value2) return -sortDir;
        if (!value1) return sortDir;
        return Date.parse(value1) - Date.parse(value2);
      },
      defaultWidth: 130,
      render: (value: any) => {
        const date = new Date(value.data.schedfinish);
        return <span>{value.data.schedfinish ? format(date, 'ddMMMyyyy').toUpperCase() : null}</span>;
      },
    },
  ];

  return (
    <ReactDataGrid
      style={gridStyle}
      idProperty="id"
      rowStyle={onRenderRow}
      pagination
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={task_columns}
      dataSource={tasks}
      showCellBorders={false}
      loading={taskLoading}
      onSortInfoChange={(sortInfo: any) => {
        setSortDir(sortInfo?.dir ?? 0);
      }}
    />
  );
};

export default TaskTable;
