import React, { useCallback, useState } from 'react';
import { Checkbox, IconButton, TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import * as R from 'ramda';
import CustomTextField from '../../../../../components/CustomTextField';
import { WalkdownTemplateTask } from '../../../../../types/walkdown-template';
import useForm from '../../../../../hooks/form';
import { defaultValues, formConfig } from './createTaskformConfig';
import RangeInputCell from './RangeInputCell';
import UOMInputCell from './UOMInputCell';
import useWalkdownInputTypes, { WalkdownInputTypesProvider } from '../../../hooks/useWalkdownInputTypes';

const TextBox = styled(CustomTextField)`
  border: none;
`;

interface Props {
  onCreate(t: WalkdownTemplateTask): void;
  overrideDefaultValues?: WalkdownTemplateTask;
}

const CreateTask = ({ onCreate, overrideDefaultValues }: Props) => {
  const { inputTypes } = useWalkdownInputTypes();
  const [rangeError, setRangeError] = useState('');

  const createHandler = useCallback(
    (rawValues: any) => {
      const v = { ...rawValues };

      if (!R.isNil(v.max)) {
        v.max = parseFloat(v.max);
      }

      if (!R.isNil(v.min)) {
        v.min = parseFloat(v.min);
      }

      if (v.isMeasurement) {
        const unitData = inputTypes.find(inp => inp.id === v.inputType);

        // check that the min and max values are within bounds of absoulte max and min
        if (!R.isNil(v.max) && !R.isNil(unitData?.absoluteMax)) {
          // Disabling the non null assertion rule since the if statement above ensures its not null
        if (v.max > unitData!.absoluteMax) { //eslint-disable-line
            setRangeError('Max value is out of bounds!');
            return;
          }
        }

        if (!R.isNil(v.min) && !R.isNil(unitData?.absoluteMin)) {
        if (v.min < unitData!.absoluteMin) { //eslint-disable-line
            setRangeError('Minimum value is out of bounds!');
            return;
          }
        }

        if (!R.isNil(v.min) && !R.isNil(v.max)) {
          if (v.min >= v.max) {
            setRangeError('Minimum value must be less than maximim value!');
            return;
          }
        }
      } else {
        delete v.max;
        delete v.min;
        delete v.inputType;
      }
      onCreate(v);
    },
    [onCreate]
  );

  const { submit, propsForField, values, setField } = useForm(
    formConfig,
    overrideDefaultValues || defaultValues,
    createHandler
  );

  const measurementHandler = (e: any) => {
    setField('isMeasurement', e.target.checked);
  };

  const inputTypeChangeHandler = (e: any) => {
    setField('inputType', e.target.value);
  };

  return (
    <TableRow>
      <TableCell>
        <TextBox
          fullWidth
          label={'Name'}
          {...propsForField('title')}
          inputProps={{
            maxLength: 50,
          }}
        />
      </TableCell>
      <TableCell>
        <TextBox
          fullWidth
          label={'Description'}
          {...propsForField('description')}
          inputProps={{
            maxLength: 50,
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          onChange={measurementHandler}
          checked={values.isMeasurement}
        />
      </TableCell>

      <TableCell align="center">
        <span
          style={{
            color: 'red',
          }}
        >
          {rangeError}
        </span>
        {values.isMeasurement && values.inputType && <RangeInputCell propsForField={propsForField} />}
      </TableCell>

      <TableCell align="center">
        {values.isMeasurement && (
          <UOMInputCell
            value={values.inputType}
            onChange={inputTypeChangeHandler}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          onClick={submit}
        >
          <CheckIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default (props: Props) => (
  <WalkdownInputTypesProvider>
    <CreateTask {...props} />
  </WalkdownInputTypesProvider>
);
