import React from 'react';
import styled from 'styled-components';
import UnstyledFilterSelector from '../../../../../components/FilterSelector';
import { toQuarterValue } from '../../../../../hooks/quarters';
import Grid from '@material-ui/core/Grid';
import useAttachmentType from '../hooks/attachment-type';
import useQuarter from '../hooks/quarter';
import UnstyledPanel from '../../../../../components/Panel';
import AutocompleteInput from '../../../../../components/AutocompleteInput';
import useAttachments from '../hooks/attachments';

const FilterSelector = styled(UnstyledFilterSelector)``;

//styled-components do not preserve generic props (loses the ability to infer the type) so we must explicitly cast to the appropriate types
const AttachmentTypeSelector = styled(AutocompleteInput as typeof AutocompleteInput<string, false>)`
  min-width: 180px;
`;

const Panel = styled(UnstyledPanel)`
  ${FilterSelector} {
    margin-right: 10px;
  }
`;

const FilterPanel: React.FC = () => {
  //used for attachmentTypeOptions and quarterOptions to match table and filter dropdowns
  const { attachments } = useAttachments();
  const { attachmentType, onChangeAttachmentType } = useAttachmentType();
  const { quarterValue, onChangeQuarterValue } = useQuarter();

  //.filter: no duplicate quarters, .sort: sort by year and quarter, .reverse: recents first
  const seen = new Set(); //ensures that the quarter dropdown only shows each quarter once
  const quarters = attachments
    .map(a => ({ year: a.year, quarter: a.quarter }))
    .filter(q => {
      const key = `${q.year}-${q.quarter}`;
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    })
    // Sort in reverse chronological order
    .sort((a, b) => {
      if (a.year === b.year) {
        return b.quarter - a.quarter;
      }
      return b.year - a.year;
    });

  //ensures that the attachment type dropdown only shows each attachment type once
  const attachmentTypeOptions = attachments.reduce((uniqueOptions, a) => {
    if (!uniqueOptions.some((option: { label: string }) => option.label === a.typeName)) {
      uniqueOptions.push({ value: a.type, label: a.typeName });
    }
    return uniqueOptions;
  }, []);

  const quarterOptions = quarters.map(q => ({
    label: `${q.year} Q${q.quarter}`,
    value: toQuarterValue(q.year, q.quarter),
  }));

  return (
    <Panel>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={3}
          sm={4}
          xs={12}
        >
          <AttachmentTypeSelector
            label={'Attachment Type'}
            value={attachmentType}
            onChange={value => onChangeAttachmentType(value ?? '')}
            options={attachmentTypeOptions}
          />
        </Grid>
        <Grid
          item
          md={3}
          sm={4}
          xs={12}
        >
          <AutocompleteInput
            label={'Quarter'}
            value={quarterValue}
            onChange={value => onChangeQuarterValue(value ?? '')}
            options={quarterOptions}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};

export default FilterPanel;
