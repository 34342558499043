import { BusinessObjective } from '../types/business-objectives';
import { MSMProgram } from '../types/msm-programs';
import { OutagePlan } from '../types/outage-plans';
import {
  RiskOwnerFormInputs,
  RiskEnvironmentGroupFormInputs,
  RiskApproverFormInputs,
  ImpactProbabilityAssessment,
  USER_ROLES,
  UserWithRoles,
  ImpactProbabilityAssessmentDetail,
  Risk,
} from '../types/risk-management';
import { Path, Remote } from './types';

const getRiskOwnerForm = (path: Path, remote: Remote) => (riskId: number) => remote.get(path(`/${riskId}/risk-owner`));

const getRiskApproverForm =
  (path: Path, remote: Remote) =>
  (riskId: number): RiskApproverFormInputs =>
    remote.get(path(`/${riskId}/risk-approver`));

const submitRiskOwnerForm =
  (path: Path, remote: Remote) =>
  (
    formInputs: RiskOwnerFormInputs,
    riskId: number // From context
  ) =>
    remote.post(path(`/${riskId}/risk-owner`), { ...formInputs });

const notifyRiskApprovers = (path: Path, remote: Remote) => (riskId: number, users: string[], envRequired: boolean) =>
  remote.post(path(`/${riskId}/risk-owner/notify`), { users, envRequired });

const getOutagePlans = (path: Path, remote: Remote) => (): Promise<OutagePlan[]> => remote.get(path('/outage-plans'));

const addOutagePlan = (path: Path, remote: Remote) => (outageCode: string, status: string) =>
  remote.post(path('/outage-plans'), { outageCode: outageCode, status: status });

const editOutagePlan =
  (path: Path, remote: Remote) => (outageCode: string, status: string, originalOutageCode: string) =>
    remote.put(path('/outage-plans'), {
      outageCode: outageCode,
      status: status,
      originalOutageCode: originalOutageCode,
    });

const deleteOutagePlan = (path: Path, remote: Remote) => (outageCode: string) =>
  remote.del(path(`/outage-plans/${outageCode}`));

const getMSMPrograms = (path: Path, remote: Remote) => (): Promise<MSMProgram[]> => remote.get(path('/msm-programs'));

const addMSMProgram = (path: Path, remote: Remote) => (msmProgram: string, status: string) =>
  remote.post(path('/msm-programs'), { msmProgram: msmProgram, status: status });

const editMSMProgram =
  (path: Path, remote: Remote) => (msmProgram: string, status: string, originalMSMProgram: string) =>
    remote.put(path('/msm-programs'), {
      msmProgram: msmProgram,
      status: status,
      originalMSMProgram: originalMSMProgram,
    });

const deleteMSMProgram = (path: Path, remote: Remote) => (msmProgram: string) =>
  remote.del(path(`/msm-programs/${msmProgram}`));

const getBusinessObjectives = (path: Path, remote: Remote) => (): Promise<BusinessObjective[]> =>
  remote.get(path('/business-objectives'));

const addBusinessObjectives = (path: Path, remote: Remote) => (title: string, status: string) =>
  remote.post(path('/business-objectives'), { title: title, status: status });

const editBusinessObjectives = (path: Path, remote: Remote) => (title: string, status: string, originalTitle: string) =>
  remote.put(path('/business-objectives'), {
    title: title,
    status: status,
    originalTitle: originalTitle,
  });

const deleteBusinessObjective = (path: Path, remote: Remote) => (title: string) =>
  remote.del(path(`/business-objectives/${title}`));

const saveDraftRiskOwnerForm =
  (path: Path, remote: Remote) =>
  (
    formInputs: RiskOwnerFormInputs,
    riskId: number // From context
  ) =>
    remote.post(path(`/${riskId}/risk-owner/draft`), formInputs);

const updateRiskOwnerForm = (path: Path, remote: Remote) => (formInputs: RiskOwnerFormInputs, riskId: number) =>
  remote.put(path(`/${riskId}/risk-owner`), formInputs);

const updateDraftRiskOwnerForm = (path: Path, remote: Remote) => (formInputs: RiskOwnerFormInputs, riskId: number) =>
  remote.put(path(`/${riskId}/risk-owner/draft`), formInputs);

const setRiskOwner = (path: Path, remote: Remote) => (elementId: number, userId: string) =>
  remote.post(path(`/risk-owners-group/${elementId}`), { userId });

const clearRiskOwner = (path: Path, remote: Remote) => (elementId: number, userId: string) =>
  remote.del(path(`/risk-owners-group/${elementId}/${userId}`));

const getRiskOwnersGroup = (path: Path, remote: Remote) => (elementId: number) =>
  remote.get(path(`/risk-owners-group/${elementId}`));

const getRiskOwnersGroupByUserId = (path: Path, remote: Remote) => (userId: number) =>
  remote.get(path(`/risk-owners-group/user/${userId}`));

const getMyRiskOwnersGroup = (path: Path, remote: Remote) => () => remote.get(path(`/risk-owners-group/user/my`));

const submitEnvGroupForm =
  (path: Path, remote: Remote) => (riskManagementId: number, formInputs: RiskEnvironmentGroupFormInputs) =>
    remote.post(path(`/${riskManagementId}/env-group`), formInputs);

const updateEnvGroupForm =
  (path: Path, remote: Remote) => (riskManagementId: number, formInputs: RiskEnvironmentGroupFormInputs) =>
    remote.put(path(`/${riskManagementId}/env-group`), formInputs);

const getAllRisks = (path: Path, remote: Remote) => (elementId: string, status?: string[]) => {
  const statusQuery = status ? `&status=${status.join(',')}` : '';
  return remote.get(path(`/?elementId=${elementId + statusQuery}`));
};

const getRiskGeneralForm =
  (path: Path, remote: Remote) =>
  (riskId: number): Promise<Risk> =>
    remote.get(path(`/${riskId}`));

const addNewRiskManagementForm = (path: Path, remote: Remote) => () => remote.post(path('/'));

const submitRiskApproverForm =
  (path: Path, remote: Remote) =>
  (formInputs: Omit<RiskApproverFormInputs, 'escalateUsers'> & { escalateUsers: string[] }, riskManagementId: number) =>
    remote.post(path(`/${riskManagementId}/risk-approver`), formInputs);

const getEnvironmentalCategories = (path: Path, remote: Remote) => () => remote.get(path('/environment-categories'));

const getRiskEnvironmentGroup = (path: Path, remote: Remote) => (riskManagementId: number) =>
  remote.get(path(`/${riskManagementId}/env-group`));

const deleteRisk = (path: Path, remote: Remote) => (riskId: number) => remote.del(path(`/${riskId}`));

const getImpactProbabilityConstants = (path: Path, remote: Remote) => () =>
  remote.get(path('/constants/impact-probability-assessment'));

const getImpactProbabilityAssessments =
  (path: Path, remote: Remote) =>
  (riskManagementId: number): Promise<ImpactProbabilityAssessmentDetail[]> =>
    remote.get(path(`/${riskManagementId}/impact-probability-assessment`));

const getImpactProbabilityAssessment =
  (path: Path, remote: Remote) =>
  (riskManagementId: number, ipaId: number): Promise<Omit<ImpactProbabilityAssessment, 'year'> & { year: number }> =>
    remote.get(path(`/${riskManagementId}/impact-probability-assessment/${ipaId}`));

const postImpactProbabilityAssessment =
  (path: Path, remote: Remote) =>
  (
    riskManagementId: number,
    formInputs: Omit<ImpactProbabilityAssessment, 'year'> & { year: number }
  ): Promise<string> =>
    remote.post(path(`/${riskManagementId}/impact-probability-assessment`), formInputs);

const updateImpactProbabilityAssessment =
  (path: Path, remote: Remote) =>
  (
    riskManagementId: number,
    ipaId: number,
    formInputs: Omit<ImpactProbabilityAssessment, 'year'> & { year: number }
  ): Promise<string> =>
    remote.put(path(`/${riskManagementId}/impact-probability-assessment/${ipaId}`), formInputs);

const deleteImpactProbabilityAssessments = (path: Path, remote: Remote) => (riskManagementId: number) =>
  remote.del(path(`/${riskManagementId}/impact-probability-assessment`));

const addUser =
  (path: Path, remote: Remote) =>
  (userIds: string[], role: USER_ROLES): Promise<void> =>
    remote.post(path('/users'), { userIds, role });

const getUsers = (path: Path, remote: Remote) => (): Promise<UserWithRoles[]> => remote.get(path('/users'));

const getRolesForCurrentUser = (path: Path, remote: Remote) => (): Promise<USER_ROLES[]> =>
  remote.get(path('/users/roles'));

const deleteRoleForUser = (path: Path, remote: Remote) => (userId: string, role: USER_ROLES) =>
  remote.del(path('/users'), { data: { userId, role } });

export default (path: Path, remote: Remote) => ({
  getImpactProbabilityConstants: getImpactProbabilityConstants(path, remote),
  submitRiskOwnerForm: submitRiskOwnerForm(path, remote),
  notifyRiskApprovers: notifyRiskApprovers(path, remote),
  getAllRisks: getAllRisks(path, remote),
  getRiskGeneralForm: getRiskGeneralForm(path, remote),
  getRiskEnvironmentGroup: getRiskEnvironmentGroup(path, remote),
  getEnvironmentalCategories: getEnvironmentalCategories(path, remote),
  addNewRiskManagementForm: addNewRiskManagementForm(path, remote),
  submitRiskApproverForm: submitRiskApproverForm(path, remote),
  submitEnvGroupForm: submitEnvGroupForm(path, remote),
  deleteRisk: deleteRisk(path, remote),
  updateEnvGroupForm: updateEnvGroupForm(path, remote),
  getRiskOwnerForm: getRiskOwnerForm(path, remote),
  updateRiskOwnerForm: updateRiskOwnerForm(path, remote),
  saveDraftRiskOwnerForm: saveDraftRiskOwnerForm(path, remote),
  getImpactProbabilityAssessments: getImpactProbabilityAssessments(path, remote),
  postImpactProbabilityAssessment: postImpactProbabilityAssessment(path, remote),
  updateImpactProbabilityAssessment: updateImpactProbabilityAssessment(path, remote),
  deleteImpactProbabilityAssessments: deleteImpactProbabilityAssessments(path, remote),
  getImpactProbabilityAssessment: getImpactProbabilityAssessment(path, remote),
  updateDraftRiskOwnerForm: updateDraftRiskOwnerForm(path, remote),
  addRiskManagementUsers: addUser(path, remote),
  getRiskManagementUsers: getUsers(path, remote),
  getRolesForCurrentUser: getRolesForCurrentUser(path, remote),
  deleteRoleForUser: deleteRoleForUser(path, remote),
  getOutagePlans: getOutagePlans(path, remote),
  addOutagePlan: addOutagePlan(path, remote),
  editOutagePlan: editOutagePlan(path, remote),
  getMSMPrograms: getMSMPrograms(path, remote),
  addMSMProgram: addMSMProgram(path, remote),
  editMSMProgram: editMSMProgram(path, remote),
  getBusinessObjectives: getBusinessObjectives(path, remote),
  addBusinessObjectives: addBusinessObjectives(path, remote),
  editBusinessObjectives: editBusinessObjectives(path, remote),
  deleteOutagePlan: deleteOutagePlan(path, remote),
  deleteBusinessObjective: deleteBusinessObjective(path, remote),
  deleteMSMProgram: deleteMSMProgram(path, remote),
  getRiskApproverForm: getRiskApproverForm(path, remote),
  setRiskOwner: setRiskOwner(path, remote),
  clearRiskOwner: clearRiskOwner(path, remote),
  getRiskOwnersGroup: getRiskOwnersGroup(path, remote),
  getRiskOwnersGroupByUserId: getRiskOwnersGroupByUserId(path, remote),
  getMyRiskOwnersGroup: getMyRiskOwnersGroup(path, remote),
});
