/* eslint-disable @typescript-eslint/no-explicit-any */
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

interface Props {
  className?: string;
  children?: React.ReactNode;
  title: string;
}

const Title = styled(Typography)`
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme }) => theme.colours.greys.darkGrey3};
`;

const Named = ({ className, children, title }: Props) => (
  <Grid
    container
    className={className}
    direction="column"
    spacing={1}
  >
    <Grid
      item
      xs={12}
    >
      <Title>{title}</Title>
    </Grid>
    <Grid
      item
      xs={12}
    >
      {children}
    </Grid>
  </Grid>
);

export default Named;
