import * as actionTypes from '../action-types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECORDS_SUMMARY_LOAD_REQUEST: {
      const { indicatorId } = action.payload;
      return {
        ...state,
        [indicatorId]: {
          ...state[indicatorId],
          isLoading: true,
          error: undefined,
        },
      };
    }
    case actionTypes.RECORDS_SUMMARY_LOAD_SUCCESS: {
      const { indicatorId, recordsSummary } = action.payload;
      return {
        ...state,
        [indicatorId]: {
          recordsSummary,
          isLoading: false,
        },
      };
    }
    case actionTypes.RECORDS_SUMMARY_LOAD_FAILURE: {
      const { error, indicatorId } = action.payload;
      return {
        ...state,
        [indicatorId]: {
          recordsSummary: undefined,
          isLoading: false,
          error,
        },
      };
    }
    default:
      return state;
  }
};
