import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadRequest = (type, category) => ({
  type: actionTypes.SCORECARD_COLOURS_LOAD_REQUEST,
  payload: {
    type,
    category,
  },
});

export const loadSuccess = (type, category, scorecardColours) => ({
  type: actionTypes.SCORECARD_COLOURS_LOAD_SUCCESS,
  payload: {
    type,
    category,
    scorecardColours,
  },
});

export const loadFailure = errorAction(actionTypes.SCORECARD_COLOURS_LOAD_FAILURE);
