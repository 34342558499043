import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReferenceForm from '../ReferenceForm';
import StartIconButton from '../StartIconButton';
import FormTitle from './FormTitle';
import FormLabel from '../CustomFormLabel';
import ReferenceList from './ReferenceList';
import useAddListItem from '../../hooks/add-list-item';

interface Props {
  className?: any;
  error: boolean;
  helperText?: string | null;
  onAddItem: any;
  onRemoveItem: any;
  onUpdateItem: any;
  values: [];
  elementId: number | string;
}

const References = ({
  className,
  error,
  helperText,
  onAddItem,
  onUpdateItem,
  onRemoveItem,
  values,
  elementId,
}: Props) => {
  const { adding, add, onCheckAndAdd, onCancelAdd } = useAddListItem(onAddItem, elementId);

  return (
    <Grid
      container
      className={className}
    >
      <Grid
        item
        xs={12}
      >
        <FormLabel error={error}>References</FormLabel>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ReferenceList
          references={values}
          onUpdateItem={onUpdateItem}
          onRemoveItem={onRemoveItem}
        />
      </Grid>
      {!adding && (
        <Grid
          item
          xs={12}
        >
          <StartIconButton onClick={add}>Add</StartIconButton>
        </Grid>
      )}
      {adding && (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <FormTitle>New Reference</FormTitle>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ReferenceForm
              onSubmit={onCheckAndAdd}
              onCancel={onCancelAdd}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default References;
