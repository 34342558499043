import { FormProvider } from 'react-hook-form';
import { useFormProps } from '../../hooks/useFormProps';
import AddReferenceForm from './AddReferenceForm';
import EditReferenceForm from './EditReferenceForm';

export interface ReferenceFormProps {
  handleClose: () => void;
  edit?: boolean;
}

export default ({ handleClose, edit }: ReferenceFormProps) => {
  const formProps = useFormProps(edit);
  const ReferenceForm = edit ? EditReferenceForm : AddReferenceForm;
  return (
    <FormProvider {...formProps}>
      <ReferenceForm handleClose={handleClose} />
    </FormProvider>
  );
};
