import ActionsCell from '../../ActionTable/ActionsCell';
import * as R from 'ramda';
import CellText from '../../ActionTable/CellText';
import isFeatureEnabled from '../../../utils/feature-flags';
import { MaximoReference } from '../../../types/actions';

const useReferenceList = (confirm: any) => {
  const workOrderColumns = [
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'nameReference',
      label: 'Reference',
      align: 'left',
    },
    {
      id: 'maximoDESCRIPTION',
      label: 'Title',
      align: 'center',
    },
    {
      id: 'maximoLOCATION',
      label: 'Location',
      align: 'center',
    },
    {
      id: 'maximoSTATUS',
      label: 'Status',
      align: 'center',
    },
    {
      id: 'maximoWORKTYPE',
      label: 'Type',
      align: 'center',
    },
    {
      id: 'maximoCREWWORKGROUP',
      label: 'Crew',
      align: 'center',
    },
    {
      id: 'maximoPLUSOUTAGECODE',
      label: 'Outage',
      align: 'center',
    },
    {
      id: 'maximoSCHEDFINISH',
      label: 'SCHD Date',
      align: 'center',
      render: (action: MaximoReference) => (
        <CellText>
          {isFeatureEnabled('scheduleVsCompletionDate') &&
          action.maximoWOCLASS === 'ACTIVITY' &&
          action.maximoORIGRECORDID &&
          action.maximoORIGRECORDCLASS === 'CORRECT_ACT'
            ? action.maximoTARGCOMPDATE
            : action.maximoSCHEDFINISH}
        </CellText>
      ),
    },
    {
      id: 'maximoACTFINISH',
      label: 'COMP Date',
      align: 'center',
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      render: (actionItem: any) => (
        <ActionsCell
          className=""
          state={'inprog'}
          action={actionItem}
          onDelete={R.thunkify(confirm)(null, actionItem.id, actionItem.referenceNumber)}
        />
      ),
    },
  ];

  const workReportColumns = [
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'nameReference',
      label: 'Reference',
      align: 'left',
    },

    {
      id: 'maximoDESCRIPTION',
      label: 'Title',
      align: 'center',
    },
    {
      id: 'maximoLOCATION',
      label: 'Location',
      align: 'center',
    },
    {
      id: 'maximoSTATUS',
      label: 'Status',
      align: 'center',
    },
    {
      id: 'maximoCLASS_DESCRIPTION',
      label: 'Type',
      align: 'center',
    },
    {
      id: 'maximoOWNERGROUP',
      label: 'Owner Group',
      align: 'center',
    },
    {
      id: 'maximoPLUSOUTAGECODE',
      label: 'Outage',
      align: 'center',
    },
    {
      id: 'maximoTARGETFINISH',
      label: 'SCHD Date',
      align: 'center',
    },
    {
      id: 'maximoACTUALFINISH',
      label: 'COMP Date',
      align: 'center',
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      render: (actionItem: any) => (
        <ActionsCell
          className=""
          state={'inprog'}
          action={actionItem}
          onDelete={R.thunkify(confirm)(null, actionItem.id, actionItem.referenceNumber)}
        />
      ),
    },
  ];

  const otherReferencesColumn = [
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'nameReference',
      label: 'Other References',
      align: 'left',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'none',
      label: '',
      align: 'center',
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      render: (actionItem: any) => (
        <ActionsCell
          className=""
          state={'inprog'}
          action={actionItem}
          onDelete={R.thunkify(confirm)(null, actionItem.id, actionItem.referenceNumber)}
        />
      ),
    },
  ];

  return {
    workOrderColumns,
    workReportColumns,
    otherReferencesColumn,
  };
};

export default useReferenceList;
