import React from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AutocompleteInput from '../../../components/AutocompleteInput';
import elementTypePrefix from '../../../utils/element-type-prefix';
import { MyElement } from '../../../types/my';

interface Props {
  elements: MyElement[];
  selectedElement: MyElement | null;
  onChangeElement(id: string): void;
  showDisclaimer?: boolean;
}

const ElementFilter = ({ elements, selectedElement, onChangeElement, showDisclaimer = false }: Props) => {
  // Only show component- and system-type elements because program-type elements don't have live data
  const elementOptions = elements
    .filter(element => element.elementType !== 'program')
    .map(element => ({
      label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
      value: `${element.elementId}`,
    }));
  const autocompleteValue = selectedElement && selectedElement?.elementId ? `${selectedElement.elementId}` : null;
  return (
    <Box my={1}>
      <Grid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid
          item
          xs={4}
        >
          <AutocompleteInput
            label="Element"
            value={autocompleteValue}
            onChange={value => onChangeElement(value || '')}
            options={elementOptions}
          />
        </Grid>
      </Grid>
      {showDisclaimer && (
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
          >
            <Alert severity="warning">
              <b>
                As of today for the period Q{moment().quarter()} {moment().year()}
              </b>
            </Alert>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default ElementFilter;
