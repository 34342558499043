import * as R from 'ramda';
import useIndicatorRecordId from '../../../../../hooks/indicator-record-id';

const useDetailsRecordFinder = (records, indicatorId) => {
  const indicatorRecordId = useIndicatorRecordId(indicatorId);

  const findRecord = recordId => R.find(record => indicatorRecordId(record) === recordId, records);

  return findRecord;
};

export default useDetailsRecordFinder;
