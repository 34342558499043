import { Grid } from '@material-ui/core';
import React from 'react';
import ActionButton from '../../../../components/ActionButton';
import { useAddReferenceForm } from '../../hooks/useAddReferenceForm';
import { Inputs } from './inputs';

export interface ReferenceFormProps {
  handleClose: () => void;
}

const AddReferenceForm: React.FC<ReferenceFormProps> = ({ handleClose }) => {
  const { control, errors, handleSubmit, actionTypeOptions, outageCodeOptions, referenceTypeOptions } =
    useAddReferenceForm(handleClose);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Inputs.OutageCode
            control={control}
            options={outageCodeOptions}
            error={!!errors.outageCode}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Inputs.ActionType
            control={control}
            options={actionTypeOptions}
            error={!!errors.actionType}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Inputs.ReferenceType
            control={control}
            options={referenceTypeOptions}
            error={!!errors.referenceType}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Inputs.ReferenceNumberText
            control={control}
            error={!!errors.referenceNumberText}
            maxLength={20}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ActionButton
            color="primary"
            type="submit"
          >
            Confirm
          </ActionButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddReferenceForm;
