import styled from 'styled-components';
import UnstyledCheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UnstyledNotInterestedIcon from '@material-ui/icons/NotInterested';
import UnstyledCommentIcon from '@material-ui/icons/Comment';
import { Badge, Box, Grid, Tooltip, makeStyles } from '@material-ui/core';
import ActionsCell from './HoverActions/ActionsCell';
import { Note, WorkOrder } from '../../../../types/cwt/workorder';
import { generateMaximoLink } from '../../utils';
import CWTToolTip from '../../components/CWTToolTip';
import { startOfWeek, endOfWeek, format } from 'date-fns';

const CheckCircleOutlineIcon = styled(UnstyledCheckCircleOutlineIcon)`
  color: rgb(10, 153, 15);
`;

const NotInterestedIcon = styled(UnstyledNotInterestedIcon)`
  color: red;
`;

const MaximoLink = styled.a`
  color: blue;
`;
const CommentIcon = styled(UnstyledCommentIcon)`
  color: rgb(15, 154, 154);
  transition: transform 0.2s;
  &:active {
    transform: scale(0.9);
  }
  &:hover {
    color: rgb(0, 100, 100);
  }
  cursor: pointer;
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '500px',
    maxHeight: '300px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    overflowY: 'scroll',
    backgroundColor: 'rgb(38, 36, 82, 0.9)',
    fontSize: '16px',
    border: '3px solid black',
  },
}));

const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const renderWOHeaderStatusIcon = ({ value }: any) => {
  return value ? (
    <CheckCircleOutlineIcon data-testid="check-circle-icon" />
  ) : (
    <NotInterestedIcon data-testid="not-interested-icon" />
  );
};

export const renderWODescription = ({ value }: any) => {
  return <CWTToolTip value={value} />;
};

export const renderMaximoLink = (id: number | undefined, wonum: string) => {
  if (!id) return wonum;
  return (
    <Tooltip title="Open in Maximo">
      <MaximoLink
        href={generateMaximoLink(String(id), 'pluswotr')}
        target="_blank"
      >
        {wonum}
      </MaximoLink>
    </Tooltip>
  );
};

export const renderMaximoTaskLink = (id: number | undefined, wonum: string) => {
  if (!id) return wonum;
  return (
    <Tooltip title="Open in Maximo">
      <MaximoLink
        href={generateMaximoLink(String(id), 'activity')}
        target="_blank"
      >
        {wonum}
      </MaximoLink>
    </Tooltip>
  );
};

export const renderMaximoPMLink = (id: number, pmnum: string) => {
  return (
    <Tooltip title="Open in Maximo">
      <MaximoLink
        href={generateMaximoLink(String(id), 'pluspm')}
        target="_blank"
      >
        {pmnum}
      </MaximoLink>
    </Tooltip>
  );
};

export const rowContextMenu = (
  menuProps: any,
  workorderId: number,
  onClick: (openTab: string) => void,
  handleSetSelectedWorkorderId: (workorderId: number | undefined) => void
) => {
  menuProps.autoDismiss = true;
  menuProps.items = [
    {
      label: 'Overview',
      onClick: getOpenDetailsFunction(onClick, 'overview', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Assessing',
      onClick: getOpenDetailsFunction(onClick, 'assessing', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Supply Chain',
      onClick: getOpenDetailsFunction(onClick, 'sc', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Engineering',
      onClick: getOpenDetailsFunction(onClick, 'eng', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Actions',
      onClick: getOpenDetailsFunction(onClick, 'actions', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Notes',
      onClick: getOpenDetailsFunction(onClick, 'notes', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Schedule',
      onClick: getOpenDetailsFunction(onClick, 'schedule', handleSetSelectedWorkorderId, workorderId),
    },
    {
      label: 'Turnover',
      onClick: getOpenDetailsFunction(onClick, 'turnover', handleSetSelectedWorkorderId, workorderId),
    },
  ];
};

export const getRowContextMenuFunction = (
  onclick: (openTab: string) => void,
  handleSetSelectedWorkorderId: (workorderId: number | undefined) => void,
  idKey = 'id'
) => {
  return (menuProps: any, { cellProps }: any) => {
    rowContextMenu(menuProps, cellProps.data[idKey], onclick, handleSetSelectedWorkorderId);
  };
};

export const getOpenDetailsFunction = (
  openFunction: (openTab: string) => void,
  openTab: string,
  handleSetSelectedWorkorderId?: (workorderId: number | undefined) => void,
  workorderId?: number
) => {
  return () => {
    if (workorderId && handleSetSelectedWorkorderId) handleSetSelectedWorkorderId(workorderId);
    openFunction(openTab);
  };
};

export const renderActions = ({ data }: any) => {
  return (
    <ActionsCell
      actionCountByStatusId={data.actionCountByStatusId}
      actions={data.actions}
    />
  );
};

export const onRenderRow = ({ data }: any) => {
  if (data.assessingReady && data.engReady && data.scReady && data.opsReady && data.otherReady) {
    return { backgroundColor: 'rgb(10, 153, 15, 0.25)' };
  }
  return;
};

export const onRowDoubleClick = (handleOpen: (tab: string) => void) => {
  return () => handleOpen('overview');
};

const Notes = ({ notes }: { notes: any[] }) => {
  return (
    <Box>
      <Grid container>
        {notes.map((note, index) => (
          <Box
            key={index}
            marginTop={'5px'}
            marginBottom={'10px'}
            bgcolor={'rgb(255,255,255, 1)'}
            borderRadius={'5px'}
            padding={'10px'}
            color={'black'}
            width={'100%'}
          >
            <Grid
              item
              xs={12}
            >
              <i>
                {note.updatedByName} - {new Date(note.updatedAt).toLocaleString()}
              </i>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {note.note}
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export const renderNotes = (notes: Note[], handleOpen: (openTab: string) => void) => {
  const classes = useStyles();
  if (notes.length === 0) {
    return <CommentIcon onClick={() => handleOpen('notes')} />;
  }
  return (
    <Tooltip
      title={<Notes notes={notes} />}
      placement="left"
      classes={{ tooltip: classes.tooltip }}
      interactive
    >
      <Box style={{ marginTop: '10px' }}>
        <Badge
          badgeContent={notes.length}
          color="primary"
          overlap="rectangular"
        >
          <CommentIcon onClick={() => handleOpen('notes')} />
        </Badge>
      </Box>
    </Tooltip>
  );
};

export const getWorkorderOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.wonum));
  return Array.from(optionsSet).map(value => ({ label: value, value }));
};

export const getUnitOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => {
    if (wo.unit) optionsSet.add(wo.unit.toString());
  });
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getLocationOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.location));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getPriorityOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.wopriority?.toString()));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getStatusOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.status));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getWorkTypeOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.worktype));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getWorkGroupOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.crewworkgroup));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getPlanningCtrOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.bpplanningctr));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getScheduleBacklogOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => optionsSet.add(wo.bpschedulebacklog));
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

export const getSpecificationsOptions = (workorders: Map<number, WorkOrder>) => {
  const optionsSet = new Set<string>();
  workorders.forEach(wo => {
    if (Array.isArray(wo.specifications)) wo.specifications.forEach(flag => optionsSet.add(flag));
  });
  return Array.from(optionsSet)
    .sort()
    .map(value => ({ label: value, value }));
};

export const getOnlineStartOptions = (objects: Map<number, any> | any[]) => {
  const optionsSet = new Set<string>();

  objects.forEach((object: any) => {
    if (object.schedstart) {
      const date = new Date(object.schedstart);
      const monday = format(startOfWeek(date, { weekStartsOn: 1 }), 'ddMMMyyyy').toUpperCase();
      const sunday = format(endOfWeek(date, { weekStartsOn: 1 }), 'ddMMMyyyy').toUpperCase();

      optionsSet.add(`${monday} to ${sunday}`);
    }
  });

  return Array.from(optionsSet)
    .sort((a, b) => new Date(a.slice(0, 9)).getTime() - new Date(b.slice(0, 9)).getTime())
    .map(value => ({ label: !value || value === '' ? 'Blank' : value, value }));
};

// Need to use this so that new Date does not return the date in local time and shift days backwards
export const formatUTCDate = (dateString: string): string | null => {
  const date = new Date(dateString);
  if (dateString) {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day}${month}${year}`;
  } else {
    return null;
  }
};
