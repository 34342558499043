import { Box, CircularProgress, Grid, Tooltip } from '@material-ui/core';
import Page from '../../../../components/Page';
import TurnoverTable from './TurnoverTable';
import DownloadButton from '../../../../components/DownloadButton';
import { useTurnover } from '../../hooks/useTurnover';
import AutocompleteInput from '../../../../components/AutocompleteInput';
import {
  getPriorityOptions,
  getUnitOptions,
  getWorkGroupOptions,
  getWorkTypeOptions,
  getWorkorderOptions,
} from '../../wo/components/utils';
import { useWorkOrders } from '../../hooks/useWorkOrders';
const Turnover = () => {
  const { exportCSV } = useTurnover();
  const { filters, setFilters, loading } = useTurnover();
  const { workorders, woloading } = useWorkOrders();
  return (
    <Page title="Turnover">
      {(loading || woloading) && <CircularProgress size={20} />}
      {!loading && !woloading && (
        <>
          <Grid
            container
            spacing={2}
            style={{ marginLeft: '10px', marginBottom: '10px' }}
          >
            <Grid
              item
              xs={3}
            >
              <AutocompleteInput
                label="Work Order"
                options={getWorkorderOptions(workorders)}
                onChange={value => {
                  setFilters({ ...filters, wonum: value });
                }}
                value={filters.wonum}
                multiple
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteInput
                label="Unit"
                options={getUnitOptions(workorders)}
                onChange={value => {
                  setFilters({ ...filters, unit: value });
                }}
                value={filters.unit}
                multiple
                selectAll
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteInput
                label="Priority"
                options={getPriorityOptions(workorders)}
                onChange={value => {
                  setFilters({ ...filters, wopriority: value });
                }}
                value={filters.wopriority}
                multiple
                selectAll
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteInput
                label="Work Type"
                options={getWorkTypeOptions(workorders)}
                onChange={value => {
                  setFilters({ ...filters, worktype: value });
                }}
                value={filters.worktype}
                multiple
                selectAll
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <AutocompleteInput
                label="Crew"
                options={getWorkGroupOptions(workorders)}
                onChange={value => {
                  setFilters({ ...filters, crewworkgroup: value });
                }}
                value={filters.crewworkgroup}
                multiple
                selectAll
              />
            </Grid>
          </Grid>
          <Box textAlign="right">
            <Tooltip
              title="Export CSV"
              placement="top"
            >
              <DownloadButton onClick={exportCSV}></DownloadButton>
            </Tooltip>
          </Box>

          <TurnoverTable />
        </>
      )}
    </Page>
  );
};
export default Turnover;
