import React, { useCallback } from 'react';
import * as R from 'ramda';
import CommentForm from './CommentForm';
import useExecutiveSummaryUi from './hooks/executive-summary-ui';

interface EditCommentProps {
  comment: string;
  saving: boolean;
}

const EditComment = ({ comment, saving }: EditCommentProps) => {
  const { onSaveComment, onFinalizeComment } = useExecutiveSummaryUi();

  const handleSave = useCallback(
    (values: any) => {
      onSaveComment(values.title, R.propOr('', 'body', values));
    },
    [onSaveComment]
  );

  const handleSubmit = useCallback(
    (values: any) => {
      onFinalizeComment(values.title, R.propOr('', 'body', values));
    },
    [onFinalizeComment]
  );

  return (
    <CommentForm
      comment={comment}
      saving={saving}
      onSave={handleSave}
      onSubmit={handleSubmit}
    />
  );
};

export default EditComment;
