import { useContext, useState, createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import API from '../../../api';
import { CreateElementParams } from '../../../types/create-element-params';
import { ElementCreator } from '../../../types/element-creator';
import { loadAllRequest } from '../../../state/elements/actions';

// Context
const elementCreatorContext = createContext<ElementCreator | null>(null);

// Provider
export const ElementCreatorProvider = ({ children }: any) => {
  const [lastCreatedId, setLastCreatedId] = useState<number | null>(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const createElement = useCallback(async (details: CreateElementParams) => {
    try {
      const newElement = await API.createElement(details);
      dispatch(loadAllRequest());
      setLastCreatedId(newElement.id);
      setError('');
    } catch (err) {
      setError('Unable to create element!');
      setLastCreatedId(null);
    }
  }, []);

  return (
    <elementCreatorContext.Provider value={{ lastCreatedId, error, createElement }}>
      {children}
    </elementCreatorContext.Provider>
  );
};

// Hook
const useElementCreator = (): ElementCreator => {
  const data = useContext(elementCreatorContext);
  if (!data) {
    throw new Error('useElementCreator must be used inside an ElementCreatorProvider');
  }
  return data;
};

export default useElementCreator;
