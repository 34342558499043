import React, { useMemo } from 'react';
import styled from 'styled-components';

const TextBox = styled.div`
  padding: 3px;
`;

const LineBox = styled.div`
  min-height: 14px;
`;

const MultiLineText = ({ description, style, lineStyle }: any) => {
  const lines = useMemo(() => {
    const showText: string = description || '';
    const lineArr = showText.split(/\n/g);
    return lineArr;
  }, [description]);

  return (
    <TextBox style={style}>
      {lines.map((ln: string) => (
        <LineBox style={lineStyle}>{ln}</LineBox>
      ))}
    </TextBox>
  );
};

export default MultiLineText;
