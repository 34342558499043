import React, { useCallback } from 'react';
import * as R from 'ramda';
import ExecutiveSummaryForm from './ExecutiveSummaryForm';
import useExecutiveSummaryUi from './hooks/executive-summary-ui';
import usePreviousReport from './hooks/usePreviousReport';

const EditExecutiveSummary = () => {
  const { report, uiState, onSaveExecutiveSummary, onFinalizeExecutiveSummary } = useExecutiveSummaryUi();

  const { previousReport, applyPreviousComments }: any = usePreviousReport(report);

  const copyPrevious = (setValue: (value: string | undefined) => void, submit: () => void) => {
    if (previousReport) {
      setValue(previousReport.executiveSummary);
      submit();
      applyPreviousComments();
    }
  };

  const handleSave = useCallback(
    (executiveSummary: any) => {
      onSaveExecutiveSummary(executiveSummary);
    },
    [onSaveExecutiveSummary]
  );

  const handleSubmit = useCallback(
    (values: { executiveSummary: any }) => {
      onFinalizeExecutiveSummary(values.executiveSummary);
    },
    [onFinalizeExecutiveSummary]
  );

  return (
    <ExecutiveSummaryForm
      initialValue={R.propOr('', 'executiveSummary', report)}
      saving={uiState === 'savingExecutiveSummary'}
      onSave={handleSave}
      onSubmit={handleSubmit}
      copyPrevious={copyPrevious}
    />
  );
};

export default EditExecutiveSummary;
