import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Action } from '../../../../../types/cwt/actions';
import { onRenderRow, renderTBE, renderTCD } from './utils';
import CWTToolTip from '../../../components/CWTToolTip';
import { useWorkOrders } from '../../../hooks/useWorkOrders';

interface Props {
  actions: Action[];
}
const ActionsTable = ({ actions }: Props) => {
  const { flags } = useWorkOrders();

  const actionColumns = [
    {
      name: 'description',
      header: 'Description',
      minWidth: 150,
      defaultFlex: 1,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
    {
      name: 'statusName',
      header: 'Status',
      width: 90,
    },
    {
      name: 'groupName',
      header: 'Group',
      width: 100,
    },
    { name: 'ownerName', header: 'Owner' },
    { name: 'tbe', header: 'TBE', width: 90, render: renderTBE },
    { name: 'tcd', header: 'TCD', width: 90, render: renderTCD },
    {
      name: 'flags',
      header: 'Flags',
      defaultWidth: 130,
      defaultFlex: 1,
      enableColumnFilterContextMenu: true,
      render: ({ value }: any) => {
        return value.map((actionFlag: any) => flags.find(flag => flag.id === actionFlag.flagId)?.label).join(', ');
      },
    },
  ];
  return (
    <>
      {actions && (
        <ReactDataGrid
          columns={actionColumns}
          dataSource={actions}
          rowStyle={onRenderRow}
          showHoverRows={false}
          reorderColumns={false}
          resizable={false}
          enableKeyboardNavigation={false}
          showColumnMenuTool={false}
        />
      )}
    </>
  );
};

export default ActionsTable;
