import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as actions from '../../../state/indicators/actions';

const useElementIndicatorWeightsCheck = elementIndicators => {
  const dispatch = useDispatch();

  const sumWeights = useMemo(
    () => R.reduce((acc, elementIndicator) => elementIndicator.weight + acc, 0, elementIndicators),
    [elementIndicators]
  );

  useEffect(() => {
    if (sumWeights > 100 || sumWeights < 100) {
      dispatch(actions.indicatorSumFailure(sumWeights));
    } else {
      dispatch(actions.indicatorSumSuccess());
    }
  }, [dispatch, sumWeights]);
};

export default useElementIndicatorWeightsCheck;
