import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export default styled(IconButton)`
  padding: 6px;
  background-color: ${({ theme }) => theme.colours.purples.purple1};
  color: ${({ theme }) => theme.colours.white};

  &:hover {
    background-color: ${({ theme }) => theme.colours.purples.purple1};
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey2};
    color: ${({ theme }) => theme.colours.white};
    border: ${({ theme }) => `1px solid ${theme.colours.greys.lightGrey1}`};
  }
`;
