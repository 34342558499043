import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 82px auto;
  grid-template-columns: auto;
`;

const PageBody = styled.div`
  padding: 0px;
  margin: 0px;
`;

const DefaultLayout = ({ children }) => (
  <Container>
    <PageBody>{children}</PageBody>
  </Container>
);

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
