import { AutocompleteOption } from '../../../components/AutocompleteInput';
import { WorkRequestCodingValues } from './types';

export const CATEGORY_LABELS: Record<keyof WorkRequestCodingValues, string> = {
  btu: 'BTU',
  caaNumber: 'CAA Number',
  crew: 'Crew',
  outageCode: 'Outage Code',
  planningCentre: 'Planning Centre',
  planningGroup: 'Planning Group',
  priority: 'Priority',
  projectNumber: 'Project Number',
  scheduleBacklog: 'Schedule Backlog',
  spm: 'SPM',
  ucr: 'UCR',
  woAcknowledge: 'WO Acknowledge',
  workCategory: 'Work Category',
  workType: 'Work Type',
};

export const CATEGORY_OPTIONS: Array<AutocompleteOption<keyof WorkRequestCodingValues>> = [
  { label: CATEGORY_LABELS.btu, value: 'btu' },
  { label: CATEGORY_LABELS.caaNumber, value: 'caaNumber' },
  { label: CATEGORY_LABELS.crew, value: 'crew' },
  { label: CATEGORY_LABELS.outageCode, value: 'outageCode' },
  { label: CATEGORY_LABELS.planningCentre, value: 'planningCentre' },
  { label: CATEGORY_LABELS.planningGroup, value: 'planningGroup' },
  { label: CATEGORY_LABELS.priority, value: 'priority' },
  { label: CATEGORY_LABELS.projectNumber, value: 'projectNumber' },
  { label: CATEGORY_LABELS.scheduleBacklog, value: 'scheduleBacklog' },
  { label: CATEGORY_LABELS.spm, value: 'spm' },
  { label: CATEGORY_LABELS.ucr, value: 'ucr' },
  { label: CATEGORY_LABELS.woAcknowledge, value: 'woAcknowledge' },
  { label: CATEGORY_LABELS.workCategory, value: 'workCategory' },
  { label: CATEGORY_LABELS.workType, value: 'workType' },
];
