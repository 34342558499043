import { useState, useCallback } from 'react';

const useSelectedItem = <T>(items: T[], compareKey: Exclude<keyof T, symbol>) => {
  const [selectedItem, setSelectedItem] = useState<T | null>(null);

  const selectItem = useCallback(
    (compareValue: string) => {
      const foundItem = items.find((item: T) => {
        const compareItem = item[compareKey];
        // should be comparing numbers but is being passed as a string.  When type ElementId is refactored
        // to be a number only this if statement can be removed.  As it is the selected value is a string
        if (typeof compareItem === 'string' || typeof compareValue === 'string') {
          return `${compareItem}` === `${compareValue}`;
        }
        return compareItem === compareValue;
      });
      setSelectedItem(foundItem ?? null);
    },
    [items, compareKey]
  );

  const clearSelectedItem = useCallback(() => {
    setSelectedItem(null);
  }, [setSelectedItem]);

  return {
    selectedItem,
    selectItem,
    clearSelectedItem,
  };
};

export default useSelectedItem;
