import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import UnstyledIconButton from '@material-ui/core/IconButton';
import UnstyledCheckIcon from '@material-ui/icons/Check';
import UnstyledClearIcon from '@material-ui/icons/Clear';
import Table from '../../../../../../components/Table';
import Link from '../../../../../../components/Link';
import { dateToString } from '../../../../../../utils/date-format';
import useDownloadReport from '../../../../../../hooks/download-report';
import DownloadButton from '../../../../../../components/DownloadButton';

const IconButton = styled(UnstyledIconButton)``;

const CheckIcon = styled(UnstyledCheckIcon)`
  color: ${({ theme }) => theme.colours.greens.green2};
`;

const ClearIcon = styled(UnstyledClearIcon)`
  color: ${({ theme }) => theme.colours.reds.red3};
`;

const Approval = ({ onCheck, onClear }: any) => (
  <Grid
    container
    justifyContent="center"
    spacing={1}
  >
    <Grid item>
      <IconButton
        size="small"
        onClick={onCheck}
      >
        <CheckIcon />
      </IconButton>
    </Grid>
    <Grid item>
      <IconButton
        size="small"
        onClick={onClear}
      >
        <ClearIcon />
      </IconButton>
    </Grid>
  </Grid>
);

const ReportTable = ({ className, reports, onAccept, onReject }: any) => {
  const { downloadReport } = useDownloadReport();
  const rows = reports.map((report: any) => (
    <TableRow
      key={report.id}
      className={className}
    >
      <TableCell>{`${report.elementName} (${report.facilityName})`}</TableCell>
      <TableCell align="center">{`Q${report.quarter} ${report.year}`}</TableCell>
      <TableCell align="center">{report.stateChangedByName}</TableCell>
      <TableCell align="center">{dateToString(report.stateChangedAt)}</TableCell>
      <TableCell
        align="center"
        padding="none"
      >
        <Approval
          onCheck={R.thunkify(onAccept)(report.id)}
          onClear={R.thunkify(onReject)(report.id)}
        />
      </TableCell>
      <TableCell align="center">
        {report.uploadBlobName && <DownloadButton onClick={R.thunkify(downloadReport)(report)}></DownloadButton>}
      </TableCell>
      <TableCell align="center">
        <Link
          to={`/app/approvals/report-review/${report.id}`}
          label="Open"
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>System Name</TableCell>
            <TableCell align="center">Quarter</TableCell>
            <TableCell align="center">Employee</TableCell>
            <TableCell align="center">Date Submitted</TableCell>
            <TableCell align="center">Approval</TableCell>
            <TableCell align="center">PDF</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
