import * as actionTypes from '../../../state/action-types';
import errorAction from '../../../state/utils/error-action';

export const loadRecordsRequest = indicatorId => ({
  type: actionTypes.DASHBOARD_LOAD_RECORDS_REQUEST,
  payload: {
    indicatorId,
  },
});

export const loadRecordsSuccess = (indicatorId, records) => ({
  type: actionTypes.DASHBOARD_LOAD_RECORDS_SUCCESS,
  payload: {
    indicatorId,
    records,
  },
});

export const loadRecordsFailure = errorAction(actionTypes.DASHBOARD_LOAD_RECORDS_FAILURE);

export const updateIndicatorRecordRequest = (indicatorId, recordId, applicable, options) => ({
  type: actionTypes.DASHBOARD_UPDATE_INDICATOR_RECORD_REQUEST,
  payload: {
    indicatorId,
    recordId,
    applicable,
    options,
  },
});

export const indicatorRecordApplied = () => ({
  type: actionTypes.DASHBOARD_INDICATOR_RECORD_APPLIED,
  payload: {
    message: 'Record applied',
  },
});

export const indicatorRecordRejected = () => ({
  type: actionTypes.DASHBOARD_INDICATOR_RECORD_REJECTED,
  payload: {
    message: 'Record marked inapplicable',
  },
});

export const updateIndicatorRecordFailure = errorAction(actionTypes.DASHBOARD_UPDATE_INDICATOR_RECORD_FAILURE);
