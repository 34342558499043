import MCRPatchParams from '../types/mcr-patch-params';
import { Path, Remote } from './types';

const getMCRUnits = (path: Path, remote: Remote) => () => remote.get(path('/'));

const patchMCRUnit = (path: Path, remote: Remote) => (updates: MCRPatchParams[]) =>
  remote.patch(path('/'), { updates });

export default (path: Path, remote: Remote) => ({
  getMCRUnits: getMCRUnits(path, remote),
  patchMCRUnit: patchMCRUnit(path, remote),
});
