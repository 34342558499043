import styled from 'styled-components';

import MUITypography from '@material-ui/core/Typography';

const TaskTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.colours.blues.blue6}
  opacity: 0.8;
  font: ${({ theme }) => theme.fonts.bold14};
`;

export default TaskTitle;
