import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const updateIndicatorSuccess = () => ({
  type: actionTypes.PROGRAM_INDICATOR_UPDATE_SUCCESS,
  payload: {
    message: 'Program Indicator Updated',
  },
});

export const updateIndicatorFailure = errorAction(actionTypes.PROGRAM_INDICATOR_UPDATE_FAILURE);
