import React from 'react';
import Grid, { GridItemsAlignment } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface Props {
  children: any;
  title?: string;
  alignItems?: GridItemsAlignment;
}

const Field = ({ children, title, alignItems = 'flex-start' }: Props) => (
  <Grid
    item
    xs={12}
    container
    alignItems={alignItems}
  >
    <Grid
      item
      xs={3}
    >
      <Typography>{title}:</Typography>
    </Grid>
    <Grid
      item
      xs={9}
    >
      {children}
    </Grid>
  </Grid>
);

export default Field;
