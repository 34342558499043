import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const InvalidIndicatorDetails = ({ className }: { className?: string }) => (
  <Container className={className}>
    <p>Missing indicator specifics for this record.</p>
  </Container>
);

export default InvalidIndicatorDetails;
