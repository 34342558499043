const getDashboards = (path, remote) => () => remote.get(path('/'));
const addDashboard = (path, remote) => dashboard => remote.post(path('/'), { dashboard });
const removeDashboard = (path, remote) => dashboardId => remote.del(path(`/${dashboardId}`));
const updateDashboard = (path, remote) => dashboard => remote.put(path(`/${dashboard.id}`), { dashboard });

export default (path, remote) => ({
  getDashboards: getDashboards(path, remote),
  addDashboard: addDashboard(path, remote),
  removeDashboard: removeDashboard(path, remote),
  updateDashboard: updateDashboard(path, remote),
});
