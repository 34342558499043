import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Input } from '@material-ui/core';
import UnstyledColourBox from '../../../../../components/ColourBox';
import { ProgramCategoryIndicatorColourThreshold } from '../../../../../hooks/program-category-indicator';

const ColourBox = styled(UnstyledColourBox)`
  width: 100%;
`;

const TextField = styled(Input)``;

interface Props {
  colour: string;
  value: ProgramCategoryIndicatorColourThreshold;
  onChange(key: string, value: string): void;
}

const ColourThreshold = ({ colour, value, onChange }: Props) => {
  const [iValue, setValue] = useState(value.value);
  const [iDescription, setDescription] = useState(value.description);

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      alignItems="center"
      style={{ marginBottom: '5px' }}
    >
      <Grid
        container
        item
        xs={2}
        alignItems="center"
      >
        <ColourBox colour={colour.toLowerCase()}>{iValue || colour}</ColourBox>
      </Grid>
      <Grid
        container
        item
        xs={2}
        alignItems="center"
      >
        <TextField
          type="text"
          placeholder={colour}
          fullWidth
          value={iValue}
          onChange={e => {
            setValue(e.target.value);
            onChange('value', e.target.value);
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={8}
        alignItems="center"
      >
        <TextField
          type="text"
          placeholder="Add Description"
          fullWidth
          value={iDescription}
          onChange={e => {
            setDescription(e.target.value);
            onChange('description', e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ColourThreshold;
