import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as customIndicatorActions from './actions';

function* loadCustomIndicators(action) {
  try {
    const { elementId } = action.payload;
    const customIndicators = yield call(Api.getCustomIndicators, elementId);
    yield put(customIndicatorActions.loadSuccess(elementId, customIndicators));
  } catch (e) {
    yield put(customIndicatorActions.loadFailure(e));
  }
}

function* createCustomIndicator(action) {
  try {
    const { customIndicator } = action.payload;
    const customIndicatorId = yield call(
      Api.createCustomIndicator,
      customIndicator.elementId,
      customIndicator.name,
      customIndicator.description
    );
    yield put(customIndicatorActions.createSuccess({ id: customIndicatorId, ...customIndicator }));
  } catch (e) {
    yield put(customIndicatorActions.createFailure(e));
  }
}

function* addCustomIndicator(action) {
  try {
    const { elementId, name, description } = action.payload;
    const customIndicatorId = yield call(Api.createCustomIndicator, elementId, name, description);
    yield put(customIndicatorActions.addSuccess(customIndicatorId, elementId, name, description));
  } catch (e) {
    yield put(customIndicatorActions.addFailure(e));
  }
}

function* updateCustomIndicator(action) {
  try {
    const { elementId, customIndicatorId, name, description } = action.payload;
    yield call(Api.updateCustomIndicator, elementId, customIndicatorId, name, description);
    yield put(customIndicatorActions.updateSuccess(elementId, customIndicatorId, name, description));
  } catch (e) {
    yield put(customIndicatorActions.updateFailure(e));
  }
}

function* deleteCustomIndicator(action) {
  try {
    const { elementId, customIndicatorId } = action.payload;
    yield call(Api.deleteCustomIndicator, elementId, customIndicatorId);
    yield put(customIndicatorActions.deleteSuccess(elementId, customIndicatorId));
  } catch (e) {
    yield put(customIndicatorActions.deleteFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.CUSTOM_INDICATORS_LOAD_REQUEST, loadCustomIndicators);
  yield takeEvery(actionTypes.CUSTOM_INDICATORS_CREATE_REQUEST, createCustomIndicator);
  yield takeEvery(actionTypes.CUSTOM_INDICATORS_ADD_REQUEST, addCustomIndicator);
  yield takeEvery(actionTypes.CUSTOM_INDICATORS_UPDATE_REQUEST, updateCustomIndicator);
  yield takeEvery(actionTypes.CUSTOM_INDICATORS_DELETE_REQUEST, deleteCustomIndicator);
}

export default root;
