import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import UnstyledFilterButton from '../../../components/FilterButton';
import { useDashboardPage } from '../hooks/useDashboardPage';

const FilterButton = styled(UnstyledFilterButton)`
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface Props {
  activeView: string;
  onShowFunctionalFailures(): void;
  onShowOutstandingCorrectiveActions(): void;
}

const IndicatorSelector = ({ activeView, onShowFunctionalFailures, onShowOutstandingCorrectiveActions }: Props) => {
  const { loadingIndicators } = useDashboardPage();
  return (
    <Grid
      container
      alignItems="center"
    >
      <Grid item>
        <FilterButton
          onClick={onShowFunctionalFailures}
          isActive={activeView === 'functional-failures'}
        >
          Functional Failures
        </FilterButton>
        <FilterButton
          onClick={onShowOutstandingCorrectiveActions}
          isActive={activeView === 'outstanding-corrective-actions'}
        >
          Outstanding Functional Failure Corrective Actions
        </FilterButton>
      </Grid>
      {loadingIndicators && (
        <Grid item>
          <CircularProgress size={18} />
        </Grid>
      )}
    </Grid>
  );
};

export default IndicatorSelector;
