import React from 'react';
import useSingleWalkdown, { SingleWalkdownProvider } from '../hooks/useSingleWalkdown';
import SingleWalkdownInternal from './SingleWalkdownInternal';
import Conditional from '../../../components/Conditional';

const SingleWalkdown = () => {
  const { isLoading, walkdown, update } = useSingleWalkdown();
  return (
    <Conditional condition={!isLoading}>
      {walkdown && walkdown.state !== 'Approved' && (
        <SingleWalkdownInternal
          walkdown={walkdown as any}
          update={update}
        />
      )}
      {walkdown && walkdown.state === 'Approved' && (
        <SingleWalkdownInternal
          walkdown={walkdown as any}
          update={update}
          hideBanner
          readOnly
        />
      )}
    </Conditional>
  );
};

export default ({ match }: any) => (
  <SingleWalkdownProvider walkdownId={match.params.walkdownId}>
    <SingleWalkdown />
  </SingleWalkdownProvider>
);
