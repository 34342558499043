import React from 'react';
import styled from 'styled-components';
import echoLogo from '../../assets/ECHO_logo.svg';

const PagesIcon = styled.img.attrs({
  src: echoLogo,
})``;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0;

  ${PagesIcon} {
    margin-right: 5px;
  }

  h1 {
    margin: 0;
    font: ${({ theme }) => theme.fonts.normal22};
    color: ${({ theme }) => theme.colours.greys.lightGrey7};
    letter-spacing: 0;
    text-align: center;
  }
`;

const EchoLogo = props => (
  <Container {...props}>
    <PagesIcon />
  </Container>
);

export default EchoLogo;
