import React from 'react';
import styled from 'styled-components';
import UnstyledDashboardTileStar from './DashboardTileStar';
import UnstyledDashboardDetails from './DashboardDetails';
import UnstyledOpenDashboard from './OpenDashboard';

const DashboardTitle = styled.div``;

const DashboardDetails = styled(UnstyledDashboardDetails)``;

const DashboardTileStar = styled(UnstyledDashboardTileStar)``;

const OpenDashboard = styled(UnstyledOpenDashboard)``;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${DashboardTitle} {
    margin-left: 15px;
  }

  ${DashboardTileStar} {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;

const Container = styled.div`
  width: 323.33px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.greys.lightGrey7};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow3};

  ${HeaderContainer} {
    margin: 20px;
  }

  ${DashboardTitle} {
    font: ${({ theme }) => theme.fonts.bold16};
  }

  ${DashboardDetails} {
    margin: 15px 25px 0px 25px;
  }

  ${OpenDashboard} {
    margin: 15px 25px 15px 25px;
  }
`;

const DashboardTile = ({ className, onToggleFavourite, dashboard }) => (
  <Container className={className}>
    <HeaderContainer>
      <DashboardTitle>{dashboard.title || 'No Title Found'}</DashboardTitle>
      <DashboardTileStar
        favourite={dashboard.favourite}
        onToggleFavourite={onToggleFavourite}
      />
    </HeaderContainer>
    <DashboardDetails details={dashboard.description} />
    <OpenDashboard dashboard={dashboard} />
  </Container>
);

export default DashboardTile;
