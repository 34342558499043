import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import moment from 'moment';
import useUpdateMCRExemption from './hooks/useUpdateMCRExemption';

const Typography = styled(UnstyledTypography)`
  font: ${({ theme }) => theme.fonts.medium16};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 3px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 14px;
`;

const ExemptElement = (exemptElement: any) => {
  const { element } = exemptElement;
  const { setMCRExemption } = useUpdateMCRExemption();
  const elementName = `(${element.facilityName}) ${element.name}`;
  const dateInformation = () => {
    if (element.mcrExemptionStartDate && element.mcrExemptionEndDate) {
      return `: Valid from ${moment(element.mcrExemptionStartDate).format('YYYY-MM-DD')} to ${moment(
        element.mcrExemptionEndDate
      ).format('YYYY-MM-DD')}`;
    }
    if (element.mcrExemptionEndDate) {
      return `: Valid until ${moment(element.mcrExemptionEndDate).format('YYYY-MM-DD')}`;
    }
    if (element.mcrExemptionStartDate) {
      return `: Valid from ${moment(element.mcrExemptionStartDate).format('YYYY-MM-DD')}`;
    }
    return '';
  };
  return (
    <Container>
      <Typography>
        {elementName} {dateInformation()}
      </Typography>
      <IconButton
        size="small"
        onClick={() => setMCRExemption(element.id, false)}
      >
        <DeleteIcon />
      </IconButton>
    </Container>
  );
};

export default ExemptElement;
