/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import useForm from '../../hooks/form';
import useConditionalConfirm from '../../hooks/conditional-confirm';
import FormDialog from '../FormDialog';
import OkayCancelDialog from '../OkayCancelDialog';
import ColourSelect from '../ColourSelect';
import CustomTextField from '../CustomTextField';
import ActionButton from '../ActionButton';
import { TotalValueState } from '../../hooks/edit-manual-total-value';

interface Props {
  title?: string;
  overrideValue: TotalValueState;
  readonly?: boolean;
  onSave: (value: number, colour?: string, comment?: string) => void;
  onCancel: () => void;
  onClear?: () => void;
}

const formConfig = {
  value: {
    validate: {
      numericality: {
        greaterThanOrEqualTo: 0,
        notGreaterThanOrEqualTo: 'Total Override Value must be greater than or equal to 0',
        lessThanOrEqualTo: 100,
        notLessThanOrEqualTo: 'Total Override Value must be less than or equal to 100',
      },
      presence: {
        allowEmpty: true,
        message: 'Enter a number',
      },
    },
  },
  colour: {
    validate: {
      presence: {
        allowEmpty: true,
        message: 'Select a colour',
      },
    },
  },
  comment: {
    validate: {
      presence: {
        allowEmpty: true,
        message: 'Enter a comment',
      },
    },
  },
};

const initialValues = (overrideValue: TotalValueState) => ({
  value: overrideValue.value.toFixed(2),
  colour: overrideValue.colour,
  comment: overrideValue.comment || '',
});

// TODO: Consider merging Manual Value Dialog and Total Value Dialog

const TotalValueOverrideDialog = ({
  title = 'Total Value Override',
  overrideValue,
  readonly,
  onSave,
  onCancel,
  onClear,
}: Props) => {
  const handleSave = ({ value, colour, comment }: TotalValueState) => {
    onSave(value, colour, comment);
  };

  const { values, submit, isDirty, propsForField } = useForm(formConfig, initialValues(overrideValue), handleSave);
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  const canSave = !R.isNil(values.colour);

  return (
    <FormDialog
      title={title}
      hideSave={!canSave || readonly}
      onSave={submit}
      onCancel={confirmCancel}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <CustomTextField
            label="Value"
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 100 }}
            {...propsForField('value')}
            disabled={readonly}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ColourSelect
            label="Colour"
            fullWidth
            {...propsForField('colour')}
            disabled={readonly}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <CustomTextField
            label="Comment"
            fullWidth
            inputProps={{ maxLength: 255 }}
            multiline
            {...propsForField('comment')}
            disabled={readonly}
          />
        </Grid>
        {R.isNil(readonly) && (
          <Grid
            item
            xs={12}
          >
            <ActionButton
              onClick={onClear}
              color="secondary"
            >
              Clear Override
            </ActionButton>
          </Grid>
        )}
      </Grid>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          Are you sure? If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default TotalValueOverrideDialog;
