import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page from '../../../components/Page';
import { Downloader } from '../../../hooks/downloader';
import { WalkdownsProvider } from '../../../hooks/walkdowns';
import { WalkdownReviewProvider } from './hooks/useWalkdownReview';
import SingleWalkdownReview from './SingleWalkdownReview';
import WalkdownReviewInternal from './WalkdownReviewPage';

const WalkdownReviewPage = ({ match }: any) => (
  <Page title="Walkdown review">
    <Downloader>
      <WalkdownReviewProvider>
        <Switch>
          <Route path={`${match.path}/:walkdownId`}>
            <Downloader>
              <WalkdownsProvider>
                <SingleWalkdownReview />
              </WalkdownsProvider>
            </Downloader>
          </Route>
          <Route
            path={`${match.path}/`}
            component={WalkdownReviewInternal}
          />
        </Switch>
      </WalkdownReviewProvider>
    </Downloader>
  </Page>
);

export default WalkdownReviewPage;
