import * as actionTypes from '../action-types';

const initialState = {
  facilities: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADMIN_LOAD_FACILITIES_SUCCESS: {
      const { facilities } = payload;
      return {
        ...state,
        facilities,
      };
    }
    default:
      return state;
  }
};
