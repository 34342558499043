import { Tabs as TabsBase } from '@material-ui/core';

export interface TabsProps<T> extends React.PropsWithChildren {
  value: T;
  onChange: (event: React.ChangeEvent<Record<string, never>>, value: T) => void;
}

const Tabs = <T,>({ children, onChange, value }: TabsProps<T>) => {
  return (
    <TabsBase
      value={value}
      onChange={onChange}
      TabIndicatorProps={{ style: { display: 'none' } }}
      style={{ minHeight: '29px' }}
    >
      {children}
    </TabsBase>
  );
};

export default Tabs;
