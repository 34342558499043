import { useEffect, useState } from 'react';
import Api from '../../../../api';
import { WorkRequest } from '../../../../types/cwt/workrequest';

export const useWorkRequest = (workRequestNumber: string) => {
  const [workRequest, setWorkRequest] = useState<WorkRequest | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchWorkRequest = async () => {
      try {
        const response = await Api.cwt.getWorkRequest(workRequestNumber);
        setWorkRequest(response);
      } catch (err) {
        console.error('Error fetching work request', err);
      } finally {
        setLoading(false);
      }
    };
    if (workRequestNumber) {
      void fetchWorkRequest();
    }
  }, [workRequestNumber]);

  return { workRequest, loading };
};
