import { Path, Remote } from '../../types';

const getAllUsers = (path: Path, remote: Remote) => () => remote.get(path(`/`));
const updateFacility = (path: Path, remote: Remote) => (facilityId: string | null) =>
  remote.patch(path(`/`), { facilityId });
const user = (path: Path, remote: Remote) => ({
  getAllUsers: getAllUsers(path, remote),
  updateFacility: updateFacility(path, remote),
});

export default user;
