import { useState, useCallback, useEffect, useContext, createContext } from 'react';
import * as R from 'ramda';
import api from '../../../../../api';

const UpdateMCRExemptionContext = createContext(null as any);

const useUpdateMCRExemption = () => {
  const context = useContext(UpdateMCRExemptionContext);
  if (R.isNil(context)) {
    throw new Error('useUpdateMCRExemption must be used inside an UpdateMCRExemptionProvider');
  }
  return context;
};

export const UpdateMCRExemptionProvider = ({ children }: any) => {
  const [elements, setElements] = useState<Array<any>>([]);
  const setMCRExemption = useCallback(
    async (elementId: any, exemption: boolean, mcrExemptionStartDate = null, mcrExemptionEndDate = null) => {
      await api.updateMcrExemption(elementId, exemption, mcrExemptionStartDate, mcrExemptionEndDate);

      const response = await api.getAllElements();

      setElements([...response]);
    },
    [elements]
  );

  useEffect(() => {
    api.getAllElements().then((response: Array<any>) => {
      setElements(response);
    });
  }, []);

  const value = {
    setMCRExemption,
    elements,
  };
  return <UpdateMCRExemptionContext.Provider value={value}>{children}</UpdateMCRExemptionContext.Provider>;
};

export default useUpdateMCRExemption;
