import { useCallback } from 'react';
import useConfirm from './confirm';

const useConditionalConfirm = (onConfirmFn: any, confirmationRequired?: any) => {
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onConfirmFn);

  const onConditionalConfirm = useCallback(
    (...args: any) => {
      if (confirmationRequired) {
        confirm(...args);
      } else {
        onConfirmFn(...args);
      }
    },
    [confirmationRequired, onConfirmFn, confirm]
  );

  return {
    confirming,
    confirm: onConditionalConfirm,
    onConfirm,
    onCancel,
  };
};

export default useConditionalConfirm;
