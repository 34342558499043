import * as R from 'ramda';
import { CSSProperties } from 'react';
import { TabState } from '../../constants';
import PowerBiReport from '../../../../components/PowerBiReport';

interface ForcedOutageReportProps {
  tab: TabState;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

const ForcedOutageReport: React.FC<ForcedOutageReportProps> = ({ tab, width, height }) => {
  const reportTitle = 'Forced Outages PowerBI Report';
  const reportId = R.pathOr('', ['echoConfig', 'forcedOutagesPowerBiReportId'], window);
  const overviewPageName = R.pathOr('', ['echoConfig', 'forcedOutagesOverviewPageName'], window);
  const detailsPageName = R.pathOr('', ['echoConfig', 'forcedOutagesDetailsPageName'], window);
  const managementPageName = R.pathOr('', ['echoConfig', 'forcedOutagesManagementPageName'], window);

  const pageNames: Record<TabState, string> = {
    overview: overviewPageName,
    details: detailsPageName,
    management: managementPageName,
  };

  return (
    <PowerBiReport
      title={reportTitle}
      reportId={reportId}
      pageName={pageNames[tab]}
      width={width}
      height={height}
    />
  );
};

export default ForcedOutageReport;
