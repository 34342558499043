import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api';

// Context
const PreviousScoreContext = createContext<any>(null);

// Hook
const usePreviousScore = () => {
  const data = useContext(PreviousScoreContext);
  if (!data) {
    throw new Error('usePreviousScore must be used inside an PreviousScoreProvider');
  }
  return data;
};

export default usePreviousScore;

// Provider
export const PreviousScoreProvider = ({ children, indicatorId, elementUnit, elementId, year, quarter }: any) => {
  const [previousScoreData, setPreviousScoreData] = useState(null);
  useEffect(() => {
    api.getIndicatorScore(indicatorId, elementUnit, elementId, year, quarter).then((response: any) => {
      setPreviousScoreData(response);
    });
  }, [indicatorId, elementId]);
  return <PreviousScoreContext.Provider value={{ previousScoreData }}>{children}</PreviousScoreContext.Provider>;
};
