import React from 'react';
import TextSections from './TextSections';

const ExecutiveSummaryContainer = ({ className, report }) => (
  <TextSections
    className={className}
    sections={[
      {
        title: 'Executive Summary',
        body: report.executiveSummary,
      },
    ]}
  />
);

export default ExecutiveSummaryContainer;
