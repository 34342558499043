import { AppBar, Box, Tab, Typography, makeStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import styled from 'styled-components';
import ActionsTab from './ActionsTab';
import { getHandleTabChangeFunction } from './utils';
import { ActionsContextProvider } from '../../hooks/useActions';
import { ScheduleContextProvider } from '../../hooks/useSchedule';
import { TurnoverContextProvider } from '../../hooks/useTurnover';
import { NotesContextProvider } from '../../hooks/useNotes';
import TurnoverTab from './TurnoverTab';
import ScheduleTab from './ScheduleTab';
import NotesTab from './NotesTab';
import { SupplyChainContextProvider } from '../../hooks/useSupplyChain';
import SupplyChainTab from './SupplyChainTab';
import { EngineeringContextProvider } from '../../hooks/useEngineering';
import EngineeringTab from './EngineeringTab';
import OverviewTab from './OverviewTab';
import { OverviewContextProvider } from '../../hooks/useOverview';
import AssessingTab from './AssessingTab';
import { WorkorderTasksContextProvider } from '../../hooks/useWorkorderTasks';
import isFeatureEnabled from '../../../../utils/feature-flags';

const StyledTab = styled(AppBar)`
  background-color: rgb(38, 36, 82, 0.95);
  max-width: 100%;
`;

const BoldTypography = styled(Typography)`
  font-weight: bold;
`;

const useStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: 'rgb(15, 154, 154);',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap', // This allows the tabs to wrap onto multiple lines
  },
}));

const DetailTabs = () => {
  const classes = useStyles();

  const { activeTab, handleSetActiveTab, workorder } = useWODetailsModal();
  return (
    <TabContext value={activeTab.toString()}>
      <StyledTab position="static">
        <TabList
          onChange={getHandleTabChangeFunction(handleSetActiveTab)}
          classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer }}
        >
          <Tab
            id="overview-tab"
            label="Overview"
            value="overview"
          />
          <Tab
            id="assessing-tab"
            label="Assessing"
            value="assessing"
          />
          <Tab
            label="Supply Chain"
            value="sc"
          />
          <Tab
            label="Engineering"
            value="eng"
          />
          <Tab
            label="Actions"
            value="actions"
          />
          <Tab
            label="Notes"
            value="notes"
          />
          <Tab
            label="Schedule"
            value="schedule"
          />
          <Tab
            label="Turnover"
            value="turnover"
          ></Tab>
        </TabList>
      </StyledTab>
      {workorder && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            p={1}
            borderBottom={1}
          >
            <Box flex={1}>
              <BoldTypography>WONUM:</BoldTypography>
              {workorder.wonum}
            </Box>
            <Box flex={1}>
              <BoldTypography>Priority:</BoldTypography>
              {workorder.wopriority}
            </Box>
            <Box flex={1}>
              <BoldTypography>Type:</BoldTypography>
              {workorder.worktype}
            </Box>
            <Box flex={1}>
              <BoldTypography>Status:</BoldTypography>
              {workorder.status}
            </Box>
            <Box flex={2}>
              <BoldTypography>Description:</BoldTypography>
              {workorder.description}
            </Box>
            <Box flex={1}>
              <BoldTypography>Location:</BoldTypography>
              {workorder.location}
            </Box>
            <Box flex={1}>
              <BoldTypography>Crew:</BoldTypography>
              {workorder.crewworkgroup}
            </Box>
          </Box>
          <ActionsContextProvider>
            <TabPanel value={'overview'}>
              <SupplyChainContextProvider>
                <EngineeringContextProvider>
                  <OverviewContextProvider>
                    <OverviewTab wo={workorder} />
                  </OverviewContextProvider>
                </EngineeringContextProvider>
              </SupplyChainContextProvider>
            </TabPanel>
            <TabPanel value={'assessing'}>
              {isFeatureEnabled('CWT_Assessing') && (
                <WorkorderTasksContextProvider wonum={workorder.wonum}>
                  <AssessingTab />
                </WorkorderTasksContextProvider>
              )}
            </TabPanel>
            <TabPanel value={'sc'}>
              <SupplyChainContextProvider>
                <SupplyChainTab wo={workorder} />
              </SupplyChainContextProvider>
            </TabPanel>
            <TabPanel value={'eng'}>
              <EngineeringContextProvider>
                <EngineeringTab />
              </EngineeringContextProvider>
            </TabPanel>
            <TabPanel value={'actions'}>
              <ActionsTab />
            </TabPanel>
            <TabPanel value={'notes'}>
              <NotesContextProvider>
                <NotesTab />
              </NotesContextProvider>
            </TabPanel>
            <TabPanel value={'schedule'}>
              <ScheduleContextProvider>
                <TurnoverContextProvider>
                  <ScheduleTab />
                </TurnoverContextProvider>
              </ScheduleContextProvider>
            </TabPanel>
            <TabPanel value={'turnover'}>
              <TurnoverContextProvider>
                <TurnoverTab />
              </TurnoverContextProvider>
            </TabPanel>
          </ActionsContextProvider>
        </>
      )}
    </TabContext>
  );
};

export default DetailTabs;
