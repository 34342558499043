import React from 'react';
import useWalkdownTemplate from '../hooks/useWalkdownTemplate';
import Conditional from '../../../components/Conditional';
import SingleTemplateInternal from './SingleTemplateInternal';

const SingleTemplate = () => {
  const { template } = useWalkdownTemplate();

  // #ToDo Depricate usewalkdowns no longer selects element
  // const { selectedElement, setSelectedElement } = useWalkdowns();

  // useEffect(() => {
  //   if (template && !selectedElement) {
  //     setSelectedElement(template.elementId);
  //   }
  // }, [selectedElement, template]);

  return (
    <Conditional condition={template}>
      <SingleTemplateInternal />
    </Conditional>
  );
};

export default SingleTemplate;
