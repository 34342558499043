import * as actionTypes from '../action-types';

export const reset = () => ({
  type: actionTypes.EXECUTIVE_SUMMARY_UI_RESET,
});

export const editExecutiveSummary = () => ({
  type: actionTypes.EXECUTIVE_SUMMARY_UI_EDIT_EXECUTIVE_SUMMARY,
});

export const editComment = () => ({
  type: actionTypes.EXECUTIVE_SUMMARY_UI_EDIT_COMMENT,
});
