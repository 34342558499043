import { renderMaximoLink, renderWODescription } from '../../wo/components/utils';

export const WOColumns = [
  {
    name: 'wonum',
    header: 'WO',
    defaultWidth: 80,
    render: ({ data }: any) => renderMaximoLink(data.id, data.wonum),
  },
  {
    name: 'wopriority',
    header: 'Prior',
    type: 'number',
    defaultWidth: 65,
    enableColumnFilterContextMenu: false,
    headerEllipsis: false,
  },
  {
    name: 'worktype',
    header: 'Type',
    defaultWidth: 65,
    headerEllipsis: false,
    enableColumnFilterContextMenu: false,
  },
  {
    name: 'status',
    header: 'Status',
    defaultWidth: 90,
    enableColumnFilterContextMenu: false,
  },
  { name: 'location', header: 'Location', defaultWidth: 100 },
  {
    name: 'description',
    header: 'Description',
    minWidth: 140,
    defaultFlex: 1,
    render: renderWODescription,
  },
  { name: 'crewworkgroup', header: 'Crew', defaultWidth: 80 },
  {
    name: 'bpspm',
    header: 'SPM',
    type: 'number',
    headerEllipsis: false,
    defaultWidth: 60,
  },
  {
    name: 'totallife',
    header: 'Life',
    type: 'number',
    headerEllipsis: false,
    defaultWidth: 65,
  },
];
