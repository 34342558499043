import ActionButton from '../../../../components/ActionButton';
import OverlayPaper from '../../../../components/OverlayPaper';

interface Props {
  className?: string;
  children: React.ReactNode;
  title: string;
  statusText?: string;
  isDoneDisabled?: boolean;
  onDone?(): void;
}

const HealthReportsOverlayPaper = ({
  className,
  children,
  title,
  statusText,
  isDoneDisabled = true,
  onDone,
}: Props) => {
  return (
    <OverlayPaper
      action={
        <ActionButton
          disabled={isDoneDisabled}
          color="secondary"
          onClick={onDone}
        >
          Next
        </ActionButton>
      }
      className={className}
      statusText={statusText}
      title={title}
    >
      {children}
    </OverlayPaper>
  );
};

export default HealthReportsOverlayPaper;
