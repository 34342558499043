import React from 'react';
import styled from 'styled-components';

import StarRoundedIcon from '@material-ui/icons/StarRounded';

const StarIconDefault = styled(StarRoundedIcon)`
  color: ${({ theme }) => theme.colours.black};
`;

const StarIconYellow = styled(StarRoundedIcon)`
  color: ${({ theme }) => theme.colours.yellows.yellow1};
`;

const Container = styled.div`
  cursor: pointer;

  ${StarIconDefault} {
    height: 30px;
    width: 30px;
  }
  ${StarIconYellow} {
    height: 30px;
    width: 30px;
  }
`;

const DashboardTileStar = ({ className, favourite = false, onToggleFavourite }) => (
  <Container
    className={className}
    onClick={onToggleFavourite}
  >
    {favourite ? <StarIconYellow /> : <StarIconDefault />}
  </Container>
);

export default DashboardTileStar;
