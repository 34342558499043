// useProgramElement
import * as R from 'ramda';
import React, { useState, useEffect, createContext, useContext } from 'react';
import Api from '../api';

export interface MyRecentAssignment {
  elementId: string;
  elementName: string;
  elementType: string;
  reportId: string;
  quarter: string;
  year: string;
  totalScore: string;
  totalColour: string;
}
const MyRecentAssignmentsContext = createContext(null as any);

const useMyRecentAssignments = () => {
  const myRecentAssignments = useContext(MyRecentAssignmentsContext);

  if (R.isNil(myRecentAssignments)) {
    throw new Error('useMyRecentAssignments must be used inside MyRecentAssignmentsProvider');
  }

  return myRecentAssignments;
};

export const MyRecentAssignmentsProvider = ({ ...props }: any) => {
  const [myRecentAssignments, setMyRecentAssignments] = useState([] as Array<MyRecentAssignment>);

  useEffect(() => {
    Api.getMyRecentAssignments().then(setMyRecentAssignments);
  }, [setMyRecentAssignments]);

  const value = {
    myRecentAssignments,
  };

  return (
    <MyRecentAssignmentsContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useMyRecentAssignments;
