import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useCallWithRedirect = (path, fn) => {
  const history = useHistory();

  const callFn = useCallback(
    (...params) => {
      fn(...params);
      history.replace(path);
    },
    [path, fn, history]
  );

  return callFn;
};

export default useCallWithRedirect;
