import NoRecords from '../../../components/NoRecords';
import TabPanel, { TabPanelProps } from '../../../components/Tabs/TabPanel';
import { TabProps, TabState } from '../types';

interface Props extends Omit<TabPanelProps<TabState>, 'children'> {
  element: TabProps['element'] | null;
  component: React.ComponentType<TabProps>;
  lazyLoad?: boolean;
}

const LiveReportsTab: React.FC<Props> = ({
  component: Component,
  element,
  value,
  currentTab,
  className,
  lazyLoad = false,
}) => {
  // When lazy-loading a tab, don't render it until it has been selected
  if (lazyLoad && currentTab !== value) {
    return null;
  }
  return (
    <TabPanel
      value={value}
      currentTab={currentTab}
      className={className}
    >
      {element ? <Component element={element} /> : <NoRecords />}
    </TabPanel>
  );
};

export default LiveReportsTab;
