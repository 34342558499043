import { Grid } from '@material-ui/core';
import { ControlTextField } from '../../../../form/ControlTextField';
import { useActions } from '../../hooks/useActions';
import { ControlAutocomplete } from '../../../../form/ControlAutocomplete';
import { useUsers } from '../../hooks/useUsers';
import { ControlDate } from '../../../../form/ControlDate';
const AddEditActionForm = () => {
  const { groups, flags, control, errors } = useActions();
  const { users } = useUsers();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <ControlTextField
          control={control}
          name="description"
          label="Action Description"
          fullWidth
          required
          minLength={1}
          maxLength={4000}
          error={!!errors.description}
          multiline
          placeholder="Required (Character Limitation: 4000 Characters)"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlAutocomplete
          control={control}
          options={users.map((user: any) => ({
            label: user.name,
            value: user.id,
          }))}
          name="owner"
          label="Owner"
          required={false}
          error={!!errors.owner}
          placeholder=""
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginBottom: 16 }}
      >
        <ControlAutocomplete
          control={control}
          options={groups.map((group: any) => ({
            label: group.label,
            value: group.id,
          }))}
          name="groupId"
          label="Group"
          required
          error={!!errors.groupId}
          placeholder="Required"
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlDate
          control={control}
          dateFormat="dMMMyyyy"
          name="tbe"
          label="TBE"
          required={false}
          disableTyping
          error={!!errors.tbe}
          clearable
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlDate
          control={control}
          dateFormat="dMMMyyyy"
          name="tcd"
          label="TCD"
          required={false}
          disableTyping
          error={!!errors.tcd}
          clearable
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlAutocomplete
          control={control}
          options={flags.map((flag: any) => ({
            label: flag.label,
            value: flag.id,
          }))}
          name="flags"
          label="Flags"
          required={false}
          error={!!errors.flags}
          multiple={true}
        />
      </Grid>
    </Grid>
  );
};

export default AddEditActionForm;
