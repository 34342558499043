import React from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import useAllReferenceTypes from '../hooks/all-reference-types';
import useForm from '../hooks/form';
import useConditionalConfirm from '../hooks/conditional-confirm';
import DialogButton from './DialogButton';
import OkayCancelDialog from './OkayCancelDialog';
import CustomFormLabel from './CustomFormLabel';
import CustomTextField from './CustomTextField';
import EchoSelect from './Select';

const formConfig = {
  typeId: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select reference type',
      },
    },
  },
  referenceNumber: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter reference number',
      },
    },
  },
};

const initialValues = (reference: any) => ({
  typeId: R.propOr('', 'typeId', reference),
  referenceNumber: R.propOr('', 'referenceNumber', reference),
});

interface Props {
  className?: string;
  reference?: any;
  onSubmit(x: any): void;
  onCancel(x: any): void;
}

const ReferenceForm = ({ className, reference, onSubmit, onCancel }: Props) => {
  const { referenceTypes } = useAllReferenceTypes();
  const { submit, isDirty, propsForField } = useForm(formConfig, initialValues(reference), onSubmit);
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  const referenceTypeOptions = referenceTypes.map((referenceType: any) => ({
    value: referenceType.name === 'Cap Proj' || referenceType.name === 'Other' ? referenceType.id : referenceType.name,
    label: referenceType.name,
  }));

  return (
    <Grid
      container
      className={className}
      spacing={2}
    >
      <Grid
        item
        xs={6}
      >
        <EchoSelect
          label="Reference Type"
          fullWidth
          options={referenceTypeOptions}
          LabelComponent={CustomFormLabel}
          {...propsForField('typeId')}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <CustomTextField
          label="Reference #"
          {...propsForField('referenceNumber')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <DialogButton
              color="primary"
              onClick={submit}
            >
              Confirm
            </DialogButton>
          </Grid>
          <Grid item>
            <DialogButton onClick={confirmCancel}>Cancel</DialogButton>
          </Grid>
        </Grid>
      </Grid>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Yes"
          cancelLabel="No"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          You have unconfirmed changes. Are you sure you want to cancel?
        </OkayCancelDialog>
      )}
    </Grid>
  );
};

export default ReferenceForm;
