import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as myActions from '../state/my/actions';
import * as mySelectors from '../state/my/selectors';
import { Store } from 'redux';
import { MyElement } from '../types/my';

const useMyElements = (load = true) => {
  const dispatch = useDispatch();

  const loadMyElements = useCallback(() => {
    dispatch(myActions.loadElementsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (load) {
      loadMyElements();
    }
  }, [load, loadMyElements]);

  const myElements = useSelector<Store, MyElement[]>(mySelectors.getElements);

  const myElementIds = myElements.map(({ elementId }) => elementId);

  return {
    myElements,
    loadMyElements,
    myElementIds,
  };
};

export default useMyElements;
