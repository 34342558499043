import React from 'react';
import * as R from 'ramda';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { User } from '../../../../types/user';

type UserListItemProps = {
  user: User;
  selectedUserId: string | null;
  onSelectUserId: (id: string) => void;
};

const UserListItem = ({ user, selectedUserId, onSelectUserId }: UserListItemProps) => (
  <ListItem
    button
    selected={selectedUserId === user.id}
    onClick={R.thunkify(onSelectUserId)(user.id)}
  >
    <ListItemText
      primary={user.name}
      secondary={user.email}
    />
  </ListItem>
);

type UserListProps = {
  className?: string;
  users: User[];
  selectedUserId: string | null;
  onSelectUserId: (id: string) => void;
};

const UserList = ({ className, users, selectedUserId, onSelectUserId }: UserListProps) => {
  const items = users.map(user => (
    <UserListItem
      key={user.id}
      user={user}
      selectedUserId={selectedUserId}
      onSelectUserId={onSelectUserId}
    />
  ));

  return <List className={className}>{items}</List>;
};

export default UserList;
