import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const OutlineButton = styled(Button).attrs({
  variant: 'outlined',
})`
  padding: 8px 32px;
  font: ${({ theme }) => theme.fonts.medium13};
  color: ${({ theme }) => theme.colours.teals.teal1};
  border: ${({ theme }) => theme.borders.teal.solid1};

  &:hover {
    background-color: ${({ theme }) => theme.colours.teals.teal3};
  }
`;

export default OutlineButton;
