import React, { useCallback } from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import { defaultValues, formConfig } from './task-form-config';
import CustomTextField from '../../../components/CustomTextField';
import useForm from '../../../hooks/form';
import TaskCommentcell from './TaskCommentcell';
import TaskAttachmentsBox from './upload-attachments/components/AttachmentsBox';

const TextBox = styled(CustomTextField)`
  border: none;
`;

interface Props {
  onCreate(t: any): any;
}

const CreateTask = ({ onCreate }: Props) => {
  const { submit, propsForField, values, setField } = useForm(formConfig, defaultValues, onCreate);

  const commentHandler = useCallback(
    (_c: number, _t: number, comment: string) => {
      setField('comment', comment);
    },
    [setField]
  );

  const uploadHandler = (_w: any, f: string) => setField('file', f);

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>
        <TextBox
          fullWidth
          {...propsForField('title')}
          inputProps={{
            maxLength: 50,
          }}
        />
      </TableCell>
      <TableCell>
        <TextBox
          fullWidth
          {...propsForField('description')}
          inputProps={{
            maxLength: 50,
          }}
        />
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <TextBox
          fullWidth
          {...propsForField('value')}
          inputProps={{
            maxLength: 50,
          }}
        />
      </TableCell>
      <TableCell>
        <TaskAttachmentsBox
          walkdownId={0}
          onAddAttachment={uploadHandler}
          showAttachment={values.file}
          taskId={0}
        />{' '}
        {/** Using a static walkdown id since this value won't be used anywhere */}
      </TableCell>
      <TableCell align="center">
        <TaskCommentcell
          task={values}
          hasComment={values.comment}
          setTaskComment={commentHandler}
        />
        <IconButton
          size="small"
          onClick={submit}
        >
          <CheckIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CreateTask;
