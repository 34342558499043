export const UCR_VALUES = [
  'ANYC',
  'LYUP',
  'MPRE',
  'OOPR',
  'OPNF',
  'OPRE',
  'OPST',
  'SPLN',
  'SSCO',
  'SVAC',
  'SFRC',
] as const;

export const OVERLAPPING_WORK_CATEGORY_TYPE_VALUES: Array<
  (typeof WORK_TYPES)[number] & (typeof WORK_CATEGORIES)[number]
> = ['ADMIN', 'MINOR'];

export const WORK_TYPES = [
  'ACT',
  'ADMIN',
  'CC',
  'CCA',
  'CL',
  'CN',
  'CNFSP',
  'DC',
  'DL',
  'DN',
  'EPS',
  'EQ',
  'FME',
  'FPE',
  'FWO',
  'HP',
  'HRE',
  'HWORK',
  'HZ',
  'INSUL',
  'IPTE',
  'LIFT',
  'MAJOR',
  'MAR',
  'MINOR',
  'MO',
  'MR',
  'OA',
  'OB',
  'OD',
  'OF',
  'OP',
  'OR',
  'OX',
  'PD',
  'PM',
  'RADSH',
  'RO',
  'RP',
  'SCFD',
  'SUR',
  'TCC',
  'WLD',
] as const;

export const WORK_CATEGORIES = [
  'ADMIN',
  'CORR',
  'ELECT',
  'MAJEVOL',
  'MINOR',
  'OTHER',
  'PROJECT',
  'STANDARD',
  'TOOLPOUCH',
] as const;

export const SCHEDULE_BACKLOG_VALUES = [
  '5J',
  '5R',
  '9Z',
  'CY',
  'EX',
  'FH',
  'H2',
  'HS',
  'JJ',
  'MA',
  'MC',
  'ME',
  'MP',
  'MW',
  'MX',
  'PL',
  'PO',
  'PP',
  'PR',
  'U0',
  'U1',
  'U2',
  'U3',
  'U4',
  'U5',
  'U6',
  'U7',
  'U8',
  'WA',
  'WB',
  'WC',
  'WD',
  'WE',
  'WF',
  'WG',
  'WH',
  'WJ',
  'WK',
  'WL',
  'WM',
  'WN',
  'WZ',
] as const;

export const PLANNING_CENTRES = [
  'ALW',
  'FHA',
  'FHB',
  'FLEX',
  'FO',
  'GSDU',
  'GSDX',
  'MCR',
  'OPA',
  'OPB',
  'PLN',
  'PRJ',
  'RST',
  'SCO',
  'VBO',
] as const;
