import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledActionButton from '../../../../../components/ActionButton';
import ActionFilterPanel, { ActionFilterPanelProps } from '../../../../../components/ShipChip/ActionFilterPanel';
import { ElementId } from '../../../../../types/app';

const ActionButton = styled(UnstyledActionButton)``;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;

  ${ActionButton} {
    margin-right: 43px;
  }
`;

interface Props extends ActionFilterPanelProps {
  className?: string;
  elementId: ElementId;
  onNew: any;
}

const TopPanel: React.FC<Props> = ({ className, elementId, state, source, onChangeStatus, onChangeSource, onNew }) => (
  <Container className={className}>
    <ActionFilterPanel
      state={state}
      onChangeStatus={onChangeStatus}
      source={source}
      onChangeSource={onChangeSource}
    />
    <ActionButton
      color="primary"
      onClick={onNew}
      disabled={R.isEmpty(elementId)}
    >
      Add New
    </ActionButton>
  </Container>
);

export default TopPanel;
