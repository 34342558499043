import React from 'react';
import OverlayPaper from '../../../components/OverlayPaper/index';
import OkayCancelDialog from '../../../../../components/OkayCancelDialog';
import useAttachmentsByQuarter from '../../../../../hooks/report-attachments-by-quarter';
import useAddReportAttachment from '../../../../../hooks/add-report-attachment';
import useDeleteReportAttachment from '../../../../../hooks/delete-report-attachment';
import useDownloadReportAttachment from '../../../../../hooks/download-report-attachment';
import AttachmentList from './AttachmentList';
import AddAttachment from './AddAttachment';
import { Report } from '../../../../../hooks/report';

interface Props {
  report: Report;
  onNext: () => void;
}

const AttachmentsBox = ({ report, onNext }: Props) => {
  const { attachments, attachmentsWithState } = useAttachmentsByQuarter(report.elementId, report.year, report.quarter);
  const { addAttachment } = useAddReportAttachment(report.elementId, report.year, report.quarter);
  const { attachmentToDelete, confirmDeleteAttachment, cancelDeleteAttachment, deleteAttachment } =
    useDeleteReportAttachment(report.elementId, report.year, report.quarter, attachments);
  const { downloadAttachment } = useDownloadReportAttachment(attachments);

  return (
    <OverlayPaper
      title="Upload Attachments"
      isDoneDisabled={false}
      onDone={onNext}
    >
      <AttachmentList
        attachmentsWithState={attachmentsWithState}
        onDelete={confirmDeleteAttachment}
        onDownload={downloadAttachment}
      />
      <AddAttachment onAddAttachment={addAttachment} />
      {attachmentToDelete && (
        <OkayCancelDialog
          title="Delete?"
          okayLabel="Delete"
          onOkay={deleteAttachment}
          onCancel={cancelDeleteAttachment}
        >
          Are you sure you want to delete the attachment "{attachmentToDelete.filename}"?
        </OkayCancelDialog>
      )}
    </OverlayPaper>
  );
};

export default AttachmentsBox;
