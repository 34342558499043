import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import * as R from 'ramda';
import ActionButton from '../../../../components/ActionButton';
import CustomTextField from '../../../../components/CustomTextField';
import FilterSelector from '../../../../components/FilterSelector';
import useForm from '../../../../hooks/form';
import useMyElements from '../../../../hooks/my-elements';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import useWalkdownTemplatesList from '../../hooks/useWalkdownTemplatesList';
import CreateCategory from '../categories/CreateCategory';
import TemplateCategory from '../categories/TemplateCategory';
import { defaultValues, formConfig } from '../config/createTemplateFormFields';
import {
  CreateCategoryParams,
  WalkdownTemplate,
  WalkdownTemplateCategory,
  WalkdownTemplateParams,
  WalkdownTemplateTask,
} from '../../../../types/walkdown-template';
import useSequence from '../../../../hooks/sequence';

const Controls = styled.div`
  padding: 30px 10px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Story = styled.div`
  padding-bottom: 30px;
`;

const Layer = styled.div`
  padding: 10px;
`;

interface Props {
  template?: WalkdownTemplate;
}

const CreateTemplateInternal = ({ template }: Props) => {
  const { create, lastCreatedId } = useWalkdownTemplatesList();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { myElements } = useMyElements();
  const [creating, setCreating] = useState(false);
  const [categories, setCategories] = useState<Partial<WalkdownTemplateCategory>[]>([]);
  const { generate } = useSequence();

  const createWrapper = useCallback(
    (v: WalkdownTemplateParams) =>
      create({
        ...v,
        categories,
      }),
    [categories]
  );

  const { submit, propsForField, setField, values, clear } = useForm(
    formConfig,
    template || defaultValues,
    createWrapper
  );

  useEffect(() => {
    if (template && template.id) {
      setCategories(template.categories);
      setField('title', template.title);
      setField('type', template.type);
      setField('elementId', template.elementId);
    }
  }, [template]);

  const elementOptions = myElements
    .filter((element: any) => element.elementType !== 'program')
    .map((element: any) => ({
      name: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
      value: element.elementId,
    }));

  useEffect(() => {
    clear(); // Clears the form every time a new template is successfully created
    if (lastCreatedId) {
      history.push(path.replace('create', `view/${lastCreatedId}`));
    }
  }, [lastCreatedId]);

  const createCategoryHandler = useCallback((t: CreateCategoryParams) => {
    setCategories(curr =>
      [...curr].concat({
        id: generate(),
        ...t,
        tasks: [],
      })
    );

    setCreating(false);
  }, []);

  const createTaskHandler = useCallback((categoryId: string, task: WalkdownTemplateTask) => {
    setCategories(curr => {
      const cat = curr.find(R.propEq<any>('id', categoryId));

      if (cat && cat.tasks) {
        const newCategories = [...curr];
        cat.tasks.push(task);
        return newCategories;
      }

      return curr;
    });
  }, []);

  const removeTaskHandler = (categoryId: string, task: WalkdownTemplateTask) => {
    const newCategories: WalkdownTemplateCategory[] = [...values.categories];
    const idx = newCategories.findIndex((r: any) => r.id === categoryId);
    if (idx > -1) {
      newCategories[idx].tasks.push(task);
      setCategories(newCategories);
    }
  };

  const updateTaskHandler = (categoryId: string, task: WalkdownTemplateTask) => {
    const newCategories = [...categories] as WalkdownTemplateCategory[];
    const idx = newCategories.findIndex((r: any) => r.id === categoryId);

    if (idx > -1) {
      const taskIndex = newCategories[idx].tasks.findIndex(t => t.id === task.id);
      if (taskIndex > -1) {
        newCategories[idx].tasks[taskIndex] = task;
        setCategories(newCategories);
      }
    }
  };

  const deleteCategoryHandler = (id: any) => {
    setCategories(curr => curr.filter(c => c.id !== id));
  };

  const radioHandler = (e: any) => setField('type', e.target.value);

  const filterSelectorHandler = (elementId: string) => {
    setField('elementId', elementId);
  };

  return (
    <div>
      <Controls>
        <Grid item>
          <FilterSelector
            name="Element"
            value={values.elementId}
            onChange={filterSelectorHandler}
            options={elementOptions}
          />
        </Grid>
        <ActionButton
          color="primary"
          onClick={submit}
        >
          Save
        </ActionButton>
      </Controls>

      <Story>
        <div>Title</div>
        <CustomTextField
          {...propsForField('title')}
          variant="standard"
          style={{ width: '400px' }}
        />
      </Story>

      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Type</FormLabel>
          <RadioGroup
            onChange={radioHandler}
            row
            aria-label="type"
            name="row-radio-buttons-group"
            value={values.type}
          >
            <FormControlLabel
              value="innage"
              control={<Radio />}
              label="Innage"
            />
            <FormControlLabel
              value="outage"
              control={<Radio />}
              label="Outage"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <Layer>Tasks</Layer>
      <Layer>
        <Button
          variant="text"
          onClick={() => setCreating(true)}
        >
          + Add Category
        </Button>
      </Layer>

      <Layer>
        {creating && <CreateCategory onSave={createCategoryHandler} />}

        {categories.map((c: any) => (
          <TemplateCategory
            key={c.id}
            category={c}
            onRemoveCategory={deleteCategoryHandler}
            onCreateTask={createTaskHandler}
            onUpdateTask={updateTaskHandler}
            onRemoveTask={removeTaskHandler}
          />
        ))}
      </Layer>
    </div>
  );
};

export default CreateTemplateInternal;
