import React from 'react';
import styled from 'styled-components';
import FileUploadForm from './FileUploadForm';

const Container = styled.div``;

interface Props {
  taskId: any;
  onAddAttachment(taskId: any, file: any): void;
  attachment?: any;
}

const AddAttachment = ({ taskId, onAddAttachment, attachment }: Props) => {
  const handleUpload = (file: string) => {
    onAddAttachment(taskId, file);
  };

  return <Container>{!attachment.name && <FileUploadForm onUpload={handleUpload} />}</Container>;
};

export default AddAttachment;
