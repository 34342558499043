import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Control, FieldErrors, useForm } from 'react-hook-form';
import { useWorkOrders } from './useWorkOrders';
import { Overrides, OverviewSchedule } from '../../../types/cwt/overview';
import { PM, WorkOrder } from '../../../types/cwt/workorder';

interface useOverviewValue {
  pms: PM[];
  control: Control<OverviewSchedule, any>;
  errors: FieldErrors<OverviewSchedule>;
  handleSubmit: any;
  wos: WorkOrder[];
  overrides: Overrides;
  handleChangeOverride: (groupId: number, overrideValue: boolean | null) => void;
}

interface Props {
  children: ReactNode;
}
const OverviewContext = createContext<useOverviewValue | undefined>(undefined);

export const OverviewContextProvider = ({ children }: Props) => {
  const { selectedWorkorderId, getWorkorderById } = useWorkOrders();
  const [pms, setPms] = useState<PM[]>([]);
  const [wos, setWOs] = useState<WorkOrder[]>([]);
  const [overrides, setOverrides] = useState<Overrides>({
    assessing: null,
    sc: null,
    eng: null,
    ops: null,
    other: null,
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OverviewSchedule>({
    defaultValues: {
      start: undefined,
      end: undefined,
      crewId: getWorkorderById(selectedWorkorderId)?.crewworkgroup,
    },
  });

  const getPms = useCallback(async () => {
    if (selectedWorkorderId) {
      const res = await Api.cwt.getWorkorderRelatedPms(selectedWorkorderId);
      setPms(res);
    }
  }, [selectedWorkorderId]);

  const getWos = useCallback(async () => {
    if (selectedWorkorderId) {
      const res = await Api.cwt.getWorkorderRelatedWOs(getWorkorderById(selectedWorkorderId)?.location);
      setWOs(res);
    }
  }, [selectedWorkorderId]);

  const getOverview = useCallback(async () => {
    const overview = await Api.cwt.getOverview(selectedWorkorderId);
    setValue('start', overview.start);
    setValue('end', overview.end);
    if (overview.crewId) setValue('crewId', overview.crewId);
    setOverrides(overview.groupStatusOverrides);
  }, [selectedWorkorderId]);
  const handleChangeOverride = (groupId: number, overrideValue: boolean | null) => {
    if (!selectedWorkorderId) return;
    try {
      Api.cwt.updateOverrides(selectedWorkorderId, groupId, overrideValue);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    void getOverview();
    void getPms();
    void getWos();
  }, [selectedWorkorderId]);

  const onSubmit = (data: OverviewSchedule) => {
    console.log(data);
    if (!selectedWorkorderId) return;
    try {
      Api.cwt.updateScheduleOverview(selectedWorkorderId, data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <OverviewContext.Provider
      value={{
        pms,
        control,
        errors,
        handleSubmit: handleSubmit(onSubmit),
        wos,
        overrides: overrides,
        handleChangeOverride,
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export const useOverview = () => {
  const context = useContext(OverviewContext);
  if (context === undefined) {
    throw new Error('useOverview must be used within a OverviewContextProvider');
  }
  return context;
};
