import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Scorecard } from 'echo-scorecard';

import Api from '../api';
import * as reportsActions from '../state/reports/actions';
import * as uiActions from '../state/ui/actions';
import { UI_MESSAGE } from '../utils/ui-messages';

interface Props {
  reportId: number;
  scorecard: Scorecard;
  overrideTotal: {
    score: number;
    colour: string;
    comment: string;
  } | null;
}

export interface TotalValueState {
  value: number;
  colour?: string;
  comment: string;
}

const useEditManualTotalValue = ({ reportId, scorecard, overrideTotal }: Props) => {
  const dispatch = useDispatch();

  const [manualTotalValue, setManualTotalValue] = useState<null | TotalValueState>(null);

  const editManualTotalValue = useCallback(() => {
    setManualTotalValue({
      value: overrideTotal ? overrideTotal.score : scorecard.totals.score,
      colour: overrideTotal ? overrideTotal.colour : scorecard.totals.colour,
      comment: overrideTotal ? overrideTotal.comment : '',
    });
  }, [overrideTotal, scorecard]);

  const cancelEditManualTotalValue = useCallback(() => {
    setManualTotalValue(null);
  }, []);

  const saveManualTotalValue = useCallback(
    async (value: number, colour?: string, comment?: string) => {
      try {
        await Api.overrideReportTotal(reportId, value, colour, comment);
        setManualTotalValue(null);
        dispatch(reportsActions.loadRequest(reportId));
      } catch (e: any) {
        dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
      }
    },
    [dispatch, reportId]
  );

  const clearManualTotalValue = useCallback(async () => {
    try {
      await Api.clearOverrideReportTotal(reportId);
      setManualTotalValue(null);
      dispatch(reportsActions.loadRequest(reportId));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, [dispatch, reportId]);

  return {
    manualTotalValue,
    editManualTotalValue,
    cancelEditManualTotalValue,
    saveManualTotalValue,
    clearManualTotalValue,
  };
};

export default useEditManualTotalValue;
