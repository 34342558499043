import { useCallback, useEffect, useState } from 'react';
import Api from '../../../../../api';

const useAddAttachment = (walkdownId: number, taskId: any) => {
  const [attachment, setAttachment] = useState([]);

  const addAttachment = useCallback(async (id: number | string, file: any) => {
    await Api.uploadTaskAttachment(walkdownId, id, file);
    const response = await Api.getAttachmentByTaskId(walkdownId, taskId);
    setAttachment(response);
  }, []);

  const deleteAttachment = useCallback(async (id: number) => {
    await Api.deleteWalkdownTaskAttachment(walkdownId, id);
    const response = await Api.getAttachmentByTaskId(walkdownId, taskId);
    setAttachment(response);
  }, []);

  useEffect(() => {
    Api.getAttachmentByTaskId(walkdownId, taskId).then((response: any) => {
      setAttachment(response);
    });
  }, [taskId]);

  return {
    addAttachment,
    attachment,
    deleteAttachment,
  };
};

export default useAddAttachment;
