import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogButton from './DialogButton';

interface Props {
  children?: any;
  title?: string;
  okayLabel?: string;
  cancelLabel?: string;
  onOkay: any;
  onCancel: any;
}

const OkayCancelDialog = ({ children, title, okayLabel = 'OK', cancelLabel = 'Cancel', onOkay, onCancel }: Props) => {
  const handleOkay = () => {
    onOkay();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={handleCancel}>{cancelLabel}</DialogButton>
        <DialogButton
          color="primary"
          onClick={handleOkay}
        >
          {okayLabel}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default OkayCancelDialog;
