import React from 'react';
import styled from 'styled-components';

import MUIPaper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

const Input = styled(InputBase)`
  margin-left: 8px;
  padding-right: 10px;
`;
const Paper = styled(MUIPaper)`
  border: ${({ theme }) => theme.borders.thin.solid2};
  border-radius: 2px;
  height: 50px;
  width: 30%;
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow5};
`;

const SearchField = ({ value, onChange }) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <Paper
      elevation={2}
      component="form"
    >
      <Input
        fullWidth
        value={value}
        onChange={handleChange}
        placeholder="Search Dashboards"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Paper>
  );
};

export default SearchField;
