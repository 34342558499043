import { differenceInMilliseconds, isBefore } from 'date-fns';

const MILLISECONDS_PER_HOUR = 1000 * 60 * 60;
const MILLISECONDS_PER_DAY = MILLISECONDS_PER_HOUR * 24;

export const durationMilliseconds = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate || isBefore(endDate, startDate)) {
    return null;
  }
  return differenceInMilliseconds(endDate, startDate);
};

export const durationHours = (startDate: Date | null, endDate: Date | null) => {
  const diffMs = durationMilliseconds(startDate, endDate);
  return diffMs && diffMs / MILLISECONDS_PER_HOUR;
};

export const durationDays = (startDate: Date | null, endDate: Date | null) => {
  const diffMs = durationMilliseconds(startDate, endDate);
  return diffMs && diffMs / MILLISECONDS_PER_DAY;
};
