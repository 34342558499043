import { isNil } from 'ramda';
import { Coding, WorkRequestCoding, WorkRequestCodingFormInputs, WorkRequestCodingValues } from '../types';
import { CATEGORY_LABELS } from '../constants';

export const buildInitialValues = (coding: WorkRequestCoding | undefined): WorkRequestCodingFormInputs | undefined => {
  if (!coding) {
    return;
  }
  const { proposedCoding, notes, screeningStatus } = coding;
  return {
    proposedCoding: proposedCoding ?? null,
    codingNotes: notes ?? null,
    screeningStatus,
    codings: buildInitialCodingValues(coding),
  };
};

export const buildInitialCodingValues = (coding: WorkRequestCoding): Coding[] => {
  return Object.keys(coding)
    .filter(key => key in CATEGORY_LABELS && !isNil(coding[key as keyof WorkRequestCodingValues]))
    .map(key => ({ category: key, value: coding[key as keyof WorkRequestCodingValues], locked: true } as Coding));
};
