import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

const typeToColourPath: { [type: string]: string[] } = {
  system: ['blues', 'blue1'],
  component: ['teals', 'teal1'],
  program: ['purples', 'purple1'],
};

const typeToLetter: { [type: string]: string } = {
  system: 'S',
  component: 'C',
  program: 'P',
};

const Container = styled(props => <div {...R.omit(['elementType'], props)} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  background-color: ${({ theme, elementType }) => R.path(typeToColourPath[elementType], theme.colours)};
  color: white;
`;

interface Props {
  className?: string;
  type: string;
}

const ElementTypeIcon = ({ className, type }: Props) => (
  <Container
    className={className}
    elementType={type}
  >
    {typeToLetter[type]}
  </Container>
);

export default ElementTypeIcon;
