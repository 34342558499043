import * as actionTypes from '../action-types';

const initialState = {
  elements: [],
  assignments: [],
  units: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_LOAD_ELEMENTS_SUCCESS: {
      const { elements } = action.payload;
      return {
        ...state,
        elements,
      };
    }
    case actionTypes.MY_LOAD_ASSIGNMENTS_SUCCESS: {
      const { assignments } = action.payload;
      return {
        ...state,
        assignments,
      };
    }
    case actionTypes.MY_LOAD_UNITS_SUCCESS: {
      const { units } = action.payload;
      return {
        ...state,
        units,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
