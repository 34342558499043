import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import * as R from 'ramda';
import useForm from '../hooks/form';
import UnstyledFormDialog from './FormDialog';
import Select from './Select';

const CustomSelectField = styled(Select)``;

const FormDialog = styled(UnstyledFormDialog)`
  ${CustomSelectField} {
    width: 496px;
  }
`;

const formConfig = () => ({
  users: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Choose Users',
      },
    },
  },
});

const initialValues = {
  users: [],
};

interface Props {
  title: string;
  options: Array<any>;
  onSave(users: any[]): void;
  onCancel(): void;
}

const ReportSubmitDialog = ({ title, options, onSave, onCancel }: Props) => {
  const handleSave = (values: any) => {
    onSave(values.users);
  };

  const sort = R.sortBy(R.prop('label'));

  const changeHandler = (users: any[]) => {
    const newArray: any = [];
    users.map((user: any) => newArray.push(user.value));
    return newArray;
  };

  const { submit, setField } = useForm(formConfig(), initialValues, handleSave);

  return (
    <FormDialog
      title={title}
      onSave={submit}
      onCancel={onCancel}
    >
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={12}
        >
          Choose Users To Email
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Autocomplete
            multiple
            options={sort(options)}
            getOptionLabel={option => option.label}
            onChange={(_, value) => {
              setField('users', changeHandler(value));
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default ReportSubmitDialog;
