import { Scorecard } from 'echo-scorecard';
import { ElementId } from '../../types/app';
import { MyElement } from '../../types/my';
import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadScorecardRequest = (elementId: ElementId, year: number, quarter: number) => ({
  type: actionTypes.SCORECARD_LOAD_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
  },
});

export const loadScorecardSuccess = (elementId: ElementId, year: string, quarter: string, scorecard: Scorecard) => ({
  type: actionTypes.SCORECARD_LOAD_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    scorecard,
  },
});

export const scorecardErrorClear = (elementId: ElementId, year: number, quarter: number) => ({
  type: actionTypes.SCORECARD_HYDRATE_FROM_CACHE,
  payload: {
    elementId,
    year,
    quarter,
  },
});

export const scorecardHydrateFromCache = (
  elementId: ElementId,
  year: number,
  quarter: number,
  staleData: Scorecard,
  cacheLastModified: Date
) => ({
  type: actionTypes.SCORECARD_HYDRATE_FROM_CACHE,
  payload: {
    cacheLastModified,
    staleData,
    elementId,
    year,
    quarter,
  },
});

export const scorecardPublishError = (
  elementName: MyElement['elementName'],
  error: string | Error,
  errorMessage: string
) => ({
  type: actionTypes.SCORECARD_PUBLISH_FAILURE,
  payload: {
    elementName,
    error,
    errorMessage,
  },
});

export const loadScorecardFailure = errorAction(actionTypes.SCORECARD_LOAD_FAILURE);

export const loadOverrideRequest = (
  elementId: ElementId,
  year: string,
  quarter: string,
  indicatorId: string,
  unitNumber: string
) => ({
  type: actionTypes.SCORECARD_LOAD_OVERRIDE_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    unitNumber,
  },
});

export const loadOverrideSuccess = (overrideRecord: any) => ({
  type: actionTypes.SCORECARD_LOAD_OVERRIDE_SUCCESS,
  payload: {
    overrideRecord,
  },
});

export const loadOverrideFailure = errorAction(actionTypes.SCORECARD_LOAD_OVERRIDE_FAILURE);

export const clearOverride = () => ({
  type: actionTypes.SCORECARD_CLEAR_OVERRIDE,
});

export const overrideScoreRequest = (overrideRecord: any) => ({
  type: actionTypes.SCORECARD_OVERRIDE_SCORE_REQUEST,
  payload: {
    overrideRecord,
  },
});

export const overrideScoreFailure = errorAction(actionTypes.SCORECARD_OVERRIDE_SCORE_FAILURE);

export const resetOverrideScoreRequest = (
  elementId: ElementId,
  year: number,
  quarter: number,
  indicatorId: number,
  unitNumber: string
) => ({
  type: actionTypes.SCORECARD_RESET_OVERRIDE_SCORE_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    unitNumber,
  },
});

export const resetOverrideScoreSuccess = (
  elementId: ElementId,
  year: string,
  quarter: string,
  indicatorId: number,
  unitNumber: string
) => ({
  type: actionTypes.SCORECARD_RESET_OVERRIDE_SCORE_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    unitNumber,
  },
});

export const resetOverrideScoreFailure = errorAction(actionTypes.SCORECARD_RESET_OVERRIDE_SCORE_FAILURE);

export const setManualIndicatorValueRequest = (
  elementId: ElementId,
  year: string,
  quarter: string,
  indicatorId: string,
  elementUnit: string,
  value: string,
  colour: string,
  comment: string,
  updatedScorecard: Scorecard
) => ({
  type: actionTypes.MANUAL_INDICATOR_VALUES_SET_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    elementUnit,
    value,
    colour,
    comment,
    updatedScorecard,
  },
});

export const setManualIndicatorValueSuccess = (
  elementId: ElementId,
  year: string,
  quarter: string,
  scorecard: Scorecard
) => ({
  type: actionTypes.MANUAL_INDICATOR_VALUES_SET_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    scorecard,
  },
});

export const setManualIndicatorValueFailure = errorAction(actionTypes.MANUAL_INDICATOR_VALUES_SET_FAILURE);

export const setOverrideValueRequest = (
  elementId: ElementId,
  year: number,
  quarter: number,
  indicatorId: number,
  elementUnit: string,
  score: string,
  comment: string
) => ({
  type: actionTypes.OVERRIDE_VALUE_SET_REQUEST,
  payload: {
    elementId,
    year,
    quarter,
    indicatorId,
    elementUnit,
    score,
    comment,
  },
});

export const setOverrideValueSuccess = (elementId: ElementId, year: string, quarter: string, scorecard: Scorecard) => ({
  type: actionTypes.OVERRIDE_VALUE_SET_SUCCESS,
  payload: {
    elementId,
    year,
    quarter,
    scorecard,
  },
});

export const setOverrideValueFailure = errorAction(actionTypes.OVERRIDE_VALUE_SET_FAILURE);
