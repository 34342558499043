export type MigrateParams = { db: IDBDatabase; oldVersion: number; newVersion: number };
export type UserId = string;
export type EltId = string;
export type ApiRequestRow = {
  lastChecked: Date | string | number;
  lastUpdated: Date | string | number;
  requestContext: {
    requestBody?: Record<string, any>;
    userId: UserId;
    requestUrl: string;
  };
  requestUrlSlug: string;
  response: Record<string, any> | any[];
};
export type DispatchedEvent = {
  keyPath: string;
  params: ApiRequestRow;
};
type WriteParams = Omit<ApiRequestRow, 'requestUrlSlug'> & {
  originId?: string;
  lastChecked?: ApiRequestRow['lastChecked'];
  lastUpdated?: ApiRequestRow['lastUpdated'];
};
type UpdateParams = {
  lastChecked: ApiRequestRow['lastChecked'];
  lastUpdated?: ApiRequestRow['lastUpdated'];
  requestContext?: ApiRequestRow['requestContext'];
  response?: ApiRequestRow['response'];
};
type ObjectStoreRow = any | ApiRequestRow;
type TransactionFunctionReturn = ObjectStoreRow;
type TransactionFunction = () => TransactionFunctionReturn;
type UpdateTransactionFunction = (row: ObjectStoreRow) => TransactionFunctionReturn;
type UpdateOptions = {
  isEventMuted?: boolean;
};
export type HttpEventDetail = {
  status: number;
  data: any | any[];
  responseHeaders: Record<string, string>;
  requestHeaders: Record<string, string>;
  requestBody: string;
  method: string;
  relativeUrl: string;
  url: string;
  params: Record<string, number | string | object>;
};
export type HttpRegistration = string | ((detail: HttpEventDetail) => boolean);
export type GenerateRequestUrlSlugParams = {
  userId?: string;
  requestUrl: string;
  requestBody?: object | object[];
};
export type ClientDb = {
  eventHandler: IndexedDbEvents;
  broadcastChannel: BroadcastChannel;
  broadcastDebug: (...args: any[]) => void;
  httpRegistrations: HttpRegistration[];
  registerHttp: (reg: HttpRegistration) => void;
  unregisterHttp: (reg: HttpRegistration) => boolean;
  subscribe: (eventName: string, fn: (event: any) => void) => void;
  unsubscribe: (eventName: string, fn: (event: any) => void) => void;
  transact: (action: TransactionFunction) => Promise<ObjectStoreRow | null>;
  transactUpdate: (getAction: TransactionFunction, action: UpdateTransactionFunction) => Promise<ObjectStoreRow | null>;
  generateRequestUrlSlug: (params: GenerateRequestUrlSlugParams) => string;
  bodyEncoder: (bodyObject: string) => string;
  getRow: (keyPath: string) => Promise<ObjectStoreRow>;
  write: (params: WriteParams, options?: UpdateOptions) => Promise<void>;
  update: (keyPath: string, params: UpdateParams, options?: UpdateOptions) => Promise<ObjectStoreRow>;
  remove: (keyPath: string, options?: UpdateOptions) => Promise<ObjectStoreRow>;
  truncate: () => Promise<void>;
  initPromise: null | Promise<IDBDatabase>;
  db: null | IDBDatabase;
  rq: IDBOpenDBRequest;
};
export class IndexedDbEvents extends EventTarget {}

export type StoreListenerParams = {
  storeState?: any;
  cachedValue?: ApiRequestRow;
};
export type IndexedDbHandlerReturn = {
  current?: any;
  update?: ApiRequestRow['response'];
};
