import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useIndicator from '../../../../../hooks/indicator';
import ApplyDialog from '../../../../../components/ApplyDialog';
import useDetailsIndicatorRecordApplicability from '../hooks/details-indicator-record-applicability';
import useSelectedRecord from '../hooks/selected-record';
import UnstyledRecordDetailsPanel from './RecordDetailsPanel';
import UnstyledRecordListPanel from './RecordListPanel';
import { ElementId } from '../../../../../types/app';

const RecordListPanel = styled(UnstyledRecordListPanel)``;
const RecordDetailsPanel = styled(UnstyledRecordDetailsPanel)``;

const Container = styled.div`
  ${RecordListPanel} {
    height: 100%;
  }

  ${RecordDetailsPanel} {
  }
`;

interface Props {
  className?: string;
  records: any;
  indicatorId: string;
  elementId: ElementId;
  recordId: string;
  isLoading: boolean;
  onChangeRecord: (recordId: string) => void;
}

const ContentPanel = ({ className, records, indicatorId, elementId, recordId, isLoading, onChangeRecord }: Props) => {
  const { shortName } = useIndicator();
  const [applyDialogOpen, setApplyDialogOpen] = useState(false);
  const [hasComment, setOnlyComment] = useState(false);
  const [auxRecordType, setAuxRecordType] = useState(null);
  const selectedRecord = useSelectedRecord(records, shortName, recordId);
  const { setSelectedRecordApplicable, setSelectedRecordInapplicable } = useDetailsIndicatorRecordApplicability(
    indicatorId,
    shortName,
    elementId,
    selectedRecord
  );
  const handleApply = ({ onlyComments, recordType }: any) => {
    setApplyDialogOpen(true);
    setOnlyComment(onlyComments);
    setAuxRecordType(recordType);
  };

  const handleSave = (options: any) => {
    if (hasComment) {
      setSelectedRecordInapplicable(options);
    } else {
      setSelectedRecordApplicable({ ...options, recordType: auxRecordType });
    }
    setApplyDialogOpen(false);
  };

  const handleCancel = () => {
    setApplyDialogOpen(false);
  };

  return (
    <Container className={className}>
      <Grid
        container
        justify="flex-start"
        spacing={2}
      >
        <Grid item>
          <Box
            height="100%"
            width="300px"
          >
            <RecordListPanel
              records={records}
              selectedRecord={selectedRecord}
              isLoading={isLoading}
              onSelectRecordId={onChangeRecord}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs
        >
          {selectedRecord && (
            <RecordDetailsPanel
              record={selectedRecord}
              onApply={handleApply}
              onDismiss={() => setSelectedRecordInapplicable()}
            />
          )}
        </Grid>
        {applyDialogOpen && (
          <ApplyDialog
            indicatorShortName={auxRecordType || shortName}
            onlyComment={hasComment}
            record={selectedRecord}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}
      </Grid>
    </Container>
  );
};

export default ContentPanel;
