import React, { useState } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button, Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import useWalkdownTemplatesList from '../hooks/useWalkdownTemplatesList';
import Table from '../../../components/Table';
import ActionButton from '../../../components/ActionButton';
import elementTypePrefix from '../../../utils/element-type-prefix';
import NoRecords from '../../../components/NoRecords';
import OkayCancelDialog from '../../../components/OkayCancelDialog';
import { WalkdownTemplate } from '../../../types/walkdown-template';

const TemplatesList = () => {
  const { list, del } = useWalkdownTemplatesList();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [removingTemplate, setRemovingTemplate] = useState<WalkdownTemplate | null>(null);

  return (
    <>
      {removingTemplate && (
        <OkayCancelDialog
          title={`Are you sure you want to remove template ${removingTemplate.title}`}
          onOkay={() => {
            del(removingTemplate.id);
            setRemovingTemplate(null);
          }}
          onCancel={() => setRemovingTemplate(null)}
          okayLabel="Delete"
        />
      )}
      <Grid
        container
        style={{ paddingTop: '1em', paddingBottom: '1em' }}
      >
        <Grid
          item
          style={{ paddingRight: '1em' }}
        >
          <ActionButton
            color="primary"
            onClick={() => {
              history.push(`${path}/create`);
            }}
          >
            Create New Template
          </ActionButton>
        </Grid>
        <ActionButton
          color="primary"
          onClick={() => {
            history.push(`${path}/clone-template`);
          }}
        >
          Clone Template
        </ActionButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {list.length > 0 ? (
              list.map(template => {
                const { id, title, createdDate, elementName, elementType, facilityName } = template;

                return (
                  <TableRow key={id}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <b>
                        <>
                          [{elementTypePrefix(elementType)}] {elementName} ({facilityName}) - {title}
                        </>
                      </b>
                    </TableCell>
                    <TableCell align="center">
                      <i>Created On {moment(createdDate).format('LL')}</i>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        onClick={() => setRemovingTemplate(template)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        onClick={() => history.push(`${path}/view/${id}`)}
                      >
                        Open
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecords />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TemplatesList;
