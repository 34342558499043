import React from 'react';
import styled from 'styled-components';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiRadio from '@material-ui/core/Radio';

export type RadioOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

const Radio = styled(MuiRadio)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colours.teals.teal1};
  }
`;

interface Props {
  options: Array<RadioOption>;
  RadioComponent?: typeof Radio;
  error?: any;
  disabled?: boolean;
  helperText?: string;
  helperTextPosition?: 'top' | 'bottom';
  font?: string;
  labelColor?: string;
}

const CustomRadioGroup = ({
  options,
  RadioComponent = Radio,
  error,
  disabled: fieldDisabled,
  helperText,
  helperTextPosition = 'bottom',
  font,
  labelColor,
  ...props
}: RadioGroupProps & Props) => {
  const items = options.map(({ value, label, disabled: optionDisabled }) => (
    <FormControlLabel
      key={value}
      value={value}
      control={<RadioComponent />}
      label={label}
      disabled={fieldDisabled || optionDisabled}
    />
  ));

  return (
    <FormControl
      error={error}
      disabled={fieldDisabled}
    >
      {helperTextPosition === 'top' && (
        <FormHelperText style={{ font, color: labelColor }}>{helperText}</FormHelperText>
      )}
      <RadioGroup
        name="radio-buttons-group"
        {...props}
      >
        {items}
      </RadioGroup>
      {helperTextPosition === 'bottom' && (
        <FormHelperText style={{ font, color: labelColor }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomRadioGroup;
