import * as R from 'ramda';

const indicatorSpecifics = {
  'functional-failures': ['workOrderNumber', 'workRequestNumber', 'title'],
  'outstanding-corrective-actions': ['crNumber', 'subject'],
  'online-deficient-maintenance-backlog': ['workOrderNumber', 'title'],
  'shutdown-deficient-maintenance-backlog': ['workOrderNumber', 'title'],
  'online-corrective-maintenance-backlog': ['workOrderNumber', 'title'],
  'shutdown-corrective-maintenance-backlog': ['workOrderNumber', 'title'],
  predefines: ['pmNumber'],
  'regdoc-reportable-events': ['crNumber', 'subject'],
  'operator-workarounds': ['workOrderNumber', 'title'],
  'operator-burdens': ['workOrderNumber', 'title'],
  'tmods-beyond-6-months': ['tccId', 'title'],
  'tmods-beyond-90-days': ['tccId', 'title'],
  'modification-backlog': ['workOrderNumber', 'title'],
  'toe-corrective-actions': ['crNumber', 'description'],
  'comp-er-clock-resets': ['crNumber', 'description'],
  'comp-equipment-defects': ['workOrderNumber', 'workRequestNumber', 'title'],
  'comp-maintenance-feedback-backlog': ['workOrderNumber', 'title'],
  'comp-functional-failures': ['workOrderNumber', 'workRequestNumber', 'title'],
  'comp-outstanding-corrective-actions': ['crNumber', 'subject'],
  'comp-predefines': ['pmNumber'],
  'comp-online-deficient-maintenance-backlog': ['workOrderNumber', 'title'],
  'comp-online-corrective-maintenance-backlog': ['workOrderNumber', 'title'],
  'comp-shutdown-corrective-maintenance-backlog': ['workOrderNumber', 'title'],
  'comp-shutdown-deficient-maintenance-backlog': ['workOrderNumber', 'title'],
};

const useIndicatorQueryFields = indicatorId => R.propOr([], indicatorId, indicatorSpecifics);

export default useIndicatorQueryFields;
