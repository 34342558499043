import React from 'react';
import Grid from '@material-ui/core/Grid';
import { dateToString } from '../../../../../../utils/date-format';
import DetailHeader from './DetailHeader';
import HighLevelDetails, { HighLevelDetail } from './HighLevelDetails';

interface Props {
  tccRecord: any;
}

const TccDetails = ({ tccRecord }: Props) => {
  const { usi, title, unitNumber, status, category, unitOutageRequired, installDate, rse } = tccRecord;
  return (
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item>
        <DetailHeader>Title: {title}</DetailHeader>
      </Grid>
      <Grid item>
        <HighLevelDetails>
          <HighLevelDetail>System Code(USI): {usi}</HighLevelDetail>
          <HighLevelDetail>Unit: {unitNumber}</HighLevelDetail>
          <HighLevelDetail>Status: {status}</HighLevelDetail>
          <HighLevelDetail>Category: {category}</HighLevelDetail>
          <HighLevelDetail>Unit Outage: {unitOutageRequired}</HighLevelDetail>
          <HighLevelDetail>Install Date: {dateToString(installDate)}</HighLevelDetail>
          <HighLevelDetail>RSE: {rse}</HighLevelDetail>
        </HighLevelDetails>
      </Grid>
    </Grid>
  );
};

export default TccDetails;
