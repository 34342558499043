import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useId } from 'react';
import styled from 'styled-components';

interface AccordionSectionProps {
  title: string;
  content: React.ReactNode;
}

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${({ theme }) => theme.colours.purples.purple1};
  color: ${({ theme }) => theme.colours.white};
`;

const AccordionSection: React.FC<AccordionSectionProps> = ({ title, content }) => {
  const id = useId();
  return (
    <Accordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon htmlColor="white" />}
        aria-controls={`${id}-panel-content`}
        id={`${id}-panel-header`}
      >
        <Typography>{title}</Typography>
      </StyledAccordionSummary>
      <AccordionDetails id={`${id}-panel-content`}>{content}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
