import React, { useState } from 'react';
import * as R from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import UserLink from './UserLink';
import UserSelectForm from './UserSelectForm';

interface Props {
  className?: string;
  userId: string;
  name: string;
  allUsers: any[];
  onSet: any;
  onClear: any;
}

const PrimaryRse = ({ className, userId, name, allUsers, onSet, onClear }: Props) => {
  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSubmit = (newUserId: string) => {
    if (R.isEmpty(newUserId)) {
      onClear(userId);
    } else {
      onSet(newUserId);
    }
    setEditing(false);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  return (
    <Grid
      container
      alignItems="center"
      className={className}
    >
      <Grid
        item
        xs={12}
      >
        {editing ? (
          <UserSelectForm
            allowEmpty
            userId={userId}
            users={allUsers}
            onSubmit={handleSubmit}
            onCancel={handleCancelEdit}
          />
        ) : (
          <List>
            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <UserLink
                userId={userId}
                name={name}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export default PrimaryRse;
