import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { throttle } from 'lodash';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import App from './App';
import configureStore, { history, loadState, saveState } from './state';
import AppErrorBoundary from './AppErrorBoundary';
import { setup as clientDbSetup } from './client-db';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const { store, reactPlugin } = configureStore(loadState());

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

const Main = () => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <Provider store={store}>
      <AppErrorBoundary>
        <App history={history} />
      </AppErrorBoundary>
    </Provider>
  </AppInsightsContext.Provider>
);
const startReactApp = () => {
  root.render(<Main />);
};
clientDbSetup()
  .then(startReactApp)
  .catch(error => {
    console.error('Error:', error);
    startReactApp();
  });
