import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { dateTimeToString } from '../../../../utils/date-format';
import UnstyledParagraphs from '../../../../components/Paragraphs';

const Paragraphs = styled(UnstyledParagraphs)``;

const StatusText = styled(Typography)`
  font: ${({ theme }) => theme.fonts.normal14};
  font-style: italic;
`;
const Container = styled.div`
  ${StatusText} {
    margin-bottom: 8px;
  }

  ${Paragraphs} {
    margin-bottom: 8px;
  }
`;

interface Props {
  className?: string;
  report: any;
}

const ReportStatus = ({ className, report }: Props) => (
  <Container className={className}>
    <StatusText>
      Created by {report.createdByName} {dateTimeToString(report.createdAt)}
    </StatusText>
    {report.state === 'unsubmitted' && <StatusText>In progress</StatusText>}
    {report.state === 'pending-review' && (
      <StatusText>
        Submitted for review {dateTimeToString(report.stateChangedAt)} by {report.stateChangedByName}
      </StatusText>
    )}
    {report.state === 'changes-requested' && (
      <>
        <StatusText>
          Changes requested {dateTimeToString(report.stateChangedAt)} by {report.stateChangedByName}
        </StatusText>
        <Paragraphs s={report.stateChangeComment} />
      </>
    )}
    {report.state === 'approved' && (
      <StatusText>
        Approved {dateTimeToString(report.stateChangedAt)} by {report.stateChangedByName}
      </StatusText>
    )}
    <StatusText>
      Last updated {dateTimeToString(report.changedAt)} by {report.changedByName}
    </StatusText>
  </Container>
);

export default ReportStatus;
