import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import { Scorecard } from 'echo-scorecard';
import UnstyledTotalScoreBox from './TotalScoreBox';
import { Report } from '../../hooks/report';

const TotalScoreBox = styled(UnstyledTotalScoreBox)``;

const Typography = styled(UnstyledTypography)``;

const Container = styled(({ onClick, ...props }) => (
  <div
    {...props}
    onClick={onClick}
  />
))`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Typography} {
    font: ${({ theme }) => theme.fonts.medium13};
    color: ${({ theme }) => theme.colours.greys.darkGrey4};
  }

  ${TotalScoreBox} {
    height: 33px;
    width: 107px;
    margin-left: 22px;
  }

  ${({ onClick }) =>
    R.isNil(onClick)
      ? ''
      : `
    &:hover {
      filter: brightness(125%); /* TODO */
      cursor: pointer;
    }
  `}
`;

interface Props {
  className?: string;
  report: Report;
  scorecard: Scorecard;
  onClick: null | (() => void);
}

const TotalScore = ({ className, report, scorecard, onClick }: Props) => {
  const total = !R.isEmpty(report)
    ? {
        score: report?.overriddenScore ?? R.path(['totals', 'score'], scorecard),
        colour: report?.overriddenColour ?? R.path(['totals', 'colour'], scorecard),
        comment: report?.overrideComment,
      }
    : null;

  const override = report?.overriddenScore || report?.overriddenColour;
  const totalScore = total?.score ?? R.path(['totals', 'score'], scorecard);
  const scoreColour = total?.colour ?? R.path(['totals', 'colour'], scorecard);

  return (
    <Container
      onClick={onClick}
      className={className}
    >
      <Typography>Total Score:</Typography>
      <TotalScoreBox
        overridden={!!override}
        totalScore={totalScore}
        scoreColour={scoreColour || ''}
      />
    </Container>
  );
};

export default TotalScore;
