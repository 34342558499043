import React from 'react';
import Grid from '@material-ui/core/Grid';
import HighLevelDetails, { HighLevelDetail } from './HighLevelDetails';
import DetailHeader from './DetailHeader';
import TaskTitle from './TaskTitle';

const EcDetails = ({ record }) => (
  <Grid
    container
    direction="column"
    spacing={1}
  >
    <Grid item>
      <DetailHeader>EC #{record.workOrderNumber}</DetailHeader>
    </Grid>
    <Grid item>
      <TaskTitle>Title: {record.title}</TaskTitle>
    </Grid>
    <Grid item>
      <HighLevelDetails>
        <HighLevelDetail>{`Type: ${record.jpNumber}`}</HighLevelDetail>
        <HighLevelDetail>{`Sub-Type: ${record.modType}`}</HighLevelDetail>
        <HighLevelDetail>{`System: ${record.usi}`}</HighLevelDetail>
        <HighLevelDetail>{`Project Number: ${record.projectId}`}</HighLevelDetail>
      </HighLevelDetails>
    </Grid>
  </Grid>
);

export default EcDetails;
