import AssessingHeader from './components/AssessingHeader';
import { AssessingContextProvider } from '../hooks/useAssessing';
const AssessingPage = () => {
  return (
    <AssessingContextProvider>
      <AssessingHeader />
    </AssessingContextProvider>
  );
};
export default AssessingPage;
