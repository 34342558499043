import React, { useMemo } from 'react';
import * as R from 'ramda';
import OverlayPaper from '../../components/OverlayPaper/index';
import ExecutiveSummary from './ExecutiveSummary';
import CommentList from './CommentList';
import { ExecutiveSummaryUiProvider } from './hooks/executive-summary-ui';
import { Report } from '../../../../hooks/report';
import { getLastSavedTime } from '../../components/OverlayPaper/utils';

interface Props {
  className?: string;
  report: Report;
  comment?: any;
  onNext(): void;
}

const CommentOverlay = ({ className, report, comment, onNext }: Props) => {
  const isEmptyExecutiveSummary = R.either(R.isNil, R.isEmpty)(report.executiveSummary);
  const lastSavedTime = useMemo(() => getLastSavedTime(report, comment), [report, comment]);
  return (
    <OverlayPaper
      className={className}
      title="Executive Summary and Supporting Data"
      isDoneDisabled={isEmptyExecutiveSummary}
      onDone={onNext}
      statusText={`Last saved at: ${lastSavedTime}`}
    >
      <ExecutiveSummaryUiProvider report={report}>
        <ExecutiveSummary />
        <CommentList />
      </ExecutiveSummaryUiProvider>
    </OverlayPaper>
  );
};

export default CommentOverlay;
