import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as scorecardActions from './actions';

function* loadScorecard(action) {
  const { elementId, year, quarter } = action.payload;
  try {
    const scorecard = yield call(Api.getScorecard, elementId, year, quarter);
    yield put(scorecardActions.loadScorecardSuccess(elementId, year, quarter, scorecard));
  } catch (e) {
    yield put(
      scorecardActions.loadScorecardFailure(e, {
        elementId,
        year,
        quarter,
      })
    );
  }
}

function* loadOverride(action) {
  try {
    const { elementId, year, quarter, indicatorId, unitNumber } = action.payload;
    const overrideRecord = yield call(Api.getScorecardOverride, elementId, year, quarter, indicatorId, unitNumber);
    yield put(
      scorecardActions.loadOverrideSuccess({
        comment: overrideRecord.comment || '',
        elementId,
        year,
        quarter,
        indicatorId,
        unitNumber,
      })
    );
  } catch (e) {
    yield put(scorecardActions.loadOverrideFailure(e));
  }
}

function* overrideScore(action) {
  try {
    const { overrideRecord } = action.payload;
    yield call(
      Api.overrideScorecardScore,
      overrideRecord.elementId,
      overrideRecord.year,
      overrideRecord.quarter,
      overrideRecord.indicatorId,
      overrideRecord.unitNumber,
      overrideRecord.score,
      overrideRecord.comment
    );
    yield put(
      scorecardActions.loadScorecardRequest(overrideRecord.elementId, overrideRecord.year, overrideRecord.quarter)
    );
  } catch (e) {
    yield put(scorecardActions.overrideScoreFailure(e));
  }
}

function* resetOverrideScore(action) {
  const { elementId, year, quarter, indicatorId, unitNumber } = action.payload;
  try {
    yield call(Api.resetOverrideScore, elementId, year, quarter, indicatorId, unitNumber);
    yield put(scorecardActions.loadScorecardRequest(elementId, year, quarter));
  } catch (e) {
    yield put(scorecardActions.resetOverrideScoreFailure(e));
  }
}

function* setOverrideValue(action) {
  const { elementId, year, quarter, indicatorId, elementUnit, score, comment } = action.payload;
  try {
    yield call(Api.overrideScorecardScore, elementId, year, quarter, indicatorId, elementUnit, score, comment);
    // yield put(scorecardActions.setOverrideValueSuccess(elementId, year, quarter, updatedScorecard));
    yield put(scorecardActions.loadScorecardRequest(elementId, year, quarter));
  } catch (e) {
    yield put(scorecardActions.setOverrideValueFailure(e));
  }
}

function* setManualIndicatorValue(action) {
  try {
    const { elementId, year, quarter, indicatorId, elementUnit, value, colour, comment, updatedScorecard } =
      action.payload;
    yield call(Api.setCustomIndicatorValue, elementId, year, quarter, indicatorId, elementUnit, value, colour, comment);
    yield put(scorecardActions.setManualIndicatorValueSuccess(elementId, year, quarter, updatedScorecard));
  } catch (e) {
    yield put(scorecardActions.setManualIndicatorValueFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.SCORECARD_LOAD_REQUEST, loadScorecard);
  yield takeEvery(actionTypes.SCORECARD_LOAD_OVERRIDE_REQUEST, loadOverride);
  yield takeEvery(actionTypes.SCORECARD_OVERRIDE_SCORE_REQUEST, overrideScore);
  yield takeEvery(actionTypes.SCORECARD_RESET_OVERRIDE_SCORE_REQUEST, resetOverrideScore);
  yield takeEvery(actionTypes.OVERRIDE_VALUE_SET_REQUEST, setOverrideValue);
  yield takeEvery(actionTypes.MANUAL_INDICATOR_VALUES_SET_REQUEST, setManualIndicatorValue);
}

export default root;
