import CustomTextField, { CustomTextFieldProps } from './CustomTextField';

interface CustomNumberFieldProps extends Omit<CustomTextFieldProps, 'onChange' | 'type'> {
  onChange: (value: number | null) => void;
}

const CustomNumberField = ({ onChange, ...props }: CustomNumberFieldProps) => {
  return (
    <CustomTextField
      onChange={event => onChange(event.target.value ? Number(event.target.value) : null)}
      type="number"
      {...props}
    />
  );
};

export default CustomNumberField;
