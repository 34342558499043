import { OverviewSchedule } from '../../../types/cwt/overview';
import { Path, Remote } from '../../types';

const getOverview = (path: Path, remote: Remote) => (workorderId?: number) => remote.get(path(`/${workorderId}`));

const updateScheduleOverview = (path: Path, remote: Remote) => (workorderId: number, data: OverviewSchedule) =>
  remote.put(path(`/`), { ...data, workorderId });

const getWorkorderRelatedWOs = (path: Path, remote: Remote) => (location?: string) =>
  remote.get(path(`/wos/${location}`));

const overview = (path: Path, remote: Remote) => ({
  getOverview: getOverview(path, remote),
  updateScheduleOverview: updateScheduleOverview(path, remote),
  getWorkorderRelatedWOs: getWorkorderRelatedWOs(path, remote),
});

export default overview;
