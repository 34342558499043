import ReactDataGrid from '@inovua/reactdatagrid-community';
import { buildWorkRequestColumn, renderRelatedWorkOrders, renderFlipIcon } from './utils';
import { TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import useWorkRequests from '../../hooks/useWorkRequests';
import { ExtendedWorkRequest } from '../../../../types/cwt/workrequest';

const BASE_COLUMNS = [
  {
    name: 'workRequestNumber',
    header: 'WR',
  },
  {
    name: 'status',
    header: 'Status',
  },
  {
    name: 'reportDate',
    header: 'Original Date',
  },
  {
    name: 'title',
    header: 'Description',
    defaultFlex: 1,
  },
  {
    name: 'equipmentTag',
    header: 'EQ Tag',
  },
  {
    name: 'critCat',
    header: 'Crit Cat',
  },
  {
    name: 'location',
    header: 'Location',
  },
  {
    name: 'relatedWorkorders',
    header: 'Existing WO against Location',
    sortable: false,
    render: renderRelatedWorkOrders,
  },
].map(buildWorkRequestColumn);

const WorkRequestTable: React.FC = () => {
  const {
    state: currentTab,
    loading,
    workRequests,
    handleSelectForExport,
    handleSelectAll,
    selectedForExport,
  } = useWorkRequests();

  const [selectedWorkRequestNumber, setSelectedWorkRequestNumber] = useState<string | undefined>();

  const history = useHistory();

  // There's a bug with ReactDataGrid with onRowDoubleClick getting the wrong row data on scroll
  // https://github.com/inovua/reactdatagrid/issues/400
  const handleRowClick = (rowProps: TypeRowProps) => {
    if (rowProps.data.workRequestNumber) {
      setSelectedWorkRequestNumber(rowProps.data.workRequestNumber);
    }
  };

  const openScreeningForm = () => {
    history.push(`/cwt/wr/${selectedWorkRequestNumber}/screening`);
  };

  const areAllSelected = workRequests.every(workRequest => selectedForExport.includes(workRequest.workRequestNumber));

  const columns =
    currentTab === 'coded'
      ? [
          ...BASE_COLUMNS,
          {
            name: 'exportRow',
            header: (
              <label>
                Export
                <Checkbox
                  name="select-all"
                  aria-label="Select all"
                  checked={areAllSelected}
                  onChange={event => handleSelectAll(event.target.checked)}
                />
              </label>
            ),
            defaultWidth: 90,
            textAlign: 'center' as const,
            sortable: false,
            render: ({ data: { workRequestNumber } }: { data: ExtendedWorkRequest }) => {
              return (
                <Checkbox
                  aria-label={`Export WR ${workRequestNumber}`}
                  name={workRequestNumber}
                  checked={selectedForExport.includes(workRequestNumber)}
                  onChange={event => handleSelectForExport(workRequestNumber, event.target.checked)}
                />
              );
            },
          },
        ]
      : [
          ...BASE_COLUMNS,
          {
            name: 'screeningStatus',
            header: 'Flip',
            defaultWidth: 60,
            textAlign: 'center' as const,
            sortable: false,
            render: renderFlipIcon,
          },
        ];

  return (
    <ReactDataGrid
      idProperty="workRequestNumber"
      pagination
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={workRequests}
      showCellBorders={false}
      loading={loading}
      onRowClick={handleRowClick}
      onRowDoubleClick={openScreeningForm}
      defaultSortInfo={{ name: 'location', dir: 1 }}
    />
  );
};

export default WorkRequestTable;
