import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import Api from '../api';

const ScorecardSectionsContext = React.createContext();

const useScorecardSections = () => {
  const context = useContext(ScorecardSectionsContext);
  if (R.isNil(context)) {
    throw new Error('useScorecardSections must be used inside an ScorecardSectionsProvider');
  }
  return context;
};

export const ScorecardSectionsProvider = ({ elementId, ...props }) => {
  const [scorecardSections, setScorecardSections] = useState([]);

  useEffect(() => {
    Api.getScorecardSections(elementId).then(setScorecardSections);
  }, [elementId, setScorecardSections]);

  const value = {
    scorecardSections,
  };

  return (
    <ScorecardSectionsContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useScorecardSections;
