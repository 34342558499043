import { useReducer } from 'react';

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case 'sort': {
      const { sortColumn, sortOrder } = action.payload;
      return {
        ...state,
        sortColumn,
        sortOrder,
      };
    }
    default: {
      return state;
    }
  }
};

const useSort = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSort = (sortColumn, sortOrder) => {
    dispatch({
      type: 'sort',
      payload: {
        sortColumn,
        sortOrder,
      },
    });
  };

  return [state.sortColumn, state.sortOrder, setSort];
};

export default useSort;
