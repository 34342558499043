import { WalkdownTemplateTypeString } from '../../../../../types/walkdown-template';

const defaultType: WalkdownTemplateTypeString = 'innage';

export const defaultValues = {
  name: '',
  type: defaultType,
};

export const formConfig = {
  name: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },

  type: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },
};
