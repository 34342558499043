import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const PageTitle = styled(Typography).attrs({
  variant: 'h1',
})`
  font: ${({ theme }) => theme.fonts.medium16};
  text-transform: uppercase;
`;

export default PageTitle;
