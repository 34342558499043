import UnstyledBackdrop from '@material-ui/core/Backdrop';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const CircularProgress = styled(UnstyledCircularProgress)`
  margin: 20px;
`;

const Backdrop = styled(UnstyledBackdrop)`
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
  pointer-events: none;
  opacity: 050;
  transition: opacity 0.3s ease;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div``;

interface LoadingContainerProps extends React.PropsWithChildren {
  loading: boolean;
  indicatorSize?: number;
  className?: string;
  overlay?: boolean;
}

const LoadingContainer = ({
  children,
  loading,
  indicatorSize = 50,
  className,
  overlay = false,
}: LoadingContainerProps) => (
  <Container className={className}>
    {loading ? (
      <ProgressContainer>
        {overlay ? (
          <Backdrop open={loading}>
            <CircularProgress size={indicatorSize} />
          </Backdrop>
        ) : (
          <CircularProgress size={indicatorSize} />
        )}
      </ProgressContainer>
    ) : (
      children
    )}
  </Container>
);

export default LoadingContainer;
