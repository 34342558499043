import React, { useState, useCallback } from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import UserLinkList from './UserLinkList';
import UserSelectForm from './UserSelectForm';

const useAddToStakeholderGroup = (onSetStakeholderGroup: (userId: string) => void) => {
  const [addingToStakeholderGroup, setAddingToStakeholderGroup] = useState(false);

  const onAddToStakeholderGroup = useCallback(() => {
    setAddingToStakeholderGroup(true);
  }, [setAddingToStakeholderGroup]);

  const onConfirmAddToStakeholderGroup = useCallback(
    (userId: any) => {
      onSetStakeholderGroup(userId);
      setAddingToStakeholderGroup(false);
    },
    [onSetStakeholderGroup]
  );

  const onCancelAddToStakeholderGroup = useCallback(() => {
    setAddingToStakeholderGroup(false);
  }, [setAddingToStakeholderGroup]);

  return {
    addingToStakeholderGroup,
    onAddToStakeholderGroup,
    onConfirmAddToStakeholderGroup,
    onCancelAddToStakeholderGroup,
  };
};

interface Props {
  className?: string;
  stakeholderGroup?: any[];
  allUsers: any[];
  onSetStakeholderGroup: (userId: string) => void;
  onClearStakeholderGroup: (userId: string) => void;
}

const StakeholderGroup = ({
  className,
  stakeholderGroup,
  allUsers,
  onSetStakeholderGroup,
  onClearStakeholderGroup,
}: Props) => {
  const {
    addingToStakeholderGroup,
    onAddToStakeholderGroup,
    onConfirmAddToStakeholderGroup,
    onCancelAddToStakeholderGroup,
  } = useAddToStakeholderGroup(onSetStakeholderGroup);

  return (
    <Grid
      container
      spacing={2}
      className={className}
    >
      {!R.isEmpty(stakeholderGroup) && (
        <Grid
          item
          xs={12}
        >
          <UserLinkList
            users={stakeholderGroup}
            onDelete={onClearStakeholderGroup}
          />
        </Grid>
      )}
      {!addingToStakeholderGroup && (
        <Grid
          item
          xs={12}
        >
          <IconButton onClick={onAddToStakeholderGroup}>
            <AddIcon />
          </IconButton>
        </Grid>
      )}
      {addingToStakeholderGroup && (
        <Grid
          item
          xs={12}
        >
          <UserSelectForm
            title={className}
            users={allUsers}
            onSubmit={onConfirmAddToStakeholderGroup}
            onCancel={onCancelAddToStakeholderGroup}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default StakeholderGroup;
