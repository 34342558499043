import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as userActions from './actions';

function* loadAllUsers() {
  try {
    const users = yield call(Api.getAllUsers);
    yield put(userActions.loadAllSuccess(users));
  } catch (e) {
    yield put(userActions.loadFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.USERS_LOAD_ALL_REQUEST, loadAllUsers);
}

export default root;
