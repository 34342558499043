import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MuiTable from '@material-ui/core/Table';

const AlternatingHighlightsTable = styled(MuiTable)`
  .MuiTableCell-head {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }

  .MuiTableRow-root:nth-child(even) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

const Table = styled(({ tableElement = AlternatingHighlightsTable, ...props }) =>
  React.createElement(tableElement, R.omit(['padding'], props))
)`
  .MuiTableCell-head {
    text-transform: uppercase;
  }

  .MuiTableCell-head:first-child {
    ${({ padding }) => padding && `padding-left: ${padding}px;`}
  }
  .MuiTableCell-head:last-child {
    ${({ padding }) => padding && `padding-right: ${padding}px;`}
  }

  .MuiTableCell-body:first-child {
    ${({ padding }) => padding && `padding-left: ${padding}px;`}
  }
  .MuiTableCell-body:last-child {
    ${({ padding }) => padding && `padding-right: ${padding}px;`}
  }
`;

export default Table;
