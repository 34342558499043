import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadAllRequest = () => ({
  type: actionTypes.TOTAL_SCORE_COLOURS_LOAD_ALL_REQUEST,
});

export const loadAllSuccess = totalScoreColours => ({
  type: actionTypes.TOTAL_SCORE_COLOURS_LOAD_ALL_SUCCESS,
  payload: {
    totalScoreColours,
  },
});

export const loadAllFailure = errorAction(actionTypes.TOTAL_SCORE_COLOURS_LOAD_ALL_FAILURE);
