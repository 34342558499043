import moment from 'moment';
import { useEffect } from 'react';

const useDateInput = (
  value: string,
  defaultValue: string | undefined,
  onChange: (defaultValue: string | undefined) => void
) => {
  useEffect(() => {
    if ((!value || moment(defaultValue).isAfter(moment(value))) && typeof onChange === 'function') {
      onChange(defaultValue);
    }
  }, [defaultValue, value]);
};

export default useDateInput;

//This function handles the changing of the target date depending on the references and their scheduled due date, I tried useMemo as well as other ways to get the desired behaviour but this is all that worked
