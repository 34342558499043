import { Grid, Paper } from '@material-ui/core';
import styled from 'styled-components';
import Page from '../../../../components/Page';
import FilterButton from '../../../../components/FilterButton';
import { useState } from 'react';
import ActionButton from '../../../../components/ActionButton';
import { useHistory } from 'react-router-dom';
import { RiskTable } from '../../../../components/RiskTable';
import useRiskManagement from '../../../../context/risk-management';

type TabState = 'active' | 'archive';
const TAB_STATES: TabState[] = ['active', 'archive'];
const TITLES: Record<TabState, string> = {
  active: 'ACTIVE RISKS',
  archive: 'ARCHIVES',
};

const FilterGroup = styled(Grid)`
  ${FilterButton} {
    margin-top: 20px;
    margin-right: 16px;
  }
`;

const AddRiskButton = styled(ActionButton)`
  margin-top: 10px;
  width: 14%;
`;

const LandingPage = () => {
  const [currentTab, setCurrentTab] = useState<TabState>('active');
  const { push } = useHistory();
  const [elementId, setElementId] = useState<string>('');
  const { currentRiskManagementUserRoles } = useRiskManagement();

  return (
    <Page
      title="Business / Equipment Risk"
      bodyComponent={<Paper square />}
    >
      <FilterGroup>
        {TAB_STATES.map(tabState => (
          <FilterButton
            key={tabState}
            isActive={currentTab === tabState}
            onClick={() => setCurrentTab(tabState)}
          >
            {TITLES[tabState]}
          </FilterButton>
        ))}
      </FilterGroup>
      <AddRiskButton
        data-testid="btnAddRisk"
        color="primary"
        onClick={async () => {
          push('/app/business-equipment-risks/new');
        }}
      >
        Add New Risk
      </AddRiskButton>
      <RiskTable
        elementId={elementId}
        setElementId={setElementId}
        state={currentTab}
        currentRiskManagementUserRoles={currentRiskManagementUserRoles}
      />
    </Page>
  );
};
export default LandingPage;
