import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardsActions from '../state/dashboards/actions';
import * as dashboardsSelectors from '../state/dashboards/selectors';

const useMyDashboards = () => {
  const dispatch = useDispatch();
  const myDashboards = useSelector(dashboardsSelectors.myDashboards);

  useEffect(() => {
    dispatch(dashboardsActions.loadMyDashboardsRequest());
  }, [dispatch]);

  const addFavourite = useCallback(
    dashboardId => dispatch(dashboardsActions.addMyDashboardFavouriteRequest(dashboardId)),
    [dispatch]
  );

  const removeFavourite = useCallback(
    dashboardId => dispatch(dashboardsActions.removeMyDashboardFavouriteRequest(dashboardId)),
    [dispatch]
  );

  return {
    myDashboards,
    addFavourite,
    removeFavourite,
  };
};

export default useMyDashboards;
