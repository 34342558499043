import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { type Scorecard as IScorecard } from 'echo-scorecard';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useScorecard from '../../hooks/scorecard';
import { dateTimeToString } from '../../utils/date-format';
import UnstyledTotalScore from './TotalScore';
import UnstyledOutlineButton from '../OutlineButton';
import ManualValueDialog from './ManualValueDialog';
import TotalValueOverrideDialog from './TotalValueOverrideDialog';
import UnstyledScorecardTable from './ScorecardTable';
import useOverrideValue from '../../hooks/override-value';
import useEditManualIndicatorValue from '../../hooks/edit-manual-indicator-value';
import useEditManualTotalValue from '../../hooks/edit-manual-total-value';
import IndicatorDetailsDialog from './IndicatorDetailsDialog';
import useEditManualUnitTotalValue from '../../hooks/edit-manual-unit-total-value';
import { Report } from '../../hooks/report';
import ManualProgramIndicatorValueDialog from './ManualProgramIndicatorValueDialog';
import { MyElement } from '../../types/my';
import { ScorecardErrorText as ErrorMessage } from '../ErrorText';

const TotalScore = styled(UnstyledTotalScore)``;
const OutlineButton = styled(UnstyledOutlineButton)``;
const ScorecardTable = styled(UnstyledScorecardTable)``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  ${OutlineButton} {
    margin-left: 20px;
  }
`;

const LoadingOverlay = styled(CircularProgress)``;
const BackgroundLoadingOverlay = styled.div``;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  ${TotalScore} {
    align-self: flex-end;
    width: 200px;
  }

  ${ScorecardTable} {
    margin-bottom: 30px;
  }

  ${ButtonWrapper} {
    margin-bottom: 45px;
    ${OutlineButton} {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  ${LoadingOverlay} {
    position: absolute;
    top: 8px;
    left: 8px;
  }
  ${BackgroundLoadingOverlay} {
    padding-left: 32px;
  }
`;

const scoreValidator = {
  value: {
    validate: {
      numericality: {
        greaterThanOrEqualTo: 0,
        notGreaterThanOrEqualTo: 'Total Override Value must be greater than or equal to 0',
        lessThanOrEqualTo: 100,
        notLessThanOrEqualTo: 'Total Override Value must be less than or equal to 100',
      },
      presence: {
        allowEmpty: true,
        message: 'Enter a number',
      },
    },
  },
};

interface Props {
  report?: Report;
  className?: string;
  elementId: MyElement['elementId'];
  elementName: MyElement['elementName'];
  elementType?: string;
  facilityId: string;
  year: number;
  quarter: number;
  override?: boolean;
  isLive?: boolean;
}
type ScorecardInnerProps = Props & {
  children: ReactNode;
  scorecard: IScorecard;
};

const ScorecardInner = (props: ScorecardInnerProps) => {
  const {
    scorecard,
    children,
    report = {} as Report,
    elementId,
    elementType,
    facilityId,
    year,
    quarter,
    override = false,
    isLive = false,
  } = props;

  const overrideTotal: any = !R.isEmpty(report)
    ? {
        score: report?.overriddenScore ? report.overriddenScore : R.path(['totals', 'score'], scorecard),
        colour: report?.overriddenColour ? report.overriddenColour : R.path(['totals', 'colour'], scorecard),
        comment: report.overrideComment,
      }
    : null;
  const { overrideValue, editOverrideValue, cancelEditOverrideValue, saveOverrideValue, clearOverrideValue } =
    useOverrideValue(elementId, year, quarter, scorecard);
  const { manualIndicatorValue, editManualIndicatorValue, cancelEditManualIndicatorValue, saveManualIndicatorValue } =
    useEditManualIndicatorValue(elementId, year, quarter, scorecard);
  const {
    manualTotalValue,
    editManualTotalValue,
    cancelEditManualTotalValue,
    saveManualTotalValue,
    clearManualTotalValue,
  } = useEditManualTotalValue({ reportId: report.id, scorecard, overrideTotal });
  const {
    manualUnitTotalValue,
    editManualUnitTotalValue,
    cancelEditManualUnitTotalValue,
    saveManualUnitTotalValue,
    clearManualUnitTotalValue,
  } = useEditManualUnitTotalValue({ elementId, year, quarter, scorecard });

  return (
    <>
      {scorecard && (
        <>
          <TotalScore
            report={report}
            scorecard={scorecard}
            onClick={override ? editManualTotalValue : null}
          />
          <ScorecardTable
            scorecard={scorecard}
            editable
            onCalculatedScoreClicked={editOverrideValue}
            onManualScoreClicked={editManualIndicatorValue}
            onUnitTotalScoreClicked={override ? editManualUnitTotalValue : null}
          />
        </>
      )}
      {children}
      {overrideValue && (
        <IndicatorDetailsDialog
          overrideValue={overrideValue}
          facilityId={facilityId}
          elementId={elementId}
          elementType={elementType}
          year={year}
          quarter={quarter}
          onClear={clearOverrideValue}
          onCancel={cancelEditOverrideValue}
          onSave={saveOverrideValue}
          isLive={isLive}
        />
      )}
      {manualIndicatorValue && (report.elementType !== 'program' || elementType !== 'program') && (
        <ManualValueDialog
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={saveManualIndicatorValue}
        />
      )}
      {manualIndicatorValue && (report.elementType === 'program' || elementType === 'program') && (
        <ManualProgramIndicatorValueDialog
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={saveManualIndicatorValue}
          elementId={elementId}
        />
      )}
      {manualUnitTotalValue && override && (
        <ManualValueDialog
          title="Total Unit Value Override"
          validation={scoreValidator}
          manualValue={manualUnitTotalValue as any}
          onCancel={cancelEditManualUnitTotalValue}
          onSave={saveManualUnitTotalValue}
          onClear={clearManualUnitTotalValue}
        />
      )}
      {!R.isNil(manualTotalValue) && override && (
        <TotalValueOverrideDialog
          overrideValue={manualTotalValue}
          onCancel={cancelEditManualTotalValue}
          onSave={saveManualTotalValue}
          onClear={clearManualTotalValue}
        />
      )}
    </>
  );
};
type ScorecardDetailsProps = {
  isLoading: boolean;
  lastUpdatedAt: Date;
};
const ScorecardDetails = ({ isLoading, lastUpdatedAt }: ScorecardDetailsProps) => (
  <>
    {lastUpdatedAt && <Typography>Last Updated: {dateTimeToString(lastUpdatedAt)}</Typography>}
    {isLoading && <LoadingOverlay size={20} />}
  </>
);
const Scorecard = (props: Props) => {
  const { className, elementId, elementName, year, quarter } = props;
  const {
    scorecardDisplayable,
    scorecard: scorecardActual,
    scorecardErrorMessage,
    staleData: scordcardStaleDate,
    isLoading,
    lastUpdatedAt,
    isBackgroundLoading,
    BackgroundLoadingMessage,
  } = useScorecard({ elementName, elementId, year, quarter });
  const scorecard = scorecardDisplayable;

  return (
    <Container className={className}>
      {isBackgroundLoading && (
        <BackgroundLoadingOverlay>
          <BackgroundLoadingMessage />
        </BackgroundLoadingOverlay>
      )}
      {!isLoading && (
        <ErrorMessage
          scorecardDisplayable={scorecard}
          scorecard={scorecardActual}
          staleData={scordcardStaleDate}
          scorecardErrorMessage={scorecardErrorMessage}
        />
      )}
      {scorecard ? (
        <ScorecardInner
          {...props}
          scorecard={scorecard}
        >
          <ScorecardDetails
            isLoading={isLoading}
            lastUpdatedAt={lastUpdatedAt}
          />
        </ScorecardInner>
      ) : (
        <ScorecardDetails
          isLoading={isLoading}
          lastUpdatedAt={lastUpdatedAt}
        />
      )}
    </Container>
  );
};

export default Scorecard;
