import React from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker as UnstyledDatePicker } from '@material-ui/pickers';
import FormLabel from '../CustomFormLabel';
import useDateInput from './hooks/date-input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatePicker = styled(UnstyledDatePicker)`
  margin-top: 4px;
  padding-top: 0;
  &.MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.colours.teals.teal1};
  }
`;

const DatePickerHeaderText = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colours.teals.teal1};
  color: ${({ theme }) => theme.colours.white};
  font: ${({ theme }) => theme.fonts.medium16};

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  className?: string;
  label?: string;
  id?: string;
  value: any;
  defaultValue?: string;
  error?: any;
  helperText?: string;
  clearable?: boolean;
  headerText?: string;
  disablePast?: boolean;
  placeholder?: string;
  onChange(x: any): void;
}

const DateInput = ({
  className,
  label,
  id,
  value,
  error,
  helperText,
  clearable,
  headerText,
  disablePast,
  onChange,
  placeholder = 'Select Date',
  defaultValue,
}: Props) => {
  useDateInput(value, defaultValue, onChange);
  return (
    <Container className={className}>
      <FormLabel error={error}>{label}</FormLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {defaultValue ? (
          <DatePicker
            format="yyyy-MM-dd"
            margin="normal"
            id={`date-picker-inline-${id}`}
            value={value}
            error={error}
            helperText={helperText}
            clearable={clearable}
            disablePast={disablePast}
            onChange={onChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            ToolbarComponent={() => <DatePickerHeaderText>{headerText}</DatePickerHeaderText>}
          />
        ) : (
          <DatePicker
            label={placeholder}
            format="yyyy-MM-dd"
            margin="normal"
            id={`date-picker-inline-${id}`}
            value={value}
            error={error}
            helperText={helperText}
            clearable={clearable}
            disablePast={disablePast}
            onChange={onChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            ToolbarComponent={() => <DatePickerHeaderText>{headerText}</DatePickerHeaderText>}
          />
        )}
      </MuiPickersUtilsProvider>
    </Container>
  );
};
export default DateInput;
