import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledList from '@material-ui/core/List';
import useDownloadReport from '../../../../hooks/download-report';
import UnstyledReportItem from './ReportItem';
import NoRecords from '../../../../components/NoRecords';

const ReportItem = styled(UnstyledReportItem)``;

const List = styled(UnstyledList)`
  ${ReportItem}:nth-child(2n - 1) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

interface Props {
  reports: any;
  baseUrl?: string;
  onCloseReport?: any;
}

const ReportList = ({ reports, baseUrl, onCloseReport }: Props) => {
  const { downloadReport } = useDownloadReport();

  const items = reports.map((report: any) => (
    <ReportItem
      key={report.id}
      report={report}
      baseUrl={baseUrl}
      onClose={onCloseReport ? R.thunkify(onCloseReport)(report.id) : undefined}
      onDownload={report.state === 'approved' ? R.thunkify(downloadReport)(report) : undefined}
    />
  ));

  return reports.length > 0 ? (
    <div>
      <List>{items}</List>
    </div>
  ) : (
    <NoRecords />
  );
};

export default ReportList;
