import { Box } from '@material-ui/core';
import * as R from 'ramda';
import styled from 'styled-components';
import PowerBiReport from '../../../../../components/PowerBiReport';
import { WorkOrder } from '../../../../../types/cwt/workorder';
import SCHoldsTable from './components/SCHoldsTable';

const Container = styled(Box)`
  & > :last-child {
    margin-top: 1.5em;
  }
`;

interface SupplyChainTabProps {
  wo: WorkOrder;
}

const SupplyChainTab: React.FC<SupplyChainTabProps> = ({ wo }) => {
  const reportId = R.pathOr('', ['echoConfig', 'supplyChainPowerBiReportId'], window);
  const reportPageName = R.pathOr('', ['echoConfig', 'supplyChainPowerBiPageName'], window);
  return (
    <Container>
      {reportId && (
        <PowerBiReport
          title="Supply Chain Details"
          pageName={reportPageName}
          reportId={reportId}
          filter={`Material_Master/WOPARENT eq '${wo.wonum}'`}
          height="50vh"
          showActionBar
        />
      )}
      <SCHoldsTable />
    </Container>
  );
};

export default SupplyChainTab;
