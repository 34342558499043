import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import {
  CreateCategoryParams,
  InputTypeOption,
  WalkdownTemplate,
  WalkdownTemplateInputType,
  WalkdownTemplateTask,
  WalkdownTemplateTaskParams,
} from '../../../types/walkdown-template';

const WalkdownTemplateContext = createContext<{
  template: WalkdownTemplate;
  createCategory(t: CreateCategoryParams): Promise<void>;
  removeCategory(categoryId: number): Promise<void>;
  updateTemplate(updates: Partial<WalkdownTemplate>): Promise<void>;
  addTasks(categoryId: number, tasks: WalkdownTemplateTask[]): Promise<void>;
  addTask(categoryId: number, tasks: WalkdownTemplateTask): Promise<void>;
  deleteTask(categoryId: number, tasks: WalkdownTemplateTask): Promise<void>;
  inputTypes: InputTypeOption[];
  isLoading: boolean;
  updateTask(categoryId: any, task: WalkdownTemplateTask): Promise<void>;
}>(null as any);

interface ProviderProps {
  children: any;
  templateId: any;
}

export const WalkdownTemplateProvider = ({ children, templateId }: ProviderProps) => {
  const [template, setTemplate] = useState<WalkdownTemplate>(null as any);
  const [inputTypes, setInputTypes] = useState<InputTypeOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // fetch on load
  useEffect(() => {
    if (templateId) {
      setIsLoading(true);

      Api.getWalkdownTemplate(templateId)
        .then((tem: WalkdownTemplate) => {
          setTemplate(tem);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [templateId]);

  useEffect(() => {
    Api.getWalkdownInputTypes().then((vals: WalkdownTemplateInputType[]) => {
      setInputTypes(vals.map((r: any) => ({ ...r, value: r.id, label: r.measurementUnit })));
    });
  }, []);

  // update
  const updateTemplate = useCallback(
    async (updates: Partial<WalkdownTemplate>) => {
      await Api.updateWalkdownTemlplate(template?.id as number, updates);
      const updatedTemplate = await Api.getWalkdownTemplate(templateId);
      setTemplate(updatedTemplate);
    },
    [template]
  );

  // add category
  const createCategory = useCallback(
    async (t: CreateCategoryParams) => {
      await Api.createWalkdownTemplateCategory({ ...t, templateId: template.id });
      const updatedTemplate = await Api.getWalkdownTemplate(templateId);
      setTemplate(updatedTemplate);
    },
    [template]
  );

  const addTask = useCallback(
    async (categoryId: number, task: WalkdownTemplateTaskParams) => {
      await Api.addWalkdownTemplateTask(templateId, categoryId, task);
      const updatedTemplate = await Api.getWalkdownTemplate(templateId);
      setTemplate(updatedTemplate);
    },
    [templateId]
  );

  const deleteTask = useCallback(
    async (categoryId: number, task: WalkdownTemplateTask) => {
      await Api.removeWalkdownTemplateTask(templateId, categoryId, task);
      const updatedTemplate = await Api.getWalkdownTemplate(templateId);
      setTemplate(updatedTemplate);
    },
    [templateId]
  );

  // add task to category
  const addTasks = useCallback(
    async (categoryId: number, tasks: WalkdownTemplateTaskParams[]) => {
      await Promise.all(tasks.map(t => Api.addWalkdownTemplateTask(templateId, categoryId, t)));

      const updatedTemplate = await Api.getWalkdownTemplate(templateId);

      setTemplate(updatedTemplate);
    },
    [templateId, template]
  );

  const removeCategory = useCallback(
    async (categoryId: number) => {
      await Api.removeWalkdownTemplateCategory(template.id, categoryId);
      const updatedTemplate = await Api.getWalkdownTemplate(templateId);
      setTemplate(updatedTemplate);
    },
    [template]
  );

  const updateTask = useCallback(
    async (categoryId: number, task: WalkdownTemplateTask) => {
      if (template?.id) {
        await Api.patchWalkdownTemplateTask(template.id, categoryId, task);
        const updatedTemplate = await Api.getWalkdownTemplate(templateId);
        setTemplate(updatedTemplate);
      }
    },
    [template?.id]
  );

  const val = {
    template,
    createCategory,
    removeCategory,
    updateTemplate,
    addTasks,
    addTask,
    deleteTask,
    inputTypes,
    isLoading,
    updateTask,
  };

  return <WalkdownTemplateContext.Provider value={val}>{children}</WalkdownTemplateContext.Provider>;
};

const useWalkdownTemplate = () => {
  const val = useContext(WalkdownTemplateContext);
  if (!val) {
    throw new Error('useWalkdownTemplate must be used inside its provider!');
  }
  return val;
};

export default useWalkdownTemplate;
