import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import TableCell from '@material-ui/core/TableCell';
import CellText from './CellText';

const Container = styled(TableCell)`
  position: relative;
  border: none;
  overflow-wrap: break-word;
  padding-top: 14px;
  padding-bottom: 14px;
`;

const BodyCell = styled(({ className, action, column }) => (
  <Container
    key={column.id + action.id + 'container'}
    className={className}
    action={action}
    column={column}
    align={column.align}
    style={{ maxWidth: column.maxWidth }}
  >
    {R.has('render', column) ? column.render(action, column) : <CellText>{action[column.id]}</CellText>}
  </Container>
))``;

export default BodyCell;
