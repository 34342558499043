import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import Api from '../../../api';
import { AssessingTask } from '../../../types/cwt/assessing';

interface UseWorkorderTasksValue {
  tasks: AssessingTask[];
  fetchTasks: () => void;
  taskLoading: boolean;
  setTaskLoading: (loading: boolean) => void;
}

interface Props extends React.PropsWithChildren {
  wonum?: string;
}

const WorkorderTasksContext = createContext<UseWorkorderTasksValue | undefined>(undefined);

export const WorkorderTasksContextProvider = ({ children, wonum }: Props) => {
  const [tasks, setTasks] = useState<AssessingTask[]>([]);
  const [taskLoading, setTaskLoading] = useState<boolean>(true);

  const fetchTasks = () => {
    setTaskLoading(true);
  };

  useEffect(() => {
    const getTasks = async () => {
      if (wonum) {
        const res = await Api.cwt.getByWonumTasks(wonum);
        setTasks(res);
      }
      setTaskLoading(false);
    };
    if (taskLoading) {
      void getTasks();
    }
  }, [taskLoading]);

  const contextValue = useMemo(
    () => ({
      tasks,
      fetchTasks,
      taskLoading,
      setTaskLoading,
    }),
    [tasks, taskLoading]
  );

  return <WorkorderTasksContext.Provider value={contextValue}>{children}</WorkorderTasksContext.Provider>;
};

export const useWorkorderTasks = () => {
  const context = useContext(WorkorderTasksContext);
  if (context === undefined) {
    throw new Error('useWorkorderTasks must be used within a WorkorderTasksContextProvider');
  }
  return context;
};
