import { NoteInput } from '../../../types/cwt/workorder';
import { Path, Remote } from '../../types';

const getAllWorkorders =
  (path: Path, remote: Remote) =>
  (params: { manuallyAdded?: boolean; active?: boolean } = {}) =>
    remote.get(path(`/`), { params });
const removeManuallyAddedWorkorder = (path: Path, remote: Remote) => (id: string) => remote.del(path(`/${id}`));
const getWorkorder = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}`));
const getNewWorkorder = (path: Path, remote: Remote) => (wonum: string) => remote.get(path(`/${wonum}/verify`));
const insertNewWorkorder = (path: Path, remote: Remote) => (wonum: string) => remote.post(path(`/${wonum}`));
const getWorkorderNotes = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}/notes`));
const createWorkorderNote = (path: Path, remote: Remote) => (data: NoteInput) =>
  remote.post(path(`/${data.workorderId}/notes`), data);
const updateWorkorderNote = (path: Path, remote: Remote) => (id: number, data: NoteInput) =>
  remote.put(path(`/${data.workorderId}/notes/${id}`), data);
const getWorkorderEngHolds = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}/holds/eng`));
const getWorkorderSupplyChainHolds = (path: Path, remote: Remote) => (id: number) =>
  remote.get(path(`/${id}/holds/sc`));
const getWorkorderRelatedPms = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}/related-pms`));
const updateOverrides = (path: Path, remote: Remote) => (id: number, groupId: number, overrideValue: boolean | null) =>
  remote.put(path(`/${id}/overrides`), { workorderId: id, groupId: groupId, overrideValue: overrideValue });
const workorder = (path: Path, remote: Remote) => ({
  getAllWorkorders: getAllWorkorders(path, remote),
  getWorkorder: getWorkorder(path, remote),
  createWorkorderNote: createWorkorderNote(path, remote),
  updateWorkorderNote: updateWorkorderNote(path, remote),
  getWorkorderNotes: getWorkorderNotes(path, remote),
  getWorkorderEngHolds: getWorkorderEngHolds(path, remote),
  getWorkorderSupplyChainHolds: getWorkorderSupplyChainHolds(path, remote),
  getWorkorderRelatedPms: getWorkorderRelatedPms(path, remote),
  updateOverrides: updateOverrides(path, remote),
  getNewWorkorder: getNewWorkorder(path, remote),
  insertNewWorkorder: insertNewWorkorder(path, remote),
  removeManuallyAddedWorkorder: removeManuallyAddedWorkorder(path, remote),
});

export default workorder;
