import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import useElementIndicators from '../hooks/element-indicators';
import capitalize from '../utils/capitalize';
import useAction from '../hooks/action';
import { type ElmentIndicatorsContext } from '../hooks/element-indicators';

type ElementIndicatorsById = ElmentIndicatorsContext['elementIndicatorsById'];

const Summary = styled.div``;

const indicatorName = (indicatorId: string, indicatorsById: ElementIndicatorsById) =>
  R.pathOr('invalid indicator', [indicatorId, 'indicatorName'], indicatorsById);

const Unspecified = styled.span`
  font-style: italic;
`;

const Specified = styled.span`
  font-weight: bold;
`;

const ChangeType = ({ changeType }: { changeType: string }) =>
  R.isNil(changeType) ? (
    <Unspecified>(Unspecified change)</Unspecified>
  ) : (
    <Specified>{capitalize(changeType)}</Specified>
  );

const Indicator = ({ indicatorId, indicatorsById }: { indicatorId: string; indicatorsById: ElementIndicatorsById }) =>
  R.isNil(indicatorId) ? (
    <Unspecified>(unspecifed indicator)</Unspecified>
  ) : (
    <Specified>{indicatorName(indicatorId, indicatorsById)}</Specified>
  );

const Unit = ({ unit }: { unit: string }) =>
  R.isNil(unit) ? <Unspecified>(unspecified unit)</Unspecified> : <Specified>{unit}</Specified>;

const Amount = ({ amount }: { amount: number }) =>
  R.isNil(amount) ? <Unspecified>(unspecified amount)</Unspecified> : <Specified>{amount}</Specified>;

const ColourStatus = ({ status }: { status: string }) =>
  R.isNil(status) ? <Unspecified>(unspecified colour)</Unspecified> : <Specified>{capitalize(status)}</Specified>;

const ExpectedOutcomeSummary = ({
  className,
  expectedOutcome,
}: {
  className?: string;
  expectedOutcome: {
    changeType: string;
    indicatorId: string;
    elementUnit: string;
    amount: number;
    colourStatus: string;
  };
}) => {
  const { elementIndicatorsById } = useElementIndicators();
  const action = useAction();

  return (
    <Summary className={className}>
      {action.elementType !== 'program' && <ChangeType changeType={expectedOutcome.changeType} />}
      &nbsp;
      <Indicator
        indicatorId={expectedOutcome.indicatorId}
        indicatorsById={elementIndicatorsById}
      />
      {action.elementType === 'program' && (
        <>
          &nbsp;to&nbsp;
          <ColourStatus status={expectedOutcome.colourStatus} />
        </>
      )}
      &nbsp;for&nbsp;
      <Unit unit={expectedOutcome.elementUnit} />
      {action.elementType !== 'program' && (
        <>
          &nbsp;by&nbsp;
          <Amount amount={expectedOutcome.amount} />
        </>
      )}
    </Summary>
  );
};

export default ExpectedOutcomeSummary;
