import { Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const WideTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: '50vw',
  },
}))(Tooltip);

interface CWTToolTipProps {
  /**
   * Tooltip label text
   *
   * Optional. If omitted, `value` is used as label. */
  label?: React.ReactNode;
  value: NonNullable<React.ReactNode>;
  /** Label text size. Defaults to 'large'. */
  size?: 'small' | 'large';
}

const CWTToolTip = ({ label, value, size = 'large' }: CWTToolTipProps) => (
  <WideTooltip
    title={<Typography style={{ fontSize: size === 'large' ? '1.8em' : '1.3em' }}>{label ?? value}</Typography>}
  >
    {React.isValidElement(value) ? value : <p>{value}</p>}
  </WideTooltip>
);

export default CWTToolTip;
