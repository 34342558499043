import { useState, useCallback } from 'react';
import Api from '../api';
import { useDispatch } from 'react-redux';
import { error, genericMessage } from '../state/ui/actions';

const useAddListItem = (onAddItem: any, elementId: any) => {
  const [adding, setAdding] = useState(false);
  const dispatch = useDispatch();

  const add = useCallback(() => {
    setAdding(true);
  }, [setAdding]);

  const onAdd = useCallback(
    (...params: any) => {
      onAddItem(...params);
      setAdding(false);
    },
    [onAddItem, setAdding]
  );

  const onCheckAndAdd = useCallback(
    async (...params: any) => {
      const inputs = { ...params };
      if (inputs[0].typeId === 'capital-project' || inputs[0].typeId === 'other') {
        onAddItem({
          ...params[0],
          nameReference:
            inputs[0].typeId === 'capital-project'
              ? `Cap Proj ${inputs[0].referenceNumber}`
              : `Other ${inputs[0].referenceNumber}`,
        });
        setAdding(false);
        return;
      }
      const results = await Api.getSpecificAction(elementId, inputs[0].referenceNumber, inputs[0].typeId);
      if (results && results.length > 0) {
        onAddItem(results[0]);
        dispatch(genericMessage('Reference Added'));
      } else {
        dispatch(error(undefined, 'No results found'));
      }
      setAdding(false);
    },
    [onAddItem, setAdding, dispatch]
  );

  const onCancelAdd = useCallback(() => {
    setAdding(false);
  }, [setAdding]);

  return {
    adding,
    add,
    onAdd,
    onCancelAdd,
    onCheckAndAdd,
  };
};

export default useAddListItem;
