import FormDialog from '../../../../../components/FormDialog';
import useDeferralsRequests, { DeferralRequest } from '../../../../../hooks/deferral-requests';
import { MyRecentAssignmentsProvider } from '../../../../../hooks/my-recent-assignments';
import RequestDeferralForm from '../../request-page/components/RequestDeferralForm';

interface Props {
  request: DeferralRequest;
  onSave(r: DeferralRequest): void;
  onCancel(): void;
}

const RequestReopenDialog = ({ request, onSave, onCancel }: Props) => {
  const { setSelectedElement } = useDeferralsRequests();

  return (
    <FormDialog
      hideSave={true}
      title={'Update Deferral Request'}
      onCancel={onCancel}
      fullWidth
    >
      <MyRecentAssignmentsProvider>
        <RequestDeferralForm
          elementName={request.elementName}
          deferral={request}
          onSave={onSave}
          onSelectElement={setSelectedElement}
        />
      </MyRecentAssignmentsProvider>
    </FormDialog>
  );
};

export default RequestReopenDialog;
