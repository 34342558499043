import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as R from 'ramda';
import Api from '../api';

const AllElementsContext = React.createContext();

const useAllElements = () => {
  const context = useContext(AllElementsContext);
  if (R.isNil(context)) {
    throw new Error('useAllElements must be used inside an AllElementsProvider');
  }
  return context;
};

export const AllElementsProvider = props => {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    Api.getAllElements().then(setElements);
  }, [setElements]);

  const elementsById = useMemo(() => R.groupBy(R.prop('id'), elements), [elements]);

  const value = {
    elements,
    elementsById,
  };

  return (
    <AllElementsContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useAllElements;
