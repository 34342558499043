import { useEffect, useState } from 'react';
import Api from '../../../../api';
import { WorkRequestCoding } from '../types';

export const useWorkRequestCoding = (workRequestNumber: string) => {
  const [coding, setCoding] = useState<WorkRequestCoding | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchWorkRequest = async () => {
      try {
        const response = await Api.cwt.getWorkRequestCoding(workRequestNumber);
        setCoding(response);
      } catch (err) {
        console.error('Error fetching work request coding', err);
      } finally {
        setLoading(false);
      }
    };
    if (workRequestNumber && loading) {
      void fetchWorkRequest();
    }
  }, [loading, workRequestNumber]);

  const refetch = () => {
    setLoading(true);
  };

  // If work request number changes, trigger a refetch
  useEffect(() => {
    refetch();
  }, [workRequestNumber]);

  return { coding, loading, refetch };
};
