import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../api';
import isFeatureEnabled from '../../../utils/feature-flags';
import * as adminActions from '../state/actions';
import * as adminSelectors from '../state/selectors';

const useUserAssignments = user => {
  const dispatch = useDispatch();
  const [riskOwnerAssignments, setRiskOwnerAssignments] = useState([]);

  const loadRiskOwnerGroup = async () => {
    const result = await api.getRiskOwnersGroupByUserId(user.id);
    setRiskOwnerAssignments((result || []).map(r => ({ ...r, assignmentType: 'risk-owner' })));
  };

  useEffect(() => {
    dispatch(adminActions.loadUserAssignmentsRequest(user.id));
    loadRiskOwnerGroup();
  }, [dispatch, user]);

  const addUserAssignment = useCallback(
    async (elementId, assignmentType) => {
      if (assignmentType === 'risk-owner') {
        await api.setRiskOwner(elementId, user.id, user.name);
        loadRiskOwnerGroup();
      } else {
        dispatch(adminActions.addUserAssignmentRequest(user.id, elementId, assignmentType));
      }
    },
    [dispatch, user]
  );

  const deleteUserAssignment = useCallback(
    async (elementId, assignmentType) => {
      if (assignmentType === 'risk-owner') {
        await api.clearRiskOwner(elementId, user.id);
        loadRiskOwnerGroup();
      } else {
        dispatch(adminActions.deleteUserAssignmentRequest(user.id, elementId, assignmentType));
      }
    },
    [dispatch, user]
  );

  const rseAssignments = useSelector(state => adminSelectors.getUserAssignments(state, user.id));
  const userAssignments = isFeatureEnabled('riskOwnersGroup')
    ? [...rseAssignments, ...riskOwnerAssignments]
    : rseAssignments;

  return {
    userAssignments,
    addUserAssignment,
    deleteUserAssignment,
  };
};

export default useUserAssignments;
