import React from 'react';
import { Dialog, Typography } from '@material-ui/core';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import ActionButton from '../../../components/ActionButton';
import useForm from '../../../hooks/form';
import useReadOnly from '../../../hooks/read-only';

const StyledBody = styled.div`
  padding: 20px;
  .controls {
    padding-top: 10px;
    text-align: center;
  }
`;

interface Props {
  isOpen: boolean;
  setIsOpen(v: boolean): void;
  onSave(v: any): any;
  body?: string;
}

const TaskCommentDialog = ({ isOpen, setIsOpen, onSave, body }: Props) => {
  const { submit, propsForField } = useForm({ comment: {} }, { comment: body || '' }, onSave);
  const readOnly = useReadOnly();
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <StyledBody>
        <Typography>{body ? 'Edit Comment' : 'Add Comment'}</Typography>
        <TextField
          {...propsForField('comment')}
          multiline
          disabled={readOnly}
          rows={10}
          inputProps={{
            maxLength: 255,
          }}
          fullWidth
          variant="outlined"
        />
        <div className="controls">
          <ActionButton
            color="primary"
            onClick={() => {
              submit();
              setIsOpen(false);
            }}
          >
            Save
          </ActionButton>
        </div>
      </StyledBody>
    </Dialog>
  );
};

export default TaskCommentDialog;
