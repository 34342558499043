import React from 'react';
import useAttachmentsByQuarter from '../../hooks/report-attachments-by-quarter';
import { Downloader } from '../../hooks/downloader';
import AttachmentList from './AttachmentsList';

const AttachmentsContainer = ({ report }) => {
  const { attachments } = useAttachmentsByQuarter(report.elementId, report.year, report.quarter);
  return (
    <Downloader>
      <AttachmentList attachments={attachments} />
    </Downloader>
  );
};

export default AttachmentsContainer;
