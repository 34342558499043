import { Checkbox, IconButton, TableCell, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as R from 'ramda';
import { useState } from 'react';
import { WalkdownTemplateTask } from '../../../../types/walkdown-template';
import CreateTask from './CreateTask/CreateTask';

interface Props {
  setRemovingTask(t: WalkdownTemplateTask): void;
  task: WalkdownTemplateTask;
  onUpdateTask(task: WalkdownTemplateTask): any;
}

const TaskRow = ({ setRemovingTask, task, onUpdateTask }: Props) => {
  const [editing, setEditing] = useState(false);

  const measurementContent = task.isMeasurement
    ? `${R.isNil(task.min) ? '' : task.min} to ${R.isNil(task.max) ? '' : task.max}`
    : '';

  if (editing) {
    return (
      <CreateTask
        onCreate={t => {
          onUpdateTask(t);
          setEditing(false);
        }}
        overrideDefaultValues={task}
      />
    );
  }

  return (
    <TableRow key={task.id}>
      <TableCell>{task.title}</TableCell>
      <TableCell>{task.description}</TableCell>
      <TableCell align="center">
        <Checkbox
          checked={task.isMeasurement}
          readOnly
        />
      </TableCell>
      <TableCell align="center">{measurementContent}</TableCell>
      <TableCell align="center">{task.isMeasurement ? task.inputType : ''}</TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          onClick={() => setEditing(true)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => setRemovingTask(task)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TaskRow;
