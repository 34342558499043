import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { EchoElement } from '../../../hooks/elements';
import * as elementActions from '../../../state/elements/actions';

const useElementOverrideReportDueDate = (elementId: EchoElement['id']) => {
  const dispatch = useDispatch();

  const setOverrideReportDueDate = useCallback(
    (date: string) => {
      if (date) {
        dispatch(elementActions.setOverrideReportDueDateRequest(elementId, date));
      }
    },
    [dispatch, elementId]
  );

  return {
    setOverrideReportDueDate,
  };
};

export default useElementOverrideReportDueDate;
