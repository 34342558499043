import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import useForm from '../hooks/form';
import useConditionalConfirm from '../hooks/conditional-confirm';
import UnstyledFormDialog from './FormDialog';
import OkayCancelDialog from './OkayCancelDialog';
import UnstyledCustomTextField from './CustomTextField';

const CustomTextField = styled(UnstyledCustomTextField)``;

const Message = styled(Typography)``;

const FormDialog = styled(UnstyledFormDialog)`
  ${Message} {
    width: 496px;
    margin-bottom: 16px;
  }

  ${CustomTextField} {
    width: 496px;
  }
`;

const formConfig = commentRequired => ({
  comment: {
    validate: {
      presence: {
        allowEmpty: !commentRequired,
        message: 'Add comment',
      },
    },
  },
});

const initialValues = {
  comment: '',
};

const ReportStateChangeCommentDialog = ({ title, message, commentRequired, onSave, onCancel }) => {
  const handleSave = values => {
    onSave(values.comment);
  };

  const { submit, isDirty, propsForField } = useForm(formConfig(commentRequired), initialValues, handleSave);
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  return (
    <FormDialog
      title={title}
      onSave={submit}
      onCancel={confirmCancel}
    >
      {message && <Message>{message}</Message>}
      <CustomTextField
        label="Comments"
        multiline
        rows={16}
        {...propsForField('comment')}
      />
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          Are you sure? If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default ReportStateChangeCommentDialog;
