import React from 'react';
import styled from 'styled-components';
import UnstyledTableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import UnstyledTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import UnstyledHeaderCell from './HeaderCell';
import UnstyledRow from './Row';
import { SortableColumn } from './types';

const TableHead = styled(UnstyledTableHead)`
  border: none;
`;
const HeaderCell = styled(UnstyledHeaderCell)``;
const Row = styled(UnstyledRow)`
  &:nth-child(odd) {
    background-color: #d9d9d9;
  }
`;

const TableContainer = styled(UnstyledTableContainer)``;

interface Props {
  className?: string;
  records: any;
  columns: Array<SortableColumn>;
  indicatorRecordId(record: any): void;
  sortColumn: string;
  sortOrder: boolean;
  onSort(): void;
}

const SortableTable = ({ className, records, columns, indicatorRecordId, sortColumn, sortOrder, onSort }: Props) => {
  const rows = records.map((record: any, i: string) => (
    <Row
      key={`${i}|${indicatorRecordId(record)}`}
      record={record}
      columns={columns}
    />
  ));

  const columnHeaders = columns.map(column => (
    <HeaderCell
      key={column.id}
      column={column}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      onSort={onSort}
    />
  ));

  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow>{columnHeaders}</TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SortableTable;
