import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import MUITypography from '@material-ui/core/Typography';
import MUIListItem from '@material-ui/core/ListItem';

const Typography = styled(MUITypography)``;

const SectionName = styled(Typography)`
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme }) => theme.colours.greys.darkGrey2};
`;

const ListItemButton = styled(props => <MUIListItem {...R.omit(['selected'], props)} />).attrs({
  button: true,
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border: ${({ theme }) => theme.borders.thin.solid2};
  background-color: ${({ theme, selected }) => (selected ? theme.colours.greys.lightGrey3 : theme.colours.white)};
`;

const SectionItem = ({ title, onClick, selected }) => (
  <ListItemButton
    onClick={onClick}
    selected={selected}
  >
    <SectionName>{title}</SectionName>
  </ListItemButton>
);

export default SectionItem;
