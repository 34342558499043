import { Grid, Typography } from '@material-ui/core';
import ActionButton from '../../../../../components/ActionButton';
import ImpactProbabilityAssessmentTable from './Table';
import ImpactProbabilityAssessmentDialog from './Dialog';
import { useCallback, useState } from 'react';
import useRiskManagement from '../../../../../context/risk-management';
import ConfirmChangeDialog from './ConfirmChangeDialog';

interface Props {
  watchOpportunityOrThreat: string;
  isReadOnly: boolean;
}

export const ImpactProbabilityAssessment = ({ watchOpportunityOrThreat, isReadOnly }: Props) => {
  const {
    data,
    impactAssessments,
    confirmOpportunityOrThreatChange,
    handleConfirmOpportunityOrThreatChange,
    handleCancelOpportunityOrThreatChange,
    fetchImpactAssessments,
  } = useRiskManagement();
  const { riskId } = data;

  const [formState, setFormState] = useState<'edit' | 'add' | 'view' | 'none'>('none');
  const [editRow, setEditRow] = useState<number>(0);

  const handleOnSave = useCallback(async () => {
    setFormState('none');
    await fetchImpactAssessments();
  }, [riskId]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Typography>Annual Impact and Probability Assessment</Typography>
      </Grid>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
        >
          <ActionButton
            color="primary"
            onClick={() => {
              setFormState('add');
            }}
            disabled={isReadOnly}
          >
            Add New I&P
          </ActionButton>
        </Grid>
        {impactAssessments.length > 0 && (
          <ImpactProbabilityAssessmentTable
            impactAssessments={impactAssessments}
            setEditRow={(value: number) => {
              setFormState(isReadOnly ? 'view' : 'edit');
              setEditRow(value);
            }}
            isReadOnly={isReadOnly}
          />
        )}
      </Grid>

      {(formState === 'edit' || formState === 'add' || formState === 'view') && (
        <ImpactProbabilityAssessmentDialog
          formState={formState}
          editRow={editRow}
          watchOpportunityOrThreat={watchOpportunityOrThreat}
          onSave={handleOnSave}
          onCancel={() => {
            setFormState('none');
          }}
        />
      )}
      <ConfirmChangeDialog
        isOpen={confirmOpportunityOrThreatChange}
        onConfirm={handleConfirmOpportunityOrThreatChange}
        onCancel={handleCancelOpportunityOrThreatChange}
      />
    </>
  );
};
