import React from 'react';
import styled from 'styled-components';
import Select from '../../../../../components/Select';
import useWalkdownInputTypes from '../../../hooks/useWalkdownInputTypes';

interface Props {
  onChange: any;
  value: string;
}

const StyledSelect = styled(Select)`
  width: 120px;
`;

const UOMInputCell = ({ onChange, value }: Props) => {
  const { inputTypes } = useWalkdownInputTypes();

  return (
    <StyledSelect
      options={inputTypes}
      onChange={onChange}
      value={value}
    />
  );
};

export default UOMInputCell;
