import React, { useState, useEffect, createContext, useCallback } from 'react';
import api from '../api';
import { BusinessObjective } from '../types/business-objectives';

export const BusinessObjectivesContext = createContext<BusinessObjective[]>([]);

const BusinessObjectivesProvider = (props: React.PropsWithChildren) => {
  const [data, setData] = useState<BusinessObjective[]>([{ title: 'default', status: 'active' }]);

  const getBusinessObjectives = useCallback(async () => {
    try {
      const newObjectives = await api.getBusinessObjectives();
      if (newObjectives.length > 0) {
        setData(newObjectives);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    void getBusinessObjectives();
  }, []);

  return <BusinessObjectivesContext.Provider value={data}>{props.children}</BusinessObjectivesContext.Provider>;
};

export default BusinessObjectivesProvider;
