import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useUserAssignments from '../../hooks/user-assignments';
import AddUserForm from './AddUserForm';
import UserAssignmentsList from './UserAssignmentsList';

const useActivatedFunction = fn => {
  const [active, setActive] = useState(false);

  const activate = useCallback(() => {
    setActive(true);
  }, [setActive]);

  const finalize = useCallback(
    (...params) => {
      fn(...params);
      setActive(false);
    },
    [fn, setActive]
  );

  const cancel = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return {
    active,
    activate,
    finalize,
    cancel,
  };
};

const User = ({ user }) => {
  const { userAssignments, addUserAssignment, deleteUserAssignment } = useUserAssignments(user);
  const { active, activate, finalize, cancel } = useActivatedFunction(addUserAssignment);
  const groupedAssignments = userAssignments.reduce((acc, assignment) => {
    if (!acc[assignment.assignmentType]) {
      acc[assignment.assignmentType] = [];
    }
    acc[assignment.assignmentType].push(assignment);
    return acc;
  }, {});

  return (
    <Grid
      container
      style={{ padding: '12px' }}
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>{user.name}</Typography>
        <Typography variant="subtitle2">{user.email}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="button">Primary</Typography>
        <UserAssignmentsList
          userAssignments={groupedAssignments['primary'] || []}
          onDelete={deleteUserAssignment}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="button">Backup</Typography>
        <UserAssignmentsList
          userAssignments={groupedAssignments['backup'] || []}
          onDelete={deleteUserAssignment}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="button">Risk Owner</Typography>
        <UserAssignmentsList
          userAssignments={groupedAssignments['risk-owner'] || []}
          onDelete={deleteUserAssignment}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        {active ? (
          <AddUserForm
            onSubmit={finalize}
            onCancel={cancel}
          />
        ) : (
          <Button onClick={activate}>Add</Button>
        )}
      </Grid>
    </Grid>
  );
};

export default User;
