import React from 'react';

import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import DetailHeader from './DetailHeader';
import HighLevelDetails, { HighLevelDetail } from './HighLevelDetails';
import { dateToString } from '../../../../../../utils/date-format';

const Container = styled(Box)`
  border-bottom: ${({ theme }) => theme.borders.medium.solid2};
`;

const RecordApplicabilityComments = ({ record }: any) => (
  <Container p={5}>
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item>
        <DetailHeader>Comments</DetailHeader>
      </Grid>
      <Grid item>
        <HighLevelDetails>
          {record.applicabilityDescription && (
            <HighLevelDetail> Description: {record.applicabilityDescription}</HighLevelDetail>
          )}
          {record.forcedOutageStartDate && (
            <HighLevelDetail>{`Forced Outage Start Date:  ${
              record.forcedOutageStartDate ? dateToString(record.forcedOutageStartDate) : ''
            }`}</HighLevelDetail>
          )}
          {record.forcedOutageEndDate && (
            <HighLevelDetail>{`Forced Outage End Date:  ${
              record.forcedOutageEndDate ? dateToString(record.forcedOutageEndDate) : ''
            }`}</HighLevelDetail>
          )}
          {record.setbackEvent && (
            <HighLevelDetail>{`Setback Event: ${record.setbackEvent.toUpperCase() || 'RMI1'}`}</HighLevelDetail>
          )}
          {record.impairmentLevel && (
            <HighLevelDetail>{`Impairment Level: ${record.impairmentLevel.toUpperCase() || 'L1'}`}</HighLevelDetail>
          )}
          {record.reducedRedundancy && (
            <HighLevelDetail>{`Reduced Redundancy: ${record.reducedRedundancy
              .toString()
              .toUpperCase()}`}</HighLevelDetail>
          )}
          {record.repeatEventEfcNumber && (
            <HighLevelDetail>{`Repeat Event EFC Number: ${record.repeatEventEfcNumber || ''}`}</HighLevelDetail>
          )}
          {record.repeatEventArNumber && (
            <HighLevelDetail>{`Repeat Event AR Number: ${record.repeatEventArNumber || ''}`}</HighLevelDetail>
          )}
        </HighLevelDetails>
      </Grid>
    </Grid>
  </Container>
);

export default RecordApplicabilityComments;
