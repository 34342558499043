import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

const HeaderCell = styled(TableCell)`
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme }) => theme.colours.greys.darkGrey2};
  padding-top: 8px;
  padding-bottom: 7px;
  border: none;
  letter-spacing: 0;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow5};
`;

export default HeaderCell;
