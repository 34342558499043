import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TableContainer as UnstyledTableContainer,
} from '@material-ui/core';
import { Edit, Search } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Api from '../../../api';
import ActionButton from '../../../components/ActionButton';
import FormDialog from '../../../components/FormDialog';
import Table from '../../../components/Table';
import { Outage } from '../../../types/outages';
import { dateToString } from '../../../utils/date-format';
import OutageForm from '../forms/OutageForm';
import { durationDays } from '../utils/duration';
import ForcedOutageReport from './ForcedOutageReport';

const TabContainer = styled(Box)`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 12px;
  }
`;

const ContentContainer = styled(Box)<{ $grow?: boolean }>`
  & > * + * {
    margin-top: 12px;
  }

  ${props =>
    props.$grow &&
    css`
      flex-grow: 1;
    `}
`;

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 8px;
  }

  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 4px;
  }

  &.MuiFormControl-marginDense {
    margin: 0;
  }
`;

const TableContainer = styled(UnstyledTableContainer)`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;

  &.MuiTableContainer-root {
    width: auto;
    flex-grow: 1;
  }
`;

const ManagementTab: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [outages, setOutages] = useState<Outage[]>([]);
  const [selectedOutage, setSelectedOutage] = useState<Outage | undefined>();
  const [filter, setFilter] = useState<string | undefined>();

  const fetchOutages = async () => {
    try {
      const data = await Api.getOutages();
      setOutages(data);
    } catch (e) {
      console.error('Error fetching outage data', e);
    }
  };

  useEffect(() => {
    void fetchOutages();
  }, []);

  const filteredOutages = useMemo(() => {
    return outages.filter(outage => !filter || outage.outage_code.includes(filter));
  }, [filter, outages]);

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedOutage(undefined);
  };

  return (
    <TabContainer>
      {showDialog && (
        <FormDialog
          title={`${selectedOutage ? 'Edit' : 'Add'} Outage`}
          onCancel={handleCloseDialog}
          maxWidth="md"
          hideSave={true}
        >
          <OutageForm
            handleClose={() => {
              handleCloseDialog();
              // Re-fetch outage data after creating a new outage
              void fetchOutages();
            }}
            outage={selectedOutage}
          />
        </FormDialog>
      )}
      <ContentContainer>
        <ActionButton
          color="primary"
          onClick={() => setShowDialog(true)}
          style={{ marginBottom: '5px' }}
        >
          Add Outage
        </ActionButton>
        <ForcedOutageReport
          tab="management"
          width="500px"
          height="800px"
        />
      </ContentContainer>
      <ContentContainer $grow>
        <CustomTextField
          value={filter}
          onChange={event => setFilter(event.target.value)}
          variant="outlined"
          margin="dense"
          InputProps={{
            startAdornment: (
              <Search
                fontSize="small"
                color="action"
              />
            ),
          }}
          placeholder="Search by outage code"
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Outage</TableCell>
                <TableCell>Facility</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Duration (Days)</TableCell>
                <TableCell>{/** Empty header cell for action cell */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOutages.length > 0 &&
                filteredOutages.map(outage => (
                  <TableRow key={outage.outage_code}>
                    <TableCell>{outage.outage_code}</TableCell>
                    <TableCell>{outage.facility}</TableCell>
                    <TableCell>{outage.unit}</TableCell>
                    <TableCell>{dateToString(new Date(outage.start_date))}</TableCell>
                    <TableCell>{dateToString(new Date(outage.end_date))}</TableCell>
                    <TableCell>
                      {durationDays(new Date(outage.start_date), new Date(outage.end_date))?.toFixed(2) ?? 'N/A'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="Edit outage"
                        onClick={() => {
                          setSelectedOutage(outage);
                          setShowDialog(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentContainer>
    </TabContainer>
  );
};

export default ManagementTab;
