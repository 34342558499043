import React, { useState } from 'react';
import * as R from 'ramda';
import FilterPanel, { useFilterAllElements } from '../../../../../components/FilterPanel';
import ReportTable from './components/ReportTable';
import useReports from '../../../../../hooks/reports';
import useApproveReport from '../../../../../hooks/approve-report';
import useRejectReport from '../../../../../hooks/reject-report';
import { AllElementsProvider } from '../../../../../hooks/all-elements';
import useDataFilters from './hooks/data-filters';
import ReportStateChangeCommentDialog from '../../../../../components/ReportStateChangeCommentDialog';
import { Downloader } from '../../../../../hooks/downloader';

const LandingPage = () => {
  const [elementId, setElementId] = useState('');
  const { reports } = useReports(['pending-review'], true);
  const filteredReports = useDataFilters(reports, { elementId });
  const { approvingReport, confirmApproveReport, cancelApproveReport, approveReport } = useApproveReport(reports);
  const { rejectingReport, confirmRejectReport, cancelRejectReport, rejectReport } = useRejectReport(reports);

  const elementIds = R.compose(R.uniq, R.map(R.prop('elementId')))(reports);
  const { elementOptions } = useFilterAllElements(elementIds);

  return (
    <>
      <AllElementsProvider>
        <FilterPanel
          elementId={elementId}
          onChangeElement={setElementId}
          elementOptions={elementOptions}
        />
      </AllElementsProvider>
      <Downloader>
        <ReportTable
          reports={filteredReports}
          onAccept={confirmApproveReport}
          onReject={confirmRejectReport}
        />
      </Downloader>
      {approvingReport && (
        <ReportStateChangeCommentDialog
          title="Report Approved"
          commentRequired={false}
          onCancel={cancelApproveReport}
          onSave={approveReport}
        />
      )}
      {rejectingReport && (
        <ReportStateChangeCommentDialog
          title="Report Not Approved"
          commentRequired={true}
          onCancel={cancelRejectReport}
          onSave={rejectReport}
        />
      )}
    </>
  );
};

export default LandingPage;
