import { ControlAutocomplete, ControlAutocompleteProps } from '../../../../form/ControlAutocomplete';
import { ControlTextField, ControlTextFieldProps } from '../../../../form/ControlTextField';
import { ActionType, ReferenceFormInputs, ReferenceType } from '../../../../types/outages';

const ActionTypeInput: React.FC<
  Omit<ControlAutocompleteProps<ReferenceFormInputs, ActionType, false>, 'name' | 'label' | 'required'>
> = props => (
  <ControlAutocomplete
    name="actionType"
    label="Action type"
    required
    placeholder="Select action type"
    {...props}
  />
);

const OutageCodeInput: React.FC<
  Omit<ControlAutocompleteProps<ReferenceFormInputs, string, false>, 'name' | 'label' | 'required'>
> = props => (
  <ControlAutocomplete
    name="outageCode"
    label="Outage code"
    required
    placeholder="Select outage code"
    {...props}
  />
);

const ReferenceNumberOptionInput: React.FC<
  Omit<ControlAutocompleteProps<ReferenceFormInputs, string, false>, 'name' | 'label' | 'required'>
> = props => (
  <ControlAutocomplete
    name="referenceNumberOption"
    label="Reference #"
    required
    placeholder="Select option"
    {...props}
  />
);

const ReferenceNumberTextInput: React.FC<
  Omit<ControlTextFieldProps<ReferenceFormInputs, 'referenceNumberText'>, 'name' | 'label' | 'required'>
> = props => (
  <ControlTextField
    name="referenceNumberText"
    label="Reference #"
    maxLength={20}
    required
    {...props}
  />
);

const ReferenceTypeInput: React.FC<
  Omit<ControlAutocompleteProps<ReferenceFormInputs, ReferenceType, false>, 'name' | 'label' | 'required'>
> = props => (
  <ControlAutocomplete
    name="referenceType"
    label="Reference type"
    required
    placeholder="Select option"
    {...props}
  />
);

export const Inputs = {
  ActionType: ActionTypeInput,
  OutageCode: OutageCodeInput,
  ReferenceNumberOption: ReferenceNumberOptionInput,
  ReferenceNumberText: ReferenceNumberTextInput,
  ReferenceType: ReferenceTypeInput,
};
