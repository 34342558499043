import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Control, FieldErrors, SubmitHandler, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Api from '../../../api';
import { AutocompleteOption } from '../../../components/AutocompleteInput';
import useConfirm from '../../../hooks/confirm';
import * as uiActions from '../../../state/ui/actions';
import { ActionType, OutageReference, ReferenceFormInputs, ReferenceType } from '../../../types/outages';
import { ACTION_TYPE_OPTIONS, REFERENCE_TYPE_OPTIONS } from '../constants';
import { toOptions, toOutageCodeOptions } from '../utils/toOptions';

interface ReferenceForm {
  control: Control<ReferenceFormInputs>;
  handleSubmit: () => Promise<void>;
  errors: FieldErrors<ReferenceFormInputs>;
  actionTypeOptions: AutocompleteOption<ActionType>[];
  outageCodeOptions: AutocompleteOption[];
  referenceTypeOptions: AutocompleteOption<ReferenceType>[];
  referenceNumberOptions: AutocompleteOption[];
  onChangeOutageCode: (newValue: ReferenceFormInputs['outageCode']) => void;
  onChangeReferenceType: (newValue: ReferenceFormInputs['referenceType']) => void;
  onChangeReferenceNumberOption: (newValue: ReferenceFormInputs['referenceNumberOption']) => void;
  matchingReference: OutageReference | null;
  confirmingEdit: boolean;
  onConfirmEdit: (...args: any[]) => void;
  onCancelEdit: () => void;
  canDelete: boolean;
  onClickDelete: () => void;
  confirmingDelete: boolean;
  onConfirmDelete: () => void;
  onCancelDelete: () => void;
}

export const useEditReferenceForm = (handleClose: () => void): ReferenceForm => {
  const [outageCodeOptions, setOutageCodeOptions] = useState<AutocompleteOption[]>([]);
  const [referenceTypeOptions, setReferenceTypeOptions] = useState<AutocompleteOption<ReferenceType>[]>([]);
  const [referenceNumberOptions, setReferenceNumberOptions] = useState<AutocompleteOption[]>([]);
  const [existingReferences, setExistingReferences] = useState<OutageReference[]>([]);
  const [matchingReference, setMatchingReference] = useState<OutageReference | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    resetField,
    getValues,
  } = useFormContext<ReferenceFormInputs>();

  const dispatch = useDispatch();

  const handleUpdate = async () => {
    try {
      const { outageCode, actionType, referenceType, referenceNumberOption } = getValues();
      if (!outageCode || !actionType || !referenceType || !referenceNumberOption || !matchingReference) {
        return;
      }
      await Api.updateOutageReferenceActionType(outageCode, matchingReference.id, actionType);
      dispatch(uiActions.genericMessage('Reference updated!'));
      handleClose();
    } catch (error: any) {
      console.error(error);
      dispatch(
        uiActions.error(
          '',
          (error?.response?.status === 400 && error?.response?.data?.message) || `Error updating reference`
        )
      );
    }
  };

  const handleDelete = async () => {
    try {
      if (matchingReference) {
        await Api.deleteOutageReference(matchingReference.outage_code, matchingReference.id);
        dispatch(uiActions.genericMessage('Reference deleted!'));
        handleClose();
      }
    } catch (error: any) {
      console.error(error);
      dispatch(
        uiActions.error(
          '',
          (error?.response?.status === 400 && error?.response?.data?.message) || `Error deleting reference`
        )
      );
    }
  };

  const {
    confirm: confirmEdit,
    confirming: confirmingEdit,
    onConfirm: onConfirmEdit,
    onCancel: onCancelEdit,
  } = useConfirm(handleUpdate);

  const {
    confirm: confirmDelete,
    confirming: confirmingDelete,
    onConfirm: onConfirmDelete,
    onCancel: onCancelDelete,
  } = useConfirm(handleDelete);

  const onSubmit: SubmitHandler<ReferenceFormInputs> = async data => {
    try {
      const { outageCode, actionType, referenceType, referenceNumberOption } = data;
      if (!outageCode || !actionType || !referenceType || !referenceNumberOption || !matchingReference) {
        return;
      }
      if (
        outageCode !== matchingReference.outage_code ||
        actionType !== matchingReference.action_type ||
        referenceType !== matchingReference.reference_type ||
        referenceNumberOption !== matchingReference.reference_number
      ) {
        // Open confirmation dialog if user has made changes
        confirmEdit();
      } else {
        handleClose();
      }
    } catch (error: any) {
      console.error(error);
      dispatch(
        uiActions.error(
          '',
          (error?.response?.status === 400 && error?.response?.data?.message) || `Error updating reference`
        )
      );
    }
  };

  const fetchOutageReferences = async () => {
    const response = await Api.getOutageReferences();
    setExistingReferences(response);
    setOutageCodeOptions(toOutageCodeOptions(uniqBy(response, 'outage_code')));
  };

  useEffect(() => {
    // Fetch existing outage references
    void fetchOutageReferences();
  }, []);

  const onChangeOutageCode = useCallback(
    (outageCodeValue: ReferenceFormInputs['outageCode']) => {
      if (!outageCodeValue) {
        if (referenceTypeOptions.length) {
          // Reset available options if outage code is cleared
          setReferenceTypeOptions([]);
        }
      } else {
        // Filter reference types for current outage
        const allowedReferenceTypes = existingReferences
          .filter(({ outage_code }) => outage_code === outageCodeValue)
          .map(({ reference_type }) => reference_type);
        setReferenceTypeOptions(REFERENCE_TYPE_OPTIONS.filter(({ value }) => allowedReferenceTypes.includes(value)));
      }

      // Reset referenceType and referenceNumber if outageCode is changed
      resetField('referenceType');
      resetField('referenceNumberOption');
    },
    [existingReferences, referenceTypeOptions, resetField]
  );

  const onChangeReferenceType = useCallback(
    (referenceType: ReferenceFormInputs['referenceType']) => {
      if (!referenceType) {
        if (referenceNumberOptions.length) {
          // Reset available options if reference type is cleared
          setReferenceNumberOptions([]);
        }
      } else {
        // Filter reference numbers for current outage & reference type
        const outageCode = getValues().outageCode;
        const allowedReferences = existingReferences.filter(
          ({ outage_code, reference_type }) => outage_code === outageCode && reference_type === referenceType
        );
        setReferenceNumberOptions(toOptions(allowedReferences, 'reference_number'));
      }

      // Reset referenceNumberOption if referenceType is changed
      resetField('referenceNumberOption');
    },
    [existingReferences, getValues, referenceNumberOptions, resetField]
  );

  const onChangeReferenceNumberOption = useCallback(
    (referenceNumber: ReferenceFormInputs['referenceNumberOption']) => {
      if (!referenceNumber) {
        if (matchingReference) {
          // Reset matchingReference if reference number is cleared
          setMatchingReference(null);
        }
      } else {
        const { outageCode, referenceType } = getValues();
        const match = existingReferences.find(reference => {
          return (
            reference.outage_code === outageCode &&
            reference.reference_type === referenceType &&
            reference.reference_number === referenceNumber
          );
        });
        setMatchingReference(match || null);
      }
    },
    [existingReferences, getValues, matchingReference]
  );

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    actionTypeOptions: ACTION_TYPE_OPTIONS,
    outageCodeOptions,
    referenceTypeOptions,
    referenceNumberOptions,
    onChangeOutageCode,
    onChangeReferenceType,
    onChangeReferenceNumberOption,
    matchingReference,
    confirmingEdit,
    onConfirmEdit,
    onCancelEdit,
    canDelete: isValid,
    onClickDelete: confirmDelete,
    confirmingDelete,
    onConfirmDelete,
    onCancelDelete,
  };
};
