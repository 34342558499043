import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import CarlaText from '../../../../../../components/CarlaText';
import DialogButton from '../../../../../../components/DialogButton';
import CustomTextField from '../../../../../../components/CustomTextField';
import Select from '../../../../../../components/Select';
import { useCallback, useState } from 'react';
import api from '../../../../../../api';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewMSMProgramDashboard = ({ opened, setOpened }: Props) => {
  const [msmProgram, setMSMProgram] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();

  const handleAdd = useCallback(async () => {
    if (msmProgram && status) {
      await api.addMSMProgram(msmProgram, status);
      dispatch(uiActions.genericMessage('Added Succesfully'));
      setOpened(false);
    } else {
      dispatch(uiActions.error('', 'Please enter valid input'));
    }
  }, [msmProgram, status]);

  return (
    <>
      <Dialog
        open={opened}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Add new MSM Program</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <CarlaText>Enter MSM program and set status</CarlaText>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomTextField
                label="MSM Program"
                inputProps={{ maxLength: 256 }}
                onChange={e => setMSMProgram(e.target.value)}
              ></CustomTextField>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                label="Status"
                options={[
                  { label: 'Active', value: 'Active' },
                  { label: 'Non-Active', value: 'Non-Active' },
                ]}
                onChange={e => setStatus(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={() => setOpened(false)}>Cancel</DialogButton>
          <DialogButton
            color="primary"
            onClick={handleAdd}
          >
            Submit
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewMSMProgramDashboard;
