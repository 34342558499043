import { useCallback, useReducer, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import validate from 'validate.js';

validate.validators.nonEmptyArray = (value, options) => (R.isEmpty(value) ? options.message : null);

validate.validators.optionalDatetime = (value, options, key) => {
  if (R.isNil(value)) {
    return undefined;
  }
  return validate({ [key]: value }, { [key]: { datetime: options } }, { format: 'flat', fullMessages: false });
};

validate.extend(validate.validators.datetime, {
  parse: value => +moment.utc(value),
  format: value => moment.utc(value).format('YYYY-MM-DD'),
});

const gatherConstraints = R.compose(
  R.fromPairs,
  R.map(([name, descriptor]) => [name, descriptor.validate]),
  R.toPairs
);

const updateErrors = (config, values) => validate(values, gatherConstraints(config), { fullMessages: false });

const formReducer = config => (state, action) => {
  switch (action.type) {
    case 'submit': {
      return {
        ...state,
        submitted: true,
        errors: updateErrors(config, state.values),
      };
    }
    case 'changeField': {
      const { name, value } = action.payload;
      return {
        ...state,
        isDirty: true,
        values: {
          ...state.values,
          [name]: value,
        },
      };
    }
    case 'addListItem': {
      const { listName, value } = action.payload;
      return {
        ...state,
        isDirty: true,
        values: {
          ...state.values,
          [listName]: [...state.values[listName], value],
        },
      };
    }
    case 'updateListItem': {
      const { listName, index, value } = action.payload;
      return {
        ...state,
        isDirty: true,
        values: {
          ...state.values,
          [listName]: R.addIndex(R.map)((item, i) => (i === index ? value : item), state.values[listName]),
        },
      };
    }
    case 'setState': {
      return action.payload;
    }
    case 'removeListItem': {
      const { listName, index, id, referenceNumber } = action.payload;
      const updatedList = () => {
        if (id) {
          return R.reject(R.propEq('id', id), state.values[listName]);
        } else if (index) {
          return R.remove(index, 1, state.values[listName]);
        } else {
          return R.reject(R.propEq('referenceNumber', referenceNumber), state.values[listName]);
        }
      };
      return {
        ...state,
        isDirty: true,
        values: {
          ...state.values,
          [listName]: updatedList(),
        },
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = (config, initialValues) => ({
  submitted: false,
  isDirty: false,
  values: initialValues,
});

const isFieldDisabled = (config, name) => R.pathOr(false, [name, 'disabled'], config);

const useForm = (config, initialValues, onSubmit) => {
  const [state, dispatch] = useReducer(formReducer(config), initialState(config, initialValues));

  const isValid = R.isEmpty(state.errors);

  const submit = useCallback(() => {
    dispatch({ type: 'submit' });
  }, [dispatch]);

  const clear = () => {
    dispatch({
      type: 'setState',
      payload: initialState(config, initialValues),
    });
  };

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      submit();
    },
    [submit]
  );

  useEffect(() => {
    if (state.submitted && R.isNil(state.errors)) {
      onSubmit({
        ...state.values,
        references: state.values.references?.map(reference => ({
          id: reference.id,
          typeId: reference.typeId,
          referenceNumber: reference.referenceNumber,
        })),
      });
      dispatch({ type: 'setState', payload: { ...state, submitted: false } });
    }
  }, [state.submitted, state.errors]);

  const propsForForm = () => {
    return {
      onSubmit: handleSubmit,
    };
  };

  const setField = useCallback(
    (name, value) => {
      dispatch({ type: 'changeField', payload: { name, value } });
    },
    [dispatch]
  );

  const handleChangeField =
    name =>
    (...args) => {
      const valueFn = R.pathOr(R.path(['target', 'value']), [name, 'mapValueFn'], config);
      const value = valueFn(...args);
      setField(name, value);
    };

  const errorsFor = name => R.pathOr([], ['errors', name], state);

  const errorFor = name => R.head(errorsFor(name));

  const hasErrors = name => R.complement(R.isEmpty)(errorsFor(name));

  const propsForField = name => {
    if (!R.has(name, config)) {
      throw new Error(`propsForField called for nonexistent field "${name}"`);
    }

    const valueProp = R.pathOr('value', [name, 'valueProp'], config);

    return {
      id: `form_${name}`,
      name,
      [valueProp]: state.values[name],
      onChange: handleChangeField(name),
      ...(isFieldDisabled(config, name) ? { disabled: true } : {}),
      error: hasErrors(name),
      helperText: errorFor(name),
    };
  };

  const ensureFieldExists = (name, message) => {
    if (!R.has(name, config)) {
      throw new Error(message);
    }
  };

  const propsForFormControl = name => {
    ensureFieldExists(name, `propsForFormControl called for nonexistent field "${name}"`);

    return {
      error: hasErrors(name),
    };
  };

  const propsForSelect = name => {
    ensureFieldExists(name, `propsForFormControl called for nonexistent field "${name}"`);

    return {
      error: hasErrors(name),
    };
  };

  const propsForListField = listName => {
    if (!R.has(listName, config)) {
      throw new Error(`propsForListField called for nonexistent field "${listName}"`);
    }
    if (!R.has('list', config[listName])) {
      throw new Error(`propsForListField called for non-list field "${listName}"`);
    }

    const onAddItem = newItem => {
      dispatch({ type: 'addListItem', payload: { listName, value: newItem } });
    };

    const onUpdateItem = (index, updatedItem) => {
      dispatch({ type: 'updateListItem', payload: { listName, index, value: updatedItem } });
    };

    const onRemoveItem = (index, id, referenceNumber) => {
      dispatch({ type: 'removeListItem', payload: { listName, index, id, referenceNumber } });
    };

    return {
      values: state.values[listName],
      error: hasErrors(listName),
      helperText: errorFor(listName),
      onAddItem,
      onUpdateItem,
      onRemoveItem,
    };
  };

  return {
    values: state.values,
    setField,
    submit,
    isDirty: state.isDirty,
    isValid,
    propsForForm,
    propsForFormControl,
    propsForSelect,
    propsForField,
    propsForListField,
    errorFor,
    clear,
  };
};

export default useForm;
