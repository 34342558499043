import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputPanel from './InputPanel';
import RecordDetails from './RecordDetails';
import useIndicator from '../../../../../../hooks/indicator';
import useRecordDetails from '../../hooks/record-details';

interface Props {
  className?: string;
  record: any;
  onApply(x: any): void;
  onDismiss(): void;
}

const RecordDetailsPanel = ({ className, record, onApply, onDismiss }: Props) => {
  const { shortName } = useIndicator();
  const { recordDetails } = useRecordDetails(record, shortName);

  return (
    <Paper
      square
      className={className}
    >
      {record && (
        <>
          <InputPanel
            record={record}
            onApply={onApply}
            onDismiss={onDismiss}
          />
          <RecordDetails
            record={record}
            shortName={shortName}
            recordDetails={recordDetails}
          />
        </>
      )}
    </Paper>
  );
};

export default RecordDetailsPanel;
