import { createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { InputTypeOption, WalkdownTemplateInputType } from '../../../types/walkdown-template';

const WalkdownInputTypesContext = createContext<{
  inputTypes: InputTypeOption[];
}>(null as any);

interface ProviderProps {
  children: any;
}

export const WalkdownInputTypesProvider = ({ children }: ProviderProps) => {
  const [inputTypes, setInputTypes] = useState<InputTypeOption[]>([]);

  useEffect(() => {
    Api.getWalkdownInputTypes().then((vals: WalkdownTemplateInputType[]) => {
      setInputTypes(vals.map((r: any) => ({ ...r, value: r.id, label: r.measurementUnit })));
    });
  }, []);

  const val = {
    inputTypes,
  };

  return <WalkdownInputTypesContext.Provider value={val}>{children}</WalkdownInputTypesContext.Provider>;
};

const useWalkdownInputTypes = () => {
  const val = useContext(WalkdownInputTypesContext);
  if (!val) {
    throw new Error('useWalkdownInputTypes must be used inside its provider!');
  }
  return val;
};

export default useWalkdownInputTypes;
