import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import * as R from 'ramda';

import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import UnstyledIconButton from '@material-ui/core/IconButton';
import UnstyledClearIcon from '@material-ui/icons/Clear';
import UnstyledEditIcon from '@material-ui/icons/Edit';

import Table from '../../../../../components/Table';

import { dateToString } from '../../../../../utils/date-format';
import { DeferralRequest } from '../../../../../hooks/deferral-requests';

const IconButton = styled(UnstyledIconButton)``;

const ClearIcon = styled(UnstyledClearIcon)`
  color: ${({ theme }) => theme.colours.greys.grey1};
`;

const EditIcon = styled(UnstyledEditIcon)`
  color: ${({ theme }) => theme.colours.greys.grey1};
`;

interface Props {
  deferrals: DeferralRequest[];
  onOpen(deferral: DeferralRequest): void;
  onClose(id: number): void;
}

const DeferralsHistoryTable = ({ deferrals, onOpen, onClose }: Props) => {
  const rows = deferrals.map(deferral => (
    <TableRow key={deferral.id}>
      <TableCell>{`${deferral.elementName} (${deferral.facilityName})`}</TableCell>
      <TableCell align="center">{deferral.approverName}</TableCell>

      <TableCell align="center">{deferral.approvedState ? deferral.approvedState.toUpperCase() : 'N/A'}</TableCell>

      <TableCell align="center">
        {deferral.lastApprovedReportDate ? dateToString(new Date(deferral.lastApprovedReportDate)) : ''}
      </TableCell>
      <TableCell align="center">
        {deferral.originalNextReportDueDate ? dateToString(new Date(deferral.originalNextReportDueDate)) : ''}
      </TableCell>

      <TableCell align="center">{dateToString(moment(deferral.targetDate).utc())}</TableCell>

      <TableCell
        align="center"
        padding="none"
      >
        {(deferral.approvedState === 'rejected' || deferral.approvedState === 'pending') && (
          <IconButton
            size="small"
            onClick={R.thunkify(onOpen)(deferral)}
          >
            <EditIcon />
          </IconButton>
        )}
      </TableCell>

      <TableCell
        align="center"
        padding="none"
      >
        {deferral.approvedState !== 'approved' && (
          <IconButton
            size="small"
            onClick={R.thunkify(onClose)(deferral.id)}
          >
            <ClearIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  ));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Health Report Name</TableCell>
            <TableCell align="center">Approver Name</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Last Approved Report</TableCell>
            <TableCell align="center">Original Due Date</TableCell>
            <TableCell align="center">Deferral To</TableCell>
            <TableCell align="center">Update</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeferralsHistoryTable;
