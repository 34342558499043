import React from 'react';
import * as R from 'ramda';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DetailHeader from './DetailHeader';
import HighLevelDetails, { HighLevelDetail } from './HighLevelDetails';

import { dateToString } from '../../../../../../utils/date-format';

const crUrl = (number: any) =>
  `https://prod-maximo.corp.brucepower.com/maximo/ui/maximo.jsp?event=loadapp&value=pluswotr&uniqueid=${number}`;

const ERClockResets = ({ record }: any) => {
  const { crNumber, ticketuid, description, status, dueDate: dueDateString, owner, assetNumber, erClock } = record;

  const dueDate = new Date(dueDateString);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item>
        <Box p={5}>
          <Grid
            container
            direction="column"
            spacing={1}
          >
            <Grid item>
              <DetailHeader>
                {R.isNil(ticketuid) ? `CR #${crNumber}` : <a href={crUrl(ticketuid)}>{`CR #${crNumber}`}</a>}
              </DetailHeader>
            </Grid>
            <Grid item>
              <HighLevelDetails>
                <HighLevelDetail>Description: {description}</HighLevelDetail>
                <HighLevelDetail>Status: {status}</HighLevelDetail>
                <HighLevelDetail>Due Date: {dateToString(dueDate)}</HighLevelDetail>
                <HighLevelDetail>Owner: {owner}</HighLevelDetail>
                <HighLevelDetail>Asset Number: {assetNumber}</HighLevelDetail>
                <HighLevelDetail>ER Clock: {erClock}</HighLevelDetail>
              </HighLevelDetails>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ERClockResets;
