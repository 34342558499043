import elementTypePrefix from '../../utils/element-type-prefix';
import Panel from '../Panel';
import DropdownAutocomplete from '../../components/DropdownAutocomplete';
import { Grid } from '@material-ui/core';
import { MyElement } from '../../types/my';

interface Props {
  className?: string;
  elements: MyElement[];
  selectedElement?: MyElement;
  onChangeElement(id: string): void;
  children?: React.ReactNode;
}

const ElementFilterPanel = ({ className, elements, selectedElement, onChangeElement, children }: Props) => {
  const elementOptions = elements
    .filter(e => e.elementType !== 'program')
    .map(element => ({
      label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
      value: element.elementId,
    }));

  return (
    <Panel className={className}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginRight: 16 }}
        >
          <DropdownAutocomplete
            label="Element"
            value={selectedElement ? selectedElement.elementId : ''}
            onChange={onChangeElement}
            options={elementOptions}
          />
        </Grid>
        {children}
      </Grid>
    </Panel>
  );
};
export default ElementFilterPanel;
