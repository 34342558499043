import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import globalTheme from '../../../../theme';

const borderPath = s => ['borders', ...R.split('.', s)];

const BorderBox = styled(props => <Box {...R.omit(['path'], props)} />)`
  border: ${({ path, theme }) => R.path(borderPath(path), theme)};
  width: 200px;
  height: 128px;
`;

const BorderChipContainer = styled(Grid).attrs({
  item: true,
})`
  display: flex;
  flex-direction: column;
`;

const Section = ({ children, name }) => (
  <>
    <Typography variant="h4">{name}</Typography>
    <Grid
      container
      spacing={3}
    >
      {children}
    </Grid>
  </>
);

const BorderChip = ({ name, path }) => (
  <BorderChipContainer>
    <BorderBox path={path} />
    <Typography>{name}</Typography>
  </BorderChipContainer>
);

const section = (name, path) => ({
  name,
  path,
});

const sections = [section('Thin', 'thin'), section('Medium', 'medium'), section('Teal', 'teal')];

const Borders = () => {
  const chipsFromPath = path =>
    R.keys(R.path(borderPath(path), globalTheme)).map(name => (
      <BorderChip
        key={name}
        name={name}
        path={`${path}.${name}`}
      />
    ));

  const sectionItems = sections.map(({ name, path }) => (
    <Grid
      key={name}
      item
      xs={12}
    >
      <Section name={name}>{chipsFromPath(path)}</Section>
    </Grid>
  ));

  return <Grid container>{sectionItems}</Grid>;
};

export default Borders;
