import { useEffect, useCallback } from 'react';
import isFeatureEnabled from '../utils/feature-flags';
import { type DispatchedEvent, type ApiRequestRow, type HttpEventDetail } from './types';
import clientDb from './instance';

type RequestBody = ApiRequestRow['requestContext']['requestBody'];
type UriPath = ApiRequestRow['requestContext']['requestUrl'];
export type UseIndexedDbCacheParams = {
  userId: ApiRequestRow['requestContext']['userId'];
  // uriPath: UriPath | UriPath[]; // todo: expand to array!
  uriPath: UriPath;
  updateHttpAction?: (payload: HttpEventDetail) => void;
  removeHttpAction?: (payload: HttpEventDetail) => void;
  updatedCacheAction?: (payload?: ApiRequestRow) => void;
  removedCacheAction?: (payload?: ApiRequestRow) => void;
  cleanEventUrl?: (s: string) => string;
  httpResponseCompare?: (data: any | any[], cache: any | any[]) => boolean;
  transformRequestBody?: (data: any) => any | any[];
  // TODO detail 3rd param is incorrect but not used for now
  compareEventUrl?: (s: string, uriPath: string, details?: HttpEventDetail | DispatchedEvent) => string;
};
type UpdateCachePayload = {
  response: any | any[];
  requestUrl?: ApiRequestRow['requestContext']['requestUrl'];
  requestBody?: RequestBody;
};
const defaultCleanEventUrl = (s: string) => s;
const defaultTransformRequestBody = (body: any) => JSON.parse(body);
export const defaultHttpResponseCompare = (data: any | any[], cache: any | any[]) => {
  if (!data && !cache) {
    return true;
  }
  try {
    return JSON.stringify(data) === JSON.stringify(cache);
  } catch (e) {
    console.error(`Couldn't compare Objects`, e);
    return false;
  }
};
const emptyFunc = () => {
  return;
};
const defaultCompareEventUrl = (s: string, uriPath: string) => s.toLowerCase() === uriPath.toLowerCase();
const useIndexedDbCache = (cacheParams: UseIndexedDbCacheParams) => {
  const {
    uriPath,
    userId,
    httpResponseCompare = defaultHttpResponseCompare,
    transformRequestBody = defaultTransformRequestBody,
    updateHttpAction = emptyFunc,
    updatedCacheAction = emptyFunc,
    removedCacheAction = emptyFunc,
    removeHttpAction = emptyFunc,
    cleanEventUrl = defaultCleanEventUrl,
    compareEventUrl = defaultCompareEventUrl,
  } = cacheParams;

  const updateCache = useCallback(
    async (payload: UpdateCachePayload) => {
      const { response, requestBody } = payload;
      const requestUrl = cleanEventUrl(typeof payload.requestUrl === 'string' ? payload.requestUrl : uriPath);
      const keyPath = clientDb.generateRequestUrlSlug({ userId, requestUrl, requestBody });

      try {
        const result = await clientDb.getRow(keyPath);
        if (!result) {
          await clientDb.write({
            lastChecked: new Date(),
            lastUpdated: new Date(),
            requestContext: { requestUrl, requestBody, userId },
            response,
          });
          return;
        }
        await clientDb.update(keyPath, {
          ...result,
          lastChecked: new Date(),
          lastUpdated: new Date(),
          response,
        });
      } catch (error) {
        console.error(`Could not update the cache for key '${keyPath}': `, error);
        throw error;
      }
    },
    [cleanEventUrl, uriPath, userId]
  );
  const getCache = useCallback(
    async (requestBody?: RequestBody) => {
      try {
        const result = await clientDb.getRow(
          clientDb.generateRequestUrlSlug({ userId, requestUrl: uriPath, requestBody })
        );
        return result;
      } catch (error) {
        console.error(`Could not retrieve the cache for key '${uriPath}': `, error);
        throw error;
      }
    },
    [uriPath, userId]
  );
  const removeCache = useCallback(
    async (requestBody?: RequestBody) => {
      try {
        const keyPath = clientDb.generateRequestUrlSlug({ userId, requestUrl: uriPath, requestBody });
        const result = await clientDb.getRow(keyPath);
        if (!result) {
          return;
        }
        await clientDb.remove(keyPath);
        return result;
      } catch (error) {
        console.error(`Could not retrieve the cache for key '${uriPath}': `, error);
        throw error;
      }
    },
    [uriPath, userId]
  );
  useEffect(() => {
    if (!isFeatureEnabled('echoClientCaching')) {
      return;
    }
    let isUnmounting = false;
    let cacheAtBinding: ApiRequestRow['response'] | null = null;
    getCache()
      .then(dbRow => {
        if (dbRow?.response) {
          cacheAtBinding = dbRow.response as ApiRequestRow['response'];
        }
      })
      .catch(error => {
        console.error('Failed to Get DB-Row', error);
      });
    const writeEventHandler = (event: any) => {
      if (isUnmounting) {
        return;
      }
      const { detail = {} } = event;
      const {
        params,
        params: {
          requestContext: { requestUrl, requestBody },
        },
      } = detail as DispatchedEvent;
      const keyPath = clientDb.generateRequestUrlSlug({ userId, requestUrl, requestBody });
      if (!compareEventUrl(cleanEventUrl(requestUrl), uriPath, detail.params)) {
        return;
      }
      getCache()
        .then(dbRow =>
          clientDb
            .update(keyPath, { lastChecked: new Date() })
            .then(() => dbRow)
            .catch(error => {
              console.error('Failed to Update', error);
              return Promise.resolve(dbRow);
            })
        )
        .then(dbRow => {
          if (!dbRow || httpResponseCompare(params.response, cacheAtBinding)) {
            return;
          }
          cacheAtBinding = dbRow.response;
          return updatedCacheAction(dbRow);
        })
        .catch(console.error.bind(console, 'Failed to get from indexed-db'));
    };
    const removeEventHandler = (event: any) => {
      if (isUnmounting) {
        return;
      }
      const { detail = {} } = event;
      const {
        params: {
          requestContext: { requestUrl },
        },
      } = detail as DispatchedEvent;
      if (!compareEventUrl(cleanEventUrl(requestUrl), uriPath, detail)) {
        return;
      }
      removedCacheAction();
    };
    const httpReadEventHandler = (event: any) => {
      // read happened somewhere else in the app
      if (isUnmounting) {
        return;
      }
      const { detail = {} } = event;
      const { relativeUrl, requestBody: requestBodyRaw, data } = detail as HttpEventDetail;
      const requestBody: Record<string, any> | null = requestBodyRaw ? transformRequestBody(requestBodyRaw) : null;
      if (!compareEventUrl(cleanEventUrl(relativeUrl), uriPath, detail as HttpEventDetail)) {
        return;
      }

      getCache()
        .then(dbRow => {
          if (!dbRow || httpResponseCompare(detail.data, dbRow.response)) {
            return;
          }
          return updateCache({
            response: data,
            requestUrl: relativeUrl,
            ...(requestBody ? { requestBody } : {}),
          }).then(() => updateHttpAction(detail));
        })
        .catch(console.error.bind(console, 'Failed to get from indexed-db'));
    };
    const httpWriteEventHandler = (event: any) => {
      // write happened somewhere else in the app
      if (isUnmounting) {
        return;
      }
      const { detail = {} } = event;
      const { relativeUrl } = detail as HttpEventDetail;

      if (!compareEventUrl(cleanEventUrl(relativeUrl), uriPath, detail as HttpEventDetail)) {
        return;
      }

      removeCache();
      removeHttpAction(detail);
    };

    clientDb.subscribe('write', writeEventHandler);
    clientDb.subscribe('remove', removeEventHandler);
    clientDb.subscribe('httpWriteSuccess', httpWriteEventHandler);
    clientDb.subscribe('httpReadSuccess', httpReadEventHandler);
    return () => {
      isUnmounting = true;
      clientDb.unsubscribe('write', writeEventHandler);
      clientDb.unsubscribe('remove', removeEventHandler);
      clientDb.unsubscribe('httpWriteSuccess', httpWriteEventHandler);
      clientDb.unsubscribe('httpReadSuccess', httpReadEventHandler);
    };
  }, [
    uriPath,
    httpResponseCompare,
    updatedCacheAction,
    updateHttpAction,
    removeHttpAction,
    removedCacheAction,
    cleanEventUrl,
    compareEventUrl,
    transformRequestBody,
    removeCache,
    updateCache,
    getCache,
  ]);

  return {
    getCache,
    removeCache,
    updateCache,
  };
};

export default useIndexedDbCache;
