import { useCallback } from 'react';
import capitalize from '../utils/capitalize';
import Api from '../api';
import useDownloader from './downloader';

const reportFilename = report =>
  `${report.year} Q${report.quarter} ${capitalize(report.elementType)} Health Report for ${report.elementName}.pdf`;

const useDownloadReport = () => {
  const { onDownload } = useDownloader();

  const downloadReport = useCallback(
    report => {
      Api.downloadReport(report.reportId || report.id).then(blob => {
        onDownload(blob, reportFilename(report));
      });
    },
    [onDownload]
  );

  return {
    downloadReport,
  };
};

export default useDownloadReport;
