import { Grid } from '@material-ui/core';
import { useChangeTracking } from '../../hooks/useChangeTracking';
import ChangeTrackingFilters from './ChangeTrackingFilters';
import ChangeTrackingTable from './ChangeTrackingTable';
import ExportChangeTrackingButton from './ExportChangeTrackingButton';

const ChangeTrackingPage = () => {
  const { exportCSV, filteredLogs, loading, setGridRef, setFilters, filters, logs } = useChangeTracking();

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={11}
        alignItems="center"
        justifyContent="flex-start"
      >
        <ChangeTrackingFilters
          logs={logs}
          setFilters={setFilters}
          filters={filters}
        />
      </Grid>
      <Grid
        item
        xs={1}
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <ExportChangeTrackingButton exportCSV={exportCSV} />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ChangeTrackingTable
          filteredLogs={filteredLogs}
          loading={loading}
          setGridRef={setGridRef}
        />
      </Grid>
    </Grid>
  );
};

export default ChangeTrackingPage;
