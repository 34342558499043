import React, { useState } from 'react';
import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Page from '../../../../components/Page';
import useMCRUnits from '../../../../hooks/mcr-units';
import MCRUnit from '../../../../types/mcr-unit';
import UnitRow from './UnitRow';
import ExemptElementsList from './ExemptElementsList';
import { UpdateMCRExemptionProvider } from './hooks/useUpdateMCRExemption';

const MCRTable = styled.div`
  .header {
    display: flex;
    font-weight: bold;
    padding: 10px 10px;
  }

  .cell {
    padding: 0 20px;
    width: 150px;
  }

  .dateCell {
    width: 300px;
  }
`;

const ExemptElementsHeader = styled.div`
  display: flex;
  font-weight: bold;
  padding: 10px 10px;
`;

const MCRUnitsPage = () => {
  const { units, message } = useMCRUnits();
  const [open, setOpen] = useState(false);

  const handleSnackbar = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page title="Admin - MCR Units">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          {message}
        </Alert>
      </Snackbar>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <MCRTable>
          <div>
            <div className="header">
              <div className="cell">Unit</div>
              <div className="cell dateCell">MCR Start</div>
              <div className="cell dateCell">MCR End</div>
            </div>
            {units.map((u: MCRUnit) => (
              <UnitRow
                handleSnackbar={handleSnackbar}
                key={u.id}
                unit={u}
              />
            ))}
          </div>
        </MCRTable>
        <div>
          <ExemptElementsHeader>Exempt Elements</ExemptElementsHeader>
          <UpdateMCRExemptionProvider>
            <ExemptElementsList />
          </UpdateMCRExemptionProvider>
        </div>
      </div>
    </Page>
  );
};

export default MCRUnitsPage;
