import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import Api from '../api';

const IndicatorContext = React.createContext();

const useIndicator = () => {
  const context = useContext(IndicatorContext);
  if (R.isNil(context)) {
    throw new Error('useIndicator must be used inside an IndicatorProvider');
  }
  return context;
};

export const IndicatorProvider = ({ indicatorId, ...props }) => {
  const [indicator, setIndicator] = useState({});

  useEffect(() => {
    if (!R.isNil(indicatorId)) {
      Api.getIndicator(indicatorId).then(setIndicator);
    }
  }, [indicatorId, setIndicator]);

  return (
    <IndicatorContext.Provider
      value={indicator}
      {...props}
    />
  );
};

export default useIndicator;
