import React from 'react';
import styled from 'styled-components';
import UnstyledList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UnstyledForumIcon from '@material-ui/icons/Forum';
import { Grid, Link } from '@material-ui/core';
import UnstyledNavItem from './NavItem';
import UnstyledSubMenuItem from './SubMenuItem';
import ActionItem from './ActionItem';

const NavItem = styled(UnstyledNavItem)``;
const SubMenuItem = styled(UnstyledSubMenuItem)``;

const ForumIcon = styled(UnstyledForumIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const FormLink = styled(Link)`
  color: ${({ theme }) => theme.colours.white};
`;
const List = styled(UnstyledList)`
  color: ${({ theme }) => theme.colours.menu.text};
  padding-top: 0px;
`;

const entryToItem = (entry, i) => {
  switch (entry.type) {
    case 'subMenu': {
      return (
        <SubMenuItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
          entries={entry.children}
          isSelected={location.pathname.includes(entry.to)}
        />
      );
    }
    case 'nav': {
      return (
        <NavItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
        />
      );
    }
    case 'action': {
      return (
        <ActionItem
          key={`${i}:${entry.label}`}
          onClick={entry.action}
          iconComponent={entry.icon}
          label={entry.label}
        />
      );
    }
    default: {
      return (
        <ListItem key={`${i}:${entry.type}`}>
          <ListItemText>Type ({entry.type}) Unknown</ListItemText>
        </ListItem>
      );
    }
  }
};

const MenuList = ({ entries }) => {
  const items = entries.map(entryToItem);

  return (
    <List component="nav">
      {items}
      <Grid
        container
        style={{ height: '2.5em', marginLeft: '18%', marginTop: '3%' }}
      >
        <div
          href="https://www.npx-forum.ca"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#ffffff', textDecoration: 'none', font: '500 14px Roboto' }}
        >
          <FormLink>
            <ListItem>
              <ForumIcon style={{ display: 'tableCell', verticalAlign: 'middle' }} />
              <div style={{ marginLeft: '1.2em' }}>Forum</div>
            </ListItem>
          </FormLink>
        </div>
      </Grid>
    </List>
  );
};

export default MenuList;
