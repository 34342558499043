import UnstyledPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Page from '../../../../components/Page';
import useWorkRequests from '../../hooks/useWorkRequests';
import FilterButtons from '../components/FilterButtons';
import WorkRequestTable from '../components/WorkRequestTable';
import { Box } from '@material-ui/core';
import { ExportButton } from '../components/ExportButton';
import { useEffect } from 'react';

const Paper = styled(UnstyledPaper)`
  min-height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  padding-top: 16px;
`;

const WorkRequestPage = () => {
  const { state: currentTab, setState: setCurrentTab } = useWorkRequests();

  // If no tab is already selected, use "requiringCoding" by default
  useEffect(() => {
    if (!currentTab) {
      setCurrentTab('requiringCoding');
    }
  }, [currentTab]);

  return (
    <Page
      title="Work Request Screening"
      bodyComponent={
        <Paper
          square
          elevation={0}
        />
      }
    >
      <ButtonContainer>
        <FilterButtons
          currentTab={currentTab}
          onChangeTab={setCurrentTab}
        />
      </ButtonContainer>
      <Panel role="tabpanel">
        <Box
          display="flex"
          justifyContent="flex-end"
          height={48}
        >
          {currentTab === 'coded' && <ExportButton />}
        </Box>
        <WorkRequestTable />
      </Panel>
    </Page>
  );
};

export default WorkRequestPage;
