import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as indicatorActions from './actions';

function* loadAllIndicators() {
  try {
    const indicators = yield call(Api.getAllIndicators);
    yield put(indicatorActions.loadAllSuccess(indicators));
  } catch (e) {
    yield put(indicatorActions.loadAllFailure(e));
  }
}

function* loadReportIndicators(action) {
  const { type } = action.payload;
  try {
    const indicators = yield call(Api.getReportIndicators, type);
    yield put(indicatorActions.loadReportIndicatorsSuccess(type, indicators));
  } catch (e) {
    yield put(indicatorActions.loadReportIndicatorsFailure(e, { type }));
  }
}

function* root() {
  yield takeEvery(actionTypes.INDICATORS_LOAD_ALL_REQUEST, loadAllIndicators);
  yield takeEvery(actionTypes.INDICATORS_LOAD_REPORT_INDICATORS_REQUEST, loadReportIndicators);
}

export default root;
