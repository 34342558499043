import { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import * as actionActions from '../state/actions/actions';
import * as actionSelectors from '../state/actions/selectors';
import { type ActionSource, type ActionState, type ElementAction } from '../types/actions';

interface ActionsReturn {
  actions: ElementAction[];
  isLoading: boolean;
}

const useActions = (
  elementId: string | number,
  actionState: ActionState,
  actionSource: ActionSource = 'All'
): ActionsReturn => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!R.isEmpty(elementId) && !R.isNil(elementId)) {
      dispatch(actionActions.loadActionsRequest(elementId, actionState));
    }
  }, [dispatch, elementId, actionState]);

  const allActions = useSelector(state => actionSelectors.getActions(state, elementId, actionState)) as ElementAction[];
  const isLoading = useSelector(state => actionSelectors.getActionsIsLoading(state, elementId, actionState));

  const actions = useMemo(
    () => allActions.filter(action => action.source === actionSource || actionSource === 'All'),
    [allActions, actionSource]
  );

  return {
    actions,
    isLoading,
  };
};

export default useActions;
