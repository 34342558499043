import React from 'react';
import styled from 'styled-components';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';
import ScorecardTable from '../Scorecard/ScorecardTable';
import { Report } from '../../hooks/report';
import useScorecard from '../../hooks/scorecard';
import { type ScorecardErrorMessage, type ScorecardStaleData } from '../../hooks/scorecard/types';
import { ScorecardSectionsProvider } from '../../hooks/scorecard-sections';
import TotalScore from '../Scorecard/TotalScore';
import { ScorecardErrorText as ErrorMessage } from '../ErrorText';
import { Scorecard } from '../types/scorecard';
import { ElementId } from '../../types/app';

const CircularProgress = styled(UnstyledCircularProgress)``;
const BackgroundLoadingOverlay = styled.div``;

const Container = styled.div`
  ${CircularProgress} {
    margin-top: 20px;
  }
`;

interface SummaryScorecardProps {
  className?: string;
  report: Report;
}
type SummaryScorecardInnerProps = {
  elementId: ElementId;
  report: Report;
  scorecard: Scorecard;
  staleData: ScorecardStaleData;
  scorecardErrorMessage: ScorecardErrorMessage;
  scorecardDisplayable: Scorecard;
};
const SummaryScorecardInner = ({
  elementId,
  report,
  scorecardDisplayable,
  scorecard,
  staleData,
  scorecardErrorMessage,
}: SummaryScorecardInnerProps) => (
  <ScorecardSectionsProvider elementId={elementId}>
    <>
      {scorecardErrorMessage ? (
        <ErrorMessage
          scorecardDisplayable={scorecardDisplayable}
          scorecard={scorecard}
          staleData={staleData}
          scorecardErrorMessage={scorecardErrorMessage}
        />
      ) : null}
      <ScorecardTable scorecard={scorecardDisplayable} />
      <TotalScore
        report={report}
        scorecard={scorecardDisplayable}
        onClick={null}
      />
    </>
  </ScorecardSectionsProvider>
);
const SummaryScorecard: React.FC<SummaryScorecardProps> = ({
  className,
  report,
  report: { elementId, elementName, year, quarter },
}) => {
  const {
    scorecardDisplayable,
    scorecard,
    scorecardErrorMessage,
    staleData,
    isLoading,
    isBackgroundLoading,
    BackgroundLoadingMessage,
  } = useScorecard({
    elementName,
    elementId,
    year: Number(year),
    quarter: Number(quarter),
  });
  const isNotDisplayable = !scorecardDisplayable && !isLoading;
  if (isNotDisplayable && typeof scorecardErrorMessage === 'string') {
    return (
      <ErrorMessage
        scorecardDisplayable={scorecardDisplayable}
        scorecard={scorecard}
        staleData={staleData}
        scorecardErrorMessage={scorecardErrorMessage}
      />
    );
  }
  return (
    <Container className={className}>
      {isBackgroundLoading && (
        <BackgroundLoadingOverlay>
          <BackgroundLoadingMessage />
        </BackgroundLoadingOverlay>
      )}
      {scorecardDisplayable ? (
        <SummaryScorecardInner
          elementId={elementId}
          scorecardDisplayable={scorecardDisplayable}
          staleData={staleData}
          scorecard={scorecard}
          scorecardErrorMessage={scorecardErrorMessage}
          report={report}
        />
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
};

export default SummaryScorecard;
