import React from 'react';
import styled from 'styled-components';
import MUITypography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Scorecard from '../../../../components/Scorecard/Scorecard';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import NavPaper from '../../components/NavPaper';

const Typography = styled(MUITypography)`
  font: ${({ theme }) => theme.fonts.bold16};
  text-transform: uppercase;
`;

interface Props {
  report: any;
  onNext(): void;
  onBack(): void;
}

const ScorecardPage = ({ report, onNext, onBack }: Props) => (
  <Grid container>
    <Grid
      item
      xs={12}
    >
      <NavPaper
        onNext={onNext}
        onBack={onBack}
      >
        <Typography>SCORECARD</Typography>
      </NavPaper>
    </Grid>
    <Grid
      item
      xs={12}
    >
      {report.elementId && (
        <ElementIndicatorsProvider elementId={report.elementId}>
          <Scorecard
            facilityId={report.facilityId}
            override={true}
            report={report}
            elementId={report.elementId}
            elementName={report.elementName}
            elementType={report.elementType}
            year={report.year}
            quarter={report.quarter}
          />
        </ElementIndicatorsProvider>
      )}
    </Grid>
  </Grid>
);

export default ScorecardPage;
