import React, { useEffect } from 'react';
import * as R from 'ramda';
import useElementIndicators from '../../../../hooks/element-indicators';
import FilterSelector from '../../../../components/FilterSelector';

const IndicatorFilter = ({ indicatorId, onChangeIndicator }) => {
  const { elementIndicators } = useElementIndicators();
  const calculatedIndicators = R.filter(R.propEq('indicatorType', 'calculated'), elementIndicators);
  // NOTE: This component indicator is invalid and should not be applied/unapplied
  const options = calculatedIndicators
    .filter(elementIndicator => elementIndicator.indicatorShortName !== 'comp-component-health-effectiveness')
    .map(elementIndicator => ({ value: elementIndicator.indicatorId, name: elementIndicator.indicatorName }));

  useEffect(() => {
    if (R.isNil(indicatorId) && !R.isEmpty(calculatedIndicators)) {
      onChangeIndicator(R.head(calculatedIndicators).indicatorId);
    }
  }, [indicatorId, calculatedIndicators, onChangeIndicator]);

  return (
    <FilterSelector
      value={indicatorId}
      options={options}
      onChange={onChangeIndicator}
      name="Indicator"
      disableEmpty
    />
  );
};

export default IndicatorFilter;
