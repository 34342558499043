import * as R from 'ramda';
import useIndicatorRecordId from './indicator-record-id';

const useReviewRecordFinder = (records, indicatorId) => {
  const indicatorRecordId = useIndicatorRecordId(indicatorId);

  const findRecord = recordId => R.find(record => indicatorRecordId(record) === recordId, records);

  return findRecord;
};

export default useReviewRecordFinder;
