import { useContext, useState, useEffect, createContext, useCallback } from 'react';
import api from '../api';
import MCRPatchParams from '../types/mcr-patch-params';
import MCRUnit from '../types/mcr-unit';

// context
const MCRUnitsContext = createContext<any>(null);

// provider
export const MCRUnitsProvider = ({ children }: any) => {
  const [units, setUnits] = useState<MCRUnit[]>([]);
  let updates: any = [];
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    api.getMCRUnits().then((data: any) => {
      setUnits(data);
      setIsLoading(false);
    });
  }, []);

  const addUpdate = useCallback(
    (upd: MCRPatchParams) => {
      // Log the update to be sent to the api on save
      const idx = updates.findIndex((u: any) => upd.id === u.id);
      if (idx === -1) {
        updates = [...updates, upd];
      } else {
        const newUpdates = [...updates];
        newUpdates[idx] = { ...newUpdates[idx], ...upd };
        updates = [...newUpdates];
      }
      // apply the updates the the state
      const jdx = units.findIndex((u: any) => u.id === upd.id);
      if (jdx > -1) {
        const newUnits: any[] = [...units];
        newUnits[jdx] = { ...newUnits[jdx], ...upd };
        setUnits(newUnits);
      }
    },
    [units, updates]
  );

  const save = useCallback(async () => {
    setIsLoading(true);
    await api.patchMCRUnit(updates);
    setIsLoading(false);
    setMessage('Updated MCR dates!');
  }, [updates]);

  return (
    <MCRUnitsContext.Provider value={{ units, save, addUpdate, isLoading, message }}>
      {children}
    </MCRUnitsContext.Provider>
  );
};

// hook
interface MCRUnitsHook {
  units: MCRUnit[];
  save(): void;
  addUpdate(upd: MCRPatchParams): void;
  isLoading: boolean;
  message: string;
}

const useMCRUnits = (): MCRUnitsHook => {
  const val = useContext(MCRUnitsContext);
  if (!val) {
    throw new Error('useMCRUnits must be used inside an MCRUnitsProvider');
  }

  return val;
};
export default useMCRUnits;
