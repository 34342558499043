import React, { createContext, useContext } from 'react';
import * as R from 'ramda';

const ReportIndicatorsContext = createContext();

const useReportIndicatorsProvider = () => {
  const reportIndicators = useContext(ReportIndicatorsContext);

  if (R.isNil(reportIndicators)) {
    throw new Error('useReportIndicatorsProvider must be used inside ReportIndicatorsProvider');
  }

  return reportIndicators;
};

export const ReportIndicatorsProvider = ({ reportIndicators, reportIndicatorsById, ...props }) => (
  <ReportIndicatorsContext.Provider
    value={{ reportIndicators, reportIndicatorsById }}
    {...props}
  />
);

export default useReportIndicatorsProvider;
