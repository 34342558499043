import React, { useState } from 'react';
import styled from 'styled-components';

import MUITable from '@material-ui/core/Table';
import MUITableContainer from '@material-ui/core/TableContainer';
import MUITableHead from '@material-ui/core/TableHead';
import MUITableBody from '@material-ui/core/TableBody';
import MUITableRow from '@material-ui/core/TableRow';
import MUITableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MUIClearIcon from '@material-ui/icons/Clear';
import MUIEditIcon from '@material-ui/icons/Edit';
import AdminCategoriesDialog from './AdminCategoriesDialog';
import OutlineButton from '../../../../components/OutlineButton';

const ClearIcon = styled(MUIClearIcon)`
  color: ${({ theme }) => theme.colours.reds.red1};
`;
const EditIcon = styled(MUIEditIcon)``;

const TableCell = styled(MUITableCell)``;
const TableRow = styled(MUITableRow)``;
const TableBody = styled(MUITableBody)``;
const TableHead = styled(MUITableHead)``;
const TableContainer = styled(MUITableContainer)``;
const Table = styled(MUITable)``;

interface Props {
  categories: { id: number; categoryName: string }[];
  onAddCategory: any;
  onDelete: any;
  onEdit: any;
}
const ActionBar = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 16px;
  justify-content: flex-end;

  border: ${({ theme }) => theme.borders.thin.solid1};
`;

const AdminCategoriesTable = ({ categories, onEdit, onDelete, onAddCategory }: Props) => {
  const [adding, setAdding] = useState(false);

  const Row = ({ category, deleteHandler }: any) => {
    const [editing, setEditing] = useState(false);
    return (
      <TableRow>
        <TableCell>{category.id}</TableCell>
        <TableCell align="center">{category.categoryName}</TableCell>
        <TableCell align="right">
          <IconButton onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
          {editing && (
            <AdminCategoriesDialog
              className={'edit-admin-categories-dialog'}
              title={'Edit Admin Categories Dialog'}
              onClose={() => setEditing(false)}
              onEdit={(categoryName: string) => {
                onEdit(category.id, categoryName);
              }}
            />
          )}
          <IconButton
            onClick={() => {
              deleteHandler(category.id);
            }}
          >
            <ClearIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const adminCategoriesRows =
    categories &&
    categories.map((category: any) => {
      return (
        <>
          <Row
            category={category}
            deleteHandler={onDelete}
          />
        </>
      );
    });

  return (
    <>
      <ActionBar>
        <OutlineButton onClick={() => setAdding(true)}>Add Admin Category</OutlineButton>
      </ActionBar>
      {adding && (
        <AdminCategoriesDialog
          className={'add-admin-categories-dialog'}
          title={'Add Admin Categories Dialog'}
          onClose={() => setAdding(false)}
          onEdit={(categoryName: string) => {
            onAddCategory(categoryName);
          }}
        />
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Category Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{adminCategoriesRows}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminCategoriesTable;
