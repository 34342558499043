import React from 'react';
import Paper from '@material-ui/core/Paper';
import RecordDetails from './RecordDetails';
import PopupInputPanel from './PopupInputPanel';
import useRecordDetails from '../../hooks/record-details';

interface Props {
  className?: string;
  record: any;
  onApply(x: any): void;
  onDismiss(): void;
  setOpen(x: boolean): void;
  shortName: string;
}

const PopUpRecordDetailsPanel = ({ className, record, onApply, onDismiss, setOpen, shortName }: Props) => {
  const { recordDetails } = useRecordDetails(record, shortName);

  return (
    <Paper
      square
      className={className}
    >
      {record && (
        <>
          <PopupInputPanel
            record={record}
            onApply={onApply}
            onDismiss={onDismiss}
            setOpen={setOpen}
          />
          <RecordDetails
            record={record}
            shortName={shortName}
            recordDetails={recordDetails}
          />
        </>
      )}
    </Paper>
  );
};

export default PopUpRecordDetailsPanel;
