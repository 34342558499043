import React, { useState } from 'react';
import styled from 'styled-components';
import UnstyledFileUploadForm from './FileUploadForm';
import StartIconButton from '../../../../../components/StartIconButton';

const FileUploadForm = styled(UnstyledFileUploadForm)``;

const Container = styled.div``;

interface Props {
  className?: string;
  onAddAttachment(fileType: string, file: any): void;
}

const AddAttachment = ({ className, onAddAttachment }: Props) => {
  const [showFileUploadForm, setShowFileUploadForm] = useState(false);

  const handleUpload = (fileType: string, file: string) => {
    setShowFileUploadForm(false);
    onAddAttachment(fileType, file);
  };

  return (
    <Container className={className}>
      {showFileUploadForm ? (
        <FileUploadForm onUpload={handleUpload} />
      ) : (
        <StartIconButton onClick={() => setShowFileUploadForm(true)}>Add Attachment</StartIconButton>
      )}
    </Container>
  );
};

export default AddAttachment;
