import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import {
  renderWOHeaderStatusIcon,
  renderActions,
  onRenderRow,
  getRowContextMenuFunction,
  onRowDoubleClick,
  renderNotes,
  formatUTCDate,
} from './utils';
import styled from 'styled-components';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import { useSchedule } from '../../hooks/useSchedule';
import { useState } from 'react';
import format from 'date-fns/format';
import { WOColumns } from './WOTableColumns';

const gridStyle = { minHeight: 600, minWidth: '100%' };

const HeaderGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__row-cell-wrap:hover {
    background-color: transparent !important;
    border: unset !important;
    border: 2px solid gray !important;
  }

  .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid transparent !important;
  }
  .InovuaReactDataGrid__row-cell-wrap:active {
    transform: scale(0.997);
    cursor: pointer;
  }

  .InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid rgb(38, 36, 82) !important;
  }
  .InovuaReactDataGrid__row.InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
  }
`;

const WOHeaderTable = () => {
  const {
    filteredWorkorders,
    selectedWorkorderId,
    handleHeaderTableSelection,
    setSelectedWorkorderId,
    woloading,
    setGridRef,
  } = useWorkOrders();
  const { handleOpen } = useWODetailsModal();
  const { schedule } = useSchedule();
  const [sortDir, setSortDir] = useState(0);
  const data = filteredWorkorders.map(wo => ({
    ...wo,
    //TODO: We can probably modify the server endpoint to include this field instead of doing this here
    appStart: schedule.find(sc => sc.wonum === wo.wonum)?.start,
  }));

  const columns = [
    {
      name: 'actions',
      header: 'Actions',
      defaultWidth: 80,
      sortable: false,
      showColumnMenuTool: false,
      render: renderActions,
    },
    ...WOColumns,
    {
      name: 'appStart',
      sort: (value1: string | undefined, value2: string | undefined) => {
        if (!value2) return -sortDir;
        if (!value1) return sortDir;
        return Date.parse(value1) - Date.parse(value2);
      },
      field: 'appStart',
      header: 'App Start',
      headerEllipsis: false,
      defaultWidth: 130,
      render: (value: any) => {
        const date = new Date(value.data.appStart);
        return <span>{value.data.appStart ? format(date, 'ddMMMyyyy').toUpperCase() : null}</span>;
      },
    },
    {
      name: 'schedstart',
      header: 'Online Start',
      headerEllipsis: false,
      sort: (value1: any, value2: string | undefined) => {
        if (!value2) return -sortDir;
        if (!value1) return sortDir;
        return Date.parse(value1) - Date.parse(value2);
      },
      defaultWidth: 130,
      render: (value: any) => {
        return <span>{formatUTCDate(value.data.schedstart)}</span>;
      },
    },
    {
      name: 'assessingReady',
      header: 'Assess.',
      defaultWidth: 60,
      textAlign: 'center' as const,
      headerEllipsis: false,
      sortable: false,
      showColumnMenuTool: false,
      enableColumnFilterContextMenu: false,
      render: renderWOHeaderStatusIcon,
    },
    {
      name: 'engReady',
      header: 'Eng',
      defaultWidth: 60,
      textAlign: 'center' as const,
      headerEllipsis: false,
      sortable: false,
      showColumnMenuTool: false,
      enableColumnFilterContextMenu: false,
      render: renderWOHeaderStatusIcon,
    },
    {
      name: 'scReady',
      header: 'SC',
      defaultWidth: 60,
      textAlign: 'center' as const,
      headerEllipsis: false,
      sortable: false,
      showColumnMenuTool: false,
      enableColumnFilterContextMenu: false,
      render: renderWOHeaderStatusIcon,
    },
    {
      name: 'opsReady',
      header: 'Ops',
      defaultWidth: 60,
      textAlign: 'center' as const,
      headerEllipsis: false,
      sortable: false,
      showColumnMenuTool: false,
      enableColumnFilterContextMenu: false,
      render: renderWOHeaderStatusIcon,
    },
    {
      name: 'otherReady',
      header: 'Other',
      defaultWidth: 60,
      textAlign: 'center' as const,
      headerEllipsis: false,
      sortable: false,
      showColumnMenuTool: false,
      enableColumnFilterContextMenu: false,
      render: renderWOHeaderStatusIcon,
    },
    {
      name: 'notes',
      header: 'Notes',
      defaultWidth: 60,
      sortable: false,
      showColumnMenuTool: false,
      render: ({ value }: any) => renderNotes(value, handleOpen),
    },
  ];
  return (
    <HeaderGrid
      style={gridStyle}
      idProperty="id"
      enableSelection
      selected={selectedWorkorderId}
      onSelectionChange={handleHeaderTableSelection}
      onRowDoubleClick={onRowDoubleClick(handleOpen)}
      renderRowContextMenu={getRowContextMenuFunction(handleOpen, setSelectedWorkorderId)}
      rowStyle={onRenderRow}
      pagination
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={data}
      showCellBorders={false}
      loading={woloading}
      onReady={setGridRef}
      onSortInfoChange={(sortInfo: any) => {
        setSortDir(sortInfo?.dir ?? 0);
      }}
    />
  );
};

export default WOHeaderTable;
