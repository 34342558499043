import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useTurnover } from '../../hooks/useTurnover';
import { format } from 'date-fns';
import { useUsers } from '../../hooks/useUsers';
import { renderMaximoLink } from '../../wo/components/utils';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import { TurnoverItem } from '../../../../types/cwt/turnover';
import { Tooltip } from '@material-ui/core';

const gridStyle = { minHeight: 600, minWidth: '100%' };

const TurnoverTable = () => {
  const { turnover, filters, loading, setGridRef } = useTurnover();
  const { workorders } = useWorkOrders();
  const turnoverWithWorkorder = turnover.map((t: TurnoverItem) => {
    const workorder = workorders.get(t.workorderId);
    return {
      ...t,
      wonum: workorder?.wonum,
      unit: workorder?.unit,
      wopriority: workorder?.wopriority,
      worktype: workorder?.worktype,
      location: workorder?.location,
      description: workorder?.description,
    };
  });
  const filteredTurnover = turnoverWithWorkorder.filter((t: any) => {
    return (
      (!filters.wonum.length || filters.wonum.includes(t.wonum)) &&
      (!filters.unit.length || filters.unit.includes(t.unit)) &&
      (!filters.wopriority.length || filters.wopriority.includes(t.wopriority?.toString())) &&
      (!filters.worktype.length || filters.worktype.includes(t.worktype)) &&
      (!filters.crewworkgroup.length || filters.crewworkgroup.includes(t.crewId))
    );
  });
  const { getUser } = useUsers();
  const columns = [
    {
      name: 'wonum',
      header: 'WO',
      defaultWidth: 80,
      render: ({ data }: any) => renderMaximoLink(data.workorderId, data.wonum),
    },
    {
      name: 'wopriority',
      header: 'Prior',
      type: 'number',
      defaultWidth: 70,
    },
    {
      name: 'worktype',
      header: 'Type',
      defaultWidth: 70,
    },
    {
      name: 'description',
      header: 'Description',
      defaultWidth: 120,
      render: ({ value }: any) => (
        <Tooltip
          title={<h2>{value}</h2>}
          placement="top-start"
        >
          <div>{value}</div>
        </Tooltip>
      ),
    },
    {
      name: 'location',
      header: 'Location',
      defaultWidth: 100,
    },
    {
      name: 'crewId',
      header: 'Crew',
      defaultWidth: 100,
      render: ({ value }: any) => value?.toUpperCase(),

      enableColumnFilterContextMenu: false,
    },
    {
      name: 'turnoverDate',
      header: 'Date',
      defaultWidth: 100,
      render: ({ value }: any) => format(new Date(value), 'ddMMMyyyy').toUpperCase(),
    },
    {
      name: 'turnoverNotes',
      header: 'Notes',
      defaultWidth: 100,
      defaultFlex: 1,
      render: ({ value }: any) => (
        <Tooltip
          title={<h2>{value}</h2>}
          placement="top-start"
        >
          <div>{value}</div>
        </Tooltip>
      ),
    },
    {
      name: 'updatedBy',
      header: 'Last Modified By',
      defaultWidth: 150,
      render: ({ value }: any) => getUser(value)?.name,
    },
    {
      name: 'updatedAt',
      header: 'Last Modified',
      defaultWidth: 150,
      render: ({ value }: any) => format(new Date(value), 'ddMMMyyyy HH:mm').toUpperCase(),
    },
  ];

  return (
    <ReactDataGrid
      idProperty="id"
      style={gridStyle}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={filteredTurnover}
      loading={loading}
      onReady={setGridRef}
    />
  );
};

export default TurnoverTable;
