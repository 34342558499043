import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledListItem from '@material-ui/core/ListItem';
import UnstyledListItemIcon from '@material-ui/core/ListItemIcon';
import UnstyledListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';

const ListItemIcon = styled(UnstyledListItemIcon)``;
const ListItemText = styled(UnstyledListItemText)``;

const ListItem = styled(props => <UnstyledListItem {...R.omit(['subMenu'], props)} />)`
  padding-left: ${({ subMenu }) => (subMenu ? 111 : 54)}px;

  ${ListItemIcon} {
    min-width: 0px;
    margin-right: 18px;
  }

  ${ListItemText} {
    span {
      font: ${({ theme }) => theme.fonts.medium14};
    }
  }
`;

const NavButton = ({ className, to, onClick, iconComponent: IconComponent, label, subMenu }) => (
  <ListItem
    className={className}
    button
    disableGutters
    component={NavLink}
    exact
    to={to}
    onClick={onClick}
    subMenu={subMenu}
  >
    {IconComponent && (
      <ListItemIcon>
        <IconComponent />
      </ListItemIcon>
    )}
    <ListItemText>{label}</ListItemText>
  </ListItem>
);

export default NavButton;
