import msalInstance from './msalInstance';
import { getActiveAccount } from './utils';

export const getAccessToken = async () => {
  const account = getActiveAccount();
  const request = {
    scopes: window.echoConfig.scopes,
    account,
  };
  const response = await msalInstance.acquireTokenSilent(request);
  return response.accessToken;
};
