import React, { useContext, useRef, useCallback } from 'react';
import * as R from 'ramda';

const DownloaderContext = React.createContext(null as any);

const useDownloader = () => {
  const context = useContext(DownloaderContext);
  if (R.isNil(context)) {
    throw new Error('useDownload must be used inside a Downloader');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

export const Downloader = ({ children }: Props) => {
  const downloadRef = useRef<any>();

  const onDownload = useCallback(
    (blob: Blob | MediaSource, filename: any) => {
      const href = window.URL.createObjectURL(blob);
      downloadRef.current.download = filename;
      downloadRef.current.href = href;
      downloadRef.current.click();
    },
    [downloadRef]
  );

  const value = {
    onDownload,
  };

  return (
    <DownloaderContext.Provider value={value}>
      {children}
      <a
        ref={downloadRef}
        href="#null"
      />
    </DownloaderContext.Provider>
  );
};

export default useDownloader;
