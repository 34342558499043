import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import AutocompleteInput, { AutocompleteInputProps } from '../components/AutocompleteInput';

export interface ControlAutocompleteProps<T extends FieldValues, OptionValue extends string, Multiple extends boolean>
  extends Omit<AutocompleteInputProps<OptionValue, Multiple>, 'value' | 'onChange'>,
    Pick<RegisterOptions<T>, 'deps' | 'required' | 'validate'> {
  control: Control<T>;
  name: Path<T>;
  required: boolean;
  disabled?: boolean;
  onChangeCallback?: AutocompleteInputProps<OptionValue, Multiple>['onChange'];
  readOnly?: boolean;
}

export const ControlAutocomplete = <
  T extends FieldValues,
  OptionValue extends string,
  Multiple extends boolean = false
>({
  control,
  options,
  name,
  error,
  label,
  required,
  disabled = false,
  multiple,
  placeholder,
  onChangeCallback,
  validate,
  helperText,
  onInputBlur,
  deps,
  readOnly = false,
}: ControlAutocompleteProps<T, OptionValue, Multiple>) => (
  <Controller
    control={control}
    name={name}
    disabled={disabled}
    rules={{ required, validate, deps }}
    render={({ field }) => {
      return (
        <AutocompleteInput<OptionValue, Multiple>
          multiple={multiple}
          options={options}
          onChange={newValue => {
            onChangeCallback?.(newValue);
            field.onChange(newValue);
          }}
          value={field.value}
          error={error}
          label={label}
          placeholder={placeholder}
          disabled={field.disabled || readOnly}
          helperText={helperText}
          name={name}
          onInputBlur={onInputBlur}
        />
      );
    }}
  />
);
