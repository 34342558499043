import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UnstyledDrawer from '@material-ui/core/Drawer';
import UnstyledTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloseButton from '../../components/CloseButton';
import Notes from '../../components/Notes/Notes';
import { UserNotesProvider } from '../../hooks/user-notes';

const Drawer = styled(UnstyledDrawer)`
  flex-shrink: 0;

  .MuiDrawer-paper {
    margin-top: 4%;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
  }
`;

const Tabs = styled(UnstyledTabs)`
  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colours.purples.purple1};
  }
`;

interface Props {
  open: boolean;
}

const SideDrawer = ({ open }: Props) => {
  const [isOpen, setOpen] = useState(open);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Drawer
      variant="persistent"
      open={isOpen}
      anchor="right"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs
        >
          <CloseButton onClick={() => setOpen(false)} />
        </Grid>
        <Grid
          item
          xs
        >
          <Tabs
            value={value}
            onChange={(_, newValue: number) => setValue(newValue)}
          >
            <Tab label="Notes" />
            <Tab label="Archived" />
          </Tabs>
        </Grid>
      </Grid>
      {value === 0 && (
        <Box
          px={1}
          py={1}
        >
          <UserNotesProvider>
            <Notes />
          </UserNotesProvider>
        </Box>
      )}
      {value === 1 && (
        <Box
          px={1}
          py={1}
        >
          <UserNotesProvider>
            <Notes isArchived />
          </UserNotesProvider>
        </Box>
      )}
    </Drawer>
  );
};

export default SideDrawer;
