import React from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import ReportSectionNavButton from '../../ReportSectionNavButton';

const ReportTitle = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
  color: ${({ theme }) => theme.colours.greys.darkGrey2};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: ${({ theme }) => theme.shadows.boxShadow3};
  border: ${({ theme }) => theme.borders.thin.solid3};

  padding: 14px 40px 14px 40px;

  background-color: ${({ theme }) => theme.colours.greys.lightGrey9};
`;

const NavHeader = ({ onNext, onPrev, children }) => (
  <Container>
    <ReportSectionNavButton onClick={onPrev}>Previous Section</ReportSectionNavButton>
    <ReportTitle>{children}</ReportTitle>
    <ReportSectionNavButton onClick={onNext}>Next Section</ReportSectionNavButton>
  </Container>
);

export default NavHeader;
