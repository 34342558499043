import React from 'react';
import styled from 'styled-components';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import UnstyledIconButton from '@material-ui/core/IconButton';
import UnstyledCheckIcon from '@material-ui/icons/Check';
import UnstyledClearIcon from '@material-ui/icons/Clear';
import Table from '../../../components/Table';
import Link from '../../../components/Link';
import { dateToString } from '../../../utils/date-format';
import { Walkdown } from '../../../types/walkdown-types';
import useWalkdowns from '../../../hooks/walkdowns';
import DownloadButton from '../../../components/DownloadButton';

const IconButton = styled(UnstyledIconButton)``;

const CheckIcon = styled(UnstyledCheckIcon)`
  color: ${({ theme }) => theme.colours.greens.green2};
`;

const ClearIcon = styled(UnstyledClearIcon)`
  color: ${({ theme }) => theme.colours.reds.red3};
`;

const Approval = ({ onCheck, onClear }: any) => (
  <Grid
    container
    justifyContent="center"
    spacing={1}
  >
    <Grid item>
      <IconButton
        size="small"
        onClick={onCheck}
      >
        <CheckIcon />
      </IconButton>
    </Grid>
    <Grid item>
      <IconButton
        size="small"
        onClick={onClear}
      >
        <ClearIcon />
      </IconButton>
    </Grid>
  </Grid>
);

interface Props {
  walkdowns: Walkdown[];
  onAccept(w: Walkdown): void;
  onReject(w: Walkdown): void;
}

const ReportTable = ({ walkdowns, onAccept, onReject }: Props) => {
  const { generateAndDownloadWalkdown } = useWalkdowns();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Element Name</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Walkdown Title</TableCell>
            <TableCell>Submitted By</TableCell>
            <TableCell align="center">Date Submitted</TableCell>
            <TableCell align="center">Approval</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {walkdowns.map(w => (
            <TableRow key={w.id}>
              <TableCell>{w.elementName}</TableCell>
              <TableCell>{w.unit ? w.unit : 'N/A'}</TableCell>
              <TableCell>{w.title}</TableCell>
              <TableCell>{w.stateChangedByName}</TableCell>
              <TableCell align="center">{w.stateChangedDate ? dateToString(w.stateChangedDate) : ''}</TableCell>
              <TableCell
                align="center"
                padding="none"
              >
                <Approval
                  onCheck={() => onAccept(w)}
                  onClear={() => onReject(w)}
                />
              </TableCell>
              <TableCell align="center">
                <DownloadButton
                  color="primary"
                  onClick={() => {
                    generateAndDownloadWalkdown(w.id);
                  }}
                >
                  View
                </DownloadButton>
                <Link
                  to={`/app/approvals/walkdown-review/${w.id}`}
                  label="Open"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
