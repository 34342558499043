import React from 'react';
import styled from 'styled-components';
import UnstyledButton from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const Button = styled(UnstyledButton)``;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    min-width: 0;
    padding: 0;
    margin-left: 4px;
  }

  ${Button}.unsorted {
    svg {
      opacity: 1;
      transition: opacity 300ms;

      :hover {
        opacity: 1;
      }
    }
  }
`;

interface Props {
  className?: string;
  label: string;
  columnName: string;
  sortColumn: string;
  sortOrder: boolean;
  onSort(columnName: string, sortOrder: boolean): void;
}

const SortableColumnHeader = ({ className, label, columnName, sortColumn, sortOrder, onSort }: Props) => (
  <Container className={className}>
    {label}
    {sortColumn === columnName ? (
      <Button onClick={() => onSort(columnName, !sortOrder)}>
        {sortOrder ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Button>
    ) : (
      <Button
        className="unsorted"
        onClick={() => onSort(columnName, false)}
      >
        <KeyboardArrowDownIcon />
      </Button>
    )}
  </Container>
);

export default SortableColumnHeader;
