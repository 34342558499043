import React from 'react';
import styled from 'styled-components';

import MUIPaper from '@material-ui/core/Paper';
import MUIStepper from '@material-ui/core/Stepper';
import MUIStep from '@material-ui/core/Step';
import MUIStepButton from '@material-ui/core/StepButton';

const Paper = styled(MUIPaper)``;
const StepButton = styled(MUIStepButton)``;
const Step = styled(MUIStep)`
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${({ theme }) => theme.colours.teals.teal1};
  }
`;

const Stepper = styled(MUIStepper)``;

interface Props {
  className?: string;
  currentStep: number;
  pages: any;
  onJumpTo(id: string): void;
}

const ReportGenStepper = ({ className, currentStep, pages, onJumpTo }: Props) => {
  const stepItems = pages.map(({ pageId, title }: any, index: number) => (
    <Step
      key={pageId}
      active={index <= currentStep}
    >
      <StepButton
        onClick={() => {
          onJumpTo(pageId);
        }}
      >
        {title}
      </StepButton>
    </Step>
  ));

  return (
    <Paper
      className={className}
      elevation={2}
    >
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        nonLinear
      >
        {stepItems}
      </Stepper>
    </Paper>
  );
};

export default ReportGenStepper;
