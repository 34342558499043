import React, { useCallback, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import styled from 'styled-components';
import useWalkdownTemplate from '../hooks/useWalkdownTemplate';
import CustomTextField from '../../../components/CustomTextField';
import ActionButton from '../../../components/ActionButton';
import useForm from '../../../hooks/form';
import config from './config/singleTemplateFormConfig';
import TemplateCategory from './categories/TemplateCategory';
import CreateCategory from './categories/CreateCategory';
import { CreateCategoryParams, WalkdownTemplateTask } from '../../../types/walkdown-template';
import elementTypePrefix from '../../../utils/element-type-prefix';

const Layer = styled.div`
  padding: 10px;
`;

const SingleTemplateInternal = () => {
  const { template, createCategory, updateTemplate, deleteTask, removeCategory, addTask, updateTask } =
    useWalkdownTemplate();
  const [creating, setCreating] = useState(false);

  const { submit, propsForField } = useForm(config, template, updateTemplate);

  const createHandler = useCallback((t: CreateCategoryParams) => {
    createCategory(t).then(() => setCreating(false));
  }, []);

  const updateTaskHandler = (categoryId: string, task: WalkdownTemplateTask) => updateTask(categoryId, task);

  return (
    <div>
      <Layer style={{ textAlign: 'right' }}>
        <ActionButton
          color="primary"
          onClick={submit}
        >
          Save
        </ActionButton>
      </Layer>

      <Layer>
        <FormLabel component="legend">Element Name</FormLabel>
        <div style={{ padding: '10px 0' }}>
          <>
            [{elementTypePrefix(template.elementType)}] {template.elementName} ({template.facilityName})
          </>
        </div>
      </Layer>

      <Layer>
        <div>Title</div>
        <CustomTextField
          variant="standard"
          {...propsForField('title')}
        />
      </Layer>

      <Layer>
        <FormControl component="fieldset">
          <FormLabel component="legend">Type</FormLabel>
          <RadioGroup
            {...propsForField('type')}
            row
            aria-label="type"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="innage"
              control={<Radio />}
              label="Innage"
            />
            <FormControlLabel
              value="outage"
              control={<Radio />}
              label="Outage"
            />
          </RadioGroup>
        </FormControl>
      </Layer>

      <Layer>Tasks</Layer>
      <Layer>
        <Button
          variant="text"
          onClick={() => setCreating(true)}
        >
          + Add Category
        </Button>
      </Layer>

      <Layer>
        {creating && <CreateCategory onSave={createHandler} />}
        {template.categories.map((c: any) => (
          <TemplateCategory
            key={c.id}
            category={c}
            onRemoveTask={deleteTask}
            onUpdateTask={updateTaskHandler}
            onRemoveCategory={removeCategory}
            onCreateTask={addTask}
          />
        ))}
      </Layer>
    </div>
  );
};

export default SingleTemplateInternal;
