import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ActionButton from '../../../../components/ActionButton';
import ControlButton from '../../../../components/ControlButton';
import FilterButton from '../../../../components/FilterButton';
import SmallButton from '../../../../components/SmallButton';
import OutlineButton from '../../../../components/OutlineButton';
import ReportSectionNavButton from '../../../../components/ReportSectionNavButton';
import DialogButton from '../../../../components/DialogButton';

const BorderChipContainer = styled(Grid).attrs({
  item: true,
})`
  display: flex;
  flex-direction: column;
`;

const ButtonChip = ({ children, name }) => (
  <BorderChipContainer>
    {children}
    <Typography>{name}</Typography>
  </BorderChipContainer>
);

const Section = ({ children, name }) => (
  <>
    <Typography variant="h4">{name}</Typography>
    <Grid
      container
      spacing={3}
    >
      {children}
    </Grid>
  </>
);

const Buttons = () => (
  <Grid container>
    <Grid item>
      <Section name="ActionButton">
        <ButtonChip name="color=default">
          <ActionButton color="default">Action</ActionButton>
        </ButtonChip>
        <ButtonChip name="color=primary">
          <ActionButton color="primary">Action</ActionButton>
        </ButtonChip>
        <ButtonChip name="color=secondary">
          <ActionButton color="secondary">Action</ActionButton>
        </ButtonChip>
      </Section>
      <Section name="ControlButton (expand/collapse)">
        <ButtonChip name="default">
          <ControlButton>Control</ControlButton>
        </ButtonChip>
      </Section>
      <Section name="FilterButton (tabs)">
        <ButtonChip name="isActive=true">
          <FilterButton isActive={true}>Filter</FilterButton>
        </ButtonChip>
        <ButtonChip name="isActive=false">
          <FilterButton isActive={false}>Filter</FilterButton>
        </ButtonChip>
      </Section>
      <Section name="SmallButton (delete attachment)">
        <ButtonChip name="singular">
          <SmallButton>Small</SmallButton>
        </ButtonChip>
      </Section>
      <Section name="OutlineButton">
        <ButtonChip name="default">
          <OutlineButton>Outline</OutlineButton>
        </ButtonChip>
        <ButtonChip name="disabled">
          <OutlineButton disabled>Outline</OutlineButton>
        </ButtonChip>
      </Section>
      <Section name="ReportSectionNavButton">
        <ButtonChip name="singular">
          <ReportSectionNavButton>ReportSectionNav</ReportSectionNavButton>
        </ButtonChip>
      </Section>
      <Section name="DialogButton">
        <ButtonChip name="color=default">
          <DialogButton>Dialog</DialogButton>
        </ButtonChip>
        <ButtonChip name="color=primary">
          <DialogButton color="primary">Dialog</DialogButton>
        </ButtonChip>
        <ButtonChip name="color=primary disabled">
          <DialogButton
            color="primary"
            disabled
          >
            Dialog
          </DialogButton>
        </ButtonChip>
      </Section>
    </Grid>
  </Grid>
);

export default Buttons;
