import React from 'react';
import useQuery from '../../../../hooks/query';
import useWalkdownTemplate, { WalkdownTemplateProvider } from '../../hooks/useWalkdownTemplate';
import CreateTemplateInternal from './CreateTemplateInternal';
import Conditional from '../../../../components/Conditional';

interface CreateTemplateProps {
  cloneFrom?: any;
}

const CreateTemplate = ({ cloneFrom }: CreateTemplateProps) => {
  const { isLoading, template } = useWalkdownTemplate();

  if (cloneFrom) {
    return (
      <Conditional condition={!isLoading}>
        <CreateTemplateInternal template={template} />
      </Conditional>
    );
  }

  return <CreateTemplateInternal />;
};

export default () => {
  const { cloneFrom } = useQuery();

  return (
    <WalkdownTemplateProvider templateId={cloneFrom}>
      <CreateTemplate cloneFrom={cloneFrom} />
    </WalkdownTemplateProvider>
  );
};
