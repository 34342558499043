import React, { useState, useContext } from 'react';
import * as R from 'ramda';
import { fromQuarterValue } from '../../../../../hooks/quarters';

interface QuarterContextValue {
  quarterValue: string;
  year: string;
  quarter: string;
  onChangeQuarterValue: React.Dispatch<React.SetStateAction<string>>;
}

const QuarterContext = React.createContext<QuarterContextValue>({
  quarterValue: '',
  year: '',
  quarter: '',
  onChangeQuarterValue: () => {
    /** */
  },
});

const useQuarter = () => {
  const context = useContext(QuarterContext);
  if (R.isNil(context)) {
    throw new Error('useQuarter must be used inside a QuarterProvider');
  }
  return context;
};

export const QuarterProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [quarterValue, setQuarterValue] = useState('');
  const [year, quarter] = fromQuarterValue(quarterValue);

  const value = {
    quarterValue,
    year,
    quarter,
    onChangeQuarterValue: setQuarterValue,
  };

  return <QuarterContext.Provider value={value}>{children}</QuarterContext.Provider>;
};

export default useQuarter;
