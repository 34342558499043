import React, { useState } from 'react';
import styled from 'styled-components';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';
import NavPaper from '../../components/NavPaper';
import IndicatorFilter from './IndicatorFilter';
import { IndicatorProvider } from '../../../../hooks/indicator';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import RecordsTableModule from '../../../../components/ReviewRecordsModule';

const CircularProgress = styled(UnstyledCircularProgress)``;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${CircularProgress} {
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: center;
  }
`;

interface Props {
  report: any;
  onNext(): void;
}

const ReviewRecordsPage = ({ report, onNext }: Props) => {
  const [indicatorId, setIndicatorId] = useState(null);

  const handleNext = () => {
    // store some stuff?
    onNext();
  };

  return (
    <Container>
      <NavPaper onNext={handleNext}>
        <ElementIndicatorsProvider elementId={report.elementId}>
          <IndicatorFilter
            indicatorId={indicatorId}
            onChangeIndicator={setIndicatorId}
          />
        </ElementIndicatorsProvider>
      </NavPaper>
      <IndicatorProvider indicatorId={indicatorId}>
        <RecordsTableModule
          elementId={report.elementId}
          quarter={report.quarter}
          year={report.year}
        />
      </IndicatorProvider>
    </Container>
  );
};

export default ReviewRecordsPage;
