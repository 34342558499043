import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useAllElements from '../../hooks/all-elements';
import useForm from '../../../../hooks/form';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import Select from '../../../../components/Select';
import isFeatureEnabled from '../../../../utils/feature-flags';

const formConfig = {
  elementId: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a system or component',
      },
    },
  },
  assignmentType: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select an assignment type',
      },
    },
  },
};

const initialValues = {
  elementId: '',
  assignmentType: 'backup',
};

const AddUserForm = ({ onSubmit, onCancel }) => {
  const handleSubmit = values => {
    onSubmit(values.elementId, values.assignmentType);
  };

  const { submit, propsForField } = useForm(formConfig, initialValues, handleSubmit);
  const { elements } = useAllElements();

  const elementOptions = elements.map(element => ({
    value: element.id,
    label: `[${elementTypePrefix(element.type)}] ${element.name} (${element.facilityName})`,
  }));

  const assignmentTypeOptions = [
    { value: 'primary', label: 'Primary' },
    { value: 'backup', label: 'Backup' },
    isFeatureEnabled('riskOwnersGroup') ? { value: 'risk-owner', label: 'Risk Owner' } : null,
  ].filter(option => option !== null);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Select
          label="Element"
          fullWidth
          options={elementOptions}
          {...propsForField('elementId')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Select
          label="Assignment Type"
          fullWidth
          options={assignmentTypeOptions}
          {...propsForField('assignmentType')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Grid
          container
          justifyContent="flex-start"
        >
          <Button onClick={submit}>Add</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddUserForm;
