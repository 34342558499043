import * as R from 'ramda';
import PowerBiReport from '../../../components/PowerBiReport';
import Page from '../../../components/Page';

const EngineeringPage = () => {
  const reportId = R.pathOr('', ['echoConfig', 'erPowerBiReportId'], window);
  const reportPageName = R.pathOr('', ['echoConfig', 'erPowerBiPageName'], window);
  return (
    <Page title="Engineering">
      {reportId && reportPageName && (
        <PowerBiReport
          title="ERs"
          pageName={reportPageName}
          reportId={reportId}
          height="80vh"
        />
      )}
    </Page>
  );
};
export default EngineeringPage;
