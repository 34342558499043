import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import DateTimeInput, { DateTimeInputProps } from '../components/DateTimeInput';

export interface ControlDateTimeProps<T extends FieldValues, P extends Path<T>>
  extends Pick<DateTimeInputProps, 'fullWidth' | 'helperText' | 'disableFuture' | 'disablePast'>,
    Pick<RegisterOptions<T, P>, 'deps' | 'required' | 'validate'> {
  control: Control<T>;
  label?: string;
  name: P;
  error: boolean;
  variant?: DateTimeInputProps['inputVariant'];
  disabled?: boolean;
  readOnly?: boolean;
}

export const ControlDateTime = <T extends FieldValues, P extends Path<T>>({
  control,
  required,
  label,
  name,
  error,
  fullWidth = true,
  variant,
  helperText,
  disabled = false,
  disablePast,
  disableFuture,
  validate,
  deps,
  readOnly = false,
}: ControlDateTimeProps<T, P>) => (
  <Controller
    name={name}
    control={control}
    disabled={disabled}
    rules={{ required, validate, deps }}
    render={({ field }) => (
      <DateTimeInput
        fullWidth={fullWidth}
        onChange={field.onChange}
        value={field.value}
        disabled={field.disabled || readOnly}
        error={error}
        name={field.name}
        label={label}
        helperText={helperText}
        inputVariant={variant}
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
    )}
  />
);
