export const ACCEPT_AND_KEEP_ACTIVE = 'Accept and Keep Active';
export const ACCEPT_AND_CLOSE = 'Accept and Close';
export const REJECT_AND_CLOSE = 'Reject and Close';
export const REJECT_AND_RESUBMIT = 'Reject and Resubmit';

export enum RISK_STATES {
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
  ENV_REQUIRED = 'ENV_REQUIRED',
  CLOSED = 'CLOSED',
  APPROVED = 'APPROVED',
}
