import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadRequest = elementId => ({
  type: actionTypes.CUSTOM_INDICATORS_LOAD_REQUEST,
  payload: {
    elementId,
  },
});

export const loadSuccess = (elementId, customIndicators) => ({
  type: actionTypes.CUSTOM_INDICATORS_LOAD_SUCCESS,
  payload: {
    elementId,
    customIndicators,
  },
});

export const loadFailure = errorAction(actionTypes.CUSTOM_INDICATORS_LOAD_FAILURE);

export const createRequest = customIndicator => ({
  type: actionTypes.CUSTOM_INDICATORS_CREATE_REQUEST,
  payload: {
    customIndicator,
  },
});

export const createSuccess = customIndicator => ({
  type: actionTypes.CUSTOM_INDICATORS_CREATE_SUCCESS,
  payload: {
    customIndicator,
  },
});

export const createFailure = errorAction(actionTypes.CUSTOM_INDICATORS_CREATE_FAILURE);

export const addRequest = (elementId, name, description) => ({
  type: actionTypes.CUSTOM_INDICATORS_ADD_REQUEST,
  payload: {
    elementId,
    name,
    description,
  },
});

export const addSuccess = (customIndicatorId, elementId, name, description) => ({
  type: actionTypes.CUSTOM_INDICATORS_ADD_SUCCESS,
  payload: {
    customIndicatorId,
    elementId,
    name,
    description,
  },
});

export const addFailure = errorAction(actionTypes.CUSTOM_INDICATORS_ADD_FAILURE);

export const updateRequest = (elementId, customIndicatorId, name, description) => ({
  type: actionTypes.CUSTOM_INDICATORS_UPDATE_REQUEST,
  payload: {
    elementId,
    customIndicatorId,
    name,
    description,
  },
});

export const updateSuccess = (elementId, customIndicatorId, name, description) => ({
  type: actionTypes.CUSTOM_INDICATORS_UPDATE_SUCCESS,
  payload: {
    elementId,
    customIndicatorId,
    name,
    description,
  },
});

export const updateFailure = errorAction(actionTypes.CUSTOM_INDICATORS_UPDATE_FAILURE);

export const deleteRequest = (elementId, customIndicatorId) => ({
  type: actionTypes.CUSTOM_INDICATORS_DELETE_REQUEST,
  payload: {
    elementId,
    customIndicatorId,
  },
});

export const deleteSuccess = (elementId, customIndicatorId) => ({
  type: actionTypes.CUSTOM_INDICATORS_DELETE_SUCCESS,
  payload: {
    elementId,
    customIndicatorId,
  },
});

export const deleteFailure = errorAction(actionTypes.CUSTOM_INDICATORS_DELETE_FAILURE);
