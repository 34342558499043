import { useDispatch } from 'react-redux';
import * as healthReportActions from '../state/actions';

const useReportStarter = () => {
  const dispatch = useDispatch();

  const startReport = (elementId, year, quarter) => {
    dispatch(healthReportActions.startReportRequest(elementId, year, quarter));
  };

  return {
    startReport,
  };
};

export default useReportStarter;
