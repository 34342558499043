import { useCallback } from 'react';
import useDownloader from '../../../../hooks/downloader';
import api from '../../../../api';

const useAttachmentDownload = (walkdownId: number, attachments: any) => {
  const { onDownload } = useDownloader();

  const downloadAttachment = useCallback(
    (attachmentId: number) => {
      const attachment = attachments.find((object: any) => {
        return object.id === attachmentId;
      });

      api.downloadAttachment(walkdownId, attachmentId).then((blob: any) => {
        onDownload(blob, attachment.name);
      });
    },
    [attachments, onDownload]
  );

  return {
    downloadAttachment,
  };
};

export default useAttachmentDownload;
