import { WalkdownTask } from '../types/walkdown-types';
import { Remote, Path } from './types';

const generateWalkdown = (path: Path, remote: Remote) => (walkdownId: number) =>
  remote.post(path(`/${walkdownId}/pdf`));
const downloadWalkdown = (path: Path, remote: Remote) => (walkdownId: number) =>
  remote.download(path(`/${walkdownId}/pdf`));
const getInprogressWalkdowns = (path: any, remote: any) => () => remote.get(path('/inprogress'));
const getSubmittedWalkdowns = (path: any, remote: any) => () => remote.get(path('/submitted'));
const getArchievedWalkdowns = (path: any, remote: any) => () => remote.get(path('/archived'));
const createWalkdown = (path: any, remote: any) => (create: any) => remote.post(path(''), create);
const uploadWalkdownAttachment = (path: Path, remote: Remote) => (walkdownId: number | string, file: string | Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  return remote.post(path(`/${walkdownId}/attachments`), formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};
const uploadTaskAttachment =
  (path: Path, remote: Remote) => (walkdownId: number | string, taskId: number | string, file: string | Blob) => {
    const formData = new FormData();
    formData.append('file', file);
    return remote.post(path(`/${walkdownId}/task-attachments/${taskId}`), formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  };
const getAttachmentsByWalkdownId = (path: Path, remote: Remote) => (walkdownId: number) =>
  remote.get(path(`/${walkdownId}/attachments/list`));
const getAttachmentByTaskId = (path: Path, remote: Remote) => (walkdownId: number, taskId: number) =>
  remote.get(path(`/${walkdownId}/task-attachments/${taskId}`));
const updateWalkdown = (path: Path, remote: Remote) => (walkdownId: number, updates: any) =>
  remote.patch(path(`/${walkdownId}`), updates);
const getWalkdownById = (path: any, remote: any) => (id: number) => remote.get(path(`/${id}`));
const deleteWalkdownAttachment = (path: Path, remote: Remote) => (walkdownId: number, attachmentId: number) =>
  remote.del(path(`/${walkdownId}/attachments/${attachmentId}`));
const deleteWalkdownTaskAttachment = (path: Path, remote: Remote) => (walkdownId: number, taskId: number) =>
  remote.del(path(`/${walkdownId}/task-attachments/${taskId}`));
const downloadAttachment = (path: Path, remote: Remote) => (walkdownId: number, attachmentId: number) =>
  remote.download(path(`/${walkdownId}/attachments/${attachmentId}/download`));
const downloadTaskAttachment = (path: Path, remote: Remote) => (walkdownId: number, taskId: number) =>
  remote.download(path(`/${walkdownId}/task-attachments/${taskId}/download`));

const updateWalkdownTask =
  (path: any, remote: any) =>
  (walkdownId: number, categoryId: number, taskId: number, updates: Partial<WalkdownTask>) =>
    remote.patch(path(`/${walkdownId}/categories/${categoryId}/tasks/${taskId}`), updates);

const setWalkdownTaskComment =
  (path: any, remote: any) => (walkdownId: number, categoryId: number, taskId: number, text: string) =>
    remote.post(path(`/${walkdownId}/categories/${categoryId}/tasks/${taskId}/comment`), { text });

const submitWalkdown = (path: any, remote: any) => (walkdownId: number) => remote.patch(path(`/${walkdownId}/submit`));
const approveWalkdown = (path: any, remote: any) => (walkdownId: number, comment: string) =>
  remote.patch(path(`/${walkdownId}/approve`), { comment });
const rejectWalkdown = (path: any, remote: any) => (walkdownId: number, comment: string) =>
  remote.patch(path(`/${walkdownId}/reject`), { comment });
const deleteWalkdown = (path: Path, remote: Remote) => (walkdownId: number) => remote.del(path(`/${walkdownId}`));

const addTaskToWalkdown =
  (path: Path, remote: Remote) => (walkdownId: number, categoryId: number, task: Partial<WalkdownTask>) =>
    remote.post(path(`/${walkdownId}/categories/${categoryId}/tasks`), task);

export default (path: Path, remote: Remote) => ({
  generateWalkdown: generateWalkdown(path, remote),
  downloadWalkdown: downloadWalkdown(path, remote),
  getInprogressWalkdowns: getInprogressWalkdowns(path, remote),
  getArchievedWalkdowns: getArchievedWalkdowns(path, remote),
  getSubmittedWalkdowns: getSubmittedWalkdowns(path, remote),
  createWalkdown: createWalkdown(path, remote),
  uploadWalkdownAttachment: uploadWalkdownAttachment(path, remote),
  uploadTaskAttachment: uploadTaskAttachment(path, remote),
  updateWalkdown: updateWalkdown(path, remote),
  getWalkdownById: getWalkdownById(path, remote),
  getAttachmentsByWalkdownId: getAttachmentsByWalkdownId(path, remote),
  getAttachmentByTaskId: getAttachmentByTaskId(path, remote),
  deleteWalkdownAttachment: deleteWalkdownAttachment(path, remote),
  deleteWalkdownTaskAttachment: deleteWalkdownTaskAttachment(path, remote),
  downloadAttachment: downloadAttachment(path, remote),
  updateWalkdownTask: updateWalkdownTask(path, remote),
  setWalkdownTaskComment: setWalkdownTaskComment(path, remote),
  downloadTaskAttachment: downloadTaskAttachment(path, remote),
  submitWalkdown: submitWalkdown(path, remote),
  approveWalkdown: approveWalkdown(path, remote),
  rejectWalkdown: rejectWalkdown(path, remote),
  deleteWalkdown: deleteWalkdown(path, remote),
  addTaskToWalkdown: addTaskToWalkdown(path, remote),
});
