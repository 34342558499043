import React, { useContext, useEffect, useState, useCallback, createContext } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import Api from '../api';
import * as uiActions from '../state/ui/actions';
import { UI_MESSAGE } from '../utils/ui-messages';

export interface Note {
  note: string;
  isDone: boolean;
  createdOn: Date;
  changedAt: Date;
  id: number;
}

export interface UserNotesProviderProps {
  addNote(note: string): void;
  userNotes: Note[];
  setDone(id: number): string;
  setUpdate(id: number, note: string): void;
}

const UserNotesContext = createContext({} as UserNotesProviderProps);

const useUserNotes = () => {
  const context = useContext(UserNotesContext);
  if (R.isNil(context)) {
    throw new Error('useUserNotes must be used inside an UserNotesProvider');
  }
  return context;
};

export const UserNotesProvider = (props?: any) => {
  const dispatch = useDispatch();

  const [userNotes, setUserNotes] = useState<Array<Note>>([] as Note[]);

  const addNote = useCallback(
    async (note: string) => {
      try {
        await Api.addUserNotes(note);
        const response = await Api.getUserNotes();
        setUserNotes(response);
        dispatch(uiActions.notesMessage('Added'));
      } catch (e: any) {
        dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
      }
    },
    [setUserNotes]
  );

  const setDone = async (filterID: number) => {
    try {
      await Api.setUserNoteDone(filterID);
      const response = await Api.getUserNotes();
      setUserNotes(response);
      dispatch(uiActions.notesMessage('Done'));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  };

  const setUpdate = async (id: number, note: string) => {
    try {
      await Api.updateUserNote(id, note);
      const response = await Api.getUserNotes();
      setUserNotes(response);
      dispatch(uiActions.notesMessage('Updated'));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  };

  useEffect(() => {
    Api.getUserNotes().then(setUserNotes);
  }, []);

  const value = {
    userNotes,
    addNote,
    setDone,
    setUpdate,
  };

  return (
    <UserNotesContext.Provider
      value={value}
      {...props}
    />
  );
};

export default useUserNotes;
