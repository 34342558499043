import React, { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Api from '../api';
import * as uiActions from '../state/ui/actions';
import { UI_MESSAGE } from '../utils/ui-messages';
import useDownloader from './downloader';

const resolveAfter2Seconds = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, 2000);
  });

const walkdownFilename = (id: number) => `walkdown-${id}.pdf`;
const walkdownTemplateFilename = (id: number) => `walkdown-template-${id}.pdf`;

// Context
const WalkdownsContext = createContext<any>(null);

// Hook
const useWalkdowns = () => {
  const data = useContext(WalkdownsContext);
  if (!data) {
    throw new Error('useWalkdowns must be used inside an WalkdownsProvider');
  }
  return data;
};

export default useWalkdowns;

interface ParamTypes {
  children: any;
}

interface CreateWalkdownTypes {
  templateId: number;
  elementId: number;
}

interface UpdateWalkdownTypes {
  title: string;
  approver: string;
  year: number;
  quarter: string | number;
  startDate: string;
  completedDate: string;
  person: string;
}
// Provider
export const WalkdownsProvider = ({ children }: ParamTypes) => {
  const dispatch = useDispatch();
  const { onDownload } = useDownloader();
  const [inprogressWalkdowns, setInprogressWalkdowns] = useState(null);
  const [archivedWalkdowns, setArchivedWalkdowns] = useState(null);

  useEffect(() => {
    Api.getArchievedWalkdowns().then((response: any) => {
      setArchivedWalkdowns(response);
    });

    Api.getInprogressWalkdowns().then((response: any) => {
      setInprogressWalkdowns(response);
    });
  }, []);

  const generateWalkdown = useCallback(async (id: number) => {
    try {
      dispatch(uiActions.genericMessage('Generating...'));
      await Api.generateWalkdown(id);
      await resolveAfter2Seconds();
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, []);

  const downloadWalkdown = useCallback(async (id: number) => {
    try {
      dispatch(uiActions.genericMessage('Downloading'));
      const blob = await Api.downloadWalkdown(id);
      await onDownload(blob, walkdownFilename(id));
      dispatch(uiActions.genericMessage('Downloaded'));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, []);

  const generateAndDownloadWalkdown = async (id: number) => {
    const generatingMessage = setInterval(() => {
      dispatch(uiActions.genericMessage('Generating...'));
    }, 5000);

    await generateWalkdown(id);
    downloadWalkdown(id);
    clearInterval(generatingMessage);
  };

  const generateWalkdownTemplate = useCallback(async (id: number) => {
    try {
      dispatch(uiActions.genericMessage('Generating...'));
      await Api.generateWalkdownTemplate(id);
      await resolveAfter2Seconds();
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, []);

  const downloadWalkdownTemplate = useCallback(async (id: number) => {
    try {
      dispatch(uiActions.genericMessage('Downloading'));
      const blob = await Api.downloadWalkdownTemplate(id);
      await onDownload(blob, walkdownTemplateFilename(id));
      dispatch(uiActions.genericMessage('Downloaded'));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, []);

  const generateAndDownloadWalkdownTemplate = async (id: number) => {
    const generatingMessage = setInterval(() => {
      dispatch(uiActions.genericMessage('Generating...'));
    }, 5000);

    await generateWalkdownTemplate(id);
    downloadWalkdownTemplate(id);
    clearInterval(generatingMessage);
  };

  const create = useCallback(async (walkdown: CreateWalkdownTypes) => {
    const newWalkdown = await Api.createWalkdown({ ...walkdown });
    const response = await Api.getInprogressWalkdowns();
    setInprogressWalkdowns(response);
    return newWalkdown;
  }, []);

  const update = useCallback(async (walkdownId: number, walkdown: UpdateWalkdownTypes) => {
    await Api.updateWalkdown(walkdownId, walkdown);
    const response = await Api.getInprogressWalkdowns();
    setInprogressWalkdowns(response);
  }, []);

  const refresh = useCallback(async () => {
    const response = await Api.getInprogressWalkdowns();
    setInprogressWalkdowns(response);
  }, []);

  const del = useCallback(async (walkdownId: number) => {
    await Api.deleteWalkdown(walkdownId);
    const response = await Api.getInprogressWalkdowns();
    setInprogressWalkdowns(response);
  }, []);

  const value = {
    generateWalkdown,
    generateAndDownloadWalkdown,
    generateAndDownloadWalkdownTemplate,
    downloadWalkdown,
    generateWalkdownTemplate,
    downloadWalkdownTemplate,
    inprogressWalkdowns,
    archivedWalkdowns,
    create,
    update,
    del,
    refresh,
  };

  return <WalkdownsContext.Provider value={value}>{children}</WalkdownsContext.Provider>;
};
