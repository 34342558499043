import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledTableRow from '@material-ui/core/TableRow';
import UnstyledTableCell from '@material-ui/core/TableCell';
import { SortableColumn } from './types';

const TableCell = styled(UnstyledTableCell)``;

const TableRow = styled(UnstyledTableRow)`
  ${TableCell} {
    height: 38px;
    padding: 0;
    border: none;
    max-width: 0;
    padding-right: 8px;
  }
`;

const growableColumnProps = {
  style: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const cellProps = (column: SortableColumn) => (R.isNil(column.width) ? growableColumnProps : {});

interface Props {
  className?: string;
  record: any;
  columns: Array<SortableColumn>;
}

const Row = ({ className, record, columns }: Props) => {
  const cells = columns.map(column => (
    <TableCell
      key={column.id}
      align={R.propOr('left', 'align', column)}
      {...cellProps(column)}
    >
      {R.has('render', column) ? column.render(record, column) : column.valueFn(record)}
    </TableCell>
  ));

  return <TableRow className={className}>{cells}</TableRow>;
};

export default Row;
