import { TabState, WorkRequestCoding } from '../../../pages/cwt/wr/types';
import { ExtendedWorkRequest, WorkRequest } from '../../../types/cwt/workrequest';
import { Path, Remote } from '../../types';

export type SaveWorkRequestCodingBody = Omit<WorkRequestCoding, 'id' | 'workRequestId' | 'createdAt' | 'updatedAt'>;

const getAllWorkRequests =
  (path: Path, remote: Remote) =>
  (state?: TabState): Promise<ExtendedWorkRequest[]> =>
    remote.get(path(`/${state ? `?state=${state}` : ''}`));

const getWorkRequest =
  (path: Path, remote: Remote) =>
  (workRequestNumber: string): Promise<WorkRequest> =>
    remote.get(path(`/${workRequestNumber}`));

const getWorkRequestCoding =
  (path: Path, remote: Remote) =>
  (workRequestNumber: string): Promise<WorkRequestCoding | undefined> =>
    remote.get(path(`/${workRequestNumber}/coding`));

const saveWorkRequestCoding =
  (path: Path, remote: Remote) =>
  (workRequestNumber: string, data: SaveWorkRequestCodingBody): Promise<void> =>
    remote.put(path(`/${workRequestNumber}/coding`), data);

const exportWorkRequestCodings =
  (path: Path, remote: Remote) =>
  (workRequestNumbers?: string[]): Promise<string> =>
    remote.post(path(`/export`), { workRequestNumbers });

const workRequests = (path: Path, remote: Remote) => ({
  getAllWorkRequests: getAllWorkRequests(path, remote),
  getWorkRequest: getWorkRequest(path, remote),
  getWorkRequestCoding: getWorkRequestCoding(path, remote),
  saveWorkRequestCoding: saveWorkRequestCoding(path, remote),
  exportWorkRequestCodings: exportWorkRequestCodings(path, remote),
});

export default workRequests;
