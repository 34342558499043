import React from 'react';
import styled from 'styled-components';
import PageTitle from './PageTitle';

const Body = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${PageTitle} {
    margin-bottom: 10px;
  }
`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  bodyComponent?: any;
}

const Page = ({ children, className, title, bodyComponent = <Body /> }: Props) => (
  <Container className={className}>
    <PageTitle>{title}</PageTitle>
    {React.cloneElement(bodyComponent, { children })}
  </Container>
);

export default Page;
