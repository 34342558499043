import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import actions from './actions/reducer';
import customIndicators from './custom-indicators/reducer';
import elementAttachments from './element-attachments/reducer';
import dashboards from './dashboards/reducer';
import elements from './elements/reducer';
import executiveSummaryUi from './executive-summary-ui/reducer';
import facilities from './facilities/reducer';
import indicators from './indicators/reducer';
import indicatorRecords from './indicator-records/reducer';
import my from './my/reducer';
import scorecard from './scorecard/reducer';
import scorecardColours from './scorecard-colours/reducer';
import recordsSummary from './records-summary/reducer';
import reports from './reports/reducer';
import totalScoreColours from './total-score-colours/reducer';
import users from './users/reducer';
import ui from './ui/reducer';
import admin from '../pages/admin/state/reducer';
import dashboard from '../pages/dashboard/state/reducer';
import healthReports from '../pages/health-reports/state/reducer';
import recordDetails from '../pages/live-reports/tabs/review-records/state/reducer';

const createPageReducers = () =>
  combineReducers({
    admin,
    dashboard,
    healthReports,
    recordDetails,
  });

export default history =>
  combineReducers({
    router: connectRouter(history),
    actions,
    customIndicators,
    dashboards,
    elements,
    elementAttachments,
    executiveSummaryUi,
    facilities,
    indicators,
    indicatorRecords,
    my,
    recordsSummary,
    reports,
    scorecard,
    scorecardColours,
    totalScoreColours,
    ui,
    users,
    pages: createPageReducers(),
  });
