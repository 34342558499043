import * as R from 'ramda';

import { Indicator } from 'echo-scorecard';
import { InteractiveCell } from '../InteractiveCell';
import { Tooltip } from '../StyledComponents';
import { IndicatorRowRoot } from './IndicatorRowRoot';
import { Props } from '../types';
import { UnitValue } from './UnitValue';

export const EditableIndicatorRow = ({
  indicator,
  scorecard,
  onCalculatedScoreClicked,
  onManualScoreClicked,
}: Omit<Props, 'className' | 'editable' | 'onUnitTotalScoreClicked'> & { indicator: Indicator }) => {
  const handleClickCell = (unit: string) => () => {
    if (indicator.type === 'calculated' && onCalculatedScoreClicked) {
      onCalculatedScoreClicked(indicator.id, unit);
    } else if (onManualScoreClicked) {
      onManualScoreClicked(indicator.id, unit);
    }
  };
  const unitItems = scorecard.units.map((unitId: string) => (
    <InteractiveCell
      key={unitId}
      colour={
        scorecard.unitMap[unitId]?.mcr
          ? 'grey'
          : R.pathOr('white', ['scores', indicator.id, unitId, 'colour'], scorecard)
      }
      overridden={R.pathOr(false, ['scores', indicator.id, unitId, 'overridden'], scorecard)}
      onClick={handleClickCell(unitId)}
    >
      <Tooltip
        placement="top"
        arrow
        title={`Indicator weight: ${R.path(
          ['values', indicator.id, unitId, 'indicatorWeight'],
          scorecard
        )}%; \n Unit Weight: ${R.path(['values', indicator.id, unitId, 'unitWeight'], scorecard)}%`}
      >
        <span>
          <UnitValue
            isCalculated={indicator.type === 'calculated'}
            value={R.path(['scores', indicator.id, unitId, 'value'], scorecard)}
          />
        </span>
      </Tooltip>
    </InteractiveCell>
  ));

  return (
    <IndicatorRowRoot
      name={indicator.name}
      id={indicator.id}
      definition={indicator.definition}
    >
      {unitItems}
    </IndicatorRowRoot>
  );
};
