import { useState, useCallback } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import * as actionActions from '../state/actions/actions';

const useEditAction = actions => {
  const dispatch = useDispatch();
  const [editAction, setEditAction] = useState(null);

  const showEditActionDialog = useCallback(
    actionId => {
      setEditAction(R.find(action => action.id === actionId, actions));
    },
    [actions, setEditAction]
  );

  const clearEditAction = useCallback(() => {
    setEditAction(null);
  }, [setEditAction]);

  const updateAction = useCallback(
    action => {
      dispatch(actionActions.updateActionRequest(action));
      clearEditAction();
    },
    [dispatch, clearEditAction]
  );

  return {
    editAction,
    showEditActionDialog,
    clearEditAction,
    updateAction,
  };
};

export default useEditAction;
