import React from 'react';
import styled from 'styled-components';
import UnstyledListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import NameText from './NameText';
import StatusText from './StatusText';
import Link from './Link';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import DownloadButton from '../../../../components/DownloadButton';
import ReportStatus from './ReportStatus';
import useReport from '../../../../hooks/report';
import CustomTooltip from '../../../../components/Tooltip';

const ListItem = styled(UnstyledListItem)``;

interface Props {
  className?: string;
  report?: any;
  baseUrl?: string;
  onClose?: any;
  onDownload?: any;
}

const ReportItem = ({ className, report, baseUrl, onClose, onDownload }: Props) => {
  const { generateAndDownloadDraftReport } = useReport(report.id);

  return (
    <ListItem
      className={className}
      button={true}
    >
      <Grid
        container
        justifyContent="space-between"
      >
        <Grid
          item
          xs={10}
          container
          spacing={2}
        >
          <Grid
            item
            xs={4}
          >
            <NameText>
              [{elementTypePrefix(report.elementType)}] {report.elementName} ({report.facilityName})
            </NameText>
          </Grid>
          <Grid
            item
            xs={1}
          >
            <StatusText>
              {report.year} Q{report.quarter}
            </StatusText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <ReportStatus report={report} />
          </Grid>
        </Grid>
        <Grid item>
          {report.state !== 'approved' && (
            <CustomTooltip
              placement="top"
              arrow
              title={'Download PDF'}
            >
              <DownloadButton
                size="small"
                onClick={generateAndDownloadDraftReport}
              />
            </CustomTooltip>
          )}
          {report.state !== 'approved' && (
            <CustomTooltip
              placement="top"
              arrow
              title={'Open Report'}
            >
              <Button
                component={Link}
                to={`${baseUrl}/${report.id}`}
              >
                Open
              </Button>
            </CustomTooltip>
          )}
          {report.state === 'approved' && (
            <>
              <Button
                component={Link}
                to={`${baseUrl}/review/${report.id}`}
              >
                Open
              </Button>
              {report.uploadId && (
                <CustomTooltip
                  placement="top"
                  arrow
                  title={'Download PDF'}
                >
                  <DownloadButton
                    size="small"
                    onClick={onDownload}
                  />
                </CustomTooltip>
              )}
            </>
          )}
          {report.state === 'unsubmitted' && onClose && (
            <CustomTooltip
              placement="top"
              arrow
              title={'Close Report'}
            >
              <IconButton
                size="small"
                title="Close Report"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {report.state === 'changes-requested' && onClose && (
            <CustomTooltip
              placement="top"
              arrow
              title={'Close Report'}
            >
              <IconButton
                size="small"
                title="Close Report"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ReportItem;
