import styled from 'styled-components';
import ActionButton from '../../../../../components/ActionButton';
import ActionsTable from './ActionsTable';
import UnstyledFormDialog from '../../../../../components/FormDialog';
import AddEditActionForm from '../../forms/AddEditActionForm';
import { useActions } from '../../../hooks/useActions';

const AddActionButton = styled(ActionButton)`
  margin-bottom: 10px;
`;
const FormDialog = styled(UnstyledFormDialog)`
  max-height: 600px;
  max-width: 500px;
  min-width: 500px;
`;

const ActionsTab = () => {
  const {
    handleSubmit,
    reset,
    openAddEditActionDialog,
    setOpenAddEditActionDialog,
    handleOpenAddActionDialog,
    addEdit,
    fetchActions,
  } = useActions();

  const cancelAddAction = () => {
    setOpenAddEditActionDialog(false);
    reset();
  };

  const handleSave = async () => {
    await handleSubmit();
    fetchActions();
  };

  return (
    <>
      <AddActionButton
        color="primary"
        variant="text"
        onClick={() => handleOpenAddActionDialog()}
      >
        Add Action
      </AddActionButton>
      <ActionsTable style={{ minHeight: 600 }} />
      {openAddEditActionDialog && (
        <FormDialog
          title={addEdit === 'add' ? 'Add Action' : 'Edit Action'}
          onSave={handleSave}
          onCancel={cancelAddAction}
        >
          <AddEditActionForm />
        </FormDialog>
      )}
    </>
  );
};

export default ActionsTab;
