import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as pageActions from '../state/actions';
import * as pageSelectors from '../state/selectors';

const useRecordDetails = (record, indicatorShortName) => {
  const dispatch = useDispatch();

  const recordDetails = useSelector(pageSelectors.getRecordDetails);

  useEffect(() => {
    dispatch(pageActions.loadRecordDetailsRequest(indicatorShortName, record));
  }, [dispatch, record, indicatorShortName]);

  return {
    recordDetails,
  };
};

export default useRecordDetails;
