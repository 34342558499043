import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const ActionButton = styled(Button)`
  border: ${({ theme }) => theme.borders.thin.solid1};
  border-radius: 2px;
  font: ${({ theme }) => theme.fonts.medium14};

  padding: 8px 20px;

  &.MuiButton-text {
    background-color: ${({ theme }) => theme.colours.white};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
  }

  &.MuiButton-text:hover {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey2};
  }

  &.MuiButton-textPrimary {
    background-color: ${({ theme }) => theme.colours.teals.teal1};
    color: ${({ theme }) => theme.colours.white};
  }

  &.MuiButton-textPrimary:hover {
    background-color: ${({ theme }) => theme.colours.teals.teal2};
  }

  &.MuiButton-textSecondary {
    background-color: ${({ theme }) => theme.colours.blues.blue1};
    color: ${({ theme }) => theme.colours.white};
  }

  &.MuiButton-textSecondary:hover {
    background-color: ${({ theme }) => theme.colours.blues.blue2};
  }

  &.error {
    background-color: ${({ theme }) => theme.colours.reds.red1};
    color: ${({ theme }) => theme.colours.white};
  }

  &.error:hover {
    background-color: ${({ theme }) => theme.colours.reds.red2};
    color: ${({ theme }) => theme.colours.white};
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey2};
  }
`;

export default ActionButton;
