import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import useDeepCompareEffect from 'use-deep-compare-effect';
import * as reportActions from '../state/reports/actions';
import * as reportsSelectors from '../state/reports/selectors';

interface Filters {
  elementName: string;
  elementType: string;
  facility: string;
  quarter: string;
  year: string;
}

const useReports = (states: string[], load = false) => {
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (load) {
      dispatch(reportActions.loadAllRequest(states));
    }
  }, [dispatch, states, load]);

  const reports = useSelector(reportsSelectors.getReports);
  const isLoading = useSelector(reportsSelectors.areReportsLoading);

  const reverseApprove = useCallback(async (reportId: string) => {
    dispatch(reportActions.reverseApproveRequest(reportId, states));
  }, []);

  // TODO: Consider sending this to API in the future
  const getFilteredReports = useCallback(
    (filters: Filters) =>
      reports
        .filter(({ elementState }) => {
          if (states.includes('approved')) {
            return true;
          }
          if (elementState === 'ACTIVE') {
            return true;
          }
          return false;
        })
        .filter(({ elementName }) => {
          if (!R.isNil(filters.elementName) && filters.elementName !== 'none') {
            return filters.elementName === elementName;
          }
          return true;
        })
        .filter(({ elementType }) => {
          if (!R.isNil(filters.elementType) && filters.elementType !== 'none') {
            return filters.elementType === elementType;
          }
          return true;
        })
        .filter(({ facilityId }) => {
          if (!R.isNil(filters.facility) && filters.facility !== 'none') {
            return filters.facility === facilityId;
          }
          return true;
        })
        .filter(({ quarter }) => {
          if (!R.isNil(filters.quarter) && filters.quarter !== 'none') {
            return filters.quarter === quarter;
          }
          return true;
        })
        .filter(({ year }) => {
          if (!R.isNil(filters.year) && filters.year !== 'none') {
            return filters.year === year;
          }
          return true;
        }),
    [reports]
  );

  const uniqueYears = R.compose<any, any, any, Array<{ label: number; value: number }>>(
    R.map((year: number) => ({ label: year, value: year })),
    R.uniq,
    R.map(({ year }) => year)
  );

  const years = useMemo(() => uniqueYears(reports), [reports]);

  return {
    reports,
    getFilteredReports,
    years,
    isLoading,
    reverseApprove,
  };
};

export default useReports;
