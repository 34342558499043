import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useWalkdownTemplatesList, { WalkdownTemplatesListProvider } from './hooks/useWalkdownTemplatesList';
import ActionButton from '../../components/ActionButton';
import Page from '../../components/Page';
import NoRecords from '../../components/NoRecords';
import elementTypePrefix from '../../utils/element-type-prefix';
import useMyElements from '../../hooks/my-elements';
import FilterSelector from '../../components/FilterSelector';
import useForm from '../../hooks/form';
import { formConfig, defaultValues } from './config/singleWalkdownFormConfig';
import useWalkdowns from '../../hooks/walkdowns';
import { Downloader } from '../../hooks/downloader';
import { Template } from './types/types';

const StartWalkdown = () => {
  const { create, generateAndDownloadWalkdownTemplate } = useWalkdowns();
  const history = useHistory();
  const { list } = useWalkdownTemplatesList();
  const { values, setField } = useForm(formConfig, defaultValues, create);
  const filterSelectorHandler = (elementId: string) => {
    setField('elementId', elementId);
  };
  const { myElements } = useMyElements();
  const handleButtonClick = async (elementId: number, templateId: number) => {
    const newWalkdown = await create({ templateId, elementId });
    history.push(`view/${newWalkdown.id}`);
  };
  const elementOptions = myElements
    .filter((element: any) => element.elementType !== 'program')
    .map((element: any) => ({
      name: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
      value: element.elementId,
    }));
  const filteredList = values.elementId && list.filter(template => template.elementId === values.elementId);

  return (
    <Grid container>
      <Grid
        container
        style={{ paddingTop: '1em', paddingBottom: '1em' }}
      >
        <Button
          color="primary"
          onClick={history.goBack}
        >
          <ArrowBackIosIcon color="primary" />
          Back
        </Button>
      </Grid>
      <Grid
        container
        style={{ paddingTop: '1em', paddingBottom: '1.5em' }}
      >
        <FilterSelector
          name="Element"
          value={values.elementId}
          onChange={filterSelectorHandler}
          options={elementOptions}
        />
      </Grid>
      <Grid container>
        <Page title="Start A Walkdown">
          <div>Choose a template to begin</div>
          {values.elementId && filteredList.length === 0 ? (
            <Grid
              container
              style={{ width: '100em' }}
            >
              <NoRecords />
            </Grid>
          ) : (
            filteredList &&
            filteredList.map(({ id, title }: Template) => (
              <>
                <Grid
                  container
                  style={{ paddingTop: '1em' }}
                >
                  <ActionButton
                    fullWidth
                    key={id}
                    variant="outlined"
                    style={{ width: 500, height: 40 }}
                    onClick={() => {
                      handleButtonClick(values.elementId, id);
                    }}
                  >
                    {title.substring(0, 50)}
                  </ActionButton>
                  <Button
                    color="primary"
                    onClick={() => {
                      generateAndDownloadWalkdownTemplate(id);
                    }}
                  >
                    <PrintOutlinedIcon />
                  </Button>
                </Grid>
              </>
            ))
          )}
        </Page>
      </Grid>
    </Grid>
  );
};

export default () => (
  <Downloader>
    <WalkdownTemplatesListProvider>
      <StartWalkdown />
    </WalkdownTemplatesListProvider>
  </Downloader>
);
