import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as elementActions from '../state/elements/actions';
import * as elementSelectors from '../state/elements/selectors';

export interface EchoElement {
  id: number;
  type: 'system' | 'component' | 'program';
  state: 'ACTIVE' | 'INACTIVE';
  facilityId: string;
  facilityName: string;
  name: string;
  referenceReportingYear: string;
  reportingFrequency: string;
  category: string;
  elementSchemaName: string;
  primaryRse: string;
  primaryRseName: string;
  nextReportDueDate: Date;
  referenceReportingQuarter: number;
  pmpRequired: boolean;
  tier: string;
  sis?: 'Yes' | 'No' | null;
  safetyRelatedSystemClassification: Record<any, any>;
  pmpDate: string | number;
  isMcrExempt: boolean;
}

const useElements = (load = true) => {
  const dispatch = useDispatch();

  const loadElements = useCallback(() => {
    dispatch(elementActions.loadAllRequest());
  }, [dispatch]);

  useEffect(() => {
    if (load) {
      loadElements();
    }
  }, [load, loadElements]);

  const elements: Array<EchoElement> = useSelector(elementSelectors.getAllElements);

  return {
    elements,
    loadElements,
  };
};

export default useElements;
