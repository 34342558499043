import React, { useState, useEffect, createContext, useCallback } from 'react';
import api from '../api';
import { OutagePlan } from '../types/outage-plans';

export const OutagePlansContext = createContext<OutagePlan[]>([]);

const OutagePlansProvider = (props: React.PropsWithChildren) => {
  const [data, setData] = useState<OutagePlan[]>([{ outage_code: 'default', status: 'Planned' }]);

  const getOutagePlans = useCallback(async () => {
    try {
      const newPlans = await api.getOutagePlans();
      if (newPlans.length > 0) {
        setData(newPlans);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    void getOutagePlans();
  }, []);

  return <OutagePlansContext.Provider value={data}>{props.children}</OutagePlansContext.Provider>;
};

export default OutagePlansProvider;
