import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Panel = ({ children, className }: Props) => (
  <Paper
    square
    className={className}
  >
    <Box p={2}>{children}</Box>
  </Paper>
);

export default Panel;
