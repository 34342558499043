import { useCallback, useState } from 'react';
import * as R from 'ramda';
import useWalkdowns from '../../../hooks/walkdowns';

interface FilterOptions {
  elementName: string;
  elementType: string;
  facility: string;
  quarter: string;
  year: string;
}

const useFilteredArchivedWalkdowns = () => {
  const { archivedWalkdowns } = useWalkdowns();

  const [filters, setFilters] = useState({
    elementName: 'none',
    facility: 'none',
    elementType: 'none',
    quarter: 'none',
    year: 'none',
  });

  const yearOptions =
    archivedWalkdowns &&
    archivedWalkdowns.reduce((years: any, currentWalkdown: any) => {
      const key = { value: currentWalkdown.year, label: currentWalkdown.year };

      const keyCheck = years.find((obj: any) => obj.value === currentWalkdown.year);

      if (!keyCheck) {
        years.push(key);
      }

      return years;
    }, []);

  const getFilteredWalkdowns = useCallback(
    (filterOptions: FilterOptions) =>
      archivedWalkdowns &&
      archivedWalkdowns
        .filter(({ elementName }: any) => {
          if (!R.isNil(filterOptions.elementName) && filterOptions.elementName !== 'none') {
            return filterOptions.elementName === elementName;
          }
          return true;
        })
        .filter(({ elementType }: any) => {
          if (!R.isNil(filterOptions.elementType) && filterOptions.elementType !== 'none') {
            return filterOptions.elementType === elementType;
          }
          return true;
        })
        .filter(({ facilityName }: any) => {
          if (!R.isNil(filterOptions.facility) && filterOptions.facility !== 'none') {
            return filterOptions.facility === facilityName?.toLowerCase();
          }
          return true;
        })
        .filter(({ quarter }: any) => {
          if (!R.isNil(filterOptions.quarter) && filterOptions.quarter !== 'none') {
            return filterOptions.quarter === quarter?.toString();
          }
          return true;
        })
        .filter(({ year }: any) => {
          if (!R.isNil(filterOptions.year) && filterOptions.year !== 'none') {
            return filterOptions.year === year;
          }
          return true;
        }),
    [archivedWalkdowns]
  );

  const filteredWalkdowns = getFilteredWalkdowns(filters);

  return {
    filters,
    setFilters,
    yearOptions,
    filteredWalkdowns,
  };
};

export default useFilteredArchivedWalkdowns;
