import { useState, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import Api from '../../../api';

const processRecordCount = async (report: any, indicatorShortName: any) => {
  const result = await Api.getIndicatorsByShortName([indicatorShortName]);
  const indicatorRecords: any = R.head(result);
  const indicatorId = R.prop('id')(indicatorRecords);
  const records = await Api.getIndicatorRecordsByQuarter(
    report.elementId,
    report.year,
    report.quarter,
    indicatorId,
    null
  );

  //  useCallback prevents the canSubmit check from running and returning false preemptively

  if (R.isNil(records)) {
    return undefined;
  }

  const filteredRecords = R.filter(R.propSatisfies(R.isNil, 'applicable'), records);

  return R.length(filteredRecords);
};

const ffCountIndicators = {
  system: 'functional-failures',
  component: 'comp-functional-failures',
};

const ocaCountIndicators = {
  system: 'outstanding-corrective-actions',
  component: 'comp-outstanding-corrective-actions',
};

const useCanSubmit = (report: any) => {
  const [canSubmit, setCanSubmit] = useState<string | boolean>('Not Checked');
  const [ffCount, setFfCount] = useState<number | undefined>();
  const [ocaCount, setOcaCount] = useState<number | undefined>();

  const checkIndicatorRecords = R.includes(report.state, ['unsubmitted', 'changes-requested']);

  const checkCanSubmit = useCallback(async () => {
    const ffCountResult = await processRecordCount(
      report,
      R.propOr(R.prop('system', ffCountIndicators), report.elementType, ffCountIndicators)
    );
    const ocaCountResult = await processRecordCount(
      report,
      R.propOr(R.prop('component', ocaCountIndicators), report.elementType, ocaCountIndicators)
    );

    const canSubmitEval =
      checkIndicatorRecords && // Only check if the report is in a state that requires it
      ((R.isNil(ffCountResult) && R.isNil(ocaCountResult)) || // No Records founds
        (ffCountResult === 0 && ocaCountResult === 0) || // No applicable Records found
        report.elementType === 'program'); // Program reports don't have indicators

    setCanSubmit(canSubmitEval);
    setFfCount(ffCountResult);
    setOcaCount(ocaCountResult);
  }, [report, checkIndicatorRecords]);

  useEffect(() => {
    checkCanSubmit();
  }, [canSubmit]);

  return {
    canSubmit,
    ffCount,
    ocaCount,
  };
};

export default useCanSubmit;
