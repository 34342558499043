import { AllAttachmentTypesProvider } from '../../../../hooks/all-attachment-types';
import FilterPanel from './components/FilterPanel';
import { AttachmentTypeProvider } from './hooks/attachment-type';
import { QuarterProvider } from './hooks/quarter';

const UploadAttachmentsLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AttachmentTypeProvider>
      <QuarterProvider>
        <AllAttachmentTypesProvider>
          <FilterPanel />
          {children}
        </AllAttachmentTypesProvider>
      </QuarterProvider>
    </AttachmentTypeProvider>
  );
};

export default UploadAttachmentsLayout;
