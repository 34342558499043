import React from 'react';
import styled from 'styled-components';
import UnstyledPaper from '@material-ui/core/Paper';
import useMyAssignments from '../../../../hooks/my-assignments';
import useSelectedItem from '../../../../hooks/selected-item';
import Page from '../../../../components/Page';
import ElementFilterPanel from '../../../../components/ElementFilterPanel';
import ChartPanel from './ChartPanel';

const Paper = styled(UnstyledPaper)`
  min-height: calc(100vh - 100px);
`;

const ForecastPage = () => {
  const { myAssignments } = useMyAssignments();
  const { selectedItem: selectedAssignment, selectItem: selectAssignment } = useSelectedItem(
    myAssignments,
    'elementId'
  );

  return (
    <Page
      title="Element Forecast"
      bodyComponent={<Paper square />}
    >
      <ElementFilterPanel
        elements={myAssignments}
        selectedElement={selectedAssignment}
        onChangeElement={selectAssignment}
      />
      {selectedAssignment ? <ChartPanel selectedAssignment={selectedAssignment} /> : <div />}
    </Page>
  );
};

export default ForecastPage;
