import React, { useState } from 'react';
import styled from 'styled-components';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CarlaText from '../../../../components/CarlaText';
import useConfirm from '../../../../hooks/confirm';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';

import UnstyledMappingForm from './MappingForm';
import useElementMappings from '../../../../hooks/element-mappings';
import { UnitsProvider } from '../../../../hooks/units';

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const MappingForm = styled(UnstyledMappingForm)`
  margin-bottom: 2%;
`;

const mappingToKey = (mapping: any) => `${mapping.facilityId}|${mapping.unitNumber}|${mapping.usi}|${mapping.location}`;

const MappingTableRows = ({ elementMappings, onRemove }: any) =>
  elementMappings.map((mapping: any) => (
    <TableRow key={mappingToKey(mapping)}>
      <TableCell align="center">
        <IconButton onClick={() => onRemove(mapping)}>
          <RemoveCircleIcon />
        </IconButton>
      </TableCell>
      <TableCell align="center">{mapping.facilityName}</TableCell>
      <TableCell align="center">{mapping.unitNumber}</TableCell>
      <TableCell align="center">{mapping.usi}</TableCell>
      <TableCell align="center">{mapping.location}</TableCell>
      <TableCell align="center">{mapping.elementUnit}</TableCell>
    </TableRow>
  ));

interface Props {
  className?: string;
  elementUnits: any[];
}

const MappingsTable = ({ className, elementUnits }: Props) => {
  const { elementMappings, onAddMapping, onRemoveMapping } = useElementMappings();
  const [addMapping, setAddMapping] = useState(false);
  const { confirming, confirm, onConfirm, onCancel } = useConfirm(onRemoveMapping);

  return (
    <>
      {addMapping && (
        <UnitsProvider>
          <MappingForm
            onSave={(mapping: any) => {
              onAddMapping(mapping);
              setAddMapping(false);
            }}
            onCancel={() => setAddMapping(false)}
            elementUnits={elementUnits}
          />
        </UnitsProvider>
      )}
      {!addMapping && (
        <FlexCenter>
          <IconButton
            onClick={() => {
              setAddMapping(true);
            }}
          >
            <AddIcon />
          </IconButton>
          <CarlaText>Add Item</CarlaText>
        </FlexCenter>
      )}
      <TableContainer className={className}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">Facility</TableCell>
              <TableCell align="center">Unit</TableCell>
              <TableCell align="center">USI</TableCell>
              <TableCell align="center">Location</TableCell>
              <TableCell align="center">Target</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <MappingTableRows
              elementMappings={elementMappings}
              onRemove={confirm}
            />
            {confirming && (
              <OkayCancelDialog
                title="Cancel?"
                okayLabel="Yes"
                cancelLabel="No"
                onOkay={onConfirm}
                onCancel={onCancel}
              >
                Are you sure you want to delete?
              </OkayCancelDialog>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MappingsTable;
