import { useContext, useState, createContext, useEffect, useCallback } from 'react';
import Api from '../../../api';
import { WalkdownTemplate, WalkdownTemplateParams } from '../../../types/walkdown-template';

const WalkdownTemplatesListContext = createContext<Value>(null as any);

interface Props {
  children: any;
}

interface Value {
  list: WalkdownTemplate[];
  create: (temp: WalkdownTemplateParams) => void;
  del: (id: number) => void;
  lastCreatedId: number;
  totalList: WalkdownTemplate[];
}

const useWalkdownTemplatesList = (): Value => {
  const val = useContext(WalkdownTemplatesListContext);
  if (!val) {
    throw new Error('useWalkdownTemplatesList must be used inside its provider!');
  }
  return val;
};

export const WalkdownTemplatesListProvider = ({ children }: Props) => {
  const [list, setList] = useState<WalkdownTemplate[]>([]);
  const [totalList, setTotalList] = useState<WalkdownTemplate[]>([]);
  const [lastCreatedId, setLastCreatedId] = useState(0);

  useEffect(() => {
    Api.getAllWalkdownTemplates().then((data: any) => {
      setList(data);
      setTotalList(data);
    });
  }, []);

  const create = useCallback(async (temp: WalkdownTemplateParams) => {
    const newTemplate = await Api.createWalkdownTemplate(temp);
    const templates = await Api.getAllWalkdownTemplates();
    setList(templates);
    setLastCreatedId(newTemplate.id);
  }, []);

  const del = useCallback(
    async (id: number) => {
      await Api.deleteWalkdownTemplate(id);
      const templates = await Api.getAllWalkdownTemplates();
      setList(templates);
    },
    [list]
  );

  const value = {
    list,
    create,
    del,
    lastCreatedId,
    totalList,
  };

  return <WalkdownTemplatesListContext.Provider value={value}>{children}</WalkdownTemplatesListContext.Provider>;
};

export default useWalkdownTemplatesList;
