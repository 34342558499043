import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DeferralReviewPage from './deferrals-review/DeferralReviewPage';
import ReportReviewPage from './report-review/ReportReviewPage';
import WalkdownReviewPage from './walkdown-review';
import { AllElementsProvider } from '../../hooks/all-elements';

const ApprovalsPage = ({ match }: any) => (
  <AllElementsProvider>
    <Switch>
      <Route
        path={`${match.path}/report-review`}
        component={ReportReviewPage}
      />
      <Route
        path={`${match.path}/deferrals`}
        component={DeferralReviewPage}
      />
      <Route
        path={`${match.path}/walkdown-review`}
        component={WalkdownReviewPage}
      />
      <Route>
        <Redirect to={`${match.path}/report-review`} />
      </Route>
    </Switch>
  </AllElementsProvider>
);

export default ApprovalsPage;
