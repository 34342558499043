import AutocompleteInput from '../../../../components/AutocompleteInput';
import { format } from 'date-fns';
import { useChangeTrackingValue } from '../../hooks/useChangeTracking';
import { optionsBuilder } from '../utils';
import { Grid } from '@material-ui/core';

type ChangeTrackingFiltersProps = Pick<useChangeTrackingValue, 'logs' | 'setFilters' | 'filters'>;

const ChangeTrackingFilters = ({ logs, setFilters, filters }: ChangeTrackingFiltersProps) => {
  const dateOptions = optionsBuilder(
    logs,
    'changedDate',
    'changedDate',
    date => format(new Date(date), 'ddMMMyyyy').toUpperCase(),
    date => format(new Date(date), 'ddMMMyyyy').toUpperCase()
  );

  const woNumOptions = optionsBuilder(logs, 'wonum', 'wonum');

  const typeOptions = optionsBuilder(logs, 'recordType', 'recordType');

  const changedValueOptions = optionsBuilder(logs, 'field', 'field');

  const oldValueOptions = optionsBuilder(
    logs,
    'oldValue',
    'oldValue',
    value => value || '',
    value => value || ''
  );

  const newValueOptions = optionsBuilder(
    logs,
    'newValue',
    'newValue',
    value => value || '',
    value => value || ''
  );

  const changedByOptions = optionsBuilder(logs, 'changedByName', 'changedBy');

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="Work Order Number"
          options={woNumOptions}
          onChange={value => {
            setFilters({ ...filters, wonum: value });
          }}
          value={filters.wonum}
          multiple
          selectAll
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="Changed Type"
          options={typeOptions}
          onChange={value => {
            setFilters({ ...filters, recordType: value });
          }}
          value={filters.recordType}
          multiple
          selectAll
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="Changed Value"
          options={changedValueOptions}
          onChange={value => {
            setFilters({ ...filters, field: value });
          }}
          value={filters.field}
          multiple
          selectAll
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="Old Value"
          options={oldValueOptions}
          onChange={value => {
            setFilters({ ...filters, oldValue: value });
          }}
          value={filters.oldValue}
          multiple
          selectAll
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="New Value"
          options={newValueOptions}
          onChange={value => {
            setFilters({ ...filters, newValue: value });
          }}
          value={filters.newValue}
          multiple
          selectAll
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="Changed Date"
          options={dateOptions}
          onChange={value => {
            setFilters({ ...filters, changedDate: value });
          }}
          value={filters.changedDate}
          multiple
          selectAll
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AutocompleteInput
          label="Changed By"
          options={changedByOptions}
          onChange={value => {
            setFilters({ ...filters, changedBy: value });
          }}
          value={filters.changedBy}
          multiple
          selectAll
        />
      </Grid>
    </Grid>
  );
};
export default ChangeTrackingFilters;
