import React from 'react';
import * as R from 'ramda';
import ActionTable from '../ActionTable';
import useReportActions from '../../hooks/report-actions';
import useReport from '../../hooks/report';
import useActions from '../../hooks/actions';

const ShipContainer = ({ reportId }: { reportId: string }) => {
  const { report } = useReport(reportId);
  const { elementType } = report;
  const { actions: reportActions } = useReportActions(reportId, report.elementId as unknown as string, 'ongoing');

  const { actions } = useActions(report.elementId, 'ongoing');

  return !R.isNil(actions) ? (
    <ActionTable
      element={{ elementType }}
      actions={report.state === 'approved' ? reportActions : actions}
      reorderable={false}
      completionNotes={false}
    />
  ) : null;
};

export default ShipContainer;
