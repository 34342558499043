import React from 'react';
import styled from 'styled-components';
import UnstyledButton from '@material-ui/core/Button';

const Button = styled(UnstyledButton)`
  text-transform: uppercase;
  font: ${({ theme }) => theme.fonts.bold12};
  color: ${({ theme }) => theme.colours.blues.blue5};
`;

interface Props {
  className?: string;
  children: any;
  onClick(): void;
}

const ControlButton = ({ className, children, onClick }: Props) => (
  <Button
    className={className}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default ControlButton;
