import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as executiveSummaryUiActions from '../../../../../state/executive-summary-ui/actions';
import * as executiveSummaryUiSelectors from '../../../../../state/executive-summary-ui/selectors';

const useUiState = executiveSummary => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(executiveSummaryUiActions.reset());
  }, [dispatch]);

  useEffect(() => {
    if (R.isNil(executiveSummary) || R.isEmpty(executiveSummary)) {
      dispatch(executiveSummaryUiActions.editExecutiveSummary());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const uiState = useSelector(executiveSummaryUiSelectors.getState);
  const subjectCommentId = useSelector(executiveSummaryUiSelectors.getCommentId);
  const isEmptyExecutiveSummary = R.isNil(executiveSummary) || R.isEmpty(executiveSummary);

  return {
    isEmptyExecutiveSummary,
    uiState,
    subjectCommentId,
  };
};

export default useUiState;
