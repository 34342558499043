import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';
import { useOverview } from '../../../../hooks/useOverview';
import { useState, useEffect } from 'react';

interface Props {
  group: 'assessing' | 'sc' | 'eng' | 'ops' | 'other';
}

const OverrideButtonGroup = ({ group }: Props) => {
  const { overrides, handleChangeOverride } = useOverview();
  const [groupStatus, setGroupStatus] = useState<boolean | null>(overrides[group]);
  useEffect(() => {
    setGroupStatus(overrides[group]);
  }, [overrides]);

  const [groupId, title]: [number, string] = (() => {
    switch (group) {
      case 'assessing':
        return [1, 'Assessing'];
      case 'sc':
        return [2, 'Supply Chain'];
      case 'eng':
        return [3, 'Engineering'];
      case 'ops':
        return [4, 'Operations'];
      case 'other':
        return [5, 'Other'];
      default:
        return [0, ''];
    }
  })();
  return (
    <Box margin={'5px'}>
      <Typography style={{ fontSize: 15 }}>{title}</Typography>

      <ButtonGroup>
        <Button
          variant={groupStatus === false ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            setGroupStatus(false);
            handleChangeOverride(groupId, false);
          }}
        >
          Not Ready
        </Button>
        <Button
          variant={groupStatus === null ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            setGroupStatus(null);
            handleChangeOverride(groupId, null);
          }}
        >
          Auto
        </Button>
        <Button
          variant={groupStatus === true ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            setGroupStatus(true);
            handleChangeOverride(groupId, true);
          }}
        >
          Ready
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default OverrideButtonGroup;
