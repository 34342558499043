import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import api from '../api';
import dateFromQuarter from '../utils/date-from-quarter';
import { ElementId } from '../types/app';

// Context
const ActualsContext: any = createContext(null);

// Hook
const useElementActuals = () => {
  const actuals = useContext(ActualsContext);
  if (!actuals) {
    throw new Error('useElementActuals must be used inside an ActualsProvider');
  }
  return actuals;
};

export default useElementActuals;

// Provider
interface ProviderProps {
  elementId: ElementId;
  children: any;
  scorecard: any;
}

export const ActualsProvider = ({ elementId, children, scorecard }: ProviderProps) => {
  const [actuals, setActuals] = useState<any[]>([]);

  useEffect(() => {
    api.getElementActuals(elementId).then((data: any) => {
      const newData = [...data];

      const newActuals = newData
        .map((r: any) => {
          const quarterDate = moment(dateFromQuarter(r.quarter, r.year));

          // if date is in the future don't show it on the graph
          if (quarterDate.isSameOrAfter(moment(), 'day')) {
            return null;
          }
          return {
            date: quarterDate.toDate(),
            score: r.totalScore,
          };
        })
        .filter(element => element !== null);

      // Add a fixed point with the current score and date so it displays nice on the graph
      newActuals.unshift({
        date: new Date(),
        score: scorecard.totals.score,
      });

      setActuals(newActuals);
    });
  }, [elementId]);

  return <ActualsContext.Provider value={actuals}>{children}</ActualsContext.Provider>;
};
