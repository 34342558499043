import React from 'react';
import Select from '../components/Select';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

export type ControlSelectProps<T extends FieldValues> = {
  control: Control<T>;
  required: boolean;
  label: string;
  name: Path<T>;
  options: { label: string; value: string | number }[];
  error: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  /**
   * Disables field visually AND in form state. Form value is set to `undefined`.
   *
   * Use this prop if the field is unused and can be ignored completely.
   */
  disabled?: boolean;
  /**
   * Disables field visually ONLY.
   *
   * Use this prop if its value is important, but users should not be able to interact with the field.
   * */
  readOnly?: boolean;
  onChangeCallback?: () => void;
  additionalRules?: { min?: number; max?: number };
  'data-testid'?: string;
};

export const ControlSelect = <T extends FieldValues>({
  control,
  required,
  name,
  label,
  error,
  options,
  multiple = false,
  fullWidth = true,
  disabled = false,
  readOnly = false,
  onChangeCallback,
  additionalRules,
  'data-testid': dataTestid,
}: ControlSelectProps<T>) => (
  <Controller
    name={name}
    control={control}
    disabled={disabled}
    rules={{ required, ...additionalRules }}
    render={({ field }) => (
      <Select
        {...{ ...(typeof dataTestid === 'string' ? { 'data-testid': dataTestid } : {}) }}
        multiple={multiple}
        label={label}
        options={options}
        onChange={e => {
          onChangeCallback?.();
          field.onChange(e);
        }}
        value={field.value}
        disabled={field.disabled || readOnly}
        fullWidth={fullWidth}
        error={error}
        name={field.name}
      />
    )}
  />
);
