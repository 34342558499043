/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import CustomFormLabel from './CustomFormLabel';
import useReadOnly from '../hooks/read-only';

const TextField = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    border-radius: 0;
  }
`;

export type CustomTextFieldProps = TextFieldProps & {
  useDefaultLabel?: boolean;
};

const CustomTextField = ({
  className,
  label,
  error,
  fullWidth,
  disabled,
  variant = 'outlined',
  margin = 'dense',
  type = 'text',
  useDefaultLabel = false,
  id,
  name,
  ...props
}: CustomTextFieldProps) => {
  const readOnly = useReadOnly();

  return (
    <FormControl
      className={className}
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {label && !useDefaultLabel && <CustomFormLabel error={error}>{label}</CustomFormLabel>}
      <TextField
        disabled={disabled || readOnly}
        variant={variant}
        margin={margin}
        type={type}
        error={error}
        label={useDefaultLabel ? label : undefined}
        name={name}
        id={id || (useDefaultLabel ? name : undefined)}
        {...props}
      />
    </FormControl>
  );
};

export default CustomTextField;
