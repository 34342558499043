import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../../api';
import * as actionTypes from '../../../state/action-types';
import * as pageActions from './actions';

function* loadUserAssignments(action) {
  const { userId } = action.payload;
  try {
    const userAssignments = yield call(Api.getAllUserAssignments, userId);
    yield put(pageActions.loadUserAssignmentsSuccess(userId, userAssignments));
  } catch (e) {
    yield put(pageActions.loadUserAssignmentsFailure(e, { userId }));
  }
}

function* loadElements() {
  try {
    const elements = yield call(Api.getAllElements);
    yield put(pageActions.loadElementsSuccess(elements));
  } catch (e) {
    yield put(pageActions.loadElementsFailure(e));
  }
}

function* addUserAssignment(action) {
  try {
    const { userId, elementId, assignmentType } = action.payload;
    yield call(Api.addUserAssignment, userId, elementId, assignmentType);
    yield put(pageActions.addUserAssignmentSuccess(userId, elementId, assignmentType));
  } catch (e) {
    yield put(pageActions.addUserAssignmentFailure(e));
  }
}

function* deleteUserAssignment(action) {
  try {
    const { userId, elementId, assignmentType } = action.payload;
    yield call(Api.deleteUserAssignment, userId, elementId, assignmentType);
    yield put(pageActions.deleteUserAssignmentSuccess(userId, elementId, assignmentType));
  } catch (e) {
    yield put(pageActions.deleteUserAssignmentFailure(e));
  }
}

function* root() {
  yield takeEvery(actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_REQUEST, loadUserAssignments);
  yield takeEvery(actionTypes.ADMIN_LOAD_ELEMENTS_REQUEST, loadElements);
  yield takeEvery(actionTypes.ADMIN_ADD_USER_ASSIGNMENT_REQUEST, addUserAssignment);
  yield takeEvery(actionTypes.ADMIN_ADD_USER_ASSIGNMENT_SUCCESS, loadUserAssignments);
  yield takeEvery(actionTypes.ADMIN_DELETE_USER_ASSIGNMENT_REQUEST, deleteUserAssignment);
  yield takeEvery(actionTypes.ADMIN_DELETE_USER_ASSIGNMENT_SUCCESS, loadUserAssignments);
}

export default root;
