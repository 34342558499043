import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as elementAttachmentActions from '../../../../../state/element-attachments/actions';
import { MyElement } from '../../../../../types/my';

const useAddAttachment = (element: MyElement) => {
  const { elementId } = element;
  const dispatch = useDispatch();
  const [addingAttachment, setAddingAttachment] = useState(false);

  const onAddAttachment = useCallback(() => {
    setAddingAttachment(true);
  }, [setAddingAttachment]);

  const onConfirmAddAttachment = useCallback(
    (year: string, quarter: string, fileType: string, file: File) => {
      dispatch(elementAttachmentActions.addRequest(elementId, year, quarter, fileType, file));
      setAddingAttachment(false);
    },
    [dispatch, elementId, setAddingAttachment]
  );

  const onCancelAddAttachment = useCallback(() => {
    setAddingAttachment(false);
  }, [setAddingAttachment]);

  return {
    addingAttachment,
    onAddAttachment,
    onConfirmAddAttachment,
    onCancelAddAttachment,
  };
};

export default useAddAttachment;
