import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import UnstyledButton from '@material-ui/core/Button';

const FilterButton = styled(props => <UnstyledButton {...R.omit(['isActive'], props)} />)`
  background-color: ${({ theme, isActive }) => (isActive ? theme.colours.greys.lightGrey4 : theme.colours.white)};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow2};
  border-radius: 2px;
  font: ${({ theme }) => theme.fonts.medium14};
  color: ${({ theme }) => theme.colours.greys.darkGrey3};
  letter-spacing: 0;
  border: ${({ theme }) => theme.borders.thin.solid1};
  padding: 8px 24px;
`;

export default FilterButton;
