/* eslint-disable no-underscore-dangle */
import * as R from 'ramda';
import capitalize from '../../utils/capitalize';
import * as actionTypes from '../action-types';

const initialState = {
  elements: {
    records: [],
  },
  backupRses: {},
  elementMappings: {},
  elementUnits: {},
  unitWeights: {},
  reportingFrequencies: [],
  categories: [],
  facilities: [],
  schemas: [],
  lastCreatedElementId: null,
  lastCreatedError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ELEMENTS_LOAD_ALL_REQUEST: {
      return {
        ...state,
        elements: {
          ...state.elements,
          isLoading: true,
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_ALL_SUCCESS: {
      const { elements } = action.payload;
      return {
        ...state,
        elements: {
          ...state.elements,
          records: elements,
          isLoading: false,
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_ALL_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        elements: {
          ...state.elements,
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_BACKUP_RSES_REQUEST: {
      const { elementId } = action.payload;
      return {
        ...state,
        backupRses: {
          ...state.backupRses,
          [elementId]: {
            ...R.propOr({}, elementId, state.backupRses),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_BACKUP_RSES_SUCCESS: {
      const { elementId, backupRses } = action.payload;
      return {
        ...state,
        backupRses: {
          ...state.backupRses,
          [elementId]: {
            records: backupRses,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_BACKUP_RSES_FAILURE: {
      const { error, elementId } = action.payload;
      return {
        ...state,
        backupRses: {
          ...state.backupRses,
          [elementId]: {
            ...R.propOr({}, elementId, state.backupRses),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_SET_BACKUP_RSE_SUCCESS: {
      const { elementId, userId, name } = action.payload;
      return {
        ...state,
        backupRses: {
          ...state.backupRses,
          [elementId]: {
            ...R.propOr({}, elementId, state.backupRses),
            records: R.uniqBy(
              R.prop('userId'),
              R.append({ userId, userName: name }, R.pathOr([], [elementId, 'records'], state.backupRses))
            ),
          },
        },
      };
    }
    case actionTypes.ELEMENTS_PATCH_SUCCESS: {
      const {
        elementId,
        reportingFrequency,
        category,
        state: isActiveState,
        referenceReportingQuarter,
        referenceReportingYear,
        pmpRequired,
        tier,
        sis,
        safetyRelatedSystemClassification,
        pmpDate,
      } = action.payload;

      const idx = state.elements.records.findIndex(r => r.id === elementId);
      const newElementRecords = [...state.elements.records];

      if (newElementRecords[idx]) {
        if (category) newElementRecords[idx].category = category;
        if (reportingFrequency) newElementRecords[idx].reportingFrequency = reportingFrequency;
        if (isActiveState) newElementRecords[idx].state = isActiveState;
        if (referenceReportingQuarter) newElementRecords[idx].referenceReportingQuarter = referenceReportingQuarter;
        if (referenceReportingYear) newElementRecords[idx].referenceReportingYear = referenceReportingYear;
        if (tier) newElementRecords[idx].tier = tier;
        if (!R.isNil(pmpRequired)) newElementRecords[idx].pmpRequired = pmpRequired;
        if (sis !== undefined) newElementRecords[idx].sis = sis;
        if (safetyRelatedSystemClassification)
          newElementRecords[idx].safetyRelatedSystemClassification = safetyRelatedSystemClassification;
        if (pmpDate) newElementRecords[idx].pmpDate = pmpDate;
      }

      return {
        ...state,
        elements: {
          ...state.elements,
          records: newElementRecords,
        },
      };
    }
    case actionTypes.ELEMENTS_CLEAR_BACKUP_RSE_SUCCESS: {
      const { elementId, userId } = action.payload;
      return {
        ...state,
        backupRses: {
          ...state.backupRses,
          [elementId]: {
            ...R.propOr({}, elementId, state.backupRses),
            records: R.filter(
              R.complement(R.propEq)('userId', userId),
              R.pathOr([], [elementId, 'records'], state.backupRses)
            ),
          },
        },
      };
    }
    case actionTypes.ELEMENTS_SET_PRIMARY_RSE_SUCCESS: {
      const { elementId, userId, name } = action.payload;
      return {
        ...state,
        elements: {
          ...state.elements,
          records: R.map(
            R.when(R.propEq('id', elementId), R.mergeRight(R.__, { primaryRse: userId, primaryRseName: name })),
            R.propOr([], 'records', state.elements)
          ),
        },
      };
    }
    case actionTypes.ELEMENTS_CLEAR_PRIMARY_RSE_SUCCESS: {
      const { elementId } = action.payload;
      return {
        ...state,
        elements: {
          ...state.elements,
          records: R.map(
            R.when(R.propEq('id', elementId), R.mergeRight(R.__, { primaryRse: undefined, primaryRseName: undefined })),
            R.propOr([], 'records', state.elements)
          ), // eslint-disable-line no-underscore-dangle
        },
      };
    }
    case actionTypes.SET_OVERRIDE_REPORT_DUE_DATE_SUCCESS: {
      const { elementId, date } = action.payload;
      return {
        ...state,
        elements: {
          ...state.elements,
          records: R.map(
            R.when(R.propEq('id', elementId), R.mergeRight(R.__, { nextReportDueDate: new Date(date).toISOString() })),
            R.propOr([], 'records', state.elements)
          ),
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_ELEMENT_UNITS_SUCCESS: {
      const { elementId, elementUnits } = action.payload;
      return {
        ...state,
        elementUnits: {
          ...state.elementUnits,
          [elementId]: elementUnits,
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_REQUEST: {
      const { elementId } = action.payload;
      return {
        ...state,
        unitWeights: {
          ...state.unitWeights,
          [elementId]: {
            ...R.propOr({}, elementId, state.unitWeights),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_SUCCESS: {
      const { elementId, unitWeights } = action.payload;
      return {
        ...state,
        unitWeights: {
          ...state.unitWeights,
          [elementId]: {
            records: unitWeights,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_UNIT_WEIGHTS_FAILURE: {
      const { error, elementId } = action.payload;
      return {
        ...state,
        unitWeights: {
          ...state.unitWeights,
          [elementId]: {
            ...R.propOr({}, elementId, state.unitWeights),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_REQUEST: {
      const { elementId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [elementId]: {
            ...R.propOr({}, elementId, state.byId),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_SUCCESS: {
      const { elementId, element } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [elementId]: {
            element,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_FAILURE: {
      const { error, elementId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [elementId]: {
            ...R.propOr({}, elementId, state.byId),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.ELEMENTS_LOAD_CATEGORIES_SUCCESS: {
      const arr = action.payload;

      const categories = arr.map(r => ({
        value: r.id,
        label: capitalize(r.id),
      }));

      return {
        ...state,
        categories,
      };
    }
    case actionTypes.ELEMENTS_LOAD_FREQUENCIES_SUCCESS: {
      const arr = action.payload;

      const reportingFrequencies = arr.map(r => ({
        value: r.id,
        label: capitalize(r.id),
      }));

      return {
        ...state,
        reportingFrequencies,
      };
    }
    case actionTypes.ELEMENTS_LOAD_FACILITIES_SUCCESS: {
      const arr = action.payload;

      const data = arr.map(r => ({
        value: r.id,
        label: r.name,
      }));

      return {
        ...state,
        facilities: data,
      };
    }
    case actionTypes.ELEMENTS_LOAD_SCHEMAS_SUCCESS: {
      const arr = action.payload;

      const data = arr.map(r => ({
        value: r.name,
        label: r.name,
      }));

      return {
        ...state,
        schemas: data,
      };
    }
    default:
      return state;
  }
};
