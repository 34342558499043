import React from 'react';
import styled from 'styled-components';
import EditExecutiveSummary from './EditExecutiveSummary';
import StaticExecutiveSummary from './StaticExecutiveSummary';
import useExecutiveSummaryUi from './hooks/executive-summary-ui';

const Container = styled.div``;

interface Props {
  className?: string;
}

const ExecutiveSummary = ({ className }: Props) => {
  const { uiState } = useExecutiveSummaryUi();

  return (
    <Container className={className}>
      {uiState === 'editingExecutiveSummary' || uiState === 'savingExecutiveSummary' ? (
        <EditExecutiveSummary />
      ) : (
        <StaticExecutiveSummary />
      )}
    </Container>
  );
};

export default ExecutiveSummary;
