import * as R from 'ramda';
import moment, { Moment } from 'moment';

interface Quarter {
  quarter: number;
  year: number;
  isCurrent: boolean;
}

export const toQuarterValue = (year: string | number, quarter: string | number) => `${year}-${quarter}`;

export const fromQuarterValue = (value: string) =>
  R.adjust(0, year => parseInt(year, 10).toString(), R.split('-', value));

const multiplier = (forward: boolean) => (forward ? 1 : -1);

const toQuarter = R.curry((firstQuarter: Moment, forward: boolean, i: number) => {
  const startOfQuarter = moment(firstQuarter).add(multiplier(forward) * i, 'quarters');

  return {
    quarter: startOfQuarter.quarter(),
    year: startOfQuarter.year(),
    isCurrent: moment().startOf('quarter').isSame(startOfQuarter),
  };
});

const useQuarters = ({ n = 4, offset = 0, forward = true, today = new Date() } = {}) => {
  const thisQuarter = moment(today).startOf('quarter');
  const firstQuarter = moment(thisQuarter).add(offset, 'quarters');

  const quarters: Quarter[] = R.map(toQuarter(firstQuarter, forward), R.range(0, n));

  return {
    quarters,
  };
};

export default useQuarters;
