import Box from '@material-ui/core/Box';
import MUIPaper from '@material-ui/core/Paper';
import MUITypography from '@material-ui/core/Typography';
import styled from 'styled-components';

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: scroll;

  padding-right: 10px;
`;

const OverlayHeader = styled(MUITypography)`
  text-transform: uppercase;
  font: ${({ theme }) => theme.fonts.bold16};
  color: ${({ theme }) => theme.colours.greys.darkGrey4};
`;

const Container = styled(MUIPaper).attrs({
  elevation: 4,
  square: true,
})`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: ${({ theme }) => theme.borders.thin.solid5};

  ${OverlayHeader} {
    margin-bottom: 10px;
  }

  ${Body} {
    margin-bottom: 10px;
  }
`;

interface Props {
  action?: JSX.Element;
  className?: string;
  children: any;
  statusText?: string;
  title: string;
}

const OverlayPaper = ({ action, className, statusText, children, title }: Props) => {
  return (
    <Container className={className}>
      {statusText && (
        <Box
          textAlign="right"
          mb={2}
          component="span"
        >
          {statusText}
        </Box>
      )}
      <OverlayHeader>{title}</OverlayHeader>
      <Body>{children}</Body>
      {action && <ActionContainer>{action}</ActionContainer>}
    </Container>
  );
};

export default OverlayPaper;
