import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUIDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import UnstyledCheckboxWithExpandingSection from './CheckboxWithExpandingSection';
import UnstyledCheckboxField from '../CheckboxField';
import CustomRadioGroup from '../CustomRadioGroup';
import useForm from '../../hooks/form';
import useGroupFilters from '../../hooks/group-filters';
import CustomTextField from '../CustomTextField';
import CarlaText from '../CarlaText';

const DialogContent = styled(MUIDialogContent)``;
const DialogActions = styled(MUIDialogActions)``;

const CancelButton = styled(Button)``;
const SaveButton = styled(Button)`
  color: ${({ theme }) => theme.colours.teals.teal1};
`;

const CheckboxWithExpandingSection = styled(UnstyledCheckboxWithExpandingSection)``;
const CheckboxField = styled(UnstyledCheckboxField)``;

const Dialog = styled(MUIDialog)`
  padding: 35px 40px 41px 20px;

  ${DialogContent} {
    display: flex;
    flex-direction: column;

    ${CheckboxWithExpandingSection} {
      margin-left: 20px;
    }

    ${CheckboxField} {
      margin-left: 9px;
    }
  }

  ${DialogActions} {
    margin: 20px 28px 21px 0px;
    padding: 0px;
  }
`;

const formConfig = {
  forcedOutageStartDate: {},
  forcedOutageEndDate: {},
  setbackEvent: {},
  impairmentLevel: {},
  reducedRedundancy: {
    mapValueFn: R.path(['target', 'checked']),
    valueProp: 'checked',
  },
  repeatEventEfcNumber: {},
  repeatEventArNumber: {},
  description: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter description',
      },
    },
  },
};

const initialValues = (record: any) => ({
  forcedOutageStartDate: record.forcedOutageStartDate ? moment(record.forcedOutageStartDate).format('YYYY-MM-DD') : '',
  forcedOutageEndDate: record.forcedOutageEndDate ? moment(record.forcedOutageEndDate).format('YYYY-MM-DD') : '',
  setbackEvent: record.setbackEvent || 'rmi1',
  impairmentLevel: record.impairmentLevel || 'l1',
  reducedRedundancy: record.reducedRedundancy || false,
  repeatEventEfcNumber: record.repeatEventEfcNumber || '',
  repeatEventArNumber: record.repeatEventArNumber || '',
  description: record.applicabilityDescription || '',
});

const filterConfig = (record: any) => ({
  forcedOutage: {
    fields: ['forcedOutageStartDate', 'forcedOutageEndDate'],
    initialState: !(R.isNil(record.forcedOutageStartDate) && R.isNil(record.forcedOutageEndDate)),
  },
  setback: {
    fields: ['setbackEvent'],
    initialState: !R.isNil(record.setbackEvent),
  },
  impairment: {
    fields: ['impairmentLevel'],
    initialState: !R.isNil(record.impairmentLevel),
  },
  repeatEvent: {
    fields: ['repeatEventEfcNumber', 'repeatEventArNumber'],
    initialState: !(R.isNil(record.repeatEventEfcNumber) && R.isNil(record.repeatEventArNumber)),
  },
});

interface Props {
  indicatorShortName: string;
  record: any;
  onlyComment?: boolean;
  onSave(options: any): void;
  onCancel(): void;
}

const ApplyDialog = ({ indicatorShortName, record, onlyComment, onSave, onCancel }: Props) => {
  const { propsForGroup, filteredSave } = useGroupFilters(filterConfig(record), onSave);
  const handleNullDates = (next: any) => (values: any) =>
    next({
      ...values,
      forcedOutageStartDate: R.isEmpty(values.forcedOutageStartDate) ? undefined : values.forcedOutageStartDate,
      forcedOutageEndDate: R.isEmpty(values.forcedOutageEndDate) ? undefined : values.forcedOutageEndDate,
    });

  const { submit, propsForField } = useForm(formConfig, initialValues(record), handleNullDates(filteredSave));

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      onClose={onCancel}
      scroll="paper"
    >
      <DialogContent>
        {!onlyComment && (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: '20px' }}
          >
            {R.includes(indicatorShortName, ['functional-failures', 'comp-functional-failures']) && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <CarlaText font="medium16">Select all that apply</CarlaText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CheckboxWithExpandingSection
                    label="Forced Outage"
                    {...propsForGroup('forcedOutage')}
                  >
                    <Grid
                      container
                      alignItems="center"
                    >
                      <CustomTextField
                        variant="filled"
                        size="small"
                        type="date"
                        {...propsForField('forcedOutageStartDate')}
                        helperText="Start Date"
                      />
                      <Typography>To</Typography>
                      <CustomTextField
                        variant="filled"
                        size="small"
                        type="date"
                        {...propsForField('forcedOutageEndDate')}
                        helperText="End Date"
                      />
                    </Grid>
                  </CheckboxWithExpandingSection>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CheckboxWithExpandingSection
                    label="Set Back"
                    {...propsForGroup('setback')}
                  >
                    <CustomRadioGroup
                      options={[
                        { value: 'rmi1', label: 'RMI1 EVENT' },
                        { value: 'rmi2', label: 'RMI2 EVENT' },
                        { value: 'rmi3', label: 'RMI3 EVENT' },
                      ]}
                      {...propsForField('setbackEvent')}
                    />
                  </CheckboxWithExpandingSection>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CheckboxWithExpandingSection
                    label="Impairment"
                    {...propsForGroup('impairment')}
                  >
                    <CustomRadioGroup
                      options={[
                        { value: 'l1', label: 'L1' },
                        { value: 'l2', label: 'L2' },
                        { value: 'l3', label: 'L3' },
                      ]}
                      {...propsForField('impairmentLevel')}
                    />
                  </CheckboxWithExpandingSection>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CheckboxField
                    label="Reduction in Redundancy"
                    {...propsForField('reducedRedundancy')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CheckboxWithExpandingSection
                    label="Repeat Event"
                    {...propsForGroup('repeatEvent')}
                  >
                    <Box width={90}>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            label="EFC #"
                            variant="filled"
                            placeholder="Enter EFC #"
                            {...propsForField('repeatEventEfcNumber')}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            label="AR #"
                            variant="filled"
                            placeholder="Enter AR #"
                            {...propsForField('repeatEventArNumber')}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CheckboxWithExpandingSection>
                </Grid>
              </>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <CustomTextField
            label="Cause Description"
            multiline
            rows={5}
            fullWidth
            {...propsForField('description')}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={submit}>Save</SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyDialog;
