/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import FormHelperText from '@material-ui/core/FormHelperText';
import UnstyledFileDropZone from '../../../../../components/FileDropZone';

const FileDropZone = styled(UnstyledFileDropZone)``;

const Container = styled.div`
  width: 385px;
`;

interface Props {
  className?: string;
  error?: boolean;
  helperText?: string;
  onChange(file: any): void;
}

const FileUploadField = ({ className, error, helperText, onChange }: Props) => {
  const handleFileDrop = (files: Array<any>) => {
    if (files.length === 1) {
      onChange(files[0]);
    }
  };

  return (
    <Container className={className}>
      <FileDropZone onFileDrop={handleFileDrop} />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Container>
  );
};

export default FileUploadField;
