import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { Button, Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router';
import Table from '../../components/Table';
import useWalkdowns from '../../hooks/walkdowns';
import { Walkdown as IWalkdown } from './types/types';
import DownloadButton from '../../components/DownloadButton';
import elementTypePrefix from '../../utils/element-type-prefix';
import NoRecords from '../../components/NoRecords';
import FilterOptionsPanel from '../health-reports/pages/landing-page/FilterOptionsPanel';
import useFilteredArchivedWalkdowns from './hooks/useFilteredArchivedWalkdowns';

const WalkDown =
  (generateAndDownloadWalkdown: any, replace: any) =>
  ({ id, title, quarter, completedDate, elementType, year, elementName, facilityName, unit }: IWalkdown) => {
    return (
      <TableRow key={id}>
        <TableCell
          component="th"
          scope="row"
        >
          <b>
            <>
              [{elementTypePrefix(elementType)}] {elementName} ({facilityName})
            </>
          </b>
        </TableCell>
        <TableCell align="right">
          <b>{title}</b>
        </TableCell>
        <TableCell align="right">{unit && <b>{unit}</b>}</TableCell>
        <TableCell align="right">{quarter && <i>Q{quarter}</i>}</TableCell>
        <TableCell align="right">
          <i>{year}</i>
        </TableCell>
        <TableCell align="center">
          {completedDate && <i>Completed On {moment(completedDate).format('LL')}</i>}
        </TableCell>
        <TableCell align="right">
          <Button
            color="primary"
            onClick={() => replace(`/app/walkdown/archives/view/${id}`)}
          >
            View
          </Button>
        </TableCell>
        <TableCell align="left">
          <DownloadButton
            color="primary"
            onClick={() => {
              generateAndDownloadWalkdown(id);
            }}
          >
            View
          </DownloadButton>
        </TableCell>
      </TableRow>
    );
  };

const ArchivesPage = () => {
  const { generateAndDownloadWalkdown } = useWalkdowns();
  const { filters, setFilters, yearOptions, filteredWalkdowns } = useFilteredArchivedWalkdowns();
  const { replace } = useHistory();

  return (
    <>
      <Grid
        item
        style={{ paddingTop: '0.5em' }}
      >
        <FilterOptionsPanel
          onChangeFilter={(updatedFilter: any) => {
            setFilters({ ...filters, ...updatedFilter });
          }}
          yearOptions={yearOptions || []}
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {filteredWalkdowns ? filteredWalkdowns.map(WalkDown(generateAndDownloadWalkdown, replace)) : <NoRecords />}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ArchivesPage;
