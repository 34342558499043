import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};
const ConfirmChangeDialog = ({ isOpen, onConfirm, onCancel }: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>Delete Warning</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you wish to change the opportunity/threat?</Typography>
        <Typography style={{ fontWeight: 500 }}>
          If you choose to continue, existing probability and impact assessments will be removed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeDialog;
