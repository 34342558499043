import React from 'react';
import styled from 'styled-components';
import UnstyledCard from '@material-ui/core/Card';
import { Button, Grid } from '@material-ui/core';
import CustomTextField from '../../../../components/CustomTextField';
import { CreateCategoryParams } from '../../../../types/walkdown-template';
import useForm from '../../../../hooks/form';
import { defaultValues, formConfig } from './config/createCategoryFormConfig';

const Card = styled(UnstyledCard)`
  width: 100%;
  margin-bottom: 2%;
  background-color: ${({ theme }) => theme.colours.white};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow2};
  padding: 20px;
  padding-top: 20px;
  position: relative;

  margin-top: 10px;
`;

const SaveButton = styled(Button)`
  position: absolute;
  right: 10px;
`;

interface Props {
  onSave(t: CreateCategoryParams): void;
}

const CreateCategory = ({ onSave }: Props) => {
  const { submit, propsForField } = useForm(formConfig, defaultValues, onSave);

  return (
    <Card>
      <Grid container>
        <Grid item>
          <b>Create category</b>
        </Grid>

        <Grid
          container
          item
        >
          <Grid
            item
            xs={5}
          >
            <CustomTextField
              fullWidth
              variant="standard"
              {...propsForField('name')}
            />
          </Grid>
          <Grid item>
            <SaveButton onClick={submit}>Save</SaveButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateCategory;
