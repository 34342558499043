import {
  WalkdownTemplate,
  WalkdownTemplateCategory,
  WalkdownTemplateParams,
  WalkdownTemplateTask,
  WalkdownTemplateTaskParams,
} from '../types/walkdown-template';
import { Path, Remote } from './types';

const getWalkdownTemplate = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}`));
const listWalkdownTemplates = (path: Path, remote: Remote) => (elementId: number) =>
  remote.get(path(`?elementId=${elementId}`));
const createWalkdownTemplate = (path: Path, remote: Remote) => (template: WalkdownTemplateParams) =>
  remote.post(path(''), template);
const deleteWalkdownTemplate = (path: Path, remote: Remote) => (id: number) => remote.del(path(`/${id}`));
const getAllWalkdownTemplates = (path: Path, remote: Remote) => () => remote.get(path('/view/all'));
const updateWalkdownTemlplate = (path: Path, remote: Remote) => (id: number, updates: Partial<WalkdownTemplate>) =>
  remote.patch(path(`/${id}`), updates);
const downloadWalkdownTemplate = (path: Path, remote: Remote) => (templateId: any) =>
  remote.download(path(`/${templateId}/pdf`));
const createWalkdownTemplateCategory =
  (path: Path, remote: Remote) =>
  (t: Omit<WalkdownTemplateCategory, 'tasks' | 'id'>): Promise<WalkdownTemplateCategory> =>
    remote.post(path(`/${t.templateId}/categories`), {
      name: t.name,
      type: t.type,
    });
const generateWalkdownTemplate = (path: Path, remote: Remote) => (templateId: number) =>
  remote.post(path(`/${templateId}/pdf`));
const removeWalkdownTemplateCategory =
  (path: Path, remote: Remote) =>
  (templateId: number, categoryId: number): Promise<any> =>
    remote.del(path(`/${templateId}/categories/${categoryId}`));

const addWalkdownTemplateTask =
  (path: Path, remote: Remote) => (templateId: number, catgoryId: number, task: WalkdownTemplateTaskParams) =>
    remote.post(path(`/${templateId}/categories/${catgoryId}/tasks`), task);

const removeWalkdownTemplateTask =
  (path: Path, remote: Remote) => (templateId: number, catgoryId: number, task: WalkdownTemplateTask) =>
    remote.del(path(`/${templateId}/categories/${catgoryId}/tasks/${task.id}`));

const patchWalkdownTemplateTask =
  (path: Path, remote: Remote) => (templateId: number, catgoryId: number, task: WalkdownTemplateTask) =>
    remote.patch(path(`/${templateId}/categories/${catgoryId}/tasks/${task.id}`), task);

const getWalkdownInputTypes = (path: Path, remote: Remote) => () => remote.get(path('/view/input-types'));

export default (path: Path, remote: Remote) => ({
  getWalkdownTemplate: getWalkdownTemplate(path, remote),
  listWalkdownTemplates: listWalkdownTemplates(path, remote),
  createWalkdownTemplate: createWalkdownTemplate(path, remote),
  deleteWalkdownTemplate: deleteWalkdownTemplate(path, remote),
  getAllWalkdownTemplates: getAllWalkdownTemplates(path, remote),
  createWalkdownTemplateCategory: createWalkdownTemplateCategory(path, remote),
  removeWalkdownTemplateCategory: removeWalkdownTemplateCategory(path, remote),
  updateWalkdownTemlplate: updateWalkdownTemlplate(path, remote),
  addWalkdownTemplateTask: addWalkdownTemplateTask(path, remote),
  removeWalkdownTemplateTask: removeWalkdownTemplateTask(path, remote),
  getWalkdownInputTypes: getWalkdownInputTypes(path, remote),
  downloadWalkdownTemplate: downloadWalkdownTemplate(path, remote),
  generateWalkdownTemplate: generateWalkdownTemplate(path, remote),
  patchWalkdownTemplateTask: patchWalkdownTemplateTask(path, remote),
});
