import { AutocompleteOption } from '../../../components/AutocompleteInput';
import { Disposition, Resolution } from '../../../types/outages';

export const getDispositionOptions = (resolution: Resolution | null): AutocompleteOption<Disposition>[] => {
  switch (resolution) {
    case 'resolution_in_progress':
      return [{ label: 'Tracking Required', value: 'tracking_required' }];
    case 'resolution_required':
      return [{ label: 'N/A', value: 'not_applicable' }];
    case 'resolved':
      return [
        { label: 'Risk Eliminated', value: 'risk_eliminated' },
        { label: 'Risk Mitigated', value: 'risk_mitigated' },
        { label: 'Not Equipment Issue', value: 'not_equipment_issue' },
      ];
    default:
      return [];
  }
};
