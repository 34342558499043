import React from 'react';
import { AllAttachmentTypesProvider } from '../../../../hooks/all-attachment-types';
import { Downloader } from '../../../../hooks/downloader';
import { Report } from '../../../../hooks/report';
import AttachmentsBox from './components/AttachmentsBox';

interface Props {
  report: Report;
  onNext: () => void;
}

const UploadAttachmentsPage = ({ report, onNext }: Props) =>
  report.elementId ? (
    <AllAttachmentTypesProvider>
      <Downloader>
        <AttachmentsBox
          report={report}
          onNext={onNext}
        />
      </Downloader>
    </AllAttachmentTypesProvider>
  ) : (
    <div />
  );

export default UploadAttachmentsPage;
