import * as R from 'ramda';

export const getAllElements = state => R.pathOr([], ['elements', 'records'], state.elements);
export const getBackupRses = (state, elementId) => R.pathOr([], ['backupRses', elementId, 'records'], state.elements);
export const getElementMappings = (state, elementId) =>
  R.pathOr([], ['elementMappings', elementId, 'records'], state.elements);
export const getElementUnits = (state, elementId) => R.pathOr([], ['elements', 'elementUnits', elementId], state);
export const getUnitWeights = (state, elementId) => R.pathOr([], ['unitWeights', elementId, 'records'], state.elements);
export const getElement = (state, elementId) =>
  R.pipe(R.pathOr({}, ['elements', 'records']), R.find(R.propEq('id', elementId)))(state.elements);
export const isLoading = (state, elementId) => R.pathOr(false, ['byId', elementId, 'isLoading'], state.elements);
