/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import UnstyledFileUploadField from '../../../../../components/FileUploadField';
import UnstyledFileTypeSelector from './FileTypeSelector';
import OutlineButton from '../../../../../components/OutlineButton';

const FormTitle = styled(Typography)`
  font: ${({ theme }) => theme.fonts.normal14};
  color: ${({ theme }) => theme.colours.greys.darkGrey4};
`;

const FileUploadField = styled(UnstyledFileUploadField)``;

const FileTypeSelector = styled(UnstyledFileTypeSelector)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${FormTitle} {
    margin-bottom: 20px;
  }

  ${FileUploadField} {
    margin-bottom: 20px;
  }

  ${FileTypeSelector} {
    margin-bottom: 31px;
  }
`;

interface Props {
  className?: string;
  onUpload(fileType: string, file: any): void;
}

const FileUploadForm = ({ className, onUpload }: Props) => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');

  const handleUploadFile = () => {
    if (fileType && file) {
      onUpload(fileType, file);
    }
  };

  const canAdd = !R.isNil(file) && !R.isEmpty(fileType);

  return (
    <Container className={className}>
      <FormTitle>Add documents to your report</FormTitle>
      <FileUploadField
        value={file}
        onChange={setFile}
      />
      <FileTypeSelector
        value={fileType}
        onChange={setFileType}
      />
      <div>
        <OutlineButton
          onClick={handleUploadFile}
          disabled={!canAdd}
        >
          Add File
        </OutlineButton>
      </div>
    </Container>
  );
};

export default FileUploadForm;
