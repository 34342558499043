import * as actionTypes from '../action-types';

const initialState = {
  all: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.USERS_LOAD_ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.USERS_LOAD_ALL_SUCCESS: {
      const { users } = action.payload;
      return {
        ...state,
        all: users,
        isLoading: false,
      };
    }
    case actionTypes.USERS_LOAD_ALL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
