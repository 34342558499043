import React from 'react';
import styled from 'styled-components';

import MUITable from '@material-ui/core/Table';
import MUITableContainer from '@material-ui/core/TableContainer';
import MUITableHead from '@material-ui/core/TableHead';
import MUITableBody from '@material-ui/core/TableBody';
import MUITableRow from '@material-ui/core/TableRow';
import MUITableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import MUIClearIcon from '@material-ui/icons/Clear';
import MUIEditIcon from '@material-ui/icons/Edit';

const ClearIcon = styled(MUIClearIcon)`
  color: ${({ theme }) => theme.colours.reds.red1};
`;
const EditIcon = styled(MUIEditIcon)``;

const TableCell = styled(MUITableCell)``;
const TableRow = styled(MUITableRow)``;
const TableBody = styled(MUITableBody)``;
const TableHead = styled(MUITableHead)``;
const TableContainer = styled(MUITableContainer)``;
const Table = styled(MUITable)``;

const Row = ({ className, dashboard, onEdit, onDelete }) => (
  <TableRow className={className}>
    <TableCell>{dashboard.id}</TableCell>
    <TableCell>{dashboard.title}</TableCell>
    <TableCell>{dashboard.previewImgUrl}</TableCell>
    <TableCell>{dashboard.description}</TableCell>
    <TableCell>{dashboard.dashboardUrl}</TableCell>
    <TableCell>
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={onDelete}>
        <ClearIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const DashboardsTable = ({ dashboards, onDelete, onEdit }) => {
  const handleDelete = dashboardId => () => {
    onDelete(dashboardId);
  };

  const handleEdit = dashboardId => () => {
    onEdit(dashboardId);
  };

  const dashboardRows = dashboards.map(dashboard => (
    <Row
      key={dashboard.id}
      dashboard={dashboard}
      onEdit={handleEdit(dashboard.id)}
      onDelete={handleDelete(dashboard.id)}
    />
  ));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Preview Image</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Dashboard URL</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{dashboardRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardsTable;
