import React from 'react';
import styled from 'styled-components';

import MUIPaper from '@material-ui/core/Paper';

import UnstyledActionButton from '../ActionButton';

const ActionButton = styled(UnstyledActionButton)``;

const ActionContainer = styled.div`
  ${ActionButton} {
    margin-left: 16px;
  }
`;

const Paper = styled(MUIPaper).attrs({
  elevation: 0,
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  border: ${({ theme }) => theme.borders.thin.solid3};
  padding: 20px 30px 20px 30px;
  border-radius: 0px;

  ${ActionContainer} {
    align-self: flex-start;
    flex-shrink: 0;
  }
`;

interface Props {
  className?: string;
  onPrimary?(): void;
  primaryText?: string;
  onSecondary?(): void;
  secondaryText?: string;
}

const NavPaper = ({ className, onPrimary, primaryText, onSecondary, secondaryText }: Props) => (
  <Paper className={className}>
    <ActionContainer>
      {onSecondary && (
        <ActionButton
          color="default"
          onClick={onSecondary}
        >
          {secondaryText}
        </ActionButton>
      )}
      {onPrimary && (
        <ActionButton
          color="primary"
          onClick={onPrimary}
        >
          {primaryText}
        </ActionButton>
      )}
    </ActionContainer>
  </Paper>
);

export default NavPaper;
