import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fffc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressOverlay = () => (
  <Container>
    <CircularProgress />
  </Container>
);

export default ProgressOverlay;
