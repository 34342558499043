import { type MigrateParams } from './types';
/*
 FYI: Changing this and rolling back the deployment will break indexed-db as it
 doesn't support rolling back.  If anything the database version should relate
 to the tables created not the schema & indecies.
*/
export const MAX_DB_REVISION = 1;
export const SCHEMA_VERSION = '0.0.1';

const dbMigration = (db: IDBDatabase) => async (version: number) => {
  // this should never happen
  if (version > MAX_DB_REVISION) {
    throw new Error('Version out of Range');
  }

  if (version === 1) {
    db.createObjectStore('api_requests', { keyPath: 'requestUrlSlug' });
  }
};
const migrate = ({ db, oldVersion, newVersion }: MigrateParams) => {
  if (Math.abs(oldVersion) !== oldVersion || Math.abs(newVersion) !== newVersion) {
    console.warn(
      'Indexed-DB Migration Warning: Decimal Numbers detected. ' +
        'Versions provided should be Major-Version only. ' +
        'No minor & patch versions will perform a migration' +
        '\nIt should be impossible to see this message.'
    );
  }

  const fromVersion = Math.floor(oldVersion);
  const toVersion = Math.floor(newVersion);
  const versionDelta = toVersion - fromVersion;
  const isNew = db.objectStoreNames.length === 0 && oldVersion === 0;
  const isUpgrade = oldVersion < newVersion;
  const isRollback = versionDelta < 0;

  if (!isNew && !isUpgrade && !isRollback) {
    return;
  }
  if (isRollback) {
    console.warn(
      'Indexed-DB Migration Warning: Rollback not supported by indexed-db. ' +
        'It should be impossible to see this message.'
    );
    return;
  }
  const versions = Array.from({ length: versionDelta }).map((_v, idx) => idx + fromVersion + 1);

  return Promise.all(versions.map(dbMigration(db)));
};
export default migrate;
