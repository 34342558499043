import { type ClientDb, type HttpEventDetail, type HttpRegistration } from './types';

type DispatchParams = { detail: HttpEventDetail; method: string; status: number | string };
export const checkBroadcastDebug = () =>
  process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG_BROADCAST_CHANNEL;

const isBroadcastDebug = checkBroadcastDebug();

export const broadcastMessageHandler = (clientDb: ClientDb) => (event: any) => {
  if (typeof event.data !== 'string') {
    return;
  }
  let data = null;
  try {
    data = JSON.parse(event.data);
  } catch (error) {
    console.error('Could not decode message.  Got Error: ', error);
    return;
  }

  const {
    payload: detail,
    payload: { keyPath },
    eventType = '',
  } = data;

  const eventTypeBase =
    eventType.indexOf(':') < eventType.indexOf('@')
      ? eventType.slice(0, eventType.length - keyPath.length - 1)
      : eventType;
  if (eventType === 'log' && isBroadcastDebug) {
    console.log(
      `[REMOTE-BROADCAST-DEBUG (From: ${data.origin})]`,
      ...(data.payload instanceof Array ? data.payload : [data.payload])
    );
    return;
  }
  if (eventType === 'log') {
    return;
  }
  const eventPayload = { detail: { ...detail, eventTypeBase } };
  clientDb.eventHandler.dispatchEvent(new CustomEvent(eventType, eventPayload));
};

export const determineDispatchEvent = (clientDb: ClientDb, { detail, method, status }: DispatchParams) => {
  const is200Response = `${status}`.startsWith('2');
  const payload = { detail };

  if (is200Response && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase())) {
    isBroadcastDebug && clientDb.broadcastDebug('Matched POST/PUT/PATCH/DELETE Request');
    clientDb.eventHandler.dispatchEvent(new CustomEvent('httpWriteSuccess', payload));
    return true;
  }
  if (is200Response && method.toUpperCase() === 'GET') {
    isBroadcastDebug && clientDb.broadcastDebug('Matched GET Request');
    clientDb.eventHandler.dispatchEvent(new CustomEvent('httpReadSuccess', payload));
    return true;
  }
  return false;
};
export const axiosResponseInterceptor = (clientDb: ClientDb, responseEvent: any) => {
  // don't block
  setTimeout(() => {
    const { config = {}, status, headers: responseHeaders, data } = responseEvent;
    const { params, headers: requestHeaders, data: requestBody = null, url, method = '' } = config;
    const baseUrl = process.env.REACT_APP_ECHO_SERVER_BASE ?? '';
    const relativeUrl = url.startsWith(baseUrl) ? url.slice(baseUrl.length, url.length) : url;
    const detail: HttpEventDetail = {
      status,
      data,
      responseHeaders,
      requestHeaders,
      requestBody,
      method,
      relativeUrl,
      url,
      params,
    };
    const foundRegistrations = clientDb.httpRegistrations
      .map((registration: HttpRegistration) => {
        if (typeof registration === 'function') {
          return registration(detail);
        }
        if (registration.toLowerCase() === relativeUrl.toLowerCase()) {
          return true;
        }
        if (registration.toLowerCase() === url.toLowerCase()) {
          return true;
        }
        const withoutParams = registration.split('?')[0].toLowerCase();
        const relativeWithoutParams = relativeUrl.split('?')[0].toLowerCase();
        if (!registration.includes('?') && relativeUrl.includes('?') && withoutParams === relativeWithoutParams) {
          return true;
        }
        return false;
      })
      .filter((b: boolean) => !!b);

    foundRegistrations.length > 0 && determineDispatchEvent(clientDb, { detail, method, status });
  }, 0);
};
