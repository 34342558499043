import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as dashboardActions from '../../../state/dashboards/actions';

const useNewDashboard = () => {
  const dispatch = useDispatch();
  const [newDashboard, setNewDashboard] = useState(null);

  const addDashboard = useCallback(() => {
    setNewDashboard({});
  }, [setNewDashboard]);

  const cancelAddDashboard = useCallback(() => {
    setNewDashboard(null);
  }, [setNewDashboard]);

  const createDashboard = useCallback(
    dashboard => {
      dispatch(dashboardActions.createDashboardRequest(dashboard));
      setNewDashboard(null);
    },
    [dispatch, setNewDashboard]
  );

  return {
    newDashboard,
    addDashboard,
    cancelAddDashboard,
    createDashboard,
  };
};

export default useNewDashboard;
