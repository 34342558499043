import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Lock, LockOpenOutlined } from '@material-ui/icons';
import CustomFormLabel from '../../../../components/CustomFormLabel';
import styled from 'styled-components';
import { ControlTextField } from '../../../../form/ControlTextField';
import { WorkRequestCodingForm } from '../hooks/useWorkRequestCodingForm';
import { CATEGORY_LABELS, CATEGORY_OPTIONS } from '../constants';
import { ControlAutocomplete } from '../../../../form/ControlAutocomplete';
import { ControlCheckbox } from '../../../../form/ControlCheckbox';
import { buildCategoryValidationDeps } from '../utils/validate';
import { getInputTypeForCategory } from './utils';

const ScrollingTableContainer = styled(TableContainer).attrs({
  component: Paper,
})`
  // Match margins from .MuiFormControl-marginDense for consistency with text inputs
  margin-top: 8px;
  margin-bottom: 4px;

  flex-grow: 1;
  overflow-y: scroll;

  .MuiTableCell-head {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${({ theme }) => theme.colours.purples.purple1};
    color: ${({ theme }) => theme.colours.white};
    width: 50%;
  }

  .MuiTableCell-root {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
`;

const LockedCheckbox = styled(ControlCheckbox)`
  &.MuiFormControlLabel-root {
    margin: 0;
  }

  .MuiCheckbox-root {
    color: ${({ theme }) => theme.colours.purples.purple1};
  }
` as typeof ControlCheckbox;

export type CodingTableProps = Pick<
  WorkRequestCodingForm,
  'codings' | 'control' | 'errors' | 'handleBlurRow' | 'validateCategoryValue' | 'watch'
> & { disabled?: boolean };

const CodingTable: React.FC<CodingTableProps> = ({
  codings,
  control,
  errors,
  handleBlurRow,
  validateCategoryValue,
  disabled,
  watch,
}) => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <CustomFormLabel
        id="coding-table-title"
        component="span"
        disabled={disabled}
      >
        Auto-Categorized Codings
      </CustomFormLabel>
      <ScrollingTableContainer>
        <Table
          stickyHeader
          aria-labelledby="coding-table-title"
        >
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codings.map(({ id }, index) => (
              <TableRow key={id}>
                <TableCell>
                  <ControlAutocomplete
                    control={control}
                    name={`codings.${index}.category`}
                    required
                    label="Category"
                    options={CATEGORY_OPTIONS}
                    error={!!errors?.codings?.[index]?.category}
                    helperText={errors?.codings?.[index]?.category?.message}
                    validate={(value, formValues) =>
                      !formValues.codings.some(
                        (coding, matchingIndex) => coding.category === value && matchingIndex !== index
                      ) || 'Category already exists'
                    }
                    onInputBlur={handleBlurRow}
                    deps={buildCategoryValidationDeps(index, codings.length)}
                    disabled={disabled}
                  />
                </TableCell>
                <TableCell style={{ display: 'flex', paddingRight: 1 }}>
                  <ControlTextField
                    label="Value"
                    name={`codings.${index}.value`}
                    required
                    error={!!errors?.codings?.[index]?.value}
                    helperText={errors?.codings?.[index]?.value?.message}
                    control={control}
                    useDefaultLabel
                    onBlur={handleBlurRow}
                    validate={(value, formValues) => {
                      const category = formValues.codings[index].category;
                      return (
                        category === null ||
                        value === null ||
                        validateCategoryValue(category, value, formValues) ||
                        `Invalid value for category ${CATEGORY_LABELS[category]}`
                      );
                    }}
                    deps={[`codings.${index}.category`]}
                    type={getInputTypeForCategory(watch(`codings.${index}.category`))}
                    disabled={disabled}
                  />
                  <Tooltip
                    title={`Warning: unlocked values will be overridden when "Generate Coding" button is pressed`}
                  >
                    <span style={{ display: 'flex' }}>
                      <LockedCheckbox
                        name={`codings.${index}.locked`}
                        control={control}
                        disabled={disabled}
                        label=""
                        checkedIcon={<Lock />}
                        icon={<LockOpenOutlined />}
                      />
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollingTableContainer>
    </Box>
  );
};

export default CodingTable;
