import { TabProps } from '../../types';
import Layout from './Layout';
import DocumentContainer from './components/DocumentContainer';
import { AttachmentsProvider } from './hooks/attachments';

const UploadAttachmentsTab: React.FC<TabProps> = ({ element }) => {
  return (
    <AttachmentsProvider elementId={element.elementId}>
      <Layout>
        <DocumentContainer element={element} />
      </Layout>
    </AttachmentsProvider>
  );
};

export default UploadAttachmentsTab;
