import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import elementTypePrefix from '../../../../utils/element-type-prefix';

const UserAssignmentsList = ({ userAssignments, onDelete }) => {
  const handleDelete = (elementId, assignmentType) => () => {
    onDelete(elementId, assignmentType);
  };

  const items = userAssignments.map(userAssignment => (
    <ListItem key={`${userAssignment.assignmentType}|${userAssignment.elementId}`}>
      <ListItemText>
        <Link to={`/app/admin/elements?elementId=${userAssignment.elementId}`}>
          [{elementTypePrefix(userAssignment.elementType)}] {userAssignment.elementName} ({userAssignment.facilityName})
        </Link>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={handleDelete(userAssignment.elementId, userAssignment.assignmentType)}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));
  return <List>{items}</List>;
};

export default UserAssignmentsList;
