import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import * as reportAttachmentActions from '../state/element-attachments/actions';
import * as reportAttachmentSelectors from '../state/element-attachments/selectors';

const useReportAttachmentsByQuarter = (elementId, year, quarter, load = true) => {
  const dispatch = useDispatch();

  const loadAttachments = useCallback(() => {
    dispatch(reportAttachmentActions.loadByQuarterRequest(elementId, year, quarter));
  }, [dispatch, elementId, year, quarter]);

  useEffect(() => {
    if (!R.isEmpty(elementId) && !R.isNil(year) && !R.isNil(quarter) && load) {
      loadAttachments();
    }
  }, [elementId, year, quarter, load, loadAttachments]);

  const attachmentsWithState = useSelector(state =>
    reportAttachmentSelectors.getAttachmentsWithStateByQuarter(state, elementId, year, quarter)
  );
  const attachments = useSelector(state =>
    reportAttachmentSelectors.getAttachmentsByQuarter(state, elementId, year, quarter)
  );
  const isLoading = useSelector(state => reportAttachmentSelectors.isLoadingByQuarter(state, elementId, year, quarter));
  const error = useSelector(state => reportAttachmentSelectors.getErrorByQuarter(state, elementId, year, quarter));

  return {
    attachments,
    attachmentsWithState,
    isLoading,
    error,
    loadAttachments,
  };
};

export default useReportAttachmentsByQuarter;
