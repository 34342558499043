import React from 'react';
import styled from 'styled-components';

import MUITypography from '@material-ui/core/Typography';
import MUIList from '@material-ui/core/List';

import SectionItem from './SectionItem';

const Typography = styled(MUITypography)`
  font: ${({ theme }) => theme.fonts.normal14};
`;

const Header = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
`;

const List = styled(MUIList)`
  padding-top: 0;
  height: calc(100vh - 382px);
  overflow: auto;
`;

const Container = styled.div`
  width: 251px;
  border: ${({ theme }) => theme.borders.medium.solid2};

  ${Header} {
    padding-top: 24px;
    padding-left: 18px;
    padding-bottom: 12px;
    border: ${({ theme }) => theme.borders.thin.solid2};
  }
`;

interface Props {
  sections: any;
  onSectionItemClick(sectionNumber: number): void;
  className?: string;
  currentSectionId: number;
}

const SectionNavigator = ({ sections, onSectionItemClick, className, currentSectionId }: Props) => {
  const sectionItems = sections.map((section: any, sectionNumber: number) => (
    <SectionItem
      key={section.title}
      title={section.title}
      onClick={() => onSectionItemClick(sectionNumber)}
      selected={currentSectionId === section.id}
    />
  ));
  return (
    <Container className={className}>
      <Header>Sections</Header>
      <List>{sectionItems}</List>
    </Container>
  );
};

export default SectionNavigator;
