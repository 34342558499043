import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import Table from '../../../../../../components/Table';
import { dateToString } from '../../../../../../utils/date-format';
import ControlButton from '../../../../../../components/ControlButton';
import CarlaText from '../../../../../../components/CarlaText';
import UnstyledExpandableTable from './ExpandableTable';
import ExpandableTableBody from './ExpandableTableBody';

const ExpandableTable = styled(UnstyledExpandableTable)`
  .MuiTableCell-body {
    color: ${({ theme }) => theme.colours.greys.darkGrey5};
    font: ${({ theme }) => theme.fonts.medium14};
  }

  .MuiTableCell-body.strong {
    color: ${({ theme }) => theme.colours.greys.darkGrey4};
  }
`;

const AssignmentRow = ({ assignment, isOpen, onToggleOpen }) => (
  <>
    <TableRow>
      <TableCell
        align="left"
        className="strong"
      >
        {assignment.assignmentNumber}
      </TableCell>
      <TableCell align="center">{dateToString(assignment.dueDate)}</TableCell>
      <TableCell align="center">{assignment.status}</TableCell>
      <TableCell align="center">{assignment.ownerGroup}</TableCell>
      <TableCell align="left">{assignment.description}</TableCell>
      <TableCell
        padding="none"
        align="right"
      >
        <ControlButton onClick={onToggleOpen}>{isOpen ? 'Close' : 'Open'}</ControlButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={6}
      >
        <Collapse
          in={isOpen}
          unmountOnExit
        >
          <Box py={2}>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <CarlaText
                  variant="h3"
                  colour="greys.darkGrey4"
                >
                  Condition Details
                </CarlaText>
              </Grid>
              <Grid item>
                <CarlaText colour="greys.darkGrey5">{assignment.conditionDetails}</CarlaText>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
);

const AssignmentTable = ({ assignments }) => {
  const rows = assignments.map(assignment => (
    <AssignmentRow
      key={assignment.crNumber}
      assignment={assignment}
    />
  ));

  return (
    <Table
      padding={40}
      tableElement={ExpandableTable}
    >
      <TableHead>
        <TableRow>
          <TableCell align="left">Assignment #</TableCell>
          <TableCell align="center">Due Date</TableCell>
          <TableCell align="center">Status</TableCell>
          <TableCell align="center">Primary Resp Group</TableCell>
          <TableCell align="left">Subject</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <ExpandableTableBody>{rows}</ExpandableTableBody>
    </Table>
  );
};

export default AssignmentTable;
