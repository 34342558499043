/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import UnstyledAttachmentName from './AttachmentName';
import useReadOnly from '../../../../../hooks/read-only';

const AttachmentName = styled(UnstyledAttachmentName)``;

const Details = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${AttachmentName} {
    width: 230px;
    margin-right: 10px;
  }

  ${Details} {
    display: flex;
    flex-direction: row;
  }
`;

interface Props {
  className?: string;
  attachment: any;
  onDelete?: any;
  onDownload?: any;
}

const Attachment = ({ className, attachment, onDelete, onDownload }: Props) => {
  const readOnly = useReadOnly();

  return (
    <Container className={className}>
      {attachment.name && (
        <Details>
          <AttachmentName>{attachment.name}</AttachmentName>
          {!readOnly && (
            <IconButton
              size="small"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={onDownload}
          >
            <GetAppIcon />
          </IconButton>
        </Details>
      )}
    </Container>
  );
};

export default Attachment;
