import React from 'react';
import styled from 'styled-components';

const FeatureListItem = styled.li`
  margin: 0px;
  padding-left: 10px;
  font: ${({ theme }) => theme.fonts.normal14};
  color: ${({ theme }) => theme.colours.greys.darkGrey4};
`;

const FeatureHeader = styled.h2`
  margin: 0px;
  color: ${({ theme }) => theme.colours.purples.purple1};
  font: ${({ theme }) => theme.fonts.medium16};
`;

const Container = styled.div`
  ${FeatureHeader} {
    margin-bottom: 2px;
  }
`;

const FeatureList = styled.ul`
  margin: 0px;
  padding-left: 26px;
`;

const Feature = ({ className, header, features }) => (
  <Container className={className}>
    <FeatureHeader>{header}</FeatureHeader>
    <FeatureList>
      {features.map(feature => (
        <FeatureListItem key={feature}>{feature}</FeatureListItem>
      ))}
    </FeatureList>
  </Container>
);

export default Feature;
