import { useMemo } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import useAllElements from '../hooks/all-elements';
import UnstyledPanel from './Panel';
import UnstyledFilterSelector from './FilterSelector';
import elementTypePrefix from '../utils/element-type-prefix';
import DropdownAutocomplete from './DropdownAutocomplete';
import { Grid } from '@material-ui/core';
import useMyElements from '../hooks/my-elements';
import { MyElement } from '../types/my';
import { LabelOptions } from '../types/risk-management';

const FilterSelector = styled(UnstyledFilterSelector)``;

const Panel = styled(UnstyledPanel)`
  ${FilterSelector} {
    margin-right: 10px;
  }
`;

interface Props {
  elementId: string;
  onChangeElement(element: string): void;
  elementOptions: LabelOptions[];
}

export const useFilterAllElements = (elementIds?: string[]) => {
  const { elements } = useAllElements();

  const filteredElements = useMemo(
    () => (elementIds ? R.filter((element: any) => R.includes(element.id, elementIds), elements) : elements),
    [elementIds, elements]
  );

  const elementOptions = filteredElements.map((element: any) => ({
    label: `[${elementTypePrefix(element.type)}] ${element.name} (${element.facilityName})`,
    value: element.id,
  }));

  return { elementOptions };
};

export const useFilterMyElements = () => {
  const { myElements } = useMyElements();

  const elementOptions = myElements.map((element: MyElement) => ({
    label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
    value: element.elementId,
  }));

  return { elementOptions };
};

const FilterPanel = ({ elementId, onChangeElement, elementOptions }: Props) => {
  return (
    <Panel>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
      >
        <DropdownAutocomplete
          label="Element"
          options={elementOptions}
          value={elementId}
          onChange={onChangeElement}
        />
      </Grid>
    </Panel>
  );
};

export default FilterPanel;
