import React from 'react';
import styled from 'styled-components';
import Paragraphs from '../../../../components/Paragraphs';
import UnstyledCommentActions from './CommentActions';
import Body from './Body';
import useExecutiveSummaryUi from './hooks/executive-summary-ui';
import ProgressOverlay from './ProgressOverlay';

const CommentActions = styled(UnstyledCommentActions)``;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${CommentActions} {
    margin-left: 10px;
  }
`;

const StaticExecutiveSummary = ({ className }: { className?: string }) => {
  const { report, uiState, onEditExecutiveSummary } = useExecutiveSummaryUi();

  return (
    <Container className={className}>
      <Body>
        <Paragraphs s={report.executiveSummary || ''} />
      </Body>
      <CommentActions
        disabled={uiState !== 'idle'}
        onEdit={onEditExecutiveSummary}
        canReorder={false}
      />
      {uiState === 'finalizingExecutiveSummary' && <ProgressOverlay />}
    </Container>
  );
};

export default StaticExecutiveSummary;
