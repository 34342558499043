import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  checkboxOptions: Record<string, string>;
  disabled?: boolean;
}

export const ControlCheckboxes = <T extends FieldValues>({
  control,
  name,
  checkboxOptions,
  disabled = false,
}: Props<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => {
      return (
        <Grid
          container
          spacing={3}
        >
          {Object.entries(checkboxOptions).map(([name, label]) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                style={{ paddingTop: 0, paddingBottom: 0 }}
                key={name}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={Boolean(field.value[name])}
                      onChange={() => {
                        field.onChange({ ...field.value, [name]: !field.value[name] });
                      }}
                      size="small"
                    />
                  }
                  label={label}
                  style={{ padding: 0, fontSize: '0.5rem' }}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }}
  />
);
