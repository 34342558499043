import { Box, Modal, makeStyles } from '@material-ui/core';
import { useWODetailsModal } from '../hooks/useWODetailsModal';
import styled from 'styled-components';
import DetailTabs from './WODetailTabs';

const DetailsModal = styled(Modal)`
  margin: auto;
  width: 90%;
  height: 90%;
`;
const useStyles = makeStyles(() => ({
  paper: {
    border: '2px solid #000',
    height: '100%',
    width: '100%',
    overflow: 'scroll',
  },
}));

const WODetailsModal = () => {
  const classes = useStyles();
  const { open, handleClose } = useWODetailsModal();
  return (
    <DetailsModal
      open={open}
      onClose={handleClose}
    >
      <Box
        bgcolor={'white'}
        className={classes.paper}
      >
        <DetailTabs />
      </Box>
    </DetailsModal>
  );
};

export default WODetailsModal;
