import React, { useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';

import FormHelperText from '@material-ui/core/FormHelperText';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import styled from 'styled-components';
import useReadOnly from '../../../hooks/read-only';

interface Props {
  minDate?: Date;
  maxDate?: Date;
  label: string;
  defaultValue?: string;
  onChange(x: Date): void;
  value?: any;
  error?: boolean;
}

const DatePicker = styled(KeyboardDatePicker)`
  margin: 0;
`;

const DateSelect = ({ minDate, maxDate, label, defaultValue = '', onChange, value, error }: Props) => {
  const [type, setType] = useState(defaultValue || null);
  const handleChange = (date: any) => {
    setType(date);
    onChange(date);
  };

  const readOnly = useReadOnly();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl>
        <DatePicker
          error={error}
          disabled={readOnly}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={value || type}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <FormHelperText>{label}</FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

export default DateSelect;
