import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const SmallButton = styled(Button)`
  padding: 4px 0px;
  text-transform: none;
  font: ${({ theme }) => theme.fonts.medium16};
  color: ${({ theme }) => theme.colours.teals.teal1};
`;

export default SmallButton;
