import React from 'react';
import styled from 'styled-components';
import UnstyledTableBody from '@material-ui/core/TableBody';
import UnstyledRow from './Row';
import ActionTableRoot from './ActionTableRoot';

const Row = styled(UnstyledRow)``;

const TableBody = styled(UnstyledTableBody)`
  ${Row}:nth-child(2n) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

const StaticActionTable = ({ className, columns, actions }) => {
  const rows = actions.map(action => (
    <Row
      key={action.id}
      action={action}
      columns={columns}
    />
  ));

  return (
    <ActionTableRoot
      className={className}
      columns={columns}
    >
      <TableBody>{rows}</TableBody>
    </ActionTableRoot>
  );
};

export default StaticActionTable;
