import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { capitalize } from 'lodash';
import * as reportsActions from '../state/reports/actions';
import * as reportsSelectors from '../state/reports/selectors';
import * as uiActions from '../state/ui/actions';
import { UI_MESSAGE } from '../utils/ui-messages';
import useDownloader from './downloader';
import Api from '../api';

export interface Report {
  changedAt: Date;
  changedByName: string;
  changedByUserId: string;
  changeDescription: string;
  changeType: string;
  createdAt: Date;
  createdByEcho: number;
  createdByName: string;
  createdByUserId: string;
  displayId: number;
  elementId: number;
  elementName: string;
  elementType: string;
  executiveSummary: string;
  executiveSummaryUpdatedAt: string;
  facilityId: string;
  facilityName: string;
  id: number;
  overriddenColour: string;
  overriddenScore: number;
  overrideComment: string;
  quarter: string;
  state: string;
  stateChangeComment: null | string;
  stateChangedAt: Date;
  stateChangedByName: string;
  stateChangedByUserId: string;
  uploadBlobName: null | string;
  uploadedAt: null | string;
  uploadId: null | string;
  year: string;
}

const resolveAfter2Seconds = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, 2000);
  });

const reportFilename = (report: Report, isDraft: boolean) =>
  `${report.year} Q${report.quarter} ${capitalize(report.elementType)} Health Report for ${report.elementName}${
    isDraft ? '-DRAFT' : ''
  }.pdf`;

const useReport = (reportId: string, load = true) => {
  const dispatch = useDispatch();
  const { onDownload } = useDownloader();

  const report: Report = useSelector(state => reportsSelectors.getReport(state, reportId));

  const generateDraftReport = useCallback(async () => {
    try {
      dispatch(uiActions.genericMessage('Generating...'));
      await Api.generateDraftReport(reportId);
      await resolveAfter2Seconds();
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, [reportId]);

  const downloadDraftReport = useCallback(async () => {
    try {
      dispatch(uiActions.genericMessage('Downloading'));
      const blob = await Api.downloadReport(reportId);
      onDownload(blob, reportFilename(report, true));
      dispatch(uiActions.genericMessage('Downloaded'));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, [reportId, report]);

  const generateAndDownloadDraftReport = async () => {
    const generatingMessage = setInterval(() => {
      dispatch(uiActions.genericMessage('Generating...'));
    }, 5000);

    await generateDraftReport();
    downloadDraftReport();
    clearInterval(generatingMessage);
  };

  useEffect(() => {
    if (load) {
      dispatch(reportsActions.loadRequest(reportId));
    }
  }, [dispatch, reportId, load]);

  return {
    report,
    generateDraftReport,
    downloadDraftReport,
    generateAndDownloadDraftReport,
  };
};

export default useReport;
