import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { format } from 'date-fns';
import { useUsers } from '../../../../hooks/useUsers';
import { useNotes } from '../../../../hooks/useNotes';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
const gridStyle = { minHeight: 600, minWidth: '100%' };
const NotesGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__cell__content {
    overflow-wrap: break-word;
    white-space: normal;
  }
`;
const NotesTable = () => {
  const { getUser } = useUsers();
  const { notes, loading } = useNotes();
  const columns = [
    {
      name: 'note',
      header: 'Note',
      defaultWidth: 100,
      defaultFlex: 1,
      render: ({ value }: any) => (
        <Tooltip
          title={<h2>{value}</h2>}
          placement="top-start"
        >
          <div>{value}</div>
        </Tooltip>
      ),
    },
    {
      name: 'updatedBy',
      header: 'Last Modified By',
      defaultWidth: 150,
      render: ({ value }: any) => getUser(value)?.name,
    },
    {
      name: 'updatedAt',
      header: 'Last Modified',
      defaultWidth: 150,
      render: ({ value }: any) => format(new Date(value), 'ddMMMyyyy HH:mm').toUpperCase(),
    },
  ];

  return (
    <NotesGrid
      idProperty="id"
      style={gridStyle}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={notes}
      rowHeight={null}
      loading={loading}
    />
  );
};

export default NotesTable;
