import ActionsCell from '../../wo/components/HoverActions/ActionsCell';
import styled from 'styled-components';
import UnstyledCheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UnstyledNotInterestedIcon from '@material-ui/icons/NotInterested';

const CheckCircleOutlineIcon = styled(UnstyledCheckCircleOutlineIcon)`
  color: rgb(10, 153, 15);
`;

const NotInterestedIcon = styled(UnstyledNotInterestedIcon)`
  color: red;
`;

export const renderIsASD = ({ value }: any) => {
  return value ? (
    <CheckCircleOutlineIcon data-testid="check-circle-icon" />
  ) : (
    <NotInterestedIcon data-testid="not-interested-icon" />
  );
};

export const getOptions = (tasks: Map<number, any>, option: string) => {
  const optionsSet = new Set<string>();
  tasks.forEach(item => {
    if ((item as any)[option]) {
      optionsSet.add((item as any)[option].toString());
    }
  });
  return Array.from(optionsSet)
    .sort((a, b) => parseFloat(a) - parseFloat(b))
    .map(value => ({ label: value, value }));
};

export const renderAssessingActions = ({ data }: any) => {
  return (
    <ActionsCell
      actionCountByStatusId={data.actionCountByStatusId}
      actions={data.actions.filter((action: any) => action.groupId === 1)}
    />
  );
};
