import { TurnoverContextProvider } from '../hooks/useTurnover';
import Turnover from './components/Turnover';

const TurnoverPage = () => {
  return (
    <TurnoverContextProvider allTurnovers={true}>
      <Turnover />
    </TurnoverContextProvider>
  );
};
export default TurnoverPage;
