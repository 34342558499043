import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as dashboardsActions from '../../../state/dashboards/actions';
import * as dashboardsSelectors from '../../../state/dashboards/selectors';

const useDashboards = () => {
  const dispatch = useDispatch();

  const dashboards = useSelector(dashboardsSelectors.dashboards);

  useEffect(() => {
    dispatch(dashboardsActions.loadDashboardsRequest());
  }, [dispatch]);

  return {
    dashboards,
  };
};

export default useDashboards;
